/* eslint-disable jsx-a11y/anchor-is-valid */
import { ColumnInstance, Row } from 'react-table'
import { DisabledFunction } from '../utils'
import { CommonTabProps } from '../RegSHOModels'
import { IExecutionUserInputs } from '../BuyInAllocationAndExecution/IBuyInAllocationInterface'
import {
  BuyInExecutionButton,
  BuyInExecutionDropDown,
  BuyInExecutionInput,
} from '../BuyInAllocationAndExecution/buyInCommonComponents'
import { StyledImage } from '../../../components/image/styles'
import { Icons } from '../../../shared/GlobalStyle'
import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'
import { sethttpErrorMessageAllocation } from '../../../store/reg-SHO-Tool/reg-sho-buyInAllocation'
import { useDispatch } from 'react-redux'
type SetValue = React.Dispatch<React.SetStateAction<IExecutionUserInputs[]>>
interface RegSHOCustomColumnInstance<D extends object = {}>
  extends ColumnInstance<D> {
  objectKey?: string
}
interface ICell<D extends object = {}> {
  column: RegSHOCustomColumnInstance
  row: Row<any>
}
export const useGetStockLoanSpecificL2Column = (
  value: any,
  setValue: SetValue,
  UpdateBuyInExeCution: any,
  DeleteL2BuyInExeCution: any,
  AuditBuyInNotify: any,
  commonTabProps: CommonTabProps,
  setActiveTab: React.Dispatch<any>,
  setCommonTabProps: React.Dispatch<any>,
  handleViewComment: any
) => {
  const dispatch = useDispatch()
  return [
    {
      Header: ' ',
      columns: [
        {
          Header: 'BuyInNotice date',
          objectKey: 'buyInNoticeDate',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'date'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyInQuantity',
          objectKey: 'buyInQty',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: '...',
          accessor: '...',
          Cell: ({ row, column }: ICell) => {
            const buyInType = value?.current[row.index]?.buyInType
            const dateToBuyIn = value?.current[row.index]?.dateToBuyIn
            return (
              <a
                title={
                  buyInType && dateToBuyIn
                    ? `${buyInType} Allocation`
                    : 'pls fill mandatory field'
                }
                href="#"
                onClick={() => {
                  setCommonTabProps &&
                    setCommonTabProps({
                      ...commonTabProps,
                      allocationId: row?.original?.allocationId,
                      regSHOBuyIn_ID: row?.original?.regSHOBuyIn_ID,
                    })
                  if (buyInType === 'Stock Loan' && dateToBuyIn) {
                    setActiveTab && setActiveTab(2)
                    setCommonTabProps &&
                      setCommonTabProps((previousValue: CommonTabProps) => ({
                        ...previousValue,
                        allocationType: 'Stock LoanBuyin execution',
                      }))
                  }
                  if (buyInType === 'Others' && dateToBuyIn) {
                    setActiveTab && setActiveTab(2)
                    setCommonTabProps &&
                      setCommonTabProps((previousValue: CommonTabProps) => ({
                        ...previousValue,
                        allocationType: 'Others Buyin execution',
                      }))
                  }
                  if (buyInType === 'Customer' && dateToBuyIn) {
                    setActiveTab && setActiveTab(2)
                    setCommonTabProps &&
                      setCommonTabProps((previousValue: CommonTabProps) => ({
                        ...previousValue,
                        allocationType: 'Customer Buyin execution',
                      }))
                  }
                  if (buyInType === 'Broker' && dateToBuyIn) {
                    setActiveTab && setActiveTab(2)
                    setCommonTabProps &&
                      setCommonTabProps((previousValue: CommonTabProps) => ({
                        ...previousValue,
                        allocationType: 'Broker Buyin execution',
                      }))
                  }
                  if (buyInType === 'Correspondent' && dateToBuyIn) {
                    setActiveTab && setActiveTab(2)
                    setCommonTabProps &&
                      setCommonTabProps((previousValue: CommonTabProps) => ({
                        ...previousValue,
                        allocationType: 'Correspondent',
                      }))
                  }
                  if (!(buyInType && dateToBuyIn)) {
                    dispatch(
                      sethttpErrorMessageAllocation('Pls fill mandatory field')
                    )
                  }
                }}
              >
                <StyledImage
                  title={
                    buyInType && dateToBuyIn
                      ? `${buyInType} Allocation`
                      : 'pls fill mandatory field'
                  }
                  width={'25px'}
                  style={{ cursor: 'pointer' }}
                  src={Icons.Dash}
                />
              </a>
            )
          },
        },
        {
          Header: () => (
            <span title="Mandatory field for the L3 navigation link to work">
              DateTo Buyin/Execute
              <span style={{ color: 'red' }}>*</span>
            </span>
          ),
          accessor: 'dateToBuyIn',
          objectKey: 'dateToBuyIn',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'date'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyIn Ticket',
          objectKey: 'buyInTicketNumber',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'OFFSET',
          objectKey: 'offset',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: () => (
            <span title="Mandatory field for the L3 navigation link to work">
              AllocationType
              <span style={{ color: 'red' }}>*</span>
            </span>
          ),
          accessor: 'allocationType',
          objectKey: 'buyInType',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionDropDown
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
                defaultValue={{
                  value: value?.current[row.index]?.buyInType,
                  label: value?.current[row.index]?.buyInType,
                }}
                options={[
                  {
                    value: 'Correspondent',
                    label: 'Correspondent',
                  },
                  {
                    value: 'Others',
                    label: 'Others',
                  },
                  {
                    value: 'Customer',
                    label: 'Customer',
                  },
                  {
                    value: 'Broker',
                    label: 'Broker',
                  },
                  {
                    value: 'Stock Loan',
                    label: 'Stock Loan',
                  },
                ]}
              />
            )
          },
        },
        {
          Header: 'Add Comments',
          objectKey: 'buyInComment',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Comments',
          objectKey: 'View Comments',
          Cell: ({ row }: ICell) => {
            return (
              <Button
                onClick={() => {
                  handleViewComment(row.index)
                }}
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                style={{ whiteSpace: 'nowrap' }}
                bgColor={'transparent'}
              >
                View Comments
              </Button>
            )
          },
        },
        {
          Header: 'Audit Trail',
          objectKey: 'audit Trail',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={false}
                parentValue={value}
                label={'Audit Trail'}
                tableId={row.index}
                UpdateBuyInExeCution={AuditBuyInNotify}
              />
            )
          },
        },
        {
          Header: 'Update',
          objectKey: 'update',
          Cell: ({ row }: ICell) => {
            const buyInType = value?.current[row.index]?.buyInType
            const dateToBuyIn = value?.current[row.index]?.dateToBuyIn

            return (
              <BuyInExecutionButton
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                label={'Update'}
                tableId={row.index}
                UpdateBuyInExeCution={
                  !(buyInType == null && dateToBuyIn == null)
                    ? UpdateBuyInExeCution
                    : () => {
                        dispatch(
                          sethttpErrorMessageAllocation(
                            'Pls fill mandatory field'
                          )
                        )
                      }
                }
              />
            )
          },
        },
        {
          Header: 'Delete',
          objectKey: 'delete',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  value?.current[row.index]?.createBy,
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                parentValue={value}
                label={'Delete'}
                tableId={row.index}
                UpdateBuyInExeCution={DeleteL2BuyInExeCution}
              />
            )
          },
        },
        {
          Header: 'Email Attachment',
          Cell: 'emailAttachment',
        },
      ],
    },
  ]
}
