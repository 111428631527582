import React, { ReactNode } from 'react'
import Label from '../../components/label/Label'
import DatePicker from '../../components/datePicker/DatePicker'
import { Textbox } from '../../components/textbox/Textbox'

interface IProppedLabelProps {
  children: ReactNode
}

interface ProppedDatePickerProps {
  value: string | null
  onChange: (v: string) => void
  placeHolderText?: string
  type?:string
  max?:any
}

export const ProppedLabel: React.FC<IProppedLabelProps> = ({ children }) => {
  return (
    <Label
      fontSize="16px"
      fontWeight={400}
      color={'#0F172A'}
      lineHeight={'24px'}
    >
      {children}
    </Label>
  )
}

export const ProppedDatePicker: React.FC<ProppedDatePickerProps> = ({
  value,
  onChange,
  max
}) => {
  return (
    <DatePicker
      padding={'4px 8px'}
      onChange={onChange}
      value={value}
      max={ max ?? new Date().toISOString().split('T')[0]}
      bgColor=""
      borderColor=""
      placeHolder="From"
      maxWidth="295px"
      defaultValue={new Date().toISOString().split('T')[0]}
    />
  )
}

export const ProppedTextbox: React.FC<ProppedDatePickerProps> = ({
  value,
  onChange,
  placeHolderText,
  type
}) => {
  return (
    <Textbox
    type={type}
      onChange={onChange}
      placeholder={placeHolderText ? placeHolderText : ''}
      value={value}
      style={{
        borderRadius: '4px',
        height: '32px',
        padding: '4px 8px',
      }}
    />
  )
}
