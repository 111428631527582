import {
  ButtonWrapper,
  DownloadWrapper,
  ExcelWrapper,
  StyledContainer,
  StyledRightContainer,
  StyledSelectWrapper,
} from './RegShoHistoryStyles'
import {
  ProppedDatePicker,
  ProppedLabel,
  ProppedTextbox,
} from '../commonComponents'
import Button from '../../../components/button/Button'
import {
  RegShoHistoryDataSelector,
  getRegShoHistory,
  getRegShoHistoryExcel,
} from '../../../store/settlement/regSho-History'
import { useDispatch, useSelector } from 'react-redux'
import { IRegShoHistorySearchFilters } from './RegShoHistoryPage'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import Label from '../../../components/label/Label'
import { COLORS } from '../../../theme/Colors'

interface RegShoHistorySearchPanelProps {
  searchParams: IRegShoHistorySearchFilters
  setsearchParams: React.Dispatch<
    React.SetStateAction<IRegShoHistorySearchFilters>
  >
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>
}

const RegShoHistorySearchPanel = ({
  searchParams,
  setsearchParams,
  setShowTable,
}: RegShoHistorySearchPanelProps) => {
  const pageNameOption = [
    { value: 'CusipLevelSummaryBuyInDetails', label: 'CusipLevelSummary' },
    { value: 'Allocations', label: 'Allocations' },
    { value: 'BuyInDetails', label: 'BuyInDetails' },
    { value: 'BuyInExecDetails', label: 'BuyInExecDetails' },
  ]

  const allocationOption = [
    { value: 'ALL', label: 'All' },
    { value: 'Stock Loan', label: 'Stock Loan' },
    { value: 'Correspondent', label: 'Correspondent' },
    { value: 'Customer', label: 'Customer' },
    { value: 'Broker', label: 'Broker' },
    { value: 'Other', label: 'Other' },
  ]

  const dispatch = useDispatch()
  const handleSearch = () => {
    setShowTable(true)
    const fetchData = async () => {
      await dispatch(
        getRegShoHistory(searchParams.source, {
          ...searchParams,
          cusip: searchParams.cusip === '' ? "" : searchParams.cusip,
          symbol: searchParams.symbol === '' ? "" : searchParams.symbol,
          source: searchParams.source === '' ? null : searchParams.source,
          pageName: searchParams.pageName === '' ? null : searchParams.pageName,
          allocationType: searchParams.allocationType,
        })
      )
    }

    fetchData()
  }
  const ExcelDownload = async() => {
      await dispatch(
        getRegShoHistoryExcel( {
          ...searchParams,
          cusip: searchParams.cusip === '' ? "" : searchParams.cusip,
          symbol: searchParams.symbol === '' ? "" : searchParams.symbol,
          source: searchParams.source === '' ? null : searchParams.source,
          pageName: searchParams.pageName === '' ? null : searchParams.pageName,
          allocationType: searchParams.allocationType,
        })
      )
  }
  return (
    <StyledContainer>
      <StyledSelectWrapper>
        <div>
          <ProppedLabel>Source</ProppedLabel>
          <SelectDropdown
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                source: v,
              }))
            }}
            defaultValue={{ value: 'Beta', label: 'Beta' }}
            options={[
              { value: 'Beta', label: 'Beta' },
              { value: 'P3', label: 'P3' },
            ]}
          />
        </div>
        <div style={{ width: '15%' }}>
          <ProppedLabel>PageName</ProppedLabel>
          <SelectDropdown
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                pageName: v,
              }))
              setShowTable(false)
            }}
            defaultValue={{
              value: 'CusipLevelSummaryDetails',
              label: 'CusipLevelSummary',
            }}
            options={pageNameOption}
          />
        </div>
        <div style={{ width: '12%' }}>
          <ProppedLabel>AllocationType</ProppedLabel>
          <SelectDropdown
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                allocationType: v,
              }))
              setShowTable(false)
            }}
            defaultValue={{ value: 'all', label: 'All' }}
            options={allocationOption}
          />
        </div>
        <div>
          <ProppedLabel>From Date</ProppedLabel>
          <ProppedDatePicker
            value={searchParams.reportDateFrom}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                reportDateFrom: v,
              }))
            }}
          />
        </div>
        <div>
          <ProppedLabel>To Date</ProppedLabel>
          <ProppedDatePicker
            value={searchParams.reportDateTo}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                reportDateTo: v,
              }))
            }}
          />
        </div>
        <div>
          <ProppedLabel>CUSIP</ProppedLabel>
          <ProppedTextbox
            value={searchParams.cusip}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                cusip: v,
              }))
            }}
            placeHolderText={'Cusip'}
          />
        </div>
        <div>
          <ProppedLabel>Symbol</ProppedLabel>
          <ProppedTextbox
            value={searchParams.symbol}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                symbol: v,
              }))
            }}
            placeHolderText={'Symbol'}
          />
        </div>
        <ButtonWrapper>
          <div >
            <StyledRightContainer>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                margin={'0px 6px'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
            </StyledRightContainer>
          </div>
        </ButtonWrapper>
        <ExcelWrapper 
        onClick={()=>{
          ExcelDownload()
        }}  
        >
        <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label>Excel</Label>
          </WDStyledButtonText>
          </ExcelWrapper>
      </StyledSelectWrapper>
      
    </StyledContainer>
  )
}

export default RegShoHistorySearchPanel
