export const getProductColumns = (
  previousMonth: string,
  currentMonth: string
) => {
  return [
    {
      Header: 'Request Type',
      accessor: 'requestType',
    },
    {
      Header: () => <div>Previous Month ({previousMonth}) </div>,

      accessor: 'previousMonthCount',
    },
    {
      Header: () => <div>Current Month ({currentMonth}) </div>,
      accessor: 'currentMonthCount',
    },
  ]
}
