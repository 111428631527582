import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const RootContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  color: ${COLORS.Background.DarkCyanBlueBackground};
  background-color: 'transparent';
`
export const PopUpStyleLoader = styled.div`
  background: #fcfdff;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`
export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${COLORS.Background.Primary};

  > * {
    margin-right: 16px;
  }
`
export const LottieWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
export const FilterContainer = styled.div`
  align-items: center;
  border: 1px solid ${COLORS.Background.StrongBackgound};
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  padding: 16px 25px 4px 25px;
`
export const FirstContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const LeftSpace = styled.div`
  margin-left: 1.5%;
  position: relative;
`
export const ApprovedBy = styled.div`
  margin: 1.5%;
  position: relative;
`
export const FilterLabelWrapper = styled.div`
  margin-bottom: 20px;
  margin-left: 1.5%;
`
export const ApplyResetButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: 1.5%;
`
export const ApproveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* gap: 10px; */
  margin-left: 10px;
`

export const PopupCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: -125px;
  padding: 8px 16px;
  gap: 8px;
  position: absolute;
  width: 18%;
  height: 172px;
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`

export const PopUpStyleUpload = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`

export const UploadWrapper = styled.div`
  border: 2px dashed ${() => COLORS.UI.PrimaryBorderStrong};
  border-radius: 8px;
  padding: 1.5rem 0;
  display: flex;
  margin-top: 0.5rem;
  &.file-uploaded {
    background-color: ${() => COLORS.Background.Primary};
  }
`

export const FileUploadInput = styled.input``

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`
export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 24px 0px 8px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${COLORS.Background.Primary};
  > * {
    margin-right: 16px;
  }
`

export const MarginLeftAutoSpace = styled.div`
  margin-left: auto;
`

export const SvgButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-left: auto;
`
export const FilterSVGWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 7px;
`
export const CancelButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`

export const PopUpStyleContent = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  // height: 60%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  z-index: 999;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`