import { useEffect, useState } from "react";
import { FeatureDescription, FeatureTile, IconConatainer, SummaryCardContainerTradeReconcilation, TitleContainer, TradeReconInfoTile } from './styles'
import { authApiCall } from "../../../utils/api";
import { SvgEye } from "../../svg/SvgEye";
import { SvgExclamation } from "../../svg/logo/SvgExclamation";
import SvgWrench from "../../svg/SvgWrench";
import { formattingDate, formattingNumber } from "../../../utils/globalFunctions";
import { useNavigate } from "react-router-dom";
import { configJSONDashboard } from "../../../utils/config";
import Image from '../../image/Image';
import { Icons } from "../../../shared/GlobalStyle";
import SvgResearches from "../../svg/SvgResearches";


const TradeReconcilation = (props: any) => {
  const navigate = useNavigate();

  const addBreadCrumb = async (date: any) => {
    const breadCrumbArray = [
      {
        key: "Dashboard",
        link: "/tradeReconDashboard",
        active: false
      }
    ]
    sessionStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbArray));
    navigate("/tradeReconDashboard", {
      state: {
        passProps: {}
      },
    });
  }

  return (
    <SummaryCardContainerTradeReconcilation onClick={addBreadCrumb}>
      <TitleContainer>Trade Reconcilation
      </TitleContainer>
      <IconConatainer>
        <SvgResearches fillColor={"#FFFFFF"} />
      </IconConatainer>
      <FeatureDescription>
        Manage and resolve streetside clearing and ex-clearing trade breaks
      </FeatureDescription>
    </SummaryCardContainerTradeReconcilation>
  )
}

export default TradeReconcilation
