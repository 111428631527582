import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'

export const RegShoColumnsL1ALL = (
  setMailList: (regSHOId: string,allocation_ID:string) => Promise<void>,
  handleViewComment: (
    source: string,
    selectedSummaryTableCUSIP: string,
    failDate: string
  ) => Promise<void>
) => {
  return [
    {
      Header: 'Security Description',
      accessor: 'securityDescription',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'ReportDate',
      accessor: 'reportDate',
    },
    {
      Header: 'FailDate',
      accessor: 'failDate',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'tn',
      accessor: 'tn',
    },
    {
      Header: 'ThresholdIndex',
      accessor: 'thresholdIndex',
    },
    {
      Header: 'Cusip',
      accessor: 'cusiP_Number',
    },
    {
      Header: 'BorrowDesc',
      accessor: 'borrowDesc',
    },
    {
      Header: 'FailQTY',
      accessor: 'failQTY',
    },
    {
      Header: 'currentNew',
      accessor: 'currentNew',
    },
    {
      Header: 'currentMade',
      accessor: 'currentMade',
    },
    {
      Header: 'Security Number',
      accessor: 'securityNumber',
    },
    {
      Header: 'earlySalesReleaseInd',
      accessor: 'earlySalesReleaseInd',
    },
    {
      Header: 'currentMisc',
      accessor: 'currentMisc',
    },
    {
      Header: 'correspondentAllocation',
      accessor: 'correspondentAllocation',
    },
    {
      Header: 'brokerAllocation',
      accessor: 'brokerAllocation',
    },
    {
      Header: 'customerAllocation',
      accessor: 'customerAllocation',
    },
    {
      Header: 'stockLoanAllocation',
      accessor: 'stockLoanAllocation',
    },
    {
      Header: 'otherAllocation',
      accessor: 'otherAllocation',
    },
    {
      Header: 'Buy-In Execution Date',
      accessor: 'nextBuyinDate',
    },
    {
      Header: 'Buy-In Quantity',
      accessor: 'buyInAmount',
    },
    {
      Header: 'Today204Need',
      accessor: 'today204Need',
    },
    {
      Header: 'NetItemToWorkToday',
      accessor: 'netItemToWorkToday',
    },
    {
      Header: 'Comments',
      Cell: (table: { row: { original: any } }) => {
        const data = table.row.original
        return (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Primarytext}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
            onClick={() => {
              handleViewComment(data.source, data.cusiP_Number, data.failDate)
            }}
          >
            view Comments
          </Button>
        )
      },
    },
    {
      Header: 'Email',
      Cell: (table: { row: { original: any } }) => {
        return (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Primarytext}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
            onClick={() => {
              setMailList(table.row.original.regSHO_ID,table.row.original.allocation_ID)
            }}
          >
            View Mail
          </Button>
        )
      },
    },
  ]
}

export const RegShoColumnsL2All = (
  setMailList: (regSHOId: string,allocation_ID:string) => Promise<void>
) => {
  return [
    {
      Header: 'Security Description',
      accessor: 'securityDescription',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'ReportDate',
      accessor: 'reportDate',
    },
    {
      Header: 'FailDate',
      accessor: 'failDate',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'tn',
      accessor: 'tn',
    },
    {
      Header: 'ThresholdIndex',
      accessor: 'thresholdIndex',
    },
    {
      Header: 'Cusip',
      accessor: 'cusiP_Number',
    },
    {
      Header: 'BorrowDesc',
      accessor: 'borrowDesc',
    },
    {
      Header: 'FailQTY',
      accessor: 'failQTY',
    },
    {
      Header: 'currentNew',
      accessor: 'currentNew',
    },
    {
      Header: 'currentMade',
      accessor: 'currentMade',
    },
    {
      Header: 'Security Number',
      accessor: 'securityNumber',
    },
    {
      Header: 'earlySalesReleaseInd',
      accessor: 'earlySalesReleaseInd',
    },
    {
      Header: 'currentMisc',
      accessor: 'currentMisc',
    },
    {
      Header: 'correspondentAllocation',
      accessor: 'correspondentAllocation',
    },
    {
      Header: 'brokerAllocation',
      accessor: 'brokerAllocation',
    },
    {
      Header: 'customerAllocation',
      accessor: 'customerAllocation',
    },
    {
      Header: 'stockLoanAllocation',
      accessor: 'stockLoanAllocation',
    },
    {
      Header: 'otherAllocation',
      accessor: 'otherAllocation',
    },
    {
      Header: 'Buy-In Execution Date',
      accessor: 'nextBuyinDate',
    },
    {
      Header: 'Buy-In Quantity',
      accessor: 'buyInAmount',
    },
    {
      Header: 'Today204Need',
      accessor: 'today204Need',
    },
    {
      Header: 'NetItemToWorkToday',
      accessor: 'netItemToWorkToday',
    },
    {
      Header: 'Shares Allocated',
      accessor: 'allocateQTY',
    },
    {
      Header: 'Account',
      accessor: 'allocateAccount',
    },
    {
      Header: 'description',
      accessor: 'description',
    },
    {
      Header: 'recalDate',
      accessor: 'recalDate',
    },
    {
      Header: 'recallAmount',
      accessor: 'recallAmount',
    },
    {
      Header: 'longShort',
      accessor: 'longShort',
    },
    {
      Header: 'Account Number',
      accessor: 'acct_Nbr',
    },
    {
      Header: 'tradeNetQuantity',
      accessor: 'tradeNetQuantity',
    },
    {
      Header: 'sR_Prior_TD_QTY',
      accessor: 'sR_Prior_TD_QTY',
    },
    {
      Header: 'sR_Current_TD_QTY',
      accessor: 'sR_Current_TD_QTY',
    },
    {
      Header: 'sR_Net_SD_QTY',
      accessor: 'sR_Net_SD_QTY',
    },
    {
      Header: 'closeOutStatus',
      accessor: 'closeOutStatus',
    },
    {
      Header: 'dateToBuyIn',
      accessor: 'dateToBuyIn',
    },
    {
      Header: 'buyInNotice',
      accessor: 'buyInNotice',
    },
    {
      Header: 'buyInTicketNumber',
      accessor: 'buyInTicketNumber',
    },
    {
      Header: 'buyInComment',
      accessor: 'buyInComment',
    },
    {
      Header: 'buyInNoticeDate',
      accessor: 'buyInNoticeDate',
    },
    {
      Header: 'buyInQty',
      accessor: 'buyInQty',
    },
    {
      Header: 'offset',
      accessor: 'offset',
    },
    {
      Header: 'Email',
      Cell: (table: { row: { original: any } }) => {
        return (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Primarytext}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
            onClick={() => {
              setMailList(table.row.original.regSHO_ID,table.row.original.allocation_ID)
            }}
          >
            View Mail
          </Button>
        )
      },
    },
  ]
}

export const RegShoColumnsL3All = (
  setMailList: (regSHOId: string,allocation_ID:string) => Promise<void>
) => {
  return [
    {
      Header: 'Security Description',
      accessor: 'securityDescription',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'ReportDate',
      accessor: 'reportDate',
    },
    {
      Header: 'FailDate',
      accessor: 'failDate',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'tn',
      accessor: 'tn',
    },
    {
      Header: 'ThresholdIndex',
      accessor: 'thresholdIndex',
    },
    {
      Header: 'Cusip',
      accessor: 'cusiP_Number',
    },
    {
      Header: 'BorrowDesc',
      accessor: 'borrowDesc',
    },
    {
      Header: 'FailQTY',
      accessor: 'failQTY',
    },
    {
      Header: 'currentNew',
      accessor: 'currentNew',
    },
    {
      Header: 'currentMade',
      accessor: 'currentMade',
    },
    {
      Header: 'Security Number',
      accessor: 'securityNumber',
    },
    {
      Header: 'earlySalesReleaseInd',
      accessor: 'earlySalesReleaseInd',
    },
    {
      Header: 'currentMisc',
      accessor: 'currentMisc',
    },
    {
      Header: 'correspondentAllocation',
      accessor: 'correspondentAllocation',
    },
    {
      Header: 'brokerAllocation',
      accessor: 'brokerAllocation',
    },
    {
      Header: 'customerAllocation',
      accessor: 'customerAllocation',
    },
    {
      Header: 'stockLoanAllocation',
      accessor: 'stockLoanAllocation',
    },
    {
      Header: 'otherAllocation',
      accessor: 'otherAllocation',
    },
    {
      Header: 'Buy-In Execution Date',
      accessor: 'nextBuyinDate',
    },
    {
      Header: 'Buy-In Quantity',
      accessor: 'buyInAmount',
    },
    {
      Header: 'Today204Need',
      accessor: 'today204Need',
    },
    {
      Header: 'NetItemToWorkToday',
      accessor: 'netItemToWorkToday',
    },
    {
      Header: 'Shares Allocated',
      accessor: 'allocateQTY',
    },
    {
      Header: 'Account',
      accessor: 'allocateAccount',
    },
    {
      Header: 'description',
      accessor: 'description',
    },
    {
      Header: 'recalDate',
      accessor: 'recalDate',
    },
    {
      Header: 'recallAmount',
      accessor: 'recallAmount',
    },
    {
      Header: 'longShort',
      accessor: 'longShort',
    },
    {
      Header: 'Account Number',
      accessor: 'acct_Nbr',
    },
    {
      Header: 'tradeNetQuantity',
      accessor: 'tradeNetQuantity',
    },
    {
      Header: 'sR_Prior_TD_QTY',
      accessor: 'sR_Prior_TD_QTY',
    },
    {
      Header: 'sR_Current_TD_QTY',
      accessor: 'sR_Current_TD_QTY',
    },
    {
      Header: 'sR_Net_SD_QTY',
      accessor: 'sR_Net_SD_QTY',
    },
    {
      Header: 'closeOutStatus',
      accessor: 'closeOutStatus',
    },
    {
      Header: 'recallDate',
      accessor: 'recallDate',
    },
    {
      Header: 'recallShare',
      accessor: 'recallShare',
    },
    {
      Header: 'BuyInExecTradeRef',
      accessor: 'buyInExecTradeRef',
    },
    {
      Header: 'BuyInExecTradeDate',
      accessor: 'buyInExecTradeDate',
    },
    {
      Header: 'BuyInExecSettleDate',
      accessor: 'buyInExecSettleDate',
    },
    {
      Header: 'BuyInExecCounterParty',
      accessor: 'buyInExecCounterParty',
    },
    {
      Header: 'BuyInExecTicker',
      accessor: 'buyInExecTicker',
    },
    {
      Header: 'BuyInExecShares',
      accessor: 'buyInExecShares',
    },
    {
      Header: 'BuyInExecPrice',
      accessor: 'buyInExecPrice',
    },
    {
      Header: 'BuyInExecAmount',
      accessor: 'buyInExecAmount',
    },
    {
      Header: 'Email',
      Cell: (table: { row: { original: any } }) => {
        return (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Primarytext}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
            onClick={() => {
              setMailList(table.row.original.regSHO_ID,table.row.original.allocation_ID)
            }}
          >
            View Mail
          </Button>
        )
      },
    },
  ]
}

export const RegShoColumnsBuyIn = (
  setMailList: (regSHOId: string,allocation_ID:string) => Promise<void>
) => {
  return [
    {
      Header: 'Cusip',
      accessor: 'cusiP_Number',
    },
    {
      Header: 'Security Description',
      accessor: 'securityDescription',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'BorrowDesc',
      accessor: 'borrowDesc',
    },
    {
      Header: 'ThresholdIndex',
      accessor: 'thresholdIndex',
    },
    {
      Header: 'BuyInNotice date',
      accessor: 'buyInNoticeDate',
    },
    {
      Header: 'BuyInQuantity',
      accessor: 'buyInQty',
    },
    {
      Header: 'DateTo Buyin/Execute',
      accessor: 'dateToBuyIn',
    },
    {
      Header: 'BuyIn Ticket',
      accessor: 'buyInTicketNumber',
    },
    {
      Header: 'OFFSET',
      accessor: 'offset',
    },
    {
      Header: 'allocationType',
      accessor: 'allocationType',
    },
    {
      Header: 'Comments',
      accessor: 'buyInComment',
    },
    {
      Header: 'Email',
      Cell: (table: { row: { original: any } }) => {
        return (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Primarytext}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
            onClick={() => {
              setMailList(table.row.original.regSHO_ID,table.row.original.allocation_ID)
            }}
          >
            View Mail
          </Button>
        )
      },
    },
  ]
}
