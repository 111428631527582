import { LottieWrapper, TitleContainer, TopCorrespondent } from './styles'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import Label from '../../../label/Label'
import { useDispatch, useSelector } from 'react-redux'
import {
  TopCorrespondentsSelector,
  getTopCorrespondents,
  isLoadingSelector,
} from '../../../../store/dash-board/settlement'
import DoughnutChart from '../../../doughnutChart/DoughnutChart'
import SelectDropdown from '../../../selectDropdown/SelectDropdown'
import { Colors } from '../../../../shared/styles'
import { async } from 'q'
import {
  formattingDate,
  formattingDateWithTime,
} from '../../../../utils/globalFunctions'
import { ChartData, ChartItem } from 'chart.js'
import { useNavigate } from 'react-router-dom'

const TopCorrespondents = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const topCorrenspondentDetails = useSelector(TopCorrespondentsSelector)
  const [name, setName] = useState<any>([])
  const [quantity, setQuantity] = useState<any>([])
  const [startDate] = useState(new Date())
  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  const loading = useSelector(isLoadingSelector)
  const [chart, setChart] = useState<boolean>(false)
  const [system, setSystem] = useState('Beta')
  const [product, setProduct] = useState('EQ')
  const systemOption = [
    { value: 'Beta', label: 'Beta' },
    { value: 'P3', label: 'P3' },
  ]
  const productOption = [
    { value: 'EQ', label: 'EQ' },
    { value: 'FI', label: 'FI' },
    { value: 'All', label: 'All' },
  ]

  useEffect(() => {
    const tempName: any = []
    const tempQuantity: any = []
    topCorrenspondentDetails.contraOrCorrespondentCountListModel &&
      topCorrenspondentDetails.contraOrCorrespondentCountListModel.map(
        (value: any) => {
          tempName.push(value.accountName)
        }
      )
    topCorrenspondentDetails.contraOrCorrespondentCountListModel &&
      topCorrenspondentDetails.contraOrCorrespondentCountListModel.map(
        (value: any) => {
          tempQuantity.push(value.failCount)
        }
      )
    setName(tempName)
    setQuantity(tempQuantity)
  }, [topCorrenspondentDetails.contraOrCorrespondentCountListModel])

  useEffect(() => {
    const getTopCorrespond = async () => {
      await dispatch(getTopCorrespondents(system, product))
    }
    setChart(true)
    getTopCorrespond()
  }, [])

  const onSystemChange = async (props: string) => {
    await setSystem(props)
    const producValue = product === 'All' ? null : product
    await dispatch(getTopCorrespondents(props, producValue))
  }
  const onProductChange = async (props: string) => {
    await setProduct(props)
    const producValue = props === 'All' ? null : props
    await dispatch(getTopCorrespondents(system, producValue))
  }
  const chartClickNavigation = (e: any, items: any) => {
    if (name[items[0]?.index]) {
      navigate('/settlementContraCorres', {
        state: {
          type: 'Correspondent',
          system: system,
          name: name[items[0]?.index],
          accounts: name,
          product: product,
        },
      })
    }
  }

  return (
    <>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <TopCorrespondent>
        <div style={{ margin: '10px', display: 'flex' }}>
          <TitleContainer>{props.widgetDescription}</TitleContainer>
          <div style={{ margin: '10px', display: 'flex' }}>
            <SelectDropdown
              defaultValue={{ value: 'Beta', label: 'Beta' }}
              borderRadius={'4px'}
              options={systemOption}
              onChange={(e) => {
                onSystemChange(e)
              }}
            ></SelectDropdown>
            <div style={{ marginLeft: '10px' }}>
              <SelectDropdown
                defaultValue={{ value: 'EQ', label: 'EQ' }}
                borderRadius={'4px'}
                options={productOption}
                onChange={(e) => {
                  onProductChange(e)
                }}
              ></SelectDropdown>
            </div>
          </div>
        </div>
        {name.length ? (
          <DoughnutChart
            legendLabels={name}
            title={'BetaContra'}
            dataValues={quantity}
            bgColors={[
              Colors.Primary90,
              Colors.Blue1,
              Colors.Primary800,
              Colors.PrimaryText,
              Colors.Primary70,
              Colors.Primary80,
              Colors.Primary50,
              Colors.Primary60,
              Colors.Primary20,
              Colors.Primary10,
            ]}
            borderColors={[
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
            ]}
            borderWidth={0}
            onClick={chartClickNavigation}
          />
        ) : (
          <span>No Data Found</span>
        )}
        {name.length ? (
          <span style={{ fontSize: '10px' }}>
            {' '}
            Data as of:{' '}
            {topCorrenspondentDetails.currentDate
              ? formattingDateWithTime(topCorrenspondentDetails.currentDate)
              : null}
          </span>
        ) : null}
      </TopCorrespondent>
    </>
  )
}

export default TopCorrespondents
