/* eslint-disable @typescript-eslint/no-explicit-any */
import TabList from '../../components/tab/TabList'
import { RegSHOTabDetails } from './RegSHOModels'

interface RegSHOTabsProps {
  commonTabProps: {
    regSHOId: number
    source: string
    fromDate: string
    toDate: string
    dropdownCusip: string
    dropdownSymbols: string
    applySearchClick: boolean
    selectedSummaryTableCUSIP: string
    selectedCUSIPReportDate: string
    allocationType: string
  }
  setCommonTabProps: React.Dispatch<React.SetStateAction<any>>
  filterData: { id: string; value: string | string[] | undefined | null }[]
  setSummaryFilters: React.Dispatch<React.SetStateAction<any>>
}
const RegSHOTabs = ({
  commonTabProps,
  setCommonTabProps,
  filterData,
  setSummaryFilters,
}: RegSHOTabsProps) => {
  const disableTab = commonTabProps.selectedSummaryTableCUSIP == ''
  return (
    <>
      <div style={{ margin: 'auto' }}>
        <TabList
          tabs={RegSHOTabDetails}
          commonTabProps={commonTabProps}
          setCommonTabProps={setCommonTabProps}
          isGoToFirst={commonTabProps.applySearchClick}
          isDisabled={disableTab}
          filterData={filterData}
          setFilters={setSummaryFilters}
        />
      </div>
    </>
  )
}

export default RegSHOTabs
