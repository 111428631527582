import { useEffect, useState } from 'react'
import { authApiCall } from '../../../../utils/api'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles'
import {
  LottieWrapper,
  TitleSubContainer,
  RootContainerTable,
  SummaryCardContainerTradeBreakCountT1,
} from './styles'
import Loader from '../../../../assets/lottiefiles/loader.json'
import Label from '../../../label/Label'
import Lottie from 'lottie-react'
import CreateTable from '../createTable'

const P3SubmissionLOB = (props:{
  selectedDate:string
}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any>([])
  useEffect(() => {
    setLoading(true)
    const getLOB = async () => {
      setLoading(true)
      try {
        const { data, status } = await authApiCall.get(
          `opsdashboardapi/OpsDashboard/Get_CAISTop3LOBErrorCount?source=P3&reportDate=${props?.selectedDate}`
        )
        if (status === 200) {
          setData(data)
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
      }
    }
    getLOB()
  }, [props?.selectedDate])

  const columns = [
    { Header: 'LOB', accessor: 'lob' },
    { Header: 'RecordType', accessor: 'recordType' },
    { Header: 'ErrorCount', accessor: 'errorCount' },
  ]

  const Table = (props: any) => {
    return (
      <RootContainerTable minHeight={'130px'} fontSize={'11px'}>
        <CreateTable
          data={data ? data.slice(0, 4) : []}
          columns={props.columns}
        />
      </RootContainerTable>
    )
  }

  return (
    <>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <SummaryCardContainerTradeBreakCountT1>
        <TitleSubContainer>Top 4 Submission P3 LOBs</TitleSubContainer>

        <Table columns={columns} />
      </SummaryCardContainerTradeBreakCountT1>
    </>
  )
}

export default P3SubmissionLOB
