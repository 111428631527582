import { getAccessFlag } from "../../utils/globalFunctions"

export const numberFilter = (rows: any[], id: any, filterValue: any) => {
  const trimmedFilterValue = filterValue.trim()
  if (
    trimmedFilterValue === '' ||
    trimmedFilterValue === '>' ||
    trimmedFilterValue === '<' ||
    trimmedFilterValue === '>=' ||
    trimmedFilterValue === '<='
  )
    return rows

  return (
    rows &&
    rows.filter((row: any) => {
      const rowValue = Number(row?.values[id]?.toString().replace(/,/g, ''))

      if (filterValue.startsWith('>=')) {
        return rowValue >= Number(filterValue.substring(2))
      } else if (filterValue.startsWith('>')) {
        return rowValue > Number(filterValue.substring(1))
      } else if (filterValue.startsWith('<=')) {
        return rowValue <= Number(filterValue.substring(2))
      } else if (filterValue.startsWith('<')) {
        return rowValue < Number(filterValue.substring(1))
      } else {
        return rowValue === Number(filterValue)
      }
    })
  )
}

export const textArrayFilter = (rows: any[], id: any, filterValue: any) => {
  if (filterValue.length === 0) return rows

  return rows.filter((row) => {
    const rowValue = row.values[id]
    return !filterValue.includes(rowValue)
  })
}

export const staticOptionsFilter = (rows: any[], id: any, filterValue: any) => {
  if (filterValue.length === 0 || filterValue === 'all') return rows

  return rows.filter((row) => {
    const rowValue = row.values[id]
    if (filterValue === 'actioned') {
      return rowValue === true
    } else if (filterValue === 'notActioned') {
      return !rowValue
    }
  })
}

export const DisabledFunction = (
  source: string,
  createdBy: string,
  createDate: string,
  approveFlag?:boolean
) => {
  const writeExecuteFlag = getAccessFlag(['SS-RWX', 'OM-RWX'])
  
  const currentDate = new Date().toISOString().split('T')[0]
  const createdDate =
    createDate?.length > 0
      ? new Date(createDate).toISOString().split('T')[0]
      : currentDate
      if(approveFlag){
        return true
      }
      if(writeExecuteFlag === false){
        return true
      }
  if (createdBy === 'System') {
    return createdBy === 'System'
  } else {
    return createdDate < currentDate
  }
}
