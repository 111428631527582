import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const RootContainer = styled.div`
  margin: -8px 0px 8px 0px;
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  // background: #E5E5E5;
  /* background-color: orange; */
`
export const PageHeader = styled.div`
  width: 100%;
  
  
`
export const HeadingText = styled.div`
  color: #1E3A8A;  
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  display: flex;
  align-items: center;
  margin: 30px ;
  white-space: nowrap;
`
export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  cursor: pointer;
  align-items: center;
  padding: 25px;
`

export const StyledBodyContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 95%;
  margin: 0px 50px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`

export const StyledFilterPanel = styled.div`

  background: #F8FAFC;
  /* Elevation/Elevation-low */
  width: 100%;
  border-radius: 8px;
  display: flex;
`

export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 24px 24px 26px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: grid;
  grid-template-columns: repeat(4 ,1fr);

  align-items: center;
  
  > * {
    margin-right: 16px;
  }
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin-left: auto;
  align-items: right;
  line-height: 24px;
`
export const StyledButtonRoot= styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: right;
  line-height: 24px;
  padding:10px;
  margin: 15px;
`
export const Col1Container = styled.div`
  grid-column: span 1;
`
export const Col2Container = styled.div`
  grid-column: span 2;
`
export const Col3Container = styled.div`
  grid-column: span 3;
`
export const Col4Container = styled.div`
  grid-column: span 4;
  // display: contents;
`

export const Col5Container = styled.div`
  grid-column: span 5;
  // display: contents;
`
export const Col6Container = styled.div`
  grid-column: span 6;
`
export const Col7Container = styled.div`
  grid-column: span 7;
`
export const Col8Container = styled.div`
  grid-column: span 8;
`
export const ColDiv = styled.div`
  display: inline-block;
  margin: 10px;
`
export const ColFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: fit-content;
`

export const StyledPanelSelect = styled.div`
  width: 100%;
  
  padding: 20px 30px 20px 30px;
  border-radius: 0px 0px 8px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // background: #fcfdff;
`

export const StyledPanelHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  /* background: #fcfdff; */
  /* color: #192638; */
  // color: #ffffff;
  // background: #1f5eb7;

  /* padding: 32px 32px 0px 32px; */
  padding: 16px 24px 16px;
  border-radius: 8px 8px 0px 0px;
`

export const StyledPanelHeadTitle = styled.div`
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 48px;
text-align: left;
color: #0F172A;
`

export const StyledFilter = styled.div`
background: #FFFFFF;
/* Elevation/Elevation-low */
width: 100%;
box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
border-radius: 8px;
`
export const StyledFilterWrapper = styled.div`
  width: 100%;
  padding: 24px 24px 26px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  > * {
    margin-right: 16px;
  }
`

export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`

export const PageBody = styled.div`
background: #F8FAFC;
width: 100%;

`
export const PopUpStyleLoader = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`
export const LottieWrapper = styled.div`
  // position: fixed;
  // top: 70%;
  // left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`