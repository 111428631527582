import styled from 'styled-components'
import { Backgrounds, Colors } from '../../../shared/styles'
import { COLORS } from '../../../theme/Colors'

export const RootContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`
export const FilterContainer = styled.div`
  align-items: center;
  width: 97.3vw;
  margin-top: 16px;
  border: 1px solid ${COLORS.Background.StrongBackgound};
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  padding: 16px 25px 4px 25px;
`
export const FirstContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const LeftSpace = styled.div`
  margin-left: 1.5%;
  position: relative;
`
export const MonthPickerLabelWrapper = styled.div`
  margin-bottom: 20px;
`
export const DateWrapper = styled.div`
  & .react-datepicker-wrapper .react-datepicker__input-container input {
    border-radius: 8px;
    height: 29px;
    border: 1px solid ${COLORS.Background.Border};
    padding: 0px 0px 0px 9px;
    width: 100px;
    color: ${COLORS.Text.TextWeak};
    font-weight: bold;
    background: ${COLORS.Background.Primary};
  }
`
export const MarginLeftAutoSpace = styled.div`
  margin-left: auto;
`
export const FileUploadPopUpStyleWrapper = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`
export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${COLORS.Background.Primary};

  > * {
    margin-right: 16px;
  }
`
export const SvgButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`
export const UploadWrapper = styled.div`
  border: 2px dashed ${() => COLORS.UI.PrimaryBorderStrong};
  border-radius: 8px;
  padding: 1.5rem 0;
  display: flex;
  margin-top: 0.5rem;
  &.file-uploaded {
    background-color: ${() => COLORS.Background.Primary};
  }
`
export const FileUploadInput = styled.input``

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`
export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 24px 0px 8px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${COLORS.Background.Primary};
  > * {
    margin-right: 16px;
  }
`
export const SecondContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`
export const FilterLabelWrapper = styled.div``

export const ApplyResetButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  float: right;
`

export const ThirdContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  float: right;
  margin-bottom: 20px;
`
export const RefreshDownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  width: 100%;
`
export const FilterContainerHistory = styled.div`
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
  border: 1px solid ${COLORS.Background.StrongBackgound};
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  padding: 16px 25px 4px 25px;
`
export const MarginTopSpace = styled.div`
  margin-top: 16px;
`
export const BackButton = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
`
export const HistoryDownload = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ButtonIconWrapper = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 25px;
`
export const StyledTableContainer = styled.div`
  width: 100%;
  padding: 8px 16px 24px 16px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
export const PopUpStyleLoader = styled.div`
  background: #fcfdff;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`
export const LottieWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
