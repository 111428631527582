import styled from 'styled-components'

import { BorderColors, Backgrounds } from '../../shared/styles'

interface InputFunc {
  (value: string): void | string | boolean | number
}
export interface CheckboxProps {
  title?: string
  name?: string
  checkBoxId?: string
  disabled?: boolean
  checked?: any
  defaultChecked?:any
  indeterminate?: boolean
  onChange?: InputFunc
  required?: boolean
  buttonCheckbox?: boolean
  toggleCheckbox?: boolean
  ToggleButtonPadding?: string
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledCheckbox = styled.input<CheckboxProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  margin: 0rem 0.625rem;
  border: 0.0625rem solid ${BorderColors.Primary70};
  accent-color: ${Backgrounds.Primary50};
  outline: none;
  &:disabled {
    background: ${Backgrounds.Primary80};
    border-radius: 4px;
    cursor: no-drop;
  }
  &:checked {
    accent-color: ${Backgrounds.Primary50};
    border-radius: 4px;
  }
`
export const StyledLabel = styled.label<CheckboxProps>`
  &:disabled {
    color: #a7afbc;
    cursor: no-drop;
  }
`
export const StyledButtonLabel = styled.label<CheckboxProps>`
  border: ${(props: CheckboxProps) =>
    props.checked ? '1px solid #2563EB' : '1px solid #2563EB'};
  border-radius: 20px;
  padding: 4px 16px 4px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  background: ${(props: CheckboxProps) =>
    props.checked ? '#2563EB' : '#FCFDFF'};
  color: ${(props: CheckboxProps) => (props.checked ? '#FFFFFF;' : '#2563EB')};
`

export const ToggleButtonLabel = styled.label<CheckboxProps>`
  border: ${(props: CheckboxProps) =>
    props.checked ? '1px solid #2563EB' : '1px solid #2563EB'};
  border-radius: ${(props: CheckboxProps) =>
    props.ToggleButtonPadding ? props.ToggleButtonPadding : ''};
  padding: 4px 16px 4px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  background: ${(props: CheckboxProps) =>
    props.checked ? '#2563EB' : '#FCFDFF'};
  color: ${(props: CheckboxProps) => (props.checked ? '#FFFFFF;' : '#2563EB')};
`
