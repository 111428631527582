import { ISvg } from '../ISvg'

const SvgCancel = ({ fillColor }: ISvg) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93996 7.9998L11.8066 5.1398C11.9322 5.01426 12.0027 4.844 12.0027 4.66646C12.0027 4.48893 11.9322 4.31867 11.8066 4.19313C11.6811 4.0676 11.5108 3.99707 11.3333 3.99707C11.1558 3.99707 10.9855 4.0676 10.86 4.19313L7.99996 7.0598L5.13996 4.19313C5.01442 4.0676 4.84416 3.99707 4.66663 3.99707C4.48909 3.99707 4.31883 4.0676 4.19329 4.19313C4.06776 4.31867 3.99723 4.48893 3.99723 4.66646C3.99723 4.844 4.06776 5.01426 4.19329 5.1398L7.05996 7.9998L4.19329 10.8598C4.13081 10.9218 4.08121 10.9955 4.04737 11.0767C4.01352 11.158 3.99609 11.2451 3.99609 11.3331C3.99609 11.4211 4.01352 11.5083 4.04737 11.5895C4.08121 11.6708 4.13081 11.7445 4.19329 11.8065C4.25527 11.8689 4.329 11.9185 4.41024 11.9524C4.49148 11.9862 4.57862 12.0037 4.66663 12.0037C4.75463 12.0037 4.84177 11.9862 4.92301 11.9524C5.00425 11.9185 5.07798 11.8689 5.13996 11.8065L7.99996 8.9398L10.86 11.8065C10.9219 11.8689 10.9957 11.9185 11.0769 11.9524C11.1581 11.9862 11.2453 12.0037 11.3333 12.0037C11.4213 12.0037 11.5084 11.9862 11.5897 11.9524C11.6709 11.9185 11.7446 11.8689 11.8066 11.8065C11.8691 11.7445 11.9187 11.6708 11.9526 11.5895C11.9864 11.5083 12.0038 11.4211 12.0038 11.3331C12.0038 11.2451 11.9864 11.158 11.9526 11.0767C11.9187 10.9955 11.8691 10.9218 11.8066 10.8598L8.93996 7.9998Z"
        fill={fillColor}
      />
    </svg>
  )
}
export default SvgCancel
