import styled from 'styled-components'
import { COLORS } from '../../../../theme/Colors'

export const GoToPageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`
export const NextPreviousWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  > * {
    margin-right: 10px !important;
  }
`
export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 28px;
  align-items: center;
`
export const RootContainerTable = styled.div`
  table {
    width: 95vw !important;
    table-layout: fixed;
    display: block;
    overflow-x: auto;
    overflow-y: auto;
    scrollbar-color: #2563eb white;

    thead {
      tr {
        :nth-child(2) {
          text-align: left;
        }
        th {
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          cursor: pointer;
          line-height: 16px;
          color: black;
          border-radius: 1px;
          padding: 4px 4px;
          border-right: 1px solid ${COLORS.UI.Gray};
          border-bottom: 1px solid ${COLORS.UI.Gray};
          &:active {
            box-shadow: inset 0 3px 0 0 rgb(0 0 0 / 60%);
          }
        }
      }
    }
    tr {
      td {
        padding: 4px;
        color: black;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        box-shadow: inset 0px -1px 0px ${COLORS.UI.Gray};
      }
    }
  }
`
export const PopUpStyleLoader = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`
export const SpaceMaker = styled.div`
  margin-left: 10px;
`
export const ColoumnPaddingTop = styled.div`
  padding-top: 7px;
`
export const ForwardButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 15px;
`
export const BackwardButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 5px;
`
export const LottieWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const ThWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ThContentWrapper = styled.div`
  width: 100%;
  padding-right: 8px;
`
