/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  P3MarketMakerSec: []
  isLoading: boolean
  action: string
  sorting: any
  sortDirection: any
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  P3MarketMakerSec: [],
  isLoading: false,
  action: '',
  sorting: 'SYMBOL',
  sortDirection: 'ASC',
  httpErrorMessage: '',
  successMessage: '',
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'P3-Market-Maker-Sec',
  initialState,
  reducers: {
    setP3MarketMakerSecData: (state: State, action: PayloadAction<any>) => {
      state.P3MarketMakerSec = action.payload.data
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
      // state.isLoading = true
    },
    setSorting: (state: State, action: PayloadAction<any>) => {
      state.sorting = action.payload
    },
    setSortDirection: (state: State, action: PayloadAction<any>) => {
      state.sortDirection = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  setAction,
  setP3MarketMakerSecData,
  setIsLoading,
  setSelectedRow,
  setSorting,
  setSortDirection,
  logOutUser,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getP3MarketMakerSecData: any =
  (
    action: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/GetP3MarketMakerSecuritiesReport',
        {
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          setP3MarketMakerSecData({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setP3MarketMakerSecData({
          data: '',
        })
      )
      return null
    }
    dispatch(setIsLoading(false))
  }

export const getP3MarketMakerSecCSV: any =
  (
    fileName: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/GetP3MarketMakerSecurities_CsvReport',
        {
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('File Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return 400
    }
  }

export const isLoadingSelector = (store: StoreState) =>
  store.valdi.P3MarketMakerSec.isLoading
export const dataSelector = (store: StoreState) =>
  store.valdi.P3MarketMakerSec.P3MarketMakerSec
export const actionSelector = (store: StoreState) =>
  store.valdi.P3MarketMakerSec.action
export const sortingSelector = (store: StoreState) =>
  store.valdi.P3MarketMakerSec.sorting
export const sortDirectionSelector = (store: StoreState) =>
  store.valdi.P3MarketMakerSec.sortDirection
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.valdi.P3MarketMakerSec.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.valdi.P3MarketMakerSec.successMessage
export default reducer
