import styled from 'styled-components'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { SIZES, TextSizes, TextWeight } from '../../theme/Sizes'

interface CardProps {
  children?: React.ReactNode
  activeButton?: number
  buttonStatus?: string | number
}

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Primary};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Neutral};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.NeutralInverted};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`

export const StyledButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: transparent;
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ${SIZES[0]};
    line-height: ${TextSizes[6]};
  }
`
export const StyledPageButtonWhite = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border-radius: ${SIZES[10]};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Primary};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.Primary};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[1]} 0.6rem;
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledPageButtonPrimary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Primary};
  border-radius: ${SIZES[10]};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Primary};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[1]} 0.6rem;
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`

export const StyledButtonDisabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.NeutralStrong};
  border-radius: ${SIZES[0]};
  border: 1px solid ${() => DEFAULT_COLORS.Border.NeutralDisabled};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.NeutralDisabled};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonOutLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border-radius: ${SIZES[0]};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Neutral};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[1]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonTransparent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: 1px solid ${() => DEFAULT_COLORS.Background.Neutral};
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    border-radius: ${SIZES[0]};
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[8]};
    font-size: 14px;
    line-height: ${SIZES[3]};
    font-weight: ${TextWeight[3]};
  }
`

export const StyledButtonAccent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: ${SIZES[0]};
  border: 1px solid ${() => DEFAULT_COLORS.Border.Accent};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Text.Elevation};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledBorderButtonPrimary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: ${SIZES[0]};
  border: 1px solid ${() => DEFAULT_COLORS.Background.Primary};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Primary};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[0]} ${SIZES[3]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonOutLineLarge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() => DEFAULT_COLORS.Background.Neutral};
  border-radius: ${SIZES[0]};
  border: 1px solid ${() => DEFAULT_COLORS.Background.Neutral};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${() => DEFAULT_COLORS.Background.Neutral};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    padding: ${SIZES[1]} ${SIZES[5]};
    font-size: ${SIZES[3]};
    line-height: ${SIZES[4]};
    font-weight: ${TextWeight[0]};
  }
`
export const StyledButtonPrimaryOutlined = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: ${SIZES[0]};
  cursor: pointer;
  button {
    border: none;
    background: none;
    color: ${DEFAULT_COLORS.Text.Primary};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${SIZES[5]};
    font-size: ${TextSizes[2]};
    line-height: ${TextSizes[6]};
    font-weight: ${TextWeight[2]};
    padding: ${SIZES[0]} ${SIZES[3]};
  }
`

export const WDButton = ({ children }: CardProps) => {
  return <StyledButton>{children}</StyledButton>
}

export const WDButtonIcon = ({ children }: CardProps) => {
  return <StyledButtonIcon>{children}</StyledButtonIcon>
}

export const WDPageButtonWhite = ({ children }: CardProps) => {
  return <StyledPageButtonWhite>{children}</StyledPageButtonWhite>
}

export const WDPageButtonPrimary = ({ children }: CardProps) => {
  return <StyledPageButtonPrimary>{children}</StyledPageButtonPrimary>
}

export const WDButtonAccent = ({ children }: CardProps) => {
  return <StyledButtonAccent>{children}</StyledButtonAccent>
}

export const WDBorderButtonPrimary = ({ children }: CardProps) => {
  return <StyledBorderButtonPrimary>{children}</StyledBorderButtonPrimary>
}

export const WDButtonDisabled = ({ children }: CardProps) => {
  return <StyledButtonDisabled>{children}</StyledButtonDisabled>
}

export const WDButtonOutLine = ({ children }: CardProps) => {
  return <StyledButtonOutLine>{children}</StyledButtonOutLine>
}

export const WDButtonTransparent = ({ children }: CardProps) => {
  return <StyledButtonTransparent>{children}</StyledButtonTransparent>
}

export const WDButtonOutLineLarge = ({ children }: CardProps) => {
  return <StyledButtonOutLineLarge>{children}</StyledButtonOutLineLarge>
}

export const WDButtonPrimaryOutlined = ({ children }: CardProps) => {
  return <StyledButtonPrimaryOutlined>{children}</StyledButtonPrimaryOutlined>
}
