/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import Button from '../../../components/button/Button'
import {
  WDStyledHeadingLabel,
  WDStyledLabel,
} from '../../../components/ui/WDTypography'
import { COLORS } from '../../../theme/Colors'
import {
  AllocationDataWrapper,
  DatePickerWrapper,
  LabelTextboxWrapper,
  LabelWrapper,
  TextboxWrapper,
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  GetRegShoListBuyInsAuditTrail,
  buyInDetailsSelector,
  getRegShoL1BuyInDetails,
  getUpdateRegShoL1BuyIns,
} from '../../../store/reg-SHO-Tool/Reg-SHO-Summary'
import { Textbox } from '../../../components/textbox/Textbox'
import ViewAllocationDetails from './ViewAllocationDetails'
import DatePicker from '../../../components/datePicker/DatePicker'

const NextBuyinDate = (props: any) => {
  const dispatch = useDispatch()
  const buyInData = useSelector(buyInDetailsSelector)
  const [openAllocationDetails, setOpenAllocationDetails] =
    useState<boolean>(false)
  const [allocationInput, setAllocationInput] = useState<any>({
    correspondentDate: new Date().toISOString().split('T')[0],
    correspondentShares: 0,
    failDate: new Date().toISOString().split('T')[0],
    failShares: 0,
    customerDate: new Date().toISOString().split('T')[0],
    customerShares: 0,
    stockLoanDate: new Date().toISOString().split('T')[0],
    stockLoanShares: 0,
    otherAllocationDate: new Date().toISOString().split('T')[0],
    otherAllocationShares: 0,
  })
  const reportDate = props?.reportDate

  const dateCurrent = new Date()
  const mnthCurrent = ('0' + (dateCurrent.getMonth() + 1)).slice(-2)
  const dayCurrent = ('0' + dateCurrent.getDate()).slice(-2)
  const currentDate = [dateCurrent.getFullYear(), mnthCurrent, dayCurrent].join(
    '-'
  )

  const handleBuyInSubmit = async (
    regSho_L1BuyIns_Id: number | null,
    buyInType: string,
    nextBuyinDate: string,
    buyinAmount: number
  ) => {
    const response = await dispatch(
      getUpdateRegShoL1BuyIns(
        regSho_L1BuyIns_Id,
        props.regSHOId,
        props.selectedSummaryTableCUSIP,
        props.source,
        buyInType,
        nextBuyinDate,
        buyinAmount
      )
    )
  }
  const handleBuyInView = async (buyInType: string) => {
    const response = await dispatch(
      getRegShoL1BuyInDetails(props.regSHOId, props.source, buyInType, 'C')
    )
    setOpenAllocationDetails(true)
  }

  const date = new Date(buyInData[0]?.createdOn)
  const mnth = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  const createdOnDate = [date.getFullYear(), mnth, day].join('-')

  return (
    <AllocationDataWrapper>
      <WDStyledLabel>Buy-In Execution Date :</WDStyledLabel>
      <LabelTextboxWrapper>
        <LabelWrapper>
          <WDStyledHeadingLabel>Correspondent Allocation</WDStyledHeadingLabel>
        </LabelWrapper>
        <TextboxWrapper>
          <Textbox
            disabled={props.approveFlag ||  !(reportDate >= currentDate)}
            onChange={(e) => {
              setAllocationInput({ ...allocationInput, correspondentShares: e })
            }}
            placeholder="#Shares"
            style={{
              borderRadius: '4px',
              height: '32px',
              padding: '4px 8px',
            }}
          />
        </TextboxWrapper>
        <DatePickerWrapper>
          <DatePicker
            disabled={props.approveFlag || !(reportDate >= currentDate)}
            min={new Date().toISOString().split('T')[0]}
            defaultValue={''}
            borderColor={''}
            bgColor={''}
            placeHolder={''}
            padding={'4px 8px'}
            onChange={(e) => {
              setAllocationInput({ ...allocationInput, correspondentDate: e })
            }}
            maxWidth={'100%'}
          />
        </DatePickerWrapper>
        <Button
          disabled={props.accessFlag}
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin={'10px'}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            handleBuyInSubmit(
              null,
              'Correspondent',
              allocationInput.correspondentDate,
              allocationInput.correspondentShares
            )
          }}
        >
          Submit
        </Button>
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin={'10px'}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            handleBuyInView('Correspondent')
          }}
        >
          View
        </Button>
      </LabelTextboxWrapper>
      <LabelTextboxWrapper>
        <LabelWrapper>
          <WDStyledHeadingLabel>Fail Allocation</WDStyledHeadingLabel>
        </LabelWrapper>
        <TextboxWrapper>
          <Textbox
            disabled={!(reportDate >= currentDate)}
            onChange={(e) => {
              setAllocationInput({ ...allocationInput, failShares: e })
            }}
            placeholder="#Shares"
            style={{
              borderRadius: '4px',
              height: '32px',
              padding: '4px 8px',
            }}
          />
        </TextboxWrapper>
        <DatePickerWrapper>
          <DatePicker
            disabled={!(reportDate >= currentDate)}
            min={new Date().toISOString().split('T')[0]}
            defaultValue={''}
            borderColor={''}
            bgColor={''}
            placeHolder={''}
            padding={'4px 8px'}
            onChange={(e) => {
              setAllocationInput({ ...allocationInput, failDate: e })
            }}
            maxWidth={'100%'}
          />
        </DatePickerWrapper>
        <Button
          disabled={props.accessFlag}
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin={'10px'}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            handleBuyInSubmit(
              null,
              'Broker',
              allocationInput.failDate,
              allocationInput.failShares
            )
          }}
        >
          Submit
        </Button>
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin={'10px'}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            handleBuyInView('Broker')
          }}
        >
          View
        </Button>
      </LabelTextboxWrapper>
      <LabelTextboxWrapper>
        <LabelWrapper>
          <WDStyledHeadingLabel>Customer Allocation</WDStyledHeadingLabel>
        </LabelWrapper>
        <TextboxWrapper>
          <Textbox
            disabled={!(reportDate >= currentDate)}
            onChange={(e) => {
              setAllocationInput({ ...allocationInput, customerShares: e })
            }}
            placeholder="#Shares"
            style={{
              borderRadius: '4px',
              height: '32px',
              padding: '4px 8px',
            }}
          />
        </TextboxWrapper>
        <DatePickerWrapper>
          <DatePicker
            disabled={!(reportDate >= currentDate)}
            min={new Date().toISOString().split('T')[0]}
            defaultValue={''}
            borderColor={''}
            bgColor={''}
            placeHolder={''}
            padding={'4px 8px'}
            onChange={(e) => {
              setAllocationInput({ ...allocationInput, customerDate: e })
            }}
            maxWidth={'100%'}
          />
        </DatePickerWrapper>
        <Button
          disabled={props.accessFlag}
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin={'10px'}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            handleBuyInSubmit(
              null,
              'Customer',
              allocationInput.customerDate,
              allocationInput.customerShares
            )
          }}
        >
          Submit
        </Button>
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin={'10px'}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            handleBuyInView('Customer')
          }}
        >
          View
        </Button>
      </LabelTextboxWrapper>
      <LabelTextboxWrapper>
        <LabelWrapper>
          <WDStyledHeadingLabel>Stock Loan Allocation</WDStyledHeadingLabel>
        </LabelWrapper>
        <TextboxWrapper>
          <Textbox
            disabled={!(reportDate >= currentDate)}
            onChange={(e) => {
              setAllocationInput({ ...allocationInput, stockLoanShares: e })
            }}
            placeholder="#Shares"
            style={{
              borderRadius: '4px',
              height: '32px',
              padding: '4px 8px',
            }}
          />
        </TextboxWrapper>
        <DatePickerWrapper>
          <DatePicker
            disabled={!(reportDate >= currentDate)}
            min={new Date().toISOString().split('T')[0]}
            defaultValue={''}
            borderColor={''}
            bgColor={''}
            placeHolder={''}
            padding={'4px 8px'}
            onChange={(e) => {
              setAllocationInput({ ...allocationInput, stockLoanDate: e })
            }}
            maxWidth={'100%'}
          />
        </DatePickerWrapper>
        <Button
          disabled={props.accessFlag}
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin={'10px'}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            handleBuyInSubmit(
              null,
              'Stock Loan',
              allocationInput.stockLoanDate,
              allocationInput.stockLoanShares
            )
          }}
        >
          Submit
        </Button>
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin={'10px'}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            handleBuyInView('Stock Loan')
          }}
        >
          View
        </Button>
      </LabelTextboxWrapper>
      <LabelTextboxWrapper>
        <LabelWrapper>
          <WDStyledHeadingLabel>Others Allocation</WDStyledHeadingLabel>
        </LabelWrapper>
        <TextboxWrapper>
          <Textbox
            disabled={!(reportDate >= currentDate)}
            onChange={(e) => {
              setAllocationInput({
                ...allocationInput,
                otherAllocationShares: e,
              })
            }}
            placeholder="#Shares"
            style={{
              borderRadius: '4px',
              height: '32px',
              padding: '4px 8px',
            }}
          />
        </TextboxWrapper>
        <DatePickerWrapper>
          <DatePicker
            disabled={!(reportDate >= currentDate)}
            min={new Date().toISOString().split('T')[0]}
            defaultValue={''}
            borderColor={''}
            bgColor={''}
            placeHolder={''}
            padding={'4px 8px'}
            onChange={(e) => {
              setAllocationInput({ ...allocationInput, otherAllocationDate: e })
            }}
            maxWidth={'100%'}
          />
        </DatePickerWrapper>
        <Button
          disabled={props.accessFlag}
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin={'10px'}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            handleBuyInSubmit(
              null,
              'Others',
              allocationInput.otherAllocationDate,
              allocationInput.otherAllocationShares
            )
          }}
        >
          Submit
        </Button>
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin={'10px'}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            handleBuyInView('Others')
          }}
        >
          View
        </Button>
      </LabelTextboxWrapper>

      {openAllocationDetails && (
        <ViewAllocationDetails
          pastActionDateDisableFlag={
            buyInData.length > 0 ? currentDate > createdOnDate : ''
          }
          setOpenAllocationDetails={setOpenAllocationDetails}
          regSHOId={props.regSHOId}
          selectedSummaryTableCUSIP={props.selectedSummaryTableCUSIP}
          source={props.source}
          handleBuyInView={handleBuyInView}
          buyInData={buyInData}
        />
      )}
    </AllocationDataWrapper>
  )
}

export default NextBuyinDate
