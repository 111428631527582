import { useMemo } from "react";


 export  const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }:any) => {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row:any) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);
    
      return (
        <select
        aria-label="label for the select"
          value={filterValue}
          onChange={e => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option:any, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    };
