/* eslint-disable jsx-a11y/anchor-is-valid */
import BuyInAllocationTabs from '../BuyInAllocationAndExecution/BuyInAllocationTabs'
import { CommonTabProps, RegSHOTabDetails } from '../RegSHOModels'

export const SummaryTableCell = (
  setAllocationType: React.Dispatch<any> | undefined | null,
  setActiveTabIndex: React.Dispatch<any> | undefined | null,
  allocationType: string | undefined | null,
  summaryValue: string | number | null | undefined
) => {
  return (
    <a
      style={{ color: 'white', fontSize: 'medium' }}
      href="#"
      onClick={() => {
        {
          setAllocationType &&
            setAllocationType((previousValue: CommonTabProps) => ({
              ...previousValue,
              allocationType: allocationType,
            }))
          setActiveTabIndex &&
            setActiveTabIndex(
              RegSHOTabDetails.find(
                (tab) => tab.component === BuyInAllocationTabs
              )?.tabIndex
            )
        }
      }}
    >
      {summaryValue}
    </a>
  )
}
