import {
  BuyInIntent2,
  LottieWrapper,
  RootContainerTable,
  TitleContainer
} from './styles'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles';
import Loader from "../../../../assets/lottiefiles/loader.json";
import { useEffect, useState } from 'react'
import Lottie from 'lottie-react';
import Label from '../../../label/Label';
import CreateTable from '../createTable';
import { getTopSecurity, topSecuritySelector } from '../../../../store/dash-board/static-data';
import { useDispatch ,useSelector} from 'react-redux';



const BuyInIntents2 = (props: any) => {
  const dispatch = useDispatch()
  const data = useSelector(topSecuritySelector)
  const [startDate] = useState(new Date())
  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1)

  let mm: string | number
  let firstDayOfMonth: string | number

  mm = startDate.getMonth() + 1
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth
  
  const columns = [
    { Header: 'Field Name', accessor: 'requestType' },
    { Header: 'Count', accessor: 'fieldCount' },
  ]
  
    const [loading, isLoading] = useState<boolean>(true)
  
    const Table = (props: any) => {
    
      return (
        <RootContainerTable>
          <CreateTable data={props.data} columns={props.columns} />
        </RootContainerTable>
      )
    }
  
    useEffect(() => {
      const getTopSecurities = async () => {
       await  dispatch( getTopSecurity(fromDate) )}
        isLoading(false)
      getTopSecurities();
    }, [])
  
    return (
      <>
      {loading && <PopUpStyleLoader>
        <LottieWrapper>
        <Lottie animationData={Loader} loop={true} />
      </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div>
      </PopUpStyleLoader>}
        <BuyInIntent2>
          <TitleContainer>{props.widgetDescription}</TitleContainer>
        
  
        </BuyInIntent2>
      </>
    )
  }


export default BuyInIntents2
