/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'


interface State {
  FormateDate: []
  intraDayMinDate:string
}

const initialState: State = {
  FormateDate: [],
  intraDayMinDate:''
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    setFormateDate: (state: State, action: PayloadAction<any>) => {
      state.FormateDate = action.payload
    },
    setIntraDayMinDate:(state:State,action:PayloadAction<any>)=>{
      state.intraDayMinDate = action.payload
    }
  },
})

export const {  setFormateDate , setIntraDayMinDate } = actions

export const getHoliDay: any = () => async () => {
  try {
    const { data, status } = await authApiCall.get(
      'opsfailprocessapi/FailAnalysisReport/GetHolidayMasterDates'
    )

    if (status === 200) {
      return data
    }
  } catch (e: any) {
    return []
  }
}

export const userActivity: any = (
  
  userAdId: string,
  loginTimeStamp: string,
  loginDate:string,
  timeZone: string,
  userAgent: string,
) => async () => {
  try {
    const { data, status } = await authApiCall.post(
      'opsdashboardapi/Login/LoginAudit', {
        userAdId,
        loginTimeStamp,
        loginDate,
        timeZone,
        userAgent
      }
    )

    if (status === 200) {
      return data
    }
  } catch (e: any) {
    return []
  }
}

export const FormateDateSelector  = (store: StoreState) =>
  store.Dashboard.about.FormateDate

export const IntraDayMinDateSelector =(store:StoreState) =>
store.Dashboard.about.intraDayMinDate

export default reducer
