/* eslint-disable @typescript-eslint/no-explicit-any */
import Label from '../../../components/label/Label'
import {
  WDStyledButtonText,
  WDStyledMainHeading,
} from '../../../components/ui/WDTypography'
import {
  CancelEmailModal,
  CancelSendButtonWrapper,
  EmailBodyWrapper,
  EmailHeaderContainer,
  EmailModalWrapper,
  FileUploadInput,
  FileUploadWrapper,
  MailWrapper,
  PopUpWrapper,
  RowWrapper,
  SelectedMail,
  SubjectSearchSecurityContainer,
  SummaryTable,
  SvgButtonWrapper,
  TextEditorWrapper,
  ToastWrapper,
  ToMail,
  ToPopupCard,
  UploadWrapper,
  UserWrapper,
} from './styles'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'
import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'
import { Textbox } from '../../../components/textbox/Textbox'
import { WDLabel } from '../../../components/ui/WDLabel'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/table/Table'
import Divider from '../../../components/divider/Divider'
import { Backgrounds } from '../../../shared/styles'
import {
  getToMail,
  ResponseSelector,
  SendMail,
  statusSelector,
} from '../../../store/staticData/stale-pricing-beta'
import { Toast } from '../../../components/toast/Toast'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { authApiCall } from '../../../utils/api'

const EXTENSIONS = ['csv', 'xlsx', 'xls']

const EmailModal = (props: any) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const [toMail, setToMail] = useState<string>('')
  const [dummyMail, setDummyMail] = useState<string>('')
  const [selectedMail, setSelectedMail] = useState<any>([])
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [autoSuggest, setAutoSuggest] = useState([{}])
  const [fileName, setFileName] = useState('')
  const [fileData, setFileData] = useState<any>()
  const [toastPopup, setToastPopup] = useState<boolean>(false)
  const [CSV, setCSV] = useState<boolean>(false)
  const [isCSV, setIsCSV] = useState(true)
  const fileUpload = useRef<HTMLInputElement>(null)
  const response = useSelector(ResponseSelector)
  const status = useSelector(statusSelector)
  const modules = {
    toolbar: [['bold', 'italic', 'underline'], [{ list: 'bullet' }], ['link']],
  }
  function changeDateFormat(inputDate: any) {
    const splitDate = inputDate.split('-')
    if (splitDate.count == 0) {
      return null
    }
    const month = splitDate[1]
    return Number(month.replace(/^0+/, ''))
  }
  const [res, setRes] = useState<any>([])
  const inputDate = props.fromDate
  const newMonth: any = changeDateFormat(inputDate)
  const m_names = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const d = new Date(props.fromDate)
  const month = m_names[newMonth-1]
  const year = d.getFullYear()
  const [showList, setShowList] = useState(false)
  const [subject, setSubject] = useState<string>(
    `${month}  ${year}  ${' '}Stale Pricing Report Inquiry`
  )

  useEffect(() => {
    async function AllMail(){
      const AllMailOption:any = []
     const response :any = await authApiCall.get('opsstaticdataapi/StaticData/GetStalePrice_AllContacts')
     await response.data.map((value:any,index:number)=>{
      AllMailOption.push({'label':value?.firstName+value?.lastName,
            'value':value?.email})
   })
      await setRes(AllMailOption) 
    }
    AllMail()
  }, [])
  const getExtension = (file: File) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    setIsCSV(EXTENSIONS.includes(extension))
    return EXTENSIONS.includes(extension)
  }
  function useOuterClick(callback: (e: Event) => void) {
    const innerRef: React.MutableRefObject<HTMLElement | undefined> = useRef()
    const callbackRef: React.MutableRefObject<
      ((e: Event) => void) | undefined
    > = useRef()
    useEffect(() => {
      callbackRef.current = callback
    })

    useEffect(() => {
      function handleClick(e: Event) {
        if (
          innerRef.current &&
          callbackRef.current &&
          !innerRef.current.contains(e.target as Node)
        ) {
          callbackRef.current(e)
        }
      }
      document.addEventListener('click', handleClick)
      return () => document.removeEventListener('click', handleClick)
    }, [])

    return innerRef
  }
  const innerRef = useOuterClick(() => {
    const createAtt = document.getElementById('right-side')
    if (createAtt) {
      setShowList(false)
    }
  })
  const handleDrop = (uploadedFile: File) => {
    setFileName(uploadedFile.name)
    if (getExtension(uploadedFile)) {
      setIsFileUploaded(true)
      const reader = new FileReader()
      reader.readAsBinaryString(uploadedFile)
      setFileData(uploadedFile)
    } else {
      setIsFileUploaded(false)
      //setError('*Only .csv extension files are allowed')
    }
  }

  const preventDefaults = (event: BaseSyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDropFile = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer.files
    handleDrop(files[0])
  }

  const handleFiles = (event: BaseSyntheticEvent) => {
    handleDrop(event.target.files[0])
  }

  const browseFile = () => {
    fileUpload.current?.click()
  }
  const handleSubmit = async () => {
    const concat: any = document.querySelector('#mailTable')
    const response = await dispatch(
      SendMail(
        subject,
        toMail.substring(0, toMail.length - 1),
        value + '\n' + concat.innerHTML,
        fileData
      )
    )
    setToastPopup(true)
    setCSV(false)
    const uploadElement = document.getElementById(
      'fileElement'
    ) as HTMLInputElement
    uploadElement.value = ''
    setTimeout(() => {
      props.setShowEmailPopup(false)
    }, 1000)
    setIsFileUploaded(false)
  }
  const mailSelect = (e: string) => {
    setShowList(false)
    setSelectedMail([...selectedMail,e])
    setToMail(toMail + e + ';')
    setDummyMail('')
  }
const RemoveMail =(e:number) =>{
 let removedMail=''
 selectedMail.splice(e,1);
 setSelectedMail([...selectedMail])
 selectedMail.map((value:any,key:any)=>(
   removedMail= removedMail + value + ';'
 ))

 setToMail(removedMail)
}
  const autoSuggestion = async (e: string) => {
    const response = await dispatch(getToMail(e))
    setAutoSuggest(response)
  }

  return (
    <EmailModalWrapper>
      <EmailHeaderContainer>
        <WDStyledMainHeading>Email</WDStyledMainHeading>
        <CancelSendButtonWrapper>
          <CancelEmailModal
            onClick={() => {
              props.setShowEmailPopup(false)
              setCSV(false)
              const uploadElement = document.getElementById(
                'fileElement'
              ) as HTMLInputElement
              uploadElement.value = ''
              setIsFileUploaded(false)
            }}
          >
            <WDStyledButtonText>
              <Label>Cancel</Label>
            </WDStyledButtonText>
          </CancelEmailModal>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              handleSubmit()
            }}
          >
            Send
          </Button>
        </CancelSendButtonWrapper>
      </EmailHeaderContainer>
      <EmailBodyWrapper>
        <SubjectSearchSecurityContainer>
          <Label>Subject</Label>

          <Textbox
            style={{
              marginTop: '9px',
              marginBottom: '9px',
            }}
            onChange={(e) => {
              setSubject(e)
            }}
            defaultValue={`${month}  ${year}  ${' '}Stale Pricing Report Inquiry`}
            placeholder="SUBJECT"
          />

          <Label>To</Label>
          <ToMail>
            {selectedMail.map((item:any,index:any)=>{
              return(
                <SelectedMail key={index}>
                  {item}  <SvgButtonWrapper
                onClick={() => {
                  RemoveMail(index)
                }}
              >
                <SvgCancel
                  fillColor={COLORS.Icons.NeutralIcon}
                ></SvgCancel>
              </SvgButtonWrapper>
                </SelectedMail>
              )
            })}
          </ToMail>
          <div style={{height:'10%'}}>
          <SelectDropdown
                defaultValue={{
                  value: '',
                  label: 'Select',
                }}
                borderRadius={'4px'}
                height="32px"
                options={res}
                onChange={(e) => {
                  mailSelect(e)
                }}
              ></SelectDropdown>
                </div>
          <Textbox
            style={{
              marginTop: '9px',
              marginBottom: '9px',
            }}
            onChange={(e) => {
              setDummyMail(e)
              {
                dummyMail.length >= 2 && setShowList(true)
              }
              {
                dummyMail.length >= 2 && autoSuggestion(e)
              }
            }}
            placeholder="To"
            value={dummyMail}
          />
          {showList && dummyMail?.length > 0 && (
            <ToPopupCard id="right-side" ref={innerRef}>
              {autoSuggest?.map((item: any, index: number) => {
                return (
                  <>
                    {item?.firstName && (
                      <>
                        <PopUpWrapper key={index}>
                          <UserWrapper>
                            {item.firstName.charAt(0).toUpperCase()}
                            {item.lastName.charAt(0).toUpperCase()}
                          </UserWrapper>
                          <MailWrapper onClick={() => mailSelect(item.email)}>
                            <Label>
                              {item.firstName} {item.lastName}
                            </Label>
                            <Label>{item.email}</Label>
                          </MailWrapper>
                        </PopUpWrapper>
                        <Divider
                          thickness={'1px'}
                          horizontal={true}
                          bgColor={Backgrounds.Gray110}
                        />
                      </>
                    )}
                  </>
                )
              })}
            </ToPopupCard>
          )}
          <Label margin="5px">Security</Label>
          <SummaryTable>
            <div id="mailTable">
              <Table
                tableColumns={
                  props.System == 'Beta'
                    ? [
                        {
                          Header: 'CUSIP Number',
                          accessor: 'cusip',
                        },
                        {
                          Header: 'Security Description',
                          accessor: 'securityDesc',
                        },
                        {
                          Header: 'Security Number',
                          accessor: 'securityNbr',
                        },
                      ]
                    : [
                        {
                          Header: 'CUSIP Number',
                          accessor: 'cusip_Number',
                        },
                        {
                          Header: 'Security Description',
                          accessor: 'securityDescription',
                        },
                      ]
                }
                tableData={props.selectedRows}
                onClick={() => {
                  //
                }}
              />
            </div>
          </SummaryTable>
        </SubjectSearchSecurityContainer>
        <TextEditorWrapper>
          <Label>Body</Label>
          <ReactQuill
            modules={modules}
            value={value}
            onChange={setValue}
            placeholder="Type..."
          />
          <FileUploadWrapper>
            <Label>Upload Files</Label>
            <UploadWrapper
              id="drop-area"
              onDragEnter={preventDefaults}
              onDragOver={preventDefaults}
              onDragLeave={preventDefaults}
              onDrop={handleDropFile}
              className={isFileUploaded ? 'file-uploaded' : ''}
            >
              <FileUploadInput
                type="file"
                id="fileElement"
                ref={fileUpload}
                multiple
                accept=".csv, .xls, .xlsx,"
                hidden
                onChange={handleFiles}
              ></FileUploadInput>
              {isFileUploaded ? (
                <RowWrapper>
                  <WDLabel>
                    <Label>{fileName}</Label>
                  </WDLabel>
                </RowWrapper>
              ) : (
                <RowWrapper>
                  <WDLabel>
                    <Label>{'Drag your file here to start uploading or'}</Label>
                  </WDLabel>
                  <Button
                    hoverBgColor={COLORS.Background.Primary70}
                    bgColor={COLORS.Background.Primarytext}
                    color={COLORS.Background.Primary}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    hover={true}
                    type={''}
                    activeBgColor={COLORS.Background.Primaryactive}
                    borderColor={'transparent'}
                    onClick={browseFile}
                  >
                    Browse files
                  </Button>
                </RowWrapper>
              )}
            </UploadWrapper>
            <Label margin="5px">
              {'Supported file types are .csv, .xls, .xlsx'}
            </Label>
          </FileUploadWrapper>
        </TextEditorWrapper>
      </EmailBodyWrapper>
      {toastPopup && (
        <ToastWrapper>
          <Toast
            text={status}
            type={response ? 'success' : 'danger'}
            openStatusCallback={() => {
              setToastPopup(false)
            }}
          />
        </ToastWrapper>
      )}
    </EmailModalWrapper>
  )
}

export default EmailModal
