import React from 'react'
import { StyledImage } from '../../../../../components/image/styles'
import { Icons } from '../../../../../shared/GlobalStyle'
import { BuyInReceivesProducts } from '../constants'

export const getProductColumns = (
  data: any,
  setShowAddProduct: React.Dispatch<React.SetStateAction<boolean>>,
  setProducts: React.Dispatch<React.SetStateAction<BuyInReceivesProducts>>,
  setDeactivate: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return [
    {
      Header: 'Asset Type',
      accessor: 'assetType',
    },
    {
      Header: 'Active',
      accessor: 'buyInActiveYN',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'DTCC Sec Type',
      accessor: 'dtccSecType',
    },
    {
      Header: 'SD Business Days',
      accessor: 'sdBusinessDays',
    },
    {
      Header: 'Sec Type',
      accessor: 'secType',
    },
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Delete',
      Cell: (e: { cell: { row: { id: string | number } } }) => {
        return (
          <StyledImage
            style={{ cursor: 'pointer' }}
            title="delete"
            src={Icons.Delete}
            onClick={() => {
              setProducts({
                id: data[e.cell.row.id].id,
                source: data[e.cell.row.id].source,
                secType: data[e.cell.row.id].secType,
                description: data[e.cell.row.id].description,
                assetType: data[e.cell.row.id].assetType,
                dTCCSecType: data[e.cell.row.id].dtccSecType,
                buyInActiveYN:
                  data[e.cell.row.id].buyInActiveYN == 'Y' ? true : false,
                sdBusinessDays: data[e.cell.row.id].sdBusinessDays,
              })

              setDeactivate(true)
            }}
          ></StyledImage>
        )
      },
    },
    {
      Header: 'Edit',
      Cell: (e: { cell: { row: { id: string | number } } }) => {
        return (
          <StyledImage
            title="Edit"
            onClick={() => {
              setProducts({
                id: data[e.cell.row.id].id,
                source: data[e.cell.row.id].source,
                secType: data[e.cell.row.id].secType,
                description: data[e.cell.row.id].description,
                assetType: data[e.cell.row.id].assetType,
                dTCCSecType: data[e.cell.row.id].dtccSecType,
                buyInActiveYN:
                  data[e.cell.row.id].buyInActiveYN == 'Y' ? true : false,
                sdBusinessDays: data[e.cell.row.id].sdBusinessDays,
              })

              setShowAddProduct(true)
            }}
            width={'25px'}
            style={{ cursor: 'pointer' }}
            src={Icons.Edit}
            alt=""
          />
        )
      },
    },
  ]
}
