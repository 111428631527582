/* eslint-disable react-hooks/exhaustive-deps */
import Lottie from 'lottie-react'
import Button from '../../../components/button/Button'
import DatePicker from '../../../components/datePicker/DatePicker'
import Label from '../../../components/label/Label'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import WDCard from '../../../components/ui/WDCard'
import { WDStyledHeading } from '../../../components/ui/WDTypography'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import { COLORS } from '../../../theme/Colors'
import JAMSJobReportColumns from './JAMSJobReportColumns'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  FilterContainer,
  FilterLabelWrapper,
  LottieWrapper,
  MainContainer,
  MarginTopSpace,
  PopUpStyleLoader,
  RootContainer,
  SearchButtonWrapper,
  StyledSelectWrapper,
  ToastWrapper,
} from './styles'
import { useEffect, useState } from 'react'
import { Toast } from '../../../components/toast/Toast'
import { useDispatch, useSelector } from 'react-redux'
import {
  actionSelector,
  getJAMSJobListBasedOnModule,
  getJAMSJobModuleNameList,
  getJAMSJobNameList,
  getJAMSJobReportData,
  jamsJobDataSelector,
} from '../../../store/tech-ops/JAMS-Job-Report'

interface JobListProps {
  value: string
  label: string
}

const JAMSJobReport = () => {
  const dispatch = useDispatch()
  const data = useSelector(jamsJobDataSelector)
  const action = useSelector(actionSelector)
  const [loadingBuffer, setLoadingBuffer] = useState<boolean>(false)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [jobNameListArr, setJobNameListArr] = useState<JobListProps[]>([])
  const [moduleNameListArr, setModuleNameListArr] = useState<JobListProps[]>([])
  const [jobNameInDropdown, setJobNameInDropdown] = useState<string>('All')
  const [moduleNameDropdown, setModuleNameDropdown] = useState<string>('All')
  const [statusOptionName, setStatusOptionName] = useState<string>('All')
  const [reportStartDate, setReportStartDate] = useState<string | null>(null)
  const [reportEndDate, setReportEndDate] = useState<string | null>(null)

  const statusOptions = [
    { value: 'All', label: 'All' },
    { value: 'Completed - C', label: 'Completed - C' },
    { value: 'Fail - F', label: 'Fail - F' },
    { value: 'Running - R', label: 'Running - R' },
  ]
  const [requestSubmit, setrequestSubmit] = useState<{
    status: boolean | null
    text: string
  }>({
    status: null,
    text: '',
  })

  const jobNameOptions = [
    {
      value: 'All',
      label: 'All',
    },
  ]

  useEffect(() => {
    const jobNameList = async () => {
      const response = await dispatch(getJAMSJobNameList())
      response.jobNameListViewModel.map((option: string, i: number) => {
        jobNameOptions.push({
          value: response.jobNameListViewModel[i].jobName,
          label: response.jobNameListViewModel[i].jobName,
        })
      })
      setJobNameListArr(jobNameOptions)
    }
    const moduleNameList = async () => {
      const response = await dispatch(getJAMSJobModuleNameList())
      const moduleNameOptions = [
        {
          value: 'All',
          label: 'All',
        },
      ]
      response.moduleNameListViewModel.map((option: string, i: number) => {
        moduleNameOptions.push({
          value: response.moduleNameListViewModel[i].moduleName,
          label: response.moduleNameListViewModel[i].moduleName,
        })
      })
      setModuleNameListArr(moduleNameOptions)
    }
    const topTwentyJob = async () => {
      setLoadingBuffer(true)
      const response = await dispatch(
        getJAMSJobReportData('search', null, null, null, null, null, 1, 20)
      )
      setLoadingBuffer(false)
      if (response) {
        if (response.totalCount > 0) {
          setTotalRecords(response.totalCount)
        } else {
          setTotalRecords(0)
        }
      }
      if (!response) {
        setrequestSubmit({
          status: false,
          text: 'Sorry, Something went wrong',
        })
      }
    }
    topTwentyJob()
    moduleNameList()
    jobNameList()
  }, [])

  useEffect(() => {
    const jobListBasedOnModule = async () => {
      setLoadingBuffer(true)
      const response = await dispatch(
        getJAMSJobListBasedOnModule(
          'search',
          moduleNameDropdown == 'All' ? null : moduleNameDropdown
        )
      )
      jobNameOptions.splice(1)
      response.jobNameListViewModel.map((option: string, i: number) => {
        jobNameOptions.push({
          value: response.jobNameListViewModel[i].jobName,
          label: response.jobNameListViewModel[i].jobName,
        })
      })
      setJobNameListArr(jobNameOptions)
      setLoadingBuffer(false)
    }
    jobListBasedOnModule()
  }, [moduleNameDropdown])

  const handleSearch = async () => {
    setLoadingBuffer(true)
    const response = await dispatch(
      getJAMSJobReportData(
        'search',
        jobNameInDropdown == 'All' ? null : jobNameInDropdown,
        moduleNameDropdown == 'All' ? null : moduleNameDropdown,
        statusOptionName == 'All' ? null : statusOptionName.slice(-1),
        reportStartDate == '' ? null : reportStartDate,
        reportEndDate == '' ? null : reportEndDate,
        1,
        20
      )
    )
    setLoadingBuffer(false)
    if (response) {
      if (response.totalCount > 0) {
        setTotalRecords(response.totalCount)
      } else {
        setTotalRecords(0)
      }
    }
    if (!response) {
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }
  return (
    <RootContainer>
      <WDStyledHeading>{'JAMS Job Report'}</WDStyledHeading>
      <MainContainer>
        <FilterContainer>
          <FilterLabelWrapper>
            <Label>From Date</Label>
            <DatePicker
              max={new Date().toISOString().split('T')[0]}
              defaultValue={''}
              borderColor={''}
              bgColor={''}
              placeHolder={''}
              padding={'4px 8px'}
              onChange={(e) => {
                setReportStartDate(e)
              }}
              maxWidth={'100%'}
            />
          </FilterLabelWrapper>
          <FilterLabelWrapper>
            <Label>End Date</Label>
            <DatePicker
              max={new Date().toISOString().split('T')[0]}
              defaultValue={''}
              borderColor={''}
              bgColor={''}
              placeHolder={''}
              padding={'4px 8px'}
              onChange={(e) => {
                setReportEndDate(e)
              }}
              maxWidth={'100%'}
            />
          </FilterLabelWrapper>
          <FilterLabelWrapper>
            <Label>Status</Label>
            <SelectDropdown
              defaultValue={{ value: 'All', label: 'All' }}
              borderRadius={'4px'}
              height="28px"
              width="200px"
              options={statusOptions}
              onChange={(e) => {
                setStatusOptionName(e)
              }}
            />
          </FilterLabelWrapper>
          <FilterLabelWrapper>
            <Label>ModuleName</Label>
            <SelectDropdown
              defaultValue={{ value: 'All', label: 'All' }}
              borderRadius={'4px'}
              height="28px"
              width="200px"
              options={moduleNameListArr}
              onChange={(e) => {
                setModuleNameDropdown(e)
                setJobNameInDropdown('All')
              }}
            />
          </FilterLabelWrapper>
          <FilterLabelWrapper>
            <Label>JobName</Label>
            <SelectDropdown
              defaultValue={{ value: 'All', label: 'All' }}
              borderRadius={'4px'}
              height="28px"
              width="200px"
              value={{ value: jobNameInDropdown, label: jobNameInDropdown }}
              options={jobNameListArr}
              onChange={(e) => {
                setJobNameInDropdown(e)
              }}
            />
          </FilterLabelWrapper>
        </FilterContainer>
        <SearchButtonWrapper>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              handleSearch()
            }}
          >
            Search
          </Button>
        </SearchButtonWrapper>
      </MainContainer>
      {action === 'search' && (
        <>
          <MarginTopSpace>
            <WDCard>
              <CardHeader
                label={'JAMS Job'}
                icon={false}
                amountValue={`Total: ${totalRecords}`}
              />
            </WDCard>
          </MarginTopSpace>
          <JAMSJobReportColumns
            data={data.jobStatusViewModel ? data.jobStatusViewModel : []}
            jobNameInDropdown={jobNameInDropdown}
            moduleNameDropdown={moduleNameDropdown}
            statusOptionName={statusOptionName}
            reportStartDate={reportStartDate}
            reportEndDate={reportEndDate}
            totalRecords={totalRecords}
          ></JAMSJobReportColumns>
        </>
      )}
      {loadingBuffer && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {requestSubmit.status !== null && (
        <ToastWrapper>
          <Toast
            text={requestSubmit.text}
            type={requestSubmit.status ? 'success' : 'danger'}
            openStatusCallback={(status: boolean) =>
              setrequestSubmit({
                status: status ? status : null,
                text: '',
              })
            }
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default JAMSJobReport
