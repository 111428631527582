import { BaseSyntheticEvent, useRef, useState } from 'react'
import Button from '../../../components/button/Button'
import Divider from '../../../components/divider/Divider'
import Label from '../../../components/label/Label'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import { WDLabel } from '../../../components/ui/WDLabel'
import { Backgrounds } from '../../../shared/styles'
import { COLORS } from '../../../theme/Colors'
import {
  PopUpStyleUpload,
  UploadWrapper,
  FileUploadInput,
  RowWrapper,
  ButtonWrapper,
  MarginLeftAutoSpace,
  SvgButtonWrapper,
  StyledSelectWrapper,
} from '../styles'
import { getAccessFlag } from '../../../utils/globalFunctions'

const EXTENSIONS = ['msg']

interface MailProps {
    ClosePopUp : () => void
    FileData :  (e:File) => void
    onSubmit: (fileName:string) => void
}

const FileUpload = ({ClosePopUp,FileData,onSubmit}:MailProps) => {
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [fileName, setFileName] = useState('')
  const [isMSG, setIsMSG] = useState(true)
  const fileUpload = useRef<HTMLInputElement>(null)
  const writeExecuteFlag = getAccessFlag(['SS-RWX', 'OM-RWX'])

  const getExtension = (file: File) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    setIsMSG(EXTENSIONS.includes(extension))
    return EXTENSIONS.includes(extension)
  }

  const handleDrop = (uploadedFile: File) => {
    setFileName(uploadedFile.name)
    if (getExtension(uploadedFile)) {
      setIsFileUploaded(true)
      const reader = new FileReader()
      reader.readAsBinaryString(uploadedFile)
      FileData(uploadedFile)
    } else {
      setIsFileUploaded(false)
    }
  }

  const preventDefaults = (event: BaseSyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDropFile = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer.files
    handleDrop(files[0])
  }

  const handleFiles = (event: BaseSyntheticEvent) => {
    handleDrop(event.target.files[0])
  }

  const browseFile = () => {
    fileUpload.current?.click()
  }

  return (
    <PopUpStyleUpload>
      <StyledSelectWrapper
        style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
      >
        {'Email Upload '}
        <MarginLeftAutoSpace>
          <SvgButtonWrapper
            onClick={() => {
              const uploadElement = document.getElementById(
                'fileElement'
              ) as HTMLInputElement
              uploadElement.value = ''
              setIsFileUploaded(false)
              ClosePopUp()
            }}
          >
            <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
          </SvgButtonWrapper>
        </MarginLeftAutoSpace>
      </StyledSelectWrapper>
      <Divider
        thickness={'1px'}
        horizontal={true}
        bgColor={Backgrounds.Gray110}
      />
      <UploadWrapper
        id="drop-area"
        onDragEnter={preventDefaults}
        onDragOver={preventDefaults}
        onDragLeave={preventDefaults}
        onDrop={handleDropFile}
        className={isFileUploaded ? 'file-uploaded' : ''}
      >
        <FileUploadInput
          type="file"
          id="fileElement"
          ref={fileUpload}
          multiple
          accept=".msg"
          hidden
          onChange={handleFiles}
        ></FileUploadInput>
        {isFileUploaded ? (
          <RowWrapper>
            <WDLabel>
              <Label>{fileName}</Label>
            </WDLabel>
          </RowWrapper>
        ) : (
          <RowWrapper>
            <WDLabel>
              <Label>{'Drag your file here to start uploading or'}</Label>
            </WDLabel>
            <Button
              hoverBgColor={COLORS.Background.Primary70}
              bgColor={COLORS.Background.Primarytext}
              color={COLORS.Background.Primary}
              padding={'4px 16px'}
              disabled={!writeExecuteFlag}
              borderRadius={'4px'}
              hover={true}
              type={''}
              activeBgColor={COLORS.Background.Primaryactive}
              borderColor={'transparent'}
              onClick={browseFile}
            >
              Browse files
            </Button>
          </RowWrapper>
        )}
      </UploadWrapper>
      {`Supported file type only '.msg' .Max FileSize 200KB`}
      <MarginLeftAutoSpace>
        <ButtonWrapper>
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Primarytext}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
            onClick={() => {
                ClosePopUp()
              const uploadElement = document.getElementById(
                'fileElement'
              ) as HTMLInputElement
              uploadElement.value = ''
              setIsFileUploaded(false)
            }}
          >
            Cancel
          </Button>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            disabled={!writeExecuteFlag}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              fileName.length>0 && onSubmit(fileName)
            }}
          >
            Upload
          </Button>
        </ButtonWrapper>
      </MarginLeftAutoSpace>
    </PopUpStyleUpload>
  )
}

export default FileUpload
