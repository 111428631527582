import {
  RootContainerTable,
  TableChartWidgetContainerByAgeEquity,
  ChartContainerByAgeEquity,
  TitleContainer,
  LottieWrapper,
} from './styles'
import CreateTable from '../createTable'
import { SetStateAction, useEffect, useState } from 'react'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles'
import { authApiCall } from '../../../../utils/api'
import BarChart from '../../../bar/barChart'
import {
  formattingDate,
  formattingDateWithTime,
} from '../../../../utils/globalFunctions'
import Loader from '../../../../assets/lottiefiles/loader.json'
import Label from '../../../label/Label'
import Lottie from 'lottie-react'
import MultipleDropDown from './multipleDropDown'
import { Link, useNavigate } from 'react-router-dom'
import { COLORS } from '../../../../theme/Colors'

const FeedBackErrorByType = (props:{
  selectedDate:string
}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any>([])
  const [sum, setSum] = useState<any>(0)
  const [source, setSource] = useState<string>('Beta')
  const [errorDate, setErrorDate] = useState<any>(null)
  const [legendLabels, setLegendLabels] = useState<any>([])
  const [responseData, setResponseData] = useState<any>([])

  const addBreadCrumb = async (row: any) => {
    const date = props?.selectedDate
    const breadCrumbArray = [
      {
        key: 'Dashboard',
        link: '/CaisCatsDashoard',
        active: false,
      },
      {
        key: 'ErrorsDetailedReport',
        link: '/errorsDetailedReport',
        active: true,
        passProps: { row, source, errorDate,date },
      },
    ]

    navigate('/errorsDetailedReport', {
      state: {
        passProps: { row, source, errorDate,date },
      },
    })
    sessionStorage.setItem('breadCrumbData', JSON.stringify(breadCrumbArray))
  }

  const columns = [
    {
      Header: 'ErrorType',
      accessor: 'errorType',
    },
    {
      Header: 'ErrorCount',
      accessor: (row: any) => {
        if (row.errorCount)
          return (
            <Link
              onClick={(e) => {
                e.preventDefault()
                addBreadCrumb(row)
              }}
              to={''}
            >
              {row.errorCount}
            </Link>
          )
      },
    },
  ]

  const Table = (props: any) => {
    return (
      <RootContainerTable>
        <CreateTable data={props.data} columns={props.columns} />
      </RootContainerTable>
    )
  }
  const getError = async () => {
    setLoading(true)
    try {
      setLoading(true)
      const { data, status } = await authApiCall.get(
        `opsdashboardapi/OpsDashboard/GetFeedbackErrors?source=${source}&reportDate=${props?.selectedDate}`
      )
      if (status === 200) {
        setErrorDate(formattingDate(data.currentDate))
        const formatDataChart: {
          label: string
          data: any
          barThickness: number
          backgroundColor: any
          borderColor: any
          borderWidth: any
          stack: string
        }[] = []
        const responseData = data.caISErrorsDashboardViewModels
        setResponseData(responseData)
        const legendLabels =
          responseData?.length &&
          responseData.map(
            (m: { errorType: string; errorCount: number }) =>
              m.errorType.replaceAll(' ', '') + ': ' + m.errorCount
          )
        const bgColors = ['#2563EB', '#1E40AF']
        const borderColors = ['#FFFFFF', '#FFFFFF']

        if (responseData && responseData.length) {
          responseData.map((value: any, index: any) =>
            formatDataChart.push({
              label: legendLabels[index],
              data: [value.errorCount],
              barThickness: 170,
              stack: 'Stack 0',
              backgroundColor: bgColors[index],
              borderColor: borderColors[index],
              borderWidth: 0,
            })
          )
        }
        setData(formatDataChart)
        setLegendLabels(legendLabels)
        const sum = responseData?.reduce(
          (a: any, b: any) => a + (b['errorCount'] || 0),
          0
        )
        setSum(sum)
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getError()
  }, [source,props?.selectedDate])
  const options = {
    plugins: {
      title: {
        display: true,
        text: sum ? `Total Error ${sum}` : '',
        align: 'start',
        color: '#0F172A',
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        fullSize: true,
        align: 'start',
        labels: {
          color: '#0F172A',
          usePointStyle: true,
          pointStyle: 'rectRounded',
          boxWidth: 24,
          boxHeight: 24,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 30,
      },
    },

    responsive: true,
    scales: {
      y: {
        stacked: true,
        display: false,
      },
      x: {
        display: false,
      },
    },
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
  }

  return (
    <>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <TableChartWidgetContainerByAgeEquity>
        <TitleContainer>{'FDID Errors (Account level)'}</TitleContainer>
        <MultipleDropDown
          dropDowns={[
            {
              label: 'Source',
              attribute: 'Source',
              element: 'select',
              options: ['Beta', 'P3'],
              onChange: async (e: {
                target: { value: SetStateAction<string> }
              }) => {
                setSource(e.target.value)
              },
            },
          ]}
        />
        <Table data={responseData ?? responseData} columns={columns} />
        <ChartContainerByAgeEquity>
          <BarChart
            legendLabels={legendLabels}
            options={options}
            dataValues={data}
          />
        </ChartContainerByAgeEquity>
        {
          <span style={{ fontSize: '10px' }}>
            Data as of: {errorDate ? formattingDateWithTime(errorDate) : null}
          </span>
        }
      </TableChartWidgetContainerByAgeEquity>
    </>
  )
}

export default FeedBackErrorByType
