import styled from 'styled-components'
import { COLORS } from '../../../../../theme/Colors'

export const MoreFiltersWrapper = styled.div`
  position: absolute;
  top: 100%;
  z-index: 10;
  border-radius: 10px;
  right: 0%;
  background: white;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  max-height: 50vh;
  min-width: 15rem;
  padding: 15px;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${() => COLORS.Background.Primary};
  }
  ::-webkit-scrollbar-thumb {
    background: ${() => COLORS.Background.Primary10};
    border-radius: 4px;
  }
`
export const LabelCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-weight: 600;
  margin: 5px 0px 5px 0px;
`

export const DropDownWrapper = styled.div`
  position: absolute;
  top: 100%;
  z-index: 10;
  border-radius: 10px;
  left: 0%;
  background: white;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  max-height: 50vh;
  min-width: 15rem;
  padding: 15px;
  overflow-y: auto;
`
export const TextAreaCancelIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
`
export const TextAreaStyleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`
export const CloseButtonStyleWrapper = styled.div`
  position: absolute;
  right: 35px;
`
export const AddButtonStyleWrapper = styled.div`
  display: flex;
`
export const SavedDataWrapper = styled.div`
  margin-bottom: 10px;
`

export const SaveCancelButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  float: right;
`

export const ColumnCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const FilterDropDownWrapper = styled.div``
export const MoreFilterButtonWrapper = styled.div`
  position: relative;
`
export const ButtonStyleWidth = styled.div`
  width: 8rem;
`
export const MainFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const DefaultFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.5rem;
  position: relative;
`
export const LabelWrapper = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
`
export const FlagIconLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14px;
`
