/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  data: any
  isLoading: boolean
  httpErrorMessage:string,
  successMessage:string
}

const initialState: State = {
  data: [],
  isLoading: false,
  httpErrorMessage:'',
  successMessage:''
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'country-code',
  initialState,
  reducers: {
    setCurrentData: (state: State, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const { setCurrentData, setIsLoading, setHttpErrorMessage, setSuccessMessage } = actions

export const getDataList: any = () => async (dispatch: Dispatch) => {
  try {
    const { data, status } = await authApiCall.get(
      'opsstaticdataapi/StaticData/GetFisCountryDetails'
    )
    dispatch(setIsLoading(true))
    if (status === 200) {
      dispatch(setCurrentData(data))
      dispatch(setIsLoading(false))
      return 200
    }
  } catch (e: any) {
    handleHttpError(dispatch, e, setHttpErrorMessage)
    dispatch(setIsLoading(false))
    
  }
  dispatch(setIsLoading(false))
}

export const addList: any =
(id:number, countryName: string, irsCountryCode: string,isoCountryCode:string, isActive: boolean,updatedBy=localStorage.getItem('AccountName'),updatedDate=new Date().toISOString().split('T')[0]) =>
async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { status } = await authApiCall.post(
      'opsstaticdataapi/StaticData/AddFisCountry',
      {
        
          id,
          countryName,
          irsCountryCode,
          isoCountryCode,
          isActive,
          updatedBy,
          updatedDate
        
      }
    )
      if (status === 200) {
        dispatch(setSuccessMessage('Added Successfully'))
        dispatch(setIsLoading(false))
        
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      
    }
  }
export const updateList: any =
  (id:number, countryName: string, irsCountryCode: string,isoCountryCode:string, isActive: boolean,updatedBy=localStorage.getItem('AccountName'),updatedDate=new Date().toISOString().split('T')[0]) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.put(
        'opsstaticdataapi/StaticData/UpdateFisCountry',
        {
          
            id,
            countryName,
            irsCountryCode,
            isoCountryCode,
            isActive,
            updatedBy,
            updatedDate
          
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Updated Successfully'))
        dispatch(setIsLoading(false))
        
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      
    }
  }

export const deactivateMapping: any =
(id:number, countryName: string, irsCountryCode: string,isoCountryCode:string, isActive: boolean,updatedBy=localStorage.getItem('AccountName'),updatedDate=new Date().toISOString().split('T')[0]) =>
async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { status } = await authApiCall.put(
      'opsstaticdataapi/StaticData/UpdateFisCountry',
      {
        
          id,
          countryName,
          irsCountryCode,
          isoCountryCode,
          isActive,
          updatedBy,
          updatedDate
        
      }
    )
      if (status === 200) {
        dispatch(setSuccessMessage('Deactivated Successfully'))
        dispatch(setIsLoading(false))
        
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      
    }
  }

export const currentDataSelector = (store: StoreState) =>
  store.valdi.countryCode.data
export const LoadingSelector = (store: StoreState) =>
  store.valdi.countryCode.isLoading
  export const HttpErrorMessageSelector = (store: StoreState) =>
 store.valdi.countryCode.httpErrorMessage
  
  export const HttpSuccessMessageSelector = (store: StoreState) =>
 store.valdi.countryCode.successMessage

export default reducer
