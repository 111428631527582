/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  action: string
  isLoading: boolean
  data: any
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  action: 'search',
  isLoading: false,
  data: [],
  httpErrorMessage: '',
  successMessage: '',
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'p3-recon',
  initialState,
  reducers: {
    setCurrentData: (state: State, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
      state.isLoading = true
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  setCurrentData,
  setIsLoading,
  setAction,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getP3ReconData: any =
  (ReportDate: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        'opsstaticdataapi/StaticData/GetFlexTradeReconReport',
        {
          params: {
            ReportDate,
          },
        }
      )

      if (status === 200) {
        dispatch(setCurrentData(data))
        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
    dispatch(setIsLoading(false))
  }

export const getP3ReconCSV: any =
  (fileName: string, ReportDate: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        `opsstaticdataapi/StaticData/GetFlexTradeReconReport_CsvDownload?ReportDate=${ReportDate}`,
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return 400
    }
  }

export const isLoadingSelector = (store: StoreState) =>
  store.valdi.P3Recon.isLoading
export const dataSelector = (store: StoreState) => store.valdi.P3Recon.data

export const HttpErrorMessageSelector = (store: StoreState) =>
  store.valdi.P3Recon.httpErrorMessage

export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.valdi.P3Recon.successMessage
export default reducer
