/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { authApiCall } from '../../utils/api'

interface State {
  securityUpdatesManagement: any
  securityUpdatesDetails: any
  P3UnSpecifiedSecurityUpdate: any
  requestType: ['']
  isLoading: boolean
  action: string
}

const initialState: State = {
  securityUpdatesManagement: {},
  securityUpdatesDetails: [],
  P3UnSpecifiedSecurityUpdate: [],
  requestType: [''],
  isLoading: true,
  action: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'trade-break',
  initialState,
  reducers: {
    setSecurityUpdate: (state: State, action: PayloadAction<any>) => {
      state.securityUpdatesManagement = action.payload.data
    },

    setSecurityUpdateDetails: (state: State, action: PayloadAction<any>) => {
      state.securityUpdatesDetails = action.payload.data
    },

    setP3UnSpecifiedSecurityUpdate: (
      state: State,
      action: PayloadAction<any>
    ) => {
      state.P3UnSpecifiedSecurityUpdate = action.payload.data
    },
    setRequestType: (state: State, action: PayloadAction<any>) => {
      state.requestType = action.payload.data
    },

    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
  },
})

export const {
  setAction,
  setSecurityUpdate,
  setSecurityUpdateDetails,
  setP3UnSpecifiedSecurityUpdate,
  setIsLoading,
  setRequestType,
} = actions

export const getRequestType: any = () => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.get(
      `opsstaticdataapi/StaticData/GetP3RequestTypeList`
    )

    if (status === 200) {
      dispatch(
        setRequestType({
          data: data.p3RequestTypeMasterList,
        })
      )
      dispatch(setIsLoading(false))
      return data
    }
  } catch (e: any) {
    dispatch(
      setRequestType({
        data: [],
      })
    )
    dispatch(setIsLoading(false))
  }
}

export const AddP3RequestTypeMaster: any =
  (REQUEST: []) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/StaticData/AddP3RequestTypeMaster`,
        REQUEST
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return []
    }
  }

export const getSecurityUpdate: any =
  (reportDate: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/StaticData/GetViewDetailP3StaticSecurityUpdateCount?reportDate=${reportDate}`
      )

      if (status === 200) {
        dispatch(
          setSecurityUpdate({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(
        setSecurityUpdate({
          data: [],
        })
      )
      dispatch(setIsLoading(false))
    }
  }

export const getSecurityUpdateDetails: any =
  (
    fromDate: string,
    toDate: string,
    cusip: string,
    pageNumber: string,
    pageSize: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/StaticData/GetStaticDataSecurityUpdateDetails`,
        { fromDate, toDate, cusip, pageNumber, pageSize }
      )

      if (status === 200) {
        dispatch(
          setSecurityUpdateDetails({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(
        setSecurityUpdateDetails({
          data: [],
        })
      )
      dispatch(setIsLoading(false))
    }
  }

export const getP3UnSpecifiedSecurityUpdateCount: any =
  (reportDate: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/StaticData/GetP3UnSpecifiedSecurityUpdateCount?reportDate=${reportDate}`
      )

      if (status === 200) {
        dispatch(
          setP3UnSpecifiedSecurityUpdate({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(
        setSecurityUpdateDetails({
          data: [],
        })
      )
      dispatch(setIsLoading(false))
    }
  }

export const getSecurityUpdateDetailsExcel: any =
  (
    fromDate: string,
    toDate: string,
    cusip: string,
    pageNumber: string,
    pageSize: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `/opsstaticdataapi/StaticData/ExportStaticDataSecurityUpdateDetailsReport`,
        { fromDate, toDate, cusip, pageNumber, pageSize }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'SecurityUpdateDetails.xlsx')
        document.body.appendChild(link)
        link.click()

        dispatch(setIsLoading(false))
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
    }
  }

export const securityUpdateDetailsSelector = (store: StoreState) =>
  store.SecurityUpdates.SecurityUpdate.securityUpdatesDetails

export const p3UnSpecifiedSecurityUpdateSelector = (store: StoreState) =>
  store.SecurityUpdates.SecurityUpdate.P3UnSpecifiedSecurityUpdate

export const securiyUpdateAccountSelector = (store: StoreState) =>
  store.SecurityUpdates.SecurityUpdate.securityUpdatesManagement

export const requestTypeSelector = (store: StoreState) =>
  store.SecurityUpdates.SecurityUpdate.requestType

export const actionSelector = (store: StoreState) =>
  store.SecurityUpdates.SecurityUpdate.action

export const loadingSelector = (store: StoreState) =>
  store.SecurityUpdates.SecurityUpdate.isLoading

export default reducer
