import {
  RootContainerTable,
  ChartContainerByTradeBreakByAgeFI,
  TitleContainer,
  TableChartWidgetContainerByFI,
  LottieWrapper,
} from './styles'
import CreateTable from '../createTable'
import { useEffect, useState } from 'react'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles'
import { authApiCall } from '../../../../utils/api'
import BarChart from '../../../bar/barChart'
import {
  formattingDate,
  formattingCurrencyWithOutDecimal,
  formattingNumber,
  formattingDateWithTime,
} from '../../../../utils/globalFunctions'
import { configJSONDashboard } from '../../../../utils/config'
import Loader from '../../../../assets/lottiefiles/loader.json'
import Label from '../../../label/Label'
import Lottie from 'lottie-react'

const columns = [
  { Header: 'Age', accessor: 'age' },
  { Header: '# of Breaks', accessor: 'number' },
  { Header: 'Quantity', accessor: 'quantity' },
  { Header: 'Amount', accessor: 'amount' },
]

const TradeBreakByAgeFI = (props: {
  isIntraday?: boolean
  widgetDescription: any
}) => {
  const [loading, isLoading] = useState<boolean>(true)
  const [datas, setDatas] = useState<any>([])
  const [sum, setSum] = useState<any>(0)
  const [t0Data, sett0Date] = useState<any>(null)
  const [legendLabels, setlegendLabels] = useState<any>([])
  const [responseData, seResponseData] = useState<any>([])

  const Table = (props: any) => {
    const formattedData = props.data.map(
      (r: {
        age: string
        number: string
        amount: number
        quantity: number
      }) => {
        return {
          ...r,
          quantity: r.quantity ? formattingNumber(r.quantity) : null,
          amount: r.amount && formattingCurrencyWithOutDecimal(r.amount),
        }
      }
    )

    return (
      <RootContainerTable>
        <CreateTable data={formattedData} columns={props.columns} />
      </RootContainerTable>
    )
  }
  useEffect(() => {
    const getAge = async () => {
      let api = ''
      if (props?.isIntraday) {
        api = configJSONDashboard.IntraDayTradeBreaks.tradeBreaksByAgeFI.kpi
      } else {
        api = configJSONDashboard.TradeBreaks.tradeBreaksByAgeFI.kpi
      }

      try {
        const { data, status } = await authApiCall.post(api)
        if (status === 200) {
          if (props?.isIntraday) {
            sett0Date(formattingDateWithTime(data.currentDate))
          } else {
            sett0Date(formattingDate(data.currentDate))
          }
          const formatDataChart: {
            label: string
            data: any
            barThickness: number
            backgroundColor: any
            borderColor: any
            borderWidth: any
            stack: string
          }[] = []
          const responseData = data.tradeBreakTrendByAgeViewModels
          seResponseData(responseData)
          const legendLabels =
            responseData?.length &&
            responseData.map(
              (m: { age: string; number: string }) =>
                m.age.replaceAll(' ', '') + ': ' + m.number
            )
          const bgColors = [
            '#BFDBFE',
            '#60A5FA',
            '#2563EB',
            '#1E40AF',
            '#1E3A8A',
          ]
          const borderColors = [
            '#FFFFFF',
            '#FFFFFF',
            '#FFFFFF',
            '#FFFFFF',
            '#FFFFFF',
          ]

          if (responseData && responseData.length) {
            responseData.map((value: any, index: any) =>
              formatDataChart.push({
                label: legendLabels[index],
                data: [value.number],
                barThickness: 170,
                stack: 'Stack 0',
                backgroundColor: bgColors[index],
                borderColor: borderColors[index],
                borderWidth: 0,
              })
            )
          }
          setDatas(formatDataChart)
          setlegendLabels(legendLabels)
          const sum = responseData?.reduce(
            (a: any, b: any) => a + (b['number'] || 0),
            0
          )
          setSum(sum)
          isLoading(false)
        }
      } catch (e) {
        isLoading(false)
      }
    }
    getAge()
  }, [])

  const options = {
    plugins: {
      title: {
        display: true,
        text: sum ? `Total Breaks ${sum}` : '',
        align: 'start',
        color: '#0F172A',
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        fullSize: true,
        align: 'start',
        labels: {
          color: '#0F172A',
          usePointStyle: true,
          pointStyle: 'rectRounded',
          boxWidth: 24,
          boxHeight: 24,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 30,
      },
    },

    responsive: true,
    scales: {
      y: {
        stacked: true,
        display: false,
      },
      x: {
        display: false,
      },
    },
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
  }

  return (
    <>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <TableChartWidgetContainerByFI>
        <TitleContainer>{props.widgetDescription}</TitleContainer>

        <Table data={responseData ? responseData : []} columns={columns} />
        <ChartContainerByTradeBreakByAgeFI>
          <BarChart
            legendLabels={legendLabels}
            options={options}
            dataValues={datas}
          />
        </ChartContainerByTradeBreakByAgeFI>
      </TableChartWidgetContainerByFI>
    </>
  )
}

export default TradeBreakByAgeFI
