import { ReactNode } from 'react'
import { SvgVector } from '../../../../../components/svg/SvgVector'
import { FlagIconLabelWrapper } from './styles'

interface dropDownProps {
  value: boolean
  label: string | ReactNode
  labelValue?: number
}
interface tableProps {
  Header: string
  value: boolean
  selectedData: string[]
  showDropdown: boolean
  dropDownArray: dropDownProps[]
  addData: string[]
}

export const TableFilterList: tableProps[] = [
  {
    Header: 'Security Number',
    value: true,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Top Category',
    value: true,
    selectedData: [],
    addData: [''],
    showDropdown: false,

    dropDownArray: [
      {
        value: true,
        label: 'Independent Pricing Source For Security Not Available',
      },
      {
        value: true,
        label:
          'Security Currently Not Positioned As Of Report Compilation Date - Pricing Update Not Required',
      },
      {
        value: true,
        label:
          'Security Is No Longer on The System Stale Pricing Report as Of Report Compilation Date',
      },
      {
        value: true,
        label: 'Wedbush Price And Independent Pricing Source Delta < $0.01',
      },

      {
        value: true,
        label:
          'Wedbush Price Does Not Match Independent Pricing Source - Need Further Review',
      },
      {
        value: true,
        label:
          'Wedbush Price Does Not Match Independent Pricing Source and IPS < $0.01',
      },
      { value: true, label: 'Blank' },
    ],
  },
  {
    Header: 'Sub Category',
    value: true,
    selectedData: [],
    addData: [''],
    showDropdown: false,

    dropDownArray: [
      {
        value: true,
        label: 'Alternative Investment/Limited Partnership - Priced Quarterly',
      },
      {
        value: true,
        label:
          'Alternative Investment/Limited Partnership - Reach out to Internal Controls for Pricing',
      },
      {
        value: true,
        label:
          'Alternative Investment/Limited Partnership - Reach out to Brokers/Admins for Pricing',
      },
      {
        value: true,
        label: `Assets Under Customer's Name - Limited/No Pricing Source`,
      },
      {
        value: true,
        label: 'Beta Fixed Pricing In Effect - Confirm Price',
      },
      {
        value: true,
        label: 'Contingent Value Right CUSIP - Limited/No Pricing Source',
      },
      {
        value: true,
        label:
          'DTC Premed eligible, positions maybe taken down - Limited/No Pricing Source',
      },
      {
        value: true,
        label: 'Escrow CUSIP - Limited/No Pricing Source',
      },
      {
        value: true,
        label: 'Foreign Security - Reach out to ICE/Setts/Desk for pricing',
      },
      {
        value: true,
        label: 'Fixed Income security - source price from the desk',
      },
      {
        value: true,
        label: 'Bond is in monetary/actual default - Limited/No Pricing Source',
      },
      {
        value: true,
        label:
          'Delisted or suspended indefinitely (listed securities); not currently evaluated (unlisted securities) - Limited/No Pricing Source',
      },
      {
        value: true,
        label:
          'Expired, matured, fully called, converted, exchanged, or liquidated - Limited/No Pricing Source',
      },
      {
        value: true,
        label:
          'In bankruptcy, receivership, or reorganization - Limited/No Pricing Source',
      },
      {
        value: true,
        label: 'Need further review',
      },

      {
        value: true,
        label: 'Physical Certificate - Limited/No Pricing Source',
      },

      {
        value: true,
        label: 'Restricted Security - Limited/No Pricing Source',
      },
      {
        value: true,
        label: 'Rights/Warrants - Limited/No Pricing Source',
      },
      {
        value: true,
        label: 'See Previous Comments - Update Comments If Needed',
      },
      { value: true, label: 'Blank' },
    ],
  },
  {
    Header: 'Reviewer Latest Comment',
    value: true,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Last price in Current Stale Report',
    value: true,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Flag',
    value: true,
    selectedData: [],
    addData: [''],
    showDropdown: false,

    dropDownArray: [
      {
        value: true,
        label: (
          <FlagIconLabelWrapper>
            Follow Up
            <SvgVector fillColor="red" />
          </FlagIconLabelWrapper>
        ),
        labelValue: 1,
      },
      {
        value: true,
        label: (
          <FlagIconLabelWrapper>
            Reviewed
            <SvgVector fillColor="#008000" />
          </FlagIconLabelWrapper>
        ),
        labelValue: 2,
      },
      {
        value: true,
        label: (
          <FlagIconLabelWrapper>
            No further review required
            <SvgVector fillColor="#737373" />
          </FlagIconLabelWrapper>
        ),
        labelValue: 3,
      },
      {
        value: true,
        label: 'Unflagged',
        labelValue: 0,
      },
    ],
  },
  {
    Header: 'Security Description',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'CUSIP',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'ISIN',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Comments Created By',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Reviewer Last Commented Date(EST)',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'ICE Last Priced',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'ICE Last Price',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'ICE Notes',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },

  {
    Header: 'BBG Last Priced',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'BBG Last Price',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },

  {
    Header: 'Box Location',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Box Description',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Country',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Security Type Code',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Expiration Date/Maturity Date',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'In Default',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Corporate Action 1',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Corporate Action 2',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Surf-User Pricing/Manual',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Surf Last Updated',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Surf Last Price',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'User Initial',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Changed Date',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Market Price',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Changed Terminal',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },

  {
    Header: 'DTC Premed',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },

  {
    Header: 'Last Update',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Last Price',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'S/D',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Cash',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Margin',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'IRA',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'UPX/Z',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },

  {
    Header: 'Currently still in stale report',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Last priced in Current Stale Report',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'All Comments',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
  {
    Header: 'Notes',
    value: false,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [{ value: false, label: 'Blank' }],
  },
]
