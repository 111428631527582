import Checkbox from '../../../../components/checkbox/Checkbox'
import { StyledCheckBox, StyledTableHeader } from './tableStyles'

const TableToggle = (props: any) => {
  return (
    <StyledTableHeader>
      <StyledCheckBox>
        <Checkbox
          checkBoxId={props.checkFilterKeys?.[0]}
          toggleCheckbox={true}
          ToggleButtonPadding={'8px 0px 0px 8px'}
          onChange={props.filterOnChange}
          title={props.toggleLabels[0]}
          checked={props.checkFilter[props.checkFilterKeys?.[0]]}
        />
      </StyledCheckBox>
      <StyledCheckBox>
        <Checkbox
          checkBoxId={props.checkFilterKeys?.[1]}
          toggleCheckbox={true}
          ToggleButtonPadding={'0px'}
          onChange={props.filterOnChange}
          title={props.toggleLabels[1]}
          checked={props.checkFilter[props.checkFilterKeys?.[1]]}
        />
      </StyledCheckBox>
      <StyledCheckBox>
        <Checkbox
          checkBoxId={props.checkFilterKeys?.[2]}
          toggleCheckbox={true}
          ToggleButtonPadding={'0px 8px 8px 0px'}
          onChange={props.filterOnChange}
          title={props.toggleLabels[2]}
          checked={props.checkFilter[props.checkFilterKeys?.[2]]}
        />
      </StyledCheckBox>
    </StyledTableHeader>
  )
}
export default TableToggle
