import styled from "styled-components";
import { COLORS } from "../../../theme/Colors";

export const RootContainerPage = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  color: ${COLORS.Background.DarkCyanBlueBackground};
  background-color: 'transparent';
`