/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-autofocus */
import { useRef, useState } from 'react'
import DatePicker from '../../../../components/datePicker/DatePicker'
import { Textbox } from '../../../../components/textbox/Textbox'
import {
  formattingCurrencyWithDecimal,
  formattingDate,
  formattingNumber,
} from '../../../../utils/globalFunctions'
import { TextWrapper } from './tableStyles'
import SelectDropdown from '../../../../components/selectDropdown/SelectDropdown'
import Label from '../../../../components/label/Label'
import { TableInstance } from 'react-table'
import { BuyInReceiveExceptionModel } from '../IbuyinReceive'
import { z } from 'zod'
import { SvgEye } from '../../../../components/svg/SvgEye'
import { colors } from 'react-select/dist/declarations/src/theme'
import { Colors } from '../../../../shared/styles'
import Button from '../../../../components/button/Button'
import {
  BuyInExecutionButton,
  BuyInExecutionInput,
} from '../../../regSHOTool/BuyInAllocationAndExecution/buyInCommonComponents'
import {
  SettlementExecutionButton,
  SettlementExecutionInput,
} from '../BuyInSettlementCommonComp'

interface ErrorReferenceArray {
  selectedIndex: number
  orginalIndex: number
}
const noValue = [null, '', undefined]
const displayError: ErrorReferenceArray[] = []

const renderError = (
  element: string,
  error?: z.ZodError[],
  displayError?: any,
  currentKey?: number
) => {
  if (error && error.length) {
    const elementValue = element.charAt(0).toLowerCase() + element.slice(1)
    const errorText = error.find((e: any) => {
      const isArray = displayError?.find(
        (test: any) => test.orginalIndex === currentKey
      )
      if (
        e.path[0] == isArray?.selectedIndex &&
        isArray?.orginalIndex == currentKey
      ) {
        return e.path[1] == elementValue
      }
    })
    if (errorText) {
      return `*${errorText.message}`
    } else {
      return null
    }
  } else return null
}

const getSelectedIndex = (
  tableData: any,
  selectedArray: BuyInReceiveExceptionModel[],
  currentId?: number
) => {
  tableData?.data.map((parent: BuyInReceiveExceptionModel, key: number) => {
    selectedArray.map((e: BuyInReceiveExceptionModel, index: number) => {
      if (e.ftrUniqueId === parent.ftrUniqueId && key === currentId) {
        if (displayError.length) {
          const isElement = displayError.find(
            (e: any) => e.selectedIndex == index
          )
          if (!isElement) {
            displayError.push({
              selectedIndex: index,
              orginalIndex: key,
            })
          }
        } else {
          displayError.push({
            selectedIndex: index,
            orginalIndex: key,
          })
        }
      }
    })
  })
}

const secTypeOptions = [
  {
    value: 'STK',
    label: 'STK',
  },
  {
    value: 'MUNI',
    label: 'MUNI',
  },
  {
    value: 'CORP',
    label: 'CORP',
  },
]
export const BuyInDeliverSettlementsColumns = [
  {
    Header: ' ',
    columns: [
      {
        Header: 'CUSIP',
        accessor: 'dtccCusip',
      },
      {
        Header: 'Broker',
        accessor: 'dtccBroker',
      },

      {
        Header: 'Quantity',
        accessor: (d: { dtccQuantity: number }) => {
          return d.dtccQuantity ? formattingNumber(d.dtccQuantity) : null
        },
      },
      {
        Header: 'Dollar',
        accessor: (d: { dtccDollar: number }) => {
          if (d.dtccDollar) {
            return formattingCurrencyWithDecimal(d.dtccDollar)
          }
        },
      },

      {
        Header: 'Cent',
        accessor: (d: { dtccCent: number }) => {
          if (d.dtccCent) {
            return formattingNumber(d.dtccCent)
          }
        },
      },
      {
        Header: 'Settle Date',
        accessor: (d: { dtccSettleDate: string }) => {
          return formattingDate(d.dtccSettleDate)
        },
      },
      {
        Header: 'Fail Cusip',
        accessor: 'failCusip',
      },

      {
        Header: 'Fail Broker',
        accessor: 'failBroker',
      },
      {
        Header: 'Fail Quantity',
        accessor: 'failQuantity',
      },
      {
        Header: 'Fail Dollar',
        accessor: 'failDollar',
      },
      {
        Header: 'Fail Cent',
        accessor: 'failCent',
      },
      {
        Header: 'FailSettleDate',
        accessor: (d: { failSettleDate: string }) => {
          if (d.failSettleDate) return formattingDate(d.failSettleDate)
        },
      },
      {
        Header: 'Source',
        accessor: 'source',
      },
      {
        Header: 'status',
        accessor: 'status',
      },
      {
        Header: 'JobRun TimeStamp',
        accessor: (d: { jobRunTimeStamp: string }) => {
          if (d.jobRunTimeStamp) return formattingDate(d.jobRunTimeStamp)
        },
      },
    ],
  },
]

export const BuyInReceiveSettlementsColumns = (toggleViewComments: any) => {
  return [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Security Category',
          accessor: 'securityCategory',
        },
        {
          Header: 'CUSIP',
          accessor: 'cusip',
        },
        {
          Header: 'CUSIP Description',
          accessor: 'cusipDesc',
        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
        },
        {
          Header: 'Account Number',
          accessor: 'accountNumber',
        },
        {
          Header: 'Settle Date',
          accessor: (d: any) => {
            return formattingDate(d.settleDate)
          },
        },
        {
          Header: 'Quantity',
          accessor: (d: any) => {
            return d.quantity ? formattingNumber(d.quantity) : null
          },
        },
        {
          Header: 'Dollar',
          accessor: (d: any) => {
            if (d.dollar) {
              return formattingCurrencyWithDecimal(d.dollar)
            }
          },
        },
        {
          Header: 'Cent',
          accessor: (d: any) => {
            if (d.cent) {
              return formattingNumber(d.cent)
            }
          },
        },
        {
          Header: 'Sec Type',
          accessor: 'secType',
        },
        {
          Header: 'Delivery Broker',
          accessor: 'delvBroker',
        },
        {
          Header: 'Trade Date',
          accessor: (d: any) => {
            return formattingDate(d.tradeDate)
          },
        },
        {
          Header: 'CounterParty Type',
          accessor: 'counterpartyType',
        },
        {
          Header: 'Delivery date',
          accessor: (d: any) => {
            if (d.businessDays) return formattingDate(d.businessDays)
          },
        },
        {
          Header: 'Source',
          accessor: 'source',
        },
        {
          Header: 'Audit Trail',
          Cell: (table: {
            cell: { row: { index: number; original: any } }
          }) => {
            return table.cell?.row?.original?.saveFixFlag == 1 ? (
              <span
                role="presentation"
                title="View"
                style={{ cursor: 'pointer', width: '5px' }}
                onClick={() => {
                  toggleViewComments(table.cell?.row?.original)
                }}
              >
                <SvgEye fillColor={Colors.Black} />
              </span>
            ) : (
              '-'
            )
          },
        },
      ],
    },
  ]
}

export const BuyInReceiveErrorSettlementsColumns = (
  toggleViewComments: any,
  saveColumnValues: any,
  executionUserInputsRef: React.MutableRefObject<any>,
  setExecutionUserInputs: React.Dispatch<any>
) => {
  return [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Security Category',
          accessor: 'securityCategory',
        },
        {
          Header: 'CUSIP',
          accessor: 'cusip',
        },
        {
          Header: 'CUSIP Description',
          accessor: 'cusipDesc',
        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
        },
        {
          Header: 'Account Number',
          accessor: 'accountNumber',
        },
        {
          Header: 'Settle Date',
          accessor: 'settleDate',
        },
        {
          Header: 'Quantity',
          accessor: (d: BuyInReceiveExceptionModel) => {
            return d.quantity ? formattingNumber(d.quantity) : null
          },
        },

        {
          Header: 'Dollar',
          accessor: (d: BuyInReceiveExceptionModel) => {
            if (d.dollar) {
              return formattingCurrencyWithDecimal(d.dollar)
            }
          },
        },
        {
          Header: 'Cent',
          accessor: (d: BuyInReceiveExceptionModel) => {
            if (d.cent) {
              return formattingNumber(d.cent)
            }
          },
        },
        {
          Header: 'Sec Type',
          objectKey: 'secType',
          Cell: ({ row, column }: any) => {
            return !row?.original?.secType?.trim() ||
              row?.original?.secType === null ? (
              <SettlementExecutionInput
                disableFunction={false}
                type={'text'}
                parentValue={executionUserInputsRef}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setExecutionUserInputs}
              />
            ) : (
              row?.original?.secType
            )
          },
        },
        {
          Header: 'Delivery Broker',
          objectKey: 'delvBroker',
          Cell: ({ row, column }: any) => {
            return !row?.original?.delvBroker?.trim() ||
              row?.original?.delvBroker === null ? (
              <SettlementExecutionInput
                disableFunction={false}
                type={'text'}
                parentValue={executionUserInputsRef}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setExecutionUserInputs}
              />
            ) : (
              row?.original?.delvBroker
            )
          },
        },
        {
          Header: 'Trade Date',
          accessor: (d: { tradeDate: string; settleDate: string }) => {
            return (
              <span
                style={{
                  color:
                    d.tradeDate > d.settleDate ? Colors.Danger : Colors.Black,
                  display: 'inline-block',
                  fontWeight: d.tradeDate > d.settleDate ? 'bold' : 'normal',
                }}
              >
                {formattingDate(d.tradeDate)}
              </span>
            )
          },
        },
        {
          Header: 'CounterParty Type',
          accessor: 'counterpartyType',
        },
        {
          Header: 'Delivery date',
          accessor: (d: any) => {
            if (d.businessDays) return formattingDate(d.businessDays)
          },
        },
        {
          Header: 'Source',
          accessor: 'source',
        },
        {
          Header: 'Status',
          accessor: (d: BuyInReceiveExceptionModel) => {
            if (d.status === 'E') return 'Exception'
            else if (d.status === 'F') return 'Fixed'
            else return 'Pending'
          },
        },
        {
          Header: 'Save',
          objectKey: 'save',
          Cell: ({ row }: any) => {
            return (
              <SettlementExecutionButton
                disableFunction={false}
                parentValue={executionUserInputsRef}
                label={'Save'}
                tableId={row.index}
                currentRow={row}
                UpdateBuyInIntentRecords={saveColumnValues}
              />
            )
          },
        },
        {
          Header: 'Fix',
          objectKey: 'fix',
          Cell: ({ row }: any) => {
            return (
              <SettlementExecutionButton
                disableFunction={
                  !(
                    row?.original?.secType &&
                    row?.original?.secType?.trim() &&
                    row?.original?.delvBroker &&
                    row?.original?.delvBroker?.trim()
                  )
                }
                parentValue={executionUserInputsRef}
                label={'Fix'}
                tableId={row.index}
                currentRow={row}
                UpdateBuyInIntentRecords={saveColumnValues}
              />
            )
          },
        },
        {
          Header: 'Audit Trail',
          Cell: (table: {
            cell: { row: { index: number; original: any } }
          }) => {
            return (
              <span
                role="presentation"
                title="View"
                style={{ cursor: 'pointer', width: '5px' }}
                onClick={() => {
                  toggleViewComments(table.cell?.row?.original)
                }}
              >
                <SvgEye fillColor={Colors.Black} />
              </span>
            )
          },
        },
      ],
    },
  ]
}

export const BuyInReceiveDTCCStatusColumns = (toggleError: any) => {
  return [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Security Category',
          accessor: 'securityCategory',
        },
        {
          Header: 'CUSIP',
          accessor: 'cusip',
        },
        {
          Header: 'CUSIP Description',
          accessor: 'cusipDesc',
        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
        },
        {
          Header: 'Account Number',
          accessor: 'accountNumber',
        },
        {
          Header: 'Settle Date',
          accessor: (d: any) => {
            return formattingDate(d.settleDate)
          },
        },
        {
          Header: 'Quantity',
          accessor: (d: any) => {
            return d.quantity ? formattingNumber(d.quantity) : null
          },
        },

        {
          Header: 'Dollar',
          accessor: (d: any) => {
            if (d.dollar) {
              return formattingCurrencyWithDecimal(d.dollar)
            }
          },
        },
        {
          Header: 'Cent',
          accessor: (d: any) => {
            if (d.cent) {
              return formattingNumber(d.cent)
            }
          },
        },
        {
          Header: 'Sec Type',
          accessor: 'secType',
        },
        {
          Header: 'Delivery Broker',
          accessor: 'delvBroker',
        },
        {
          Header: 'Trade Date',
          accessor: (d: any) => {
            return formattingDate(d.tradeDate)
          },
        },
        {
          Header: 'CounterParty Type',
          accessor: 'counterpartyType',
        },
        {
          Header: 'Delivery date',
          accessor: (d: any) => {
            if (d.businessDays) return formattingDate(d.businessDays)
          },
        },
        {
          Header: 'Source',
          accessor: 'source',
        },
        {
          Header: 'Status',
          accessor: 'dtccStatus',
        },
        {
          Header: 'Reject Reason',
          accessor: 'rejectCode',
        },
        {
          Header: 'Auto Resubmit Count',
          accessor: 'resubmitCount',
        },
        {
          Header: 'Action',
          Cell: (table: {
            cell: { row: { index: number; original: any } }
          }) => {
            return (
              <span
                role="presentation"
                title="View"
                style={{ cursor: 'pointer', width: '25px' }}
                onClick={() => {
                  table.cell?.row?.original.errorFlag &&
                    toggleError(table.cell?.row?.original)
                }}
              >
                <SvgEye
                  fillColor={
                    table.cell?.row?.original.errorFlag
                      ? Colors.Danger
                      : Colors.Black
                  }
                />
              </span>
            )
          },
        },
      ],
    },
  ]
}
