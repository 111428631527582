import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const RootContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`
export const PopUpStyleLoader = styled.div`
  background: #f0f2f5;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const LottieWrapper = styled.div`
  // position: fixed;
  // top: 70%;
  // left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 12px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`
export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 97.3vw;
  margin-top: 16px;
  border: 1px solid ${COLORS.Background.StrongBackgound};
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  padding: 16px 25px 16px 25px;
  gap: 20px;
`
export const FilterChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`
export const RadioCommentWrapper = styled.div`
  margin-top: 1.5%;
`

export const MarginLeftAutoSpace = styled.div`
  margin-left: auto;
  margin-top: 1.5%;
`

export const HeadingDownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1% 1%;
  gap: 10px;
`
export const DownloadButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`
export const FilterOptionWrapper = styled.div`
  width: 18%;
`
export const PopupCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 16px;
  gap: 8px;
  position: absolute;
  width: 397px;
  height: 172px;
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  // padding: 2px 0px 0px 0px;
  // justify-content: 'flex-end';
  // width: 100%;
  // flex-wrap: wrap;
  // font-style: normal;
  // font-weight: 400;
  // font-size: 16px;
  // line-height: 24px;

  // background: ${COLORS.Background.Primary};
  // > * {
  //   margin-right: 16px;
  // }
`
export const StyledTableContainer = styled.div`
  width: 100%;
  padding: 8px 16px 24px 16px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`
export const MarginTopSpace = styled.div`
  margin-top: 16px;
`
export const PopUpStyleContent = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 60%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`
export const CancelButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`
export const CommentTableContainer = styled.div`
  width: 100%;
  padding: 24px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  overflow-y: scroll;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
