import styled from 'styled-components'
import { BreadCrumbProps } from './IbreadCrumbs'


export const StyledBreadCrumb = styled.ul`
padding: 16px 16px 16px 16px;
list-style: none;
`
export const StyledLink = styled.li`
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
`
export const StyledLinkTag=styled.a`
    color: ${(props: BreadCrumbProps) => props.active ?  '#0F172A' : '#2563EB' };
    text-decoration: none;
`