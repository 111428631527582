import SecuritiesToP3 from './securitiesToP3/SecuritiesToP3'
import P3Reconciliation from './p3Reconciliation/P3Reconciliation'
import P3MarketMakerSecurities from './p3MarketMakerSecurities/P3MarketMakerSecurities'
import { RootContainer } from './styles'
import CountryCode from './countryCode/CountryCode'
import FISIRSISOCountryReconciliation from './countryCodeRecon/CountryCodeRecon'
import BetaStalePricing from './stalePricing/betaStalePricing/BetaStalePricing'
import P3StalePricing from './stalePricing/p3StalePricing/P3StalePricing'
import  BetaStalePricingHistory from './stalePricing/betaStalePricing/betaStalePricingHistory'
import P3StalePricingHistory from './stalePricing/p3StalePricing/P3StalePricingHistory'
import ReconHistory from './ReconHistoryReport/ReconHistory'

export const SecuritiesToP3Translator = () => {
  return (
    <RootContainer>
      <SecuritiesToP3 />
    </RootContainer>
  )
}
export const P3ReconciliationPage = () => {
  return (
    <RootContainer>
      <P3Reconciliation />
    </RootContainer>
  )
}
export const CountryCodePage = () => {
  return (
    <RootContainer>
      <CountryCode />
    </RootContainer>
  )
}
export const P3MarketMakerSecuritiesPage = () => {
  return (
    <RootContainer>
      <P3MarketMakerSecurities />
    </RootContainer>
  )
}

export const FISIRSISOCountryReconciliationPage = () => {
  return (
    <RootContainer>
      <FISIRSISOCountryReconciliation />
    </RootContainer>
  )
}

export const BetaStalePricingPage = () => {
  return (
    <RootContainer>
      <BetaStalePricing />
    </RootContainer>
  )
}

export const P3StalePricingPage = () => {
  return (
    <RootContainer>
      <P3StalePricing />
    </RootContainer>
  )
}

export const BetaStalePricingPageHistorical = () => {
  return (
    <RootContainer>
      <BetaStalePricingHistory />
    </RootContainer>
  )
}

export const P3StalePricingPageHistorical = () => {
  return (
    <RootContainer>
      <P3StalePricingHistory />
    </RootContainer>
  )
  
}

export const ReconHistoryPage = () => {
  return (
    <RootContainer>
      <ReconHistory />
    </RootContainer>
  )
}