import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/button/Button'
import DatePicker from '../../../components/datePicker/DatePicker'
import Label from '../../../components/label/Label'
import { Textbox } from '../../../components/textbox/Textbox'
import { Toast } from '../../../components/toast/Toast'
import {
  getTradeRemediationData,
  tradeRemediationSelector,
  loadingSelector,
} from '../../../store/tradeRemediation/tradeRemediation-management'
import {
  StyledPanelSelect,
  RootContainer,
  StyledFilter,
  StyledButtonWrapper,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledFilterWrapper,
  ToastWrapper,
  LottieWrapper,
} from './styles'
import {
  formattingDateMonthOnly,
  formattingDateOnly,
  formattingDateYearOnly,
  getAccessFlag,
} from '../../../utils/globalFunctions'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { PopUpStyleLoader } from './styles'

const TradeRemediation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const tradeRemediationData = useSelector(tradeRemediationSelector)
  const loading = useSelector(loadingSelector)
  const [tradeDetails, setTradeDetails] = useState<any>({
    tradeNumber: null,
    tradeDate: new Date().toISOString().split('T')[0],
  })
  const [toggleModal, settoggleModal] = useState(false)
  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)

  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['TR-RWX', 'OM-RWX']))
  }, [])

  const getTradeDetails = async (mode: string) => {
    const tradeDate = tradeDetails.tradeDate
      ? tradeDetails.tradeDate.split('-')
      : []

    const tradeDetailsResponse = await dispatch(
      getTradeRemediationData({
        header: {
          functionMode: 'RTV',
          screenName: 'ORDGTI',
          error: '',
          returnValue: '',
          noOfErrors: '',
          dateFormat: '',
          returnData: '',
          firmNumber: '23',
          mode: '',
          userId: 'API',
        },
        body: {
          tradeMonth: tradeDate?.[1],
          tradeDay: tradeDate?.[2],
          tradeYear: tradeDate?.[0].substring(2, 4),
          tradeNumber: tradeDetails.tradeNumber,
        },
        trailer: {},
        session: {},
      })
    )
    if (
      tradeDetailsResponse &&
      tradeDetailsResponse.fisTradeBreakResponseViewModelAsJson &&
      tradeDetailsResponse.statusCode &&
      tradeDetailsResponse.statusCode === 200
    ) {
      navigate('/tradeFix', {
        state: { passProps: { tradeDetails, action: mode } },
      })
    } else {
      settoggleModal(true)
    }
  }

  const TradeRetrieve = async () => {
    await getTradeDetails('fix')
  }

  const TradeReinstantiate = async () => {
    await getTradeDetails('reinstantiate')
  }

  const CancelTrade = async () => {
    await getTradeDetails('cancel')
  }

  const AddTrade = async () => {
    navigate('/tradeFix', {
      state: { passProps: { tradeDetails, action: 'add' } },
    })
  }

  return (
    <RootContainer>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      {toggleModal && (
        <ToastWrapper>
          <Toast
            text={
              tradeRemediationData?.successMessageFromFIS
                ? tradeRemediationData.successMessageFromFIS
                : tradeRemediationData?.errorResponse?.guid +
                  ` - ` +
                  tradeRemediationData?.errorResponseFromFIS
            }
            type={
              tradeRemediationData?.statusCode == 200 ? 'success' : 'danger'
            }
            openStatusCallback={() => settoggleModal(false)}
          />
        </ToastWrapper>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>Trade Remediation</StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledFilter>
          <StyledFilterWrapper>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Trade Number
              </Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                name={''}
                value={tradeDetails.tradeNumber}
                onChange={(e) => {
                  setTradeDetails({
                    ...tradeDetails,
                    tradeNumber: e,
                  })
                }}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Trade Date
              </Label>
              <DatePicker
                onChange={(e: any) => {
                  setTradeDetails({ ...tradeDetails, tradeDate: e })
                }}
                max={new Date().toISOString().split('T')[0]}
                bgColor=""
                borderColor=""
                placeHolder="From"
                padding="4px 8px"
                maxWidth="180px"
                value={tradeDetails.tradeDate}
                defaultValue={new Date().toISOString().split('T')[0]}
              />
            </div>
          </StyledFilterWrapper>
          <StyledFilterWrapper>
            <StyledButtonWrapper>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'10px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                margin={'0px 6px'}
                onClick={() => {
                  TradeRetrieve()
                }}
                borderColor={'#2563EB'}
                disabled={
                  writeExecuteFlag &&
                  tradeDetails.tradeNumber &&
                  tradeDetails.tradeDate
                    ? false
                    : true
                }
              >
                Trade Retrieve
              </Button>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'10px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                margin={'0px 6px'}
                onClick={() => {
                  AddTrade()
                }}
                borderColor={'#2563EB'}
                disabled={!writeExecuteFlag}
              >
                New Trade
              </Button>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'10px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                margin={'0px 6px'}
                onClick={() => {
                  CancelTrade()
                }}
                borderColor={'#2563EB'}
                disabled={
                  writeExecuteFlag &&
                  tradeDetails.tradeNumber &&
                  tradeDetails.tradeDate
                    ? false
                    : true
                }
              >
                Cancel Trade
              </Button>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'10px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                margin={'0px 6px'}
                onClick={() => {
                  TradeReinstantiate()
                }}
                borderColor={'#2563EB'}
                disabled={
                  writeExecuteFlag &&
                  tradeDetails.tradeNumber &&
                  tradeDetails.tradeDate
                    ? false
                    : true
                }
              >
                Trade Re-instantiate
              </Button>
            </StyledButtonWrapper>
          </StyledFilterWrapper>
        </StyledFilter>
      </StyledPanelSelect>
    </RootContainer>
  )
}
export default TradeRemediation
