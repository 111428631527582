/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
    action: string
    isLoading: boolean
    data: any
    httpErrorMessage: string
    successMessage: string
  }
  
  const initialState: State = {
    action: 'search',
    isLoading: false,
    data: [],
    httpErrorMessage: "",
    successMessage: "",
  }

  // TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
    name: 'country-code-recon',
    initialState,
    reducers: {
      setCurrentData: (state: State, action: PayloadAction<any>) => {
        state.data = action.payload
      },
      setIsLoading: (state: State, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload
      },
      setAction: (state: State, action: PayloadAction<string>) => {
        state.action = action.payload
        state.isLoading = true
      },
      setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
        state.httpErrorMessage = action.payload
      },
      setSuccessMessage: (state: State, action: PayloadAction<string>) => {
        state.successMessage = action.payload
      },
    },
  })

  export const { setCurrentData, setIsLoading, setAction,setSuccessMessage,setHttpErrorMessage } = actions

  export const getCountryCodeRecon: any = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        'opsstaticdataapi/StaticData/GetFIS_IRS_ISO_CountryReconciliation'
      )
  
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setCurrentData(data))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
    dispatch(setIsLoading(false))
  }
  
  export const getCountryCodeReconCSV: any =
  (fileName: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        'opsstaticdataapi/StaticData/GetFIS_IRS_ISO_CountryReconciliation_CsvReport',
        { responseType: 'blob' }
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Downloaded Successfully'))
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return 400
    }
  }

  export const isLoadingSelector = (store: StoreState) =>
  store.valdi.countryCodeRecon.isLoading
  export const dataSelector = (store: StoreState) =>
  store.valdi.countryCodeRecon.data
  export const HttpErrorMessageSelector = (store: StoreState) =>
  store.failAnalysis.betafailanalysis.httpErrorMessage

export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.failAnalysis.betafailanalysis.successMessage
  export default reducer