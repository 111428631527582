let emailIds = [] as any;
if (process.env.NODE_ENV?.toLowerCase().includes('development')) {
    emailIds = ['nanda_doneparthy@infosys.com','ankit.tiwari09@infosys.com',"ashish.tripathi@wedbush.com"]
}
else if (process.env.NODE_ENV?.toLowerCase().includes('dev')) {
    emailIds = ['nanda_doneparthy@infosys.com','ankit.tiwari09@infosys.com',"ashish.tripathi@wedbush.com"]
} else if (process.env.NODE_ENV?.toLowerCase().includes('prod')) {
    emailIds = ['nanda_doneparthy@infosys.com','ankit.tiwari09@infosys.com','juan.sanchez@wedbush.com','joseph.henry@wedbush.com','zeke.dizon@wedbush.com']
} else if (process.env.NODE_ENV?.toLowerCase().includes('uat')) {
    emailIds = ['nanda_doneparthy@infosys.com','ankit.tiwari09@infosys.com','juan.sanchez@wedbush.com','joseph.henry@wedbush.com','zeke.dizon@wedbush.com']
} else {
    emailIds = ['nanda_doneparthy@infosys.com','ankit.tiwari09@infosys.com',"ashish.tripathi@wedbush.com"]
}
export {
    emailIds 
}
