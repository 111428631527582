/* eslint-disable @typescript-eslint/no-explicit-any */
import { formattingDate } from '../../../utils/globalFunctions'
import { JamsJobProps } from './IJAMSJobReport'
import JAMSJobReportTable from './jamsJobReportTable/JAMSJobReportTable'
import { StyledTableContainer, StatusIcon } from './styles'

const JAMSJobReportColumns = (props: JamsJobProps) => {
  const tableColumn = [
    {
      Header: 'Job Name',
      accessor: 'jobName',
    },
    {
      Header: 'JobStatus',
      accessor: (d: any) => {
        return d.jobStatus == 'C' ? (
          <StatusIcon color="#008000" />
        ) : d.jobStatus == 'R' ? (
          <StatusIcon color="#ffbf00" />
        ) : (
          <StatusIcon color="#ff0000" />
        )
      },
    },
    {
      Header: 'Module Name',
      accessor: 'moduleName',
    },
    {
      Header: 'Processed Records',
      accessor: 'processedRecords',
    },
    {
      Header: 'Skipped Records',
      accessor: 'skippedRecords',
    },
    {
      Header: 'Table Name',
      accessor: 'tableName',
    },
    {
      Header: 'Report Date',
      accessor: (d: any) => {
        return formattingDate(d.reportDate)
      },
    },
    {
      Header: 'Start Time',
      accessor: 'startTime',
    },
    {
      Header: 'End Time',
      accessor: 'endTime',
    },
    {
      Header: 'Duration',
      accessor: 'duration',
    },
    {
      Header: 'Run Count',
      accessor: 'runCount',
    },
    {
      Header: 'Created Date',
      accessor: (d: any) => {
        return formattingDate(d.createdDate)
      },
    },
    {
      Header: 'CreatedBy',
      accessor: 'createdBy',
    },
    {
      Header: 'Modified Date',
      accessor: (d: any) => {
        return formattingDate(d.modifiedDate)
      },
    },
    {
      Header: 'ModifiedBy',
      accessor: 'modifiedBy',
    },
  ]
  return (
    <StyledTableContainer>
      <JAMSJobReportTable
        columns={tableColumn}
        data={props.data}
        jobNameInDropdown={props.jobNameInDropdown}
        moduleNameDropdown={props.moduleNameDropdown}
        statusOptionName={props.statusOptionName}
        reportStartDate={props.reportStartDate}
        reportEndDate={props.reportEndDate}
        totalRecords={props.totalRecords}
      />
    </StyledTableContainer>
  )
}

export default JAMSJobReportColumns
