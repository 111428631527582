/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from "@reduxjs/toolkit";
import { StoreState } from "../index";
import { authApiCall } from "../../utils/api"
import { mockData, sampleResponseTemplate } from "./sample";

interface State {
  isLoading: boolean;
  action: string;
  entityType: [];
  tradeFix:[];
  tradeCancel:any;
  modifyTrade:any;
  addTrade:any;
}

let initialState: State = {
  isLoading: false,
  action: "",
  entityType: [],
  tradeFix:[],
  tradeCancel:{},
  modifyTrade:{},
  addTrade:{}
};

const { actions, reducer }: Slice = createSlice({

  name: "trade-fix",
  initialState,
  reducers: {
   
    setTradeRemediationData: (state: State, action: PayloadAction<any>) => {
      state.tradeFix = action.payload.data;
    },
    setCancelTradeData: (state: State, action: PayloadAction<any>) => {
      state.tradeCancel = action.payload.data;
    },
    setModifyTradeData: (state: State, action: PayloadAction<any>) => {
      state.modifyTrade = action.payload.data;
    },
    setAddTradeData: (state: State, action: PayloadAction<any>) => {
      state.addTrade = action.payload.data;
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload;
    },
  },
});

export const {
  setAction,
  setIsLoading,
  setTradeRemediationData,
  setCancelTradeData,
  setModifyTradeData,
  setAddTradeData
} = actions;

export const getTradeRemediationData: any = (
  param: string
) => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const { data, status } = await authApiCall.post(
      "tradebreakapi/TradeBreakFIS/GetTradeBreakDetailFromFIS",
        param,
        {validateStatus: () => {
          return true}}
    );

    // if (status === 200) {
      dispatch(
        setTradeRemediationData({
          data: data,
        })

      );

      dispatch(setIsLoading(false));
      return data;
    // }
  } catch (e: any) {
   
    dispatch(setIsLoading(false));
    return e;
   
  }
};

export const cancelTrade: any = (
  param: string
) => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const { data, status } = await authApiCall.post(
      "tradebreakapi/TradeBreakFIS/CancelTradeFromFIS",
        param,
        {validateStatus: () => {
          return true}}
    );
    // if (status === 200) {
      dispatch(
        setCancelTradeData({
          data: data,
        })

      );

      dispatch(setIsLoading(false));
      return data;
    // }
  } catch (e: any) {
    setCancelTradeData({
      data: e,
    })

    dispatch(setIsLoading(false));
  }
};

export const modifyTrade: any = (
  param: string
) => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const { data, status } = await authApiCall.post(
      "tradebreakapi/TradeBreakFIS/UpdateTradeFromFIS",
        param,
        {validateStatus: () => {
          return true}}
    );

    // if (status === 200) {
      dispatch(
        setModifyTradeData({
          data: data,
        })

      );

      dispatch(setIsLoading(false));
      return data;
    // }
  } catch (e: any) {
    dispatch(setIsLoading(false));
  }
};

export const addTrade: any = (
  param: string
) => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const { data, status } = await authApiCall.post(
      "tradebreakapi/TradeBreakFIS/AddTradetoFIS",
        param,
        {validateStatus: () => {
          return true}}
    );

    // if (status === 200) {
      dispatch(
        setAddTradeData({
          data: data,
        })

      );

      dispatch(setIsLoading(false));
      return data;
    // }
  } catch (e: any) {
    setAddTradeData({
      data: e,
    })

    dispatch(setIsLoading(false));
  }
};


export const tradeRemediationSelector = (store: StoreState) =>
store.TradeRemediation.TradeFixManagement.tradeFix;
///mockData

export const cancelTradeSelector = (store: StoreState) =>
store.TradeRemediation.TradeFixManagement.tradeCancel;
//sampleResponseTemplate


export const modifyTradeSelector = (store: StoreState) =>
store.TradeRemediation.TradeFixManagement.modifyTrade;
//sampleResponseTemplate

export const addTradeSelector = (store: StoreState) =>
store.TradeRemediation.TradeFixManagement.addTrade;
//sampleResponseTemplate

export const actionSelector = (store: StoreState) =>
store.TradeRemediation.TradeFixManagement.action;

export const loadingSelector = (store: StoreState) =>
store.TradeRemediation.TradeFixManagement.isLoading;

export default reducer;
