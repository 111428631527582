import { useEffect, useState, BaseSyntheticEvent, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/button/Button'
import DatePicker from '../../components/datePicker/DatePicker'
import Label from '../../components/label/Label'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import Lottie from 'lottie-react'
import Loader from '../../assets/lottiefiles/loader.json'
import { useLocation } from 'react-router-dom'

import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledSelectWrapper,
  StyledTableContainer,
  PopUpStyleLoader,
  LottieWrapper,
  ActionItems,
  ColorCell,
} from './styles'
import Table from './table/Table'
import { Colors } from '../../shared/styles'
import { SvgEdit } from '../../components/svg/SvgEdit'
import { SvgDelete } from '../../components/svg/SvgDelete'
import ConfirmationModal from '../../composites/confirmationModal/confirmationModal'
import ConfigPopUp from './configPopup'
import {
  ConfigDetailSelector,
  FilterDetailSelector,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  addConfigDetails,
  deleteConfigDetails,
  editConfigDetails,
  getConfigDetails,
  getFilterDetails,
  getWidgetDetails,
  loadingSelector,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../store/threshold-Config/thresholdCongfiguration'
import ConfigFilters from './configFilter'
import { Toast } from '../../components/toast/Toast'
import { ConfigDetails, columnElement, filterElement } from './IconfigDetails'
import { UseTableHooks } from 'react-table'
import { ToastWrapper } from '../../pages/settlement/buyInSettlements/styles'
import { getAccessFlag } from '../../utils/globalFunctions'

interface department {
  Department: string
}

const ThresholdConfig = ({ Department }: department) => {
  const dispatch = useDispatch()
  const configDetails = useSelector(ConfigDetailSelector)
  const filterData = useSelector(FilterDetailSelector)
  const [filterDetails, setFilterDetails] = useState([])
  const loading = useSelector(loadingSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [input, setInput] = useState({
    widgetId: '',
  })
  const [selectedValue, setSelectedValue] = useState<ConfigDetails>({})
  const [previousValue, setPreviousValue] = useState<ConfigDetails>({})
  const [nextValue, setNextValue] = useState<ConfigDetails>({})
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [showConfigPopup, setShowConfigPopup] = useState<boolean>(false)
  const [openMode, setOpenModel] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [toastShow, setToast] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState({ message: '', status: 0 })
  const [widgetIds, setWidgetIds] = useState<any>({})
  const [tableData, setTableData] = useState<any>([])
  const [colorsSelected, setColorsSelected] = useState<any>([])

  const [checkFilter, setCheckFilter] = useState<any>({
    matched: false,
    unMatched: true,
  })

  const writeExecuteFlag =
    Department === 'Settlement'
      ? getAccessFlag(['SA-RWX', 'OM-RWX'])
      : Department === 'TradeReconciliation'
      ? getAccessFlag(['TR-RWX', 'OM-RWX'])
      : false

  useEffect(() => {
    if (filterData?.responseasJSONString?.[0]) {
      const filters = JSON.parse(
        filterData?.responseasJSONString?.[0]?.jsonResponse
      )

      setFilterDetails(filters?.Result)
    }
  }, [filterData])
  const onWidgetChange = async (widgetId: string) => {
    const obj = widgetIds

    for (const key in obj) {
      delete obj[key]
    }
    await setWidgetIds(obj)
    const res = await dispatch(getFilterDetails(widgetId))
    setInput({
      ...input,
      widgetId: widgetId,
    })
    setTableData([])
  }

  const getDetails = async (widgetId: string) => {
    setWidgetIds(widgetId)
    setColorsSelected([])
    const widgetsSelected = Object.values(widgetId).map((ele: any) => ele.value)
    const res = await dispatch(getConfigDetails(widgetsSelected.join()))
    if (res && res.thresholdConfigDetails?.length) {
      const colorArray: any = []
      res.thresholdConfigDetails.map((element: ConfigDetails) => {
        colorArray.push(element.colorCode)
      })
      setColorsSelected(colorArray)
    }
    setTableData(res)
  }

  useEffect(() => {
    dispatch(getWidgetDetails(Department))
  }, [])

  const editItem = (e: UseTableHooks<ConfigDetails>) => {
    const tableValues = e?.data
    const currentIndex = e?.row?.index
    const ConfigDetails = e?.row?.original
    return (
      <>
        <ActionItems role="presentation" title="View">
          <span
            role="presentation"
            onClick={async (e: React.BaseSyntheticEvent) => {
              e.preventDefault()
              await setSelectedValue(ConfigDetails)
              await setPreviousValue(tableValues[currentIndex - 1])
              await setNextValue(tableValues[currentIndex + 1])
              setShowConfigPopup(true)
              setOpenModel('edit')
            }}
            onKeyDown={(e: React.BaseSyntheticEvent) => {
              e.preventDefault()
              setShowConfigPopup(true)
              setOpenModel('edit')
            }}
          >
            <SvgEdit fillColor="#475569" />
          </span>
          <span
            role="presentation"
            onClick={(e: any) => {
              e.preventDefault()
              setDeleteModal(true)
              setDeleteId(ConfigDetails.thresholdId)
            }}
            onKeyDown={(e: any) => {
              e.preventDefault()
              setDeleteModal(true)
              setDeleteId(ConfigDetails.thresholdId)
            }}
          >
            <SvgDelete fillColor="#475569" />
          </span>
        </ActionItems>
      </>
    )
  }

  const settlementConfigColumns = [
    {
      Header: 'Color',
      Cell: (props: any) => {
        return <ColorCell color={props.cell.row.original.color} />
      },
    },

    {
      Header: 'Min Value',
      accessor: 'minValue',
    },
    {
      Header: 'Max Value',
      accessor: 'maxValue',
    },
    {
      Header: 'Comment',
      accessor: 'comment',
    },
    {
      Header: 'Action',
      Cell: writeExecuteFlag ? editItem : <>-</>,
    },
  ]

  const deleteConfiguration = async () => {
    const responseData = await dispatch(deleteConfigDetails(deleteId))
    setDeleteModal(false)
    setToast(true)
    setToastMessage({
      message: responseData?.successOrErrorMessage,
      status: responseData?.statusCode,
    })
    await getDetails(widgetIds)
  }

  const saveDetails = async (ConfigDetails: ConfigDetails) => {
    setShowConfigPopup(false)
    const widgetsSelected = Object.values(widgetIds)
      .map((ele: any) => ele.value)
      ?.join()
    if (openMode === 'add') {
      const responseData = await dispatch(
        addConfigDetails({
          ...ConfigDetails,
          attributeMappingIds: widgetsSelected,
        })
      )
      if (responseData) {
        setTimeout(() => {
          setToast(true)
        }, 1000)
        setToastMessage({
          message: responseData?.message,
          status: responseData?.statusCode,
        })
        await getDetails(widgetIds)
      }
    } else {
      const responseData = await dispatch(editConfigDetails(ConfigDetails))
      setToast(true)
      setToastMessage({
        message: responseData?.message,
        status: responseData?.statusCode,
      })
      await getDetails(widgetIds)
    }
    setPreviousValue({})
    setNextValue({})
  }

  return (
    <RootContainer>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>Threshold Configuration</StyledPanelHeadTitle>
      </StyledPanelHead>
      <ConfigFilters
        onWidgetChange={onWidgetChange}
        input={input}
        getDetails={getDetails}
        filterDetails={filterDetails}
      />
      <StyledTableContainer>
        <Table
          hidePagination={false}
          checkFilter={checkFilter}
          heading={'Threshold Configuration'}
          data={tableData?.thresholdConfigDetails || []}
          columns={settlementConfigColumns}
        />
      </StyledTableContainer>
      {Object.keys(widgetIds).length ? (
        <StyledSelectWrapper
          style={{ justifyContent: 'flex-end', backgroundColor: 'white' }}
        >
          <Button
            hoverBgColor={Colors.White}
            hoverTextColor={Colors.PrimaryText}
            borderColor={Colors.PrimaryText}
            borderRadius={'4px'}
            disabled={!writeExecuteFlag}
            hover={true}
            padding={'5px 10px'}
            color={Colors.White}
            bgColor={Colors.PrimaryText}
            type={'Button'}
            fontWeight={'400'}
            onClick={() => {
              setShowConfigPopup(true)
              setPreviousValue(
                tableData?.thresholdConfigDetails[
                  tableData?.thresholdConfigDetails.length - 1
                ] || []
              )
              setOpenModel('add')
            }}
          >
            Add New
          </Button>
        </StyledSelectWrapper>
      ) : null}
      {deleteModal ? (
        <ConfirmationModal
          cancelClick={() => setDeleteModal(false)}
          headingText={'Delete Configuration'}
          closeModal={() => setDeleteModal(false)}
          message={`Are you sure you need to Delete this Configuration`}
          successClick={deleteConfiguration}
          cancelText={'No'}
          confirmText={'Yes'}
        />
      ) : null}
      {toastShow && (
        <ToastWrapper>
          <Toast
            text={toastMessage.message}
            type={toastMessage.status === 200 ? 'success' : 'danger'}
            openStatusCallback={(status: boolean) => setToast(false)}
          />
        </ToastWrapper>
      )}
      {showConfigPopup && (
        <ConfigPopUp
          openMode={openMode}
          editDetails={selectedValue}
          cancelModal={() => {
            setShowConfigPopup(false)
            setOpenModel('')
          }}
          saveDetails={saveDetails}
          title={'Threshold Configuration'}
          widgetIds={widgetIds}
          filterDetails={filterDetails}
          previousEditDetails={previousValue}
          nextEditDetails={nextValue}
          colorsSelected={colorsSelected}
        ></ConfigPopUp>
      )}
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}
export default ThresholdConfig
