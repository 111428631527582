/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import {
  CancelButtonWrapper,
  CommentTableContainer,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  PopUpStyleLoader,
  PopupCard,
  StyledButtonWrapper,
  StyledReportDetailsContainer,
  StyledSelectWrapper,
  StyledTableContainer,
  ToastWrapper,
} from './styles'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { useState } from 'react'
import { Toast } from '../../../components/toast/Toast'
import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'
import { Textarea } from '../../../components/textArea/Textarea'
import ReportDetailTable from './table/ReportDetailTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  BetaLoanetReconReportDataSelector,
  TotalCountSelector,
  commentSelector,
  getComments,
  isLoadingSelector,
  putUserComment,
} from '../../../store/operations-Control/BETALoanetReconReport'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import { Backgrounds } from '../../../shared/styles'
import Table from '../../../components/table/Table'
import { getAccessFlag } from '../../../utils/globalFunctions'

const BETALoanetReportDetails = (props: any) => {
  const dispatch = useDispatch()
  const Usercomments = useSelector(commentSelector)
  const loading = useSelector(isLoadingSelector)
  const data = useSelector(BetaLoanetReconReportDataSelector)
  const totalRecords = useSelector(TotalCountSelector)
  const [comment, setComments] = useState<boolean>(false)
  const [inputComment, setInputComment] = useState<any>([])
  const [openComments, setOpenComments] = useState<boolean>(false)
  const writeExecuteFlag = getAccessFlag(['OC-RWX', 'OM-RWX'])

  const [input, setInput] = useState({
    commentRow: 0,
    commentDate: new Date().toISOString(),
  })

  const newUserComment = async (
    loanNetId: string,
    reportDate: string,
    acct: string,
    brkr: string,
    cusip: string,
    secNo: string,
    loanetQnty: string,
    wedbushQnty: string,
    qtyDiff: string,
    loanetAmt: string,
    wedbushAmt: string,
    amtDiff: string,
    comment: string,
    rowId: number
  ) => {
    const response = await dispatch(
      putUserComment(
        loanNetId.toString(),
        reportDate,
        acct,
        brkr,
        cusip,
        secNo,
        loanetQnty,
        wedbushQnty,
        qtyDiff,
        loanetAmt,
        wedbushAmt,
        amtDiff,
        comment
      )
    )
    inputComment[rowId] = '  '
    setInputComment({ ...inputComment })
  }

  const savedComments = async (tb: any) => {
    await dispatch(
      getComments(
        data[tb].acct,
        data[tb].brkr,
        data[tb].cusip,
        data[tb].secNo,
        data[tb].loanetQnty,
        data[tb].wedbushQnty,
        data[tb].loanetAmt,
        data[tb].wedbushAmt
      )
    )
    setOpenComments(true)
  }

  const TableColumns = [
    {
      Header: 'Age',
      accessor: 'age',
    },
    {
      Header: 'Report Date',
      accessor: 'reportDate',
    },
    {
      Header: 'Add Note',
      Cell: (table: any) => {
        return (
          <>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                setComments(!comment)
                setInput({
                  ...input,
                  commentRow: table.cell.row.id,
                })
              }}
            >
              Add Notes
            </Button>

            {comment && table.cell.row.id === input.commentRow && (
              <PopupCard>
                <Textarea
                  onChange={(e: any) => {
                    inputComment[table.cell.row.id] = e.target.value
                    setInputComment(inputComment)
                  }}
                  height="365px"
                  placeholder={'Enter Comment'}
                ></Textarea>
                <StyledButtonWrapper>
                  <Button
                    style={{
                      marginRight: '16px',
                    }}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    type={'button'}
                    color={COLORS.Background.Primarytext}
                    bgColor={'transparent'}
                    onClick={() => {
                      setComments(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    hoverBgColor={COLORS.Background.Primary70}
                    bgColor={COLORS.Background.Primarytext}
                    color={COLORS.Background.Primary}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    hover={true}
                    type={''}
                    activeBgColor={COLORS.Background.Primaryactive}
                    borderColor={'transparent'}
                    onClick={async () => {
                      await newUserComment(
                        data[table.cell.row.id].id,
                        data[table.cell.row.id].reportDate,
                        data[table.cell.row.id].acct,
                        data[table.cell.row.id].brkr,
                        data[table.cell.row.id].cusip,
                        data[table.cell.row.id].secNo,
                        data[table.cell.row.id].loanetQnty,
                        data[table.cell.row.id].wedbushQnty,
                        data[table.cell.row.id].qtyDiff,
                        data[table.cell.row.id].loanetAmt,
                        data[table.cell.row.id].wedbushAmt,
                        data[table.cell.row.id].amtDiff,
                        inputComment[table.cell.row.id],
                        table.cell.row.id
                      )
                      setComments(false)
                    }}
                  >
                    Submit
                  </Button>
                </StyledButtonWrapper>
              </PopupCard>
            )}
          </>
        )
      },
    },
    {
      Header: 'View Comments',
      Cell: (table: any) => {
        return (
          <>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              disabled={!writeExecuteFlag}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                savedComments(table.cell.row.id)
              }}
            >
              Comments
            </Button>
          </>
        )
      },
    },
    {
      Header: 'Acct',
      accessor: 'acct',
    },
    {
      Header: 'BRKR',
      accessor: 'brkr',
    },
    {
      Header: 'CUSIP',
      accessor: 'cusip',
    },
    {
      Header: 'SecNo',
      accessor: 'secNo',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'LoanetQnty',
      accessor: 'loanetQnty',
    },
    {
      Header: 'WedbushQnty',
      accessor: 'wedbushQnty',
    },
    {
      Header: 'Qnty Diff',
      accessor: 'qtyDiff',
    },
    {
      Header: 'LoanetAmt',
      accessor: 'loanetAmt',
    },
    {
      Header: 'WedbushAmt',
      accessor: 'wedbushAmt',
    },
    {
      Header: 'AmtDiff',
      accessor: 'amtDiff',
    },
  ]

  return (
    <StyledReportDetailsContainer>
      {openComments && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>View Comment</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenComments(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'Acct',
                  accessor: 'acct',
                },
                {
                  Header: 'BRKR',
                  accessor: 'brkr',
                },
                {
                  Header: 'CUSIP',
                  accessor: 'cusip',
                },
                {
                  Header: 'SecNo',
                  accessor: 'secNo',
                },
                {
                  Header: 'LoanetQnty',
                  accessor: 'loanetQnty',
                },
                {
                  Header: 'WedbushQnty',
                  accessor: 'wedbushQnty',
                },
                {
                  Header: 'LoanetAmt',
                  accessor: 'loanetAmt',
                },
                {
                  Header: 'WedbushAmt',
                  accessor: 'wedbushAmt',
                },
                {
                  Header: 'CreatedBy',
                  accessor: 'updateBy',
                },
                {
                  Header: 'CreatedOn(EST)',
                  accessor: 'updateDateTime',
                },
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
              ]}
              tableData={Usercomments ? Usercomments : []}
              onClick={() => {
                //
              }}
            />
            {Usercomments.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      <WDCard>
        <CardHeader
          label={'Beta Loanet Reconciliation'}
          icon={false}
          amountValue={`Total: ${
            totalRecords.totalCount ? totalRecords.totalCount : 0
          }`}
        />
      </WDCard>
      <StyledTableContainer>
        <ReportDetailTable
          columns={TableColumns}
          data={data}
          totalRecords={totalRecords.totalCount}
          startDate={props.startDate}
        ></ReportDetailTable>
      </StyledTableContainer>
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
    </StyledReportDetailsContainer>
  )
}

export default BETALoanetReportDetails
