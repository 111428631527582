import { TabData } from '../../components/tab/TabListModels'
import BuyInAllocationTabs from './BuyInAllocationAndExecution/BuyInAllocationTabs'
import BrokerL2Content from './BuyInAllocationAndExecution/broker/brokerBuyInL2Content'
import BrokerL3Content from './BuyInAllocationAndExecution/broker/brokerBuyInL3Content'
import CorrespondentL2Content from './BuyInAllocationAndExecution/correspondent/correspondentBuyInL2Page'
import CustomerL2Content from './BuyInAllocationAndExecution/customer/customerBuyInL2Page'
import CustomerL3Content from './BuyInAllocationAndExecution/customer/customerBuyInL3Page'
import OthersL2Content from './BuyInAllocationAndExecution/others/othersBuyInL2Page'
import OthersL3Content from './BuyInAllocationAndExecution/others/othersBuyInL3Page'
import StockLoanL2Content from './BuyInAllocationAndExecution/stockLoan/stockLoanBuyInL2Page'
import StockLoanL3Content from './BuyInAllocationAndExecution/stockLoan/stockLoanBuyInL3Page'
import BuyInContent from './buyInTab/buyInTabPage'
import RegSHOCusipSummary from './cusipLevelSummary/RegSHOCusipSummary'
import ToolSummaryDetails from './toolSummary/ToolSummaryDetails'

export interface CommonTabProps {
  regSHOId: number
  source: string
  fromDate: string
  toDate: string
  dropdownCusip: string
  dropdownSymbols: string
  applySearchClick: boolean
  selectedSummaryTableCUSIP: string
  selectedCUSIPReportDate: string
  allocationType: string
  failDate:string
  tn:string
  approveFlag:boolean
}
export interface IRegSHOData {
  [key: string]: string | number | boolean | JSX.Element | null
}
export const RegSHOTabDetails: TabData[] = [
  {
    tabIndex: 0,
    label: 'RegSHO Tool Summary',
    component: ToolSummaryDetails,
    apiEndpoint: '',
  },
  {
    tabIndex: 1,
    label: 'Cusip Level Summary',
    component: RegSHOCusipSummary,
    apiEndpoint: '',
  },
  {
    tabIndex: 2,
    label: 'Allocations',
    component: BuyInAllocationTabs,
    apiEndpoint: '',
  },
  {
    tabIndex: 3,
    label: 'BuyIn notification',
    component: BuyInContent,
    apiEndpoint: '',
  },
]
export const BuyInAllocationTabDetails: TabData[] = [
  {
    tabIndex: 0,
    label: 'Correspondent Allocation',
    component: CorrespondentL2Content,
    apiEndpoint: '',
  },
  {
    tabIndex: 1,
    label: 'Broker Allocation',
    component: BrokerL2Content,
    apiEndpoint: '',
  },
  {
    tabIndex: 2,
    label: 'Broker Buyin execution',
    component: BrokerL3Content,
    apiEndpoint: '',
  },
  {
    tabIndex: 3,
    label: 'Customer Allocation',
    component: CustomerL2Content,
    apiEndpoint: '',
  },
  {
    tabIndex: 4,
    label: 'Customer Buyin execution',
    component: CustomerL3Content,
    apiEndpoint: '',
  },
  {
    tabIndex: 5,
    label: 'Stock Loan Allocation',
    component: StockLoanL2Content,
    apiEndpoint: '',
  },
  {
    tabIndex: 6,
    label: 'Stock LoanBuyin execution',
    component: StockLoanL3Content,
    apiEndpoint: '',
  },
  {
    tabIndex: 7,
    label: 'Others Allocation',
    component: OthersL2Content,
    apiEndpoint: '',
  },
  {
    tabIndex: 8,
    label: 'Others Buyin execution',
    component: OthersL3Content,
    apiEndpoint: '',
  },
]
