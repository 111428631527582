import styled from 'styled-components'

export interface IDTokenInterface {
  Username: string
  at_hash: string
  aud: string
  auth_time: number
  email: string
  exp: number
  family_name: string
  given_name: string
  iat: number
  idp: string
  iss: string
  name: string
  nbf: number
  nonce: string
  sub: string
  tid: string
  upn: string
  ver: string
  roles: RolesInterface
}
export interface RolesInterface {
  Entitlements: string[]
  OrgRoles: string[]
  SegRoles: string[]
}
export const RootContainer = styled.div`
  margin: -8px 0px 8px 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f8fafc;
  // background: #E5E5E5;
  /* background-color: orange; */
`

export const DashboardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  max-width: 1366px;
  grid-auto-flow: column;
  margin: 10px auto auto auto;
  padding: 15px;
  //height: 100vh;
  // max-height: 750px;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-srows: minmax(0, 1fr);
`
export const LottieWrapper = styled.div`
  // position: fixed;
  // top: 70%;
  // left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const LodingTable = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 28px 0 28px 0;
`

export const StyledPanelHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  align-items: stretch;
  padding: 16px 24px 16px;
  border-radius: 8px 8px 0px 0px;
`

export const StyledPanelHeadTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  text-align: left;
  color: #0f172a;
`
export const StyledPanelSubHeader = styled.div``

export const HelpDocContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  place-content: end;
  margin-left: auto;
  padding: 15px;
`
