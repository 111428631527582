import LandingPageLayout from "../../components/layouts/landingPage/LandingPageLayout";
import { RootContainerParent } from "./styles";
import TradeManagementByMPID from "./tradeBreaksbyMPID/tradeBreaks/tradeBreaksByMpid/tradeBreaksByMPID"
import IntraDayManagementByMPID from "./tradeBreaksbyMPID/tradeBreaks/intradayBreaks/intradayBreaksByMPID"
import IntraDayManagementByEC from "./tradeBreaksByEC/intradayBreaks/intradayBreaksByEC"
import TradeBreakManagement from "./tradeBreakDetails/tradeBreak/tradeBreakDetails"
import TradeBreaksByECDetails from "./tradeBreaksByEC/tradeBreak/tradeBreaksByEC"
import BreadCrumb from "../../components/BreadCrumb/breadCrumbs";
import IntraTradeBreakManagement from "./tradeBreakDetails/intradayBreaks/intraDayTradeBreakDetails";

export const TradeBreakByMPID = () => {
  return (
    <LandingPageLayout>
      <RootContainerParent>
        <BreadCrumb />
        <TradeManagementByMPID />
      </RootContainerParent>
    </LandingPageLayout>)
};

export const IntraDayBreaksByMPID = () => {
  return (
    <LandingPageLayout>
      <RootContainerParent>
        <BreadCrumb />
        <IntraDayManagementByMPID />
      </RootContainerParent>
    </LandingPageLayout>)
};


export const TradeDetails = () => {
  return (
    <LandingPageLayout>
      <RootContainerParent>
        <BreadCrumb />
        <TradeBreakManagement />
      </RootContainerParent>
    </LandingPageLayout>)
}

export const IntraDayTradeDetails = () => {
  return (
    <LandingPageLayout>
      <RootContainerParent>
        <BreadCrumb />
        <IntraTradeBreakManagement />
      </RootContainerParent>
    </LandingPageLayout>)
}

export const TradeBreaksByEC = () => {
  return (
    <LandingPageLayout>
      <RootContainerParent>
        <BreadCrumb />
        <TradeBreaksByECDetails />
      </RootContainerParent>
    </LandingPageLayout>)
};

export const IntraDayBreaksByEC = () => {
  return (
    <LandingPageLayout>
      <RootContainerParent>
        <BreadCrumb />
        <IntraDayManagementByEC />
      </RootContainerParent>
    </LandingPageLayout>)
};