import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Label from '../../../components/label/Label'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { useLocation } from 'react-router-dom'

import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledTableContainer,
  PopUpStyleLoader,
  LottieWrapper,
} from './styles'
import Table from './table/Table'

import {
  getSecurityUpdate,
  securiyUpdateAccountSelector,
  loadingSelector,
  getRequestType,
} from '../../../store/securityUpdate/securityUpdate'

const SecurityUpdates = () => {
  const dispatch = useDispatch()
  const securityUpdateData = useSelector(securiyUpdateAccountSelector)

  const location = useLocation()
  const loading = useSelector(loadingSelector)
  const [startDate] = useState(new Date())
  const firstDay = new Date(
    startDate.getFullYear(),
    startDate.getMonth() - 1,
    1
  )

  let mm: string | number
  let firstDayOfMonth: string | number

  mm = startDate.getMonth()
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth

  useEffect(() => {
    dispatch(getSecurityUpdate(fromDate))
    dispatch(getRequestType())
  }, [])

  return (
    <RootContainer>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>Security Updates</StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledTableContainer>
          {securityUpdateData ? (
            <Table
              hidePagination={false}
              heading={'Security Updates'}
              data={
                securityUpdateData &&
                securityUpdateData.p3SecurityUpdateViewModel
                  ? securityUpdateData.p3SecurityUpdateViewModel
                  : []
              }
              columns={[
                { Header: 'Request Type', accessor: 'requestType' },
                {
                  Header: `${securityUpdateData?.dateViewModel?.[0]?.previousDate}`,
                  accessor: 'previousMonthCount',
                },
                {
                  Header: `${securityUpdateData?.dateViewModel?.[0]?.currentDate}`,
                  accessor: 'currentMonthCount',
                },
              ]}
              fromDate={fromDate}
            />
          ) : null}
        </StyledTableContainer>
      </StyledPanelSelect>
    </RootContainer>
  )
}
export default SecurityUpdates
