import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/button/Button'
import DatePicker from '../../../components/datePicker/DatePicker'
import Label from '../../../components/label/Label'
import { useLocation, useNavigate } from 'react-router-dom'
import * as z from 'zod'
import { LottieWrapper, RootContainer } from './styles'
import {
  ColDiv,
  HeadingText,
  PageHeader,
  StyledBodyContainer,
  StyledFilterPanel,
  StyledSelectWrapper,
  ToastWrapper,
  PageBody,
  StyledButtonRoot,
} from './styles'
import { Textbox } from '../../../components/textbox/Textbox'
import {
  loadingSelector,
  getTradeRemediationData,
  tradeRemediationSelector,
  cancelTrade,
  modifyTrade,
  addTrade,
  addTradeSelector,
  modifyTradeSelector,
} from '../../../store/tradeRemediation/tradeRemediation-management'
import ConfirmationModal from '../../../composites/confirmationModal/confirmationModal'
import { Toast } from '../../../components/toast/Toast'
import { tradeTemplate } from '../../../store/tradeRemediation/sample'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { PopUpStyleLoader } from '../../settlement/failAnalysis/styles'
import { ValidateSchema } from './validations'
interface ConfirmationDetails {
  mode?: string
  text?: string
  functionValue?: () => void
}

const TradeFix = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const loading = useSelector(loadingSelector)
  const [messageDetails, setMessageDetails] = useState({
    status: '',
    message: '',
  })
  const [modifiedTradeData, setModifiedTradeData] = useState<any>({})
  const [action, setAction] = useState<any>('fix')
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const [confirmationDetails, setConfirmationDetails] =
    useState<ConfirmationDetails>({ text: '' })
  const [tradeParam, setTradeParams] = useState<any>({})
  const [toggleModal, settoggleModal] = useState<boolean>(false)
  const [validationError, setValidationError] = useState<any>([])
  const [reinstantiateData, setReinstantiate] = useState<any>({})
  const [tradeData, setTradeData] = useState<any>({})

  const handlechange = (key: any, value: any) => {
    const keyValue = key.charAt(0).toLowerCase() + key.slice(1)
    setModifiedTradeData({
      ...modifiedTradeData,
      [keyValue]: value,
    })
  }

  const inputFormant = (item: any, type: string, isDisabled: boolean) => {
    {
      return type == 'date' ? (
        <DatePicker
          name={item}
          value={tradeData[item]?.value}
          max={new Date().toISOString().split('T')[0]}
          onChange={(e) => {
            setTradeData({
              ...tradeData,
              [item]: { ...tradeData[item], value: e },
            })
            handlechange(item, e)
          }}
          borderColor={''}
          bgColor={''}
          placeHolder={''}
          padding={'4px 8px'}
          maxWidth={'100'}
        />
      ) : (
        <Textbox
          style={{
            borderRadius: '4px',
            height: '32px',
            padding: '4px 8px',
          }}
          disabled={action === 'cancel' || isDisabled ? true : false}
          name={item}
          value={tradeData[item]?.value}
          onChange={(e) => {
            setTradeData({
              ...tradeData,
              [item]: { ...tradeData[item], value: e },
            })
            handlechange(item, e)
          }}
        />
      )
    }
  }

  const cancelTradeDetails = async () => {
    const tradeDate = tradeParam.tradeDate
      ? tradeParam.tradeDate.split('-')
      : []
    setConfirmationModal(false)
    const response = await dispatch(
      cancelTrade([
        {
          header: {
            functionMode: 'CXL',
            screenName: 'ORDGTI',
            errorString: '',
            returnValue: '0',
            noOfErrors: '',
            dateFormat: 'MM/dd/yyyy',
            returnData: '',
            firmNumber: '23',
            mode: 'CXL MODE',
            userId: 'API',
            terminationStatus: 1,
          },
          body: {
            tradeMonth: tradeDate?.[1],
            tradeDay: tradeDate?.[2],
            tradeYear: tradeDate?.[0].substring(2, 4),
            tradeNumber: tradeParam.tradeNumber,
            confirm: 'P',
            originalClosecode: 'C',
            closecode: 'X',
            enteredBy: 'API',
            specialCode: 'o',
          },
          trailer: {},
          session: {},
        },
      ])
    )
    if (response) {
      await setMessageDetails({
        status: response?.statusCode == 200 ? 'success' : 'danger',
        message:
          response?.statusCode == 200
            ? response.successMessageFromFIS
            : response?.errorResponseFromFIS != ''
            ? response?.errorResponse?.guid +
              ` - ` +
              response?.errorResponseFromFIS
            : 'Something Went Wrong',
      })
      settoggleModal(true)
      setTimeout(() => {
        settoggleModal(false)
        setMessageDetails({ status: '', message: '' })
      }, 30000)
      if (response?.statusCode == 200) {
        setTimeout(() => {
          navigate('/tradeRemediation', {
            state: {},
          })
        }, 4000)
      }
    }
  }

  const getTradeRemediation = async (params: any) => {
    const tradeDate = params.passProps.tradeDetails.tradeDate
      ? params.passProps.tradeDetails.tradeDate.split('-')
      : []
    const mode = params.passProps.action
    const tradeRemediationData = await dispatch(
      getTradeRemediationData({
        header: {
          functionMode: 'RTV',
          screenName: 'ORDGTI',
          error: '',
          returnValue: '',
          noOfErrors: '',
          dateFormat: '',
          returnData: '',
          firmNumber: '23',
          mode: '',
          userId: 'API',
        },
        body: {
          tradeMonth: tradeDate?.[1],
          tradeDay: tradeDate?.[2],
          tradeYear: tradeDate?.[0].substring(2, 4),
          tradeNumber: params.passProps.tradeDetails.tradeNumber,
        },
        trailer: {},
        session: {},
      })
    )
    if (
      tradeRemediationData &&
      tradeRemediationData.fisTradeBreakResponseViewModelAsJson
    ) {
      if (mode === 'reinstantiate') {
        const res = JSON.parse(
          tradeRemediationData.fisTradeBreakResponseViewModelAsJson
        )
        let tradeDataValue = {
          ...res,
          TradeNbr: {
            ...res['TradeNbr'],
            disabled: true,
          },
          TradeDate: {
            ...res['TradeDate'],
            type: 'date',
            value: res['TradeDate']?.value.split('T')?.[0],
          },
          SettleDate: {
            ...res['SettleDate'],
            type: 'date',
            value: '',
          },
          RetrievalTradeDate: {
            ...res['RetrievalTradeDate'],
            type: 'date',
          },
        }
        let editData: any = {}
        if (res) {
          Object.keys(res).map((element: any, key: any) => {
            const keyValue = element.charAt(0).toLowerCase() + element.slice(1)
            if (
              res[element]?.value?.toString().trim() != '' ||
              res[element]?.value != 0
            ) {
              editData = {
                ...editData,
                [keyValue]:
                  res[element]?.value === '' || res[element]?.value === 0
                    ? null
                    : res[element]?.value?.toString(),
              }
            }
            if (res[element]?.value === 0) {
              tradeDataValue = {
                ...tradeDataValue,
                [element]: {
                  ...tradeDataValue[element],
                  value: null,
                },
              }
            }
          })
          if (editData) {
            delete editData['settleDate']
          }
          setReinstantiate(editData)
        }
        setTradeData(tradeDataValue)
      } else {
        setTradeData(
          JSON.parse(tradeRemediationData.fisTradeBreakResponseViewModelAsJson)
        )
      }
    }
  }

  const setActionDeatils = async (params: any) => {
    if (params && params.passProps) {
      setAction(params.passProps.action)
      setTradeParams(params.passProps.tradeDetails)
      if (params.passProps.action === 'add') {
        setTradeData(tradeTemplate?.fisTradeBreakResponseViewModelAsJson)
      } else {
        getTradeRemediation(params)
      }
    }
  }

  useEffect(() => {
    const params = location.state
    setActionDeatils(params)
  }, [])

  const modifyTradeDetails = async (params: any) => {
    const tradeDate = params.passProps.tradeDetails.tradeDate
      ? params.passProps.tradeDetails.tradeDate.split('-')
      : []
    setConfirmationModal(false)
    const response = await dispatch(
      modifyTrade({
        header: {
          functionMode: 'COR',
          screenName: 'ORDGTI',
          errorString: '',
          returnValue: '0',
          noOfErrors: '',
          dateFormat: 'MM/dd/yyyy',
          returnData: '',
          firmNumber: '23',
          mode: 'ADD MODE',
          userId: 'API',
        },
        body: {
          ...modifiedTradeData,
          tradeMonth: tradeDate?.[1],
          tradeDay: tradeDate?.[2],
          tradeYear: tradeDate?.[0].substring(2, 4),
          tradeNbr: tradeData?.TradeNbr?.value,
          enterdBy: 'API',
          closeCode: 'S',
        },
        trailer: {},
        session: {},
      })
    )
    if (response && response.statusCode === 200) {
      if (response && response.fisTradeBreakResponseViewModelAsJson) {
        setTradeData(JSON.parse(response.fisTradeBreakResponseViewModelAsJson))
      }
    }
    await setMessageDetails({
      status: response?.statusCode == 200 ? 'success' : 'danger',
      message:
        response?.statusCode == 200
          ? response.successMessageFromFIS
          : response?.errorResponseFromFIS != ''
          ? response?.errorResponse?.guid +
            ` - ` +
            response?.errorResponseFromFIS
          : 'Something Went Wrong',
    })
    if (response?.statusCode) {
      settoggleModal(true)

      await setTimeout(() => {
        settoggleModal(false)
        setMessageDetails({ status: '', message: '' })
      }, 30000)
    }
  }

  const renderError = (element: string) => {
    if (validationError && validationError.length) {
      const elementValue = element.charAt(0).toLowerCase() + element.slice(1)
      const errorText = validationError.find(
        (e: any) => e.path[0] === elementValue
      )?.message
      if (errorText) {
        return `*${errorText}`
      } else {
        return null
      }
    } else return null
  }

  const addTradeDetails = async () => {
    const tradeDate = modifiedTradeData.tradeDate
      ? modifiedTradeData.tradeDate.split('-')
      : []
    setConfirmationModal(false)
    const response = await dispatch(
      addTrade({
        header: {
          returnValue: '0',
          errorString: '',
          returnData: '',
          firmNumber: '23',
          noOfErrors: '',
          mode: 'ADD MODE',
          functionMode: 'ADD',
          screenName: 'ORDGTI',
          userId: 'API',
          dateFormat: 'MM/dd/yyyy',
        },
        body: {
          ...reinstantiateData,
          ...modifiedTradeData,
          tradeMonth: tradeDate?.[1],
          tradeDay: tradeDate?.[2],
          tradeYear: tradeDate?.[0].substring(2, 4),
          market: modifiedTradeData.mktCap,
          enteredBy: 'API',
        },
        trailer: {},
        session: {},
      })
    )
    if (response) {
      await setMessageDetails({
        status: response?.statusCode == 200 ? 'success' : 'danger',
        message:
          response?.statusCode == 200
            ? response.successMessageFromFIS
            : response?.errorResponseFromFIS != ''
            ? response?.errorResponse?.guid +
              ` - ` +
              response?.errorResponseFromFIS
            : 'Something Went Wrong',
      })
      if (response?.statusCode) {
        settoggleModal(true)
        await setTimeout(() => {
          settoggleModal(false)
          setMessageDetails({ status: '', message: '' })
        }, 30000)
      }
    }
  }

  const reInstantiateTradeDetails = async () => {
    let tradeDate = []
    let market = ''
    if (modifiedTradeData.tradeDate) {
      tradeDate = modifiedTradeData.tradeDate
        ? modifiedTradeData.tradeDate.split('-')
        : []
      market = modifiedTradeData.mktCap ? modifiedTradeData.mktCap : []
    } else {
      tradeDate = reinstantiateData.tradeDate
        ? reinstantiateData.tradeDate.split('-')
        : []
      market = reinstantiateData.mktCap ? reinstantiateData.mktCap : []
    }
    setConfirmationModal(false)
    const response = await dispatch(
      addTrade({
        header: {
          returnValue: '0',
          errorString: '',
          returnData: '',
          firmNumber: '23',
          noOfErrors: '',
          mode: 'ADD MODE',
          functionMode: 'ADD',
          screenName: 'ORDGTI',
          userId: 'API',
          dateFormat: 'MM/dd/yyyy',
        },
        body: {
          ...reinstantiateData,
          ...modifiedTradeData,
          tradeMonth: tradeDate?.[1],
          tradeDay: tradeDate?.[2],
          tradeYear: tradeDate?.[0].substring(2, 4),
          market: market,
          enteredBy: 'API',
        },
        trailer: {},
        session: {},
      })
    )
    if (response) {
      let successMessage = ''
      if (response?.statusCode == 200) {
        successMessage = response?.successMessageFromFIS?.split(':')
        // if (response && response.fisTradeBreakResponseViewModelAsJson) {
        //   setTradeData(JSON.parse(response.fisTradeBreakResponseViewModelAsJson))
        // }
      }
      await setMessageDetails({
        status: response?.statusCode == 200 ? 'success' : 'danger',
        message:
          response?.statusCode == 200
            ? 'Re-instantiated with trade number:' + successMessage?.[1]
            : response?.errorResponseFromFIS != ''
            ? response?.errorResponse?.guid +
              ` - ` +
              response?.errorResponseFromFIS
            : 'Something Went Wrong',
      })
      if (response?.statusCode) {
        settoggleModal(true)
        await setTimeout(() => {
          settoggleModal(false)
          setMessageDetails({ status: '', message: '' })
        }, 30000)
      }
    }
  }
  const validateinstantiateFormDetails = async () => {
    const editDetails = { ...reinstantiateData, ...modifiedTradeData }
    try {
      const validatedForm = ValidateSchema.parse(editDetails)
      if (validatedForm) {
        setValidationError([])
        setConfirmationModal(true)
        setConfirmationDetails({
          mode: 'Re-instantiate',
          text: `Are you sure want to re-instantiate this trade`,
          functionValue: () => reInstantiateTradeDetails(),
        })
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        settoggleModal(true)
        await setMessageDetails({
          status: 'danger',
          message: 'Mandatory Field is invalid/missing',
        })
        setTimeout(() => {
          settoggleModal(false)
          setMessageDetails({ status: '', message: '' })
        }, 30000)
        const errors = err.issues
        if (errors) {
          setValidationError(errors)
        }
      }
    }
  }

  const validateFormDetails = async () => {
    if (action === 'reinstantiate') {
      validateinstantiateFormDetails()
    } else {
      try {
        const validatedForm = ValidateSchema.parse(modifiedTradeData)
        if (validatedForm) {
          setValidationError([])
          setConfirmationModal(true)
          setConfirmationDetails({
            mode: 'Add',
            text: `Are you sure want to add this trade`,
            functionValue: () => addTradeDetails(),
          })
        }
      } catch (err) {
        if (err instanceof z.ZodError) {
          settoggleModal(true)
          await setMessageDetails({
            status: 'danger',
            message: 'Mandatory Field is invalid/missing',
          })
          setTimeout(() => {
            settoggleModal(false)
            setMessageDetails({ status: '', message: '' })
          }, 30000)
          const errors = err.issues
          if (errors) {
            setValidationError(errors)
          }
        }
      }
    }
  }

  const validateEditFormDetails = async () => {
    let editData = {}
    if (tradeData) {
      Object.keys(tradeData).map((element: any, key: any) => {
        const keyValue = element.charAt(0).toLowerCase() + element.slice(1)
        if (tradeData[element].value?.toString().trim() != '') {
          editData = {
            ...editData,
            [keyValue]:
              tradeData[element].value === ''
                ? null
                : tradeData[element].value?.toString(),
          }
        }
      })
    }
    const editDetails = { ...editData, ...modifiedTradeData }
    try {
      const validatedForm = ValidateSchema.parse(editDetails)
      if (validatedForm) {
        setValidationError([])
        setConfirmationModal(true)
        setConfirmationDetails({
          mode: 'Modify',
          text: `Are you sure want to modify trade 'Trade #'${tradeData?.TradeNbr?.value}'`,
          functionValue: () => modifyTradeDetails(location.state),
        })
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        settoggleModal(true)
        await setMessageDetails({
          status: 'danger',
          message: 'Mandatory Field is invalid/missing',
        })
        setTimeout(() => {
          settoggleModal(false)
          setMessageDetails({ status: '', message: '' })
        }, 30000)
        const errors = err.issues
        if (errors) {
          setValidationError(errors)
        }
      }
    }
  }

  return (
    <>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <RootContainer>
        <PageBody>
          <PageHeader>
            <StyledFilterPanel>
              <HeadingText>
                {action === 'add'
                  ? 'Add New Trade'
                  : action === 'cancel'
                  ? 'Cancel Trade '
                  : action === 'fix'
                  ? 'Amend Trade'
                  : 'Re-instantiate Trade'}
              </HeadingText>
            </StyledFilterPanel>
          </PageHeader>
          <StyledBodyContainer>
            <StyledSelectWrapper>
              {Object.keys(tradeData).map((element: any, key: any) => {
                return (
                  <ColDiv key={key}>
                    <Label
                      fontSize="16px"
                      fontWeight={400}
                      color={'#0F172A'}
                      lineHeight={'24px'}
                    >
                      {tradeData[element]?.displayName}
                    </Label>
                    {inputFormant(
                      element,
                      tradeData[element]?.type,
                      tradeData[element]?.disabled
                    )}
                    <span style={{ color: 'red' }}>{renderError(element)}</span>
                  </ColDiv>
                )
              })}
            </StyledSelectWrapper>
          </StyledBodyContainer>
          <StyledButtonRoot>
            <Button
              hoverBgColor={'white'}
              hoverTextColor={'E2E8F0'}
              borderRadius={'4px'}
              hover={true}
              padding={'10px 30px'}
              bgColor={'#E2E8F0'}
              color={'#A7AFBC'}
              type={'Button'}
              margin={'0px 6px'}
              onClick={() => {
                navigate('/tradeRemediation', {})
              }}
            >
              Back
            </Button>
            {action === 'cancel' ? (
              <Button
                margin={'0px 6px'}
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'10px 30px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={() => {
                  setConfirmationModal(true)
                  setConfirmationDetails({
                    mode: 'Cancel',
                    text: `Are you sure want to cancel trade 'Trade #'${tradeParam.tradeNumber}'`,
                    functionValue: () => cancelTradeDetails(),
                  })
                }}
              >
                Cancel Trade
              </Button>
            ) : action === 'fix' ? (
              <Button
                margin={'0px 6px'}
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'10px 30px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={() => {
                  validateEditFormDetails()
                }}
              >
                Modify Trade
              </Button>
            ) : (
              <Button
                margin={'0px 6px'}
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'10px 30px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={validateFormDetails}
              >
                Add Trade
              </Button>
            )}
            {confirmationModal ? (
              <ConfirmationModal
                cancelClick={() => setConfirmationModal(false)}
                headingText={`${confirmationDetails.mode} Trade`}
                closeModal={() => setConfirmationModal(false)}
                message={confirmationDetails.text}
                successClick={confirmationDetails.functionValue}
                cancelText={'No'}
                confirmText={'Yes'}
              />
            ) : null}
            {toggleModal && messageDetails.message != '' && (
              <ToastWrapper>
                <Toast
                  text={messageDetails.message}
                  type={messageDetails.status}
                  openStatusCallback={() => settoggleModal(false)}
                />
              </ToastWrapper>
            )}
          </StyledButtonRoot>
        </PageBody>
      </RootContainer>
    </>
  )
}
export default TradeFix
