import { StyledBreadCrumb, StyledLink, StyledLinkTag } from "./breadCrumbStyles"
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const BreadCrumb = (props: any) => {
    const navigate = useNavigate();
    const [breadCrumbArray, setArray] = useState([])

    useEffect(() => {
        window.onpopstate = e => {
            const data: any = sessionStorage.getItem('breadCrumbData')
            const tempArray = JSON.parse(data)
            if (tempArray) {
                tempArray?.splice(-1)
                tempArray[tempArray.length - 1]['active'] = true
                sessionStorage.setItem("breadCrumbData", JSON.stringify(tempArray));
                setArray(tempArray)
            }
        };
    });

    useEffect(() => {
        const data = sessionStorage.getItem('breadCrumbData')
        if (data) {
            setArray(JSON.parse(data))
        }
    }, [])

    const removeBreadCrumb = (keyValue: number) => {
        const selectBreadCrumb: any = breadCrumbArray[keyValue]
        const tempArray: any = breadCrumbArray.filter((child: any, key: number) => (key <= keyValue))

        tempArray[tempArray.length - 1]['active'] = true
        sessionStorage.setItem("breadCrumbData", JSON.stringify(tempArray));
        setArray(tempArray)
        navigate(selectBreadCrumb.link, {
            state: { passProps: selectBreadCrumb.passProps }
        });
    }

    return (
        <StyledBreadCrumb>
            {breadCrumbArray && breadCrumbArray.length ?
                breadCrumbArray.map((element: any, key: number) => {
                    return <StyledLink active={element.active} key={key}><StyledLinkTag
                        onClick={() => removeBreadCrumb(key)}
                        active={element.active}
                    > {element.key}</StyledLinkTag>
                    </StyledLink>
                }) : null}
        </StyledBreadCrumb>
    )
}
export default BreadCrumb;
