/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  GoToPageWrapper,
  NextPreviousWrapper,
  PaginationContainer,
  RootContainerTable,
  PopUpStyleLoader,
  SpaceMaker,
  ColoumnPaddingTop,
  ForwardButtonWrapper,
  BackwardButtonWrapper,
  ThWrapper,
  ThContentWrapper,
} from './styles'

import { useTable, useSortBy, usePagination, useFilters } from 'react-table'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LottieWrapper } from './styles'
import Lottie from 'lottie-react'
import Label from '../../../../components/label/Label'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { COLORS } from '../../../../theme/Colors'
import SvgBackwardDoubleChevron from '../../../../components/svg/SvgBackwardDoubleChevron'
import SvgForwardDoubleChevron from '../../../../components/svg/SvgForwardDoubleChevron'
import {
  getRegSHOSummaryData,
  setSortDirection,
  setSorting,
  sortDirectionSelector,
  sortingSelector,
} from '../../../../store/reg-SHO-Tool/Reg-SHO-Summary'
import {
  CommonTabProps,
  IRegSHOData,
  RegSHOTabDetails,
} from '../../RegSHOModels'
import RegSHOCusipSummary from '../../cusipLevelSummary/RegSHOCusipSummary'
import ColumnFilter, {
  ExclusiveCheckboxFilter,
  MultiSelectFilter,
} from '../../CommonComponents'
import { numberFilter, staticOptionsFilter, textArrayFilter } from '../../utils'
import FreeFormInputFilter from '../../CommonComponents'

interface IToolSummaryTableProps {
  columns: any
  fromDate: string
  toDate: string
  data: IRegSHOData[]
  totalRecords: number
  dropdownCusip: string
  dropdownSymbols: string
  commonTabProps: CommonTabProps
  setCommonTabProps: React.Dispatch<React.SetStateAction<any>>
  setActiveTab: React.Dispatch<React.SetStateAction<any>>
  handleActionedOnSaveClick: () => void
  filterData?: { id: string; value: string }[]
  setSummaryFilters?: React.Dispatch<React.SetStateAction<any>>
}
const ToolSummaryTable = ({
  columns,
  fromDate,
  toDate,
  data,
  totalRecords,
  dropdownCusip,
  dropdownSymbols,
  commonTabProps,
  setCommonTabProps,
  setActiveTab,
  handleActionedOnSaveClick,
  filterData,
  setSummaryFilters,
}: IToolSummaryTableProps) => {
  const dispatch = useDispatch()
  const [sortDirect, setSortDirect] = useState<any>('ASC')
  let [sortBy, setSortBy] = useState<any>('')
  const [popUpLoaderSort, setPopUpLoaderSort] = useState<boolean>(false)
  const [sortAction, setSortAction] = useState<boolean>(false)
  const [tempEvent, setTempEvent] = useState<string>('')
  const [pageStatus, setPageStatus] = useState<boolean>(false)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    setFilter,
    filteredFlatRows,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        filters: filterData,
      },
      useFilters,
      filterTypes: {
        numberFilter: numberFilter,
        textArrayFilter: textArrayFilter,
        staticOptionsFilter: staticOptionsFilter,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  )

  const sorting = async (e: any) => {
    setPopUpLoaderSort(true)
    setTempEvent(e)
    sortBy = e.replace(/_Formatted/g, '')
    setSortBy([...sortBy])
    dispatch(setSorting(sortBy))
    if (sortDirect == 'ASC') {
      setSortDirect('DESC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(true)
    }
    if (sortDirect == 'DESC') {
      setSortDirect('ASC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(false)
    }

    await dispatch(
      getRegSHOSummaryData(
        'sort',
        'P3',
        fromDate,
        toDate,
        pageIndex,
        20,
        sortBy,
        sortDirect,
        dropdownCusip,
        dropdownSymbols
      )
    )
    setPopUpLoaderSort(false)
  }
  return (
    <RootContainerTable>
      <table {...getTableProps()}>
        {popUpLoaderSort && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <SpaceMaker>
              <Label> Loading...</Label>
            </SpaceMaker>{' '}
          </PopUpStyleLoader>
        )}
        <thead>
          {headerGroups.map((headerGroup, rowIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={rowIndex}>
              {headerGroup.headers.map((column: any, index) => (
                <th
                  {...column.getHeaderProps({
                    style: { width: column.width, verticalAlign: 'top' },
                  })}
                  onClick={(e) => {
                    //trigger the react-table header onClick manually
                    if (column && column.onClick) {
                      column
                        .getHeaderProps(column.getSortByToggleProps())
                        .onClick(e)

                      sorting(column.id)
                    }
                  }}
                  key={index}
                >
                  {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                  <ThWrapper>
                    <ThContentWrapper>
                      {column.render('Header')}
                      <div>
                        {column.Filter
                          ? column.Filter === FreeFormInputFilter
                            ? column.render(FreeFormInputFilter, {
                                pageIndex: pageIndex,
                                gotoPage: gotoPage,
                                filterData,
                                setSummaryFilters,
                              })
                            : column.Filter === MultiSelectFilter
                            ? column.render(MultiSelectFilter, {
                                displayedRows: filteredFlatRows,
                                gotoPage: gotoPage,
                                filterData,
                                setSummaryFilters,
                              })
                            : column.Filter === ExclusiveCheckboxFilter
                            ? column.render(ExclusiveCheckboxFilter, {
                                displayedRows: filteredFlatRows,
                                gotoPage: gotoPage,
                                handleActionedOnSaveClick:
                                  handleActionedOnSaveClick,
                                filterData,
                                setSummaryFilters,
                              })
                            : null
                          : null}
                      </div>{' '}
                    </ThContentWrapper>
                    <ColoumnPaddingTop>
                      {column.id == tempEvent
                        ? sortAction
                          ? '🔼'
                          : '🔽 '
                        : ''}
                    </ColoumnPaddingTop>

                    {headerGroup.headers.length - 1 !== index && <div></div>}
                  </ThWrapper>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, i) => {
                  switch (cell.column.Cell?.toString()) {
                    case 'cusipNumber':
                      return (
                        <td key={i}>
                          <a
                            href="#"
                            onClick={() => {
                              setCommonTabProps &&
                                setCommonTabProps({
                                  ...commonTabProps,
                                  selectedSummaryTableCUSIP:
                                    cell?.row?.original?.cusiP_Number,
                                  regSHOId: cell?.row?.original?.regSHO_Id,
                                  failDate: cell?.row?.original?.failDate,
                                  selectedCUSIPReportDate:
                                    cell?.row?.original?.reportDate,
                                    tn:cell?.row?.original?.tn,
                                    approveFlag: cell?.row?.original?.approveFlag,
                                })
                              setActiveTab(
                                RegSHOTabDetails.find(
                                  (tab) => tab.component === RegSHOCusipSummary
                                )?.tabIndex
                              )
                            }}
                          >
                            {cell.value}
                          </a>
                        </td>
                      )

                    default:
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data.length == 0 && (
        <>
          <Label fontWeight={700} margin="5% 0 0 50%">
            No Records Found
          </Label>
          <Label fontWeight={700} margin="0.5% 0 0 49%">
            No Exceptions Identified
          </Label>
        </>
      )}

      <PaginationContainer>
        <Label>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </Label>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <GoToPageWrapper>
          <Label>Go to page:</Label>
          <input
            type="number"
            defaultValue={1}
            max={pageOptions.length}
            min={1}
            onChange={(e) => {
              if (Number(e.target.value) <= pageOptions.length) {
                e.stopPropagation()
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(pageNumber)
              }
            }}
            style={{ width: '50px' }}
          />
        </GoToPageWrapper>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <NextPreviousWrapper>
          <button
            onClick={() => {
              setPageStatus(true)
              previousPage()
            }}
            disabled={pageIndex + 1 === 1}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              setPageStatus(true)
              nextPage()
            }}
            disabled={pageIndex + 1 === pageCount}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Next
          </button>
        </NextPreviousWrapper>
      </PaginationContainer>
    </RootContainerTable>
  )
}

export default ToolSummaryTable
