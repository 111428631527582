/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'
import { useEffect, useState } from 'react'
import {
  CancelButtonWrapper,
  CommentTableContainer,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  PopUpStyleLoader,
  PopupCard,
  StyledButtonWrapper,
  StyledSelectWrapper,
  StyledTableContainer,
  ToastWrapper,
} from './styles'
import { Textarea } from '../../../components/textArea/Textarea'
import {
  DividendReconReportDataSelector,
  commentSelector,
  getComments,
  isLoadingSelector,
  putUserComment,
} from '../../../store/asset-Servicing/DividendReconReport'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import { Backgrounds } from '../../../shared/styles'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { Toast } from '../../../components/toast/Toast'
import ReportTable from './table/ReportTable'
import Table from '../../../components/table/Table'
import { getAccessFlag } from '../../../utils/globalFunctions'

const ReportDetails = (props: any) => {
  const dispatch = useDispatch()
  const data = useSelector(DividendReconReportDataSelector)
  const loading = useSelector(isLoadingSelector)
  const Usercomments = useSelector(commentSelector)
  const [comment, setComments] = useState<boolean>(false)
  const [input, setInput] = useState({
    commentRow: 0,
    commentDate: new Date().toISOString(),
  })
  const [inputComment, setInputComment] = useState<any>([])
  const [openComments, setOpenComments] = useState<boolean>(false)

  const writeExecuteFlag = getAccessFlag(['AS-RWX', 'OM-RWX'])

  const newUserComment = async (
    rpt172UniqueId: string,
    comment: string,
    user: any,
    rowId: number
  ) => {
    const response = await dispatch(
      putUserComment(
        rpt172UniqueId.toString(),
        comment,
        user,
        props.commentDate
      )
    )
    inputComment[rowId] = '  '
    setInputComment({ ...inputComment })
  }

  const savedComments = async (tb: any) => {
    await dispatch(getComments(data[tb].rpt172UniqueId))
    setOpenComments(true)
  }

  const DailyTableColumns = [
    {
      Header: 'Snapshot Date',
      accessor: 'snapshotdate',
    },
    {
      Header: 'Cusip',
      accessor: 'cusip',
    },
    {
      Header: 'Add Note',
      Cell: (table: any) => {
        return (
          <>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              disabled={!writeExecuteFlag}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                setComments(!comment)
                setInput({
                  ...input,
                  commentRow: table.cell.row.id,
                })
              }}
            >
              Add Notes
            </Button>

            {comment && table.cell.row.id === input.commentRow && (
              <PopupCard>
                <Textarea
                  onChange={(e: any) => {
                    inputComment[table.cell.row.id] = e.target.value
                    setInputComment(inputComment)
                  }}
                  height="365px"
                  placeholder={'Enter Comment'}
                ></Textarea>

                <StyledButtonWrapper>
                  <Button
                    style={{
                      marginRight: '16px',
                    }}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    type={'button'}
                    color={COLORS.Background.Primarytext}
                    bgColor={'transparent'}
                    onClick={() => {
                      setComments(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    hoverBgColor={COLORS.Background.Primary70}
                    bgColor={COLORS.Background.Primarytext}
                    color={COLORS.Background.Primary}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    hover={true}
                    type={''}
                    activeBgColor={COLORS.Background.Primaryactive}
                    borderColor={'transparent'}
                    onClick={async () => {
                      await newUserComment(
                        data[table.cell.row.id].rpt172UniqueId,
                        inputComment[table.cell.row.id],
                        localStorage.getItem('AccountName'),
                        table.cell.row.id
                      )
                      setComments(false)
                    }}
                  >
                    Submit
                  </Button>
                </StyledButtonWrapper>
              </PopupCard>
            )}
          </>
        )
      },
    },
    {
      Header: 'View Comments',
      Cell: (table: any) => {
        return (
          <>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                savedComments(table.cell.row.id)
              }}
            >
              Comments
            </Button>
          </>
        )
      },
    },
    {
      Header: 'Currency',
      accessor: 'currency',
    },
    {
      Header: 'Account Number',
      accessor: 'accountNumber',
    },
    {
      Header: 'Short Name',
      accessor: 'shortName',
    },
    {
      Header: 'Pay Date',
      accessor: 'payDate',
    },
    {
      Header: 'Last Activity Date',
      accessor: 'lastActivityDate',
    },
    {
      Header: 'Div Type',
      accessor: 'divType',
    },
    {
      Header: 'Original',
      accessor: 'original',
    },
    {
      Header: 'Under 7',
      accessor: 'under7',
    },
    {
      Header: 'Over 7',
      accessor: 'over7',
    },
    {
      Header: 'Over 30',
      accessor: 'over30',
    },
    {
      Header: 'Net Amount',
      accessor: 'netAmount',
    },
    {
      Header: 'Grand Total',
      accessor: 'grandTotal',
    },
    {
      Header: 'Age By PayDate',
      accessor: 'ageByPayDate',
    },
    {
      Header: 'Age By LastActivityDate',
      accessor: 'ageByLastActivityDate',
    },
  ]
  return (
    <>
      {openComments && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>View Comment</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenComments(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'CreatedBy',
                  accessor: 'createdBy',
                },
                {
                  Header: 'CreatedOn(EST)',
                  accessor: 'createdOn',
                },
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
              ]}
              tableData={Usercomments ? Usercomments : []}
              onClick={() => {
                //
              }}
            />
            {Usercomments.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      <WDCard>
        <CardHeader
          label={'Dividend open items reconciliation and tracking tool'}
          icon={false}
          amountValue={`Total: ${props.totalRecords}`}
        />
      </WDCard>
      <StyledTableContainer>
        <ReportTable
          columns={DailyTableColumns}
          data={data}
          reportType={props.reportType}
          startDate={props.startDate}
          endDate={props.startDate}
          cusip={props.cusip}
          accountNumber={props.accountNumber}
          ageByPayDateRange={props.ageByPayDateRange}
          ageByLastActivityDateRange={props.ageByLastActivityDateRange}
          includeFailComments={props.includeFailComments}
          totalRecords={props.totalRecords}
        ></ReportTable>
      </StyledTableContainer>
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
    </>
  )
}

export default ReportDetails
function setAccessFlag(arg0: any) {
  throw new Error('Function not implemented.')
}
