/* eslint-disable react/prop-types */
// /* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  GoToPageWrapper,
  NextPriviousWrapper,
  PaginationContainer,
  RootContainerTable,
  SpaceMaker,
  ColoumnPaddingTop,
  ForwardButtonWrapper,
  BackwardButtonWrapper,
  ThWrapper,
  ThContentWrapper,
  ViewColumnsContainer,
  ViewColumnsPopupWrapper,
  ActionsButtonWrapper,
  CancelSaveWrapper,
  CheckboxStyle,
} from './styles'
import {
  setSorting,
  setSortDirection,
  getP3StalePriceData,
  sortingSelectorP3,
  sortDirectionSelectorP3,
  setPageNumberP3,
  PageNumSelectorP3,
  LoadingSelector,
} from '../../../../../store/staticData/stale-pricing-p3'
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useMountedLayoutEffect,
} from 'react-table'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LottieWrapper, PopUpStyleLoader, SvgButtonWrapper } from '../../styles'
import Lottie from 'lottie-react'
import Label from '../../../../../components/label/Label'
import Loader from '../../../../../assets/lottiefiles/loader.json'
import { COLORS } from '../../../../../theme/Colors'
import SvgBackwardDoubleChevron from '../../../../../components/svg/SvgBackwardDoubleChevron'
import SvgForwardDoubleChevron from '../../../../../components/svg/SvgForwardDoubleChevron'
import Button from '../../../../../components/button/Button'
import { WDTextStrong } from '../../../../../components/ui/WDTypography'
import BulkComment from '../../BulkComment'
import EmailModal from '../../EmailModal'
import AddEmail from '../../AddEmail'
import { P3TableFilterList } from '../p3TableFilter/P3TableFilterData'
import SvgCancel from '../../../../../components/svg/logo/SvgCancel'
import Checkbox from '../../../../../components/checkbox/Checkbox'
import Divider from '../../../../../components/divider/Divider'
import { Backgrounds } from '../../../../../shared/styles'
import BulkFlag from '../../BulkFlag'
import { getAccessFlag } from '../../../../../utils/globalFunctions'

const IndeterminateCheckbox = ({ ...rest }) => {
  return (
    <>
      <CheckboxStyle type="checkbox" {...rest} />
    </>
  )
}

const CreateTable = (props: any) => {
  const dispatch = useDispatch()
  const sortingCol = useSelector(sortingSelectorP3)
  const sortingDir = useSelector(sortDirectionSelectorP3)
  const pageP3 = useSelector(PageNumSelectorP3)
  const loading = useSelector(LoadingSelector)
  const [sortDirect, setSortDirect] = useState<any>('ASC')
  let [sortBy, setSortBy] = useState<any>('')
  const [sortAction, setSortAction] = useState<boolean>(false)
  const [tempEvent, setTempEvent] = useState<string>('')
  const [pageNumberCall, setPageNumberCall] = useState<any>(1)
  const [pageStatus, setPageStatus] = useState<boolean>(false)
  const [timeoutId, setTimeoutId] = useState()
  const [showColumnPopup, setShowColumnPopup] = useState<boolean>(false)
  const [showEmailPopup, setShowEmailPopup] = useState<boolean>(false)
  const [showAddEmail, setShowAddEmail] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any>()

  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)

  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['SD-RWX', 'OM-RWX']))
  }, [])

  const TopColumn: any = []
  ;(TopColumn[0] = 'P3 Databases'),
    (TopColumn[13] = 'DTC Data'),
    (TopColumn[14] = 'P3 RPT058 Report Data'),
    (TopColumn[23] = 'Current RPT058 Report')
  const ChooseColumnList = [
    'boxLocation',
    'locationMemo',
    'boxDesc',
    'country',
    'securityTypeCode',
    'security_Type_and_Sub_Type_Code',
    'maturity_WarrantExpire_Date',
    'inDefault',
    'description2',
    'description3',
    'eodcmvdaT_LastPriced',
    'eodcmvdaT_LastPrice',
    'issue_Status_Code',
    'dtC_Premed_Elig',
    'maturityDate',
    'couponRate',
    'symbol',
    'currency',
    'lastPriceDate',
    'lastPrice',
    'cashQnty',
    'marginQnty',
    'inventory',
    'currently_Still_In_StaleReport',
  ]
  const [hide, setHide] = useState<any>([
    'boxLocation',
    'locationMemo',
    'boxDesc',
    'country',
    'securityTypeCode',
    'security_Type_and_Sub_Type_Code',
    'maturity_WarrantExpire_Date',
    'inDefault',
    'description2',
    'description3',
    'eodcmvdaT_LastPriced',
    'eodcmvdaT_LastPrice',
    'issue_Status_Code',
    'dtC_Premed_Elig',
    'maturityDate',
    'couponRate',
    'symbol',
    'currency',
    'lastPriceDate',
    'lastPrice',
    'cashQnty',
    'marginQnty',
    'inventory',
    'currently_Still_In_StaleReport',
  ])
  const INITIAL_SELECTED_ROW_IDS = {}
  let data = props.data
  const columns = props.columns
  const totalRecords = props.totalRecords
  const fromDate = props.fromDate
  const toDate = props.toDate

  useEffect(() => {
    if (pageP3 == 0) {
      setPageNumberCall(1)
    }
  }, [pageP3])

  let controlledPageCount: number
  controlledPageCount = Math.ceil(totalRecords / 20)

  const securityDescription =
    P3TableFilterList[0]?.dropDownArray[0].value &&
    P3TableFilterList[0].selectedData[0]
      ? P3TableFilterList[0].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[0]?.dropDownArray[0].value &&
        !P3TableFilterList[0].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[0].selectedData.join(';').toString()

  let top_Category = ''
  if (
    P3TableFilterList[1].dropDownArray.filter((option) => option.value)
      .length == P3TableFilterList[1].dropDownArray.length
  ) {
    top_Category = ''
  } else {
    top_Category = P3TableFilterList[1].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  let sub_Category = ''
  if (
    P3TableFilterList[2].dropDownArray.filter((option) => option.value)
      .length == P3TableFilterList[2].dropDownArray.length
  ) {
    sub_Category = ''
  } else {
    sub_Category = P3TableFilterList[2].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  const reviewer_LatestComment =
    P3TableFilterList[3]?.dropDownArray[0].value &&
    P3TableFilterList[3].selectedData[0]
      ? P3TableFilterList[3].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[3]?.dropDownArray[0].value &&
        !P3TableFilterList[3].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[3].selectedData.join(';').toString()

  const security_Type_and_Sub_Type_Code =
    P3TableFilterList[4]?.dropDownArray[0].value &&
    P3TableFilterList[4].selectedData[0]
      ? P3TableFilterList[4].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[4]?.dropDownArray[0].value &&
        !P3TableFilterList[4].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[4].selectedData.join(';').toString()

  const lastPrice_In_Current_StaleReport =
    P3TableFilterList[5]?.dropDownArray[0].value &&
    P3TableFilterList[5].selectedData[0]
      ? P3TableFilterList[5].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[5]?.dropDownArray[0].value &&
        !P3TableFilterList[5].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[5].selectedData.join(';').toString()

  let followup_Flag: string
  if (
    P3TableFilterList[6].dropDownArray.filter((option) => option.value)
      .length == P3TableFilterList[6].dropDownArray.length
  ) {
    followup_Flag = ''
  } else {
    followup_Flag = P3TableFilterList[6].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.labelValue)
      .join(';')
      .toString()
  }

  const cusip_Number =
    P3TableFilterList[7]?.dropDownArray[0].value &&
    P3TableFilterList[7].selectedData[0]
      ? P3TableFilterList[7].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[7]?.dropDownArray[0].value &&
        !P3TableFilterList[7].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[7].selectedData.join(';').toString()

  const isiN_NUMBER =
    P3TableFilterList[8]?.dropDownArray[0].value &&
    P3TableFilterList[8].selectedData[0]
      ? P3TableFilterList[8].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[8]?.dropDownArray[0].value &&
        !P3TableFilterList[8].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[8].selectedData.join(';').toString()

  const comments_Created_By =
    P3TableFilterList[9]?.dropDownArray[0].value &&
    P3TableFilterList[9].selectedData[0]
      ? P3TableFilterList[9].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[9]?.dropDownArray[0].value &&
        !P3TableFilterList[9].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[9].selectedData.join(';').toString()

  const reviewer_Last_Commented_Date =
    P3TableFilterList[10]?.dropDownArray[0].value &&
    P3TableFilterList[10].selectedData[0]
      ? P3TableFilterList[10].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[10]?.dropDownArray[0].value &&
        !P3TableFilterList[10].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[10].selectedData.join(';').toString()

  const icE_Last_Priced =
    P3TableFilterList[11]?.dropDownArray[0].value &&
    P3TableFilterList[11].selectedData[0]
      ? P3TableFilterList[11].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[11]?.dropDownArray[0].value &&
        !P3TableFilterList[11].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[11].selectedData.join(';').toString()

  const icE_Last_Price =
    P3TableFilterList[12]?.dropDownArray[0].value &&
    P3TableFilterList[12].selectedData[0]
      ? P3TableFilterList[12].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[12]?.dropDownArray[0].value &&
        !P3TableFilterList[12].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[12].selectedData.join(';').toString()

  const icE_Notes =
    P3TableFilterList[13]?.dropDownArray[0].value &&
    P3TableFilterList[13].selectedData[0]
      ? P3TableFilterList[13].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[13]?.dropDownArray[0].value &&
        !P3TableFilterList[13].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[13].selectedData.join(';').toString()

  const bbG_Last_Priced =
    P3TableFilterList[14]?.dropDownArray[0].value &&
    P3TableFilterList[14].selectedData[0]
      ? P3TableFilterList[14].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[14]?.dropDownArray[0].value &&
        !P3TableFilterList[14].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[14].selectedData.join(';').toString()

  const bbG_Last_Price =
    P3TableFilterList[15]?.dropDownArray[0].value &&
    P3TableFilterList[15].selectedData[0]
      ? P3TableFilterList[15].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[15]?.dropDownArray[0].value &&
        !P3TableFilterList[15].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[15].selectedData.join(';').toString()

  const boxLocation =
    P3TableFilterList[16]?.dropDownArray[0].value &&
    P3TableFilterList[16].selectedData[0]
      ? P3TableFilterList[16].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[16]?.dropDownArray[0].value &&
        !P3TableFilterList[16].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[16].selectedData.join(';').toString()

  const locationMemo =
    P3TableFilterList[17]?.dropDownArray[0].value &&
    P3TableFilterList[17].selectedData[0]
      ? P3TableFilterList[17].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[17]?.dropDownArray[0].value &&
        !P3TableFilterList[17].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[17].selectedData.join(';').toString()

  const boxDesc =
    P3TableFilterList[18]?.dropDownArray[0].value &&
    P3TableFilterList[18].selectedData[0]
      ? P3TableFilterList[18].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[18]?.dropDownArray[0].value &&
        !P3TableFilterList[18].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[18].selectedData.join(';').toString()

  const country =
    P3TableFilterList[19]?.dropDownArray[0].value &&
    P3TableFilterList[19].selectedData[0]
      ? P3TableFilterList[19].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[19]?.dropDownArray[0].value &&
        !P3TableFilterList[19].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[19].selectedData.join(';').toString()

  const securityTypeCode =
    P3TableFilterList[20]?.dropDownArray[0].value &&
    P3TableFilterList[20].selectedData[0]
      ? P3TableFilterList[20].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[20]?.dropDownArray[0].value &&
        !P3TableFilterList[20].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[20].selectedData.join(';').toString()

  const maturity_WarrantExpire_Date =
    P3TableFilterList[21]?.dropDownArray[0].value &&
    P3TableFilterList[21].selectedData[0]
      ? P3TableFilterList[21].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[21]?.dropDownArray[0].value &&
        !P3TableFilterList[21].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[21].selectedData.join(';').toString()

  const inDefault =
    P3TableFilterList[22]?.dropDownArray[0].value &&
    P3TableFilterList[22].selectedData[0]
      ? P3TableFilterList[22].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[22]?.dropDownArray[0].value &&
        !P3TableFilterList[22].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[22].selectedData.join(';').toString()

  const description2 =
    P3TableFilterList[23]?.dropDownArray[0].value &&
    P3TableFilterList[23].selectedData[0]
      ? P3TableFilterList[23].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[23]?.dropDownArray[0].value &&
        !P3TableFilterList[23].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[23].selectedData.join(';').toString()

  const description3 =
    P3TableFilterList[24]?.dropDownArray[0].value &&
    P3TableFilterList[24].selectedData[0]
      ? P3TableFilterList[24].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[24]?.dropDownArray[0].value &&
        !P3TableFilterList[24].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[24].selectedData.join(';').toString()

  const eodcmvdaT_LastPriced =
    P3TableFilterList[25]?.dropDownArray[0].value &&
    P3TableFilterList[25].selectedData[0]
      ? P3TableFilterList[25].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[25]?.dropDownArray[0].value &&
        !P3TableFilterList[25].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[25].selectedData.join(';').toString()

  const eodcmvdaT_LastPrice =
    P3TableFilterList[26]?.dropDownArray[0].value &&
    P3TableFilterList[26].selectedData[0]
      ? P3TableFilterList[26].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[26]?.dropDownArray[0].value &&
        !P3TableFilterList[26].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[26].selectedData.join(';').toString()

  const issue_Status_Code =
    P3TableFilterList[27]?.dropDownArray[0].value &&
    P3TableFilterList[27].selectedData[0]
      ? P3TableFilterList[27].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[27]?.dropDownArray[0].value &&
        !P3TableFilterList[27].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[27].selectedData.join(';').toString()

  const dtC_Premed_Elig =
    P3TableFilterList[28]?.dropDownArray[0].value &&
    P3TableFilterList[28].selectedData[0]
      ? P3TableFilterList[28].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[28]?.dropDownArray[0].value &&
        !P3TableFilterList[28].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[28].selectedData.join(';').toString()

  const maturityDate =
    P3TableFilterList[29]?.dropDownArray[0].value &&
    P3TableFilterList[29].selectedData[0]
      ? P3TableFilterList[29].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[29]?.dropDownArray[0].value &&
        !P3TableFilterList[29].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[29].selectedData.join(';').toString()

  const couponRate =
    P3TableFilterList[30]?.dropDownArray[0].value &&
    P3TableFilterList[30].selectedData[0]
      ? P3TableFilterList[30].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[30]?.dropDownArray[0].value &&
        !P3TableFilterList[30].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[30].selectedData.join(';').toString()

  const symbol =
    P3TableFilterList[31]?.dropDownArray[0].value &&
    P3TableFilterList[31].selectedData[0]
      ? P3TableFilterList[31].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[31]?.dropDownArray[0].value &&
        !P3TableFilterList[31].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[31].selectedData.join(';').toString()

  const currency =
    P3TableFilterList[32]?.dropDownArray[0].value &&
    P3TableFilterList[32].selectedData[0]
      ? P3TableFilterList[32].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[32]?.dropDownArray[0].value &&
        !P3TableFilterList[32].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[32].selectedData.join(';').toString()

  const lastPriceDate =
    P3TableFilterList[33]?.dropDownArray[0].value &&
    P3TableFilterList[33].selectedData[0]
      ? P3TableFilterList[33].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[33]?.dropDownArray[0].value &&
        !P3TableFilterList[33].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[33].selectedData.join(';').toString()

  const lastPrice =
    P3TableFilterList[34]?.dropDownArray[0].value &&
    P3TableFilterList[34].selectedData[0]
      ? P3TableFilterList[34].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[34]?.dropDownArray[0].value &&
        !P3TableFilterList[34].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[34].selectedData.join(';').toString()

  const cashQnty =
    P3TableFilterList[35]?.dropDownArray[0].value &&
    P3TableFilterList[35].selectedData[0]
      ? P3TableFilterList[35].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[35]?.dropDownArray[0].value &&
        !P3TableFilterList[35].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[35].selectedData.join(';').toString()

  const marginQnty =
    P3TableFilterList[36]?.dropDownArray[0].value &&
    P3TableFilterList[36].selectedData[0]
      ? P3TableFilterList[36].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[36]?.dropDownArray[0].value &&
        !P3TableFilterList[36].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[36].selectedData.join(';').toString()

  const inventory =
    P3TableFilterList[37]?.dropDownArray[0].value &&
    P3TableFilterList[37].selectedData[0]
      ? P3TableFilterList[37].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[37]?.dropDownArray[0].value &&
        !P3TableFilterList[37].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[37].selectedData.join(';').toString()

  const currently_Still_in_StaleReport =
    P3TableFilterList[38]?.dropDownArray[0].value &&
    P3TableFilterList[38].selectedData[0]
      ? P3TableFilterList[38].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[38]?.dropDownArray[0].value &&
        !P3TableFilterList[38].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[38].selectedData.join(';').toString()

  const last_Priced_In_Current_StaleReport =
    P3TableFilterList[39]?.dropDownArray[0].value &&
    P3TableFilterList[39].selectedData[0]
      ? P3TableFilterList[39].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[39]?.dropDownArray[0].value &&
        !P3TableFilterList[39].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[39].selectedData.join(';').toString()

  const commentFilter =
    P3TableFilterList[40]?.dropDownArray[0].value &&
    P3TableFilterList[40].selectedData[0]
      ? P3TableFilterList[40].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[40]?.dropDownArray[0].value &&
        !P3TableFilterList[40].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[40].selectedData.join(';').toString()

  const notes =
    P3TableFilterList[41]?.dropDownArray[0].value &&
    P3TableFilterList[41].selectedData[0]
      ? P3TableFilterList[41].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[41]?.dropDownArray[0].value &&
        !P3TableFilterList[41].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[41].selectedData.join(';').toString()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        pageIndex: 1,
        pageSize: 20,
        selectedRowIds: INITIAL_SELECTED_ROW_IDS, // Do not just use {}
        hiddenColumns: hide,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: '2%',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const { selectedRowIds } = state

  useMountedLayoutEffect(() => {
    const selectedIds = Object.keys(selectedRowIds)
    const selectedRowsData = selectedIds
      .map((x) => data[x])
      .filter(function (x) {
        return x != null
      })
    setSelectedRows(selectedRowsData)
  }, [selectedRowIds])

  const sorting = async (e: any) => {
    setTempEvent(e)

    sortBy = e.replace(/_Formatted/g, '')
    setSortBy([...sortBy])
    dispatch(setSorting(sortBy))

    if (sortDirect == 'ASC') {
      setSortDirect('DESC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(true)
    }

    if (sortDirect == 'DESC') {
      setSortDirect('ASC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(false)
    }

    await dispatch(
      getP3StalePriceData(
        'sort',
        'P3',
        fromDate,
        toDate,
        pageNumberCall - 1,
        20,
        sortBy,
        sortDirect,
        0,
        securityDescription,
        top_Category,
        sub_Category,
        reviewer_LatestComment,
        security_Type_and_Sub_Type_Code,
        lastPrice_In_Current_StaleReport,
        followup_Flag,
        cusip_Number,
        isiN_NUMBER,
        comments_Created_By,
        reviewer_Last_Commented_Date,
        icE_Last_Priced,
        icE_Last_Price,
        icE_Notes,
        bbG_Last_Priced,
        bbG_Last_Price,
        boxLocation,
        locationMemo,
        boxDesc,
        country,
        securityTypeCode,
        maturity_WarrantExpire_Date,
        inDefault,
        description2,
        description3,
        eodcmvdaT_LastPriced,
        eodcmvdaT_LastPrice,
        issue_Status_Code,
        dtC_Premed_Elig,
        maturityDate,
        couponRate,
        symbol,
        currency,
        lastPriceDate,
        lastPrice,
        cashQnty,
        marginQnty,
        inventory,
        currently_Still_in_StaleReport,
        last_Priced_In_Current_StaleReport,
        commentFilter,
        notes
      )
    )
  }

  const pageCallFunction = async (pageIndex: any, rowRange: any) => {
    dispatch(setPageNumberP3(pageIndex - 1))

    await dispatch(
      getP3StalePriceData(
        'search',
        'P3',
        fromDate,
        toDate,
        pageIndex - 1,
        rowRange,
        sortingCol,
        sortingDir,
        0,
        securityDescription,
        top_Category,
        sub_Category,
        reviewer_LatestComment,
        security_Type_and_Sub_Type_Code,
        lastPrice_In_Current_StaleReport,
        followup_Flag,
        cusip_Number,
        isiN_NUMBER,
        comments_Created_By,
        reviewer_Last_Commented_Date,
        icE_Last_Priced,
        icE_Last_Price,
        icE_Notes,
        bbG_Last_Priced,
        bbG_Last_Price,
        boxLocation,
        locationMemo,
        boxDesc,
        country,
        securityTypeCode,
        maturity_WarrantExpire_Date,
        inDefault,
        description2,
        description3,
        eodcmvdaT_LastPriced,
        eodcmvdaT_LastPrice,
        issue_Status_Code,
        dtC_Premed_Elig,
        maturityDate,
        couponRate,
        symbol,
        currency,
        lastPriceDate,
        lastPrice,
        cashQnty,
        marginQnty,
        inventory,
        currently_Still_in_StaleReport,
        last_Priced_In_Current_StaleReport,
        commentFilter,
        notes
      )
    )
  }

  useEffect(() => {
    if (pageStatus) {
      pageCallFunction(pageNumberCall, 20)
    }
  }, [pageNumberCall])

  const onChangeFunction = (pageValue: number) => {
    if (pageValue > 0 && pageValue <= pageCount) {
      const pageNumber = pageValue ? pageValue : 1
      setPageStatus(true)
      clearTimeout(timeoutId)
      const timeout: any = setTimeout(() => {
        gotoPage(pageNumber)
        setPageNumberCall(pageNumber)
      }, 3000)
      setTimeoutId(timeout)
    }
  }
  const renderColors = (flag: number) => {
    switch (flag) {
      case 1:
        return '#ffe6cc'
      case 2:
        return '#e6ffe6'
      case 3:
        return '#f2f2f2'
      default:
        return ''
    }
  }
  const getTrProps = (rowInfo: any) => {
    return {
      style: {
        background: rowInfo?.original?.followup_Flag
          ? renderColors(Number(rowInfo?.original?.followup_Flag))
          : '',
        border: 'none',
      },
    }
  }
  return (
    <>
      <PaginationContainer>
        <Label>
          Page{' '}
          <strong>
            {pageNumberCall} of {pageOptions.length}
          </strong>{' '}
        </Label>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <GoToPageWrapper>
          <Label>Go to page:</Label>
          <input
            type="number"
            min="1"
            max={pageCount}
            defaultValue={pageNumberCall}
            onChange={(e) => {
              onChangeFunction(Number(e.target.value))
            }}
            style={{ width: '50px' }}
          />
        </GoToPageWrapper>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <NextPriviousWrapper>
          <BackwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(1)
              setPageNumberCall(1)
            }}
          >
            {pageNumberCall !== 1 ? (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </BackwardButtonWrapper>
          <button
            onClick={() => {
              setPageStatus(true)
              previousPage()
              setPageNumberCall(pageNumberCall - 1)
            }}
            disabled={pageNumberCall === 1}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              setPageStatus(true)
              nextPage()
              setPageNumberCall(pageNumberCall + 1)
            }}
            disabled={pageNumberCall === pageCount}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Next
          </button>
          <ForwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(pageCount)
              setPageNumberCall(pageCount)
            }}
          >
            {pageNumberCall !== pageCount ? (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </ForwardButtonWrapper>
        </NextPriviousWrapper>
      </PaginationContainer>
      <ViewColumnsContainer>
        <BulkFlag
          fromDate={fromDate}
          toDate={toDate}
          handleSubmit={props.handleSubmit}
          system={'P3'}
          selectedRows={selectedRows}
          toggleAllRowsSelected={toggleAllRowsSelected}
        />
        <BulkComment
          fromDate={fromDate}
          toDate={toDate}
          handleSubmit={props.handleSubmit}
          system={'P3'}
          selectedRows={selectedRows}
          toggleAllRowsSelected={toggleAllRowsSelected}
        />

        <Button
          padding={'4px 16px'}
          borderRadius={'4px'}
          type={'button'}
          color={COLORS.Background.Primarytext}
          style={{ whiteSpace: 'nowrap' }}
          bgColor={'transparent'}
          disabled={!writeExecuteFlag}
          onClick={() => {
            setShowAddEmail(!showAddEmail)
          }}
        >
          Add Contact
        </Button>
        {showAddEmail && (
          <AddEmail
            fromDate={fromDate}
            System={'P3'}
            selectedRows={selectedRows}
            toggleAllRowsSelected={toggleAllRowsSelected}
            setShowAddEmail={setShowAddEmail}
          ></AddEmail>
        )}
        {selectedRows && selectedRows.length > 0 ? (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Primarytext}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
            disabled={!writeExecuteFlag}
            onClick={() => {
              setShowEmailPopup(!showEmailPopup)
            }}
          >
            Send Email
          </Button>
        ) : (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Neutral40}
            borderColor={COLORS.Background.Neutral40}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
          >
            Send Email
          </Button>
        )}
        {showEmailPopup && (
          <EmailModal
            fromDate={fromDate}
            System={'P3'}
            selectedRows={selectedRows}
            toggleAllRowsSelected={toggleAllRowsSelected}
            setShowEmailPopup={setShowEmailPopup}
          ></EmailModal>
        )}
        <Button
          padding={'4px 16px'}
          borderRadius={'4px'}
          type={'button'}
          color={COLORS.Background.Primarytext}
          style={{ whiteSpace: 'nowrap' }}
          bgColor={'transparent'}
          onClick={() => {
            setShowColumnPopup(!showColumnPopup)
            !showColumnPopup == false && pageCallFunction(pageNumberCall, 20)
          }}
        >
          View Columns
        </Button>
        {showColumnPopup && (
          <ViewColumnsPopupWrapper>
            <div style={{ height: '90%', overflow: 'auto' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <WDTextStrong>Choose columns</WDTextStrong>
                <SvgButtonWrapper
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {
                    setShowColumnPopup(false)
                    pageCallFunction(pageNumberCall, 20)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </div>
              {ChooseColumnList?.map((item: any, index: number) => {
                return (
                  <>
                    {
                      <Label padding="2px" fontWeight={600}>
                        {TopColumn[index]}
                      </Label>
                    }
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '4px',
                      }}
                      key={index}
                    >
                      <Checkbox
                        defaultChecked={!hide.includes(item)}
                        onChange={(e: any) => {
                          const index = hide.indexOf(item)
                          {
                            e.target.checked
                              ? index > -1 && hide.splice(index, 1)
                              : setHide([...hide, item])
                          }
                        }}
                      ></Checkbox>{' '}
                      {item.toUpperCase().replace(/_/g, ' ')}
                    </div>
                    <Divider
                      thickness={'1px'}
                      horizontal={true}
                      bgColor={Backgrounds.Gray110}
                    />
                  </>
                )
              })}
            </div>
            <ActionsButtonWrapper>
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                style={{ whiteSpace: 'nowrap' }}
                bgColor={'transparent'}
                onClick={() => {
                  setHide(ChooseColumnList)
                  pageCallFunction(pageNumberCall, 20)
                  setShowColumnPopup(!showColumnPopup)
                }}
              >
                Default
              </Button>
              <CancelSaveWrapper>
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={() => {
                    pageCallFunction(pageNumberCall, 20)
                    setShowColumnPopup(!showColumnPopup)
                  }}
                >
                  Save
                </Button>
              </CancelSaveWrapper>
            </ActionsButtonWrapper>
          </ViewColumnsPopupWrapper>
        )}
      </ViewColumnsContainer>

      <table {...getTableProps()}>
        {loading && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <SpaceMaker>
              <Label> Loading...</Label>
            </SpaceMaker>{' '}
          </PopUpStyleLoader>
        )}
        <thead>
          {headerGroups.map((headerGroup, rowIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={rowIndex}>
              {headerGroup.headers.map((column: any, index) => (
                <th
                  {...column.getHeaderProps({
                    style: { width: column.width },
                  })}
                  onClick={(e) => {
                    //trigger the react-table header onClick manually
                    column
                      .getHeaderProps(column.getSortByToggleProps())
                      .onClick(e)

                    sorting(column.id)
                  }}
                  key={index}
                >
                  <ThWrapper>
                    <ThContentWrapper>
                      {column.render('Header')}
                    </ThContentWrapper>
                    <ColoumnPaddingTop>
                      {column.id == tempEvent
                        ? sortAction
                          ? '🔼'
                          : '🔽 '
                        : ''}
                    </ColoumnPaddingTop>

                    {headerGroup.headers.length - 1 !== index && <div></div>}
                  </ThWrapper>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} {...getTrProps(row)} key={index}>
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data.length == 0 && (
        <>
          <Label fontWeight={700} margin="5% 0 0 50%">
            No Records Found
          </Label>
          <Label fontWeight={700} margin="0.5% 0 0 49%">
            No Exceptions Identified
          </Label>
        </>
      )}

      <PaginationContainer>
        <Label>
          Page{' '}
          <strong>
            {pageNumberCall} of {pageOptions.length}
          </strong>{' '}
        </Label>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <GoToPageWrapper>
          <Label>Go to page:</Label>
          <input
            type="number"
            min="1"
            max={pageCount}
            defaultValue={pageNumberCall}
            onChange={(e) => {
              onChangeFunction(Number(e.target.value))
            }}
            style={{ width: '50px' }}
          />
        </GoToPageWrapper>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <NextPriviousWrapper>
          <BackwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(1)
              setPageNumberCall(1)
            }}
          >
            {pageNumberCall !== 1 ? (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </BackwardButtonWrapper>
          <button
            onClick={() => {
              setPageStatus(true)
              previousPage()
              setPageNumberCall(pageNumberCall - 1)
            }}
            disabled={pageNumberCall === 1}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              setPageStatus(true)
              nextPage()
              setPageNumberCall(pageNumberCall + 1)
            }}
            disabled={pageNumberCall === pageCount}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Next
          </button>
          <ForwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(pageCount)
              setPageNumberCall(pageCount)
            }}
          >
            {pageNumberCall !== pageCount ? (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </ForwardButtonWrapper>
        </NextPriviousWrapper>
      </PaginationContainer>
    </>
  )
}

const P3StalePricingTable = (props: any) => {
  return (
    <RootContainerTable>
      <CreateTable
        handleSubmit={props.handleSubmit}
        fromDate={props.fromDate}
        toDate={props.toDate}
        data={props.data}
        columns={props.columns}
        totalRecords={props.totalRecords}
      />
    </RootContainerTable>
  )
}

export default P3StalePricingTable
