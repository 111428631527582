import React, { useState, useMemo } from 'react';
import { useTable, useFilters } from 'react-table';

export const  TableComponent = ({ columns, data }:any) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters
  );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, key) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={key}>
            {headerGroup.headers.map((column: any, index: any) => (
              <th {...column.getHeaderProps()}
              key={index}>
                {column.render('Header')}
               
                <div>
                {/* <>{console.log(column.canFilter)}</> */}
                  {column.canFilter ? column.render('Filter') : null}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, key) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}
            key={key}>
              {row.cells.map((cell, key) => (
                <td {...cell.getCellProps()}
                key={key}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};







