/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  tradeManagement: []
  isLoading: boolean
  action: string
  entityType: []
  age: []
  entityCode: []
  httpErrorMessage: string
  successMessage: string
}

let initialState: State = {
  tradeManagement: [],
  isLoading: true,
  action: '',
  entityType: [],
  age: [],
  entityCode: [],
  httpErrorMessage: '',
  successMessage: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'trade-break',
  initialState,
  reducers: {
    SetTradeManagement: (state: State, action: PayloadAction<any>) => {
      state.tradeManagement = action.payload.data
    },
    SetentityType: (state: State, action: PayloadAction<any>) => {
      state.entityType = action.payload.data
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },

    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },

    setEntityCode: (state: State, action: PayloadAction<any>) => {
      state.entityCode = action.payload.data
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  setAction,
  SetTradeManagement,
  setIsLoading,
  SetentityType,
  setEntityCode,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

//ask we dont have get ?
export const getEntityType: any =
  (fromDate: string, toDate: string, settleDate: any, entitycode: any) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/TradeBreak/GetTradeBreakByEntityCode',
        {
          fromDate,
          toDate,
          settleDate,
          entitycode,
        }
      )

      if (status === 200) {
        dispatch(
          SetentityType({
            data: data,
          })
        )

        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
  }

export const getECtradeBreakEXCEL: any =
  (
    filename: string,
    fromDate: string,
    toDate: string,
    settleDate: string,
    entitycode: string,
    cusip: string,
    symbol: string,
    assetType: string,
    mpid: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/IntraDayTradeBreak/ExportToExcelEntityCodeLevel',
        {
          fromDate,
          toDate,
          entitycode,
          cusip,
          symbol,
          assetType,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}.xlsx`)
        dispatch(setIsLoading(false))
        document.body.appendChild(link)
        link.click()
        dispatch(setSuccessMessage('File Downloaded Successfully'))
        return status
      }
      dispatch(setIsLoading(false))
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return 400
    }
  }

export const getTradeBreak: any =
  (
    action: string,
    fromDate: string,
    toDate: string,
    settleDate: any,
    entityType: any,
    entitycode: any,
    cusip: any,
    symbol: any,
    mpid: any,
    assetType: any,
    tradeBreakcase: 0
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/IntraDayTradeBreak/GetIntraDayBreakByEntityCode',
        {
          fromDate,
          toDate,
          entitycode,
          cusip,
          symbol: null,
          assetType: null,
        }
      )

      if (status === 200) {
        dispatch(
          SetTradeManagement({
            data: data,
          })
        )
        dispatch(setAction(action))
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        SetTradeManagement({
          data: [],
        })
      )
      dispatch(setIsLoading(false))
    }
  }

export const getEntityCode: any = () => async (dispatch: Dispatch) => {
  try {
    const { data, status } = await authApiCall.get(
      'tradebreakapi/TradeBreak/GetEntityCodeList'
    )
    if (status === 200) {
      const entityCode = data?.entityCodeList
      dispatch(
        setEntityCode({
          data: entityCode,
        })
      )
      return data
    }
  } catch (e: any) {
    handleHttpError(dispatch, e, setHttpErrorMessage)
    dispatch(
      setEntityCode({
        data: [],
      })
    )
  }
}

export const entitySelector = (store: StoreState) =>
  store.trade.TradeManagement.entityType

export const tradeManageSelector = (store: StoreState) =>
  store.trade.TradeManagement.tradeManagement
//mockData.opsTradeBreakViewModels

export const actionSelector = (store: StoreState) =>
  store.trade.TradeManagement.action

export const loadingSelector = (store: StoreState) =>
  store.trade.TradeManagement.isLoading

export const entityCodeSelector = (store: StoreState) =>
  store.EquityBreakManage.EquityBreakManagement.entityCode

export const HttpErrorMessageSelector = (store: StoreState) =>
  store.EquityBreakManage.EquityBreakManagement.httpErrorMessage

export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.EquityBreakManage.EquityBreakManagement.successMessage

export default reducer
