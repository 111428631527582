import { useRowSelect, useTable } from 'react-table'
import {
  Wrapper,
} from './styles'
import { useMemo } from 'react'
import { TableFooter, TableHeader } from './TableUtils'
import TableCell from './TableCell'
import { ICusipL1SummaryTableProps } from './ITable'

const CusipL1SummaryTable = ({
  tableColumns,
  tableData,
  hasFooter = false,
}: ICusipL1SummaryTableProps) => {
  const data = useMemo(() => tableData, [tableData])
  const columns = useMemo(() => tableColumns, [tableColumns])
  const tableInstance = useTable({ columns, data }, useRowSelect)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const selectedColor = (e: any) => {
    for (const elem of (document as any).getElementsByClassName(
      'active-selected'
    )) {
      elem.classList.remove('active-selected')
    }
    for (const elem of (document as any).getElementsByClassName('table-row')) {
      elem.classList.remove('table-row')
    }
    e.currentTarget.classList.add('active-selected')
  }

 
  return (
    
    <Wrapper >
      <table {...getTableProps()}>
        <TableHeader headerGroups={headerGroups} />
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                key={index}
                onClick={(e) => {
                  selectedColor(e)
                } }
                className="table-row"
              >
                <TableCell row={row}/>
              </tr>
            )
          })}
        </tbody>
        {hasFooter ? <TableFooter footerGroups={footerGroups} /> : null}
      </table>
    </Wrapper>
  )
}

export default CusipL1SummaryTable
