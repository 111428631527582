/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '../../../../components/button/Button'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Label from '../../../../components/label/Label'
import SvgVerticalDivider from '../../../../components/svg/SvgVerticalDivider'
import {
  WDStyledButtonText,
  WDStyledHeading,
} from '../../../../components/ui/WDTypography'
import { COLORS } from '../../../../theme/Colors'
import { useNavigate } from 'react-router-dom'
import TableFilter from './tableFilter/TableFilter'
import {
  ButtonIconWrapper,
  ButtonWrapper,
  DateWrapper,
  FileUploadInput,
  FilterContainer,
  LeftSpace,
  MarginLeftAutoSpace,
  MarginTopSpace,
  MonthPickerLabelWrapper,
  RefreshDownloadWrapper,
  RootContainer,
  RowWrapper,
  UploadWrapper,
  StyledSelectWrapper,
  SvgButtonWrapper,
  ToastWrapper,
  LottieWrapper,
  PopUpStyleContentBBG,
  ThirdContainer,
  SecondContainer,
  FirstContainer,
  PopUpStyleLoader,
} from '../styles'

import {
  actionSelector,
  bbgUpload,
  dataSelector,
  getBetaStalePriceData,
  getBetaStalePriceDataCSV,
  LoadingSelector,
  ResponseSelector,
  setAction,
  SetBetaStalePriceData,
  SetBetaStalePriceDataHistory,
  setPageNumberBeta,
  setResponse,
  setSortDirection,
  setSorting,
  setStatusMessage,
  sortDirectionSelectorBeta,
  sortingSelectorBeta,
  statusSelector,
  TotalCountSelectorBeta,
} from '../../../../store/staticData/stale-pricing-beta'
import { useDispatch, useSelector } from 'react-redux'
import SvgRedo from '../../../../components/svg/SvgRedo'
import { SvgDownloadSmall } from '../../../../components/svg/logo/SvgDownload'
import WDCard from '../../../../components/ui/WDCard'
import { CardHeader } from '../../../../composites/cardHeader/CardHeader'
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'

import { Backgrounds } from '../../../../shared/styles'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import Divider from '../../../../components/divider/Divider'
import { WDLabel } from '../../../../components/ui/WDLabel'
import { TableFilterList } from './tableFilter/TableFilterData'

const EXTENSIONS = ['csv', 'txt']

import BetaStaleTable from './BetaTableColumns'

import { Toast } from '../../../../components/toast/Toast'
import Lottie from 'lottie-react'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { WDButton, WDButtonDisabled } from '../../../../components/ui/WDButton'
import SelectDropdown from '../../../../components/selectDropdown/SelectDropdown'
import { getAccessFlag } from '../../../../utils/globalFunctions'

const BetaStalePricingHistory = () => {
  const navigation = useNavigate()
  const sorting = useSelector(sortingSelectorBeta)
  const sortDirection = useSelector(sortDirectionSelectorBeta)
  const response = useSelector(ResponseSelector)
  const status = useSelector(statusSelector)
  const action = useSelector(actionSelector)
  const data = useSelector(dataSelector)
  const loading = useSelector(LoadingSelector)
  const TotalCount = useSelector(TotalCountSelectorBeta)
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [fileName, setFileName] = useState('')
  const [fileData, setFileData] = useState<any>()
  const [BBGPOPUP, setBBGPOPUP] = useState<boolean>(false)
  const [uploadType, setUploadType] = useState<string>('bbg')
  const [isCSV, setIsCSV] = useState(true)
  const fileUpload = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const defaultDate = new Date()
  defaultDate.setMonth(defaultDate.getMonth(), 0)
  const [startDate, setStartDate] = useState(defaultDate)
  const [toggle, setToggle] = useState<boolean>(false)
  const [resetState, setResetState] = useState<boolean>(false)
  const [resetTable, setResetTable] = useState<boolean>(false)

  const fileOptions = [
    { value: 'bbg', label: 'BBG Upload' },
    { value: 'ice', label: 'ICE Upload' },
    { value: 'dtcc_beta', label: 'DTCC Upload(Beta)' },
    { value: 'dtcc_p3', label: 'DTCC Upload(P3)' },
  ]
  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  const lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)

  let mm: string | number
  let firstDayOfMonth: string | number
  let lastDayOfMonth: string | number

  mm = startDate.getMonth() + 1
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  lastDayOfMonth = lastDay.getDate()
  lastDayOfMonth = '' + lastDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth
  const toDate = yyyy + '-' + mm + '-' + lastDayOfMonth

  const current = new Date()

  const monthNames = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]

  const monthYear = monthNames[startDate.getMonth()] + startDate.getFullYear()

  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)

  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['SD-RWX', 'OM-RWX']))
  }, [])

  const getExtension = (file: File) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    setIsCSV(EXTENSIONS.includes(extension))
    return EXTENSIONS.includes(extension)
  }

  const handleDrop = (uploadedFile: File) => {
    setFileName(uploadedFile.name)
    if (getExtension(uploadedFile)) {
      setIsFileUploaded(true)
      const reader = new FileReader()
      reader.readAsBinaryString(uploadedFile)
      setFileData(uploadedFile)
    } else {
      setIsFileUploaded(false)
    }
  }

  const preventDefaults = (event: BaseSyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDropFile = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer.files
    handleDrop(files[0])
  }

  const handleFiles = (event: BaseSyntheticEvent) => {
    handleDrop(event.target.files[0])
  }

  const browseFile = () => {
    fileUpload.current?.click()
  }
  const handleSearchBBG = async () => {
    const response = await dispatch(bbgUpload(fileData, uploadType))
    setBBGPOPUP(false)
    const uploadElement = document.getElementById(
      'fileElement'
    ) as HTMLInputElement
    uploadElement.value = ''
    setIsFileUploaded(false)

    setFileData('')
  }

  const handleSubmit = async () => {
    setResetTable(true)
    dispatch(setPageNumberBeta(0))
    TableFilterList.forEach((obj) => {
      obj.showDropdown = false
    })
    setIsTableVisible(false)

    const securityNbr =
      TableFilterList[0]?.dropDownArray[0].value &&
      TableFilterList[0].selectedData[0]
        ? TableFilterList[0].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[0]?.dropDownArray[0].value &&
          !TableFilterList[0].selectedData[0]
        ? 'Blank'
        : TableFilterList[0].selectedData.join(';').toString()

    let top_Category = ''
    if (
      TableFilterList[1].dropDownArray.filter((option) => option.value)
        .length == TableFilterList[1].dropDownArray.length
    ) {
      top_Category = ''
    } else {
      top_Category = TableFilterList[1].dropDownArray
        .filter((obj) => obj.value)
        .map((obj) => obj.label)
        .join(';')
        .toString()
    }

    let sub_Category = ''
    if (
      TableFilterList[2].dropDownArray.filter((option) => option.value)
        .length == TableFilterList[2].dropDownArray.length
    ) {
      sub_Category = ''
    } else {
      sub_Category = TableFilterList[2].dropDownArray
        .filter((obj) => obj.value)
        .map((obj) => obj.label)
        .join(';')
        .toString()
    }

    const reviewer_LatestComment =
      TableFilterList[3]?.dropDownArray[0].value &&
      TableFilterList[3].selectedData[0]
        ? TableFilterList[3].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[3]?.dropDownArray[0].value &&
          !TableFilterList[3].selectedData[0]
        ? 'Blank'
        : TableFilterList[3].selectedData.join(';').toString()

    const lastPrice_In_Current_StaleReport =
      TableFilterList[4]?.dropDownArray[0].value &&
      TableFilterList[4].selectedData[0]
        ? TableFilterList[4].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[4]?.dropDownArray[0].value &&
          !TableFilterList[4].selectedData[0]
        ? 'Blank'
        : TableFilterList[4].selectedData.join(';').toString()

    let followup_Flag: string
    if (
      TableFilterList[5].dropDownArray.filter((option) => option.value)
        .length == TableFilterList[5].dropDownArray.length
    ) {
      followup_Flag = ''
    } else {
      followup_Flag = TableFilterList[5].dropDownArray
        .filter((obj) => obj.value)
        .map((obj) => obj.labelValue)
        .join(';')
        .toString()
    }

    const securityDesc =
      TableFilterList[6]?.dropDownArray[0].value &&
      TableFilterList[6].selectedData[0]
        ? TableFilterList[6].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[6]?.dropDownArray[0].value &&
          !TableFilterList[6].selectedData[0]
        ? 'Blank'
        : TableFilterList[6].selectedData.join(';').toString()

    const cusip =
      TableFilterList[7]?.dropDownArray[0].value &&
      TableFilterList[7].selectedData[0]
        ? TableFilterList[7].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[7]?.dropDownArray[0].value &&
          !TableFilterList[7].selectedData[0]
        ? 'Blank'
        : TableFilterList[7].selectedData.join(';').toString()

    const isiN_Number =
      TableFilterList[8]?.dropDownArray[0].value &&
      TableFilterList[8].selectedData[0]
        ? TableFilterList[8].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[8]?.dropDownArray[0].value &&
          !TableFilterList[8].selectedData[0]
        ? 'Blank'
        : TableFilterList[8].selectedData.join(';').toString()

    const comments_Created_By =
      TableFilterList[9]?.dropDownArray[0].value &&
      TableFilterList[9].selectedData[0]
        ? TableFilterList[9].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[9]?.dropDownArray[0].value &&
          !TableFilterList[9].selectedData[0]
        ? 'Blank'
        : TableFilterList[9].selectedData.join(';').toString()

    const reviewer_Last_Commented_Date =
      TableFilterList[10]?.dropDownArray[0].value &&
      TableFilterList[10].selectedData[0]
        ? TableFilterList[10].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[10]?.dropDownArray[0].value &&
          !TableFilterList[10].selectedData[0]
        ? 'Blank'
        : TableFilterList[10].selectedData.join(';').toString()

    const icE_Last_Priced =
      TableFilterList[11]?.dropDownArray[0].value &&
      TableFilterList[11].selectedData[0]
        ? TableFilterList[11].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[11]?.dropDownArray[0].value &&
          !TableFilterList[11].selectedData[0]
        ? 'Blank'
        : TableFilterList[11].selectedData.join(';').toString()

    const icE_Last_Price =
      TableFilterList[12]?.dropDownArray[0].value &&
      TableFilterList[12].selectedData[0]
        ? TableFilterList[12].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[12]?.dropDownArray[0].value &&
          !TableFilterList[12].selectedData[0]
        ? 'Blank'
        : TableFilterList[12].selectedData.join(';').toString()

    const icE_Notes =
      TableFilterList[13]?.dropDownArray[0].value &&
      TableFilterList[13].selectedData[0]
        ? TableFilterList[13].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[13]?.dropDownArray[0].value &&
          !TableFilterList[13].selectedData[0]
        ? 'Blank'
        : TableFilterList[13].selectedData.join(';').toString()

    const bbG_Last_Priced =
      TableFilterList[14]?.dropDownArray[0].value &&
      TableFilterList[14].selectedData[0]
        ? TableFilterList[14].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[14]?.dropDownArray[0].value &&
          !TableFilterList[14].selectedData[0]
        ? 'Blank'
        : TableFilterList[14].selectedData.join(';').toString()

    const bbG_Last_Price =
      TableFilterList[15]?.dropDownArray[0].value &&
      TableFilterList[15].selectedData[0]
        ? TableFilterList[15].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[15]?.dropDownArray[0].value &&
          !TableFilterList[15].selectedData[0]
        ? 'Blank'
        : TableFilterList[15].selectedData.join(';').toString()

    const box_Location =
      TableFilterList[16]?.dropDownArray[0].value &&
      TableFilterList[16].selectedData[0]
        ? TableFilterList[16].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[16]?.dropDownArray[0].value &&
          !TableFilterList[16].selectedData[0]
        ? 'Blank'
        : TableFilterList[16].selectedData.join(';').toString()

    const box_Description =
      TableFilterList[17]?.dropDownArray[0].value &&
      TableFilterList[17].selectedData[0]
        ? TableFilterList[17].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[17]?.dropDownArray[0].value &&
          !TableFilterList[17].selectedData[0]
        ? 'Blank'
        : TableFilterList[17].selectedData.join(';').toString()

    const country =
      TableFilterList[18]?.dropDownArray[0].value &&
      TableFilterList[18].selectedData[0]
        ? TableFilterList[18].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[18]?.dropDownArray[0].value &&
          !TableFilterList[18].selectedData[0]
        ? 'Blank'
        : TableFilterList[18].selectedData.join(';').toString()

    const securityTypeCode =
      TableFilterList[19]?.dropDownArray[0].value &&
      TableFilterList[19].selectedData[0]
        ? TableFilterList[19].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[19]?.dropDownArray[0].value &&
          !TableFilterList[19].selectedData[0]
        ? 'Blank'
        : TableFilterList[19].selectedData.join(';').toString()

    const expirationDate =
      TableFilterList[20]?.dropDownArray[0].value &&
      TableFilterList[20].selectedData[0]
        ? TableFilterList[20].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[20]?.dropDownArray[0].value &&
          !TableFilterList[20].selectedData[0]
        ? 'Blank'
        : TableFilterList[20].selectedData.join(';').toString()

    const inDefault =
      TableFilterList[21]?.dropDownArray[0].value &&
      TableFilterList[21].selectedData[0]
        ? TableFilterList[21].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[21]?.dropDownArray[0].value &&
          !TableFilterList[21].selectedData[0]
        ? 'Blank'
        : TableFilterList[21].selectedData.join(';').toString()

    const corporateAction1 =
      TableFilterList[22]?.dropDownArray[0].value &&
      TableFilterList[22].selectedData[0]
        ? TableFilterList[22].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[22]?.dropDownArray[0].value &&
          !TableFilterList[22].selectedData[0]
        ? 'Blank'
        : TableFilterList[22].selectedData.join(';').toString()

    const corporateAction2 =
      TableFilterList[23]?.dropDownArray[0].value &&
      TableFilterList[23].selectedData[0]
        ? TableFilterList[23].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[23]?.dropDownArray[0].value &&
          !TableFilterList[23].selectedData[0]
        ? 'Blank'
        : TableFilterList[23].selectedData.join(';').toString()

    const surf_User_Pricing_Manual =
      TableFilterList[24]?.dropDownArray[0].value &&
      TableFilterList[24].selectedData[0]
        ? TableFilterList[24].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[24]?.dropDownArray[0].value &&
          !TableFilterList[24].selectedData[0]
        ? 'Blank'
        : TableFilterList[24].selectedData.join(';').toString()

    const surf_LastUpdated =
      TableFilterList[25]?.dropDownArray[0].value &&
      TableFilterList[25].selectedData[0]
        ? TableFilterList[25].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[25]?.dropDownArray[0].value &&
          !TableFilterList[25].selectedData[0]
        ? 'Blank'
        : TableFilterList[25].selectedData.join(';').toString()

    const surf_LastPrice =
      TableFilterList[26]?.dropDownArray[0].value &&
      TableFilterList[26].selectedData[0]
        ? TableFilterList[26].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[26]?.dropDownArray[0].value &&
          !TableFilterList[26].selectedData[0]
        ? 'Blank'
        : TableFilterList[26].selectedData.join(';').toString()

    const userIntial =
      TableFilterList[27]?.dropDownArray[0].value &&
      TableFilterList[27].selectedData[0]
        ? TableFilterList[27].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[27]?.dropDownArray[0].value &&
          !TableFilterList[27].selectedData[0]
        ? 'Blank'
        : TableFilterList[27].selectedData.join(';').toString()

    const changedDate =
      TableFilterList[28]?.dropDownArray[0].value &&
      TableFilterList[28].selectedData[0]
        ? TableFilterList[28].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[28]?.dropDownArray[0].value &&
          !TableFilterList[28].selectedData[0]
        ? 'Blank'
        : TableFilterList[28].selectedData.join(';').toString()

    const marketPrice =
      TableFilterList[29]?.dropDownArray[0].value &&
      TableFilterList[29].selectedData[0]
        ? TableFilterList[29].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[29]?.dropDownArray[0].value &&
          !TableFilterList[29].selectedData[0]
        ? 'Blank'
        : TableFilterList[29].selectedData.join(';').toString()

    const changeTerminal =
      TableFilterList[30]?.dropDownArray[0].value &&
      TableFilterList[30].selectedData[0]
        ? TableFilterList[30].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[30]?.dropDownArray[0].value &&
          !TableFilterList[30].selectedData[0]
        ? 'Blank'
        : TableFilterList[30].selectedData.join(';').toString()

    const dtC_Premed =
      TableFilterList[31]?.dropDownArray[0].value &&
      TableFilterList[31].selectedData[0]
        ? TableFilterList[31].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[31]?.dropDownArray[0].value &&
          !TableFilterList[31].selectedData[0]
        ? 'Blank'
        : TableFilterList[31].selectedData.join(';').toString()

    const lastUpdateDate =
      TableFilterList[32]?.dropDownArray[0].value &&
      TableFilterList[32].selectedData[0]
        ? TableFilterList[32].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[32]?.dropDownArray[0].value &&
          !TableFilterList[32].selectedData[0]
        ? 'Blank'
        : TableFilterList[32].selectedData.join(';').toString()

    const lastPrice =
      TableFilterList[33]?.dropDownArray[0].value &&
      TableFilterList[33].selectedData[0]
        ? TableFilterList[33].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[33]?.dropDownArray[0].value &&
          !TableFilterList[33].selectedData[0]
        ? 'Blank'
        : TableFilterList[33].selectedData.join(';').toString()

    const s_D =
      TableFilterList[34]?.dropDownArray[0].value &&
      TableFilterList[34].selectedData[0]
        ? TableFilterList[34].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[34]?.dropDownArray[0].value &&
          !TableFilterList[34].selectedData[0]
        ? 'Blank'
        : TableFilterList[34].selectedData.join(';').toString()

    const cash =
      TableFilterList[35]?.dropDownArray[0].value &&
      TableFilterList[35].selectedData[0]
        ? TableFilterList[35].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[35]?.dropDownArray[0].value &&
          !TableFilterList[35].selectedData[0]
        ? 'Blank'
        : TableFilterList[35].selectedData.join(';').toString()

    const margin =
      TableFilterList[36]?.dropDownArray[0].value &&
      TableFilterList[36].selectedData[0]
        ? TableFilterList[36].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[36]?.dropDownArray[0].value &&
          !TableFilterList[36].selectedData[0]
        ? 'Blank'
        : TableFilterList[36].selectedData.join(';').toString()

    const ira =
      TableFilterList[37]?.dropDownArray[0].value &&
      TableFilterList[37].selectedData[0]
        ? TableFilterList[37].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[37]?.dropDownArray[0].value &&
          !TableFilterList[37].selectedData[0]
        ? 'Blank'
        : TableFilterList[37].selectedData.join(';').toString()

    const upX_Z =
      TableFilterList[38]?.dropDownArray[0].value &&
      TableFilterList[38].selectedData[0]
        ? TableFilterList[38].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[38]?.dropDownArray[0].value &&
          !TableFilterList[38].selectedData[0]
        ? 'Blank'
        : TableFilterList[38].selectedData.join(';').toString()

    const currently_Still_in_StaleReport =
      TableFilterList[39]?.dropDownArray[0].value &&
      TableFilterList[39].selectedData[0]
        ? TableFilterList[39].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[39]?.dropDownArray[0].value &&
          !TableFilterList[39].selectedData[0]
        ? 'Blank'
        : TableFilterList[39].selectedData.join(';').toString()

    const last_Priced_In_Current_StaleReport =
      TableFilterList[40]?.dropDownArray[0].value &&
      TableFilterList[40].selectedData[0]
        ? TableFilterList[40].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[40]?.dropDownArray[0].value &&
          !TableFilterList[40].selectedData[0]
        ? 'Blank'
        : TableFilterList[40].selectedData.join(';').toString()

    const commentFilter =
      TableFilterList[41]?.dropDownArray[0].value &&
      TableFilterList[41].selectedData[0]
        ? TableFilterList[41].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[41]?.dropDownArray[0].value &&
          !TableFilterList[41].selectedData[0]
        ? 'Blank'
        : TableFilterList[41].selectedData.join(';').toString()

    const notes =
      TableFilterList[42]?.dropDownArray[0].value &&
      TableFilterList[42].selectedData[0]
        ? TableFilterList[42].selectedData.join(';').toString() + ';' + 'Blank'
        : TableFilterList[42]?.dropDownArray[0].value &&
          !TableFilterList[42].selectedData[0]
        ? 'Blank'
        : TableFilterList[42].selectedData.join(';').toString()

    const response = await dispatch(
      getBetaStalePriceData(
        'search',
        'Beta',
        fromDate,
        toDate,
        0,
        20,
        sorting,
        sortDirection,
        0,
        securityNbr,
        top_Category,
        sub_Category,
        reviewer_LatestComment,
        lastPrice_In_Current_StaleReport,
        followup_Flag,
        securityDesc,
        cusip,
        isiN_Number,
        comments_Created_By,
        reviewer_Last_Commented_Date,
        icE_Last_Priced,
        icE_Last_Price,
        icE_Notes,
        bbG_Last_Priced,
        bbG_Last_Price,
        box_Location,
        box_Description,
        country,
        securityTypeCode,
        expirationDate,
        inDefault,
        corporateAction1,
        corporateAction2,
        surf_User_Pricing_Manual,
        surf_LastUpdated,
        surf_LastPrice,
        userIntial,
        changedDate,
        marketPrice,
        changeTerminal,
        dtC_Premed,
        lastUpdateDate,
        lastPrice,
        s_D,
        cash,
        margin,
        ira,
        upX_Z,
        currently_Still_in_StaleReport,
        last_Priced_In_Current_StaleReport,
        commentFilter,
        notes
      )
    )
    if (response == 200) {
      setIsTableVisible(true)
    } else {
      setResetTable(false)
    }
  }

  const DownloadCSV = async () => {
    const response = await dispatch(
      getBetaStalePriceDataCSV(
        `Beta_stalePriceReport_${monthYear}`,
        'Beta',
        fromDate,
        toDate,
        0,
        0,
        'SecurityNbr',
        'ASC',
        0,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
    )
  }

  const resetData = () => {
    TableFilterList.forEach((obj) => {
      obj.selectedData = []
      obj.addData = ['']
      if (
        obj.Header !== 'Security Number' &&
        obj.Header !== 'Top Category' &&
        obj.Header !== 'Sub Category' &&
        obj.Header !== 'Reviewer Latest Comment' &&
        obj.Header !== 'Last price in Current Stale Report' &&
        obj.Header !== 'Flag'
      ) {
        obj.value = false
      }
      if (
        (obj.Header == 'Top Category' ||
          obj.Header == 'Sub Category' ||
          obj.Header == 'Flag') &&
        obj.dropDownArray.length > 0
      ) {
        obj.dropDownArray.forEach((dropDowns) => {
          dropDowns.value = true
        })
      } else {
        obj.dropDownArray.forEach((dropDowns) => {
          dropDowns.value = false
        })
      }
    })
    if (resetTable) {
      handleSubmit()
    }
    setToggle(!toggle)
  }

  const checkResetStatus = (data: any) => {
    if (data) {
      setResetState(true)
    } else {
      setResetState(false)
    }
  }

  useEffect(() => {
    setResetTable(false)
    dispatch(SetBetaStalePriceData([]))
    dispatch(SetBetaStalePriceDataHistory([]))
    dispatch(setAction(''))
    dispatch(setSortDirection('ASC'))
    dispatch(setSorting('SecurityNbr, ChangedDate'))
    resetData()
  }, [])

  return (
    <RootContainer>
      <WDStyledHeading>{'Stale Price Report'}</WDStyledHeading>
      <FilterContainer>
        <FirstContainer>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'20px'}
            hover={true}
            type={'button'}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              //
            }}
          >
            Beta
          </Button>
          <LeftSpace>
            <Button
              padding={'4px 16px'}
              borderRadius={'20px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                navigation('/P3-Stale-Pricing-Report')
              }}
            >
              P3
            </Button>
          </LeftSpace>
          <LeftSpace></LeftSpace>
          <SvgVerticalDivider
            fillColor={COLORS.Background.Neutral40}
          ></SvgVerticalDivider>
          <LeftSpace></LeftSpace>
          <MonthPickerLabelWrapper>
            <Label>Select Month</Label>
            <DateWrapper>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                startDate={startDate}
                maxDate={current}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </DateWrapper>
          </MonthPickerLabelWrapper>
          <LeftSpace>
            <MonthPickerLabelWrapper>
              <Label>Select Source</Label>
              <SelectDropdown
                defaultValue={{ value: 'bbgUpload', label: 'BBG Upload' }}
                borderRadius={'4px'}
                height="32px"
                options={fileOptions}
                onChange={(e) => {
                  setUploadType(e)
                }}
              />
            </MonthPickerLabelWrapper>
          </LeftSpace>
          <LeftSpace>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              bgColor={'transparent'}
              disabled={!writeExecuteFlag}
              onClick={() => {
                setBBGPOPUP(true)
              }}
            >
              File Upload
            </Button>
          </LeftSpace>
          <MarginLeftAutoSpace>
            <Button
              padding={'4px 16px'}
              borderRadius={'20px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                navigation('/beta-Stale-Pricing-history')
              }}
            >
              Beta History
            </Button>
          </MarginLeftAutoSpace>
        </FirstContainer>
        <SecondContainer>
          <TableFilter checkResetStatus={checkResetStatus}></TableFilter>
        </SecondContainer>
        <ThirdContainer>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              handleSubmit()
            }}
          >
            Apply
          </Button>
          <LeftSpace>
            {resetState ? (
              <WDButton>
                <Button
                  type="button"
                  color={''}
                  onClick={() => {
                    resetData()
                    checkResetStatus(false)
                  }}
                >
                  {'Reset'}
                </Button>
              </WDButton>
            ) : (
              <WDButtonDisabled>
                <Button type="button" color={''}>
                  {'Reset'}
                </Button>
              </WDButtonDisabled>
            )}
          </LeftSpace>
        </ThirdContainer>
      </FilterContainer>
      <RefreshDownloadWrapper>
        <ButtonIconWrapper
          onClick={async () => {
            if (isTableVisible) {
              await setResetTable(false)
              await handleSubmit()
              await setResetTable(true)
            }
          }}
        >
          <SvgRedo fillColor={`${COLORS.UI.Primary50}`}></SvgRedo>
          <WDStyledButtonText>
            <Label>Refresh</Label>
          </WDStyledButtonText>
        </ButtonIconWrapper>
        <ButtonIconWrapper
          onClick={() => {
            DownloadCSV()
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>

          <WDStyledButtonText>
            <Label>CSV Download</Label>
          </WDStyledButtonText>
        </ButtonIconWrapper>
      </RefreshDownloadWrapper>
      {resetTable && (action === 'search' || action === 'sort') && (
        <>
          <MarginTopSpace>
            <WDCard>
              <CardHeader
                label={'Beta'}
                icon={false}
                amountValue={`Total: ${TotalCount.data}`}
              />
            </WDCard>
          </MarginTopSpace>
          <BetaStaleTable
            handleSubmit={handleSubmit}
            fromDate={fromDate}
            toDate={toDate}
            data={data.data ? data.data : []}
            totalRecords={TotalCount.data}
          />
        </>
      )}

      {BBGPOPUP && (
        <PopUpStyleContentBBG>
          <StyledSelectWrapper
            style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
          >
            {uploadType === 'bbg'
              ? 'BBG Upload'
              : uploadType == 'ice'
              ? 'ICE Upload'
              : uploadType == 'dtcc_beta'
              ? 'DTCC Upload(Beta)'
              : 'DTCC Upload(P3)'}
            <MarginLeftAutoSpace>
              <SvgButtonWrapper
                onClick={() => {
                  const uploadElement = document.getElementById(
                    'fileElement'
                  ) as HTMLInputElement
                  uploadElement.value = ''
                  setIsFileUploaded(false)
                  setBBGPOPUP(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </SvgButtonWrapper>
            </MarginLeftAutoSpace>
          </StyledSelectWrapper>
          <Divider
            thickness={'1px'}
            horizontal={true}
            bgColor={Backgrounds.Gray110}
          />
          <UploadWrapper
            id="drop-area"
            onDragEnter={preventDefaults}
            onDragOver={preventDefaults}
            onDragLeave={preventDefaults}
            onDrop={handleDropFile}
            className={isFileUploaded ? 'file-uploaded' : ''}
          >
            <FileUploadInput
              type="file"
              id="fileElement"
              ref={fileUpload}
              multiple
              accept=".csv,.txt"
              hidden
              onChange={handleFiles}
            ></FileUploadInput>
            {isFileUploaded ? (
              <RowWrapper>
                <WDLabel>
                  <Label>{fileName}</Label>
                </WDLabel>
              </RowWrapper>
            ) : (
              <RowWrapper>
                <WDLabel>
                  <Label>{'Drag your file here to start uploading or'}</Label>
                </WDLabel>
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={browseFile}
                >
                  Browse files
                </Button>
              </RowWrapper>
            )}
          </UploadWrapper>
          {uploadType === 'bbg'
            ? 'Max file size is 10mb.Supported file types are .csv'
            : uploadType == 'ice'
            ? 'Max file size is 10mb.Supported file types are .csv'
            : uploadType == 'dtcc_beta'
            ? 'Max file size is 10mb.Supported file types are .txt'
            : 'Max file size is 10mb.Supported file types are .txt'}
          <MarginLeftAutoSpace>
            <ButtonWrapper>
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                style={{ whiteSpace: 'nowrap' }}
                bgColor={'transparent'}
                onClick={() => {
                  setBBGPOPUP(false)
                  const uploadElement = document.getElementById(
                    'fileElement'
                  ) as HTMLInputElement
                  uploadElement.value = ''
                  setIsFileUploaded(false)
                }}
              >
                Cancel
              </Button>
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  fileData && handleSearchBBG()
                }}
              >
                Upload
              </Button>
            </ButtonWrapper>
          </MarginLeftAutoSpace>
        </PopUpStyleContentBBG>
      )}
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {status.length > 0 && (
        <ToastWrapper>
          <ToastWrapper>
            <Toast
              text={status}
              type={response ? 'success' : 'danger'}
              openStatusCallback={() => {
                dispatch(setStatusMessage(''))
                dispatch(setResponse(false))
              }}
            />
          </ToastWrapper>
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default BetaStalePricingHistory
