/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall, authApiUpload } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  buyInAllocationData: []
  isLoading: boolean
  toastResponse: boolean | null
  toastMessage: string
  httpErrorMessage: string
  accountInfo: []
}

const initialState: State = {
  buyInAllocationData: [],
  isLoading: false,
  toastResponse: null,
  toastMessage: '',
  httpErrorMessage: '',
  accountInfo: [],
}

const { actions, reducer }: Slice = createSlice({
  name: 'reg-sho-buyInAllocation',
  initialState,
  reducers: {
    SetRegSHObuyInAllocation: (state: State, action: PayloadAction<any>) => {
      state.buyInAllocationData = action.payload.data
    },
    SetAccountInfo: (state: State, action: PayloadAction<any>) => {
      state.accountInfo = action.payload.data
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setToastResponse: (state: State, action: PayloadAction<boolean>) => {
      state.toastResponse = action.payload
    },
    setToastMessage: (state: State, action: PayloadAction<string>) => {
      state.toastMessage = action.payload
    },
    sethttpErrorMessageAllocation: (
      state: State,
      action: PayloadAction<string>
    ) => {
      state.httpErrorMessage = action.payload
    },
  },
})

export const {
  SetRegSHObuyInAllocation,
  setIsLoading,
  setToastResponse,
  setToastMessage,
  sethttpErrorMessageAllocation,
  SetAccountInfo,
} = actions

export const getRegSHOBuyInAllocation: any =
  (payLoad: {}) => async (dispatch: Dispatch) => {
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetRegShoAllocationExecution',
        payLoad
      )
      if (status === 200) {
        dispatch(
          SetRegSHObuyInAllocation({
            data: data,
          })
        )
        return data
      }
    } catch (e: any) {
      dispatch(
        SetRegSHObuyInAllocation({
          data: [],
        })
      )
      return null
    }
  }
export const addRegSHOBuyInAllocation: any =
  (payload: {}) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/AddRegShoBuyInDetail',
        payload
      )
      if (status === 200) {
        dispatch(setToastMessage('Added successfully'))
        dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return true
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return false
    }
  }
export const updateRegSHOBuyInAllocation: any =
  (payload: {}) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/UpdateRegShoBuyInDetail',
        payload
      )
      if (status === 200) {
        dispatch(setToastMessage('Updated Successfully'))
        dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return true
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return false
    }
  }

export const deleteRegSHOBuyInAllocation: any =
  (regSHOBuyIn_ID: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiUpload.post(
        'opsfailprocessapi/FailAnalysisReport/DeleteRegShoBuyInDetail',
        { regSHOBuyIn_ID }
      )
      if (status === 200) {
        dispatch(setToastMessage('Deleted Successfully'))
        dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return true
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return false
    }
  }

export const getL2RegSHOData: any =
  (payload: {}) => async (dispatch: Dispatch) => {
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetRegSho_L2_Allocations',
        payload
      )
      if (status === 200) {
        return data
      }
    } catch (e: any) {
      return null
    }
  }

export const updateL2RegSHOData: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/AddOrUpdateRegShoL2BuyInDetails`,
        payload
      )
      if (status === 200) {
        payload.regSHOBuyInId !== null &&
          dispatch(setToastMessage('Updated successfully'))
        payload.regSHOBuyInId !== null && dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return true
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return false
    }
  }
export const deleteL2RegSHOData: any =
  (regSHOBuyIn_ID: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiUpload.post(
        'opsfailprocessapi/FailAnalysisReport/DeleteRegShoL2BuyInDetail',
        { regSHOBuyIn_ID }
      )
      if (status === 200) {
        dispatch(setToastMessage('Deleted Successfully'))
        dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return true
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return false
    }
  }

export const auditTrailL2RegSHOData: any =
  (allocation_ID: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoBuyInAllocationAuditTrail?allocationId=${allocation_ID}`
      )
      if (status === 200) {
        dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return []
    }
  }

  export const auditTrailL3RegSHOData: any =
  (regSHOBuyInExecId: string,) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoBuyInExecAuditTrail?regSHOBuyInExecId=${regSHOBuyInExecId}`
      )
      if (status === 200) {
        dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return []
    }
  }
export const emailUploadL2RegSHOData: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiUpload.post(
        `opsfailprocessapi/FailAnalysisReport/UploadEmailAttachment`,
        {
          AllocationId: payload.allocation_ID,
          RegShoId: payload.regSHO_Id,
          FileData: payload.fileData,
          FileName: payload.fileName,
          ScreenName: payload.dataLevel,
        }
      )
      if (status === 200) {
        dispatch(setToastMessage('Uploaded successfully'))
        dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return true
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return false
    }
  }
export const getL2RegSHOExcelL2: any =
  (fileName: string, payload: {}) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/RegShoL2AllocationsExcelExport',
        payload,
        { responseType: 'blob' }
      )
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return null
    }
  }

export const getL2RegSHOExcelL3: any =
  (fileName: string, payload: {}) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/RegShoL3AllocationsExcelExport',
        payload,
        { responseType: 'blob' }
      )
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return null
    }
  }

export const getEmailDownload: any =
  (payload: { id: number; fileName: string }) => async (dispatch: Dispatch) => {
    try {
      const { data, status } = await authApiCall.get(
        `opsfailprocessapi/FailAnalysisReport/DownloadEmailAttachment?regShoBuyInId=${payload.id}`,
        { responseType: 'blob' }
      )
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${payload.fileName}`)
        document.body.appendChild(link)
        link.click()
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      return null
    }
  }

export const getEmailDelete: any =
  (payload: { id: number; fileName: string }) => async (dispatch: Dispatch) => {
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/DeleteRegShoEmailAttachment`,
        [payload.id]
      )
      if (status === 200) {
        dispatch(setToastMessage('Deleted successfully'))
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      return null
    }
  }

export const getL2AccountInfo: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegSho_L2_AccountInfo`,
        {
          regSHO_Id: payload.regSHOId,
          allocationType: payload.allocationType.split('Buyin')[0],
          currHist: 'C',
          pageNumber: 0,
          pageSize: 0,
          sortBy: 'ReportDate, CUSIP_Number',
          sortDirection: 'ASC',
          source: payload.source,
        }
      )
      if (status === 200) {
        dispatch(SetAccountInfo(data))
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(SetAccountInfo([]))
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return []
    }
  }

export const getL2AllEmailList: any =
  (regShoId: any, allocationId: any, screenName: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetEmailAttachmentList`,
        {
          regShoId,
          allocationId,
          screenName,
          CurrHist: 'C',
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return []
    }
  }

export const addBuyInTab: any = (payload: {}) => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.post(
      'opsfailprocessapi/FailAnalysisReport/AddOrUpdateRegShoL2BuyInDetails',
      payload
    )
    if (status === 200) {
      dispatch(setToastMessage('Added successfully'))
      dispatch(setToastResponse(true))
      dispatch(setIsLoading(false))
      return true
    }
  } catch (e: any) {
    handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
    dispatch(setIsLoading(false))
    return false
  }
}

export const updateBuyInTab: any =
  (payload: {}) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/AddOrUpdateRegShoL2BuyInDetails',
        payload
      )
      if (status === 200) {
        dispatch(setToastMessage('Updated Successfully'))
        dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return true
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return false
    }
  }

export const getRegCommentsBuyIn: any =
  (payload: {}) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoBuyIns`,
        payload
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageAllocation(''))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      //
    }
  }
  export const getAuditTrailBuyInNotify: any =
  (regSHOBuyIn_ID: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post( 
        `opsfailprocessapi/FailAnalysisReport/GetRegShoBuyInNotifyAuditTrail?regShoL1buyInsId=${regSHOBuyIn_ID}`
      )
      if (status === 200) {
        dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageAllocation)
      dispatch(setIsLoading(false))
      return []
    }
  }
export const DataSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOBuyInAllocation.buyInAllocationData
export const LoadingSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOBuyInAllocation.isLoading
export const ToastResponseSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOBuyInAllocation.toastResponse
export const ToastMessageSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOBuyInAllocation.toastMessage
export const ErrorMessageAllocationSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOBuyInAllocation.httpErrorMessage
export const AccountInfoSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOBuyInAllocation.accountInfo

export default reducer
