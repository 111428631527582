/* eslint-disable @typescript-eslint/no-explicit-any */
import { SvgEye } from '../../../svg/SvgEye'
import SvgWrench from '../../../svg/SvgWrench'
import { AlertContainer, AlertDataContainer, AlertTile } from './styles'

const AlertsDetails = (props: any) => {
  return (
    <AlertContainer>
      {props.cardTitle && props.cardTitle.length ? (
        props.cardTitle.map((m: any, index: any) =>
          m.importance === 'Alert' ? (
            <AlertTile
              key={index}
              background={'#FEF1EE'}
              border={'1px solid #FEB8AB'}
              fontColor={'#BA0517'}
              width={'100%'}
              onClick={() => props.addBreadCrumb(m.paramInfo)}
            >
              <AlertDataContainer>
                <span
                  onClick={props.addBreadCrumb}
                  onKeyDown={props.addBreadCrumb}
                  style={{ textDecoration: 'none', color: '#BA0517' }}
                  role="button"
                  tabIndex={0}
                >
                  {m.value}
                  <SvgEye fillColor={'#BA0517'}></SvgEye>
                </span>
              </AlertDataContainer>
              <p
                style={{
                  color: '#BA0517',
                  fontSize: '14px',
                  textDecoration: 'underline',
                  textAlign: 'center',
                }}
              >
                {m.message}
              </p>
            </AlertTile>
          ) : m.importance === 'warning' ? (
            <AlertTile
              background={'#FFF7ED'}
              border={'1px solid #FED7AA'}
              fontColor={'#C2410C'}
              width={'100%'}
              onClick={() => props.viewIdentified(m.paramInfo)}
            >
              <AlertDataContainer>
                <span
                  style={{ textDecoration: 'none', color: '#BA0517' }}
                  role="button"
                  tabIndex={0}
                >
                  {m.value}
                  <SvgEye fillColor={'#EA001E'}></SvgEye>
                </span>
              </AlertDataContainer>
              <p
                style={{
                  color: '#C2410C',
                  fontSize: '14px',
                  textDecoration: 'underline',
                  textAlign: 'center',
                }}
              >
                {m.message}
              </p>
            </AlertTile>
          ) : m.importance === 'information' ? (
            <AlertTile
              background={'#EBF7E6'}
              border={'1px solid #91DB8B'}
              fontColor={'#2E844A'}
              width={'100%'}
            >
              <AlertDataContainer>
                <span style={{ textDecoration: 'none', color: '#2E844A' }}>
                  {m.value}
                  <SvgWrench fillColor={'#3BA755'}></SvgWrench>
                </span>
              </AlertDataContainer>
              <p
                style={{
                  color: '#2E844A',
                  fontSize: '14px',
                  textDecoration: 'none',
                  textAlign: 'center',
                }}
              >
                {m.message}
              </p>
            </AlertTile>
          ) : null
        )
      ) : (
        <>
          <span
            style={{
              display: 'block',
              textAlign: 'left',
              fontWeight: 'normal',
              fontSize: '15px',
              padding: '10px',
            }}
          >
            <a href="/tradeBreaksByEC" onClick={props.addBreadCrumb}>
              Click here to view trade by Entity code
            </a>
          </span>
        </>
      )}
    </AlertContainer>
  )
}

export default AlertsDetails
