import Button from '../../../../components/button/Button'
import Label from '../../../../components/label/Label'
import Image from '../../../../components/image/Image'
import { COLORS } from '../../../../theme/Colors'
import { ADRTableFilterList } from './ADRTableFilterData'
import { CancelProps } from './IADRTableFilter'
import {
  AddButtonStyleWrapper,
  CloseButtonStyleWrapper,
  ColumnCheckboxWrapper,
  DefaultFilterWrapper,
  DropDownWrapper,
  LabelWrapper,
  MainFilterContainer,
  SaveCancelButtonWrapper,
  SavedDataWrapper,
  TextAreaCancelIconWrapper,
  TextAreaStyleWrapper,
} from './styles'
import { Icons } from '../../../../shared/GlobalStyle'
import {
  BaseSyntheticEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { WDTextbox } from '../../../../components/ui/WDTextbox'
import { Textbox } from '../../../../components/textbox/Textbox'
import { WDButtonTransparent } from '../../../../components/ui/WDButton'
import SvgClose from '../../../../components/svg/SvgClose'
import { SvgPlus } from '../../../../components/svg/SvgPlus'

const ADRTableFilter = ({ reference, checkResetStatus }: CancelProps) => {
  const [toggleWindow, setToggleWindow] = useState(false)
  const [toggle, setToggle] = useState<boolean>(false)

  const wrapperRef = useRef(null)

  const toggleDropdown = (tableindex: number) => {
    ADRTableFilterList[tableindex].showDropdown =
      !ADRTableFilterList[tableindex].showDropdown
    ADRTableFilterList.forEach((filter, index) => {
      if (tableindex != index) {
        filter.showDropdown = false
      }
    })
    setToggle(!toggle)
  }

  const closeDropDownToggle = () => {
    setToggleWindow(true)
  }

  const setUpdateData = (value: string, index: number, tableIndex: number) => {
    ADRTableFilterList[tableIndex].addData[index] = value
    setToggle(!toggle)
  }

  const checkIfAnyValueSelectedInDropdownFilters = () => {
    let selectionStatus = false
    ADRTableFilterList.forEach((filter, index) => {
      if (filter.dropDownArray.length) {
        filter.dropDownArray.forEach((dropdown, index) => {
          if (dropdown.value) {
            selectionStatus = true
          }
        })
      }
      if (filter.addData.length > 0 && filter.addData[0] !== '') {
        selectionStatus = true
      }
    })
    checkResetStatus(selectionStatus)
  }

  const delTextArea = (index: number, tableIndex: number) => {
    ADRTableFilterList[tableIndex].addData.splice(index, 1)
    setToggle(!toggle)
  }

  const addTextArea = (index: number) => {
    ADRTableFilterList[index].addData.push('')
    setToggle(!toggle)
  }

  const saveData = (tableIndex: number, source: string) => {
    if (source !== 'fromCancel')
      ADRTableFilterList[tableIndex].selectedData =
        ADRTableFilterList[tableIndex].addData
    else {
      ADRTableFilterList[tableIndex].addData = ['']
      ADRTableFilterList[tableIndex].selectedData = ['']
      checkIfAnyValueSelectedInDropdownFilters()
    }
    ADRTableFilterList[tableIndex].showDropdown = false
    setToggle(!toggle)
  }

  const verifyChecked = (index: number) => {
    let flag = true
    if (
      ADRTableFilterList[index].dropDownArray.filter((option) => option.value)
        .length == ADRTableFilterList[index].dropDownArray.length
    ) {
      flag = true
    } else flag = false
    return flag
  }

  const selectAll = (index: number, event: BaseSyntheticEvent) => {
    const checked = (event.target as HTMLInputElement).checked
    if (checked) {
      ADRTableFilterList[index].dropDownArray.forEach((option) => {
        option.value = true
      })
    } else {
      ADRTableFilterList[index].dropDownArray.forEach((option) => {
        option.value = false
      })
      checkResetStatus(true)
    }
    setToggle(!toggle)
  }

  const handleOptions = (
    tableIndex: number,
    optionIndex: number,
    event: BaseSyntheticEvent
  ) => {
    const checked = (event.target as HTMLInputElement).checked
    if (checked) {
      ADRTableFilterList[tableIndex].dropDownArray[optionIndex].value = true
      checkResetStatus(true)
    } else {
      ADRTableFilterList[tableIndex].dropDownArray[optionIndex].value = false
      checkIfAnyValueSelectedInDropdownFilters()
    }
    setToggle(!toggle)
  }

  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          if (!reference?.current?.contains(event.target as Node)) {
            setToggleWindow(false)
            ADRTableFilterList.forEach((filter, index) => {
              filter.showDropdown = false
            })
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  useOutsideAlerter(wrapperRef)

  return (
    <MainFilterContainer ref={wrapperRef}>
      <DefaultFilterWrapper>
        {ADRTableFilterList.map((column, index) => {
          return (
            column.value && (
              <DefaultFilterWrapper key={index}>
                <>
                  <Button
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    type={'button'}
                    color={COLORS.Text.Neutral}
                    borderColor={COLORS.Background.Border}
                    bgColor={'transparent'}
                    onClick={() => {
                      toggleDropdown(index)
                      closeDropDownToggle()
                    }}
                  >
                    <Label>{column.Header}</Label>
                    <Image
                      image={
                        column.showDropdown && toggleWindow
                          ? Icons.ChevronUp
                          : Icons.ChevronDown
                      }
                      alt="Chevron Down"
                    />
                  </Button>
                  {column.showDropdown && toggleWindow && (
                    <DropDownWrapper>
                      {column.dropDownArray.length == 0 && (
                        <>
                          {column.addData.map((option, i) => {
                            return (
                              <TextAreaCancelIconWrapper key={i}>
                                {
                                // column.Header == 'Record Date' ? (
                                //   <TextAreaStyleWrapper>
                                //     <WDTextbox>
                                //       <Textbox
                                //         value={option}
                                //         placeholder="MM/DD/YYYY"
                                //         onChange={(e) => {
                                //           setUpdateData(e, i, index)
                                //           checkIfAnyValueSelectedInDropdownFilters()
                                //         }}
                                //         style={{
                                //           borderRadius: '4px',
                                //           height: '32px',
                                //           padding: '4px 8px',
                                //         }}
                                //       />
                                //     </WDTextbox>
                                //   </TextAreaStyleWrapper>
                                // ) : 
                                (
                                  <TextAreaStyleWrapper>
                                    <WDTextbox>
                                      <Textbox
                                        value={option}
                                        onChange={(e) => {
                                          setUpdateData(e, i, index)
                                          checkIfAnyValueSelectedInDropdownFilters()
                                        }}
                                        style={{
                                          borderRadius: '4px',
                                          height: '32px',
                                          padding: '4px 8px',
                                        }}
                                      />
                                    </WDTextbox>
                                  </TextAreaStyleWrapper>
                                )}
                                {column.addData.length > 1 && (
                                  <CloseButtonStyleWrapper>
                                    <WDButtonTransparent>
                                      <Button
                                        type={'button'}
                                        color={''}
                                        onClick={() => {
                                          delTextArea(i, index)
                                        }}
                                        subSuffixIcon={
                                          <SvgClose fillColor=""></SvgClose>
                                        }
                                      ></Button>
                                    </WDButtonTransparent>
                                  </CloseButtonStyleWrapper>
                                )}
                              </TextAreaCancelIconWrapper>
                            )
                          })}
                          <AddButtonStyleWrapper>
                            <WDButtonTransparent>
                              <Button
                                type={'button'}
                                color={COLORS.Background.Primarytext}
                                onClick={() => {
                                  addTextArea(index)
                                }}
                                subPrefixedIcon={
                                  <SvgPlus
                                    fillColor={COLORS.Background.Primarytext}
                                  ></SvgPlus>
                                }
                              >
                                Add
                              </Button>
                            </WDButtonTransparent>
                          </AddButtonStyleWrapper>
                          <SavedDataWrapper>
                            {column.selectedData.map((option, i) => {
                              return <Label key={i}>{option}</Label>
                            })}
                          </SavedDataWrapper>
                          <SaveCancelButtonWrapper>
                            <Button
                              hoverBgColor={COLORS.Background.Primary70}
                              padding={'4px 16px'}
                              borderRadius={'4px'}
                              type={'button'}
                              color={COLORS.Background.Primarytext}
                              bgColor={'transparent'}
                              onClick={() => {
                                saveData(index, 'fromCancel')
                                checkIfAnyValueSelectedInDropdownFilters()
                              }}
                            >
                              Clear All
                            </Button>
                            <Button
                              hoverBgColor={COLORS.Background.Primary70}
                              bgColor={COLORS.Background.Primarytext}
                              color={COLORS.Background.Primary}
                              padding={'4px 16px'}
                              borderRadius={'4px'}
                              hover={true}
                              type={''}
                              activeBgColor={COLORS.Background.Primaryactive}
                              borderColor={'transparent'}
                              onClick={() => {
                                saveData(index, 'fromSave')
                              }}
                            >
                              Save
                            </Button>
                          </SaveCancelButtonWrapper>
                        </>
                      )}
                      {(column.Header == 'Depository') && (
                        <ColumnCheckboxWrapper>
                          <WDButtonTransparent>
                            <input
                              type="checkbox"
                              checked={verifyChecked(index)}
                              onChange={(event) => {
                                selectAll(index, event)
                              }}
                            ></input>
                            <Button type="button" color={''}>
                              <LabelWrapper>Select All</LabelWrapper>
                            </Button>
                          </WDButtonTransparent>
                        </ColumnCheckboxWrapper>
                      )}

                      {column.dropDownArray &&
                        column.dropDownArray.length > 0 &&
                        column.dropDownArray.map((option, i) => {
                          return (
                            <ColumnCheckboxWrapper key={i}>
                              <WDButtonTransparent>
                                <input
                                  type="checkbox"
                                  checked={option.value}
                                  onChange={(event) => {
                                    handleOptions(index, i, event)
                                  }}
                                ></input>
                                <Button type="button" color={''}>
                                  <LabelWrapper>{option.label}</LabelWrapper>
                                </Button>
                              </WDButtonTransparent>
                            </ColumnCheckboxWrapper>
                          )
                        })}
                    </DropDownWrapper>
                  )}
                </>
              </DefaultFilterWrapper>
            )
          )
        })}
      </DefaultFilterWrapper>
    </MainFilterContainer>
  )
}

export default ADRTableFilter
