/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addList,
  currentDataSelector,
  deactivateMapping,
  getDataList,
  updateList,
  LoadingSelector,
  setIsLoading,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  setSuccessMessage,
  setHttpErrorMessage,
} from '../../../store/staticData/country-code'
import {
  LabelTextboxWrapeer,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  PopUpStyleLoader,
  RootContainer,
  StyledSelectWrapper,
  StyledTableContainer,
  SvgButtonWrapper,
  ToastWrapper,
} from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import { StyledImage } from '../../../components/image/styles'
import { Backgrounds } from '../../../shared/styles'
import { Textbox } from '../../../components/textbox/Textbox'
import { Icons } from '../../../shared/GlobalStyle'
import { Toast } from '../../../components/toast/Toast'
import Button from '../../../components/button/Button'
import Table from '../../../components/table/Table'
import WDCard from '../../../components/ui/WDCard'
import Loader from '../../../assets/lottiefiles/loader.json'
import Label from '../../../components/label/Label'
import Divider from '../../../components/divider/Divider'
import Lottie from 'lottie-react'
import { COLORS } from '../../../theme/Colors'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import { SvgExclamation } from '../../../components/svg/logo/SvgExclamation'
import { getAccessFlag } from '../../../utils/globalFunctions'

const CountryCode = () => {
  const dispatch = useDispatch()
  const data = useSelector(currentDataSelector)
  const loading = useSelector(LoadingSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const [toggleEdit, SetToggleEdit] = useState<boolean>(false)
  const [toggleInsert, SetToggleInsert] = useState<boolean>(false)
  const [toggleDeactivate, SetToggleDeactivate] = useState<boolean>(false)
  const [res, setRes] = useState<number>(0)
  const [mapping, setMapping] = useState({
    id: 0,
    countryName: '',
    IRSCountryCode: '',
    ISOCountryCode: '',
    isActive: true,
  })

  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)

  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['SD-RWX', 'OM-RWX']))
  }, [])

  const addMapping = () => {
    setMapping({
      id: 0,
      countryName: '',
      IRSCountryCode: '',
      ISOCountryCode: '',
      isActive: true,
    })
    SetToggleInsert(true)
  }

  useEffect(() => {
    async function valdiData() {
      dispatch(setIsLoading(true))
      const response = await dispatch(getDataList())
      setRes(response)
    }
    valdiData()
  }, [])

  const addNew = async (
    countryName: string,
    IRSCountryCode: string,
    ISOCountryCode: string
  ) => {
    SetToggleInsert(false)
    await dispatch(
      addList(0, countryName, IRSCountryCode, ISOCountryCode, true)
    )
    await dispatch(getDataList())
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    })
  }
  const update = async (
    id: number,
    countryName: string,
    IRSCountryCode: string,
    ISOCountryCode: string
  ) => {
    SetToggleEdit(false)
    await dispatch(
      updateList(id, countryName, IRSCountryCode, ISOCountryCode, true)
    )
    await dispatch(getDataList())
  }
  const deactive = async (
    id: number,
    countryName: string,
    IRSCountryCode: string,
    ISOCountryCode: string
  ) => {
    SetToggleDeactivate(false)
    await dispatch(
      deactivateMapping(id, countryName, IRSCountryCode, ISOCountryCode, false)
    )
    await dispatch(getDataList())
  }
  return (
    <RootContainer>
      <WDCard>
        <CardHeader label={'FIS IRS ISO Country Codes'} />
      </WDCard>
      <StyledSelectWrapper style={{ justifyContent: 'flex-end' }}>
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          gap={'8px'}
          type={''}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          disabled={!writeExecuteFlag}
          onClick={() => {
            addMapping()
          }}
        >
          Add New
        </Button>
      </StyledSelectWrapper>
      <StyledTableContainer>
        <Table
          tableColumns={[
            {
              Header: 'Country Name',
              accessor: 'countryName',
            },

            {
              Header: 'IRSCountryCode',
              accessor: 'irsCountryCode',
            },
            {
              Header: 'ISOCountryCode',
              accessor: 'isoCountryCode',
            },
            {
              Header: 'Deactivate',
              Cell: (e: any) => {
                return writeExecuteFlag ? (
                  <input
                    style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                    title="check to deactivate"
                    type="checkbox"
                    defaultChecked={data[e.cell.row.id].isActive === false}
                    disabled={data[e.cell.row.id].isActive === false}
                    onClick={() => {
                      setMapping({
                        id: data[e.cell.row.id].id,
                        countryName: data[e.cell.row.id].countryName,
                        IRSCountryCode: data[e.cell.row.id].irsCountryCode,
                        ISOCountryCode: data[e.cell.row.id].isoCountryCode,
                        isActive: false,
                      })
                      SetToggleDeactivate(true)
                    }}
                  ></input>
                ) : (
                  <>-</>
                )
              },
            },
            {
              Header: 'Edit',
              Cell: (e: any) => {
                return writeExecuteFlag ? (
                  data[e.cell.row.id].isActive === true ? (
                    <StyledImage
                      title="Edit"
                      onClick={() => {
                        setMapping({
                          id: data[e.cell.row.id].id,
                          countryName: data[e.cell.row.id].countryName,
                          IRSCountryCode: data[e.cell.row.id].irsCountryCode,
                          ISOCountryCode: data[e.cell.row.id].isoCountryCode,
                          isActive: data[e.cell.row.id].isActive,
                        })
                        SetToggleEdit(true)
                      }}
                      width={'25px'}
                      style={{ cursor: 'pointer' }}
                      src={Icons.Edit}
                      alt="Edit"
                    />
                  ) : (
                    <StyledImage
                      title="Edit"
                      width={'25px'}
                      style={{ cursor: 'pointer', opacity: '0.3' }}
                      src={Icons.Edit}
                      alt="Edit"
                    />
                  )
                ) : (
                  <>-</>
                )
              },
            },
          ]}
          tableData={data}
          onClick={() => {
            //
          }}
        />
        {res == 200 && data.length == 0 && (
          <>
            <Label fontWeight={700} margin="5% 0 0 50%">
              No Records Found
            </Label>
            <Label fontWeight={700} margin="0.5% 0 0 49%">
              No Exceptions Identified
            </Label>
          </>
        )}
        {loading && (
          <PopUpStyleLoader>
            <StyledSelectWrapper style={{ justifyContent: 'center' }}>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LottieWrapper style={{ top: '40.5%' }}>
                {' '}
                Loading...
              </LottieWrapper>
            </StyledSelectWrapper>
          </PopUpStyleLoader>
        )}
        {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
          <ToastWrapper>
            <Toast
              text={SuccessMessage || ErrorMessage}
              type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
              openStatusCallback={() => {
                dispatch(setHttpErrorMessage(''))
                dispatch(setSuccessMessage(''))
              }}
            />
          </ToastWrapper>
        )}
        {/*Insert*/}
        {toggleInsert && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              Add CountryCode
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleInsert(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <LabelTextboxWrapeer>
                <Label color={'black'}>Country Name</Label>
                <Textbox
                  placeholder="Country Name"
                  onChange={(e) => {
                    setMapping({ ...mapping, countryName: e })
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}> IRSCountry Code </Label>
                <Textbox
                  placeholder="IRSCountry Code "
                  onChange={(e) => {
                    setMapping({ ...mapping, IRSCountryCode: e })
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}>ISOCountry Code</Label>
                <Textbox
                  placeholder="ISOCountry Code"
                  onChange={(e) => {
                    setMapping({ ...mapping, ISOCountryCode: e })
                  }}
                />
              </LabelTextboxWrapeer>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleInsert(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>{' '}
              {mapping.countryName &&
              mapping.IRSCountryCode &&
              mapping.ISOCountryCode ? (
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={() => {
                    addNew(
                      mapping.countryName,
                      mapping.IRSCountryCode,
                      mapping.ISOCountryCode
                    )
                  }}
                >
                  Add
                </Button>
              ) : (
                <Button
                  bgColor={COLORS.Text.NeutralTextDisabled}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={''}
                  borderColor={'transparent'}
                >
                  Add
                </Button>
              )}
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
        {/*Edit*/}
        {toggleEdit && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              <>Update CountryCode</>
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleEdit(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <LabelTextboxWrapeer>
                <Label color={'black'}>Country Name</Label>
                <Textbox
                  placeholder="Country Name"
                  defaultValue={mapping.countryName}
                  onChange={(e) => {
                    setMapping({ ...mapping, countryName: e })
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}> IRSCountry Code </Label>
                <Textbox
                  placeholder="IRSCountry Code"
                  defaultValue={mapping.IRSCountryCode}
                  onChange={(e) => {
                    setMapping({ ...mapping, IRSCountryCode: e })
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}>ISOCountry Code</Label>
                <Textbox
                  placeholder="ISOCountry Coder"
                  defaultValue={mapping.ISOCountryCode}
                  onChange={(e) => {
                    setMapping({ ...mapping, ISOCountryCode: e })
                  }}
                />
              </LabelTextboxWrapeer>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleEdit(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>{' '}
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  update(
                    mapping.id,
                    mapping.countryName,
                    mapping.IRSCountryCode,
                    mapping.ISOCountryCode
                  )
                }}
              >
                Update
              </Button>
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
        {/* Deactivate */}
        {toggleDeactivate && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              <SvgButtonWrapper
                onClick={() => {
                  SetToggleEdit(false)
                }}
              >
                <SvgExclamation
                  fillColor={COLORS.Icons.DangerIcon}
                ></SvgExclamation>
              </SvgButtonWrapper>
              Deactivate CountryCode
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleDeactivate(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <>
                {`Are you sure want to deactivate? Once Deactivated can't Activate
                again.`}
              </>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleDeactivate(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  deactive(
                    mapping.id,
                    mapping.countryName,
                    mapping.IRSCountryCode,
                    mapping.ISOCountryCode
                  )
                }}
              >
                Deactivate
              </Button>
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
      </StyledTableContainer>
    </RootContainer>
  )
}

export default CountryCode
