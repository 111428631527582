/* eslint-disable @typescript-eslint/no-explicit-any */
import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/button/Button'
import Divider from '../../../../components/divider/Divider'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import Table from '../../../../components/table/Table'
import { Textarea } from '../../../../components/textArea/Textarea'
import { Backgrounds, Colors } from '../../../../shared/styles'
import {
  AddComment,
  dataSelector,
  getBetaStalePriceData,
  getComments,
  LoadingSelector,
  PageNumSelectorBeta,
  sortDirectionSelectorBeta,
  sortingSelectorBeta,
  updateFlag,
  UpdateNote,
} from '../../../../store/staticData/stale-pricing-beta'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { COLORS } from '../../../../theme/Colors'

import {
  ButtonWrapper,
  CancelButtonWrapper,
  CommentTableContainer,
  FlagPopUpWrapper,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopupCardComment,
  PopupCardNote,
  PopUpStyleContent,
  PopUpStyleLoader,
  RadioButtonWrapper,
  StyledSelectWrapper,
  StyledTableContainer,
  FlagPopUpTitle,
} from '../styles'

import BetaStalePricingTable from './betaStalePricingTable/BetaStalePricingTable'
import { TableFilterList } from './tableFilter/TableFilterData'
import { SvgVector } from '../../../../components/svg/SvgVector'
import Label from '../../../../components/label/Label'
import { Radio } from '../../../../components/radio/radio'
import SvgFilter from '../../../../components/svg/SvgFilter'
import { getAccessFlag } from '../../../../utils/globalFunctions'

const BetaStaleTable = (props: any) => {
  const dispatch = useDispatch()
  const data = useSelector(dataSelector)
  const [inputComment, setInputComment] = useState<any>([])
  const [addCommentPopup, setAddCommentsPopup] = useState<boolean>(false)
  const [userComment, setUserComments] = useState<any>([])
  const PageNum = useSelector(PageNumSelectorBeta)
  const [openComments, setOpenComments] = useState<boolean>(false)
  const loading = useSelector(LoadingSelector)
  const [system, setSystem] = useState<string>('')
  const sortingBeta = useSelector(sortingSelectorBeta)
  const sortDirectionBeta = useSelector(sortDirectionSelectorBeta)
  const [flagPopUp, setFlagPopup] = useState<boolean>(false)
  const [flagedRow, setFlagRow] = useState<any>({
    currentRow: {},
    rowId: null,
    cusip: null,
    type: null,
    followUp_Flag: 0,
  })
  const [input, setInput] = useState({
    commentRow: 0,
    commentDate: new Date().toISOString(),
  })

  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)
  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['SD-RWX', 'OM-RWX']))
  }, [])

  const securityNbr =
    TableFilterList[0]?.dropDownArray[0].value &&
    TableFilterList[0].selectedData[0]
      ? TableFilterList[0].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[0]?.dropDownArray[0].value &&
        !TableFilterList[0].selectedData[0]
      ? 'Blank'
      : TableFilterList[0].selectedData.join(';').toString()

  let top_Category = ''
  if (
    TableFilterList[1].dropDownArray.filter((option) => option.value).length ==
    TableFilterList[1].dropDownArray.length
  ) {
    top_Category = ''
  } else {
    top_Category = TableFilterList[1].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  let sub_Category = ''
  if (
    TableFilterList[2].dropDownArray.filter((option) => option.value).length ==
    TableFilterList[2].dropDownArray.length
  ) {
    sub_Category = ''
  } else {
    sub_Category = TableFilterList[2].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  const reviewer_LatestComment =
    TableFilterList[3]?.dropDownArray[0].value &&
    TableFilterList[3].selectedData[0]
      ? TableFilterList[3].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[3]?.dropDownArray[0].value &&
        !TableFilterList[3].selectedData[0]
      ? 'Blank'
      : TableFilterList[3].selectedData.join(';').toString()

  const lastPrice_In_Current_StaleReport =
    TableFilterList[4]?.dropDownArray[0].value &&
    TableFilterList[4].selectedData[0]
      ? TableFilterList[4].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[4]?.dropDownArray[0].value &&
        !TableFilterList[4].selectedData[0]
      ? 'Blank'
      : TableFilterList[4].selectedData.join(';').toString()

  let followup_Flag: string
  if (
    TableFilterList[5].dropDownArray.filter((option) => option.value).length ==
    TableFilterList[5].dropDownArray.length
  ) {
    followup_Flag = ''
  } else {
    followup_Flag = TableFilterList[5].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.labelValue)
      .join(';')
      .toString()
  }

  const securityDesc =
    TableFilterList[6]?.dropDownArray[0].value &&
    TableFilterList[6].selectedData[0]
      ? TableFilterList[6].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[6]?.dropDownArray[0].value &&
        !TableFilterList[6].selectedData[0]
      ? 'Blank'
      : TableFilterList[6].selectedData.join(';').toString()

  const cusip =
    TableFilterList[7]?.dropDownArray[0].value &&
    TableFilterList[7].selectedData[0]
      ? TableFilterList[7].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[7]?.dropDownArray[0].value &&
        !TableFilterList[7].selectedData[0]
      ? 'Blank'
      : TableFilterList[7].selectedData.join(';').toString()

  const isiN_Number =
    TableFilterList[8]?.dropDownArray[0].value &&
    TableFilterList[8].selectedData[0]
      ? TableFilterList[8].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[8]?.dropDownArray[0].value &&
        !TableFilterList[8].selectedData[0]
      ? 'Blank'
      : TableFilterList[8].selectedData.join(';').toString()

  const comments_Created_By =
    TableFilterList[9]?.dropDownArray[0].value &&
    TableFilterList[9].selectedData[0]
      ? TableFilterList[9].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[9]?.dropDownArray[0].value &&
        !TableFilterList[9].selectedData[0]
      ? 'Blank'
      : TableFilterList[9].selectedData.join(';').toString()

  const reviewer_Last_Commented_Date =
    TableFilterList[10]?.dropDownArray[0].value &&
    TableFilterList[10].selectedData[0]
      ? TableFilterList[10].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[10]?.dropDownArray[0].value &&
        !TableFilterList[10].selectedData[0]
      ? 'Blank'
      : TableFilterList[10].selectedData.join(';').toString()

  const icE_Last_Priced =
    TableFilterList[11]?.dropDownArray[0].value &&
    TableFilterList[11].selectedData[0]
      ? TableFilterList[11].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[11]?.dropDownArray[0].value &&
        !TableFilterList[11].selectedData[0]
      ? 'Blank'
      : TableFilterList[11].selectedData.join(';').toString()

  const icE_Last_Price =
    TableFilterList[12]?.dropDownArray[0].value &&
    TableFilterList[12].selectedData[0]
      ? TableFilterList[12].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[12]?.dropDownArray[0].value &&
        !TableFilterList[12].selectedData[0]
      ? 'Blank'
      : TableFilterList[12].selectedData.join(';').toString()

  const icE_Notes =
    TableFilterList[13]?.dropDownArray[0].value &&
    TableFilterList[13].selectedData[0]
      ? TableFilterList[13].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[13]?.dropDownArray[0].value &&
        !TableFilterList[13].selectedData[0]
      ? 'Blank'
      : TableFilterList[13].selectedData.join(';').toString()

  const bbG_Last_Priced =
    TableFilterList[14]?.dropDownArray[0].value &&
    TableFilterList[14].selectedData[0]
      ? TableFilterList[14].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[14]?.dropDownArray[0].value &&
        !TableFilterList[14].selectedData[0]
      ? 'Blank'
      : TableFilterList[14].selectedData.join(';').toString()

  const bbG_Last_Price =
    TableFilterList[15]?.dropDownArray[0].value &&
    TableFilterList[15].selectedData[0]
      ? TableFilterList[15].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[15]?.dropDownArray[0].value &&
        !TableFilterList[15].selectedData[0]
      ? 'Blank'
      : TableFilterList[15].selectedData.join(';').toString()

  const box_Location =
    TableFilterList[16]?.dropDownArray[0].value &&
    TableFilterList[16].selectedData[0]
      ? TableFilterList[16].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[16]?.dropDownArray[0].value &&
        !TableFilterList[16].selectedData[0]
      ? 'Blank'
      : TableFilterList[16].selectedData.join(';').toString()

  const box_Description =
    TableFilterList[17]?.dropDownArray[0].value &&
    TableFilterList[17].selectedData[0]
      ? TableFilterList[17].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[17]?.dropDownArray[0].value &&
        !TableFilterList[17].selectedData[0]
      ? 'Blank'
      : TableFilterList[17].selectedData.join(';').toString()

  const country =
    TableFilterList[18]?.dropDownArray[0].value &&
    TableFilterList[18].selectedData[0]
      ? TableFilterList[18].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[18]?.dropDownArray[0].value &&
        !TableFilterList[18].selectedData[0]
      ? 'Blank'
      : TableFilterList[18].selectedData.join(';').toString()

  const securityTypeCode =
    TableFilterList[19]?.dropDownArray[0].value &&
    TableFilterList[19].selectedData[0]
      ? TableFilterList[19].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[19]?.dropDownArray[0].value &&
        !TableFilterList[19].selectedData[0]
      ? 'Blank'
      : TableFilterList[19].selectedData.join(';').toString()

  const expirationDate =
    TableFilterList[20]?.dropDownArray[0].value &&
    TableFilterList[20].selectedData[0]
      ? TableFilterList[20].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[20]?.dropDownArray[0].value &&
        !TableFilterList[20].selectedData[0]
      ? 'Blank'
      : TableFilterList[20].selectedData.join(';').toString()

  const inDefault =
    TableFilterList[21]?.dropDownArray[0].value &&
    TableFilterList[21].selectedData[0]
      ? TableFilterList[21].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[21]?.dropDownArray[0].value &&
        !TableFilterList[21].selectedData[0]
      ? 'Blank'
      : TableFilterList[21].selectedData.join(';').toString()

  const corporateAction1 =
    TableFilterList[22]?.dropDownArray[0].value &&
    TableFilterList[22].selectedData[0]
      ? TableFilterList[22].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[22]?.dropDownArray[0].value &&
        !TableFilterList[22].selectedData[0]
      ? 'Blank'
      : TableFilterList[22].selectedData.join(';').toString()

  const corporateAction2 =
    TableFilterList[23]?.dropDownArray[0].value &&
    TableFilterList[23].selectedData[0]
      ? TableFilterList[23].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[23]?.dropDownArray[0].value &&
        !TableFilterList[23].selectedData[0]
      ? 'Blank'
      : TableFilterList[23].selectedData.join(';').toString()

  const surf_User_Pricing_Manual =
    TableFilterList[24]?.dropDownArray[0].value &&
    TableFilterList[24].selectedData[0]
      ? TableFilterList[24].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[24]?.dropDownArray[0].value &&
        !TableFilterList[24].selectedData[0]
      ? 'Blank'
      : TableFilterList[24].selectedData.join(';').toString()

  const surf_LastUpdated =
    TableFilterList[25]?.dropDownArray[0].value &&
    TableFilterList[25].selectedData[0]
      ? TableFilterList[25].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[25]?.dropDownArray[0].value &&
        !TableFilterList[25].selectedData[0]
      ? 'Blank'
      : TableFilterList[25].selectedData.join(';').toString()

  const surf_LastPrice =
    TableFilterList[26]?.dropDownArray[0].value &&
    TableFilterList[26].selectedData[0]
      ? TableFilterList[26].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[26]?.dropDownArray[0].value &&
        !TableFilterList[26].selectedData[0]
      ? 'Blank'
      : TableFilterList[26].selectedData.join(';').toString()

  const userIntial =
    TableFilterList[27]?.dropDownArray[0].value &&
    TableFilterList[27].selectedData[0]
      ? TableFilterList[27].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[27]?.dropDownArray[0].value &&
        !TableFilterList[27].selectedData[0]
      ? 'Blank'
      : TableFilterList[27].selectedData.join(';').toString()

  const changedDate =
    TableFilterList[28]?.dropDownArray[0].value &&
    TableFilterList[28].selectedData[0]
      ? TableFilterList[28].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[28]?.dropDownArray[0].value &&
        !TableFilterList[28].selectedData[0]
      ? 'Blank'
      : TableFilterList[28].selectedData.join(';').toString()

  const marketPrice =
    TableFilterList[29]?.dropDownArray[0].value &&
    TableFilterList[29].selectedData[0]
      ? TableFilterList[29].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[29]?.dropDownArray[0].value &&
        !TableFilterList[29].selectedData[0]
      ? 'Blank'
      : TableFilterList[29].selectedData.join(';').toString()

  const changeTerminal =
    TableFilterList[30]?.dropDownArray[0].value &&
    TableFilterList[30].selectedData[0]
      ? TableFilterList[30].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[30]?.dropDownArray[0].value &&
        !TableFilterList[30].selectedData[0]
      ? 'Blank'
      : TableFilterList[30].selectedData.join(';').toString()

  const dtC_Premed =
    TableFilterList[31]?.dropDownArray[0].value &&
    TableFilterList[31].selectedData[0]
      ? TableFilterList[31].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[31]?.dropDownArray[0].value &&
        !TableFilterList[31].selectedData[0]
      ? 'Blank'
      : TableFilterList[31].selectedData.join(';').toString()

  const lastUpdateDate =
    TableFilterList[32]?.dropDownArray[0].value &&
    TableFilterList[32].selectedData[0]
      ? TableFilterList[32].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[32]?.dropDownArray[0].value &&
        !TableFilterList[32].selectedData[0]
      ? 'Blank'
      : TableFilterList[32].selectedData.join(';').toString()

  const lastPrice =
    TableFilterList[33]?.dropDownArray[0].value &&
    TableFilterList[33].selectedData[0]
      ? TableFilterList[33].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[33]?.dropDownArray[0].value &&
        !TableFilterList[33].selectedData[0]
      ? 'Blank'
      : TableFilterList[33].selectedData.join(';').toString()

  const s_D =
    TableFilterList[34]?.dropDownArray[0].value &&
    TableFilterList[34].selectedData[0]
      ? TableFilterList[34].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[34]?.dropDownArray[0].value &&
        !TableFilterList[34].selectedData[0]
      ? 'Blank'
      : TableFilterList[34].selectedData.join(';').toString()

  const cash =
    TableFilterList[35]?.dropDownArray[0].value &&
    TableFilterList[35].selectedData[0]
      ? TableFilterList[35].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[35]?.dropDownArray[0].value &&
        !TableFilterList[35].selectedData[0]
      ? 'Blank'
      : TableFilterList[35].selectedData.join(';').toString()

  const margin =
    TableFilterList[36]?.dropDownArray[0].value &&
    TableFilterList[36].selectedData[0]
      ? TableFilterList[36].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[36]?.dropDownArray[0].value &&
        !TableFilterList[36].selectedData[0]
      ? 'Blank'
      : TableFilterList[36].selectedData.join(';').toString()

  const ira =
    TableFilterList[37]?.dropDownArray[0].value &&
    TableFilterList[37].selectedData[0]
      ? TableFilterList[37].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[37]?.dropDownArray[0].value &&
        !TableFilterList[37].selectedData[0]
      ? 'Blank'
      : TableFilterList[37].selectedData.join(';').toString()

  const upX_Z =
    TableFilterList[38]?.dropDownArray[0].value &&
    TableFilterList[38].selectedData[0]
      ? TableFilterList[38].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[38]?.dropDownArray[0].value &&
        !TableFilterList[38].selectedData[0]
      ? 'Blank'
      : TableFilterList[38].selectedData.join(';').toString()

  const currently_Still_in_StaleReport =
    TableFilterList[39]?.dropDownArray[0].value &&
    TableFilterList[39].selectedData[0]
      ? TableFilterList[39].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[39]?.dropDownArray[0].value &&
        !TableFilterList[39].selectedData[0]
      ? 'Blank'
      : TableFilterList[39].selectedData.join(';').toString()

  const last_Priced_In_Current_StaleReport =
    TableFilterList[40]?.dropDownArray[0].value &&
    TableFilterList[40].selectedData[0]
      ? TableFilterList[40].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[40]?.dropDownArray[0].value &&
        !TableFilterList[40].selectedData[0]
      ? 'Blank'
      : TableFilterList[40].selectedData.join(';').toString()

  const commentFilter =
    TableFilterList[41]?.dropDownArray[0].value &&
    TableFilterList[41].selectedData[0]
      ? TableFilterList[41].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[41]?.dropDownArray[0].value &&
        !TableFilterList[41].selectedData[0]
      ? 'Blank'
      : TableFilterList[41].selectedData.join(';').toString()

  const notes =
    TableFilterList[42]?.dropDownArray[0].value &&
    TableFilterList[42].selectedData[0]
      ? TableFilterList[42].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[42]?.dropDownArray[0].value &&
        !TableFilterList[42].selectedData[0]
      ? 'Blank'
      : TableFilterList[42].selectedData.join(';').toString()

  const savedComments = async (tb: any, system: string) => {
    const response = await dispatch(
      getComments(
        system,
        data.data[tb].cusip.replace(/'/g, ''),
        data.data[tb].securityNbr,
        false,
        props.toDate
      )
    )
    setUserComments(response)
    await setOpenComments(true)
  }

  const renderColors = (flag: number) => {
    switch (flag) {
      case 1:
        return 'red'
      case 2:
        return '#008000'
      case 3:
        return '#737373'
      default:
        return '#475569'
    }
  }

  const toggleFlag = async (
    type: string,
    securityNumber: string,
    Cusip: string,
    followup_Flag: boolean,
    currentRow: any,
    rowId: any
  ) => {
    setFlagPopup(true)
    setFlagRow({
      currentRow,
      rowId,
      cusip: Cusip?.replace(/'/g, ''),
      type,
      securityNumber,
      followup_Flag,
    })
  }

  const updateFlagDetails = async () => {
    const { currentRow, rowId, cusip, type, followup_Flag, securityNumber } =
      flagedRow

    const tempRow = currentRow
    const tempTable = [...props.data]
    tempTable[rowId] = {
      ...tempRow,
      followup_Flag,
    }
    const response = await dispatch(
      updateFlag(type, securityNumber, cusip, followup_Flag, {
        data: tempTable,
        totalCount: data?.totalCount,
      })
    )
    setFlagPopup(false)
  }

  const newUserComment = async (
    type: string,
    SecNum: string,
    comment: string,
    rowId: number,
    Cusip: string,
    boxLocation: any,
    commentedBy: any
  ) => {
    const response = await dispatch(
      AddComment(
        type,
        SecNum,
        comment,
        Cusip.replace(/'/g, ''),
        boxLocation ? boxLocation.toString() : '',
        commentedBy,
        input.commentDate,
        props.toDate
      )
    )
    response == 200 &&
      type == 'Beta' &&
      (await dispatch(
        getBetaStalePriceData(
          'search',
          'Beta',
          props.fromDate,
          props.toDate,
          PageNum,
          20,
          sortingBeta,
          sortDirectionBeta,
          0,
          securityNbr,
          top_Category,
          sub_Category,
          reviewer_LatestComment,
          lastPrice_In_Current_StaleReport,
          followup_Flag,
          securityDesc,
          cusip,
          isiN_Number,
          comments_Created_By,
          reviewer_Last_Commented_Date,
          icE_Last_Priced,
          icE_Last_Price,
          icE_Notes,
          bbG_Last_Priced,
          bbG_Last_Price,
          box_Location,
          box_Description,
          country,
          securityTypeCode,
          expirationDate,
          inDefault,
          corporateAction1,
          corporateAction2,
          surf_User_Pricing_Manual,
          surf_LastUpdated,
          surf_LastPrice,
          userIntial,
          changedDate,
          marketPrice,
          changeTerminal,
          dtC_Premed,
          lastUpdateDate,
          lastPrice,
          s_D,
          cash,
          margin,
          ira,
          upX_Z,
          currently_Still_in_StaleReport,
          last_Priced_In_Current_StaleReport,
          commentFilter,
          notes
        )
      ))
    inputComment[rowId] = '  '
    setInputComment({ ...inputComment })
  }

  const updateNote = async (
    type: string,
    SecNum: string,
    comment: string,
    rowId: number,
    Cusip: string,
    boxLocation: any,
    commentedBy: any
  ) => {
    const response = await dispatch(
      UpdateNote(
        type,
        SecNum,
        comment,
        Cusip.replace(/'/g, ''),
        boxLocation ? boxLocation.toString() : '',
        commentedBy,
        input.commentDate,
        props.toDate
      )
    )
    response == 200 &&
      (await dispatch(
        getBetaStalePriceData(
          'search',
          'Beta',
          props.fromDate,
          props.toDate,
          PageNum,
          20,
          sortingBeta,
          sortDirectionBeta,
          0,
          securityNbr,
          top_Category,
          sub_Category,
          reviewer_LatestComment,
          lastPrice_In_Current_StaleReport,
          followup_Flag,
          securityDesc,
          cusip,
          isiN_Number,
          comments_Created_By,
          reviewer_Last_Commented_Date,
          icE_Last_Priced,
          icE_Last_Price,
          icE_Notes,
          bbG_Last_Priced,
          bbG_Last_Price,
          box_Location,
          box_Description,
          country,
          securityTypeCode,
          expirationDate,
          inDefault,
          corporateAction1,
          corporateAction2,
          surf_User_Pricing_Manual,
          surf_LastUpdated,
          surf_LastPrice,
          userIntial,
          changedDate,
          marketPrice,
          changeTerminal,
          dtC_Premed,
          lastUpdateDate,
          lastPrice,
          s_D,
          cash,
          margin,
          ira,
          upX_Z,
          currently_Still_in_StaleReport,
          last_Priced_In_Current_StaleReport,
          commentFilter,
          notes
        )
      ))
    inputComment[rowId] = '  '
    setInputComment({ ...inputComment })
  }

  const tableColumn = [
    {
      Header: 'Security',
      columns: [
        {
          Header: (
            <>
              Flag{' '}
              {followup_Flag.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'followup_Flag',
          width: '2%',
          Cell: (table: any) => {
            return (
              <>
                <Label
                  cursor="pointer"
                  onClick={() =>
                    toggleFlag(
                      'Beta',
                      data.data[table.cell.row.id].securityNbr,
                      data.data[table.cell.row.id].cusip,
                      table?.row?.original?.followup_Flag,
                      table?.row?.original,
                      table.cell.row.id
                    )
                  }
                >
                  <SvgVector
                    fillColor={
                      table?.row?.original?.followup_Flag
                        ? renderColors(
                            Number(table?.row?.original?.followup_Flag)
                          )
                        : '#475569'
                    }
                  />
                </Label>
              </>
            )
          },
        },
        {
          Header: (
            <>
              Security Desc{' '}
              {securityDesc.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'securityDesc',
        },
        {
          Header: (
            <>
              Security Number{' '}
              {securityNbr.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'securityNbr',
        },
        {
          Header: (
            <>Cusip {cusip.length > 0 && <SvgFilter fillColor={'grey'} />}</>
          ),
          id: 'cusip',
          accessor: (d: any) => {
            if (d.cusip) return d.cusip.replace(/'/g, '')
          },
        },
        {
          Header: (
            <>
              ISIN {isiN_Number.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'isiN_Number',
        },
      ],
    },
    {
      Header: 'Category',
      columns: [
        {
          Header: (
            <>
              Top Category{' '}
              {top_Category.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'top_Category',
        },
        {
          Header: (
            <>
              Sub Category{' '}
              {sub_Category.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'sub_Category',
        },
      ],
    },
    {
      Header: 'Comments',
      columns: [
        {
          Header: (
            <>
              Reviewer LatestComment{' '}
              {reviewer_LatestComment.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'reviewer_LatestComment',
        },
        {
          Header: (
            <>
              Comments Created By{' '}
              {comments_Created_By.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'comments_Created_By',
        },
        {
          Header: (
            <>
              {'Reviewer Last Commented Date(EST)'}{' '}
              {reviewer_Last_Commented_Date.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'reviewer_Last_Commented_Date',
        },
        {
          Header: 'Add Comment',
          Cell: (table: any) => {
            return (
              <>
                <Button
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={'button'}
                  color={COLORS.Background.Primarytext}
                  style={{ whiteSpace: 'nowrap' }}
                  bgColor={'transparent'}
                  disabled={!writeExecuteFlag}
                  onClick={() => {
                    setSystem('beta')
                    setAddCommentsPopup(!addCommentPopup)
                    setInput({
                      ...input,
                      commentRow: table.cell.row.id,
                    })
                  }}
                >
                  Add Comment
                </Button>

                {system == 'beta' &&
                  addCommentPopup &&
                  table.cell.row.id === input.commentRow && (
                    <PopupCardComment>
                      <Textarea
                        onChange={(e: any) => {
                          inputComment[table.cell.row.id] = e.target.value
                          setInputComment(inputComment)
                        }}
                        height="365px"
                        placeholder={'Enter Comment'}
                      ></Textarea>

                      <StyledSelectWrapper
                        style={{
                          padding: '2px 0px 0px 0px',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          style={{
                            marginRight: '16px',
                          }}
                          padding={'4px 16px'}
                          borderRadius={'4px'}
                          type={'button'}
                          color={COLORS.Background.Primarytext}
                          bgColor={'transparent'}
                          onClick={() => {
                            setAddCommentsPopup(false)
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          hoverBgColor={COLORS.Background.Primary70}
                          bgColor={COLORS.Background.Primarytext}
                          color={COLORS.Background.Primary}
                          padding={'4px 16px'}
                          borderRadius={'4px'}
                          hover={true}
                          type={''}
                          activeBgColor={COLORS.Background.Primaryactive}
                          borderColor={'transparent'}
                          onClick={async () => {
                            await newUserComment(
                              'Beta',
                              data.data[table.cell.row.id].securityNbr,
                              inputComment[table.cell.row.id],
                              table.cell.row.id,
                              data.data[table.cell.row.id].cusip,
                              data.data[table.cell.row.id].box_Location,
                              localStorage.getItem('AccountName')
                            )
                            setAddCommentsPopup(false)
                          }}
                        >
                          Submit
                        </Button>
                      </StyledSelectWrapper>
                    </PopupCardComment>
                  )}
              </>
            )
          },
        },
        {
          Header: 'All Comments',
          Cell: (table: any) => {
            return (
              <>
                <Button
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={'button'}
                  color={COLORS.Background.Primarytext}
                  style={{ whiteSpace: 'nowrap' }}
                  bgColor={'transparent'}
                  onClick={() => savedComments(table.cell.row.id, 'beta')}
                >
                  View
                </Button>
              </>
            )
          },
        },
        {
          Header: (
            <>
              view Notes {notes.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'notes',
          Cell: (table: any) => {
            return (
              <>
                <Label
                  hover={true}
                  cursor="pointer"
                  onClick={() => {
                    setSystem('Beta_ISIN')
                    setAddCommentsPopup(!addCommentPopup)
                    inputComment[table.cell.row.id] =
                      table?.row?.original?.notes
                    setInputComment(inputComment)
                    setInput({
                      ...input,
                      commentRow: table.cell.row.id,
                    })
                  }}
                >
                  {table?.row?.original?.notes
                    ? table?.row?.original?.notes?.length > 25
                      ? `${table?.row?.original?.notes?.slice(0, 26)}...`
                      : table?.row?.original?.notes
                    : '-'}
                </Label>
                {system == 'Beta_ISIN' &&
                  addCommentPopup &&
                  table.cell.row.id === input.commentRow && (
                    <PopupCardNote>
                      <Textarea
                        onChange={(e: any) => {
                          inputComment[table.cell.row.id] = e.target.value
                          setInputComment(inputComment)
                        }}
                        defaultValue={inputComment[table.cell.row.id]}
                        height="365px"
                        placeholder={'Enter Notes'}
                      ></Textarea>

                      <StyledSelectWrapper
                        style={{
                          padding: '2px 0px 0px 0px',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          style={{
                            marginRight: '16px',
                          }}
                          padding={'4px 16px'}
                          borderRadius={'4px'}
                          type={'button'}
                          color={COLORS.Background.Primarytext}
                          bgColor={'transparent'}
                          onClick={() => {
                            setAddCommentsPopup(false)
                          }}
                        >
                          Cancel
                        </Button>

                        <Button
                          hoverBgColor={COLORS.Background.Primary70}
                          bgColor={COLORS.Background.Primarytext}
                          color={COLORS.Background.Primary}
                          padding={'4px 16px'}
                          borderRadius={'4px'}
                          hover={true}
                          type={''}
                          activeBgColor={COLORS.Background.Primaryactive}
                          borderColor={'transparent'}
                          onClick={async () => {
                            await updateNote(
                              'Beta',
                              data.data[table.cell.row.id].securityNbr,
                              inputComment[table.cell.row.id],
                              table.cell.row.id,
                              data.data[table.cell.row.id].cusip,
                              data.data[table.cell.row.id].box_Location,
                              ''
                            )
                            setAddCommentsPopup(false)
                          }}
                        >
                          Submit
                        </Button>
                      </StyledSelectWrapper>
                    </PopupCardNote>
                  )}
              </>
            )
          },
        },
      ],
    },
    {
      Header: 'ICE',
      columns: [
        {
          Header: (
            <>
              ICE Last Priced{' '}
              {icE_Last_Priced.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'icE_Last_Priced',
        },
        {
          Header: (
            <>
              ICE Last Price{' '}
              {icE_Last_Price.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'icE_Last_Price',
        },
        {
          Header: (
            <>
              ICE Notes{' '}
              {icE_Notes.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'icE_Notes',
        },
      ],
    },
    {
      Header: 'Pricing Vendor',
      columns: [
        {
          Header: (
            <>
              BBG Last Priced{' '}
              {bbG_Last_Priced.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'bbG_Last_Priced',
        },
        {
          Header: (
            <>
              BBG Last Price{' '}
              {bbG_Last_Price.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'bbG_Last_Price',
        },
      ],
    },
    {
      Header: 'Beta Databases',
      columns: [
        {
          Header: (
            <>
              Box Location{' '}
              {box_Location.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'box_Location',
        },
        {
          Header: (
            <>
              Box Description{' '}
              {box_Description.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'box_Description',
        },
        {
          Header: (
            <>
              Country {country.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'country',
        },
        {
          Header: (
            <>
              SecurityTypeCode{' '}
              {securityTypeCode.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'securityTypeCode',
        },
        {
          Header: (
            <>
              ExpirationDate/MaturityDate{' '}
              {expirationDate.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'expirationDate',
        },
        {
          Header: (
            <>
              InDefault{' '}
              {inDefault.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'inDefault',
        },
        {
          Header: (
            <>
              CorporateAction1{' '}
              {corporateAction1.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'corporateAction1',
        },
        {
          Header: (
            <>
              CorporateAction2{' '}
              {corporateAction2.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'corporateAction2',
        },
        {
          Header: (
            <>
              Surf User PricingManual{' '}
              {surf_User_Pricing_Manual.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'surf_User_Pricing_Manual',
        },
        {
          Header: (
            <>
              Surf LastUpdated{' '}
              {surf_LastUpdated.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'surf_LastUpdated',
        },
        {
          Header: (
            <>
              Surf LastPrice{' '}
              {surf_LastPrice.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'surf_LastPrice',
        },
        {
          Header: (
            <>
              UserIntial{' '}
              {userIntial.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'userIntial',
        },
        {
          Header: (
            <>
              ChangedDate{' '}
              {changedDate.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'changedDate',
        },
        {
          Header: (
            <>
              MarketPrice{' '}
              {marketPrice.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'marketPrice',
        },
        {
          Header: (
            <>
              ChangeTerminal{' '}
              {changeTerminal.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'changeTerminal',
        },
      ],
    },
    {
      Header: 'DTC Data',
      columns: [
        {
          Header: (
            <>
              DTC Premed{' '}
              {dtC_Premed.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'dtC_Premed',
        },
      ],
    },
    {
      Header: 'WED14509A10 Report Data',
      columns: [
        {
          Header: (
            <>
              LastUpdate Date{' '}
              {lastUpdateDate.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'lastUpdateDate',
        },
        {
          Header: (
            <>
              LastPrice{' '}
              {lastPrice.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'lastPrice',
        },
        {
          Header: <>S_D {s_D.length > 0 && <SvgFilter fillColor={'grey'} />}</>,
          accessor: 's_D',
        },
        {
          Header: (
            <>Cash {cash.length > 0 && <SvgFilter fillColor={'grey'} />}</>
          ),
          accessor: 'cash',
        },
        {
          Header: (
            <>Margin {margin.length > 0 && <SvgFilter fillColor={'grey'} />}</>
          ),
          accessor: 'margin',
        },
        {
          Header: <>IRA {ira.length > 0 && <SvgFilter fillColor={'grey'} />}</>,
          accessor: 'ira',
        },
        {
          Header: (
            <>UPX_Z {upX_Z.length > 0 && <SvgFilter fillColor={'grey'} />}</>
          ),
          accessor: 'upX_Z',
        },
      ],
    },
    {
      Header: 'Current WED14509ABCD10 Report',
      columns: [
        {
          Header: (
            <>
              Currently Still in StaleReport{' '}
              {currently_Still_in_StaleReport.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'currently_Still_In_StaleReport',
        },
        {
          Header: (
            <>
              Last Priced In Current StaleReport{' '}
              {last_Priced_In_Current_StaleReport.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'last_Priced_In_Current_StaleReport',
        },
        {
          Header: (
            <>
              LastPrice In Current StaleReport{' '}
              {lastPrice_In_Current_StaleReport.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'lastPrice_In_Current_StaleReport',
        },
      ],
    },
  ]

  return (
    <StyledTableContainer>
      {flagPopUp && (
        <FlagPopUpWrapper>
          <FlagPopUpTitle>Choose the flag type</FlagPopUpTitle>
          <RadioButtonWrapper>
            <Radio
              label={
                <>
                  Un Flag
                  <SvgVector fillColor="" />
                </>
              }
              onChange={() => {
                setFlagRow({ ...flagedRow, followup_Flag: 0 })
              }}
              value={0}
              name={'radio'}
              checked={flagedRow.followup_Flag === 0 ? true : false}
            />
            <Radio
              label={
                <>
                  Follow Up
                  <SvgVector fillColor="red" />
                </>
              }
              onChange={() => {
                setFlagRow({ ...flagedRow, followup_Flag: 1 })
              }}
              value={1}
              name={'radio'}
              checked={flagedRow.followup_Flag === 1 ? true : false}
            />
            <Radio
              label={
                <>
                  Reviewed
                  <SvgVector fillColor="#008000" />
                </>
              }
              value={2}
              onChange={() => {
                setFlagRow({ ...flagedRow, followup_Flag: 2 })
              }}
              name={'radio'}
              checked={flagedRow.followup_Flag === 2 ? true : false}
            />
            <Radio
              label={
                <>
                  No further review required
                  <SvgVector fillColor="#737373" />
                </>
              }
              onChange={() => {
                setFlagRow({ ...flagedRow, followup_Flag: 3 })
              }}
              value={3}
              name={'radio'}
              checked={flagedRow.followup_Flag === 3 ? true : false}
            />
          </RadioButtonWrapper>
          <ButtonWrapper>
            <Button
              hoverBgColor={'#A7AFBC'}
              hoverTextColor={'#E2E8F0'}
              borderRadius={'4px'}
              hover={true}
              padding={'5px 10px'}
              bgColor={'#E2E8F0'}
              color={'#A7AFBC'}
              type={'Button'}
              margin={'0px 6px'}
              onClick={() => {
                setFlagPopup(false)
              }}
            >
              Cancel
            </Button>
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'5px 10px'}
              color={Colors.White}
              bgColor={Colors.PrimaryText}
              type={'Button'}
              fontWeight={'400'}
              onClick={() => {
                updateFlagDetails()
              }}
            >
              Save
            </Button>
          </ButtonWrapper>
        </FlagPopUpWrapper>
      )}
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {openComments && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>All Comments</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenComments(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'Security Number',
                  accessor: 'securityNumber',
                },
                {
                  Header: 'Cusip',
                  accessor: 'cusip',
                },
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
                {
                  Header: 'Created by',
                  accessor: 'createdBy',
                },
                {
                  Header: 'Create on(EST)',
                  accessor: 'createdOn',
                },
              ]}
              tableData={userComment.length ? userComment : []}
              onClick={() => {
                //
              }}
            />
            {userComment.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      <BetaStalePricingTable
        handleSubmit={props.handleSubmit}
        columns={tableColumn}
        fromDate={props.fromDate}
        toDate={props.toDate}
        data={props.data}
        totalRecords={props.totalRecords}
      />
    </StyledTableContainer>
  )
}
export default BetaStaleTable
