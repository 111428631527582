import { ISvg } from './ISvg'

const SvgBackwardDoubleChevron = ({ fillColor }: ISvg) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3687 5.20555C10.4934 5.07648 10.6639 5.00257 10.8427 5.00007C11.0215 4.99757 11.194 5.06669 11.3222 5.19222C11.4503 5.31776 11.5238 5.48943 11.5262 5.66946C11.5287 5.84949 11.4601 6.02315 11.3354 6.15222L9.78604 7.67889L11.3354 9.20555C11.3975 9.26753 11.4467 9.34126 11.4803 9.4225C11.5139 9.50374 11.5312 9.59088 11.5312 9.67889C11.5312 9.7669 11.5139 9.85403 11.4803 9.93527C11.4467 10.0165 11.3975 10.0902 11.3354 10.1522C11.2738 10.2147 11.2006 10.2643 11.1199 10.2981C11.0392 10.332 10.9527 10.3494 10.8653 10.3494C10.7779 10.3494 10.6913 10.332 10.6107 10.2981C10.53 10.2643 10.4567 10.2147 10.3952 10.1522L8.40884 8.15222C8.34678 8.09025 8.29753 8.01651 8.26391 7.93527C8.2303 7.85403 8.21299 7.7669 8.21299 7.67889C8.21299 7.59088 8.2303 7.50374 8.26391 7.4225C8.29753 7.34126 8.34678 7.26753 8.40884 7.20555L10.3687 5.20555ZM4.74072 7.20555L6.72707 5.20555C6.85175 5.08002 7.02085 5.00949 7.19717 5.00949C7.37349 5.00949 7.54259 5.08002 7.66727 5.20555C7.79195 5.33109 7.862 5.50135 7.862 5.67889C7.862 5.85642 7.79195 6.02669 7.66727 6.15222L6.1444 7.67889L7.66727 9.20555C7.72933 9.26753 7.77859 9.34126 7.8122 9.4225C7.84582 9.50374 7.86313 9.59088 7.86313 9.67889C7.86313 9.7669 7.84582 9.85403 7.8122 9.93527C7.77859 10.0165 7.72933 10.0902 7.66727 10.1522C7.60572 10.2147 7.53249 10.2643 7.4518 10.2981C7.37112 10.332 7.28458 10.3494 7.19717 10.3494C7.10976 10.3494 7.02322 10.332 6.94254 10.2981C6.86185 10.2643 6.78862 10.2147 6.72707 10.1522L4.74072 8.15222C4.67689 8.09202 4.62555 8.01969 4.58964 7.93941C4.55373 7.85912 4.53398 7.77246 4.53151 7.68445C4.52905 7.59643 4.54393 7.50879 4.57529 7.4266C4.60665 7.3444 4.65388 7.26927 4.71423 7.20555H4.74072Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default SvgBackwardDoubleChevron
