
import ThresholdConfig from "../../../composites/ThresholdConfiguration/ThresholdConfig"
import {  RootContainerPage } from "./styles"

  export const TradeConfiguration = () => {
    return (
      <RootContainerPage>
        <ThresholdConfig Department={"TradeReconciliation"} />
      </RootContainerPage>
    )
  }