/* eslint-disable @typescript-eslint/no-explicit-any */
import Label from '../../components/label/Label'
import {
  WDStyledButtonText,
  WDStyledMainHeading,
} from '../../components/ui/WDTypography'
import * as z from "zod";

import {
  CancelConfiglModal,
  CancelSendButtonWrapper,
  ConfiglBodyWrapper,
  ConfiglHeaderContainer,
  ConfigModalWrapper,
  PresetColor,
  Picker,
  StyledSelectWrapper
} from './styles'

import { useEffect, useState } from 'react'
import Button from '../../components/button/Button'
import { COLORS } from '../../theme/Colors'
import { Textbox } from '../../components/textbox/Textbox'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import { Textarea } from '../../components/textArea/Textarea';
import { ConfigDetails } from './IconfigDetails'
import { useSelector } from 'react-redux'
import { FilterDetailSelector } from '../../store/threshold-Config/thresholdCongfiguration'
import { ValidateSchema } from './validations'

interface ConfigInterfaceProps {
  title: string,
  editDetails: ConfigDetails,
  previousEditDetails: ConfigDetails,
  nextEditDetails: ConfigDetails,
  openMode: string,
  saveDetails: (editValue: ConfigDetails) => void,
  cancelModal: () => void,
  widgetIds: any
  filterDetails: any,
  colorsSelected: []
}


const ConfigPopUp = (props: ConfigInterfaceProps) => {
  const [validationError, setValidationError] = useState<any>([])
  const { filterDetails } = props
  const [configData, setConfigData] = useState<any>(
    {
      color: "",
      colorCode: "",
      minValue: null,
      maxValue: null,
      comment: "",
      ThresholdConfigId: 0,
    }
  )

  useEffect(() => {
    if (props?.editDetails?.thresholdId && props.openMode === 'edit') {
      const { editDetails } = props
      setConfigData({
        color: editDetails.color,
        colorCode: editDetails.colorCode,
        minValue: editDetails.minValue,
        maxValue: editDetails.maxValue,
        comment: editDetails.comment,
        ThresholdConfigId: editDetails.thresholdId,
      })

    }

  }, [props])

  const presetColors = [{ colorCode: "#ff0000", color: 'Red' }, { colorCode: "#008000", color: 'Green' }, { colorCode: "#ffff00", color: 'Yellow' }, { colorCode: "#FFA500", color: 'Orange' }, { colorCode: "#0000FF", color: 'Blue' }, { colorCode: "#800080", color: 'Purple' }, { colorCode: "#00FF00", color: 'Lime' }, { colorCode: "#A52A2A", color: 'Brown' }, { colorCode: "#00FFFF", color: 'Cyan' }, { colorCode: "#00008B", color: 'Dark Blue' }, { colorCode: "#800000", color: 'Maroon' }, { colorCode: "#808000", color: 'Olive' }, { colorCode: "#7FFFD4", color: 'Aquamarine' }];

  const onChange = (presetColor: any) => {
    setConfigData({
      ...configData,
      colorCode: presetColor.colorCode,
      color: presetColor.color
    })
  }

  const CancelModal = () => {
    setConfigData({
      age: '',
      color: "",
      colorCode: "",
      minValue: null,
      maxValue: null,
      comment: "",
      ThresholdConfigId: 0
    })
    props.cancelModal()
  }
  const { previousEditDetails, nextEditDetails } = props

  const saveDetails = async () => {
    const test = {
      ...configData,
      prevMin: previousEditDetails?.minValue,
      prevMax: previousEditDetails?.maxValue,
      nextMin: nextEditDetails?.minValue,
      nextMax: nextEditDetails?.maxValue,
      minValue: configData.minValue ? parseInt(configData.minValue) : null,
      maxValue: configData.maxValue ? parseInt(configData.maxValue) : null,
    }

    try {
      const validatedForm = ValidateSchema.parse(test);
      if (validatedForm) {
        setValidationError([])
        await props.saveDetails(configData)
        setConfigData({
          age: '',
          color: "",
          colorCode: "",
          minValue: null,
          maxValue: null,
          comment: "",
          ThresholdConfigId: 0
        })
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        const errors = err.issues
        if (errors) {
          setValidationError(errors)
        }
      }
    }
  }

  const renderError = (element: string) => {
    if (validationError && validationError.length) {
      const elementValue = element.charAt(0).toLowerCase() + element.slice(1)
      const errorText = validationError.find((e: any) => e.path[0] === elementValue)?.message
      if (errorText) {
        return `*${errorText}`
      }
      else {
        return null
      }
    }
    else return null
  }

  return (
    <ConfigModalWrapper>
      <ConfiglHeaderContainer>
        <WDStyledMainHeading>{props.title}</WDStyledMainHeading>
        <CancelSendButtonWrapper>
          <CancelConfiglModal>
            <WDStyledButtonText>
              <Label onClick={() => CancelModal()}>Cancel</Label>
            </WDStyledButtonText>
          </CancelConfiglModal>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => saveDetails()}

          >
            Save
          </Button>
        </CancelSendButtonWrapper>

      </ConfiglHeaderContainer>
      <ConfiglBodyWrapper>
        <StyledSelectWrapper>
          {filterDetails && filterDetails.length ? filterDetails.map((ele: any, key: number) => {
            const selectedValue = props.widgetIds[ele.Label]?.value
            const selectedLabel = ele.Value.find((label: any) => label.Id === selectedValue)
            return <div key={key}>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                {ele.Label}
              </Label>
              <Textbox
                disabled={true}
                placeholder={"Color"}
                value={selectedLabel?.AttributeValue}
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
            </div>
          })
            : null}
          <div>
            <Label
              fontSize="16px"
              fontWeight={400}
              color={'#0F172A'}
              lineHeight={'24px'}
            >
              Color
            </Label>
            <Picker>
              {presetColors.map((presetColor) => {
                let colorDisabled = props.colorsSelected.find((ele) => ele === presetColor.colorCode) ? true : false
                if (presetColor.colorCode === props.editDetails?.colorCode) colorDisabled = false
                return <PresetColor
                  key={presetColor.colorCode}
                  style={{ background: presetColor.colorCode }}
                  active={presetColor.colorCode === configData.colorCode ? true : false}
                  onClick={() => { if (!colorDisabled) onChange(presetColor) }}
                  disabled={colorDisabled}
                />
              })}
            </Picker>
            <Textbox
              disabled={true}
              placeholder={"Color"}
              value={configData.color}
              style={{
                borderRadius: '4px',
                height: '32px',
                padding: '4px 8px',
              }}
            />
            <span style={{ color: 'red' }}>{renderError('color')}</span>
          </div>

          <div>
            <Label
              fontSize="16px"
              fontWeight={400}
              color={'#0F172A'}
              lineHeight={'24px'}
            >MinValue</Label>
            <Textbox
              value={configData.minValue}
              placeholder={"MinValue"}
              style={{
                borderRadius: '4px',
                height: '32px',
                padding: '4px 8px',
                width: '212px'
              }}
              onChange={(e) => {
                if (e.length)
                  setConfigData({ ...configData, minValue: e })
                else
                  setConfigData({ ...configData, minValue: null })
              }}
            />
            <span style={{ color: 'red' }}>{renderError('minValue')}</span>
          </div>
          <div>
            <Label
              fontSize="16px"
              fontWeight={400}
              color={'#0F172A'}
              lineHeight={'24px'}
            >MaxValue</Label>
            <Textbox
              value={configData.maxValue}
              placeholder={"MinValue"}
              style={{
                borderRadius: '4px',
                height: '32px',
                padding: '4px 8px',
                width: '212px'
              }}
              onChange={(e) => {
                if (e.length)
                  setConfigData({ ...configData, maxValue: e })
                else
                  setConfigData({ ...configData, maxValue: null })
              }}
            />
            <span style={{ color: 'red' }}>{renderError('maxValue')}</span>
          </div>
          <div>
            <Label
              fontSize="16px"
              fontWeight={400}
              color={'#0F172A'}
              lineHeight={'24px'}
            >Comment</Label>
            <Textarea
              value={configData.comment}
              onChange={(e: any) => {
                setConfigData({ ...configData, comment: e.target.value })
              }}
              width="440px"
              // height="365px"
              placeholder={'Enter Comment'}
            ></Textarea>
          </div>
        </StyledSelectWrapper>

      </ConfiglBodyWrapper>
    </ConfigModalWrapper>
  )
}

export default ConfigPopUp
