interface dropDownProps {
  value: boolean
  label: string
}
interface tableProps {
  Header: string
  value: boolean
  selectedData: string[]
  showDropdown: boolean
  dropDownArray: dropDownProps[]
  addData: string[]
}

export const ADRTableFilterList: tableProps[] = [
  {
    Header: 'Amount',
    value: true,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [],
  },
  {
    Header: 'Account Number',
    value: true,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [],
  },
  {
    Header: 'Cusip',
    value: true,
    selectedData: [],
    addData: [''],
    showDropdown: false,
    dropDownArray: [],
  },
]
