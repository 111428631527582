/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useTable, useSortBy, usePagination } from 'react-table'
import { useEffect, useState } from 'react'
import {
  BackwardButtonWrapper,
  ColoumnPaddingTop,
  ForwardButtonWrapper,
  GoToPageWrapper,
  NextPriviousWrapper,
  PaginationContainer,
  RootContainerTable,
  SpaceMaker,
} from '../../styles'
import {
  setSorting,
  setSortDirection,
  getP3FailAnalysis,
  sortingSelector,
  sortDirectionSelector,
} from '../../../../../store/failAnalysis/p3-fail-analysis'
import { useDispatch, useSelector } from 'react-redux'
import { LottieWrapper, PopUpStyleLoader } from '../styles'
import Lottie from 'lottie-react'
import Label from '../../../../../components/label/Label'
import Loader from '../../../../../assets/lottiefiles/loader.json'
import { COLORS } from '../../../../../theme/Colors'
import SvgBackwardDoubleChevron from '../../../../../components/svg/SvgBackwardDoubleChevron'
import SvgForwardDoubleChevron from '../../../../../components/svg/SvgForwardDoubleChevron'

const CreateTable = (props: any) => {
  const sortingCol = useSelector(sortingSelector)
  const sortingDir = useSelector(sortDirectionSelector)
  let [sortDirect, setSortDirect] = useState<any>('ASC')
  let [sortBy, setSortBy] = useState<any>('')
  const [popUpLoaderSort, setPopUpLoaderSort] = useState<boolean>(false)
  const [sortAction, setSortAction] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [tempEvent, setTempEvent] = useState<string>('')
  const [pageNumberCall, setPageNumberCall] = useState<any>(1)
  const [pageStatus, setPageStatus] = useState<boolean>(false)
  const [timeoutId, setTimeoutId] = useState()
  let data = props.data
  const hidePagination = props.hidePagination
  const totalRecords = props.totalRecords

  useEffect(() => {
    data = props.data
  }, [sortBy])

  const typeValue = props.typeValue
  const type = props.type
  const startDate = props.startDate
  const endDate = props.endDate
  const age = props.age
  const accNum = props.accNum
  const branch = props.branch
  const entity = props.entity
  const BS = props.BS
  const cusip = props.cusip
  const columns = props.columns
  const includeFailComments = props.includeFailComments
  const checkedValue = props.checkedValue
  let controlledPageCount: number
  controlledPageCount = Math.ceil(totalRecords / 20)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: { pageIndex: 0, pageSize: 20 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  )

  const sorting = async (e: any) => {
    setPopUpLoaderSort(true)
    setTempEvent(e)

    sortBy = e.replace(/_Formatted/g, '')
    setSortBy([...sortBy])
    dispatch(setSorting(sortBy))

    if (sortDirect == 'ASC') {
      setSortDirect('DESC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(true)
    }

    if (sortDirect == 'DESC') {
      setSortDirect('ASC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(false)
    }

    await dispatch(
      getP3FailAnalysis(
        'sort',
        type,
        startDate,
        endDate,
        age,
        accNum,
        branch,
        entity,
        BS,
        cusip,
        pageNumberCall - 1,
        20,
        sortBy,
        sortDirect,
        '',
        includeFailComments,
        typeValue,
        checkedValue
      )
    )
    setPopUpLoaderSort(false)
  }

  const pageCallFunction = async (pageIndex: any, rowRange: any) => {
    setPopUpLoaderSort(true)
    await dispatch(
      getP3FailAnalysis(
        'search',
        type,
        startDate,
        endDate,
        age,
        accNum,
        branch,
        entity,
        BS,
        cusip,
        pageIndex - 1,
        rowRange,
        sortingCol,
        sortingDir,
        '',
        includeFailComments,
        typeValue,
        checkedValue
      )
    )
    setPopUpLoaderSort(false)
  }

  useEffect(() => {
    if (pageStatus) {
      pageCallFunction(pageNumberCall, 20)
    }
  }, [pageNumberCall])

  const onChangeFunction = (pageValue: number) => {
    if (pageValue > 0 && pageValue <= pageCount) {
      const pageNumber = pageValue ? pageValue : 1
      setPageStatus(true)
      clearTimeout(timeoutId)
      const timeout: any = setTimeout(() => {
        gotoPage(pageNumber)
        setPageNumberCall(pageNumber)
      }, 1000)
      setTimeoutId(timeout)
    }
  }

  return (
    <>
      <table {...getTableProps()}>
        {popUpLoaderSort && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <SpaceMaker>
              <Label> Loading...</Label>
            </SpaceMaker>{' '}
          </PopUpStyleLoader>
        )}
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
              {headerGroup.headers.map((column: any, index) => (
                <th
                  key={index}
                  onClick={(e) => {
                    //trigger the react-table header onClick manually
                    column
                      .getHeaderProps(column.getSortByToggleProps())
                      .onClick(e)

                    sorting(column.id)
                  }}
                >
                  {column.render('Header')}

                  <ColoumnPaddingTop>
                    {column.id == tempEvent ? (sortAction ? '🔼' : '🔽 ') : ''}
                  </ColoumnPaddingTop>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {!hidePagination && (
        <PaginationContainer>
          <Label>
            Page{' '}
            <strong>
              {pageNumberCall} of {pageOptions.length}
            </strong>{' '}
          </Label>
          <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
          <GoToPageWrapper>
            <Label>Go to page:</Label>
            <input
              type="number"
              min="1"
              max={pageCount}
              defaultValue={pageNumberCall}
              onChange={(e) => {
                onChangeFunction(Number(e.target.value))
              }}
              style={{ width: '50px' }}
            />
          </GoToPageWrapper>
          <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
          <NextPriviousWrapper>
            <BackwardButtonWrapper
              onClick={() => {
                setPageStatus(true)
                gotoPage(1)
                setPageNumberCall(1)
              }}
            >
              {pageNumberCall !== 1 ? (
                <SvgBackwardDoubleChevron
                  fillColor={`${COLORS.Icons.NeutralIcon}`}
                />
              ) : (
                <SvgBackwardDoubleChevron
                  fillColor={`${COLORS.Background.Neutral40}`}
                />
              )}
            </BackwardButtonWrapper>
            <button
              onClick={() => {
                setPageStatus(true)
                previousPage()
                setPageNumberCall(pageNumberCall - 1)
              }}
              disabled={pageNumberCall === 1}
              style={{
                border: 'none',
                background: '#FCFDFF',
                cursor: 'pointer',
              }}
            >
              Previous
            </button>
            <button
              onClick={() => {
                setPageStatus(true)
                nextPage()
                setPageNumberCall(pageNumberCall + 1)
              }}
              disabled={pageNumberCall === pageCount}
              style={{
                border: 'none',
                background: '#FCFDFF',
                cursor: 'pointer',
              }}
            >
              Next
            </button>
            <ForwardButtonWrapper
              onClick={() => {
                setPageStatus(true)
                gotoPage(pageCount)
                setPageNumberCall(pageCount)
              }}
            >
              {pageNumberCall !== pageCount ? (
                <SvgForwardDoubleChevron
                  fillColor={`${COLORS.Icons.NeutralIcon}`}
                />
              ) : (
                <SvgForwardDoubleChevron
                  fillColor={`${COLORS.Background.Neutral40}`}
                />
              )}
            </ForwardButtonWrapper>
          </NextPriviousWrapper>
        </PaginationContainer>
      )}
    </>
  )
}

const P3Table = (props: any) => {
  return (
    <RootContainerTable>
      <CreateTable
        typeValue={props.typeValue}
        type={props.type}
        startDate={props.startDate}
        endDate={props.endDate}
        age={props.age}
        accNum={props.accNum}
        branch={props.branch}
        entity={props.entity}
        BS={props.BS}
        cusip={props.cusip}
        data={props.data}
        columns={props.columns}
        totalRecords={props.totalRecords}
        includeFailComments={props.includeFailComments}
        hidePagination={props.hidePagination}
        checkedValue={props.checkedValue}
      />
    </RootContainerTable>
  )
}

export default P3Table
