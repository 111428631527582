import { GraphBorderColors, GraphColors, LottieWrapper, SettlementJobRoot, TitleContainer } from './styles'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import Label from '../../../label/Label'
import { useDispatch, useSelector } from 'react-redux'
import {
  SettlementJobSelector,
  getSettlementJob,
  isLoadingSelector,
} from '../../../../store/dash-board/jamsJob'
import { useNavigate } from 'react-router-dom'
import PieChart from '../../../pieChart/PieChart'
import WidgetHeader from './widgetHeader'

const SettlementJobs = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const settlementJobDetails = useSelector(SettlementJobSelector)
  const [name, setName] = useState<any>([])
  const [quantity, setQuantity] = useState<any>([])
  const loading = useSelector(isLoadingSelector)
  const [dateValue, setDate] = useState<any>(
    new Date().toISOString().split('T')[0]
  )

  const renderStatus = (statusValue: string) => {
    switch (statusValue) {
      case 'C':
        return 'Completed'
      case 'R':
        return 'Running'
      case 'F':
        return 'Failed'
    }
  }

  useEffect(() => {
    const tempName: any = []
    const tempQuantity: any = []
    settlementJobDetails.modulesViewModel &&
      settlementJobDetails.modulesViewModel.map((value: any) => {
        tempQuantity.push(value.count)
      })
    settlementJobDetails.modulesViewModel &&
      settlementJobDetails.modulesViewModel.map((value: any) => {
        if (tempQuantity.some((el: number) => el > 0))
          tempName.push(renderStatus(value.status))
      })

    setName(tempName)
    setQuantity(tempQuantity)
  }, [settlementJobDetails.modulesViewModel])

  useEffect(() => {
    const getSettlementJobDetails = async () => {
      await dispatch(getSettlementJob(dateValue))
    }
    getSettlementJobDetails()
  }, [])


  return (
    <>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <SettlementJobRoot>
        <WidgetHeader
          setDate={setDate}
          getDetails={getSettlementJob}
          widgetDescription={props.widgetDescription}
        />
        {name.length ? (
          <PieChart
            legendLabels={name}
            title={'BetaContra'}
            dataValues={quantity}
            bgColors={GraphColors}
            borderColors={GraphBorderColors}
            borderWidth={0}
          />
        ) : (
          <span>No Data Found</span>
        )}
      </SettlementJobRoot>
    </>
  )
}

export default SettlementJobs
