import { useState } from 'react'
import { TextareaProps, Wrapper, StyledTextarea } from './styles'

export const Textarea = ({
  placeholder,
  onChange,
  value,
  ref,
  defaultValue,
  onBlur,
  border,
  disabled,
  required,
  name,
  width,
  height,
  padding,
  autoFocus,
  style
}: TextareaProps) => {
  const [inputValue, setInputValue] = useState('')

  

  return (
    <Wrapper>
      <StyledTextarea
      style={style}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        defaultValue={defaultValue}
        padding={padding}
        height={height}
        width={width}
        name={name}
        value={value}
        border={border}
        disabled={disabled}
        required={required}
        autoFocus={autoFocus}
      />
    </Wrapper>
  )
}
