import { z } from 'zod'

export const ValidateBuyInProductSchema = z.object({
  secType: z.string().regex(/^[a-zA-Z0-9]$/, {
    message: 'Invalid sec type. Enter single character alphanumeric value',
  }),
  description: z
    .string()
    .regex(/^[a-zA-Z]+$/, { message: 'Invalid description' }),
  assetType: z.string().regex(/^[a-zA-Z]+$/, { message: 'Invalid asset type' }),

  dTCCSecType: z
    .string()
    .regex(/^[a-zA-Z]+$/, { message: 'Invalid DTCC sec type' }),
    sdBusinessDays: z
    .string()
    .regex(/^[0-9]+$/, { message: 'Invalid SD business days' }),
})
