/* eslint-disable @typescript-eslint/no-explicit-any */
import Select, {
  CSSObjectWithLabel,
  IndicatorsContainerProps,
} from 'react-select'
import { BorderColors } from '../../shared/styles'
import { SelectDropdownProps, Wrapper } from './styles'

const SelectDropdown = ({
  onChange,
  onBlur,
  defaultValue,
  value,
  isMulti,
  borderRadius,
  options,
  isDisabled,
  isSearchable,
  indicatorSeparator,
  width,
  height,
  name,
  placeholder,
  innerRef,
  border,
  color,
  buttonWidth,
  autoFocus
}: SelectDropdownProps) => {
  const handleChange = (newValue: any) => {
    const inputValue = newValue.value
    onChange && onChange(inputValue)
  }

  const customStyles = {
    control: (provided: CSSObjectWithLabel) => {
      return {
        ...provided,
        width: buttonWidth?buttonWidth:width,
        height: height,
        minHeight: height,
        borderRadius: borderRadius,
        // borderColor: '#CBD5E1',
        border: border ? border : '1px solid ' + BorderColors.Gray50,
        color: color ? color : '#475569'
      }
    },
    placeholder: (provided: CSSObjectWithLabel) => {
      return {
        ...provided,
        color: '#475569',
      }
    },
    singleValue: (provided: CSSObjectWithLabel) => {
      return {
        ...provided,
        color: '#475569',
      }
    },
    indicatorContainer: (provided: IndicatorsContainerProps) => {
      return {
        ...provided,
        color: '#475569',
      }
    },
    dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: '#475569', // Custom colour
    }),
  }

  return (
    <>
      {!indicatorSeparator ? (
        <Wrapper width={width}>
          <Select
            options={options}
            onChange={handleChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            components={{
              IndicatorSeparator: () => null,
            }}
            name={name}
            value={value}
            styles={customStyles}
            placeholder={placeholder}
            ref={innerRef}
            autoFocus={autoFocus}
          />
        </Wrapper>
      ) : (
        <Wrapper width={width}>
          <Select
            options={options}
            onChange={handleChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            isMulti={isMulti}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            name={name}
            value={value}
            styles={customStyles}
            placeholder={placeholder}
            ref={innerRef}

          />
        </Wrapper>
      )}
    </>
  )
}

export default SelectDropdown
