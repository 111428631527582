import styled from 'styled-components'
import { COLORS } from '../../../../theme/Colors'

export const RootContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  // background: ${COLORS.Background.Secondary};
  //background: #e5e5e5;
`
export const HeadingDownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
`
export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: end;
  width: 5%;
  justify-content: flex-end;
`
export const DownloadButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`
export const CancelButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  // background: ${COLORS.Background.Secondary};
 // background: #e5e5e5;
  margin-top: 12px;
  margin-bottom: 24px;
`
export const FilterCard = styled.div`
  width: 57%;
  border: 1px solid ${COLORS.Background.StrongBackgound};
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  margin-right: 1.5%;
  padding: 12px 25px;
`
export const FailSummaryCard = styled.div`
  padding: 12px 25px;
  width: 41%;
  height  348px;
  background: ${COLORS.Background.Primary};
  border: 1px solid ${COLORS.Background.StrongBackgound};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const StyledTableFailSummary = styled.div`
  margin-bottom: 10px;
  width: 100%;
  padding: 24px 0px 0px 0px;
`
export const SubHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: ${COLORS.Text.Neutral};
`
export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 12px 0px 12px 0px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${COLORS.Background.Primary};
`

export const FilterOptionWrapper = styled.div`
  width: 30%;
`
export const StyledTableContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  // background: ${COLORS.Background.BlurBackground};
  border: 1px solid ${COLORS.UI.Gray};
 // background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`

export const PopupCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 16px;
  gap: 8px;

  position: absolute;
  width: 397px;
  height: 172px;

  /* neutral/background */

  background: ${COLORS.Background.Primary};
  /* Elevation/Elevation-low */

  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const PopUpStyleLoader = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 16px;
  }
`
export const PageNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  > * {
    margin-right: 16px;
  }
`
export const LottieWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const LodingTable = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 28px 0 28px 0;
`
export const PopUpStyleContent = styled.div`
  background: rgba(100, 116, 139, 0.75);
  position: fixed;
  height: 40%;
  width: 60%;
  top: 30%;
  left: 20%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px 10px 15px;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
`
export const CommentTableContainer = styled.div`
  width: 100%;
  padding: 24px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  overflow-y: scroll;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`
export const HeadingWrapper = styled.div`
  width: 90%;
`
export const RadioCommentWrapper = styled.div``

export const MarginLeftAutoSpace = styled.div`
  margin-left: auto;
`
export const LoadingTextSpace = styled.div`
  margin-left: 10px;
`
