/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { protectedResources } from '../../utils/authConfig'
import { useAccount, useMsal } from '@azure/msal-react'
import { authApiCall } from '../../utils/api'
import { LottieWrapper, PopUpStyleLoader } from '../tradeRecon/styles'
import Lottie from 'lottie-react'
import Loader from '../../assets/lottiefiles/loader.json'
import Label from '../../components/label/Label'
import {
  DashboardContainer,
  HelpDocContainer,
  IDTokenInterface,
  RolesInterface,
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSubHeader,
} from './styles'
import WidgetCreator from './widgetCreator'
import { useDispatch } from 'react-redux'
import {
  getHoliDay,
  setFormateDate,
  setIntraDayMinDate,
  userActivity,
} from '../../store/dash-board/about'
import HoliDay from '../../utils/holiday'
import { AnchorTag } from '../../components/anchorTag/AnchorTag'
import { Colors } from '../../shared/styles'
import { SvgDownloadSmall } from '../../components/svg/logo/SvgDownload'
import { browserName, browserVersion } from 'react-device-detect'
import {formatISO} from 'date-fns'
import { useNavigate } from 'react-router-dom'

const AboutPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { instance, accounts, inProgress } = useMsal()
  const [loading, isLoading] = useState<boolean>(true)
  const account: any = useAccount(accounts[0] || {})
  const [menuItems, setMenuItems] = useState<any>([])
  

  const getDashboardData = async () => {
    const role =localStorage.getItem('roleName') ?JSON.parse(localStorage.getItem('roleName')??''): ''
    const aboutArray = [
      'Trade Reconciliation',
      'Settlement',
      'Static Data',
      'Asset Servicing',
      'Operations Control',
      'Regulatory Operations',
    ]

    if (role?.length > 0) {

      try {
        const { data, status } = await authApiCall.post(
          'opsdashboardapi/OpsDashboard/MenuDetails',
          {
            roleNames: role.map((item:string) => 
              item.split('_')[0].split('-')[0]),
          }
        )
        if (status === 200) {
          localStorage.setItem('MenuItems', JSON.stringify(data.menuList))
          setMenuItems(
            data.menuList.filter((ele: { content: string }) => {
              return aboutArray?.includes(ele.content)
            })
          )
          isLoading(false)
        }
      } catch (e) {
        isLoading(false)
      }
    } else {
      navigate('/error-page')
        isLoading(false)
      
    }
  }

  useEffect(() => {
    const loginFlag = sessionStorage.getItem('login_flag')
    loginFlag == null && sessionStorage.setItem('login_flag', 'false')

    if (account && inProgress === 'none') {
      localStorage.setItem('AcctInfo', JSON.stringify(account))
      instance
        .acquireTokenSilent({
          scopes: protectedResources.api.scopes,
          account: account,
        })

        .then(async (response) => {
          const idToken = response.idTokenClaims as IDTokenInterface
          const givenRoles = JSON.parse(idToken.roles.toString())
          const token = response.accessToken
          const account: any = response.account?.name
          const userId: any = response.account?.idTokenClaims?.Username
          const AccountIcon = account.charAt(0)

          localStorage.setItem('roleName', JSON.stringify(givenRoles.SegRoles))
          localStorage.setItem('entitlements', JSON.stringify(givenRoles.Entitlements))
          localStorage.setItem('AccountIcon', AccountIcon)
          localStorage.setItem('AccountName', account)
          localStorage.setItem('AccessToken', token)
          localStorage.setItem('UserId', userId.split('@')[0])

          if (loginFlag == 'false') {
            const response = dispatch(
              userActivity(
                userId,
                new Date().toLocaleTimeString(),
                new Date().toISOString().split('T')[0],
                new Date()
                  .toLocaleDateString('en-US', {
                    day: '2-digit',
                    timeZoneName: 'short',
                  })
                  .slice(4),
                browserName + ' Ver.' + browserVersion
              )
            )
            sessionStorage.setItem('login_flag', 'true')
          }
          await getDashboardData()
          async function HolidayData() {
            const AllHoliDays = await dispatch(getHoliDay())
            localStorage.setItem(
              'DefaultDate',
              JSON.stringify(AllHoliDays.recentTradeDate)
            )
            const response: [] = await HoliDay(AllHoliDays.lstHolidays)
            await dispatch(setFormateDate(response))
            await dispatch(setIntraDayMinDate(formatISO(new Date(AllHoliDays.intradayMinDate||new Date() ))?.split('T')[0]))
          }
          HolidayData()
        })
    }
  }, [account, inProgress, instance])

  const token = localStorage.getItem('AccessToken')

  return (
    <RootContainer>
      {loading && (
        <PopUpStyleLoader>
          {' '}
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>Operations Hub</StyledPanelHeadTitle>
        <StyledPanelSubHeader>
          Centralized workspace for operational activities
        </StyledPanelSubHeader>
      </StyledPanelHead>
      <HelpDocContainer>
        <AnchorTag
          href={'/OPSDashboard.pdf'}
          download={true}
          title={'HelpDoc'}
          color={Colors.PrimaryText}
          textDecoration={'none'}
          target={'_blank'}
          icon={<SvgDownloadSmall fillColor={Colors.PrimaryText} />}
        />
      </HelpDocContainer>
      <DashboardContainer>
        {token && menuItems?.map((widget: any) => WidgetCreator(widget))}
      </DashboardContainer>
    </RootContainer>
  )
}

export default AboutPage
