import styled from 'styled-components'
import { ReactNode } from 'react'
interface InputFunc {
  (value: string): void | string | boolean | number
}
export interface RadioProps {
  label?: string | ReactNode
  name?: string
  checkBoxId?: string
  value?: string | boolean | number
  disabled?: boolean
  checked?: boolean
  defaultChecked?: boolean
  indeterminate?: boolean
  onChange?: InputFunc | void
  required?: boolean
}

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 8px;
  width: 16px;
  height: 24px;
`

export const RadioButton = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 8px;
`

export const StyledLabel = styled.div<RadioProps>`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props: RadioProps) => (props.disabled ? '#A7AFBC' : '#0F172A')};
`

export const RadioButtonLabel = styled.span`
  position: relative;
  align-self: center;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 1000px;

  &::after {
    content: '';
    display: none;
  }

  &::after {
    position: absolute;
    width: 8px;
    height: 8px;
    left: calc(50% - 8px / 2);
    top: calc(50% - 8px / 2);
    background: #ffffff;
    border-radius: 100px;
  }
`

export const Main = styled.input`
  accent-color: #2563eb;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;

  &:checked ~ ${RadioButtonLabel} {
    background-color: #2563eb;
  }

  &:checked ~ ${RadioButtonLabel}:after {
    display: block;
  }
`