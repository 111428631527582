import React, { ReactEventHandler, useState } from 'react'
import Label from '../../components/label/Label'

import {
  RadioProps,
  Main,
  Wrapper,
  RadioButton,
  StyledLabel,
  RadioButtonLabel,
} from './styles'

export const Radio = ({
  name,
  checkBoxId,
  value,
  disabled,
  checked,
  defaultChecked,
  indeterminate,
  onChange,
  required,
  label,
}: RadioProps) => {
  return (
    <RadioButton>
      <Wrapper>
        <Main
          type={'radio'}
          id={checkBoxId}
          value={value}
          disabled={disabled}
          checked={checked}
          defaultChecked={defaultChecked}
          indeterminate={indeterminate}
          onChange={onChange}
          name={name}
          required={required}
        ></Main>
        <RadioButtonLabel />
      </Wrapper>
      {label && <StyledLabel htmlFor={checkBoxId}>{label}</StyledLabel>}
    </RadioButton>
  )
}