import { useEffect, useState } from 'react'
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  PopUpStyleLoader,
  StyledSelectWrapper,
  LottieWrapper,
  ToastWrapper,
} from '../SettlementCommonStyles'
import BetaSecLendingSearchPanel from './BetaSecLendingSearchPanel'
import BetaSecLendingDetails from './BetaSecLendingDetails'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  BetaSecLendingSelector,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  loadingSelector,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../store/betaSecLending/beta-sec-lending'
import { Toast } from '../../../components/toast/Toast'

export interface IBetaSecLendingSearchFilters {
  fromDate: string
  toDate: string
  accountNumber: string
}

const date = new Date()
date.setDate(date.getDate() - 1)
const isoStringDate = date.toISOString()
const splitDate = isoStringDate.split('T')[0]

const BetaSecLendingPage = () => {
  const InitialFilterParams = {
    fromDate: splitDate,
    toDate: splitDate,
    accountNumber: '',
    securityNumber: '',
  }

  const [searchParams, setSearchParams] = useState({ ...InitialFilterParams })
  const [searchFlag, setSearchFlag] = useState({ ...InitialFilterParams })
  const BetaSecLendingData = useSelector(BetaSecLendingSelector)
  const Loading = useSelector(loadingSelector)

  const dispatch = useDispatch()
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)

  return (
    <RootContainer>
      {Loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>
          Beta Securities Lending Monitoring Report
        </StyledPanelHeadTitle>
      </StyledPanelHead>
      <BetaSecLendingSearchPanel
        searchParams={searchParams}
        setsearchParams={setSearchParams}
        setSearchFlag={setSearchFlag}
      />
      <BetaSecLendingDetails
        totalCount={
          BetaSecLendingData?.totalCount ? BetaSecLendingData?.totalCount : 0
        }
        data={
          BetaSecLendingData?.betaSecLendingModel?.length
            ? BetaSecLendingData.betaSecLendingModel
            : []
        }
      />
      {(SuccessMessage?.length > 0 ||
        ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success':'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default BetaSecLendingPage
