/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import SvgVerticalDivider from '../../../components/svg/SvgVerticalDivider'
import {
  WDStyledButtonText,
  WDStyledHeading,
} from '../../../components/ui/WDTypography'
import { COLORS } from '../../../theme/Colors'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  ApplyResetButtonWrapper,
  ButtonIconWrapper,
  ButtonWrapper,
  DateWrapper,
  FileUploadInput,
  FileUploadPopUpStyleWrapper,
  FilterContainer,
  FirstContainer,
  LeftSpace,
  LottieWrapper,
  MarginLeftAutoSpace,
  MarginTopSpace,
  MonthPickerLabelWrapper,
  PopUpStyleLoader,
  RefreshDownloadWrapper,
  RootContainer,
  RowWrapper,
  SecondContainer,
  StyledSelectWrapper,
  SvgButtonWrapper,
  ThirdContainer,
  ToastWrapper,
  UploadWrapper,
} from './styles'
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'
import { WDButton, WDButtonDisabled } from '../../../components/ui/WDButton'
import ADRTableFilter from './adrTableFilter/ADRTableFilter'
import SvgRedo from '../../../components/svg/SvgRedo'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import ADRFeesUploadColumn from './ADRFeesUploadColumn'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import { Backgrounds } from '../../../shared/styles'
import { WDLabel } from '../../../components/ui/WDLabel'
import { useDispatch, useSelector } from 'react-redux'
import {
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  LoadingSelector,
  SetADRFeeFileUploadData,
  TotalCountSelectorADRFee,
  actionSelector,
  csvFileUpload,
  dataSelector,
  getADRFeeFileUploadData,
  getADRFeeFileUploadDataCSV,
  setAction,
  setHttpErrorMessage,
  setPageNumberADRFee,
  setSortDirection,
  setSorting,
  setSuccessMessage,
  sortDirectionSelectorADRFee,
  sortingSelectorADRFee,
} from '../../../store/asset-Servicing/ADR-Fee-Upload-Report'
import { Toast } from '../../../components/toast/Toast'
import { ADRTableFilterList } from './adrTableFilter/ADRTableFilterData'
import Lottie from 'lottie-react'
import { useNavigate } from 'react-router-dom'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { getAccessFlag } from '../../../utils/globalFunctions'

const EXTENSIONS = ['csv']

const ADRFeesUpload = () => {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const sorting = useSelector(sortingSelectorADRFee)
  const sortDirection = useSelector(sortDirectionSelectorADRFee)
  const TotalCount = useSelector(TotalCountSelectorADRFee)
  const action = useSelector(actionSelector)
  const data = useSelector(dataSelector)
  const loading = useSelector(LoadingSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [startDate, setStartDate] = useState(new Date())
  const [accountType, setAccountType] = useState<string>('all')
  const [resetState, setResetState] = useState<boolean>(false)
  const [fileUploadPopup, setFileUploadPopup] = useState<boolean>(false)
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [fileName, setFileName] = useState('')
  const [fileData, setFileData] = useState<any>()
  const [resetTable, setResetTable] = useState<boolean>(false)
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [toggle, setToggle] = useState<boolean>(false)
  const fileUpload = useRef<HTMLInputElement>(null)
  const accountTypeOption = [
    { label: 'All (Inventory & NonInventory)', value: 'all' },
    {
      label: 'Customer (Non Inventory) Accounts',
      value: 'noninventoryaccount',
    },
    { label: 'Inventory Accounts', value: 'inventoryaccount' },
  ]
  // const [requestSubmit, setrequestSubmit] = useState<{
  //   status: boolean | null
  //   text: string
  // }>({
  //   status: null,
  //   text: '',
  // })

  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  const lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)

  let mm: string | number
  let firstDayOfMonth: string | number
  let lastDayOfMonth: string | number

  mm = startDate.getMonth() + 1
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  lastDayOfMonth = lastDay.getDate()
  lastDayOfMonth = '' + lastDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth
  const toDate = yyyy + '-' + mm + '-' + lastDayOfMonth

  const current = new Date()
  const monthNames = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]
  const monthYear = monthNames[startDate.getMonth()] + startDate.getFullYear()

  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)

  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['AS-RWX', 'OM-RWX']))
  }, [])

  const getExtension = (file: File) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    return EXTENSIONS.includes(extension)
  }

  const handleDrop = (uploadedFile: File) => {
    setFileName(uploadedFile.name)
    if (getExtension(uploadedFile)) {
      setIsFileUploaded(true)
      const reader = new FileReader()
      reader.readAsBinaryString(uploadedFile)
      setFileData(uploadedFile)
    } else {
      setIsFileUploaded(false)
    }
  }

  const preventDefaults = (event: BaseSyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDropFile = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer.files
    handleDrop(files[0])
  }

  const handleFiles = (event: BaseSyntheticEvent) => {
    handleDrop(event.target.files[0])
  }

  const browseFile = () => {
    fileUpload.current?.click()
  }

  const handleFileUpload = async () => {
    const response = await dispatch(csvFileUpload(fileData))
    setFileUploadPopup(false)
    const uploadElement = document.getElementById(
      'fileElement'
    ) as HTMLInputElement
    uploadElement.value = ''
    setIsFileUploaded(false)
    // if (response === 200) {
    //   setrequestSubmit({
    //     status: true,
    //     text: 'File uploaded successfully',
    //   })
    // }
    // if (response === 400) {
    //   setrequestSubmit({
    //     status: false,
    //     text: 'Sorry, Something went wrong',
    //   })
    // }
  }

  const handleSubmit = async () => {
    setResetTable(true)
    dispatch(setPageNumberADRFee(0))
    ADRTableFilterList.forEach((obj) => {
      obj.showDropdown = false
    })
    setIsTableVisible(false)

    const amount = ADRTableFilterList[0].selectedData.join(';').toString()
    const accT_NBR = ADRTableFilterList[1].selectedData.join(';').toString()
    const cusip = ADRTableFilterList[2].selectedData.join(';').toString()

    const response = await dispatch(
      getADRFeeFileUploadData(
        'search',
        fromDate,
        toDate,
        0,
        20,
        sorting,
        sortDirection,
        0,
        amount,
        accT_NBR,
        cusip,
        accountType
      )
    )
    if (response == 200) {
      setIsTableVisible(true)
    } else {
      setResetTable(false)
      // setrequestSubmit({
      //   status: false,
      //   text: 'Sorry, Something went wrong',
      // })
    }
  }

  const DownloadCSV = async () => {
    const response = await dispatch(
      getADRFeeFileUploadDataCSV(
        `ADRFeeFileUploadReport_${monthYear}`,
        fromDate,
        toDate,
        0,
        0,
        sorting,
        sortDirection,
        0,
        '',
        '',
        '',
        accountType
      )
    )
    // if (response === 200) {
    //   setrequestSubmit({
    //     status: true,
    //     text: 'File Downloaded',
    //   })
    // }
    // if (response === 400) {
    //   setrequestSubmit({
    //     status: false,
    //     text: 'Sorry, Something went wrong',
    //   })
    // }
  }

  const resetData = () => {
    ADRTableFilterList.forEach((obj) => {
      obj.selectedData = []
      obj.addData = ['']

      if (
        (obj.Header == 'Status' || obj.Header == 'Depository') &&
        obj.dropDownArray.length > 0
      ) {
        obj.dropDownArray.forEach((dropDowns) => {
          dropDowns.value = true
        })
      }
    })
    if (resetTable) {
      handleSubmit()
    }
    setToggle(!toggle)
  }

  const checkResetStatus = (data: any) => {
    if (data) {
      setResetState(true)
    } else {
      setResetState(false)
    }
  }

  useEffect(() => {
    setResetTable(false)
    dispatch(SetADRFeeFileUploadData([]))
    dispatch(setAction(''))
    dispatch(setSortDirection('ASC'))
    dispatch(setSorting('Cusip'))
    resetData()
  }, [])

  return (
    <RootContainer>
      <WDStyledHeading>{'ADR Fee Report'}</WDStyledHeading>
      <FilterContainer>
        <FirstContainer>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'20px'}
            hover={true}
            type={'button'}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              //
            }}
          >
            P3
          </Button>
          <LeftSpace></LeftSpace>
          <SvgVerticalDivider
            fillColor={COLORS.Background.Neutral40}
          ></SvgVerticalDivider>
          <LeftSpace></LeftSpace>
          <MonthPickerLabelWrapper>
            <Label>Select Month</Label>
            <DateWrapper>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                startDate={startDate}
                maxDate={current}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </DateWrapper>
          </MonthPickerLabelWrapper>
          <LeftSpace></LeftSpace>
          <MonthPickerLabelWrapper>
            <Label>Account Range</Label>
            <SelectDropdown
              defaultValue={{
                label: 'All (both Inventory & NonInventory)',
                value: 'all',
              }}
              width="250px"
              borderRadius={'4px'}
              height="32px"
              options={accountTypeOption}
              onChange={(e) => {
                setAccountType(e)
              }}
            ></SelectDropdown>
          </MonthPickerLabelWrapper>
          <LeftSpace>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              bgColor={'transparent'}
              disabled={!writeExecuteFlag}
              onClick={() => {
                setFileUploadPopup(true)
              }}
            >
              File Upload
            </Button>
          </LeftSpace>
          {fileUploadPopup && (
            <FileUploadPopUpStyleWrapper>
              <StyledSelectWrapper
                style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
              >
                File Upload
                <MarginLeftAutoSpace>
                  <SvgButtonWrapper
                    onClick={() => {
                      const uploadElement = document.getElementById(
                        'fileElement'
                      ) as HTMLInputElement
                      uploadElement.value = ''
                      setIsFileUploaded(false)
                      setFileUploadPopup(false)
                    }}
                  >
                    <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                  </SvgButtonWrapper>
                </MarginLeftAutoSpace>
              </StyledSelectWrapper>
              <Divider
                thickness={'1px'}
                horizontal={true}
                bgColor={Backgrounds.Gray110}
              />
              <UploadWrapper
                id="drop-area"
                onDragEnter={preventDefaults}
                onDragOver={preventDefaults}
                onDragLeave={preventDefaults}
                onDrop={handleDropFile}
                className={isFileUploaded ? 'file-uploaded' : ''}
              >
                <FileUploadInput
                  type="file"
                  id="fileElement"
                  ref={fileUpload}
                  multiple
                  accept=".csv"
                  hidden
                  onChange={handleFiles}
                ></FileUploadInput>
                {isFileUploaded ? (
                  <RowWrapper>
                    <WDLabel>
                      <Label>{fileName}</Label>
                    </WDLabel>
                  </RowWrapper>
                ) : (
                  <RowWrapper>
                    <WDLabel>
                      <Label>
                        {'Drag your file here to start uploading or'}
                      </Label>
                    </WDLabel>
                    <Button
                      hoverBgColor={COLORS.Background.Primary70}
                      bgColor={COLORS.Background.Primarytext}
                      color={COLORS.Background.Primary}
                      padding={'4px 16px'}
                      borderRadius={'4px'}
                      hover={true}
                      type={''}
                      activeBgColor={COLORS.Background.Primaryactive}
                      borderColor={'transparent'}
                      onClick={browseFile}
                    >
                      Browse files
                    </Button>
                  </RowWrapper>
                )}
              </UploadWrapper>
              Max file size is 10mb.Supported file types are .csv
              <MarginLeftAutoSpace>
                <ButtonWrapper>
                  <Button
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    type={'button'}
                    color={COLORS.Background.Primarytext}
                    style={{ whiteSpace: 'nowrap' }}
                    bgColor={'transparent'}
                    onClick={() => {
                      setFileUploadPopup(false)
                      const uploadElement = document.getElementById(
                        'fileElement'
                      ) as HTMLInputElement
                      uploadElement.value = ''
                      setIsFileUploaded(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    hoverBgColor={COLORS.Background.Primary70}
                    bgColor={COLORS.Background.Primarytext}
                    color={COLORS.Background.Primary}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    hover={true}
                    type={''}
                    activeBgColor={COLORS.Background.Primaryactive}
                    borderColor={'transparent'}
                    onClick={() => {
                      handleFileUpload()
                    }}
                  >
                    Upload
                  </Button>
                </ButtonWrapper>
              </MarginLeftAutoSpace>
            </FileUploadPopUpStyleWrapper>
          )}
          <LeftSpace>
            <Button
              padding={'4px 16px'}
              borderRadius={'20px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                navigation('/ADRPastFileUpload')
              }}
            >
              View Past Upload
            </Button>
          </LeftSpace>
          <MarginLeftAutoSpace>
            <ButtonIconWrapper
              onClick={() => {
                DownloadCSV()
              }}
            >
              <SvgDownloadSmall
                fillColor={`${COLORS.UI.Primary50}`}
              ></SvgDownloadSmall>

              <WDStyledButtonText>
                <Label>CSV Download</Label>
              </WDStyledButtonText>
            </ButtonIconWrapper>
          </MarginLeftAutoSpace>
        </FirstContainer>
        <SecondContainer>
          <ADRTableFilter checkResetStatus={checkResetStatus}></ADRTableFilter>
          <ApplyResetButtonWrapper>
            <Button
              hoverBgColor={COLORS.Background.Primary70}
              bgColor={COLORS.Background.Primarytext}
              color={COLORS.Background.Primary}
              padding={'4px 16px'}
              borderRadius={'4px'}
              hover={true}
              type={''}
              activeBgColor={COLORS.Background.Primaryactive}
              borderColor={'transparent'}
              onClick={() => {
                handleSubmit()
              }}
            >
              Apply
            </Button>
            <LeftSpace>
              {resetState ? (
                <WDButton>
                  <Button
                    type="button"
                    color={''}
                    onClick={() => {
                      resetData()
                      checkResetStatus(false)
                    }}
                  >
                    {'Reset'}
                  </Button>
                </WDButton>
              ) : (
                <WDButtonDisabled>
                  <Button type="button" color={''}>
                    {'Reset'}
                  </Button>
                </WDButtonDisabled>
              )}
            </LeftSpace>
          </ApplyResetButtonWrapper>
        </SecondContainer>
      </FilterContainer>
      {resetTable && (action === 'search' || action === 'sort') && (
        <>
          <MarginTopSpace>
            <WDCard>
              <CardHeader
                label={'ADR Fee Report'}
                icon={false}
                amountValue={`Total: ${TotalCount.data}`}
              />
            </WDCard>
          </MarginTopSpace>
          <ADRFeesUploadColumn
            accountType={accountType}
            fromDate={fromDate}
            toDate={toDate}
            data={data.data ? data.data : []}
            totalRecords={TotalCount.data}
          />
        </>
      )}
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default ADRFeesUpload
