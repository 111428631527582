/* eslint-disable jsx-a11y/anchor-is-valid */
import { ColumnInstance, Row } from 'react-table'
import {
  BuyInExecutionInput,
  BuyInExecutionButton,
} from '../buyInCommonComponents'
import {
  IExecutionUserInputs,
  commonTabProps,
} from '../IBuyInAllocationInterface'
import { DisabledFunction } from '../../utils'
import { BuyInAllocationTabDetails } from '../../RegSHOModels'
import BrokerL3Content from '../broker/brokerBuyInL3Content'
import StockLoanL3Content from './stockLoanBuyInL3Page'
import { sethttpErrorMessageAllocation } from '../../../../store/reg-SHO-Tool/reg-sho-buyInAllocation'
import { useDispatch } from 'react-redux'
type SetValue = React.Dispatch<React.SetStateAction<IExecutionUserInputs[]>>
interface RegSHOCustomColumnInstance<D extends object = {}>
  extends ColumnInstance<D> {
  objectKey?: string
}
interface ICell<D extends object = {}> {
  column: RegSHOCustomColumnInstance
  row: Row<any>
}

export const useGetStockLoanSpecificL2Column = (
  accessFlag: boolean,
  value: any,
  setValue: SetValue,
  UpdateBuyInExeCution: any,
  DeleteL2BuyInExeCution: any,
  AuditL2BuyInInformation: any,
  commonTabProps: commonTabProps,
  setActiveTab: React.Dispatch<any>,
  setCommonTabProps: React.Dispatch<any>
) => {
  const dispatch = useDispatch()
  return [
    {
      Header: ' ',
      columns: [
        {
          Header: 'CUSIP',
          accessor: 'cusiP_Number',
        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
        },
        {
          Header: 'Type',
          accessor: 'type',
        },
        {
          Header: 'T+N',
          accessor: 'tn',
        },
        {
          Header: 'BorrowDesc',
          accessor: 'borrowDesc',
        },
        {
          Header: 'ThresholdInd',
          accessor: 'thresholdIndex',
        },
        {
          Header: 'FailQty',
          accessor: 'failQTY',
        },
        {
          Header: 'AcctNbr',
          objectKey: 'acct_Nbr',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                maxLength={'8'}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'LongShort',
          objectKey: 'longShort',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'TradeNet Quantity',
          objectKey: 'tradeNetQuantity',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'SR_Prior_TD_QTY',
          objectKey: 'sR_Prior_TD_QTY',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'SR_Current_TD_QTY',
          objectKey: 'sR_Current_TD_QTY',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'SR_Net_SD_QTY',
          objectKey: 'sR_Net_SD_QTY',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'CloseOut Status',
          objectKey: 'closeOutStatus',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
      ],
    },
    {
      Header: 'Current Day Activity',
      columns: [
        {
          Header: 'New',
          accessor: 'currentNew',
        },
        {
          Header: 'Made',
          accessor: 'currentMade',
        },
        {
          Header: 'EarlySales ReleaseInd',
          accessor: 'earlySalesReleaseInd',
        },
        {
          Header: 'MISC',
          accessor: 'currentMisc',
        },
        {
          Header: 'NetItemToWorkToday',
          accessor: 'netItemToWorkToday',
        },
      ],
    },
    {
      Header: 'Allocation Details',
      columns: [
        {
          Header: 'StockLoanAllocation',
          accessor: 'stockLoanAllocation',
          Cell: ({ row, column }: ICell) => {
            return (
              <a
                title={
                  value?.current[row.index]?.dateToBuyIn
                    ? 'StockLoan Execution'
                    : 'pls fill mandatory field'
                }
                href="#"
                onClick={() => {
                  setCommonTabProps &&
                    setCommonTabProps({
                      ...commonTabProps,
                      allocationId: row?.original?.allocation_ID,
                      regSHOBuyIn_ID: row?.original?.regSHOBuyIn_ID,
                    })
                  if (value?.current[row.index]?.dateToBuyIn) {
                    setActiveTab &&
                      setActiveTab(
                        BuyInAllocationTabDetails.find(
                          (tab) => tab.component === StockLoanL3Content
                        )?.tabIndex
                      )
                  } else {
                    dispatch(
                      sethttpErrorMessageAllocation('Pls fill mandatory field')
                    )
                  }
                }}
              >
                {row.values[column.id]}
              </a>
            )
          },
        },
        {
          Header: 'Shares Allocated',
          objectKey: 'allocateQTY',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  value?.current[row.index]?.createBy,
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Client/Broker Account',
          accessor: 'allocateAccount',
        },
        {
          Header: 'RecallDate',
          objectKey: 'recalDate',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'date'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Description',
          objectKey: 'description',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'RecallShare',
          objectKey: 'recallAmount',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
      ],
    },
    {
      Header: 'Buy In Information',
      columns: [
        {
          Header: () => (
            <span title="Mandatory field for the L3 navigation link to work">
              Date to Buyin/Execute
              <span style={{ color: 'red' }}>*</span>
            </span>
          ),
          accessor: 'dateToBuyIn',
          objectKey: 'dateToBuyIn',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'date'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Buy In Notice',
          accessor: 'buyInNotice',
          objectKey: 'buyInNotice',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Buyin Ticket #',
          objectKey: 'buyInTicketNumber',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Add Comments',
          objectKey: 'comments',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Audit Trail',
          objectKey: 'audit Trail',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={false}
                parentValue={value}
                label={'Audit Trail'}
                tableId={row.index}
                UpdateBuyInExeCution={AuditL2BuyInInformation}
              />
            )
          },
        },
        {
          Header: 'Update',
          objectKey: 'update',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={
                  accessFlag ||
                  DisabledFunction(
                    value?.current[row.index]?.source,
                    '',
                    value?.current[row.index]?.reportDate,
                    commonTabProps.approveFlag
                  )
                }
                label={'Update'}
                tableId={row.index}
                UpdateBuyInExeCution={UpdateBuyInExeCution}
              />
            )
          },
        },
        {
          Header: 'Delete',
          objectKey: 'delete',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={
                  accessFlag ||
                  DisabledFunction(
                    value?.current[row.index]?.source,
                    '',
                    value?.current[row.index]?.reportDate,
                    commonTabProps.approveFlag
                  )
                }
                parentValue={value}
                label={'Delete'}
                tableId={row.index}
                UpdateBuyInExeCution={DeleteL2BuyInExeCution}
              />
            )
          },
        },
        {
          Header: 'Email Attachment',
          Cell: 'emailAttachment',
        },
      ],
    },
  ]
}
export const getStockLoanSpecificL3Column = (
  accessFlag: boolean,
  value: any,
  setValue: SetValue,
  UpdateBuyInExeCution: any,
  DeleteL3BuyInExeCution: any,
  AuditL3BuyInInformation: any,
  commonTabProps: commonTabProps
) => {
  return [
    {
      Header: ' ',
      columns: [
        {
          Header: 'CUSIP',
          accessor: 'cusiP_Number',
        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
        },
        {
          Header: 'Type',
          accessor: 'type',
        },
        {
          Header: 'T+N',
          accessor: 'tn',
        },
        {
          Header: 'BorrowDesc',
          accessor: 'borrowDesc',
        },
        {
          Header: 'ThresholdInd',
          accessor: 'thresholdIndex',
        },
        {
          Header: 'FailQty',
          accessor: 'failQTY',
        },
        {
          Header: 'AcctNbr',
          accessor: 'acct_Nbr',
        },
        {
          Header: 'LongShort',
          accessor: 'longShort',
        },
        {
          Header: 'TradeNet Quantity',
          accessor: 'tradeNetQuantity',
        },
        {
          Header: 'SR_Prior_TD_QTY',
          accessor: 'sR_Prior_TD_QTY',
        },
        {
          Header: 'SR_Current_TD_QTY',
          accessor: 'sR_Current_TD_QTY',
        },
        {
          Header: 'SR_Net_SD_QTY',
          accessor: 'sR_Net_SD_QTY',
        },
        {
          Header: 'CloseOut Status',
          accessor: 'closeOutStatus',
        },
      ],
    },
    {
      Header: 'Current Day Activity',
      columns: [
        {
          Header: 'New',
          accessor: 'currentNew',
        },
        {
          Header: 'Made',
          accessor: 'currentMade',
        },
        {
          Header: 'EarlySales ReleaseInd',
          accessor: 'earlySalesReleaseInd',
        },
        {
          Header: 'MISC',
          accessor: 'currentMisc',
        },
        {
          Header: 'NetItemToWorkToday',
          accessor: 'netItemToWorkToday',
        },
      ],
    },
    {
      Header: 'Allocation Details',
      columns: [
        {
          Header: 'StockLoanAllocation',
          accessor: 'stockLoanAllocation',
        },
        {
          Header: 'Broker Allocated ',
          accessor: 'allocateQTY',
        },
        {
          Header: 'Client/Broker Account',
          accessor: 'allocateAccount',
        },
        {
          Header: 'RecallDate',
          accessor: 'recalDate',
        },
        {
          Header: 'RecallShare',
          accessor: 'recallAmount',
        },
      ],
    },
    {
      Header: 'Buy In Information',
      columns: [
        {
          Header: 'Date to Buyin/Execute',
          objectKey: 'dateToBuyIn',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'date'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyInNotice date',
          objectKey: 'buyInNoticeDate',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'date'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyInQuantity',
          objectKey: 'buyInQty',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'OFFSET',
          objectKey: 'offset',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Buyin Ticket #',
          objectKey: 'buyInTicketNumber',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Add Comments',
          objectKey: 'buyInComment',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
      ],
    },
    {
      Header: 'Buy In ExeCution Details',
      columns: [
        {
          Header: 'BuyInExecTradeRef',
          objectKey: 'buyInExecTradeRef',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyInExecTradeDate',
          objectKey: 'buyInExecTradeDate',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'date'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyInExecSettleDate',
          objectKey: 'buyInExecSettleDate',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'date'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyInExecCounterParty',
          objectKey: 'buyInExecCounterParty',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyInExecTicker',
          objectKey: 'buyInExecTicker',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyInExecShares',
          objectKey: 'buyInExecShares',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyInExecPrice',
          objectKey: 'buyInExecPrice',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'BuyInExecAmount',
          objectKey: 'buyInExecAmount',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Audit Trail',
          objectKey: 'audit Trail',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={false}
                parentValue={value}
                label={'Audit Trail'}
                tableId={row.index}
                UpdateBuyInExeCution={AuditL3BuyInInformation}
              />
            )
          },
        },
        {
          Header: 'Update',
          objectKey: 'update',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={
                  accessFlag ||
                  DisabledFunction(
                    value?.current[row.index]?.source,
                    '',
                    value?.current[row.index]?.reportDate,
                    commonTabProps.approveFlag
                  )
                }
                parentValue={value}
                label={'Update'}
                tableId={row.index}
                UpdateBuyInExeCution={UpdateBuyInExeCution}
              />
            )
          },
        },
        {
          Header: 'Delete',
          objectKey: 'delete',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={
                  accessFlag ||
                  DisabledFunction(
                    value?.current[row.index]?.source,
                    '',
                    value?.current[row.index]?.reportDate,
                    commonTabProps.approveFlag
                  )
                }
                parentValue={value}
                label={'Delete'}
                tableId={row.index}
                UpdateBuyInExeCution={DeleteL3BuyInExeCution}
              />
            )
          },
        },
      ],
    },
  ]
}
