import { combineReducers } from '@reduxjs/toolkit'
import regSHOSummary from './Reg-SHO-Summary'
// import regSHOCorrespondentAllocation from './RegSHOCorrespondentAllocation'
import regSHOBuyInAllocation from './reg-sho-buyInAllocation'

export const regSHO204Tool = combineReducers({
  regSHOSummary,
  // regSHOCorrespondentAllocation,
  regSHOBuyInAllocation
})
