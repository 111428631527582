import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import 'react-datepicker/dist/react-datepicker.css'
import {
  WDStyledButtonText,
  WDStyledHeading,
} from '../../../components/ui/WDTypography'
import { COLORS } from '../../../theme/Colors'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  ApplyResetButtonWrapper,
  ButtonIconWrapper,
  DateWrapper,
  FilterContainer,
  FirstContainer,
  LottieWrapper,
  MarginLeftAutoSpace,
  MarginTopSpace,
  MonthPickerLabelWrapper,
  PopUpStyleLoader,
  RootContainer,
  SecondContainer,
  StyledSelectWrapper,
  StyledTableContainer,
  ToastWrapper,
} from './styles'
import { useEffect, useState } from 'react'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import { ReeOrgFeeColumn } from './ReeOrgFeeColumn'
import { useDispatch, useSelector } from 'react-redux'
import { Toast } from '../../../components/toast/Toast'
import Lottie from 'lottie-react'
import DatePicker from 'react-datepicker'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import {
  DataSelector,
  ErrorMessageSelector,
  LoadingSelector,
  SuccessMessageSelector,
  TotalCountSelector,
  getReOrgFeeCorrespondentReport,
  getReeOrgFileDownload,
  getReorgFeeAllCorrespondents,
  setErrorMessage,
  setSuccessMessage,
} from '../../../store/asset-Servicing/reeorg-Fee-Report-for-acaps'
import { Textbox } from '../../../components/textbox/Textbox'
import ReOrgTable from './reOrgTable/ReOrgTable'

const ReeOrgFeeForAcaps = () => {
  const dispatch = useDispatch()
  const data = useSelector(DataSelector)
  const TotalCount = useSelector(TotalCountSelector)
  const SuccessMessage = useSelector(SuccessMessageSelector)
  const ErrorMessage = useSelector(ErrorMessageSelector)
  const [reportRunDate, setReportRunDate] = useState(new Date())
  const [correspondentsOptions] = useState([{ value: 'All', label: 'All' }])
  const [correspondentsID, setCorrespondentsID] = useState('All')
  const [type, setType] = useState('Scrubbed')
  const loading = useSelector(LoadingSelector)
  const [showFilter, setShowFilter] = useState(false)
  const [accountNum, setAccountNum] = useState('')
  const [isTableVisible, setIsTableVisible] = useState<boolean>(false)
  const firstDay = new Date(
    reportRunDate.getFullYear(),
    reportRunDate.getMonth(),
    1
  )
  const lastDay = new Date(
    reportRunDate.getFullYear(),
    reportRunDate.getMonth() + 1,
    0
  )

  let mm: string | number
  let lastDayOfMonth: string | number

  mm = reportRunDate.getMonth() + 1
  const yyyy = firstDay.getFullYear()
  lastDayOfMonth = lastDay.getDate()
  lastDayOfMonth = '' + lastDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const toDate = yyyy + '-' + mm + '-' + lastDayOfMonth

  const current = new Date()
  const monthNames = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]
  const monthYear =
    monthNames[reportRunDate.getMonth()] + reportRunDate.getFullYear()

  const handleSubmit = async () => {
    const response = await dispatch(
      getReOrgFeeCorrespondentReport(
        correspondentsID,
        toDate,
        0,
        0,
        accountNum,
        type
      )
    )
    if (response == 200) {
      setIsTableVisible(true)
    } 
  }

  const DownloadFile = async (Type: string) => {
    const response = await dispatch(
      getReeOrgFileDownload(
        `ReOrgFeeReport${type}${monthYear}`,
        Type,
        correspondentsID,
        toDate,
        0,
        0,
        accountNum,
        type
      )
    )
  }

  useEffect(() => {
    const getCorres = async () => {
      const response = await getReorgFeeAllCorrespondents()
      if (response && response.length) {
        correspondentsOptions.length ===1 && response.forEach((element: any) => {
          correspondentsOptions.push({
            value: element.correspondentId,
            label: element.correspondentName,
          })
        })
      }
      setShowFilter(true)
    }
    getCorres()
  }, [])

  return (
    <RootContainer>
      <WDStyledHeading>{'ReOrg fee report For ACAPS'}</WDStyledHeading>
      <FilterContainer>
        <FirstContainer>
          {showFilter && (
            <>
              <MonthPickerLabelWrapper>
                <Label>Scrubbed/UnScrubbed</Label>
                <SelectDropdown
                  defaultValue={{
                    value: 'Scrubbed',
                    label: 'Scrubbed',
                  }}
                  width="200px"
                  borderRadius={'4px'}
                  height="32px"
                  options={[
                    {
                      value: 'Scrubbed',
                      label: 'Scrubbed',
                    },
                    {
                      value: 'UnScrubbed',
                      label: 'UnScrubbed',
                    },
                  ]}
                  onChange={(e) => {
                    setIsTableVisible(false)
                    setType(e)
                  }}
                ></SelectDropdown>
              </MonthPickerLabelWrapper>
              <MonthPickerLabelWrapper>
                <Label>Correspondent Name</Label>
                <SelectDropdown
                  defaultValue={{
                    value: 'All',
                    label: 'All',
                  }}
                  width="200px"
                  borderRadius={'4px'}
                  height="32px"
                  options={correspondentsOptions}
                  onChange={(e) => {
                    setIsTableVisible(false)
                    setCorrespondentsID(e)
                  }}
                ></SelectDropdown>
              </MonthPickerLabelWrapper>
              <MonthPickerLabelWrapper>
                <Label>Select Month</Label>
                <DateWrapper>
                  <DatePicker
                    onChange={(date: Date) => setReportRunDate(date)}
                    selected={reportRunDate}
                    startDate={reportRunDate}
                    maxDate={current}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                </DateWrapper>
              </MonthPickerLabelWrapper>
              <MonthPickerLabelWrapper>
                <Label> Account Number</Label>
                <Textbox
                  placeholder="Enter Account Number"
                  onChange={(e) => {
                    setAccountNum(e)
                  }}
                  style={{
                    borderRadius: '4px',
                    height: '32px',
                    padding: '4px 8px',
                  }}
                />
              </MonthPickerLabelWrapper>
              <MonthPickerLabelWrapper style={{ marginTop: '15px' }}>
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Submit
                </Button>
              </MonthPickerLabelWrapper>
            </>
          )}
        </FirstContainer>
        <SecondContainer>
          <MarginLeftAutoSpace>
            <ApplyResetButtonWrapper>
              <ButtonIconWrapper
                onClick={() => {
                  DownloadFile('CSV')
                }}
              >
                <SvgDownloadSmall
                  fillColor={`${COLORS.UI.Primary50}`}
                ></SvgDownloadSmall>
                <WDStyledButtonText>
                  <Label>CSV Download</Label>
                </WDStyledButtonText>
              </ButtonIconWrapper>
              <ButtonIconWrapper
                onClick={() => {
                  DownloadFile('Excel')
                }}
              >
                <SvgDownloadSmall
                  fillColor={`${COLORS.UI.Primary50}`}
                ></SvgDownloadSmall>
                <WDStyledButtonText>
                  <Label>Excel Download</Label>
                </WDStyledButtonText>
              </ButtonIconWrapper>
            </ApplyResetButtonWrapper>
          </MarginLeftAutoSpace>
        </SecondContainer>
      </FilterContainer>
      {isTableVisible && (
        <>
          <MarginTopSpace>
            <WDCard>
              <CardHeader
                label={`ReOrg Fee Report For ACAPS(${type})`}
                icon={false}
                amountValue={`Total: ${TotalCount}`}
              />
            </WDCard>
          </MarginTopSpace>
          <StyledTableContainer>
            <ReOrgTable
              columns={ReeOrgFeeColumn}
              correspondentsID={correspondentsID}
              reportRunDate={toDate}
              type={type}
              data={data}
              totalRecords={TotalCount}
              accountNum={accountNum}
            />
          </StyledTableContainer>
        </>
      )}
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
     {(SuccessMessage?.length > 0 ||
        ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success':'danger'}
            openStatusCallback={() => {
              dispatch(setErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default ReeOrgFeeForAcaps
