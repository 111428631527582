import { useEffect, useState } from 'react'
import L2L3BuyInDetails from '../L2L3BuyInDetails'
import Loader from '../../../../assets/lottiefiles/loader.json'
import {
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleLoader,
  RootContainer,
  StyledSelectWrapper,
} from './styles'
import {
  getL2RegSHOExcelL3,
  getRegSHOBuyInAllocation,
} from '../../../../store/reg-SHO-Tool/reg-sho-buyInAllocation'
import { useDispatch } from 'react-redux'
import Lottie from 'lottie-react'
import {
  useGetStockLoanSpecificL2Column,
  getStockLoanSpecificL3Column,
} from './stockLoanColumns'
import { commonTabProps, setCommonTabProps } from '../IBuyInAllocationInterface'
import { ButtonWrapper } from '../../styles'
import { SvgDownloadSmall } from '../../../../components/svg/logo/SvgDownload'
import { COLORS } from '../../../../theme/Colors'
import { WDStyledButtonText } from '../../../../components/ui/WDTypography'
import Label from '../../../../components/label/Label'

interface IStockLoanL3Content {
  commonTabProps: commonTabProps
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  setCommonTabProps: setCommonTabProps
}

const StockLoanL3Content = ({
  commonTabProps,
  setActiveTab,
  setCommonTabProps,
}: IStockLoanL3Content) => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [buyInData, setBuyInData] = useState<any>([])
  const [reset, setReset] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    setCommonTabProps({
      ...commonTabProps,
      allocationType: 'Stock Loan',
    })
    async function getAllocation() {
      setLoading(false)
      const response = await dispatch(
        getRegSHOBuyInAllocation({
          regSHOBuyIn_ID: commonTabProps.regSHOBuyIn_ID,
          regSHOId: commonTabProps.regSHOId,
          allocationId: commonTabProps.allocationId,
          pageNumber: 0,
          pageSize: 0,
          sortBy: 'ReportDate, CUSIP_Number',
          sortDirection: 'ASC',
          buyInType: 'Stock Loan',
        })
      )
      setData(response.data)
      const BuyInExecutions = response.data?.map(
        ({
          source,
          buyInExecTradeRef,
          buyInExecTradeDate,
          buyInExecSettleDate,
          buyInExecCounterParty,
          buyInExecTicker,
          buyInExecShares,
          buyInExecPrice,
          buyInExecAmount,
          createDate,
          createBy,
          dateToBuyIn,
          buyInNotice,
          buyInTicketNumber,
          buyInComment,
          buyInNoticeDate,
          buyInQty,
          offset,
          reportDate,
        }: any) => ({
          source,
          buyInExecTradeRef,
          buyInExecTradeDate,
          buyInExecSettleDate,
          buyInExecCounterParty,
          buyInExecTicker,
          buyInExecShares,
          buyInExecPrice,
          buyInExecAmount,
          createDate,
          createBy,
          dateToBuyIn,
          buyInNotice,
          buyInTicketNumber,
          buyInComment,
          buyInNoticeDate,
          buyInQty,
          offset,
          reportDate,
        })
      )
      setBuyInData(BuyInExecutions)
      setLoading(true)
      setReset(false)
    }
    reset && getAllocation()
  }, [reset])

  const DownloadExcelL3 = async () => {
    await dispatch(
      getL2RegSHOExcelL3(
        `${commonTabProps.selectedSummaryTableCUSIP}_${commonTabProps.source}_StockLoanL3`,
        {
          regSHOId: commonTabProps.regSHOId,
          allocationId: commonTabProps.allocationId,
          pageNumber: 0,
          pageSize: 0,
          sortBy: 'ReportDate, CUSIP_Number',
          sortDirection: 'ASC',
        }
      )
    )
  }
  return (
    <RootContainer>
      {!loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {loading && (
        <>
          <MarginLeftAutoSpace>
            <ButtonWrapper
              style={{ padding: '0px', lineHeight: '0px' }}
              onClick={async () => {
                DownloadExcelL3()
              }}
            >
              <SvgDownloadSmall
                fillColor={`${COLORS.UI.Primary50}`}
              ></SvgDownloadSmall>
              <WDStyledButtonText>
                <Label>EXCEL</Label>
              </WDStyledButtonText>
            </ButtonWrapper>
          </MarginLeftAutoSpace>
          <L2L3BuyInDetails
            commonTabProps={commonTabProps}
            setActiveTab={setActiveTab}
            setCommonTabProps={setCommonTabProps}
            title={'StockLoan Buyin execution'}
            setReset={setReset}
            specificColumnL2={useGetStockLoanSpecificL2Column}
            specificColumnL3={getStockLoanSpecificL3Column}
            buyInData={Object.entries(buyInData).map((e) => e[1])} // changing object of object into array of object
            data={data}
            dataLevel={'L3'}
          />
        </>
      )}
    </RootContainer>
  )
}

export default StockLoanL3Content
