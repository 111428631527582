import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/button/Button'
import DatePicker from '../../../components/datePicker/DatePicker'
import Label from '../../../components/label/Label'
import { Textbox } from '../../../components/textbox/Textbox'
import { Toast } from '../../../components/toast/Toast'
import WDCard from '../../../components/ui/WDCard'
import {
  WDStyledButtonText,
  WDStyledHeading,
  WDStyledMainHeading,
} from '../../../components/ui/WDTypography'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import { COLORS } from '../../../theme/Colors'
import FailReport204TableColumn from './FailReport204TableColumn'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  ButtonIconWrapper,
  FilterContainer,
  FilterLabelWrapper,
  LottieWrapper,
  MainContainer,
  MarginTopSpace,
  PopUpStyleLoader,
  RootContainer,
  SearchButtonWrapper,
  StyledSelectWrapper,
  TitleCsvDownloadWrapper,
  ToastWrapper,
} from './styles'
import {
  actionSelector,
  dataSelector,
  getFailReportData,
  getFailReportDataCSV,
  setAction,
  SetFailReportData,
  sortDirectionSelector,
  sortingSelector,
} from '../../../store/failReport-204/Fail-Report-204'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'

const FailReport204 = () => {
  const data = useSelector(dataSelector)
  const sorting = useSelector(sortingSelector)
  const dispatch = useDispatch()
  const sortDirection = useSelector(sortDirectionSelector)
  const action = useSelector(actionSelector)
  const [loadingBuffer, setLoadingBuffer] = useState<boolean>(false)
  const [totalRecords, setTotalRecords] = useState(0)

  const [input, setInput] = useState({
    reportDate: new Date().toISOString().split('T')[0],
    fileDate: '',
    symbol: '',
    cusip: '',
  })

  const [requestSubmit, setrequestSubmit] = useState<{
    status: boolean | null
    text: string
  }>({
    status: null,
    text: '',
  })

  useEffect(() => {
    dispatch(SetFailReportData([]))
    dispatch(setAction(''))
  }, [])

  const handleSearch = async () => {
    setLoadingBuffer(true)
    const response = await dispatch(
      getFailReportData(
        'search',
        'P3',
        input.reportDate,
        input.fileDate,
        input.symbol,
        input.cusip,
        0,
        20,
        sorting,
        sortDirection
      )
    )
    setLoadingBuffer(false)

    if (response) {
      if (response.data.length > 0) {
        setTotalRecords(response.totalCount)
      } else {
        setTotalRecords(0)
      }
    }
    if (!response) {
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }

  const DownloadCSV = async () => {
    setLoadingBuffer(true)
    const response = await dispatch(
      getFailReportDataCSV(
        `204FailReport_${input.reportDate}`,
        'P3',
        input.reportDate,
        input.fileDate,
        input.symbol,
        input.cusip,
        0,
        0,
        sorting,
        sortDirection
      )
    )
    setLoadingBuffer(false)
    if (response === 200) {
      setrequestSubmit({
        status: true,
        text: 'File Downloaded',
      })
    }
    if (response === 400) {
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }

  return (
    <RootContainer>
      <WDStyledHeading>
        <Label>{'204 Fail Report'}</Label>
      </WDStyledHeading>
      <MainContainer>
        <TitleCsvDownloadWrapper>
          <WDStyledMainHeading>Filter 204 Fails</WDStyledMainHeading>
          <ButtonIconWrapper
            onClick={() => {
              DownloadCSV()
            }}
          >
            <SvgDownloadSmall
              fillColor={`${COLORS.UI.Primary50}`}
            ></SvgDownloadSmall>

            <WDStyledButtonText>
              <Label>CSV Download</Label>
            </WDStyledButtonText>
          </ButtonIconWrapper>
        </TitleCsvDownloadWrapper>
        <FilterContainer>
          <FilterLabelWrapper>
            <Label>Report Date</Label>
            <DatePicker
              max={new Date().toISOString().split('T')[0]}
              defaultValue={new Date().toISOString().split('T')[0]}
              borderColor={''}
              bgColor={''}
              placeHolder={''}
              padding={'4px 8px'}
              onChange={(e) => {
                setInput({
                  ...input,
                  reportDate: e,
                })
              }}
              maxWidth={'100%'}
            />
            <Label>Select Date</Label>
          </FilterLabelWrapper>
          <FilterLabelWrapper>
            <Label>File Date</Label>
            <DatePicker
              max={new Date().toISOString().split('T')[0]}
              defaultValue={''}
              borderColor={''}
              bgColor={''}
              placeHolder={''}
              padding={'4px 8px'}
              onChange={(e) => {
                setInput({
                  ...input,
                  fileDate: e,
                })
              }}
              maxWidth={'100%'}
            />
            <Label>Select Date</Label>
          </FilterLabelWrapper>
          <FilterLabelWrapper>
            <Label>Symbol</Label>
            <Textbox
              placeholder="Enter Symbol"
              onChange={(e) => {
                setInput({ ...input, symbol: e })
              }}
              style={{
                borderRadius: '4px',
                height: '32px',
                padding: '4px 8px',
              }}
            />
          </FilterLabelWrapper>
          <FilterLabelWrapper>
            <Label>CUSIP</Label>
            <Textbox
              placeholder="Enter CUSIP"
              onChange={(e) => {
                setInput({ ...input, cusip: e })
              }}
              style={{
                borderRadius: '4px',
                height: '32px',
                padding: '4px 8px',
              }}
            />
          </FilterLabelWrapper>
        </FilterContainer>
        <SearchButtonWrapper>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              handleSearch()
            }}
          >
            Search
          </Button>
        </SearchButtonWrapper>
      </MainContainer>
      {(action === 'search' || action === 'sort') && (
        <>
          <MarginTopSpace>
            <WDCard>
              <CardHeader
                label={'204 Fails'}
                icon={false}
                amountValue={`Total: ${totalRecords}`}
              />
            </WDCard>
          </MarginTopSpace>
          <FailReport204TableColumn
            data={data.data ? data.data : []}
            reportDate={input.reportDate}
            fileDate={input.fileDate}
            symbol={input.symbol}
            cusip={input.cusip}
            totalRecords={totalRecords}
          ></FailReport204TableColumn>
        </>
      )}
      {loadingBuffer && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {requestSubmit.status !== null && (
        <ToastWrapper>
          <Toast
            text={requestSubmit.text}
            type={requestSubmit.status ? 'success' : 'danger'}
            openStatusCallback={(status: boolean) =>
              setrequestSubmit({
                status: status ? status : null,
                text: '',
              })
            }
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default FailReport204
