import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { FontSizes, FontWeights } from '../../theme/Fonts'
import { TextSizes } from '../../theme/Sizes'
interface labelProps {
  children?: React.ReactNode
}

export const StyledLabel = styled.div`
  font-style: normal;
  font-weight: ${FontWeights.Bold};
  font-size: ${FontSizes.Subtitle};
  line-height: ${TextSizes[5]};
  color: ${COLORS.Text.Text};
  margin: 0;
`

export const StyledLabelHeader = styled.div`
  font-style: normal;
  font-weight: ${FontWeights.Bold};
  font-size: ${FontSizes.Caption};
  line-height: ${TextSizes[2]};
  color: ${COLORS.Text.Neutral};
`

export const WDLabel = ({ children }: labelProps) => {
  return <StyledLabel>{children}</StyledLabel>
}

export const WDLabelHeader = ({ children }: labelProps) => {
  return <StyledLabelHeader>{children}</StyledLabelHeader>
}
