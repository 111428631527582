import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'
import { Backgrounds, Colors } from '../../../shared/styles'

export const RootContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`
export const FilterContainer = styled.div`
  align-items: center;
  width: 97.3vw;
  margin-top: 16px;
  border: 1px solid ${COLORS.Background.StrongBackgound};
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  padding: 16px 25px 4px 25px;
`
export const FirstContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const LeftSpace = styled.div`
  margin-left: 1.5%;
  position: relative;
`
export const MonthPickerLabelWrapper = styled.div`
  margin-bottom: 20px;
  margin-left: 10px;
`
export const DateWrapper = styled.div`
  & .react-datepicker-wrapper .react-datepicker__input-container input {
    border-radius: 8px;
    height: 29px;
    border: 1px solid ${COLORS.Background.Border};
    padding: 0px 0px 0px 9px;
    width: 100px;
    color: ${COLORS.Text.TextWeak};
    font-weight: bold;
    background: ${COLORS.Background.Primary};
  }
`
export const MarginLeftAutoSpace = styled.div`
  margin-left: auto;
`
export const FileUploadPopUpStyleWrapper = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`
export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${COLORS.Background.Primary};

  > * {
    margin-right: 16px;
  }
`
export const SvgButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`
export const UploadWrapper = styled.div`
  border: 2px dashed ${() => COLORS.UI.PrimaryBorderStrong};
  border-radius: 8px;
  padding: 1.5rem 0;
  display: flex;
  margin-top: 0.5rem;
  &.file-uploaded {
    background-color: ${() => COLORS.Background.Primary};
  }
`
export const FileUploadInput = styled.input``

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 24px 0px 8px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${COLORS.Background.Primary};
  > * {
    margin-right: 16px;
  }
`
export const SecondContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`
export const FilterLabelWrapper = styled.div``

export const ApplyResetButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  float: right;
`

export const ThirdContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  float: right;
  margin-bottom: 20px;
`
export const RefreshDownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  width: 100%;
`
export const FilterContainerHistory = styled.div`
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
  border: 1px solid ${COLORS.Background.StrongBackgound};
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  padding: 16px 25px 4px 25px;
`
export const MarginTopSpace = styled.div`
  margin-top: 16px;
`
export const BackButton = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
`
export const HistoryDownload = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const ButtonIconWrapper = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  align-content: center;
  background: none;
  cursor: pointer;
  margin-left: 25px;
`
export const StyledTableContainer = styled.div`
  width: 100%;
  padding: 8px 16px 24px 16px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
export const PopUpStyleLoader = styled.div`
  background: #fcfdff;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`
export const LottieWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`

export const CancelEmailModal = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
`
export const CancelSendButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 20px;
`
export const DeleteContactPopup = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`
export const EmailAddressTable = styled.div`
  overflow-y: scroll;
  height: 90%;
`
export const EmailBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3%;
`
export const EmailHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`
export const EmailModalWrapper = styled.div`
  position: fixed;
  top: 55%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fcfdff;
  display: flex;
  flex-direction: column;
  height: 87%;
  width: 90%;
  padding: 10px 15px 10px 15px;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`

export const LabelTextboxWrapper = styled.div`
  padding: 1.3%;
`
export const SubjectSearchSecurityContainer = styled.div`
  width: 48%;
  height: 58%;
`
export const TextEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 48%;
  .quill {
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
  }

  .quill > .ql-toolbar {
    border: none;
  }

  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 16px;
    font-style: normal;
  }
  .quill > .ql-container.ql-snow {
    height: 31vh;
    // border: 1px solid;
    border: none;
  }
`
export const FileUploadWrapper = styled.div`
  height: 20vh;
`
export const MailWrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
`
export const PopUpWrapper = styled.div`
  display: flex;
`
export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
`
export const SelectedMail = styled.div`
  display: flex;
  border: 2px solid ${() => COLORS.UI.PrimaryBorderStrong};
  margin: 0.5px;
`
export const SummaryTable = styled.div`
  overflow-y: scroll;
  max-height: 31vh;
`
export const ToMail = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  // height: 18%;
  padding: 10px;
  max-height: 70px;
  overflow: scroll;
`
export const ToPopupCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  gap: 8px;
  margin-right: 1000000px;
  position: absolute;
  width: 397px;
  height: 172px;
  overflow-y: scroll;
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  padding: 0.5rem;
  height: 1.75rem;
  width: 1.75rem;
  margin: 0.5rem;
  border-radius: 2rem;
  background-color: ${Backgrounds.PrimaryBgStrongActive};
  color: ${Colors.White};
  // cursor: pointer;
`
