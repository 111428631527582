/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef, SyntheticEvent } from "react";
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledBreakTableContainer,
  PopUpStyleLoader,
  PopUpSideBar,
  IconsWrapper,
  LottieWrapper,
} from "../../styles";
import Label from "../../../../components/label/Label";
import Table from "../../table/Table";
import Lottie from 'lottie-react';
import Loader from "../../../../assets/lottiefiles/loader.json"
import {
  getTradeBreakData,
  getCategoryList,
  gettradeBreakEXCEL
} from "../../../../store/trade-break/intraday-trade-breaks";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonDetails from "../commonDetails";
import { Colors } from "../../../../shared/styles";
import { SvgDownloadSmall } from "../../../../components/svg/logo/SvgDownload";
import SvgRedo from "../../../../components/svg/SvgRedo";
import RenderColumns from "./tableColumns";
import { TradeBreakDetailsModel } from "../Itradebreakdetails";
import { OptionProps } from "../../../../components/selectDropdown/styles";
import { tradeBreaksByMPIDModel } from "../../tradeBreaksbyMPID/tradeBreaks/ItradebreaksByMpid"

interface FilterModel {
  [key: string]: boolean | number,
}

interface locationValueModel {
  "passProps": tradeBreaksByMPIDModel | null
}

const IntraTradeBreakManagement = () => {
  const commentView = useRef<HTMLInputElement>()
  const location = useLocation();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<TradeBreakDetailsModel[]>([]);
  const [filterKey, setFilterKey] = useState<string | null>(null)
  const [orginalTableData, setOriginalTableData] = useState<TradeBreakDetailsModel[]>([]);
  const [categoryDropdown, SetCategoryDropdown] = useState([]);
  const [topTableData, setTopTableData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [approvalStatus, setApprovalStatus] = useState<string | undefined>('');

  const [checkFilter, setCheckFilter] = useState<FilterModel>({
    all: true,
    cancelled: false
  });

  const [checkFilterCount, setCheckFilterCount] = useState<FilterModel>({
    all: 0,
    cancelled: 0
  });

  const categoryOnchange = (e: OptionProps, row: number | string) => {
    const newSaveArr: any = [];
    tableData.forEach((item: TradeBreakDetailsModel, id: number | string) => {
      let tempObjSave: TradeBreakDetailsModel = {};
      if (row === id.toString()) {
        tempObjSave = Object.assign({}, item, { category: e.value });
      } else {
        tempObjSave = Object.assign({}, item);
      }
      newSaveArr.push(tempObjSave);
    });
    setTableData([...newSaveArr]);
  };

  const getTradeBreakDetails = async (params: any) => {
    if (params.passProps) {
      setApprovalStatus(params.passProps.approvalStatus)
      const users = await dispatch(getTradeBreakData(
        params.passProps.fromDate, params.passProps.toDate,
        params.passProps.settleDate?.split("T")[0], params.passProps.entityCode,
        params.passProps.cusip, params.passProps.symbol, params.passProps.assetType,
        params.passProps.mpid, params.passProps.breakType, params.passProps.buySell, params.passProps.forDate
      ));

      if (users && users.rpT882ViewModel) {
        let tempSave = [];
        const filterValue = filterKey === 'CXL' ? ['CXL'] : ['APR', 'REJ', null, 'NTF', 'NTF', 'F', 'NA']
        console.log("filterValue", users.rpT882ViewModel)
        const tableArr = users.rpT882ViewModel.filter((element: TradeBreakDetailsModel) => !element.breakStatus || filterValue.includes(element.breakStatus)
        );
        tempSave = tableArr;
        setTableData(tempSave);
        setOriginalTableData(users.rpT882ViewModel);
        setCheckFilterCount({
          all: users.rpT882ViewModel.filter((element: TradeBreakDetailsModel) => element.breakStatus !== 'CXL').length,
          cancelled: users.rpT882ViewModel.filter((element: TradeBreakDetailsModel) => element.breakStatus === 'CXL').length
        })
      }
      if (users && users.tradeBreakSummary) {
        setTopTableData(users.tradeBreakSummary);
      }
    }
    else {
      const users = await dispatch(getTradeBreakData(
        new Date().toISOString().split("T")[0], new Date().toISOString().split("T")[0],
        null, null,
        null, null, null,
        null, null, null, null
      ));
    }
    setLoading(false)
  };

  const getCategoryData = async () => {
    const category = await dispatch(getCategoryList())
    const categoryArray = category && category?.categorylist?.length && category?.categorylist?.map((item: any) => ({
      value: item.categoryName,
      label: item.categoryName,
    }));
    SetCategoryDropdown(categoryArray);
  }

  useEffect(() => {
    if (location.state) {
      const paramValues: any = location.state
      getTradeBreakDetails(paramValues);
    }
    else {
      setLoading(false)
    }
    getCategoryData()
  }, []);

  const filterOnChange = (e: any) => {
    const { id, checked } = e.target;
    switch (id) {
      case "cancelled":
        {
          checkFilter.all = false;
          checkFilter[id] = checked;
          setCheckFilter({ ...checkFilter });
          const tableCancelled = checked
            ? orginalTableData.filter(
              (obj: any) =>
                obj.breakStatus === "CXL"
            )
            : [];
          setFilterKey("CXL")
          setTableData(tableCancelled);
          break;
        }
      case "all":
        {
          checkFilter[id] = checked;
          checkFilter.cancelled = false
          setCheckFilter({ ...checkFilter });
          const tableAll = checked
            ? orginalTableData.filter(
              (obj: any) =>
                obj.breakStatus === null || obj.breakStatus !== "CXL"
            )
            : [];
          setFilterKey(null)
          if (checked) {
            setTableData(tableAll);
          } else {
            setTableData([]);
          }
          break;
        }
      default:
        break;
    }
  };

  const refreashData = async () => {
    setLoading(true)
    await getTradeBreakDetails(location.state);
    setLoading(false);

  };

  const downloadExcel = async (params: any) => {
    setLoading(true)
    await dispatch(gettradeBreakEXCEL(
      `TradeBreakDetails_${params.passProps.fromDate}`,
      params.passProps.fromDate, params.passProps.toDate,
      params.passProps.settleDate?.split("T")[0], params.passProps.entityCode,
      params.passProps.cusip, params.passProps.symbol, params.passProps.assetType,
      params.passProps.mpid, params.passProps.breakType, params.passProps.buySell));
    setLoading(false)
  }

  return (
    <RootContainer>
      {loading && <PopUpStyleLoader>
        <LottieWrapper>
          <Lottie animationData={Loader} loop={true} />
        </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div>
      </PopUpStyleLoader>}
      <StyledPanelHead>
        <StyledPanelHeadTitle>Trade Break Details - Intraday</StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect id="panelHead">
        <CommonDetails topTableData={topTableData} />
        <div
          style={{
            width: "100%", display: "flex", justifyContent: "flex-end",
            alignContent: 'center', gap: '4px', marginTop: "10px"
          }}
        >
          <IconsWrapper onClick={() => refreashData()}>
            <SvgRedo
              fillColor={Colors.PrimaryText}
            ></SvgRedo><Label color={Colors.PrimaryText} >Refresh</Label>
          </IconsWrapper>
          <IconsWrapper onClick={() => downloadExcel(location.state)}>
            <SvgDownloadSmall
              fillColor={Colors.PrimaryText}
            ></SvgDownloadSmall><Label color={Colors.PrimaryText}>Download</Label>
          </IconsWrapper>
        </div>
        <StyledBreakTableContainer id="tableHead">
          <Table
            hidePagination={false}
            heading={`Details (Total breaks: ${orginalTableData.length})`}
            filterOnChange={filterOnChange}
            checkFilter={checkFilter}
            isFilter={false}
            data={tableData}
            columns={RenderColumns(approvalStatus, categoryOnchange, categoryDropdown)}
            checkFilterCount={checkFilterCount}
            showCancel={true}
          />
        </StyledBreakTableContainer>
      </StyledPanelSelect>
    </RootContainer>
  );
};

export default IntraTradeBreakManagement;