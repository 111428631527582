import { z } from 'zod'

export const ValidateSchema = z
  .object({
    buySell: z.string({
      required_error: "Buy/Sell is required",
      invalid_type_error: "Buy/Sell must be a string",
    }).max(1).regex(/^[BS]+$/, { message: "Buy/Sell should contain only B/S" }),
    quantity: z.string({
      required_error: "Quantity is required",
      invalid_type_error: "Quantity must be a number",
    }).regex(/^[0-9]{1,10}(?:\.[0-9]{1,3})?$/, { message: "Quantity must be a number with not more than decimal points" }).max(13),
    aliasId: z.string().max(35).optional(), 
    exch: z.string().max(1).regex(/^[A-OQRSTZ]+$/, { message: "Invalid Exch" }).optional(),
    price: z.string().max(18).regex(/^[0-9]{1,8}(?:\.[0-9]{1,10})?$/, { message: "Invalid Price" }),
    mktCap: z.string().max(2).regex(/^[1-6AFGHNOQ-TV9](?:[1-7ACDIP9])?$/, { message: "Invalid MKTCAP" }).optional(),
    spclReason: z.string().max(1).regex(/^[A-Z12]+$/, { message: "Invalid spclReason" }).optional(),
    dest: z.string().max(1).regex(/^[ABDIMOPSTW]+$/, { message: "Invalid dest" }).optional(),
    specialCode: z.string().max(2).regex(/^[1289A-CF-IKLN-UWXY](?:[3-7M])?$/, { message: "Invalid SpecialCode" }),
    delNbr: z.string().max(1).regex(/^[1-9A-Z]?$/, { message: "Invalid delNbr" }).optional(),
    payDel: z.string().max(1).regex(/^[CDHIKPRSVMPTV]?$/, { message: "Invalid payDel" }).optional(),
    cCAP: z.string().max(1).regex(/^[1-2]+$/, { message: "Invalid CCAP" }).optional(),
    link: z.string().max(4).regex(/^[a-z0-9]+$/, { message: "Invalid Link" }).optional(),
    tRC_MSRB: z.string().max(2).regex(/^[AS](?:[NTB])(?:[BS ])?$/, { message: "Invalid TRC_MSRB" }).optional(),
    cBInd: z.string().max(1).regex(/^[FHLAXIVN]+$/, { message: "Invalid CBInd" }).optional(),
    tradeDate: z.string().refine((date: any) => {
        return new Date(date) < new Date(Date.now());
      }, "The date must be before today"),
   cxlCor:z.string().max(1).regex(/^[YN]+$/, { message: "Invalid CXL/COR" }).optional(),
   bondForm: z.string().max(1).regex(/^[CR]?$/, { message: "Invalid Bondform" }).optional(),
   dDANbr:z.string().max(1).regex(/^[ABN]?$/, { message: "Invalid DDANbr" }).optional(),
   unmatchedCancel:z.string().max(1).regex(/^[AN ]?$/, { message: "Invalid unmatchedCancel" }).optional(),
   verify:z.string().max(1).regex(/^[RY ]?$/, { message: "Invalid VERIFY" }).optional(),
   fOP:z.string().max(1).regex(/^[N YF ]?$/, { message: "Invalid FOP" }).optional(),
   groups:z.string().max(2).regex(/^[SS SE MCB ]?$/, { message: "Invalid FOP" }).optional(),
   iRA:z.string().max(1).regex(/^[CEFGHPRT]?$/, { message: "Invalid IRA" }).optional(),
   brk1: z.string().max(4).regex(/^[a-zA-Z0-9]+$/, { message: "Invalid brk1" }).optional(),
   brk2: z.string().max(4).regex(/^[a-zA-Z0-9]+$/, { message: "Invalid brk2" }).optional(),
   brk3: z.string().max(4).regex(/^[a-zA-Z0-9]+$/, { message: "Invalid brk3" }).optional(),
   officeRR:z.string().max(4).regex(/^[a-zA-Z0-9]+$/, { message: "Invalid OfficeRR" }).optional(),
   trailer:z.string().max(1).regex(/^[BCD]+$/, { message: "Trailer should contain only B/C/D" }).optional(),
   dtcNbr: z.string({
    invalid_type_error: "DTC Nbr must be a number",
  }).max(4).regex(/^[0-9]{1,8}(?:\.[0-9]{1,10})?$/, { message: "Invalid dtcNbr" }).optional(),
  frontExec:z.string().max(4).regex(/^[a-zA-Z0-9]+$/, { message: "Invalid Front Exec" }).optional(),
  backExec:z.string().max(4).regex(/^[a-zA-Z0-9]+$/, { message: "Invalid Back Exec" }).optional(),
  accountType:z.string({
    required_error: "Account type is required",
    invalid_type_error: "Account type must be a string",
  }).max(1).regex(/^[0-9CEM]+$/, { message: "Invalid Account Type" }),
  capacity:z.string({
    required_error: "Capacity is required",
    invalid_type_error: "Capacity must be a string",
  }).max(1).regex(/^[1-9CDI]+$/, { message: "Invalid Capacity Type" }),

  });


