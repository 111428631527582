import {
  ButtonWrapper,
  StyledContainer,
  StyledRightContainer,
  StyledSelectWrapper,
} from './OutStandingStyle'
import {
  ProppedDatePicker,
  ProppedLabel,
  ProppedTextbox,
} from '../commonComponents'
import Button from '../../../components/button/Button'
import { useDispatch } from 'react-redux'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import Label from '../../../components/label/Label'
import { COLORS } from '../../../theme/Colors'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { getOutStandingDetails, getOutStandingsExcel } from '../../../store/settlement/out-standing'
import { Textbox } from '../../../components/textbox/Textbox'
export interface IOutStandingSearchFilters {
  fromDate: string
  toDate: string
  cusip: string|null,
  symbol:  string|null,
  source:  string | null,
}
interface OutStandingSearchPanelProps {
  searchParams: IOutStandingSearchFilters
  setSearchParams: React.Dispatch<
    React.SetStateAction<IOutStandingSearchFilters>
  >
  setSearchFlag: React.Dispatch<React.SetStateAction<any>>
}

const OutStandingSearchPanel = ({
  searchParams,
  setSearchParams,
  setSearchFlag,
}: OutStandingSearchPanelProps) => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  const isoStringDate = date.toISOString()
  const splitDate = isoStringDate.split('T')[0]
  const resetSearchFilters = () => {
    setSearchParams({
      fromDate: splitDate,
    toDate: splitDate,
    cusip: '',
    symbol: '',
    source: null,
    })
  }
  const dispatch = useDispatch()

  const handleSearch = () => {
    const fetchData = async () => {
      await dispatch(
        getOutStandingDetails( {
          ...searchParams,
          source:
          searchParams?.source?.trim() === ''
            ? null
            : searchParams.source,
          cusip:
                  searchParams?.cusip?.trim() === ''
                    ? null
                    : searchParams.cusip,
                    symbol:
                  searchParams?.symbol?.trim() === ''
                    ? null
                    : searchParams.symbol,
        })
      )
    }

    fetchData()
    setSearchFlag((previousValue: any) => !previousValue)
  }

  return (
    <StyledContainer>
      <StyledSelectWrapper>
      <div>
          <ProppedLabel>Source</ProppedLabel>
        <SelectDropdown
        onChange={(e)=>{
          setSearchParams((previousValue) => ({
            ...previousValue,
            source: e,
          }))
        }}
        defaultValue={{
          value:'',
          label:'All'
        }}
        options={[{
          value:'',
          label:'All'
        },
        {
          value:'Beta',
          label:'Beta'
        },
        {
          value:'P3',
          label:'P3'
        }]}/>
        </div>
        <div>
          <ProppedLabel>From Date</ProppedLabel>
          <ProppedDatePicker
            value={searchParams.fromDate}
            max={ splitDate}
            onChange={(v: string) => {
              setSearchParams((previousValue) => ({
                ...previousValue,
                fromDate: v,
              }))
            }}
          />
        </div>
        <div>
          <ProppedLabel>To Date</ProppedLabel>
          <ProppedDatePicker
          max={ splitDate}
            value={searchParams.toDate}
            onChange={(v: string) => {
              setSearchParams((previousValue) => ({
                ...previousValue,
                toDate: v,
              }))
            }}
          />
        </div>
        <div>
          <ProppedLabel>Cusip</ProppedLabel>
          <Textbox
                value={searchParams.cusip}
                placeholder="Cusip"
                onChange={(v: string) => {
                  setSearchParams((previousValue) => ({
                    ...previousValue,
                    cusip: v,
                  }))
                }}
                style={{
                  width:'100px',
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
        </div>
        <div>
          <ProppedLabel>Symbol</ProppedLabel>
          <Textbox
                value={searchParams.symbol}
                placeholder="Symbol"
                onChange={(v: string) => {
                  setSearchParams((previousValue) => ({
                    ...previousValue,
                    symbol: v,
                  }))
                }}
                style={{
                  width:'100px',
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
        </div>
        <ButtonWrapper
          onClick={async () => {
            dispatch(
              getOutStandingsExcel('BuyinExecutionReport', {
                ...searchParams,
                source:
                searchParams?.source?.trim() === ''
                  ? null
                  : searchParams.source,
                cusip:
                  searchParams?.cusip?.trim() === ''
                    ? null
                    : searchParams.cusip,
                    symbol:
                  searchParams?.symbol?.trim() === ''
                    ? null
                    : searchParams.symbol,
              })
            )
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label>Excel</Label>
          </WDStyledButtonText>
        </ButtonWrapper>
        <ButtonWrapper>
        <Button
           hoverBgColor={'#FFFFFF'}
           hoverTextColor={'#2563EB' }
           borderRadius={'4px'}
           hover={true}
           padding={'4px 16px'}
           bgColor={'#2563EB'}
           color={'White'}
           type={'Button'}
           margin={'0px opx'}
            onClick={() => {
              resetSearchFilters()
            }}
            borderColor={'#2563EB'}
          >
            Reset
          </Button>
          <Button
            hoverBgColor={'#FFFFFF'}
            hoverTextColor={'#2563EB' }
            borderRadius={'4px'}
            hover={true}
            padding={'4px 16px'}
            bgColor={'#2563EB'}
            color={'White'}
            type={'Button'}
            margin={'0px opx'}
            onClick={() => {
              handleSearch()
            }}
            borderColor={'#2563EB'}
          >
            Search
          </Button>
          </ButtonWrapper>
      </StyledSelectWrapper>
    </StyledContainer>
  )
}

export default OutStandingSearchPanel
