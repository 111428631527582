import { useEffect, useState, BaseSyntheticEvent, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/button/Button'
import DatePicker from '../../../components/datePicker/DatePicker'
import Label from '../../../components/label/Label'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { useLocation } from 'react-router-dom'

import {
  loadingSelector,
  getDeliverFailReportExcel,
  getDeliverFailReport,
  deliverFailReportSelector,
  setHttpErrorMessage,
  setSuccessMessage,
  HttpSuccessMessageSelector,
  HttpErrorMessageSelector,
} from '../../../store/buyIns/buyInSettlements'
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledSelectWrapper,
  StyledTableContainer,
  PopUpStyleLoader,
  StyledFilterPanel,
  StyledButtonWrapper,
  LottieWrapper,
  PopUpStyleContent,
  MarginLeftAutoSpace,
  SvgButtonWrapper,
  UploadWrapper,
  FileUploadInput,
  RowWrapper,
  ButtonWrapper,
  TableIconWrapper,
  ToastWrapper,
} from './styles'
import Table from './table/Table'
import { Textbox } from '../../../components/textbox/Textbox'
import { COLORS } from '../../../theme/Colors'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import { Backgrounds, Colors } from '../../../shared/styles'
import { WDLabel } from '../../../components/ui/WDLabel'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { BuyInDeliverSettlementsColumns } from './table/tableColumns'
import { getAccessFlag } from '../../../utils/globalFunctions'
import { Toast } from '../../../components/toast/Toast'

const EXTENSIONS = ['csv']
const BuyInDeliverSettlements = () => {
  const dispatch = useDispatch()
  const deliverfailReport = useSelector(deliverFailReportSelector)
  const location = useLocation()
  const loading = useSelector(loadingSelector)
  const [input, setInput] = useState({
    jobRunDateFrom: new Date().toISOString().split('T')[0],
    jobRunDateTo: new Date().toISOString().split('T')[0],
    securityCategory: '',
    cusip: '',
    quantity: 0,
    broker: '',
    pageSize: 10,
    pageNumber: 1,
  })
  const [BBGPOPUP, setBBGPOPUP] = useState<boolean>(false)
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [fileName, setFileName] = useState('')
  const [fileData, setFileData] = useState<File>()
  const [tableData, setTableData] = useState<[]>([])
  const [isCSV, setIsCSV] = useState(true)
  const fileUpload = useRef<HTMLInputElement>(null)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [checkFilter, setCheckFilter] = useState<any>({
    matched: false,
    unMatched: true,
  })
  const toggleLabels = ['Matched', 'UnMatched']

  const [checkFilterCount, setCheckFilterCount] = useState<any>({
    matched: 0,
    unMatched: 0,
  })

  const [sourceOption, setSourceOption] = useState<null | string>(null)
  const sourceValues = [
    {
      value: '',
      label: '--Select--',
    },
    {
      value: 'Beta',
      label: 'BETA',
    },
    {
      value: 'P3',
      label: 'P3',
    },
  ]
  const writeExecuteFlag = getAccessFlag(['SS-RWX', 'OM-RWX'])
  const setPageCount = async (pageIndex: number, rowRange: number) => {
    setInput({
      ...input,
      pageNumber: pageIndex,
      pageSize: rowRange,
    })
    await dispatch(
      getDeliverFailReport(
        sourceOption,
        checkFilter.matched ? 'M' : 'U',
        null,
        null,
        input.jobRunDateFrom,
        input.jobRunDateTo,
        input.cusip.trim() === '' ? null : input.cusip,
        input.broker.trim() === '' ? null : input.broker,
        rowRange,
        pageIndex
      )
    )
  }

  const handleSearch = async () => {
    await dispatch(
      getDeliverFailReport(
        sourceOption,
        checkFilter.matched ? 'M' : 'U',
        null,
        null,
        input.jobRunDateFrom,
        input.jobRunDateTo,
        input.cusip.trim() === '' ? null : input.cusip,
        input.broker.trim() === '' ? null : input.broker,
        10,
        1
      )
    )
  }

  const downloadExcel = async () => {
    const source = sourceOption === '' ? null : sourceOption

    await dispatch(
      getDeliverFailReportExcel(
        `${source != null ? sourceOption : ''}DeliverFailReport_${
          input.jobRunDateFrom
        }`,
        sourceOption,
        checkFilter.matched ? 'M' : 'U',
        null,
        null,
        input.jobRunDateFrom,
        input.jobRunDateTo,
        input.cusip.trim() === '' ? null : input.cusip,
        input.quantity ? input.quantity : null,
        input.broker.trim() === '' ? null : input.broker,
        0,
        0
      )
    )
  }

  useEffect(() => {
    setTableData(deliverfailReport.ftdMatchUnmatchModel)
  }, [deliverfailReport])

  const reset = () => {
    setInput({
      jobRunDateFrom: new Date().toISOString().split('T')[0],
      jobRunDateTo: new Date().toISOString().split('T')[0],
      securityCategory: '',
      cusip: '',
      quantity: 0,
      broker: '',
      pageSize: 0,
      pageNumber: 0,
    })
    setTableData([])
    setSourceOption(null)
  }

  const filterOnChange = (e: any) => {
    const { id, checked } = e.target
    switch (id) {
      case 'matched': {
        checkFilter[id] = checked
        checkFilter.unMatched = false
        setCheckFilter({ ...checkFilter })
        handleSearch()
        break
      }
      case 'unMatched': {
        checkFilter[id] = checked
        checkFilter.matched = false
        setCheckFilter({ ...checkFilter })
        handleSearch()
        break
      }
    }
  }

  const getExtension = (file: File) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    setIsCSV(EXTENSIONS.includes(extension))
    return EXTENSIONS.includes(extension)
  }

  const handleDrop = (uploadedFile: File) => {
    setFileName(uploadedFile.name)
    if (getExtension(uploadedFile)) {
      setIsFileUploaded(true)
      const reader = new FileReader()
      reader.readAsBinaryString(uploadedFile)
      setFileData(uploadedFile)
    } else {
      setIsFileUploaded(false)
    }
  }

  const preventDefaults = (event: BaseSyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDropFile = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer.files
    handleDrop(files[0])
  }

  const handleFiles = (event: BaseSyntheticEvent) => {
    handleDrop(event.target.files[0])
  }

  const browseFile = () => {
    fileUpload.current?.click()
  }

  return (
    <RootContainer>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>Fails To Deliver</StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledFilterPanel>
          <StyledSelectWrapper>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Source
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={sourceValues}
                onChange={(options) => {
                  if (options != '') setSourceOption(options)
                  else setSourceOption(null)
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                From Date
              </Label>
              <DatePicker
                padding={'4px 8px'}
                onChange={(e) => {
                  setInput({ ...input, jobRunDateFrom: e })
                }}
                value={input.jobRunDateFrom}
                max={new Date().toISOString().split('T')[0]}
                bgColor=""
                borderColor=""
                placeHolder="From"
                maxWidth="295px"
                defaultValue={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                To Date
              </Label>
              <DatePicker
                padding={'4px 8px'}
                onChange={(e) => {
                  setInput({ ...input, jobRunDateTo: e })
                }}
                value={input.jobRunDateTo}
                max={new Date().toISOString().split('T')[0]}
                min={input.jobRunDateFrom}
                bgColor=""
                borderColor=""
                placeHolder="From"
                maxWidth="295px"
                defaultValue={new Date().toISOString().split('T')[0]}
              />
            </div>

            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                CUSIP
              </Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                onChange={(e) => {
                  setInput({ ...input, cusip: e })
                }}
                placeholder={'CUSIP'}
                value={input.cusip}
              />
            </div>
            {/* <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >Quantity</Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                type={"number"}
                onChange={(e: string) => {
                  setInput({ ...input, quantity:Number( e )});
                }}
                placeholder={"Quantity"}
              />
            </div> */}

            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Broker
              </Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                onChange={(e) => {
                  setInput({ ...input, broker: e })
                }}
                placeholder={'Broker'}
                value={input.broker}
              />
            </div>
            <div>
              <Button
                bgColor={'#FFFFFF'}
                color={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                disabled={!writeExecuteFlag}
                padding={'9px 18px'}
                hoverBgColor={'#2563EB'}
                hoverTextColor={'White'}
                type={'Button'}
                onClick={() => {
                  setBBGPOPUP(true)
                }}
                borderColor={'#2563EB'}
              >
                DTCC File Upload
              </Button>
            </div>
            <StyledButtonWrapper>
              <Button
                hoverBgColor={'#A7AFBC'}
                hoverTextColor={'#E2E8F0'}
                borderRadius={'4px'}
                hover={true}
                padding={'5px 10px'}
                bgColor={'#E2E8F0'}
                color={'#A7AFBC'}
                type={'Button'}
                margin={'0px 6px'}
                onClick={() => {
                  reset()
                }}
                borderColor={'#A7AFBC'}
              >
                Reset
              </Button>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
            </StyledButtonWrapper>
          </StyledSelectWrapper>
        </StyledFilterPanel>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignContent: 'center',
            gap: '4px',
            marginTop: '10px',
          }}
        >
          <TableIconWrapper onClick={() => downloadExcel()}>
            <SvgDownloadSmall fillColor={Colors.PrimaryText}></SvgDownloadSmall>
            <Label color={Colors.PrimaryText}>Download</Label>
          </TableIconWrapper>
        </div>
        <StyledTableContainer>
          <Table
            hidePagination={false}
            filterOnChange={filterOnChange}
            checkFilter={checkFilter}
            isFilter={true}
            checkFilterCount={checkFilterCount}
            heading={'Fails To Deliver'}
            data={tableData && tableData.length ? tableData : []}
            totalCount={
              deliverfailReport.totalCount ? deliverfailReport.totalCount : 0
            }
            checkFilterKeys={['matched', 'unMatched']}
            columns={BuyInDeliverSettlementsColumns}
            setPageCount={setPageCount}
            toggleLabels={toggleLabels}
          />
        </StyledTableContainer>
      </StyledPanelSelect>
      {BBGPOPUP && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
          >
            DTCC File Upload
            <MarginLeftAutoSpace>
              <SvgButtonWrapper
                onClick={() => {
                  const uploadElement = document.getElementById(
                    'fileElement'
                  ) as HTMLInputElement
                  uploadElement.value = ''
                  setIsFileUploaded(false)
                  setBBGPOPUP(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </SvgButtonWrapper>
            </MarginLeftAutoSpace>
          </StyledSelectWrapper>
          <Divider
            thickness={'1px'}
            horizontal={true}
            bgColor={Backgrounds.Gray110}
          />
          <UploadWrapper
            id="drop-area"
            onDragEnter={preventDefaults}
            onDragOver={preventDefaults}
            onDragLeave={preventDefaults}
            onDrop={handleDropFile}
            className={isFileUploaded ? 'file-uploaded' : ''}
          >
            <FileUploadInput
              type="file"
              id="fileElement"
              ref={fileUpload}
              multiple
              accept=".csv"
              hidden
              onChange={handleFiles}
            ></FileUploadInput>
            {isFileUploaded ? (
              <RowWrapper>
                <WDLabel>
                  <Label>{fileName}</Label>
                </WDLabel>
              </RowWrapper>
            ) : (
              <RowWrapper>
                <WDLabel>
                  <Label>{'Drag your file here to start uploading or'}</Label>
                </WDLabel>
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={browseFile}
                >
                  Browse files
                </Button>
              </RowWrapper>
            )}
          </UploadWrapper>
          <MarginLeftAutoSpace>
            <ButtonWrapper>
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                style={{ whiteSpace: 'nowrap' }}
                bgColor={'transparent'}
                onClick={() => {
                  setBBGPOPUP(false)
                  const uploadElement = document.getElementById(
                    'fileElement'
                  ) as HTMLInputElement
                  uploadElement.value = ''
                  setIsFileUploaded(false)
                }}
              >
                Cancel
              </Button>
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  handleSearch()
                }}
              >
                Upload
              </Button>
            </ButtonWrapper>
          </MarginLeftAutoSpace>
        </PopUpStyleContent>
      )}
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}
export default BuyInDeliverSettlements
