/* eslint-disable @typescript-eslint/no-explicit-any */
import Label from '../../../components/label/Label'
import {
  WDStyledButtonText,
  WDStyledMainHeading,
} from '../../../components/ui/WDTypography'
import {
  CancelEmailModal,
  CancelSendButtonWrapper,
  DeleteContactPopup,
  EmailAddressTable,
  EmailBodyWrapper,
  EmailHeaderContainer,
  EmailModalWrapper,
  LabelTextboxWrapper,
  MarginLeftAutoSpace,
  StyledSelectWrapper,
  SubjectSearchSecurityContainer,
  SvgButtonWrapper,
  TextEditorWrapper,
  ToastWrapper,
} from './styles'
import 'react-quill/dist/quill.snow.css'
import { useEffect, useState } from 'react'
import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'
import { Textbox } from '../../../components/textbox/Textbox'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/table/Table'
import Divider from '../../../components/divider/Divider'
import { Backgrounds } from '../../../shared/styles'
import {
  ResponseSelector,
  statusSelector,
  AddressData,
  addContact,
  updateContact,
  deleteContact,
} from '../../../store/staticData/stale-pricing-beta'
import { Toast } from '../../../components/toast/Toast'
import { StyledImage } from '../../../components/image/styles'
import { Icons } from '../../../shared/GlobalStyle'
import { Textarea } from '../../../components/textArea/Textarea'
import { SvgExclamation } from '../../../components/svg/logo/SvgExclamation'
import SvgCancel from '../../../components/svg/logo/SvgCancel'

const AddEmail = (props: any) => {
  const dispatch = useDispatch()
  const response = useSelector(ResponseSelector)
  const status = useSelector(statusSelector)
  const [toastPopup, setToastPopup] = useState<boolean>(false)
  const [toggleEdit, SetToggleEdit] = useState<boolean>(false)
  const [toggleInsert, SetToggleInsert] = useState<boolean>(false)
  const [showTable, SetShowTable] = useState<boolean>(false)
  const [toggleDeactivate, SetToggleDeactivate] = useState<boolean>(false)
  const [data, setData] = useState<any>([{}])
  const [mapping, setMapping] = useState({
    id: 0,
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    notes: '',
  })
  const addMapping = () => {
    setMapping({
      id: 0,
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      phone: '',
      notes: '-',
    })
    SetToggleInsert(true)
  }
  useEffect(() => {
    async function GetAddress() {
      const response = await dispatch(AddressData())
      setData(response)
      SetShowTable(true)
    }

    GetAddress()
  }, [])

  const addNew = async () => {
    SetToggleInsert(false)
    await dispatch(
      addContact(
        mapping.id,
        mapping.firstName,
        mapping.lastName,
        mapping.company,
        mapping.email,
        mapping.phone,
        mapping.notes ? mapping.notes : '-'
      )
    )
    const response = await dispatch(AddressData())
    setData(response)
    await setToastPopup(true)
  }
  const update = async () => {
    SetToggleEdit(false)
    await dispatch(
      updateContact(
        mapping.id,
        mapping.firstName,
        mapping.lastName,
        mapping.company,
        mapping.email,
        mapping.phone,
        mapping.notes ? mapping.notes : '-'
      )
    )
    const response = await dispatch(AddressData())
    setData(response)
    await setToastPopup(true)
  }
  const deactive = async () => {
    SetToggleDeactivate(false)
    await dispatch(
      deleteContact(
        mapping.id,
        mapping.firstName,
        mapping.lastName,
        mapping.company,
        mapping.email,
        mapping.phone,
        mapping.notes ? mapping.notes : '-'
      )
    )
    const response = await dispatch(AddressData())
    setData(response)
    await setToastPopup(true)
  }

  function formatPhoneNumber(value: any) {
    if (!value) return value
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length
    if (phoneNumberLength < 4) return phoneNumber
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`
  }

  const handlePhoneNum = (e: any) => {
    const formattedPhoneNumber: any = formatPhoneNumber(e)
    setMapping({ ...mapping, phone: formattedPhoneNumber })
  }

  return (
    <EmailModalWrapper>
      <EmailHeaderContainer>
        <WDStyledMainHeading>Contacts</WDStyledMainHeading>
        <CancelSendButtonWrapper>
          <CancelEmailModal
            onClick={() => {
              props.setShowAddEmail(false)
            }}
          >
            <WDStyledButtonText>
              <Label>Cancel</Label>
            </WDStyledButtonText>
          </CancelEmailModal>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            gap={'8px'}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              addMapping()
            }}
          >
            Add New
          </Button>
        </CancelSendButtonWrapper>
      </EmailHeaderContainer>
      <EmailAddressTable>
        {showTable && (
          <Table
            tableColumns={[
              {
                Header: 'First Name',
                accessor: 'firstName',
              },

              {
                Header: 'Last Name',
                accessor: 'lastName',
              },
              {
                Header: 'Company',
                accessor: 'company',
              },
              {
                Header: 'Email',
                accessor: 'email',
              },
              {
                Header: 'Phone',
                accessor: 'phone',
              },
              {
                Header: 'Notes',
                accessor: 'notes',
              },
              {
                Header: 'Delete',
                Cell: (e: any) => {
                  return (
                    <StyledImage
                      style={{ cursor: 'pointer' }}
                      title="delete"
                      src={Icons.Delete}
                      onClick={() => {
                        setMapping({
                          id: data[e.cell.row.id].id,
                          firstName: data[e.cell.row.id].firstName,
                          lastName: data[e.cell.row.id].lastName,
                          company: data[e.cell.row.id].company,
                          email: data[e.cell.row.id].email,
                          phone: data[e.cell.row.id].phone,
                          notes: data[e.cell.row.id].notes,
                        })
                        SetToggleDeactivate(true)
                      }}
                    ></StyledImage>
                  )
                },
              },
              {
                Header: 'Edit',
                Cell: (e: any) => {
                  return (
                    <StyledImage
                      title="Edit"
                      onClick={() => {
                        setMapping({
                          id: data[e.cell.row.id].id,
                          firstName: data[e.cell.row.id].firstName,
                          lastName: data[e.cell.row.id].lastName,
                          company: data[e.cell.row.id].company,
                          email: data[e.cell.row.id].email,
                          phone: data[e.cell.row.id].phone,
                          notes: data[e.cell.row.id].notes,
                        })

                        SetToggleEdit(true)
                      }}
                      width={'25px'}
                      style={{ cursor: 'pointer' }}
                      src={Icons.Edit}
                      alt=""
                    />
                  )
                },
              },
            ]}
            tableData={data}
            onClick={() => {
              //
            }}
          />
        )}
      </EmailAddressTable>

      {/*Insert*/}
      {toggleInsert && (
        <EmailModalWrapper>
          <EmailHeaderContainer>
            <WDStyledMainHeading> Add Contacts</WDStyledMainHeading>
            <CancelSendButtonWrapper>
              <CancelEmailModal
                onClick={() => {
                  SetToggleInsert(false)
                }}
              >
                <WDStyledButtonText>
                  <Label>Cancel</Label>
                </WDStyledButtonText>
              </CancelEmailModal>
              {mapping.company &&
              mapping.firstName &&
              mapping.email &&
              mapping.lastName ? (
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={() => {
                    addNew()
                  }}
                >
                  Add
                </Button>
              ) : (
                <Button
                  bgColor={COLORS.Background.Neutral40}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Neutral40}
                  borderColor={'transparent'}
                >
                  Add
                </Button>
              )}
            </CancelSendButtonWrapper>
          </EmailHeaderContainer>
          <EmailBodyWrapper>
            <SubjectSearchSecurityContainer>
              <LabelTextboxWrapper>
                <Label>First Name</Label>
                <Textbox
                  defaultValue={mapping.firstName}
                  onChange={(e) => {
                    setMapping({ ...mapping, firstName: e })
                  }}
                  placeholder="First Name"
                />
              </LabelTextboxWrapper>
              <LabelTextboxWrapper>
                <Label>Last Name</Label>

                <Textbox
                  onChange={(e) => {
                    setMapping({ ...mapping, lastName: e })
                  }}
                  placeholder="Last Name"
                />
              </LabelTextboxWrapper>
              <LabelTextboxWrapper>
                <Label>Company</Label>

                <Textbox
                  onChange={(e) => {
                    setMapping({ ...mapping, company: e })
                  }}
                  placeholder="Company"
                />
              </LabelTextboxWrapper>
              <LabelTextboxWrapper>
                <Label>Email</Label>

                <Textbox
                  type="email"
                  onChange={(e) => {
                    setMapping({ ...mapping, email: e })
                  }}
                  placeholder="Email"
                />
              </LabelTextboxWrapper>
              <LabelTextboxWrapper>
                <Label>Phone</Label>
                <Textbox
                  value={mapping.phone}
                  onChange={(e) => {
                    handlePhoneNum(e)
                  }}
                  placeholder="Phone"
                />
              </LabelTextboxWrapper>
            </SubjectSearchSecurityContainer>
            <TextEditorWrapper>
              <Label>Notes</Label>
              <Textarea
                height="50%"
                onChange={(e: any) => {
                  setMapping({ ...mapping, notes: e.target.value })
                }}
                placeholder="Type..."
              />
            </TextEditorWrapper>
          </EmailBodyWrapper>
        </EmailModalWrapper>
      )}

      {/*Edit*/}
      {toggleEdit && (
        <EmailModalWrapper>
          <EmailHeaderContainer>
            <WDStyledMainHeading> Update Contacts</WDStyledMainHeading>
            <CancelSendButtonWrapper>
              <CancelEmailModal
                onClick={() => {
                  SetToggleEdit(false)
                }}
              >
                <WDStyledButtonText>
                  <Label>Cancel</Label>
                </WDStyledButtonText>
              </CancelEmailModal>
              {mapping.company &&
              mapping.firstName &&
              mapping.email &&
              mapping.lastName ? (
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={() => {
                    update()
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button
                  bgColor={COLORS.Background.Neutral40}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  borderColor={'transparent'}
                >
                  Update
                </Button>
              )}
            </CancelSendButtonWrapper>
          </EmailHeaderContainer>
          <EmailBodyWrapper>
            <SubjectSearchSecurityContainer>
              <LabelTextboxWrapper>
                <Label>First Name</Label>
                <Textbox
                  defaultValue={mapping.firstName}
                  onChange={(e) => {
                    setMapping({ ...mapping, firstName: e })
                  }}
                  placeholder="First Name"
                />
              </LabelTextboxWrapper>
              <LabelTextboxWrapper>
                <Label>Last Name</Label>

                <Textbox
                  defaultValue={mapping.lastName}
                  onChange={(e) => {
                    setMapping({ ...mapping, lastName: e })
                  }}
                  placeholder="Last Name"
                />
              </LabelTextboxWrapper>
              <LabelTextboxWrapper>
                <Label>Company</Label>

                <Textbox
                  defaultValue={mapping.company}
                  onChange={(e) => {
                    setMapping({ ...mapping, company: e })
                  }}
                  placeholder="Company"
                />
              </LabelTextboxWrapper>
              <LabelTextboxWrapper>
                <Label>Email</Label>

                <Textbox
                  type="email"
                  defaultValue={mapping.email}
                  onChange={(e) => {
                    setMapping({ ...mapping, email: e })
                  }}
                  placeholder="Email"
                />
              </LabelTextboxWrapper>
              <LabelTextboxWrapper>
                <Label>Phone</Label>

                <Textbox
                  value={mapping.phone}
                  defaultValue={mapping.phone}
                  onChange={(e) => {
                    handlePhoneNum(e)
                  }}
                  placeholder="Phone"
                />
              </LabelTextboxWrapper>
            </SubjectSearchSecurityContainer>
            <TextEditorWrapper>
              <Label>Notes</Label>
              <Textarea
                defaultValue={mapping.notes}
                onChange={(e: any) => {
                  setMapping({ ...mapping, notes: e.target.value })
                }}
                placeholder="Type..."
              />
            </TextEditorWrapper>
          </EmailBodyWrapper>
        </EmailModalWrapper>
      )}

      {/*Delete*/}
      {toggleDeactivate && (
        <DeleteContactPopup>
          <StyledSelectWrapper
            style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
          >
            <SvgButtonWrapper
              onClick={() => {
                SetToggleEdit(false)
              }}
            >
              <SvgExclamation
                fillColor={COLORS.Icons.DangerIcon}
              ></SvgExclamation>
            </SvgButtonWrapper>
            Delete Contact
            <MarginLeftAutoSpace>
              <SvgButtonWrapper
                onClick={() => {
                  SetToggleDeactivate(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </SvgButtonWrapper>
            </MarginLeftAutoSpace>
          </StyledSelectWrapper>
          <Divider
            thickness={'1px'}
            horizontal={true}
            bgColor={Backgrounds.Gray110}
          />
          <StyledSelectWrapper style={{ padding: 15 }}>
            <>{`Are you sure want to Delete?`}</>
          </StyledSelectWrapper>
          <StyledSelectWrapper
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 20,
            }}
          >
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              bgColor={'transparent'}
              onClick={() => {
                SetToggleDeactivate(false)
              }}
            >
              <Label fontWeight={600}> Cancel</Label>
            </Button>
            <Button
              hoverBgColor={COLORS.Background.Primary70}
              bgColor={COLORS.Background.Primarytext}
              color={COLORS.Background.Primary}
              padding={'4px 16px'}
              borderRadius={'4px'}
              hover={true}
              type={''}
              activeBgColor={COLORS.Background.Primaryactive}
              borderColor={'transparent'}
              onClick={() => {
                deactive()
              }}
            >
              Delete
            </Button>
          </StyledSelectWrapper>
        </DeleteContactPopup>
      )}
      {/* {toastPopup && (
        <ToastWrapper>
          <Toast
            text={status}
            type={response ? 'success' : 'danger'}
            openStatusCallback={() => {
              setToastPopup(false)
            }}
          />
        </ToastWrapper>
      )} */}
    </EmailModalWrapper>
  )
}

export default AddEmail
