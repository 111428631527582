import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Label from '../../../../components/label/Label'
import SelectDropdownWithCheckBox from '../../../../components/selectDropdownWithCheckbox/SelectDropdown'
import { WDInputLabel } from '../../../../components/ui/WDTypography'
import {
  EmailModalBody,
  EmailPopUpWrapper,
  PopUpActionButtonWrapper,
  TextEditorWrapper,
  NoEmailPopUpWrapper,
  ErrorMessage,
} from '../styles'
import Button from '../../../../components/button/Button'
import { useSelector } from 'react-redux'
import { EmailIdListSelector } from '../../../../store/trade-break/trade-break'
import {
  OptionValueModel,
  SelectValueModel,
} from '../../../../shared/models/SelectValues.model'
import { MailingDetailsInterface } from './InotifyEmail'
import { Textbox } from '../../../../components/textbox/Textbox'
import { boolean } from 'zod'

const EmailPopUp = (props: any) => {
  const modules = {
    toolbar: [['bold', 'italic', 'underline'], [{ list: 'bullet' }]],
  }
  const [notes, setNotes] = useState<any>([])
  const [validationErrors, setValidationErrors] = useState<boolean>(false)
  const [emailError, setEmailErrors] = useState<any>([])
  const [noteError, setNoteErrors] = useState<any>([])
  const emailIdList = useSelector(EmailIdListSelector)
  const [emailIds, setEmailIds] = useState<any>([])
  const [optionValues, setOptionValues] = useState<any>([])
  type Element = {
    email: []
    mpid: string
    desk: string | null
  }
  useEffect(() => {
    const optionValues: any = []
    emailIdList?.mpidEmailModel?.length &&
      emailIdList.mpidEmailModel.map((element: Element, key: number) => {
        optionValues[key] = element?.email.map((ele: any) => {
          return { label: ele, value: ele }
        })
      })
    setEmailIds(optionValues)
    setOptionValues(optionValues)
  }, [])

  const notifyEmail = () => {
    const emailErrorArray: any = []
    const notesErrorArray: any = []

    emailIds.map((email: string) => {
      if (email.length) {
        emailErrorArray.push(false)
      } else emailErrorArray.push(true)
    })

    notes.map((note: string) => {
      if (note.length >= 1000) {
        notesErrorArray.push(true)
      } else notesErrorArray.push(false)
    })
    setEmailErrors(emailErrorArray)
    setNoteErrors(notesErrorArray)

    if (
      !emailErrorArray?.find((ele: boolean) => ele) &&
      (!notesErrorArray?.find((ele: boolean) => ele) || !notes.length)
    ) {
      setValidationErrors(false)
      props.notifyApi(emailIds, notes)
    } else {
      setValidationErrors(true)
    }
  }
  return (
    <>
      {emailIdList?.mpidEmailModel?.length && optionValues.length ? (
        <>
          <EmailPopUpWrapper>
            {emailIdList?.mpidEmailModel.map(
              (element: Element, key: number) => {
                return (
                  <>
                    <EmailModalBody key={key}>
                      <div>
                        <Label>MPID:</Label>
                        <Textbox
                          value={element.mpid}
                          disabled={true}
                          style={{
                            borderRadius: '4px',
                            height: '32px',
                            padding: '4px 8px',
                          }}
                        />
                      </div>
                      <div>
                        <Label>Desk:</Label>
                        <Label padding={'4px'}>
                          {element.desk ? element.desk : '-'}
                        </Label>
                      </div>
                      <div>
                        <Label
                          fontSize="16px"
                          fontWeight={400}
                          color={'#0F172A'}
                          lineHeight={'24px'}
                        >
                          Email
                        </Label>
                        <SelectDropdownWithCheckBox
                          height="32px"
                          isMulti={true}
                          buttonWidth="250px"
                          padding="4px 16px"
                          borderRadius="4px"
                          fw={100}
                          lh="24px"
                          fs="16px"
                          options={optionValues[key]}
                          defaultValue={emailIds[key]}
                          optionValues={optionValues[key]}
                          value={emailIds[key]}
                          onChange={(options: any) => {
                            const temp = [...emailIds]
                            temp[key] = options
                            setEmailIds(temp)
                          }}
                          allowSelectAll={true}
                          whiteSpace={'nowrap'}
                          overflow={'hidden'}
                          flexWrap={'nowrap'}
                          labelValue={'EmailIds'}
                          defaultCheckAll={true}
                        />{' '}
                        {validationErrors && emailError[key] ? (
                          <ErrorMessage>*Email is required</ErrorMessage>
                        ) : null}
                      </div>
                      <TextEditorWrapper>
                        <Label>Add Note:</Label>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          onChange={(value: any) => {
                            const temp = [...notes]
                            temp[key] = value
                            setNotes(temp)
                          }}
                          placeholder="Type..."
                          style={{
                            maxWidth: '100%',
                            resize: 'none',
                          }}
                        />
                        {validationErrors && noteError[key] ? (
                          <ErrorMessage>
                            *notes should be less than 1000 characters
                          </ErrorMessage>
                        ) : null}
                      </TextEditorWrapper>
                    </EmailModalBody>
                  </>
                )
              }
            )}

            <PopUpActionButtonWrapper>
              <Button
                hoverBgColor={'#A7AFBC'}
                hoverTextColor={'#E2E8F0'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#E2E8F0'}
                color={'#A7AFBC'}
                type={'Button'}
                margin={'0px 6px'}
                borderColor={'#A7AFBC'}
                onClick={() => props.setNotifyModalToggle(false)}
              >
                Cancel
              </Button>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                borderColor={'#2563EB'}
                onClick={() => notifyEmail()}
                disabled={!emailIds && !emailIds.length ? true : false}
              >
                Send
              </Button>
            </PopUpActionButtonWrapper>
          </EmailPopUpWrapper>
        </>
      ) : (
        <NoEmailPopUpWrapper>
          <WDInputLabel>No Email Configured for this MPID</WDInputLabel>
          <PopUpActionButtonWrapper>
            <Button
              hoverBgColor={'#A7AFBC'}
              hoverTextColor={'#E2E8F0'}
              borderRadius={'4px'}
              hover={true}
              padding={'4px 16px'}
              bgColor={'#E2E8F0'}
              color={'#A7AFBC'}
              type={'Button'}
              margin={'0px 6px'}
              borderColor={'#A7AFBC'}
              onClick={() => props.setNotifyModalToggle(false)}
            >
              Cancel
            </Button>
          </PopUpActionButtonWrapper>
        </NoEmailPopUpWrapper>
      )}
    </>
  )
}

export default EmailPopUp
