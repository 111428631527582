import { TextboxProps, Main, Wrapper } from './styles'

export const Textbox = ({
  backgroundColor,
  disabled,
  type,
  placeholder,
  required,
  onChange,
  onBlur,
  defaultValue,
  name,
  value,
  id,
  max,
  borderColor,
  innerRef,
  readOnly,
  style,
  autoFocus
}: TextboxProps) => {
  return (
    <Wrapper
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange?.(e.target.value)
      }
    >
      <Main
      style={style}
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        required={required}
        onBlur={onBlur}
        max={max}
        ref={innerRef}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        readOnly={readOnly}
        autoFocus={autoFocus}
      ></Main>
    </Wrapper>
  )
}
