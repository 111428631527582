/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  CommentTableContainer,
  FailSummaryCard,
  FilterCard,
  FilterOptionWrapper,
  PopupCard,
  PopUpStyleContent,
  RootContainer,
  SelectWrapper,
  StyledSelectWrapper,
  StyledTableContainer,
  StyledTableFailSummary,
  SubHeading,
  PopUpStyleLoader,
  ToastWrapper,
  LodingTable,
  LottieWrapper,
  RadioCommentWrapper,
  MarginLeftAutoSpace,
  LoadingTextSpace,
  ButtonWrapper,
  CancelButtonWrapper,
} from './styles'
import {
  actionSelector,
  betaFailAnalysisDataSelector,
  commentSelector,
  getBetaCSV,
  getBetaEXCEL,
  getBetaFailAnalysis,
  getComments,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  isLoadingSelector,
  putUserComment,
  setAction,
  SetBetaFailAnalysis,
  setHttpErrorMessage,
  setSortDirection,
  setSorting,
  setSuccessMessage,
  sortDirectionSelector,
  sortingSelector,
} from '../../../../store/failAnalysis/beta-fail-analysis'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { RootContainerTable } from '../styles'
import {
  WDStyledButtonText,
  WDStyledHeading,
} from '../../../../components/ui/WDTypography'
import { Backgrounds } from '../../../../shared/styles'
import { CardHeader } from '../../../../composites/cardHeader/CardHeader'
import { Toast } from '../../../../components/toast/Toast'
import { Textarea } from '../../../../components/textArea/Textarea'
import { Textbox } from '../../../../components/textbox/Textbox'
import Button from '../../../../components/button/Button'
import Label from '../../../../components/label/Label'
import WDCard from '../../../../components/ui/WDCard'
import Loader from '../../../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import BetaTable from './betaTable/BetaTable'
import SelectDropdown from '../../../../components/selectDropdown/SelectDropdown'
import Divider from '../../../../components/divider/Divider'
import { COLORS } from '../../../../theme/Colors'
import { SvgDownloadSmall } from '../../../../components/svg/logo/SvgDownload'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import { addDays, formatISO } from 'date-fns'
import WeekDayCalender from '../../../../components/WeekDayCalander/WeekDayCalender'
import { FormateDateSelector } from '../../../../store/dash-board/about'
import { getAccessFlag } from '../../../../utils/globalFunctions'
import Checkbox from '../../../../components/checkbox/Checkbox'

const BetaFailAnalysisDaily = () => {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const location = useLocation()
  const Usercomments = useSelector(commentSelector)
  const ExcludeDate = useSelector(FormateDateSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const sorting = useSelector(sortingSelector)
  const sortDirection = useSelector(sortDirectionSelector)
  const data = useSelector(betaFailAnalysisDataSelector)
  const buffering = useSelector(isLoadingSelector)
  const action = useSelector(actionSelector)
  const [inputComment, setInputComment] = useState<any>([])
  const [resetTable, setResetTable] = useState<boolean>(false)
  const [openComments, setOpenComments] = useState<boolean>(false)
  const [popUpLoader, setPopUpLoader] = useState<boolean>(false)
  const [hidePagination, setHidePagination] = useState<boolean>(false)
  const [loadingBuffer, setLoadingBuffer] = useState<boolean>(false)
  const [comment, setComments] = useState<boolean>(false)
  const [Calendar, setCalender] = useState<boolean>(false)
  const [includeFailComments, setIncludeFailComments] = useState<number>(0)
  const [ageRange, setAgeRange] = useState('')
  const [buySell, setBuySell] = useState('')
  const [typeValue, setTypeValue] = useState('')
  const [checkedValue, setCheckedValue] = useState(0)
  const DefaultDate: string =
    localStorage.getItem('DefaultDate') &&
    localStorage.getItem('DefaultDate') != 'undefined'
      ? JSON.parse(localStorage.getItem('DefaultDate') || '')
      : ''
  const [date, setDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )
  const [type] = useState({
    value: 'daily',
    label: 'Daily',
  })
  const [input, setInput] = useState({
    commentDate: new Date().toISOString(),
    cusip: '',
    subsidiaryNumber: '',
    branch: '',
    accountNumber: '',
    pageNumber: 0,
    commentRow: 0,
  })

  const ageOptions = [
    { value: '', label: 'Select' },
    { value: '1', label: '1' },
    { value: '2 to 5', label: '2 to 5' },
    { value: '6 to 30', label: '6 to 30' },
    { value: '30 and more', label: '30 more' },
  ]
  const typeOption = [
    { value: 'daily', label: 'Daily' },
    { value: 'history', label: 'Historical' },
  ]
  const BSoption = [
    { value: '', label: 'Select' },
    { value: 'S', label: 'Sell' },
    { value: 'B', label: 'Buy' },
  ]

  const writeExecuteFlag = getAccessFlag(['SS-RWX', 'OM-RWX'])

  const handleSearch = async () => {
    setResetTable(true)
    setInput({ ...input, pageNumber: 1 })
    dispatch(setSortDirection('ASC'))
    dispatch(setSorting('SnapShotDate, Age'))
    setLoadingBuffer(true)
    const response = await dispatch(
      getBetaFailAnalysis(
        'search',
        type.value,
        formatISO(date).split('T')[0],
        formatISO(date).split('T')[0],
        ageRange,
        input.accountNumber,
        input.branch,
        input.subsidiaryNumber,
        buySell,
        input.cusip,
        0,
        20,
        sorting,
        sortDirection,
        '',
        includeFailComments,
        typeValue,
        checkedValue
      )
    )
    setLoadingBuffer(false)
    setResetTable(false)
  }
  const getDetails = async (data: any) => {
    setResetTable(true)
    setInput({ ...input, pageNumber: 1 })
    dispatch(setSortDirection('ASC'))
    dispatch(setSorting('SnapShotDate, Age'))
    setLoadingBuffer(true)
    const response = await dispatch(
      getBetaFailAnalysis(
        'search',
        type.value,
        data.reportRunDate,
        data.reportRunDate,
        data.age,
        input.accountNumber,
        input.branch,
        input.subsidiaryNumber,
        buySell,
        input.cusip,
        0,
        20,
        sorting,
        sortDirection,
        '',
        includeFailComments,
        data.type === 'F/D' ? 'Deliver' : 'Receive',
        checkedValue
      )
    )
    setLoadingBuffer(false)
    setResetTable(false)
  }

  useEffect(() => {
    const setFilters = async (param: any) => {
      if (param && param?.data) {
        const data = param?.data
        await setAgeRange(data.age)
        await setDate(new Date(data.reportRunDate))
        await setTypeValue(data.type === 'F/D' ? 'Deliver' : 'Receive')
        getDetails(data)
      }
    }
    const params = location.state
    setFilters(params)
  }, [location.state])

  useEffect(() => {
    dispatch(setAction(''))
    dispatch(
      SetBetaFailAnalysis({
        data: [],
      })
    )
  }, [])

  const savedComments = async (tb: any) => {
    setPopUpLoader(true)
    await dispatch(
      getComments(
        'Beta',
        data.betaReport[tb].failUniqueId,
        data.betaReport[tb].controlNumber
      )
    )
    setPopUpLoader(false)
    setOpenComments(true)
    setHidePagination(true)
  }

  const DownloadEXCEL = async () => {
    setPopUpLoader(true)
    const response = await dispatch(
      getBetaEXCEL(
        `BETAFail_${type.value}_${formatISO(date).split('T')[0]}`,
        type.value,
        formatISO(date).split('T')[0],
        formatISO(date).split('T')[0],
        ageRange,
        input.accountNumber,
        input.branch,
        input.subsidiaryNumber,
        buySell,
        input.cusip,
        0,
        0,
        'SnapShotDate, Age',
        'ASC',
        '',
        includeFailComments,
        typeValue,
        checkedValue
      )
    )

    setPopUpLoader(false)
  }
  const DownloadCSV = async () => {
    setPopUpLoader(true)
    const response = await dispatch(
      getBetaCSV(
        `BETAFail_${type.value}_${formatISO(date).split('T')[0]}`,
        type.value,
        formatISO(date).split('T')[0],
        formatISO(date).split('T')[0],
        ageRange,
        input.accountNumber,
        input.branch,
        input.subsidiaryNumber,
        buySell,
        input.cusip,
        0,
        0,
        'SnapShotDate, Age',
        'ASC',
        '',
        includeFailComments,
        typeValue,
        checkedValue
      )
    )

    setPopUpLoader(false)
  }
  const newUserComment = async (
    type: string,
    failId: string,
    comment: string,
    user: any,
    controlNumber: number,
    rowId: number
  ) => {
    setPopUpLoader(true)
    const response = await dispatch(
      putUserComment(
        'Beta',
        failId,
        comment,
        user,
        controlNumber,
        input.commentDate
      )
    )
    inputComment[rowId] = '  '
    setInputComment({ ...inputComment })
    setPopUpLoader(false)
  }

  function onChangeValue(event: any) {
    setIncludeFailComments(parseInt(event.target.value))
  }

  function checkCheckbox(event: any) {
    setCheckedValue(event.target.checked ? 1 : 0)
  }

  return (
    <RootContainer>
      <WDStyledHeading>
        <Label>{'Beta Fail Analysis'}</Label>
      </WDStyledHeading>
      <StyledSelectWrapper
        style={{
          padding: '0px 5px',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonWrapper
          onClick={async () => {
            DownloadCSV()
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label>CSV</Label>
          </WDStyledButtonText>
        </ButtonWrapper>
        <ButtonWrapper
          onClick={async () => {
            DownloadEXCEL()
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label>EXCEL</Label>
          </WDStyledButtonText>
        </ButtonWrapper>
      </StyledSelectWrapper>
      <SelectWrapper>
        <FilterCard>
          <SubHeading>Filter Fails</SubHeading>
          <StyledSelectWrapper>
            <FilterOptionWrapper>
              <Label>Type</Label>
              <SelectDropdown
                defaultValue={{
                  value: 'daily',
                  label: 'Daily',
                }}
                borderRadius={'4px'}
                height="32px"
                options={typeOption}
                onChange={(e) => {
                  {
                    e === 'history' && navigation('history')
                  }
                }}
              ></SelectDropdown>
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>Select Date</Label>
              <WeekDayCalender
                onSelect={() => setCalender(false)}
                excludeDates={ExcludeDate}
                open={Calendar}
                onFocus={() => setCalender(true)}
                onClickOutside={() => setCalender(false)}
                selected={date}
                maxDate={addDays(new Date(), -1)}
                onChange={(e: Date) => {
                  setDate(e)
                }}
              />
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>Age Range</Label>
              <SelectDropdown
                defaultValue={{
                  value: '',
                  label: 'Select',
                }}
                borderRadius={'4px'}
                height="32px"
                options={ageOptions}
                onChange={(e) => {
                  setAgeRange(e)
                }}
                value={ageOptions?.find((b: any) => b.value === ageRange)}
              ></SelectDropdown>
            </FilterOptionWrapper>
          </StyledSelectWrapper>
          <StyledSelectWrapper>
            <FilterOptionWrapper>
              <Label>Buy/Sell</Label>
              <SelectDropdown
                defaultValue={{
                  value: '',
                  label: 'Select',
                }}
                borderRadius={'4px'}
                height="32px"
                options={BSoption}
                onChange={(e) => {
                  setBuySell(e)
                }}
                value={BSoption?.find((b: any) => b.value === buySell)}
              ></SelectDropdown>
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>Account Number</Label>
              <Textbox
                placeholder="Enter Account Number"
                onChange={(e) => {
                  setInput({ ...input, accountNumber: e })
                }}
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>Branch</Label>
              <Textbox
                onChange={(e) => {
                  setInput({ ...input, branch: e })
                }}
                placeholder="Enter Branch"
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
            </FilterOptionWrapper>
          </StyledSelectWrapper>
          <StyledSelectWrapper>
            <FilterOptionWrapper>
              <Label>CUSIP/Symbol</Label>
              <Textbox
                placeholder="Enter CUSIP/Symbol"
                onChange={(e) => {
                  setInput({ ...input, cusip: e })
                }}
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>Subsidiary Number</Label>
              <Textbox
                placeholder="Subsidiary Number"
                onChange={(e) => {
                  setInput({ ...input, subsidiaryNumber: e })
                }}
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Checkbox
                title={'Money Only'}
                checked={checkedValue == 1}
                onChange={checkCheckbox}
              />
            </FilterOptionWrapper>
          </StyledSelectWrapper>
          <StyledSelectWrapper></StyledSelectWrapper>
          <StyledSelectWrapper>
            <RadioCommentWrapper>
              <input
                type="radio"
                value={0}
                name="AllFailComments"
                checked={includeFailComments == 0}
                onChange={onChangeValue}
              />{' '}
              <span>All FailComments</span>
            </RadioCommentWrapper>
            <RadioCommentWrapper>
              <input
                type="radio"
                value={1}
                name="includeFailComments"
                checked={includeFailComments == 1}
                onChange={onChangeValue}
              />{' '}
              <span>Include Comments</span>
            </RadioCommentWrapper>
            <RadioCommentWrapper>
              <input
                type="radio"
                value={2}
                name="includeFailComments"
                checked={includeFailComments == 2}
                onChange={onChangeValue}
              />{' '}
              <span>Exclude Comments</span>
            </RadioCommentWrapper>
            <MarginLeftAutoSpace>
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  handleSearch()
                }}
              >
                Search
              </Button>
            </MarginLeftAutoSpace>
          </StyledSelectWrapper>
        </FilterCard>

        {(action === 'search' || action === 'sort') && (
          <FailSummaryCard>
            <SubHeading>Fail Summary</SubHeading>

            <StyledTableFailSummary>
              <RootContainerTable>
                <table>
                  <thead>
                    <tr>
                      <th>Age</th>
                      <th>Type</th>
                      <th>Total Fails</th>
                      <th>Total Quantity</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.betaSummary &&
                      data.betaSummary.map((element: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td>{element.age}</td>
                            <td>{element.type}</td>
                            <td>{element.totalFails}</td>
                            <td>
                              {element.totalQuantity &&
                                element.totalQuantity
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </td>
                            <td>
                              ${' '}
                              {element.totalAmount &&
                                element.totalAmount
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </td>
                          </tr>
                        )
                      })}
                    <tr>
                      {data.vSum_SumAmount && (
                        <>
                          <td>
                            <h4>Sum</h4>
                          </td>
                          <td></td>
                          <td>
                            <h4>{data.vSum_TotalFails}</h4>
                          </td>
                          <td>
                            <h4>
                              {data.vSum_Quantity &&
                                data.vSum_Quantity
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </h4>
                          </td>
                          <td>
                            <h4>
                              ${' '}
                              {data.vSum_SumAmount &&
                                data.vSum_SumAmount
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </h4>
                          </td>
                        </>
                      )}
                    </tr>
                  </tbody>
                </table>
              </RootContainerTable>
            </StyledTableFailSummary>
          </FailSummaryCard>
        )}
      </SelectWrapper>
      {buffering && loadingBuffer && (
        <>
          <WDCard>
            <CardHeader
              label={'Beta Fail Analysis'}
              icon={false}
              amountValue={`Total: $ 0.00`}
            />
          </WDCard>
          <StyledTableContainer>
            <LodingTable>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LoadingTextSpace>
                <Label> Loading...</Label>
              </LoadingTextSpace>
            </LodingTable>
          </StyledTableContainer>
        </>
      )}

      {popUpLoader && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <LoadingTextSpace>
            <Label> Loading...</Label>
          </LoadingTextSpace>{' '}
        </PopUpStyleLoader>
      )}
      {!resetTable && (action === 'search' || action === 'sort') && (
        <>
          <WDCard>
            <CardHeader
              label={'Beta Fail Analysis'}
              icon={false}
              amountValue={`Total: $ ${
                data.vSum_SumAmount
                  ? data.vSum_SumAmount
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                  : 0
              }`}
            />
          </WDCard>
          <StyledTableContainer>
            {openComments && (
              <PopUpStyleContent>
                <StyledSelectWrapper
                  style={{
                    padding: '15px 15px 0px 15px',
                    fontWeight: '600',
                    borderRadius: '8px 8px 0px 0px',
                  }}
                >
                  <>View Comment</>
                  <MarginLeftAutoSpace>
                    <CancelButtonWrapper
                      onClick={() => {
                        setOpenComments(false)
                      }}
                    >
                      <SvgCancel
                        fillColor={COLORS.Icons.NeutralIcon}
                      ></SvgCancel>
                    </CancelButtonWrapper>
                  </MarginLeftAutoSpace>
                  <Divider
                    thickness={'1px'}
                    horizontal={true}
                    bgColor={Backgrounds.Gray110}
                  />
                </StyledSelectWrapper>
                <CommentTableContainer>
                  <BetaTable
                    data={Usercomments ? Usercomments : []}
                    hidePagination={hidePagination}
                    columns={[
                      {
                        Header: 'CreatedBy',
                        accessor: 'createdBy',
                      },
                      {
                        Header: 'CreatedOn(EST)',
                        accessor: 'createdOn',
                      },
                      {
                        Header: 'Comment',
                        accessor: 'comment',
                      },
                    ]}
                  />
                  {Usercomments.length == 0 && <h3>No Comments</h3>}
                </CommentTableContainer>
              </PopUpStyleContent>
            )}
            <BetaTable
              columns={[
                {
                  Header: 'Age',
                  accessor: 'age',
                },
                {
                  Header: 'SnapShot Date',
                  accessor: 'snapShotDate',
                },
                {
                  Header: 'Add Note',
                  Cell: (table: any) => {
                    return (
                      <>
                        <Button
                          padding={'4px 16px'}
                          borderRadius={'4px'}
                          type={'button'}
                          color={COLORS.Background.Primarytext}
                          style={{ whiteSpace: 'nowrap' }}
                          disabled={!writeExecuteFlag}
                          bgColor={'transparent'}
                          onClick={() => {
                            setComments(!comment)

                            setInput({
                              ...input,
                              commentRow: table.cell.row.id,
                            })
                          }}
                        >
                          Add Notes
                        </Button>

                        {comment && table.cell.row.id === input.commentRow && (
                          <PopupCard>
                            <Textarea
                              onChange={(e: any) => {
                                inputComment[table.cell.row.id] = e.target.value
                                setInputComment(inputComment)
                              }}
                              height="365px"
                              placeholder={'Enter Comment'}
                            ></Textarea>

                            <StyledSelectWrapper
                              style={{
                                padding: '2px 0px 0px 0px',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                padding={'4px 16px'}
                                borderRadius={'4px'}
                                type={'button'}
                                color={COLORS.Background.Primarytext}
                                bgColor={'transparent'}
                                onClick={() => {
                                  setComments(false)
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                hoverBgColor={COLORS.Background.Primary70}
                                bgColor={COLORS.Background.Primarytext}
                                color={COLORS.Background.Primary}
                                padding={'4px 16px'}
                                borderRadius={'4px'}
                                hover={true}
                                type={''}
                                activeBgColor={COLORS.Background.Primaryactive}
                                borderColor={'transparent'}
                                onClick={async () => {
                                  await newUserComment(
                                    'Beta',
                                    data.betaReport[table.cell.row.id]
                                      .failUniqueId,
                                    inputComment[table.cell.row.id],
                                    localStorage.getItem('AccountName'),
                                    data.betaReport[table.cell.row.id]
                                      .controlNumber,
                                    table.cell.row.id
                                  )
                                  setComments(false)
                                }}
                              >
                                Submit
                              </Button>
                            </StyledSelectWrapper>
                          </PopupCard>
                        )}
                      </>
                    )
                  },
                },
                {
                  Header: 'All Comments',
                  Cell: (table: any) => {
                    return (
                      <Link
                        style={{
                          color: COLORS.Background.Primarytext,
                          cursor: 'pointer',
                        }}
                        onClick={() => savedComments(table.cell.row.id)}
                        to={''}
                      >
                        View comments
                      </Link>
                    )
                  },
                },
                {
                  Header: 'Account',
                  accessor: 'accountNumber',
                },
                {
                  Header: 'B/S',
                  accessor: 'buy_Sell',
                },
                {
                  Header: 'CUSIP',
                  accessor: 'cusip',
                },
                {
                  Header: 'Sec Nbr',
                  accessor: 'securityNumber',
                },
                {
                  Header: 'Symbol',
                  accessor: 'symbol',
                },

                {
                  Header: 'Qnty',
                  accessor: 'quantity',
                },
                {
                  Header: 'Partial Qnty',
                  accessor: 'partialQuantity',
                },
                {
                  Header: 'Trade Date',
                  accessor: 'tradeDate',
                },
                {
                  Header: 'Settle Date',
                  accessor: 'settleDate',
                },
                {
                  Header: 'Entry Date',
                  accessor: 'entryDate',
                },
                {
                  Header: 'Price',
                  accessor: 'price',
                },
                {
                  Header: 'Amount',
                  accessor: 'amount',
                },
                {
                  Header: 'DTC Number',
                  accessor: 'dtcNum',
                },
                {
                  Header: 'Market Value',
                  accessor: 'marketValue',
                },
                {
                  Header: 'DTC Eligibility',
                  accessor: 'dtC_CNS_Eligibility',
                },
                {
                  Header: 'Failed BookLocation',
                  accessor: 'failedBookLocation',
                },
                {
                  Header: 'Counter PartyType',
                  accessor: 'counterPartyType',
                },
                {
                  Header: 'Account Name',
                  accessor: 'accountName',
                },
                {
                  Header: 'Control Number',
                  accessor: 'controlNumber',
                },
              ]}
              type={type.value}
              startDate={date ? formatISO(date).split('T')[0] : ''}
              endDate={date ? formatISO(date).split('T')[0] : ''}
              age={ageRange}
              accNum={input.accountNumber}
              branch={input.branch}
              subNum={input.subsidiaryNumber}
              BS={buySell}
              cusip={input.cusip}
              data={data.betaReport ? data.betaReport : []}
              totalRecords={data.vSum_TotalFails}
              includeFailComments={includeFailComments}
              typeValue={typeValue}
              checkedValue={checkedValue}
            />
          </StyledTableContainer>
        </>
      )}
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default BetaFailAnalysisDaily
