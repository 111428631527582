/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  tradeManagement: []
  isLoading: boolean
  action: string
  entityType: []
  age: []
  entityCode: []
  receiveFailReport: []
  updateReceiveFailReport: []
  deliverFailReport: []
  exceptionUpdate: any
  comments: []
  dtccError: []
  addComment: any
  httpErrorMessage: string
  successMessage: string
  receivableProducts: []
}

const initialState: State = {
  tradeManagement: [],
  isLoading: false,
  action: '',
  entityType: [],
  age: [],
  entityCode: [],
  receiveFailReport: [],
  updateReceiveFailReport: [],
  deliverFailReport: [],
  exceptionUpdate: {},
  comments: [],
  dtccError: [],
  addComment: {},
  httpErrorMessage: '',
  successMessage: '',
  receivableProducts: [],
}

const { actions, reducer }: Slice = createSlice({
  name: 'trade-break',
  initialState,
  reducers: {
    setReceiveFailReport: (state: State, action: PayloadAction<any>) => {
      state.receiveFailReport = action.payload.data
    },
    setUpdateReceiveFailReport: (state: State, action: PayloadAction<any>) => {
      state.updateReceiveFailReport = action.payload.data
    },
    setDeliverFailReport: (state: State, action: PayloadAction<any>) => {
      state.deliverFailReport = action.payload.data
    },
    setFTRComments: (state: State, action: PayloadAction<any>) => {
      state.comments = action.payload.data
    },
    setDTCCError: (state: State, action: PayloadAction<any>) => {
      state.dtccError = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },

    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setExceptionUpdate: (state: State, action: PayloadAction<any>) => {
      state.exceptionUpdate = action.payload.data
    },
    SetCommentResponse: (state: State, action: PayloadAction<boolean>) => {
      state.addComment = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
    setReceivableProducts: (state: State, action: PayloadAction<any>) => {
      state.receivableProducts = action.payload
    },
  },
})

export const {
  setAction,
  setIsLoading,
  setReceiveFailReport,
  setUpdateReceiveFailReport,
  setDeliverFailReport,
  setExceptionUpdate,
  setFTRComments,
  SetCommentResponse,
  setDTCCError,
  setHttpErrorMessage,
  setSuccessMessage,
  setReceivableProducts,
} = actions

export const getReceiveFailReport: any =
  (
    securityCategory: string,
    source: string,
    fromDate: string,
    toDate: string,
    cusip: string,
    symbol: string,
    quantity: string,
    accountNumber: string,
    broker: string,
    counterpartyType: string,
    pageSize: number,
    pageNumber: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/ReceiveFailReport',
        {
          securityCategory,
          source,
          fromDate,
          toDate,
          cusip,
          symbol,
          quantity,
          accountNumber,
          broker,
          counterpartyType,
          pageSize,
          pageNumber,
        }
      )

      if (status === 200) {
        dispatch(
          setReceiveFailReport({
            data: data,
          })
        )

        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setReceiveFailReport({
          data: [],
        })
      )
      dispatch(setIsLoading(false))
    }
  }

export const getReceiveFailReportException: any =
  (
    securityCategory: string,
    source: string,
    fromDate: string,
    toDate: string,
    cusip: string,
    symbol: string,
    quantity: string,
    accountNumber: string,
    broker: string,
    counterpartyType: string,
    pageSize: number,
    pageNumber: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/ReceiveFailReportInvalid',
        {
          securityCategory,
          source,
          fromDate,
          toDate,
          cusip,
          symbol,
          quantity,
          accountNumber,
          broker,
          counterpartyType,
          pageSize,
          pageNumber,
        }
      )

      if (status === 200) {
        dispatch(
          setReceiveFailReport({
            data: data,
          })
        )

        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setReceiveFailReport({
          data: [],
        })
      )
      dispatch(setIsLoading(false))
    }
  }

export const getReceiveDTCCFiledReport: any =
  (
    securityCategory: string,
    source: string,
    fromDate: string,
    toDate: string,
    cusip: string,
    symbol: string,
    quantity: string,
    accountNumber: string,
    broker: string,
    counterpartyType: string,
    statusCode: string,
    pageSize: number,
    pageNumber: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/GetDTCCFiledData',
        {
          securityCategory,
          source,
          fromDate,
          toDate,
          cusip,
          symbol,
          quantity,
          accountNumber,
          broker,
          counterpartyType,
          statusCode,
          pageSize,
          pageNumber,
        }
      )

      if (status === 200) {
        dispatch(
          setReceiveFailReport({
            data: data,
          })
        )

        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setReceiveFailReport({
          data: [],
        })
      )
      dispatch(setIsLoading(false))
    }
  }

export const generateDTCCFile: any =
  (
    securityCategory: string,
    source: string,
    fromDate: string,
    toDate: string,
    cusip: string,
    symbol: string,
    quantity: string,
    accountNumber: string,
    broker: string,
    counterpartyType: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/GenerateDTCCFile',
        {
          securityCategory,
          source,
          fromDate,
          toDate,
          cusip,
          symbol,
          quantity,
          accountNumber,
          broker,
          counterpartyType,
        }
      )

      if (status === 200) {
        if (
          data.errorResponse.statusCode === null ||
          data.errorResponse.statusCode === ''
        ) {
          data.errorResponseList.length > 1
            ? dispatch(
                setSuccessMessage(`${data.errorResponseList[0].message}
          ${data.errorResponseList[1].message}`)
              )
            : dispatch(
                setSuccessMessage(`${data.errorResponseList[0].message}
          `)
              )
        } else {
          dispatch(setHttpErrorMessage(`${data.errorResponse.message}`))
        }
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
    }
  }

export const getReceiveFailReportExcel: any =
  (
    filename: string,
    securityCategory: string,
    source: string,
    fromDate: string,
    toDate: string,
    cusip: string,
    symbol: string,
    quantity: string,
    accountNumber: string,
    broker: string,
    counterpartyType: string,
    secType: string,
    pageSize: number,
    pageNumber: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/ExportReceiveFailReport',
        {
          securityCategory,
          source,
          fromDate,
          toDate,
          cusip,
          symbol,
          quantity,
          accountNumber,
          broker,
          counterpartyType,
          secType,
          pageSize,
          pageNumber,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Downloaded Successfully'))
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}.xlsm`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        return status
      }
      dispatch(setIsLoading(false))
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      return 400
    }
  }

export const getExceptionReceiveFailReportExcel: any =
  (
    filename: string,
    securityCategory: string,
    source: string,
    fromDate: string,
    toDate: string,
    cusip: string,
    symbol: string,
    quantity: string,
    accountNumber: string,
    broker: string,
    counterpartyType: string,
    secType: string,
    pageSize: number,
    pageNumber: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/FTRExceptionReport',
        {
          securityCategory,
          source,
          fromDate,
          toDate,
          cusip,
          symbol,
          quantity,
          accountNumber,
          broker,
          counterpartyType,
          secType,
          pageSize,
          pageNumber,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Downloaded Successfully'))
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        return status
      }
      dispatch(setIsLoading(false))
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      return 400
    }
  }

export const getReceiveDTCCFiledReportExcel: any =
  (
    filename: string,
    fromDate: string,
    toDate: string,
    source: string,
    securityCategory: string,
    cusip: string,
    symbol: string,
    accountNumber: string,
    quantity: string,
    broker: string,
    counterpartyType: string,
    secType: string,
    statusCode: string,
    pageNumber: number,
    pageSize: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/ExportDTCCFiledData',
        {
          fromDate,
          toDate,
          source,
          securityCategory,
          cusip,
          symbol,
          accountNumber,
          quantity,
          broker,
          counterpartyType,
          secType,
          statusCode,
          pageNumber,
          pageSize,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Downloaded Successfully'))
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        return status
      }
      dispatch(setIsLoading(false))
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      return 400
    }
  }

export const getDeliverFailReport: any =
  (
    source: string,
    status: string,
    settleDateFrom: string | null,
    settleDateTo: string | null,
    jobRunDateFrom: string,
    jobRunDateTo: string,
    cusip: string,
    broker: string,
    pageSize: number,
    pageNumber: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const response = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/FTDMatchAndUnmatch',
        {
          source,
          status,
          settleDateFrom,
          settleDateTo,
          jobRunDateFrom,
          jobRunDateTo,
          cusip,
          broker,
          pageSize,
          pageNumber,
        }
      )

      if (response.status === 200) {
        dispatch(
          setDeliverFailReport({
            data: response.data,
          })
        )

        dispatch(setIsLoading(false))
        return response.data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
    }
  }

export const getDeliverFailReportExcel: any =
  (
    filename: string,
    source: string,
    status: string,
    settleDateFrom: string | null,
    settleDateTo: string | null,
    jobRunDateFrom: string,
    jobRunDateTo: string,
    cusip: string,
    quantity: string,
    broker: string,
    pageSize: number,
    pageNumber: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const response = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/ExportMatchAndUnmatch',
        {
          source,
          status,
          settleDateFrom,
          settleDateTo,
          jobRunDateFrom,
          jobRunDateTo,
          cusip,
          quantity,
          broker,
          pageSize,
          pageNumber,
        },
        { responseType: 'blob' }
      )

      if (response.status === 200) {
        dispatch(setSuccessMessage('Downloaded Successfully'))
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        return status
      }
      dispatch(setIsLoading(false))
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      return 400
    }
  }

export const updateDetails: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/FTRExceptionAction',
        payload
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Updated Successfully'))
        dispatch(
          setExceptionUpdate({
            data: data,
          })
        )

        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
    }
  }

export const getFTRComments: any =
  (ftrUniqueId: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        `opsfailprocessapi/BuyInIntents/GetSettlementHistoricComments?ftrUniqueId=${ftrUniqueId}`
      )
      if (status === 200) {
        dispatch(
          setFTRComments({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
    }
  }

export const saveFTRComment: any =
  (request: any) => async (dispatch: Dispatch) => {
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/AddSettlementHistoricComments',
        request
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Added Successfully'))
        dispatch(
          SetCommentResponse({
            data: data,
          })
        )
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        SetCommentResponse({
          data: { tradeBreakActionModels: [] },
        })
      )
      return {
        data: {
          actionCountModel: [
            {
              actionCount: 0,
            },
          ],
        },
      }
    }
  }

export const getDTCCFiledError: any =
  (id: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/BuyInIntents/BuyInDTCCErrorMessages?id=${id}`
      )
      if (status === 200) {
        dispatch(setDTCCError(data.buyInDtccErrorMsgResponse))
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
    }
  }

export const updateFTRProduct: any =
  (request: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/UpdateSecTypeMasterDetails',
        { ...request, buyInActiveYN: request.buyInActiveYN ? 'Y' : 'N' }
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Updated Successfully'))
        dispatch(
          SetCommentResponse({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        SetCommentResponse({
          data: [],
        })
      )
      dispatch(setIsLoading(false))
      return {
        data: {
          actionCountModel: [
            {
              actionCount: 0,
            },
          ],
        },
      }
    }
  }

export const saveFTRProduct: any =
  (request: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/AddSecTypeMasterDetails',
        { ...request, buyInActiveYN: request.buyInActiveYN ? 'Y' : 'N' }
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Added Successfully'))
        dispatch(
          SetCommentResponse({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        SetCommentResponse({
          data: [],
        })
      )
      dispatch(setIsLoading(false))
      return {
        data: {
          actionCountModel: [
            {
              actionCount: 0,
            },
          ],
        },
      }
    }
  }

export const getFTRProducts: any =
  (request: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        '/opsfailprocessapi/BuyInIntents/SecTypeMasterDetails?source=null',
        request
      )

      if (status === 200) {
        dispatch(
          setReceivableProducts({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setReceivableProducts({
          data: { secTypeMasterModel: [] },
        })
      )
      dispatch(setIsLoading(false))
      return
    }
  }

export const deactivateFTRProducts: any =
  (request: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/BuyInIntents/DeleteSecTypeMasterDetails?source=${request.source}&Id=${request.id}`
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Deleted Successfully'))
        dispatch(
          setReceivableProducts({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setReceivableProducts({
          data: { secTypeMasterModel: [] },
        })
      )
      dispatch(setIsLoading(false))
      return
    }
  }

export const saveErrorSettlementsColumnsValues: any =
  (
    ftrUniqueId: string,
    secType: string,
    delvBroker: string,
    source: string,
    saveFixFlag: number,
    comments: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/FTRInvalidAction',
        {
          ftrUniqueId,
          secType,
          delvBroker,
          source,
          saveFixFlag,
          comments,
        }
      )
      if (status === 200) {
        saveFixFlag == 0
          ? dispatch(setSuccessMessage('Saved successfully'))
          : dispatch(setSuccessMessage('Fixed successfully'))
        dispatch(
          setUpdateReceiveFailReport({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      return null
    }
  }

export const receiveFailReportSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.receiveFailReport

export const deliverFailReportSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.deliverFailReport

export const actionSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.action

export const loadingSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.isLoading

export const ftrCommentsSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.comments

export const entityCodeSelector = (store: StoreState) =>
  store.EquityBreakManage.EquityBreakManagement.entityCode

export const updateExceptionReportSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.exceptionUpdate

export const dtccErrorSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.dtccError

export const ToastMessageSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.toastMessage

export const ToastResponseSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.toastResponse

export const ReceivableProductsSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.receivableProducts
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.httpErrorMessage

export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.BuyIns.BuyInSettlements.successMessage
export default reducer
