/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addList,
  currentDataSelector,
  deactivateMapping,
  getDataList,
  updateList,
  LoadingSelector,
  setIsLoading,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../store/staticData/securities-to-p3'
import {
  LabelTextboxWrapeer,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  RootContainer,
  StyledSelectWrapper,
  StyledTableContainer,
  SvgButtonWrapper,
  ToastWrapper,
} from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import { Backgrounds } from '../../../shared/styles'
import { Textbox } from '../../../components/textbox/Textbox'
import { Icons } from '../../../shared/GlobalStyle'
import { Toast } from '../../../components/toast/Toast'
import Button from '../../../components/button/Button'
import Table from '../../../components/table/Table'
import WDCard from '../../../components/ui/WDCard'
import Loader from '../../../assets/lottiefiles/loader.json'
import Label from '../../../components/label/Label'
import Divider from '../../../components/divider/Divider'
import Lottie from 'lottie-react'
import { COLORS } from '../../../theme/Colors'
import { StyledImage } from '../../../components/image/styles'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import { SvgExclamation } from '../../../components/svg/logo/SvgExclamation'
import { PopUpStyleLoader } from '../countryCode/styles'
import { getAccessFlag } from '../../../utils/globalFunctions'

const SecuritiesToP3 = () => {
  const dispatch = useDispatch()
  const data = useSelector(currentDataSelector)
  const loading = useSelector(LoadingSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const [toggleEdit, SetToggleEdit] = useState<boolean>(false)
  const [toggleInsert, SetToggleInsert] = useState<boolean>(false)
  const [toggleDeactivate, SetToggleDeactivate] = useState<boolean>(false)
  const [res, setRes] = useState<number>(0)
  const [mapping, setMapping] = useState({
    id: 0,
    flexTradeTicker: '',
    p3Ticker: '',
    isActive: true,
  })
  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)

  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['SD-RWX', 'OM-RWX']))
  }, [])

  const addMapping = () => {
    setMapping({
      id: 0,
      flexTradeTicker: '',
      p3Ticker: '',
      isActive: true,
    })
    SetToggleInsert(true)
  }
  useEffect(() => {
    async function valdiData() {
      dispatch(setIsLoading(true))
      const response = await dispatch(getDataList())
      setRes(response)
    }
    valdiData()
  }, [])

  const addNew = async (flexTradeTicker: string, p3Ticker: string) => {
    SetToggleInsert(false)
    await dispatch(addList(flexTradeTicker, p3Ticker))
    await dispatch(getDataList())
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    })
  }
  const update = async (
    id: number,
    flexTradeTicker: string,
    p3Ticker: string
  ) => {
    SetToggleEdit(false)
    await dispatch(updateList(id, flexTradeTicker, p3Ticker, true))
    await dispatch(getDataList())
  }
  const deactive = async (
    id: number,
    flexTradeTicker: string,
    p3Ticker: string
  ) => {
    SetToggleDeactivate(false)
    await dispatch(deactivateMapping(id, flexTradeTicker, p3Ticker, false))
    await dispatch(getDataList())
  }
  return (
    <RootContainer>
      <WDCard>
        <CardHeader label={'Securities to P3 Translator'} />
      </WDCard>
      <StyledSelectWrapper style={{ justifyContent: 'flex-end' }}>
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          gap={'8px'}
          type={''}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          disabled={!writeExecuteFlag}
          onClick={() => {
            addMapping()
          }}
        >
          Add New
        </Button>
      </StyledSelectWrapper>
      <StyledTableContainer>
        <Table
          tableColumns={[
            {
              Header: 'FlexTrade Ticker ',
              accessor: 'flexTradeTicker',
            },

            {
              Header: 'P3 Ticker',
              accessor: 'p3Ticker',
            },
            {
              Header: 'Deactivate',
              Cell: (e: any) => {
                return writeExecuteFlag ? (
                  <input
                    style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                    title="check to deactivate"
                    type="checkbox"
                    defaultChecked={data[e.cell.row.id].isActive === false}
                    disabled={data[e.cell.row.id].isActive === false}
                    onClick={() => {
                      setMapping({
                        id: data[e.cell.row.id].id,
                        flexTradeTicker: data[e.cell.row.id].flexTradeTicker,
                        p3Ticker: data[e.cell.row.id].p3Ticker,
                        isActive: false,
                      })
                      SetToggleDeactivate(true)
                    }}
                  ></input>
                ) : (
                  <>-</>
                )
              },
            },
            {
              Header: 'Edit',
              Cell: (e: any) => {
                return writeExecuteFlag ? (
                  <StyledImage
                    title="Edit"
                    onClick={() => {
                      setMapping({
                        id: data[e.cell.row.id].id,
                        flexTradeTicker: data[e.cell.row.id].flexTradeTicker,
                        p3Ticker: data[e.cell.row.id].p3Ticker,
                        isActive: data[e.cell.row.id].isActive,
                      })

                      data[e.cell.row.id].isActive === true &&
                        SetToggleEdit(true)
                    }}
                    width={'25px'}
                    style={{ cursor: 'pointer' }}
                    src={Icons.Edit}
                    alt=""
                  />
                ) : (
                  <>-</>
                )
              },
            },
          ]}
          tableData={data}
          onClick={() => {
            //
          }}
        />
        {res == 200 && data.length == 0 && (
          <>
            <Label fontWeight={700} margin="5% 0 0 50%">
              No Records Found
            </Label>
            <Label fontWeight={700} margin="0.5% 0 0 49%">
              No Exceptions Identified
            </Label>
          </>
        )}
        {loading && (
          <PopUpStyleLoader>
            <StyledSelectWrapper style={{ justifyContent: 'center' }}>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LottieWrapper style={{ top: '40.5%' }}>
                {' '}
                Loading...
              </LottieWrapper>
            </StyledSelectWrapper>
          </PopUpStyleLoader>
        )}
        {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
          <ToastWrapper>
            <Toast
              text={SuccessMessage || ErrorMessage}
              type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
              openStatusCallback={() => {
                dispatch(setHttpErrorMessage(''))
                dispatch(setSuccessMessage(''))
              }}
            />
          </ToastWrapper>
        )}
        {/*Insert*/}
        {toggleInsert && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              Add Mapping
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleInsert(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <LabelTextboxWrapeer>
                <Label color={'black'}> FlexTrade Ticker</Label>
                <Textbox
                  placeholder="Enter FlexTrade Ticker"
                  onChange={(e) => {
                    setMapping({ ...mapping, flexTradeTicker: e })
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}>P3 Ticker</Label>
                <Textbox
                  placeholder="Enter P3 Ticker"
                  onChange={(e) => {
                    setMapping({ ...mapping, p3Ticker: e })
                  }}
                />
              </LabelTextboxWrapeer>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleInsert(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>{' '}
              {mapping.flexTradeTicker && mapping.p3Ticker ? (
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={() => {
                    addNew(mapping.flexTradeTicker, mapping.p3Ticker)
                  }}
                >
                  Add
                </Button>
              ) : (
                <Button
                  bgColor={COLORS.Text.NeutralTextDisabled}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={''}
                  borderColor={'transparent'}
                >
                  Add
                </Button>
              )}
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
        {/*Edit*/}
        {toggleEdit && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              <>Update Mapping</>
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleEdit(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <LabelTextboxWrapeer>
                <Label color={'black'}>FlexTrade Ticker</Label>
                <Textbox
                  placeholder="FlexTrade Ticker"
                  defaultValue={mapping.flexTradeTicker}
                  onChange={(e) => {
                    setMapping({ ...mapping, flexTradeTicker: e })
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}>P3 Ticker</Label>
                <Textbox
                  placeholder="P3 Ticker"
                  defaultValue={mapping.p3Ticker}
                  onChange={(e) => {
                    setMapping({ ...mapping, p3Ticker: e })
                  }}
                />
              </LabelTextboxWrapeer>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleEdit(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>{' '}
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  update(mapping.id, mapping.flexTradeTicker, mapping.p3Ticker)
                }}
              >
                Update
              </Button>
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
        {/* Deactivate */}
        {toggleDeactivate && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              <SvgButtonWrapper
                onClick={() => {
                  SetToggleEdit(false)
                }}
              >
                <SvgExclamation
                  fillColor={COLORS.Icons.DangerIcon}
                ></SvgExclamation>
              </SvgButtonWrapper>
              Deactivate Mapping
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleDeactivate(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <>
                {`Are you sure want to deactivate? Once Deactivated can't Activate
                again.`}
              </>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleDeactivate(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  deactive(
                    mapping.id,
                    mapping.flexTradeTicker,
                    mapping.p3Ticker
                  )
                }}
              >
                Deactivate
              </Button>
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
      </StyledTableContainer>
    </RootContainer>
  )
}

export default SecuritiesToP3
