/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall, authApiUpload } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  adrFeeFileUploadData: []
  adrPastFileUploadDataHistory: []
  isLoading: boolean
  action: string
  status: string
  response: boolean
  sorting: any
  sortDirection: any
  pageNumber: number
  TotalCount: number
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  adrFeeFileUploadData: [],
  adrPastFileUploadDataHistory: [],
  isLoading: false,
  action: '',
  status: '',
  response: false,
  sorting: 'Cusip',
  sortDirection: 'ASC',
  pageNumber: 0,
  TotalCount: 0,
  httpErrorMessage: '',
  successMessage: '',
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'ADR-Fee-Upload-Report',
  initialState,
  reducers: {
    SetADRFeeFileUploadData: (state: State, action: PayloadAction<any>) => {
      state.adrFeeFileUploadData = action.payload.data
    },
    SetADRPastFileUploadDataHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      state.adrPastFileUploadDataHistory = action.payload.data
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setSorting: (state: State, action: PayloadAction<any>) => {
      state.sorting = action.payload
    },
    setSortDirection: (state: State, action: PayloadAction<any>) => {
      state.sortDirection = action.payload
    },
    setResponse: (state: State, action: PayloadAction<boolean>) => {
      state.response = action.payload
    },
    setStatus: (state: State, action: PayloadAction<string>) => {
      state.status = action.payload
    },
    setPageNumberADRFee: (state: State, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    setTotalCount: (state: State, action: PayloadAction<number>) => {
      state.TotalCount = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  SetADRFeeFileUploadData,
  SetADRPastFileUploadDataHistory,
  setIsLoading,
  setAction,
  setSorting,
  setSortDirection,
  setStatus,
  setResponse,
  setPageNumberADRFee,
  setTotalCount,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const csvFileUpload: any =
  (FormFile: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiUpload.post(
        '/opsstaticdataapi/AssetServicing/Adr_Fees_BackGroundUpload',
        {
          FormFile,
        }
      )
      if (status === 200) {
        // dispatch(setStatus('Uploaded Successfully'))
        dispatch(setSuccessMessage('File Uploaded Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        return 200
      }
    } catch (e: any) {
      // dispatch(setStatus('Something Went Wrong'))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      dispatch(setResponse(false))
      return 400
    }
  }

export const getADRFeeFileUploadData: any =
  (
    action: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    totalCount: number,
    amount: string,
    account: string,
    cusip: string,
    accountType: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/GetAdrFeeReport',
        {
          fromDate,
          toDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          totalCount,
          amount,
          account,
          cusip,
          accountType,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetADRFeeFileUploadData({
            data: data,
          })
        )
        dispatch(
          setTotalCount({
            data: data.totalCount,
          })
        )
        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        SetADRFeeFileUploadData({
          data: '',
        })
      )
      return null
    }
  }

export const getADRFeeFileUploadDataCSV: any =
  (
    fileName: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    totalCount: number,
    amount: string,
    account: string,
    cusip: string,
    accountType: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/GetAdrFeeReport_CsvDownload',
        {
          fromDate,
          toDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          totalCount,
          amount,
          account,
          cusip,
          accountType,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('File Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return 400
    }
  }

export const getADRPastFileUploadData: any =
  (action: string, fromDate: string, toDate: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/GetAdrFeeFileUpload',
        {
          fromDate,
          toDate,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetADRPastFileUploadDataHistory({
            data: data,
          })
        )
        dispatch(
          setTotalCount({
            data: data.totalCount,
          })
        )
        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        SetADRPastFileUploadDataHistory({
          data: '',
        })
      )
      return null
    }
  }

export const dataSelector = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.adrFeeFileUploadData
export const pastFiledataSelector = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.adrPastFileUploadDataHistory
export const currentDataSelector = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.data
export const LoadingSelector = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.isLoading
export const actionSelector = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.action
export const sortingSelectorADRFee = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.sorting
export const sortDirectionSelectorADRFee = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.sortDirection
export const statusSelector = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.status
export const ResponseSelector = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.response
export const PageNumSelectorADRFee = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.pageNumber
export const TotalCountSelectorADRFee = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.TotalCount
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.assetService.ADRFeeUploadReport.successMessage

export default reducer
