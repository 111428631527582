import { FocusEventHandler } from 'react'
import { GroupBase } from 'react-select'
import Select from 'react-select/dist/declarations/src/Select'
import styled from 'styled-components'

export interface OptionProps {
  value: string
  label: string
}
export interface DefaultProps {
  value: string
  label: string
}

interface InputFunc {
  (value:  OptionProps[]):  void
}
export interface SelectDropdownProps {
  options: OptionProps[]
  onChange?: InputFunc
  allowSelectAll?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  innerRef?: React.RefObject<
    Select<DefaultProps, boolean, GroupBase<DefaultProps>>
  >
  defaultValue?: DefaultProps
  value?: DefaultProps
  isMulti?: boolean
  icon?: string
  image?: string
  borderColor?: string
  borderRadius?: string
  isDisabled?: boolean
  isSearchable?: boolean
  indicatorSeparator?: boolean
  width?: string
  height?: string
  name?: string
  placeholder?: string
  border?:any
  color?:any
  buttonWidth?:any
  padding?:any
  fs?: string
  fw?: number
  lh?: string
  optionValues?:any
  overflow?:any
  whiteSpace?:any
  textOverflow?:any
  flexWrap?:any
  labelValue?:string
  defaultCheckAll?:boolean
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
