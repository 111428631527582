export const AuditTrailColumn = [
  {
    Header: 'Source',
    accessor: 'source',
  },
  {
    Header: 'BuyInType',
    accessor: 'buyInType',
  },
  {
    Header: 'FieldChanged',
    accessor: 'fieldChanged',
  },
  {
    Header: 'Old Value',
    accessor: 'oldValue',
  },
  {
    Header: 'New Value',
    accessor: 'newValue',
  },
  {
    Header: 'Created By',
    accessor: 'createdBy',
  },
  {
    Header: 'Create On',
    accessor: 'createdOn',
  },
]
