/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '../../../../components/button/Button'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Label from '../../../../components/label/Label'
import SvgVerticalDivider from '../../../../components/svg/SvgVerticalDivider'
import {
  WDStyledButtonText,
  WDStyledHeading,
} from '../../../../components/ui/WDTypography'
import { COLORS } from '../../../../theme/Colors'
import { useNavigate } from 'react-router-dom'
import {
  ButtonIconWrapper,
  ButtonWrapper,
  DateWrapper,
  FileUploadInput,
  FilterContainer,
  LeftSpace,
  MarginLeftAutoSpace,
  MarginTopSpace,
  MonthPickerLabelWrapper,
  RefreshDownloadWrapper,
  RootContainer,
  RowWrapper,
  UploadWrapper,
  StyledSelectWrapper,
  SvgButtonWrapper,
  ToastWrapper,
  LottieWrapper,
  PopUpStyleContentBBG,
  FirstContainer,
  SecondContainer,
  ThirdContainer,
  PopUpStyleLoader,
} from '../styles'

import {
  actionSelector,
  bbgUpload,
  dataSelector,
  getP3StalePriceData,
  getP3StalePriceDataCSV,
  LoadingSelector,
  ResponseSelector,
  setAction,
  SetP3StalePriceData,
  SetP3StalePriceDataHistory,
  setPageNumberP3,
  setResponse,
  setSortDirection,
  setSorting,
  setStatusMessage,
  sortDirectionSelectorP3,
  sortingSelectorP3,
  statusSelector,
  TotalCountSelectorP3,
} from '../../../../store/staticData/stale-pricing-p3'
import { useDispatch, useSelector } from 'react-redux'
import SvgRedo from '../../../../components/svg/SvgRedo'
import { SvgDownloadSmall } from '../../../../components/svg/logo/SvgDownload'
import WDCard from '../../../../components/ui/WDCard'
import { CardHeader } from '../../../../composites/cardHeader/CardHeader'
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'

import { Backgrounds } from '../../../../shared/styles'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import Divider from '../../../../components/divider/Divider'
import { WDLabel } from '../../../../components/ui/WDLabel'

const EXTENSIONS = ['csv', 'txt']

import P3StaleTable from './P3TableColumns'

import { Toast } from '../../../../components/toast/Toast'
import Lottie from 'lottie-react'
import Loader from '../../../../assets/lottiefiles/loader.json'
import P3TableFilter from './p3TableFilter/P3TableFilter'
import { P3TableFilterList } from './p3TableFilter/P3TableFilterData'
import SelectDropdown from '../../../../components/selectDropdown/SelectDropdown'
import { WDButton, WDButtonDisabled } from '../../../../components/ui/WDButton'
import { getAccessFlag } from '../../../../utils/globalFunctions'

const P3StalePricing = () => {
  const navigation = useNavigate()
  const TotalCount = useSelector(TotalCountSelectorP3)
  const sorting = useSelector(sortingSelectorP3)
  const sortDirection = useSelector(sortDirectionSelectorP3)
  const action = useSelector(actionSelector)
  const data = useSelector(dataSelector)
  const response = useSelector(ResponseSelector)
  const status = useSelector(statusSelector)
  const loading = useSelector(LoadingSelector)
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [fileName, setFileName] = useState('')
  const [uploadType, setUploadType] = useState<string>('bbg')
  const fileOptions = [
    { value: 'bbg', label: 'BBG Upload' },
    { value: 'ice', label: 'ICE Upload' },
    { value: 'dtcc_beta', label: 'DTCC Upload(BETA)' },
    { value: 'dtcc_p3', label: 'DTCC Upload(P3)' },
  ]
  const [fileData, setFileData] = useState<any>()
  const [BBGPOPUP, setBBGPOPUP] = useState<boolean>(false)
  const [isCSV, setIsCSV] = useState(true)
  const fileUpload = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const defaultDate = new Date()
  defaultDate.setMonth(defaultDate.getMonth(), 0)
  const [startDate, setStartDate] = useState(defaultDate)
  const [toggle, setToggle] = useState<boolean>(false)
  const [resetState, setResetState] = useState<boolean>(false)
  const [resetTable, setResetTable] = useState<boolean>(false)

  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  const lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)

  let mm: string | number
  let firstDayOfMonth: string | number
  let lastDayOfMonth: string | number

  mm = startDate.getMonth() + 1
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  lastDayOfMonth = lastDay.getDate()
  lastDayOfMonth = '' + lastDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth
  const toDate = yyyy + '-' + mm + '-' + lastDayOfMonth

  const current = new Date()

  const monthNames = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]

  const monthYear = monthNames[startDate.getMonth()] + startDate.getFullYear()

  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)

  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['SD-RWX', 'OM-RWX']))
  }, [])

  const getExtension = (file: File) => {
    const parts = file.name.split('.')
    const extension = parts[parts.length - 1]
    setIsCSV(EXTENSIONS.includes(extension))
    return EXTENSIONS.includes(extension)
  }

  const handleDrop = (uploadedFile: File) => {
    setFileName(uploadedFile.name)
    if (getExtension(uploadedFile)) {
      setIsFileUploaded(true)
      const reader = new FileReader()
      reader.readAsBinaryString(uploadedFile)
      setFileData(uploadedFile)
    } else {
      setIsFileUploaded(false)
    }
  }

  const preventDefaults = (event: BaseSyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDropFile = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer.files
    handleDrop(files[0])
  }

  const handleFiles = (event: BaseSyntheticEvent) => {
    handleDrop(event.target.files[0])
  }

  const browseFile = () => {
    fileUpload.current?.click()
  }
  const handleSearch = async () => {
    const response = await dispatch(bbgUpload(fileData, uploadType))
    setBBGPOPUP(false)
    const uploadElement = document.getElementById(
      'fileElement'
    ) as HTMLInputElement
    uploadElement.value = ''
    setIsFileUploaded(false)

    setFileData('')
  }

  const handleSubmit = async () => {
    setResetTable(true)
    dispatch(setPageNumberP3(0))
    P3TableFilterList.forEach((obj) => {
      obj.showDropdown = false
    })
    setIsTableVisible(false)

    const securityDescription =
      P3TableFilterList[0]?.dropDownArray[0].value &&
      P3TableFilterList[0].selectedData[0]
        ? P3TableFilterList[0].selectedData.join(';').toString() + ';' + 'Blank'
        : P3TableFilterList[0]?.dropDownArray[0].value &&
          !P3TableFilterList[0].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[0].selectedData.join(';').toString()

    let top_Category = ''
    if (
      P3TableFilterList[1].dropDownArray.filter((option) => option.value)
        .length == P3TableFilterList[1].dropDownArray.length
    ) {
      top_Category = ''
    } else {
      top_Category = P3TableFilterList[1].dropDownArray
        .filter((obj) => obj.value)
        .map((obj) => obj.label)
        .join(';')
        .toString()
    }

    let sub_Category = ''
    if (
      P3TableFilterList[2].dropDownArray.filter((option) => option.value)
        .length == P3TableFilterList[2].dropDownArray.length
    ) {
      sub_Category = ''
    } else {
      sub_Category = P3TableFilterList[2].dropDownArray
        .filter((obj) => obj.value)
        .map((obj) => obj.label)
        .join(';')
        .toString()
    }

    const reviewer_LatestComment =
      P3TableFilterList[3]?.dropDownArray[0].value &&
      P3TableFilterList[3].selectedData[0]
        ? P3TableFilterList[3].selectedData.join(';').toString() + ';' + 'Blank'
        : P3TableFilterList[3]?.dropDownArray[0].value &&
          !P3TableFilterList[3].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[3].selectedData.join(';').toString()

    const security_Type_and_Sub_Type_Code =
      P3TableFilterList[4]?.dropDownArray[0].value &&
      P3TableFilterList[4].selectedData[0]
        ? P3TableFilterList[4].selectedData.join(';').toString() + ';' + 'Blank'
        : P3TableFilterList[4]?.dropDownArray[0].value &&
          !P3TableFilterList[4].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[4].selectedData.join(';').toString()

    const lastPrice_In_Current_StaleReport =
      P3TableFilterList[5]?.dropDownArray[0].value &&
      P3TableFilterList[5].selectedData[0]
        ? P3TableFilterList[5].selectedData.join(';').toString() + ';' + 'Blank'
        : P3TableFilterList[5]?.dropDownArray[0].value &&
          !P3TableFilterList[5].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[5].selectedData.join(';').toString()

    let followup_Flag: string
    if (
      P3TableFilterList[6].dropDownArray.filter((option) => option.value)
        .length == P3TableFilterList[6].dropDownArray.length
    ) {
      followup_Flag = ''
    } else {
      followup_Flag = P3TableFilterList[6].dropDownArray
        .filter((obj) => obj.value)
        .map((obj) => obj.labelValue)
        .join(';')
        .toString()
    }

    const cusip_Number =
      P3TableFilterList[7]?.dropDownArray[0].value &&
      P3TableFilterList[7].selectedData[0]
        ? P3TableFilterList[7].selectedData.join(';').toString() + ';' + 'Blank'
        : P3TableFilterList[7]?.dropDownArray[0].value &&
          !P3TableFilterList[7].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[7].selectedData.join(';').toString()

    const isiN_NUMBER =
      P3TableFilterList[8]?.dropDownArray[0].value &&
      P3TableFilterList[8].selectedData[0]
        ? P3TableFilterList[8].selectedData.join(';').toString() + ';' + 'Blank'
        : P3TableFilterList[8]?.dropDownArray[0].value &&
          !P3TableFilterList[8].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[8].selectedData.join(';').toString()

    const comments_Created_By =
      P3TableFilterList[9]?.dropDownArray[0].value &&
      P3TableFilterList[9].selectedData[0]
        ? P3TableFilterList[9].selectedData.join(';').toString() + ';' + 'Blank'
        : P3TableFilterList[9]?.dropDownArray[0].value &&
          !P3TableFilterList[9].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[9].selectedData.join(';').toString()

    const reviewer_Last_Commented_Date =
      P3TableFilterList[10]?.dropDownArray[0].value &&
      P3TableFilterList[10].selectedData[0]
        ? P3TableFilterList[10].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[10]?.dropDownArray[0].value &&
          !P3TableFilterList[10].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[10].selectedData.join(';').toString()

    const icE_Last_Priced =
      P3TableFilterList[11]?.dropDownArray[0].value &&
      P3TableFilterList[11].selectedData[0]
        ? P3TableFilterList[11].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[11]?.dropDownArray[0].value &&
          !P3TableFilterList[11].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[11].selectedData.join(';').toString()

    const icE_Last_Price =
      P3TableFilterList[12]?.dropDownArray[0].value &&
      P3TableFilterList[12].selectedData[0]
        ? P3TableFilterList[12].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[12]?.dropDownArray[0].value &&
          !P3TableFilterList[12].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[12].selectedData.join(';').toString()

    const icE_Notes =
      P3TableFilterList[13]?.dropDownArray[0].value &&
      P3TableFilterList[13].selectedData[0]
        ? P3TableFilterList[13].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[13]?.dropDownArray[0].value &&
          !P3TableFilterList[13].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[13].selectedData.join(';').toString()

    const bbG_Last_Priced =
      P3TableFilterList[14]?.dropDownArray[0].value &&
      P3TableFilterList[14].selectedData[0]
        ? P3TableFilterList[14].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[14]?.dropDownArray[0].value &&
          !P3TableFilterList[14].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[14].selectedData.join(';').toString()

    const bbG_Last_Price =
      P3TableFilterList[15]?.dropDownArray[0].value &&
      P3TableFilterList[15].selectedData[0]
        ? P3TableFilterList[15].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[15]?.dropDownArray[0].value &&
          !P3TableFilterList[15].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[15].selectedData.join(';').toString()

    const boxLocation =
      P3TableFilterList[16]?.dropDownArray[0].value &&
      P3TableFilterList[16].selectedData[0]
        ? P3TableFilterList[16].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[16]?.dropDownArray[0].value &&
          !P3TableFilterList[16].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[16].selectedData.join(';').toString()

    const locationMemo =
      P3TableFilterList[17]?.dropDownArray[0].value &&
      P3TableFilterList[17].selectedData[0]
        ? P3TableFilterList[17].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[17]?.dropDownArray[0].value &&
          !P3TableFilterList[17].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[17].selectedData.join(';').toString()

    const boxDesc =
      P3TableFilterList[18]?.dropDownArray[0].value &&
      P3TableFilterList[18].selectedData[0]
        ? P3TableFilterList[18].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[18]?.dropDownArray[0].value &&
          !P3TableFilterList[18].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[18].selectedData.join(';').toString()

    const country =
      P3TableFilterList[19]?.dropDownArray[0].value &&
      P3TableFilterList[19].selectedData[0]
        ? P3TableFilterList[19].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[19]?.dropDownArray[0].value &&
          !P3TableFilterList[19].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[19].selectedData.join(';').toString()

    const securityTypeCode =
      P3TableFilterList[20]?.dropDownArray[0].value &&
      P3TableFilterList[20].selectedData[0]
        ? P3TableFilterList[20].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[20]?.dropDownArray[0].value &&
          !P3TableFilterList[20].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[20].selectedData.join(';').toString()

    const maturity_WarrantExpire_Date =
      P3TableFilterList[21]?.dropDownArray[0].value &&
      P3TableFilterList[21].selectedData[0]
        ? P3TableFilterList[21].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[21]?.dropDownArray[0].value &&
          !P3TableFilterList[21].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[21].selectedData.join(';').toString()

    const inDefault =
      P3TableFilterList[22]?.dropDownArray[0].value &&
      P3TableFilterList[22].selectedData[0]
        ? P3TableFilterList[22].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[22]?.dropDownArray[0].value &&
          !P3TableFilterList[22].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[22].selectedData.join(';').toString()

    const description2 =
      P3TableFilterList[23]?.dropDownArray[0].value &&
      P3TableFilterList[23].selectedData[0]
        ? P3TableFilterList[23].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[23]?.dropDownArray[0].value &&
          !P3TableFilterList[23].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[23].selectedData.join(';').toString()

    const description3 =
      P3TableFilterList[24]?.dropDownArray[0].value &&
      P3TableFilterList[24].selectedData[0]
        ? P3TableFilterList[24].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[24]?.dropDownArray[0].value &&
          !P3TableFilterList[24].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[24].selectedData.join(';').toString()

    const eodcmvdaT_LastPriced =
      P3TableFilterList[25]?.dropDownArray[0].value &&
      P3TableFilterList[25].selectedData[0]
        ? P3TableFilterList[25].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[25]?.dropDownArray[0].value &&
          !P3TableFilterList[25].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[25].selectedData.join(';').toString()

    const eodcmvdaT_LastPrice =
      P3TableFilterList[26]?.dropDownArray[0].value &&
      P3TableFilterList[26].selectedData[0]
        ? P3TableFilterList[26].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[26]?.dropDownArray[0].value &&
          !P3TableFilterList[26].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[26].selectedData.join(';').toString()

    const issue_Status_Code =
      P3TableFilterList[27]?.dropDownArray[0].value &&
      P3TableFilterList[27].selectedData[0]
        ? P3TableFilterList[27].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[27]?.dropDownArray[0].value &&
          !P3TableFilterList[27].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[27].selectedData.join(';').toString()

    const dtC_Premed_Elig =
      P3TableFilterList[28]?.dropDownArray[0].value &&
      P3TableFilterList[28].selectedData[0]
        ? P3TableFilterList[28].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[28]?.dropDownArray[0].value &&
          !P3TableFilterList[28].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[28].selectedData.join(';').toString()

    const maturityDate =
      P3TableFilterList[29]?.dropDownArray[0].value &&
      P3TableFilterList[29].selectedData[0]
        ? P3TableFilterList[29].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[29]?.dropDownArray[0].value &&
          !P3TableFilterList[29].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[29].selectedData.join(';').toString()

    const couponRate =
      P3TableFilterList[30]?.dropDownArray[0].value &&
      P3TableFilterList[30].selectedData[0]
        ? P3TableFilterList[30].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[30]?.dropDownArray[0].value &&
          !P3TableFilterList[30].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[30].selectedData.join(';').toString()

    const symbol =
      P3TableFilterList[31]?.dropDownArray[0].value &&
      P3TableFilterList[31].selectedData[0]
        ? P3TableFilterList[31].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[31]?.dropDownArray[0].value &&
          !P3TableFilterList[31].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[31].selectedData.join(';').toString()

    const currency =
      P3TableFilterList[32]?.dropDownArray[0].value &&
      P3TableFilterList[32].selectedData[0]
        ? P3TableFilterList[32].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[32]?.dropDownArray[0].value &&
          !P3TableFilterList[32].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[32].selectedData.join(';').toString()

    const lastPriceDate =
      P3TableFilterList[33]?.dropDownArray[0].value &&
      P3TableFilterList[33].selectedData[0]
        ? P3TableFilterList[33].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[33]?.dropDownArray[0].value &&
          !P3TableFilterList[33].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[33].selectedData.join(';').toString()

    const lastPrice =
      P3TableFilterList[34]?.dropDownArray[0].value &&
      P3TableFilterList[34].selectedData[0]
        ? P3TableFilterList[34].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[34]?.dropDownArray[0].value &&
          !P3TableFilterList[34].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[34].selectedData.join(';').toString()

    const cashQnty =
      P3TableFilterList[35]?.dropDownArray[0].value &&
      P3TableFilterList[35].selectedData[0]
        ? P3TableFilterList[35].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[35]?.dropDownArray[0].value &&
          !P3TableFilterList[35].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[35].selectedData.join(';').toString()

    const marginQnty =
      P3TableFilterList[36]?.dropDownArray[0].value &&
      P3TableFilterList[36].selectedData[0]
        ? P3TableFilterList[36].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[36]?.dropDownArray[0].value &&
          !P3TableFilterList[36].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[36].selectedData.join(';').toString()

    const inventory =
      P3TableFilterList[37]?.dropDownArray[0].value &&
      P3TableFilterList[37].selectedData[0]
        ? P3TableFilterList[37].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[37]?.dropDownArray[0].value &&
          !P3TableFilterList[37].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[37].selectedData.join(';').toString()

    const currently_Still_in_StaleReport =
      P3TableFilterList[38]?.dropDownArray[0].value &&
      P3TableFilterList[38].selectedData[0]
        ? P3TableFilterList[38].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[38]?.dropDownArray[0].value &&
          !P3TableFilterList[38].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[38].selectedData.join(';').toString()

    const last_Priced_In_Current_StaleReport =
      P3TableFilterList[39]?.dropDownArray[0].value &&
      P3TableFilterList[39].selectedData[0]
        ? P3TableFilterList[39].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[39]?.dropDownArray[0].value &&
          !P3TableFilterList[39].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[39].selectedData.join(';').toString()

    const commentFilter =
      P3TableFilterList[40]?.dropDownArray[0].value &&
      P3TableFilterList[40].selectedData[0]
        ? P3TableFilterList[40].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[40]?.dropDownArray[0].value &&
          !P3TableFilterList[40].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[40].selectedData.join(';').toString()

    const notes =
      P3TableFilterList[41]?.dropDownArray[0].value &&
      P3TableFilterList[41].selectedData[0]
        ? P3TableFilterList[41].selectedData.join(';').toString() +
          ';' +
          'Blank'
        : P3TableFilterList[41]?.dropDownArray[0].value &&
          !P3TableFilterList[41].selectedData[0]
        ? 'Blank'
        : P3TableFilterList[41].selectedData.join(';').toString()

    const response = await dispatch(
      getP3StalePriceData(
        'search',
        'P3',
        fromDate,
        toDate,
        0,
        20,
        sorting,
        sortDirection,
        0,
        securityDescription,
        top_Category,
        sub_Category,
        reviewer_LatestComment,
        security_Type_and_Sub_Type_Code,
        lastPrice_In_Current_StaleReport,
        followup_Flag,
        cusip_Number,
        isiN_NUMBER,
        comments_Created_By,
        reviewer_Last_Commented_Date,
        icE_Last_Priced,
        icE_Last_Price,
        icE_Notes,
        bbG_Last_Priced,
        bbG_Last_Price,
        boxLocation,
        locationMemo,
        boxDesc,
        country,
        securityTypeCode,
        maturity_WarrantExpire_Date,
        inDefault,
        description2,
        description3,
        eodcmvdaT_LastPriced,
        eodcmvdaT_LastPrice,
        issue_Status_Code,
        dtC_Premed_Elig,
        maturityDate,
        couponRate,
        symbol,
        currency,
        lastPriceDate,
        lastPrice,
        cashQnty,
        marginQnty,
        inventory,
        currently_Still_in_StaleReport,
        last_Priced_In_Current_StaleReport,
        commentFilter,
        notes
      )
    )
    if (response == 200) {
      setIsTableVisible(true)
    } else {
      setResetTable(false)
    }
  }

  const DownloadCSV = async () => {
    const response = await dispatch(
      getP3StalePriceDataCSV(
        `P3_stalePriceReport_${monthYear}`,
        'P3',
        fromDate,
        toDate,
        0,
        0,
        'Cusip_Number',
        'ASC',
        0,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
    )
  }

  const resetData = () => {
    P3TableFilterList.forEach((obj) => {
      obj.selectedData = []
      obj.addData = ['']
      if (
        obj.Header !== 'Security Description' &&
        obj.Header !== 'Top Category' &&
        obj.Header !== 'Sub Category' &&
        obj.Header !== 'Reviewer Latest Comment' &&
        obj.Header !== 'Security Type and Sub Type Code' &&
        obj.Header !== 'Last price in Current Stale Report' &&
        obj.Header !== 'Flag'
      ) {
        obj.value = false
      }
      if (
        (obj.Header == 'Top Category' ||
          obj.Header == 'Sub Category' ||
          obj.Header == 'Flag') &&
        obj.dropDownArray.length > 0
      ) {
        obj.dropDownArray.forEach((dropDowns) => {
          dropDowns.value = true
        })
      } else {
        obj.dropDownArray.forEach((dropDowns) => {
          dropDowns.value = false
        })
      }
    })
    if (resetTable) {
      handleSubmit()
    }
    setToggle(!toggle)
  }

  const checkResetStatus = (data: any) => {
    if (data) {
      setResetState(true)
    } else {
      setResetState(false)
    }
  }

  useEffect(() => {
    setResetTable(false)
    dispatch(SetP3StalePriceData([]))
    dispatch(SetP3StalePriceDataHistory([]))
    dispatch(setAction(''))
    dispatch(setSortDirection('ASC'))
    dispatch(setSorting('CusIp_Number'))
    resetData()
  }, [])

  return (
    <RootContainer>
      <WDStyledHeading>{'Stale Price Report'}</WDStyledHeading>
      <FilterContainer>
        <FirstContainer>
          <Button
            padding={'4px 16px'}
            borderRadius={'20px'}
            type={'button'}
            color={COLORS.Background.Primarytext}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
            onClick={() => {
              navigation('/Beta-Stale-Pricing-Report')
            }}
          >
            Beta
          </Button>
          <LeftSpace>
            <Button
              hoverBgColor={COLORS.Background.Primary70}
              bgColor={COLORS.Background.Primarytext}
              color={COLORS.Background.Primary}
              padding={'4px 16px'}
              borderRadius={'20px'}
              hover={true}
              type={'button'}
              activeBgColor={COLORS.Background.Primaryactive}
              borderColor={'transparent'}
              onClick={() => {
                //
              }}
            >
              P3
            </Button>
          </LeftSpace>
          <LeftSpace></LeftSpace>
          <SvgVerticalDivider
            fillColor={COLORS.Background.Neutral40}
          ></SvgVerticalDivider>
          <LeftSpace></LeftSpace>
          <MonthPickerLabelWrapper>
            <Label>Select Month</Label>
            <DateWrapper>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                startDate={startDate}
                maxDate={current}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </DateWrapper>
          </MonthPickerLabelWrapper>
          <LeftSpace>
            <MonthPickerLabelWrapper>
              <Label>Select Source</Label>
              <SelectDropdown
                defaultValue={{ value: 'bbgUpload', label: 'BBG Upload' }}
                borderRadius={'4px'}
                height="32px"
                options={fileOptions}
                onChange={(e) => {
                  setUploadType(e)
                }}
              />
            </MonthPickerLabelWrapper>
          </LeftSpace>
          <LeftSpace>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              bgColor={'transparent'}
              disabled={!writeExecuteFlag}
              onClick={() => {
                setBBGPOPUP(true)
              }}
            >
              File Upload
            </Button>
          </LeftSpace>
          <MarginLeftAutoSpace>
            <Button
              padding={'4px 16px'}
              borderRadius={'20px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                navigation('/p3-Stale-Pricing-history')
              }}
            >
              P3 History
            </Button>
          </MarginLeftAutoSpace>
        </FirstContainer>
        <SecondContainer>
          <P3TableFilter checkResetStatus={checkResetStatus}></P3TableFilter>
        </SecondContainer>
        <ThirdContainer>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              handleSubmit()
            }}
          >
            Apply
          </Button>
          <LeftSpace>
            {resetState ? (
              <WDButton>
                <Button
                  type="button"
                  color={''}
                  onClick={() => {
                    resetData()
                    checkResetStatus(false)
                  }}
                >
                  {'Reset'}
                </Button>
              </WDButton>
            ) : (
              <WDButtonDisabled>
                <Button type="button" color={''}>
                  {'Reset'}
                </Button>
              </WDButtonDisabled>
            )}
          </LeftSpace>
        </ThirdContainer>
      </FilterContainer>
      <RefreshDownloadWrapper>
        <ButtonIconWrapper
          onClick={async () => {
            if (isTableVisible) {
              await setResetTable(false)
              await handleSubmit()
              await setResetTable(true)
            }
          }}
        >
          <SvgRedo fillColor={`${COLORS.UI.Primary50}`}></SvgRedo>
          <WDStyledButtonText>
            <Label>Refresh</Label>
          </WDStyledButtonText>
        </ButtonIconWrapper>
        <ButtonIconWrapper
          onClick={() => {
            DownloadCSV()
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>

          <WDStyledButtonText>
            <Label>CSV Download</Label>
          </WDStyledButtonText>
        </ButtonIconWrapper>
      </RefreshDownloadWrapper>
      {resetTable && (action === 'search' || action === 'sort') && (
        <>
          <MarginTopSpace>
            <WDCard>
              <CardHeader
                label={'P3'}
                icon={false}
                amountValue={`Total: ${TotalCount.data}`}
              />
            </WDCard>
          </MarginTopSpace>
          <P3StaleTable
            handleSubmit={handleSubmit}
            fromDate={fromDate}
            toDate={toDate}
            data={data.data ? data.data : []}
            totalRecords={TotalCount.data}
          />
        </>
      )}

      {BBGPOPUP && (
        <PopUpStyleContentBBG>
          <StyledSelectWrapper
            style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
          >
            {uploadType === 'bbg'
              ? 'BBG Upload'
              : uploadType == 'ice'
              ? 'ICE Upload'
              : uploadType == 'dtcc_beta'
              ? 'DTCC Upload(Beta)'
              : 'DTCC Upload(P3)'}
            <MarginLeftAutoSpace>
              <SvgButtonWrapper
                onClick={() => {
                  const uploadElement = document.getElementById(
                    'fileElement'
                  ) as HTMLInputElement
                  uploadElement.value = ''
                  setIsFileUploaded(false)
                  setBBGPOPUP(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </SvgButtonWrapper>
            </MarginLeftAutoSpace>
          </StyledSelectWrapper>
          <Divider
            thickness={'1px'}
            horizontal={true}
            bgColor={Backgrounds.Gray110}
          />
          <UploadWrapper
            id="drop-area"
            onDragEnter={preventDefaults}
            onDragOver={preventDefaults}
            onDragLeave={preventDefaults}
            onDrop={handleDropFile}
            className={isFileUploaded ? 'file-uploaded' : ''}
          >
            <FileUploadInput
              type="file"
              id="fileElement"
              ref={fileUpload}
              multiple
              accept=".csv,.txt"
              hidden
              onChange={handleFiles}
            ></FileUploadInput>
            {isFileUploaded ? (
              <RowWrapper>
                <WDLabel>
                  <Label>{fileName}</Label>
                </WDLabel>
              </RowWrapper>
            ) : (
              <RowWrapper>
                <WDLabel>
                  <Label>{'Drag your file here to start uploading or'}</Label>
                </WDLabel>
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={browseFile}
                >
                  Browse files
                </Button>
              </RowWrapper>
            )}
          </UploadWrapper>
          {uploadType === 'bbg'
            ? 'Max file size is 10mb.Supported file types are .csv'
            : uploadType == 'ice'
            ? 'Max file size is 10mb.Supported file types are .csv'
            : uploadType == 'dtcc_beta'
            ? 'Max file size is 10mb.Supported file types are .txt'
            : 'Max file size is 10mb.Supported file types are .txt'}
          <MarginLeftAutoSpace>
            <ButtonWrapper>
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                style={{ whiteSpace: 'nowrap' }}
                bgColor={'transparent'}
                onClick={() => {
                  setBBGPOPUP(false)
                  const uploadElement = document.getElementById(
                    'fileElement'
                  ) as HTMLInputElement
                  uploadElement.value = ''
                  setIsFileUploaded(false)
                }}
              >
                Cancel
              </Button>
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  fileData && handleSearch()
                }}
              >
                Upload
              </Button>
            </ButtonWrapper>
          </MarginLeftAutoSpace>
        </PopUpStyleContentBBG>
      )}
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {status.length > 0 && (
        <ToastWrapper>
          <ToastWrapper>
            <Toast
              text={status}
              type={response ? 'success' : 'danger'}
              openStatusCallback={() => {
                dispatch(setStatusMessage(''))
                dispatch(setResponse(false))
              }}
            />
          </ToastWrapper>
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default P3StalePricing
