import {
  RootContainer,
  StyledTableContainer,
  OptionsWrapper,
  DownloadWrapper,
  LottieWrapper,
  ToastWrapper,
  LottieLoadingWrapper,
  MarginTopSpace,
} from './styles'

import {
  getP3MarketMakerSecData,
  isLoadingSelector,
  getP3MarketMakerSecCSV,
  dataSelector,
  setSorting,
  setSortDirection,
  sortingSelector,
  sortDirectionSelector,
  actionSelector,
  HttpSuccessMessageSelector,
  HttpErrorMessageSelector,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../store/staticData/P3-Market-Maker-Sec'

import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import Loader from '../../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import { Toast } from '../../../components/toast/Toast'
import { COLORS } from '../../../theme/Colors'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import P3MarketMakerTable from './P3MarketMakerTable/P3MarketMakerTable'

const P3MarketMakerSecurities = () => {
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const dispatch = useDispatch()
  const sorting = useSelector(sortingSelector)
  const sortDirection = useSelector(sortDirectionSelector)
  const buffering = useSelector(isLoadingSelector)
  const action = useSelector(actionSelector)
  const data = useSelector(dataSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const date = new Date().toISOString().split('T')[0]

  const [requestSubmit, setrequestSubmit] = useState<{
    status: boolean | null
    text: string
  }>({
    status: null,
    text: '',
  })

  const TableColumnsDaily = [
    {
      Header: 'SYMBOL',
      accessor: 'symbol',
    },
    {
      Header: 'CUSIP NUMBER',
      accessor: 'cusip_Number',
    },
    {
      Header: 'SHORT DESC',
      accessor: 'short_Desc',
    },
    {
      Header: 'MARKET MAKER',
      accessor: 'market_Maker',
    },
  ]

  const handleRunReport = async () => {
    dispatch(setSortDirection('ASC'))
    dispatch(setSorting('SYMBOL'))
    const response = await dispatch(
      getP3MarketMakerSecData('search', 0, 20, sorting, sortDirection)
    )
    setIsTableVisible(true)
    if (response) {
      if (response.length > 0) {
        setTotalRecords(response[0].totalCount)
      } else {
        setTotalRecords(0)
      }
    }
    if (!response) {
      setIsTableVisible(false)
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }

  const DownloadCSV = async () => {
    const response = await dispatch(
      getP3MarketMakerSecCSV(
        `P3MarketMakerSecReport_${date}`,
        0,
        0,
        sorting,
        sortDirection
      )
    )
    if (response === 200) {
      setrequestSubmit({
        status: true,
        text: 'File Downloaded',
      })
    }
    if (response === 400) {
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }

  return (
    <RootContainer>
      <WDCard>
        <CardHeader
          label={'P3 Market Maker Securities'}
          icon={false}
          amountValue={`Total: ${totalRecords}`}
        />
      </WDCard>
      <StyledTableContainer>
        <OptionsWrapper>
          <DownloadWrapper
            onClick={() => {
              DownloadCSV()
            }}
          >
            <SvgDownloadSmall
              fillColor={`${COLORS.UI.Primary50}`}
            ></SvgDownloadSmall>
            <WDStyledButtonText>
              <Label>CSV Download</Label>
            </WDStyledButtonText>
          </DownloadWrapper>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            gap={'8px'}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              handleRunReport()
            }}
          >
            Run Report
          </Button>
        </OptionsWrapper>
        {buffering && (
          <LottieLoadingWrapper>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '54%' }}> Loading...</LottieWrapper>
          </LottieLoadingWrapper>
        )}
        {isTableVisible && (action === 'search' || action === 'sort') && (
          <MarginTopSpace>
            <P3MarketMakerTable
              columns={TableColumnsDaily}
              data={data}
              totalRecords={totalRecords}
            />
          </MarginTopSpace>
        )}
      </StyledTableContainer>
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default P3MarketMakerSecurities
