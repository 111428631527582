import { useState } from 'react'
import { RootContainer, ToastWrapper } from './styles'
import RegSHOSearchPanel from './searchPanel/RegSHOSearchPanel'
import RegSHOTabs from './RegSHOTabs'
import {
  MarginAutoContainer,
  SearchContainer,
  TabContainer,
} from './toolSummary/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  ErrorMessageSummarySelector,
  sethttpErrorMessageSummary,
} from '../../store/reg-SHO-Tool/Reg-SHO-Summary'
import { Toast } from '../../components/toast/Toast'
import {
  ErrorMessageAllocationSelector,
  sethttpErrorMessageAllocation,
} from '../../store/reg-SHO-Tool/reg-sho-buyInAllocation'

const RegSHOPage = () => {
  const summaryHttpErrorMessage = useSelector(ErrorMessageSummarySelector)
  const allocationHttpErrorMessage = useSelector(ErrorMessageAllocationSelector)
  const dispatch = useDispatch()
  const [searchParam, setSearchParam] = useState({
    regSHOId: 0,
    source: 'P3',
    fromDate: new Date().toISOString().split('T')[0],
    toDate: new Date().toISOString().split('T')[0],
    dropdownCusip: '',
    dropdownSymbols: '',
    applySearchClick: false,
    selectedSummaryTableCUSIP: '',
    selectedCUSIPReportDate: '',
    allocationType: '',
  })
  const [summaryFilters, setSummaryFilters] = useState<
    { id: string; value: string | string[] | undefined | null }[]
  >([])
  return (
    <RootContainer>
      <MarginAutoContainer>
        <SearchContainer>
          <RegSHOSearchPanel
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            setSummaryFilters={setSummaryFilters}
          ></RegSHOSearchPanel>
        </SearchContainer>
        <TabContainer>
          <RegSHOTabs
            commonTabProps={searchParam}
            setCommonTabProps={setSearchParam}
            filterData={summaryFilters}
            setSummaryFilters={setSummaryFilters}
          />
        </TabContainer>
      </MarginAutoContainer>

      {(summaryHttpErrorMessage?.length > 0 ||
        allocationHttpErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={summaryHttpErrorMessage || allocationHttpErrorMessage}
            type={'danger'}
            openStatusCallback={() => {
              dispatch(sethttpErrorMessageSummary(''))
              dispatch(sethttpErrorMessageAllocation(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default RegSHOPage
