import styled from 'styled-components'
import { PopupData } from '../../../models/PopUpData'
import { z } from 'zod'

export interface AddPopUpProps {
  title: string
  listItems: PopupData[]
  buttonLabel: string
  error?: z.ZodIssue[]
  onCancelClick: () => void
  onButtonClick: () => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>, attribute: string) => void
  maxContainerWidth?: string
  maxContainerHeight?: string
  columnWidth?: string
}
export const EmailHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

export const EmailBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 10%;
`
export const CancelSendButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 20px;
`
export const CancelEmailModal = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
`

export const AddEditContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fcfdff;
  display: flex;
  flex-direction: column;
  // height: 87%;
  // width: 90%;
  padding: 10px 15px 10px 15px;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  max-width: ${(Props: AddPopUpProps) =>
    Props.maxContainerWidth ? Props.maxContainerWidth : '700px'};
  min-height: ${(Props: AddPopUpProps) =>
    Props.maxContainerHeight ? Props.maxContainerHeight : '600px'};
`

export const BodyContainer = styled.div`
  overflow: auto;
  display: flex;
  //  flex-direction: row
  flex-wrap: wrap;
  // max-width: 300px;
`

export const LabelTextboxWrapper = styled.div`
  padding: 5px;
  width: ${(Props: AddPopUpProps) =>
    Props.columnWidth ? Props.columnWidth : '50%'};
  display: block;
`

export const StyledTableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  padding: 24px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`
