import React, { useEffect, useState } from 'react'
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledTableContainer,
  PopUpStyleLoader,
  StyledSelectWrapper,
  LottieWrapper,
  ToastWrapper,
} from '../SettlementCommonStyles'
import TradeReportSearchPanel from './TradeReportSearchPanel'
import TradeReportDetails from './TradeReportDetails'
import {
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  loadingSelector,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../store/settlementShortSales/settlementShortSales'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import TabList from '../../../components/tab/TabList'
import { BetaShortSalesTabDetails } from './constants'
import { Toast } from '../../../components/toast/Toast'

export interface IShortSalesReportSearchFilters {
  fromDate: string
  toDate: string
  cusip: string
  symbol: string
  accountNumber: string
  quantity: string
  applySearchClick:boolean
}



const BetaTradeReportPage = () => {
  const InitialFilterParams = {
    fromDate: new Date().toISOString().split('T')[0],
    toDate: new Date().toISOString().split('T')[0],
    cusip: '',
    symbol: '',
    accountNumber: '',
    quantity: '',
    applySearchClick:false
  }

  const [searchParams, setSearchParams] = useState({ ...InitialFilterParams })
  const [searchFlag, setSearchFlag] = useState({ ...InitialFilterParams })
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const Loading = useSelector(loadingSelector)

  const dispatch = useDispatch()
  
  return (
    <>
      <RootContainer>
        {Loading && (
          <PopUpStyleLoader>
            <StyledSelectWrapper style={{ flexDirection: 'column' }}>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LottieWrapper style={{ left: '53.5%' }}>
                {' '}
                Loading...
              </LottieWrapper>
            </StyledSelectWrapper>
          </PopUpStyleLoader>
        )}
        <StyledPanelHead>
          <StyledPanelHeadTitle>Beta Short Sales Report</StyledPanelHeadTitle>
        </StyledPanelHead>
        <TradeReportSearchPanel
          searchParams={searchParams}
          setsearchParams={setSearchParams}
          setSearchFlag={setSearchFlag}
        />
        {/* <TradeReportDetails
          data={SettlementShortSaleData?.length ? SettlementShortSaleData : []}
          TotalRecordCount={
            ShortSalesTotalCount ? ShortSalesTotalCount.data : 0
          }
        /> */}

        <div style={{ margin: '10px auto', width: '100vw' }}>
          <TabList
          isGoToFirst={searchParams.applySearchClick}
            tabs={BetaShortSalesTabDetails}
            commonTabProps={searchParams}
          />
        </div>
        
        {(SuccessMessage?.length > 0 ||
        ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success':'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
      </RootContainer>
    </>
  )
}

export default BetaTradeReportPage
