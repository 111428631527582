import {
  EmailHeaderContainer,
  CancelSendButtonWrapper,
  CancelEmailModal,
  LabelTextboxWrapper,
  AddEditContainer,
  AddPopUpProps,
  BodyContainer,
} from './styles'
import { COLORS } from '../../../theme/Colors'
import Button from '../../button/Button'
import Label from '../../label/Label'
import { WDStyledMainHeading, WDStyledButtonText } from '../../ui/WDTypography'
import React from 'react'
import { PopupData } from '../../../models/PopUpData'
import { z } from 'zod'

const AddPopUp = ({
  title,
  listItems,
  buttonLabel,
  onCancelClick,
  onButtonClick,
  onChange,
  error,
  maxContainerWidth,
  maxContainerHeight,
}: AddPopUpProps) => {
  const renderError = (element: string) => {
    if (error && error.length) {
      const elementValue = element.charAt(0).toLowerCase() + element.slice(1)
      const errorText = error.find(
        (e: z.ZodIssue) => e.path[0] === elementValue
      )?.message
      if (errorText) {
        return `*${errorText}`
      } else {
        return null
      }
    } else return null
  }
  return (
    <AddEditContainer
      maxContainerWidth={maxContainerWidth}
      maxContainerHeight={maxContainerHeight}
    >
      <EmailHeaderContainer>
        <WDStyledMainHeading> {title} </WDStyledMainHeading>
        <CancelSendButtonWrapper>
          <CancelEmailModal
            onClick={() => {
              onCancelClick()
            }}
          >
            <WDStyledButtonText>
              <Label>Cancel</Label>
            </WDStyledButtonText>
          </CancelEmailModal>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              onButtonClick()
            }}
          >
            {buttonLabel}
          </Button>
        </CancelSendButtonWrapper>
      </EmailHeaderContainer>
      <BodyContainer>
        {listItems.map((item: PopupData, index: number) => {
          const {
            label,
            element,
            inputValue,
            attribute,
            options,
            checked,
            columnWidth,
            placeHolder,
            disabled
          } = item
          return (
            <>
              <LabelTextboxWrapper
                columnWidth={columnWidth && columnWidth}
                key={index}
              >
                <Label>{label}</Label>
                {React.createElement(
                  element,
                  {
                    disabled:disabled,
                    id: index,
                    placeholder: placeHolder ? placeHolder : label,
                    type: item.type,
                    defaultValue: inputValue,
                    defaultChecked: checked,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(e, attribute)
                    },
                    style: { width: '100%' },
                  },
                  options &&
                    options.map((option: string) =>
                      React.createElement('option', { option }, option)
                    )
                )}
                <span style={{ color: 'red', display: 'inline-block' }}>
                  {renderError(attribute)}
                </span>
              </LabelTextboxWrapper>
            </>
          )
        })}
      </BodyContainer>
    </AddEditContainer>
  )
}

export default AddPopUp
