import { useState } from 'react'

import { DatePickerProps, Wrapper, StyledInput } from './styles'

const DatePicker = ({
  borderColor,
  bgColor,
  placeHolder,
  padding,
  max,
  min,
  onChange,
  maxWidth,
  defaultValue,
  value,
  name,
  disabled
}: DatePickerProps) => {


  return (
    <Wrapper maxWidth={maxWidth}>
      <StyledInput
        type="date"
        // name="date"
        id="date"
        max={max}
        min={min}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeHolder}
        borderColor={borderColor}
        bgColor={bgColor}
        padding={padding}
        name={name?name:"date"}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        disabled={disabled}
      />
    </Wrapper>
  )
}

export default DatePicker
