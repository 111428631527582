import React, { useEffect, useState } from 'react'
import 'react-tabs/style/react-tabs.css'
import {
  StyledTab,
  StyledTabAfter,
  StyledTabBefore,
  StyledTabList,
  StyledTabPanel,
  StyledTabs,
} from './styles'
import { TabListProps } from './TabListModels'

const TabList: React.FC<TabListProps> = ({
  tabs,
  activeTabProp = 0,
  setCommonTabProps = null,
  commonTabProps = {},
  isDisabled = false,
  isGoToFirst = false,
  setDisabled,
  filterData,
  setFilters,
}) => {
  const [activeTab, setActiveTab] = useState(activeTabProp)
  useEffect(() => {
    isGoToFirst && setActiveTab(0)
  }, [isGoToFirst])
  const handleTabClick = (index: number) => {
    setActiveTab(index)
  }
  return (
    <StyledTabs selectedIndex={activeTab} onSelect={handleTabClick}>
      <StyledTabList>
        {tabs.map((tab) => {
          return (
            <>
              <StyledTabBefore />
              <StyledTab
                key={tab.tabIndex}
                disabled={isDisabled || (setDisabled && setDisabled(tab.label))}
              >
                {tab.label}
                <StyledTabAfter />
              </StyledTab>
            </>
          )
        })}
      </StyledTabList>
      {tabs.map((tab) => {
        return (
          <StyledTabPanel key={tab.tabIndex}>
            <tab.component
              setCommonTabProps={setCommonTabProps}
              setActiveTab={setActiveTab}
              commonTabProps={commonTabProps}
              filterData={filterData}
              setSummaryFilters={setFilters}
            />
          </StyledTabPanel>
        )
      })}
    </StyledTabs>
  )
}

export default TabList
