import {
  BuyInIntents,
  SummaryCardContainerTradeBreaksTrend,
  TitleContainer
} from './styles'
import { useEffect, useState } from 'react'
import BarChart from '../../../bar/barChart';
import { formattingDate } from '../../../../utils/globalFunctions';
import { useSelector } from 'react-redux';
import { FisPhaseSelector } from '../../../../store/dash-board/static-data';


const ButInIntents = (props: any) => {
  const phase3Data = useSelector(FisPhaseSelector)

  const [datas, setDatas] = useState<any>([]);
  const [legendLabels, setlegendLabels] = useState<any>([])

  useEffect(() => {
  
    const formatDataChart: {
      label: string,
      data: any,
      barThickness: number,
      backgroundColor: any,
      borderColor: any,
      borderWidth: any,
      stack: string,
    }[] = []
    const responseData = phase3Data
    const legendLabelsTemp: string[] = []
    responseData?.length && responseData.map((m: { date: string; }) => legendLabelsTemp.push(formattingDate(m.date)))
    const bgColors = ['#BFDBFE', '#60A5FA', '#2563EB', '#1E40AF', '#1E3A8A']
    const borderColors = ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
    if (responseData && responseData.length) {
      formatDataChart.push(
        {
          label: 'Security Add',
          data: responseData.map((value: any) => value?.securityAdd),
          barThickness: 50,
          stack: "Stack 0",
          backgroundColor: bgColors[2],
          borderColor: borderColors[2],
          borderWidth: 0,
        },
        {
          label: 'Security Update',
          data: responseData.map((value: any) => value?.securityUpdate),
          barThickness: 50,
          stack: "Stack 0",
          backgroundColor: bgColors[1],
          borderColor: borderColors[1],
          borderWidth: 0,
        },
      )

    }
    setDatas(formatDataChart)
    setlegendLabels(legendLabelsTemp)


  }, [phase3Data]);

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        fullSize: true,
        align: 'start',
        labels: {
          color: '#0F172A',
          usePointStyle: true,
          pointStyle: "rectRounded",
          boxWidth: 24,
          boxHeight: 24
        }
      },
    },
    responsive: true,
  }

  return (
    <>
      <BuyInIntents >
        <TitleContainer>{props.widgetDescription}</TitleContainer>
  
      </BuyInIntents>
    </>
  )
}

export default ButInIntents
