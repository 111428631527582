import { formattingCurrencyWithDecimal, formattingDate, formattingNumber } from "../../../../utils/globalFunctions"

export const ContraCorresColumns = {
    Header: " ",
    columns: [
        {
            Header: "Account Name",
            accessor: "accountName"
        },
        {
            Header: "Age",
            accessor: "age"
        },
        {
            Header: "Account Number",
            accessor: "accountNumber"
        },
        {
            Header: "Quantity",
            accessor: (d: any) => {
                return d.quantity ? formattingNumber(d.quantity) : null
            }
        },
        {
            Header: "Settle Date",
            accessor: (d: any) => { return formattingDate(d.settleDate) }
        },
        {
            Header: "Entry Date",
            accessor: (d: any) => { return formattingDate(d.entryDate) }
        },
        {
            Header: "Net Amount",
            accessor: (d: any) => { return formattingCurrencyWithDecimal(d.netAmount) }
        },
        {
            Header: "Deliver Code",
            accessor: "receiveDeliverCode"
        },
        {
            Header: "Counterparty Type",
            accessor: "counterpartyType"
        },
    ],
}