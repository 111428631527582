import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const RootContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  //background: ${COLORS.Background.Secondary};
`

export const PopUpStyleContent = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`

export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${COLORS.Background.Primary};

  > * {
    margin-right: 16px;
  }
`
export const SelectWrapper = styled.div`
  width: 100%;
  padding: 12px 0px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${COLORS.Background.Secondary};
`

export const StyledTableContainer = styled.div`
  width: 100%;
  padding: 24px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
  /* width */
  webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }

  & table tbody tr td {
    padding: 4px;
    justify-content: center;
    text-align: center;
    background: ${COLORS.Background.NeutralBackground};
    line-height: 16px;
  }
  & table thead tr th {
    padding: 4px;
    text-align: center;
    background: ${COLORS.Background.NeutralBackground};
    line-height: 16px;
  }
  & table tbody tr th {
    :nth-child(2) {
      text-align: right;
    }
  }

  & table tbody tr td {
    justify-content: center;
    text-align: center;
    height: 32px;
    width: 88px !important;
  }
  margin-bottom: 10px;
  width: 100%;
  padding: 24px;
  background: ${COLORS.Background.BlurBackground};
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`

export const LottieWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
export const MarginLeftAutoSpace = styled.div`
  margin-left: auto;
`
export const LabelTextboxWrapeer = styled.div``

export const SvgButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`
export const PopUpStyleLoader = styled.div`
  background: #fcfdff;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`