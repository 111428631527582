import styled from 'styled-components'
import { Colors } from '../../../../shared/styles'

export interface AlertProps {
  border?: string
  background?: string
  fontColor?: string
}

export const WidgetContainer = styled.div`
  border: 1px solid black;
  height: fit-content;
  padding: 20px;
`

export const LottieWrapper = styled.div`
  // position: fixed;
  // top: 70%;
  // left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`

export const TitleContainer = styled.div`
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding: 12px 5px;
`

export const TableWidgetContainerByRefinitiveData = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 0px 20px 20px 20px;
  grid-column:1/5;
  grid-row: 1/3;
`

export const TableChartWidgetContainerByFISPhase = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 0px 20px 20px 20px;
  grid-column:5/9;
  grid-row: 1/3;
`
export const TableChartWidgetContainerByTopSecurity = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 0px 20px 20px 20px;
  grid-column:9/13;
  grid-row: 1/3;
`
export const BetaPhase3 = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 0px 20px 20px 20px;
  grid-column:1/7;
  grid-row:  3/5;
`
export const TableChartWidgetContainerByPhase3 = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 0px 20px 20px 20px;
  grid-column:7/13;
  grid-row:  3/5;
`

export const SummaryCardContainer = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  height: fit-content;
  padding: 20px;
`
export const ChartContainerByAgeEquity = styled.div`
  height: fit-content;
  width: 100%;
  align-item: center;
  // padding: 25px;
`
export const ChartContainerByTradeBreakByAgeFI = styled.div`
  height: fit-content;
  width: 100%;
  align-item: center;
  // padding: 0px;
`
export const ChartContainerByMpid = styled.div`
  height: fit-content;
  width: 100%;
  align-item: center;
  // padding: 25px;
`

export const SummaryCardContainerSuspenseAccount = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 8px;
  grid-column:9/12;
  grid-row: 3/4;
  position: relative;
  // opacity:0.4;
`
export const SummaryCardContainerTradeBreakCountT0 = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding:10px;
  grid-column:9/12;
  grid-row: 1/2;
  position: relative;
`
export const SummaryCardContainerTradeBreakCountT1 = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 8px;
  grid-column:9/12;
  grid-row: 2/3; 
  position: relative; 
`
export const SummaryCardContainerTradeBreaksTrend = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 20px ;
  grid-column:2/5;
  grid-row: 4/7;
  position: relative;
`
export const SummaryCardContainerTradeBreaksAlerts = styled.div`
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 8px;
  grid-column:9/12;
  grid-row: 4/5;
  position: relative;
`
export const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 2%;
  margin-top: 2%;
`
export const StyledTableContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 15px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  overflow-x: hidden;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`
export const RootContainerTable = styled.div`
  table {
    width: 100%;

    th {
      font-style: normal;
      font-weight: 700;
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;
      color: black;
      text-align: left;
      border-radius: 1px;
      padding: 4px 4px;
      border-right: 1px solid #d0d7e2;
      border-bottom: 1px solid #d0d7e2;
      &:active {
        box-shadow: inset 0 3px 0 0 rgb(0 0 0 / 60%);
      }
    }

    tr {
      td {
        padding: 2px 5px;
        color: black;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        box-shadow: inset 0px -1px 0px #d0d7e2;
      }

      td:first-child{
        text-align: left;
      }
    }
  }
  grid-row:2/3;
  justify-items: center;
  padding:10px;
  min-height: 170px;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 300px;
  height: 250px;
  background-color: white;
  border: 1px solid black;
`

export const ChartContainer = styled.div`
  width: fit-content;
  // height: 100%;
  margin-left: 2%;
  border: none;
  height: fit-content;
  padding: 20px;
`
export const InformationTile = styled.div`
    padding: 20px 10px;
    border-radius: 8px;
    border: 2px solid #E2E8F0;
    background: #F8FAFC;
    width: 48%;
    cursor: pointer;
`

export const TitleSubContainer = styled.div`
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding: 12px 5px;
`

export const AlertTile = styled.div<AlertProps>`
    padding: 10px;
    border-radius: 8px;
    border: ${(props: AlertProps) => props.border};
    background: ${(props: AlertProps) => props.background};
    width: 100%;
    font-color:${(props: AlertProps) => props.fontColor};
`

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const InformationContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 10px;
`

export const QuantityContainer = styled.p`
  color: #475569;
  font-size: 14px;
  margin-bottom: 8px;
`

export const AmountContainer = styled.span`
font-weight: 600;
font-size: 16px;
`
export const AlertDataContainer = styled.span`
  display: block;
  text-align: left;
  font-weight: 600;
  font-size: 26px;
  padding: 10px;
  text-align-last: center;
  cursor: pointer;
`
export const DangerArrowContainer = styled.span`
  background: #FEF1EE;
  border: 1px solid #FEB8AB;
  border-radius: 8px;
  padding: 5px;
`
export const ArrowContainer = styled.span`
  background: #EBF7E6;
  border: 1px solid #91DB8B;
  border-radius: 8px;
  padding: 5px;
`

export const EqualContainer = styled.span`
  background: #BFDBFE;;
  border: 1px solid #60A5FA;
  border-radius: 8px;
  padding: 5px;
  margin: 10px;
`
export const ViewMoreText = styled.span`
 color:${Colors.PrimaryText};
 cursor: pointer;
 font-size: 14px;
`