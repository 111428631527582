import styled from 'styled-components'
import { COLORS } from '../../../../../theme/Colors'
import { Backgrounds, BorderColors } from '../../../../../shared/styles'

export const RootContainerTable = styled.div`
  table {
    width: 100% !important;
    border-collapse: collapse;
    thead {
      tr {
        :nth-child(2) {
          text-align: left;
        }
        th {
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          cursor: pointer;
          line-height: 16px;
          color: black;
          border-radius: 1px;
          padding: 4px 4px;
          border-right: 1px solid ${COLORS.UI.Gray};
          border-bottom: 1px solid ${COLORS.UI.Gray};
          &:active {
            box-shadow: inset 0 3px 0 0 rgb(0 0 0 / 60%);
          }
        }
      }
    }
    tr {
      td {
        white-space: break-spaces;
        padding: 4px;
        color: black;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        border-bottom: 1px solid ${COLORS.UI.Gray};
      }
    }
  }
`
export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 28px;
  align-items: center;
`
export const HistoryTable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const GoToPageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`
export const NextPriviousWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  > * {
    margin-right: 10px !important;
  }
`
export const BackwardButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 5px;
`
export const ForwardButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 15px;
`
export const SpaceMaker = styled.div`
  margin-left: 10px;
`
export const ColoumnPaddingTop = styled.div`
  padding-top: 7px;
`
export const ThWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ThContentWrapper = styled.div`
  width: 100%;
  padding-right: 8px;
`

export const StyledRectangle = styled.div`
  width: 1px !important;
  height: 16px;
  background: ${COLORS.Background.Border} !important;
  border-radius: 1px;
`
export const ViewColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 16px 0px 16px 0px;
  gap: 10px;
`
export const ViewColumnsPopupWrapper = styled.div`
  width: 320px;
  padding: 16px;
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  position: absolute;
  margin-top: 40px;
  height: 60%;
`
export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  margin-bottom: 10px;
  align-items: center;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
`
export const SearchIcon = styled.div`
  margin: 3px 5px 0px 0px;
`

export const SearchBar = styled.input`
  width: 100%;
  border: none;
`
export const ActionsButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const CancelSaveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
export const accordionItem = styled.div``

export const AccordionContent = styled.div`
   {
    background-color: #39b9d2;
  }
`
export const CheckboxStyle = styled.input`
width: 1rem;
height: 1rem;
border-radius: 0.25rem;
margin: 0rem 0.625rem;
border: 0.0625rem solid ${BorderColors.PrimaryText};
accent-color: ${BorderColors.PrimaryText};
outline: none;
&:disabled {
  background: ${Backgrounds.Primary80};
  border-radius: 4px;
  cursor: no-drop;
}
&:checked {
  accent-color: ${Backgrounds.Primary50};
  border-radius: 4px;
}
`