import { ADRTableProps } from './IADRFeesUpload'
import AdrFeesUploadTable from './adrFeesUploadTable/AdrFeesUploadTable'
import { StyledTableContainer } from './styles'

const ADRFeesUploadColumn = (props: ADRTableProps) => {
  const adrTableColumn = [
    {
      Header: 'TransactionType',
      accessor: 'transactionType',
    },
    {
      Header: 'TransactionID',
      accessor: 'transactionID',
    },
    {
      Header: 'CustomerAccount',
      accessor: 'customerAccount',
    },
    {
      Header: 'CR DR',
      accessor: 'cR_DR',
    },
    {
      Header: 'AllocateNetMoney',
      accessor: 'allocateNetMoney',
    },
    {
      Header: 'NetMoney',
      accessor: 'netMoney',
    },
    {
      Header: 'GL ACCT',
      accessor: 'gL_ACCT',
    },
    {
      Header: 'GL SUB ACCT',
      accessor: 'gL_SUB_ACCT',
    },
    {
      Header: 'GL Branch',
      accessor: 'gL_BRANCH',
    },
    {
      Header: 'GL DEPT',
      accessor: 'gL_DEPT',
    },
    {
      Header: 'GL Description',
      accessor: 'gL_DESCRIPTION',
    },
    {
      Header: 'CustomerDescription',
      accessor: 'customeR_DESCRIPTION',
    },
    {
      Header: 'Cusip',
      accessor: 'cusip',
    },
    {
      Header: 'FeeType',
      accessor: 'feeType',
    },
    {
      Header: 'TaxYear',
      accessor: 'taxYear',
    },
    {
      Header: 'Currency',
      accessor: 'currency',
    },
    {
      Header: 'WithholdingType',
      accessor: 'withholdingType',
    },
    {
      Header: 'TradeDate',
      accessor: 'tradeDate',
    },
    {
      Header: 'FundCode',
      accessor: 'fundCode',
    },
    {
      Header: 'DR CR InterestAdj',
      accessor: 'dR_CR_Interest_Adj',
    },
    {
      Header: 'TradeNbr',
      accessor: 'tradeNbr',
    },
  ]
  return (
    <StyledTableContainer>
      <AdrFeesUploadTable
      accountType={props.accountType}
        columns={adrTableColumn}
        fromDate={props.fromDate}
        toDate={props.toDate}
        data={props.data}
        totalRecords={props.totalRecords}
      />
    </StyledTableContainer>
  )
}

export default ADRFeesUploadColumn
