/* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LabelTextboxWrapeer,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  PopUpStyleLoader,
  RootContainer,
  StyledSelectWrapper,
  StyledTableContainer,
  SvgButtonWrapper,
  ToastWrapper,
} from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import { StyledImage } from '../../../components/image/styles'
import { Backgrounds } from '../../../shared/styles'
import { Textbox } from '../../../components/textbox/Textbox'
import { Icons } from '../../../shared/GlobalStyle'
import { Toast } from '../../../components/toast/Toast'
import Button from '../../../components/button/Button'
import WDCard from '../../../components/ui/WDCard'
import Loader from '../../../assets/lottiefiles/loader.json'
import Label from '../../../components/label/Label'
import Divider from '../../../components/divider/Divider'
import Lottie from 'lottie-react'
import { COLORS } from '../../../theme/Colors'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import { SvgExclamation } from '../../../components/svg/logo/SvgExclamation'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { ErrorMessageSelector, LoadingSelector, SuccessMessageSelector, addReOrgFeeReport, currentDataSelector, deactivateReorg, getDataList, setErrorMessage, setIsLoading, setSuccessMessage, updateList } from '../../../store/asset-Servicing/reorg-fee-admin'
import Table from '../../../components/table/Table'
import { getAccessFlag } from '../../../utils/globalFunctions'

const ReOrgAdmin = () => {
  const dispatch = useDispatch()
  const data = useSelector(currentDataSelector)
  const loading = useSelector(LoadingSelector)
  const SuccessMessage = useSelector(SuccessMessageSelector)
  const ErrorMessage = useSelector(ErrorMessageSelector)
  const [toggleEdit, SetToggleEdit] = useState<boolean>(false)
  const [toggleInsert, SetToggleInsert] = useState<boolean>(false)
  const [toggleDeactivate, SetToggleDeactivate] = useState<boolean>(false)
  const [popupContent, setPopupContent] = useState<boolean>(false)
  const [res, setRes] = useState<number>(0)
  const [mapping, setMapping] = useState({
    id: 0,
    eventType: '',
    eventComment: '',
    commentPattren: '',
    is_Active: true,
  })
  const typeOption = [
    { value: 'Non-Billable', label: 'Non-Billable' },
    { value: 'Billable', label: 'Billable' },
  ]
  const writeExecuteFlag = getAccessFlag(['AS-RWX', 'OM-RWX'])
  const addMapping = () => {
    setMapping({
      id: 0,
      eventType: 'Non-Billable',
      eventComment: '',
      commentPattren: '',
      is_Active: true,
    })
    SetToggleInsert(true)
  }
  useEffect(() => {
    async function Data() {
      dispatch(setIsLoading(true))
      const response = await dispatch(getDataList())
      setRes(response)
      response !== 200 && (await setPopupContent(true))
    }
    Data()
  }, [])

  const addNew = async (
    eventType: string,
    eventComment: string,
    commentPattren: string
  ) => {
    SetToggleInsert(false)
    await dispatch(addReOrgFeeReport(0, eventType, eventComment, commentPattren, true))
    await dispatch(getDataList())
    await setPopupContent(true)
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    })
  }
  const update = async (
    id: number,
    eventType: string,
    eventComment: string,
    commentPattren: string
  ) => {
    SetToggleEdit(false)
    await dispatch(
      updateList(id, eventType, eventComment, commentPattren, true)
    )
    await dispatch(getDataList())
    await setPopupContent(true)
  }
  const deactive = async (
    id: number,
    active:boolean
  ) => {
    SetToggleDeactivate(false)
    await dispatch(
      deactivateReorg(id, !active)
    )
    await dispatch(getDataList())
    await setPopupContent(true)
  }
  return (
    <RootContainer>
      <WDCard>
        <CardHeader label={'Manage ReOrg Fee Events'} />
      </WDCard>
   
        <StyledSelectWrapper style={{ justifyContent: 'flex-end' }}>
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          disabled={!writeExecuteFlag}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          gap={'8px'}
          type={''}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={() => {
            addMapping()
          }}
        >
          Add New
        </Button>
      </StyledSelectWrapper>
      <StyledTableContainer>
          <Table
          tableColumns={[
            {
              Header: 'EventType',
              accessor: 'eventType',
            },

            {
              Header: 'EventComment',
              accessor: 'eventComment',
            },
            {
              Header: 'CommentPattern',
              accessor: 'commentPattren',
            },
            {
              Header: 'Deactivate',
              Cell: (e: { cell: { row: { id:  number } } }) => {
                return (
                  <input
                    style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                    title="check Box"
                    type="checkbox"
                    disabled={!writeExecuteFlag}
                    defaultChecked={data[e.cell.row.id].is_Active === false}
                    onClick={() => {
                      setMapping({
                        id: data[e.cell.row.id].id,
                        eventType: data[e.cell.row.id].eventType,
                        eventComment: data[e.cell.row.id].eventComment,
                        commentPattren: data[e.cell.row.id].commentPattren,
                        is_Active: !data[e.cell.row.id].is_Active,
                      })
                      SetToggleDeactivate(true)
                    }}
                  ></input>
                )
              },
            },
            {
              Header: 'Edit',
              Cell: (e: { cell: { row: { id:  number } } }) => {
                return data[e.cell.row.id].is_Active === true ? (
                  <StyledImage
                    title="Edit"
                    onClick={() => {
                      writeExecuteFlag &&   setMapping({
                        id: data[e.cell.row.id].id,
                        eventType: data[e.cell.row.id].eventType,
                        eventComment: data[e.cell.row.id].eventComment,
                        commentPattren: data[e.cell.row.id].commentPattren,
                        is_Active: data[e.cell.row.id].is_Active,
                      })
                      writeExecuteFlag &&    SetToggleEdit(true)
                    }}
                    width={'25px'}
                    style={ !writeExecuteFlag ?  { cursor: 'not-allowed' }:  { cursor: 'pointer' }}
                    src={Icons.Edit}
                    alt="Edit"
                  />
                ) : (
                  <StyledImage
                    title="Edit"
                    width={'25px'}
                    style={{ cursor: 'pointer', opacity: '0.3' }}
                    src={Icons.Edit}
                    alt="Edit"
                  />
                )
              },
            },
          ]}
          tableData={data}
          onClick={() => {
            //
          }}
        />
        {res == 200 && data.length == 0 && (
          <>
            <Label fontWeight={700} margin="5% 0 0 50%">
              No Records Found
            </Label>
            <Label fontWeight={700} margin="0.5% 0 0 49%">
              No Exceptions Identified
            </Label>
          </>
        )}
        {loading && (
          <PopUpStyleLoader>
            <StyledSelectWrapper style={{ justifyContent: 'center' }}>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LottieWrapper style={{ top: '40.5%' }}>
                {' '}
                Loading...
              </LottieWrapper>
            </StyledSelectWrapper>
          </PopUpStyleLoader>
        )}
       {(SuccessMessage?.length > 0 ||
        ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success':'danger'}
            openStatusCallback={() => {
              dispatch(setErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
        {/*Insert*/}
        {toggleInsert && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              Add Event
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleInsert(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <LabelTextboxWrapeer>
                <Label color={'black'}>EventType</Label>
                <SelectDropdown
                  defaultValue={{
                    value: 'Non-Billable',
                    label: 'Non-Billable',
                  }}
                  isDisabled={true}
                  borderRadius={'4px'}
                  height="50px"
                  options={typeOption}
                  onChange={(e) => {
                    setMapping({ ...mapping, eventType: e })
                  }}
                ></SelectDropdown>
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}> EventComment </Label>
                <Textbox
                  placeholder="EventComment "
                  onChange={(e) => {
                    setMapping({ ...mapping, eventComment: e })
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}>CommentPattern</Label>
                <Textbox
                  placeholder="CommentPattern"
                  onChange={(e) => {
                    setMapping({ ...mapping, commentPattren: e })
                  }}
                />
              </LabelTextboxWrapeer>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleInsert(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>{' '}
              {mapping.eventType &&
              mapping.eventComment  ? (
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={() => {
                    addNew(
                      mapping.eventType,
                      mapping.eventComment,
                      mapping.commentPattren
                    )
                  }}
                >
                  Add
                </Button>
              ) : (
                <Button
                  bgColor={COLORS.Text.NeutralTextDisabled}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={''}
                  borderColor={'transparent'}
                >
                  Add
                </Button>
              )}
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
        {/*Edit*/}
        {toggleEdit && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              <>Update Event</>
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleEdit(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <LabelTextboxWrapeer>
                <Label color={'black'}>EventType</Label>
                <SelectDropdown
                  defaultValue={{
                    value: mapping.eventType,
                    label: mapping.eventType,
                  }}
                  borderRadius={'4px'}
                  isDisabled={true}
                  height="50px"
                  options={typeOption}
                  onChange={(e) => {
                    setMapping({ ...mapping, eventType: e })
                  }}
                ></SelectDropdown>
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}> EventComment </Label>
                <Textbox
                  placeholder="EventComment"
                  defaultValue={mapping.eventComment}
                  onChange={(e) => {
                    setMapping({ ...mapping, eventComment: e })
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}>CommentPattern</Label>
                <Textbox
                  placeholder="CommentPattern"
                  defaultValue={mapping.commentPattren}
                  onChange={(e) => {
                    setMapping({ ...mapping, commentPattren: e })
                  }}
                />
              </LabelTextboxWrapeer>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleEdit(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>{' '}
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  update(
                    mapping.id,
                    mapping.eventType,
                    mapping.eventComment,
                    mapping.commentPattren
                  )
                }}
              >
                Update
              </Button>
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
        {/* Deactivate */}
        {toggleDeactivate && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              <SvgButtonWrapper
                onClick={() => {
                  SetToggleEdit(false)
                }}
              >
                <SvgExclamation
                  fillColor={COLORS.Icons.DangerIcon}
                ></SvgExclamation>
              </SvgButtonWrapper>
              {` ${ mapping.is_Active ?"Activate" : "Deactivate"} Event`} 
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleDeactivate(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <>
                {`Are you sure want to   ${ mapping.is_Active ?"Activate" : "Deactivate"}?`}
              </>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleDeactivate(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  deactive(
                    mapping.id,
                    mapping.is_Active
                  )
                }}
              >
               { mapping.is_Active ?"Activate" : "Deactivate"}
              </Button>
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
      </StyledTableContainer>
    </RootContainer>
  )
}

export default ReOrgAdmin
