import { HeaderGroup } from "react-table"
import { StyledRectangle, ThContentWrapper, ThWrapper } from "./styles"


interface TableProps{
    headerGroups?:Array<HeaderGroup<any>>
    footerGroups?: Array<HeaderGroup<any>>
    
}

export const TableFooter = ({ footerGroups }:TableProps) => {
  return (
<tfoot>
      {footerGroups?.map((group, index) => (
<tr {...group.getFooterGroupProps()} key={index}>
          {group.headers.map((column, i) => (
<td {...column.getFooterProps()} key={i}>
              {column.render('Footer')}
</td>
          ))}
</tr>
      ))}
</tfoot>
  )
}

export const TableHeader = ({ headerGroups }:TableProps) => {
    return (
        <thead>
        {headerGroups?.map((headerGroup, key) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={key}>
            {headerGroup.headers.map((column: any, index) => (
              <th {...column.getHeaderProps()} key={index}>
                <ThWrapper>
                  <ThContentWrapper>
                    {column.render('Header')}
                  </ThContentWrapper>
                  {headerGroup.headers.length - 1 !== index && (
                    <StyledRectangle></StyledRectangle>
                  )}
                </ThWrapper>
              </th>
            ))}
          </tr>
        ))}
        </thead>
    )
  }

  