import SelectDropdown from "../../../../components/selectDropdown/SelectDropdown";
import { Colors } from "../../../../shared/styles";
import { formattingCurrencyWithDecimal, formattingDate, formattingNumber } from "../../../../utils/globalFunctions";

const RenderColumns = (approvalStatus?: string, categoryOnchange?: any, categoryDropdown?: any) => {
  let columns: any[]
  if (approvalStatus === "APR") {
    columns = [
      {
        Header: "Category",
        Cell: (props: any) => {
          return (
            <div>
              <SelectDropdown
                options={categoryDropdown}
                onChange={(e: any) =>
                  categoryOnchange(e, props.cell.row.id)
                }
                defaultValue={{
                  value: props.data[props.cell.row.id].category,
                  label: props.data[props.cell.row.id].category,
                }}
                border={`1px solid ${Colors.PrimaryText}`}
                color={Colors.PrimaryText}
                borderRadius={"4px"}
                isDisabled={true}
              />
              <p>&thinsp;</p>
            </div>
          );
        }
      },
      {
        Header: "Account Number",
        Cell: (props: any) => {
          return (
            <div>{props.data[props.cell.row.id].houseAccountNumber ? props.data[props.cell.row.id].houseAccountNumber : "-"}</div>
          )
        }
      },
      {
        Header: "MPID",
        Cell: (props: any) => {
          return (
            <div>{props.data[props.cell.row.id].mpid ? props.data[props.cell.row.id].mpid : "-"}</div>
          )
        }
      },
      {
        Header: "Trade Number",
        Cell: (props: any) => {
          if (props.data[props.cell.row.id].tradeNmbr !== "     ") {
            return (
              <div>{props.data[props.cell.row.id].tradeNmbr}</div>
            )
          }
          else {
            return (
              <div>-</div>
            )
          }

        }
      },
      {
        Header: "TradeDate(H)/ TradeDate(S)",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>
                  {props.data[props.cell.row.id].houseTradeDate ? formattingDate(
                    props.data[props.cell.row.id].houseTradeDate
                  ) : <>-</>}
                </p>
                <p>
                  {props.data[props.cell.row.id].streetTradeDate ? formattingDate(
                    props.data[props.cell.row.id].streetTradeDate
                  ) : <>-</>}
                </p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Price(H)/ Price(S)",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{props.data[props.cell.row.id].housePrice ?
                  formattingCurrencyWithDecimal(props.data[props.cell.row.id].housePrice) : <>-</>
                }</p>
                <p>{(props.data[props.cell.row.id].streetPrice) ?
                  formattingCurrencyWithDecimal(props.data[props.cell.row.id].streetPrice) : <>-</>
                }</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Quantity(H)/ Quantity(S)",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{props.data[props.cell.row.id].houseQty ?
                  formattingNumber(props.data[props.cell.row.id].houseQty) : <>-</>}</p>
                <p>{props.data[props.cell.row.id].streetQty ?
                  formattingNumber(props.data[props.cell.row.id].streetQty) : <>-</>}</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Amount(H)/ Amount(S)",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{(props.data[props.cell.row.id].houseAmount ?
                  formattingCurrencyWithDecimal(props.data[props.cell.row.id].houseAmount) : <>-</>)}</p>
                <p>{(props.data[props.cell.row.id].streetAmount) ?
                  formattingCurrencyWithDecimal(props.data[props.cell.row.id].streetAmount) : <>-</>}</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "MjrExecBrkr/ MjrEntrBrkr",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{props.data[props.cell.row.id].houseCLRG ? props.data[props.cell.row.id].houseCLRG : <>-</>}</p>
                <p>{props.data[props.cell.row.id].houseEXEC ? props.data[props.cell.row.id].houseEXEC : <>-</>}</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "MnrClrBrkr/ MnrExecBrkr",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{props.data[props.cell.row.id].streetCLRG ? props.data[props.cell.row.id].streetCLRG : <>-</>}</p>
                <p>{props.data[props.cell.row.id].streetEXEC ? props.data[props.cell.row.id].streetEXEC : <>-</>}</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Exec Time(H)/ Exec Time(S)",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{props.cell.row.original.houseTime ? props.cell.row.original.houseTime : <>-</>}</p>
                <p>{props.cell.row.original.streetTime ? props.cell.row.original.streetTime : <>-</>}</p>
              </div>
            </>
          );
        },
      },
    ]
  }
  else {
    columns = [
      {
        Header: "Category",
        Cell: (props: any) => {
          return (
            <div>
              <SelectDropdown
                options={categoryDropdown}
                onChange={(e: any) =>
                  categoryOnchange(e, props.cell.row.id)
                }
                defaultValue={{
                  value: props.data[props.cell.row.id].category,
                  label: props.data[props.cell.row.id].category,
                }}
                border={`1px solid ${Colors.PrimaryText}`}
                color={Colors.PrimaryText}
                borderRadius={"4px"}
                isDisabled={true}
              />
              <p>&thinsp;</p>
            </div>
          );
        }
      },
      {
        Header: "Account Number",
        Cell: (props: any) => {
          return (
            <div>{props.data[props.cell.row.id].houseAccountNumber ? props.data[props.cell.row.id].houseAccountNumber : "-"}</div>
          )
        }
      },
      {
        Header: "MPID",
        Cell: (props: any) => {
          return (
            <div>{props.data[props.cell.row.id].mpid ? props.data[props.cell.row.id].mpid : "-"}</div>
          )
        }
      },
      {
        Header: "Trade Number",
        Cell: (props: any) => {
          if (props.data[props.cell.row.id].tradeNmbr?.trim() !== "") {
            return (
              <div>{props.data[props.cell.row.id].tradeNmbr}</div>
            )
          }
          else {
            return (
              <div>-</div>
            )
          }

        }
      },
      {
        Header: "TradeDate(H)/ TradeDate(S)",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>
                  {props.data[props.cell.row.id].houseTradeDate ? formattingDate(
                    props.data[props.cell.row.id].houseTradeDate
                  ) : <>-</>}
                </p>
                <p>
                  {props.data[props.cell.row.id].streetTradeDate ? formattingDate(
                    props.data[props.cell.row.id].streetTradeDate
                  ) : <>-</>}
                </p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Price(H)/ Price(S)",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{props.data[props.cell.row.id].housePrice ?
                  formattingCurrencyWithDecimal(props.data[props.cell.row.id].housePrice) : <>-</>
                }</p>
                <p>{(props.data[props.cell.row.id].streetPrice) ?
                  formattingCurrencyWithDecimal(props.data[props.cell.row.id].streetPrice) : <>-</>
                }</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Quantity(H)/ Quantity(S)",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{props.data[props.cell.row.id].houseQty ?
                  formattingNumber(props.data[props.cell.row.id].houseQty) : <>-</>}</p>
                <p>{props.data[props.cell.row.id].streetQty ?
                  formattingNumber(props.data[props.cell.row.id].streetQty) : <>-</>}</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Amount(H)/ Amount(S)",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{(props.data[props.cell.row.id].houseAmount ?
                  formattingCurrencyWithDecimal(props.data[props.cell.row.id].houseAmount) : <>-</>)}</p>
                <p>{(props.data[props.cell.row.id].streetAmount) ?
                  formattingCurrencyWithDecimal(props.data[props.cell.row.id].streetAmount) : <>-</>}</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "MjrExecBrkr/ MjrEntrBrkr",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{props.data[props.cell.row.id].houseCLRG ? props.data[props.cell.row.id].houseCLRG : <>-</>}</p>
                <p>{props.data[props.cell.row.id].houseEXEC ? props.data[props.cell.row.id].houseEXEC : <>-</>}</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "MnrClrBrkr/ MnrExecBrkr",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{props.data[props.cell.row.id].streetCLRG ? props.data[props.cell.row.id].streetCLRG : <>-</>}</p>
                <p>{props.data[props.cell.row.id].streetEXEC ? props.data[props.cell.row.id].streetEXEC : <>-</>}</p>
              </div>
            </>
          );
        },
      },
      {
        Header: "Exec Time(H)/ Exec Time(S)",
        Cell: (props: any) => {
          return (
            <>
              <div>
                <p>{props.cell.row.original.houseTime ? props.cell.row.original.houseTime : <>-</>}</p>
                <p>{props.cell.row.original.streetTime ? props.cell.row.original.streetTime : <>-</>}</p>
              </div>
            </>
          );
        },
      },

    ]
  }

  columns = columns.filter((element: any) => !element.isHidden)
  return columns;
}
export default RenderColumns;
