/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

export interface IconProps {
  icon?: any
  margin?: string
  width?: any
  height?: any
  onClick?: any
}

export const StyledIcon = styled.img<IconProps>`
  margin: 0px 0px 0px ${(IconProps: any) => IconProps.margin};
  width: ${(IconProps: any) => IconProps.width};
  height: ${(IconProps: any) => IconProps.height};
  cursor: pointer;
`
