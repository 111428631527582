/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled from 'styled-components'
import { Backgrounds, BorderColors, Colors } from '../../shared/styles'

interface InputFunc {
  (value: string): void | string | boolean | number
}

export interface TextboxProps {
  backgroundColor?: string
  type?: string
  placeholder?: string
  disabled?: boolean
  value?: any
  defaultValue?: string
  onChange?: InputFunc
  innerRef?: React.RefObject<HTMLInputElement>
  onBlur?: () => void
  errorText?: string
  required?: boolean
  name?: string
  id?: string
  max?: string
  borderColor?: string
  readOnly?: boolean
  style?: any
  autoFocus?: boolean
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
`
export const StyledError = styled.p`
  display: flex;
  color: red;
`
export const Main = styled.input`
  padding: 0.75rem 1rem;
  flex: 0 1 100%;
  background: ${(TextboxProps: TextboxProps) =>
    TextboxProps.backgroundColor
      ? TextboxProps.backgroundColor
      : Backgrounds.White};
  border: 1px solid
    ${(TextboxProps: TextboxProps) =>
    TextboxProps.backgroundColor
      ? TextboxProps.borderColor
      : BorderColors.Gray50};
  &:hover {
    border: 1px solid ${'#BFDBFE'};
    color: ${Colors.Gray20};
  }
  &:active {
    border: 1px solid ${'#3B82F6'};
  }
  &:disabled {
    border: 1px solid ${BorderColors.Gray50};
    background: #e2e8f0;
    color: ${Colors.Gray};
    cursor: no-drop;
  }
  &:invalid {
    border: 1px solid #ea001e;
    background: #fef1ee;
  }
  &:focus {
    border: 2px solid ${'#2684ff'};
    color: black;
  }
  &:required:valid {
    // border: 2px solid ${BorderColors.Success2};
  }
  box-sizing: border-box;
  border-radius: 8px;
  font-family: SourceSansPro-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${Colors.NeutralText};
  outline: none;
`
