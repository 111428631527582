import ReactQuill from 'react-quill'
import Button from '../../../../components/button/Button'
import Label from '../../../../components/label/Label'
import { formattingDate } from '../../../../utils/globalFunctions'
import { BuyInReceiveExceptionModel } from '../IbuyinReceive'
import { CommentsArray, commentsModel } from './IftrAuditTrail'
import {
  AvatarStyle,
  CommentContent,
  CommentHeader,
  CommentRootContainer,
  CommentTile,
  DataContainer,
  ReactQuillStyles,
} from './styles'

interface CommentProps {
  ftrRowData?: BuyInReceiveExceptionModel
  tableCommmentsData: CommentsArray
  comment: string
  commentsOnChange: (e: any) => void
  commentTrade: () => void
}

const FtrAuditTrail = (props: CommentProps) => {
  const modules = {
    toolbar: [],
  }
  return (
    <CommentRootContainer>
      <CommentHeader>
        <Button
          type={'button'}
          color={'#000000'}
          bgColor={'#FFFF'}
          borderColor={'#FFFF'}
          padding={'4px 8px'}
        >
          Audit Trail
        </Button>
      </CommentHeader>
      <DataContainer>
        {props.tableCommmentsData && props.tableCommmentsData.length
          ? props.tableCommmentsData.map(
              (element: commentsModel, key: number) => {
                return (
                  <CommentTile key={key}>
                    <CommentContent>
                      <AvatarStyle>
                        {element.userId?.charAt(0).toUpperCase()}
                      </AvatarStyle>
                      <Label padding={'6px 0px'}>{element.userId}</Label>
                      <Label padding={'6px 0px'} minWidth={'200px'}>
                        {element.utcModifiedOn
                          ? new Date(element.utcModifiedOn).toLocaleTimeString(
                              'en-US'
                            )
                          : null}
                        ,{formattingDate(element.utcModifiedOn as string)}
                      </Label>

                      <ReactQuillStyles>
                        <ReactQuill
                          readOnly
                          modules={modules}
                          value={element.comments}
                          onChange={() => {
                            null
                          }}
                        />
                      </ReactQuillStyles>
                    </CommentContent>
                  </CommentTile>
                )
              }
            )
          : null}
      </DataContainer>
    </CommentRootContainer>
  )
}

export default FtrAuditTrail
