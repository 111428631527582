/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Label from '../../../components/label/Label'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import {
  DownloadButtonWrapper,
  DownloadWrapper,
  FilterChoiceContainer,
  FilterContainer,
  FilterOptionWrapper,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleLoader,
  RadioCommentWrapper,
  StyledSelectWrapper,
  ToastWrapper,
} from './styles'
import WeekDayCalender from '../../../components/WeekDayCalander/WeekDayCalender'
import { FormateDateSelector } from '../../../store/dash-board/about'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { addDays, formatISO } from 'date-fns'
import { Textbox } from '../../../components/textbox/Textbox'
import { COLORS } from '../../../theme/Colors'
import Button from '../../../components/button/Button'
import {
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  SetDividendReconReportData,
  TotalCountSelector,
  getDividendReconReportCSV,
  getDividendReconReportEXCEL,
  getReportData,
  isLoadingSelector,
  setHttpErrorMessage,
  setSuccessMessage,
  sortDirectionSelector,
  sortingSelector,
} from '../../../store/asset-Servicing/DividendReconReport'
import ReportDetails from './ReportDetails'
import HistoryReportDetails from './HistoryReportDetails'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { Toast } from '../../../components/toast/Toast'
const ReportSearchPanel = () => {
  const dispatch = useDispatch()
  const ExcludeDate = useSelector(FormateDateSelector)
  const sorting = useSelector(sortingSelector)
  const sortDirection = useSelector(sortDirectionSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [Calendar, setCalendar] = useState<boolean>(false)
  const [includeFailComments, setIncludeFailComments] = useState(0)
  const [startCalendarDate, setStartCalendarDate] = useState<boolean>(false)
  const [endCalendar, setEndCalender] = useState<boolean>(false)
  const loading = useSelector(isLoadingSelector)
  const [checkCusip, setCheckCusip] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [input, setInput] = useState({
    commentDate: new Date().toISOString(),
    reportType: 'daily',
    ageByPayDateRange: '',
    ageByLastActivityDateRange: '',
    accountNumber: '',
    cusip: '',
    pageNumber: 0,
    commentRow: 0,
  })

  const DefaultDate: string =
    localStorage.getItem('DefaultDate') &&
    localStorage.getItem('DefaultDate') != 'undefined'
      ? JSON.parse(localStorage.getItem('DefaultDate') || '')
      : ''

  const [Startdate, setStartDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )
  const [EndDate, setEndDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )
  const typeOption = [
    { value: 'daily', label: 'Daily' },
    { value: 'historical', label: 'Historical' },
  ]
  const ageByPaydateOptions = [
    { value: '', label: 'Select' },
    { value: '1', label: '1' },
    { value: '1-7', label: '1-7' },
    { value: '7-30', label: '7-30' },
    { value: '30+', label: '30+' },
  ]
  const ageByLastActivityDateOptions = [
    { value: '', label: 'Select' },
    { value: '1', label: '1' },
    { value: '1-7', label: '1-7' },
    { value: '7-30', label: '7-30' },
    { value: '30+', label: '30+' },
  ]
  function onChangeValue(event: any) {
    setIncludeFailComments(parseInt(event.target.value))
  }
  const handleSearch = async () => {
    setCheckCusip(false)
    setInput({ ...input, pageNumber: 1 })
    const response = await dispatch(
      getReportData(
        'search',
        input.reportType,
        formatISO(Startdate).split('T')[0],
        input.reportType == 'daily' ? '' : formatISO(EndDate).split('T')[0],
        input.cusip,
        input.accountNumber,
        input.ageByPayDateRange,
        input.ageByLastActivityDateRange,
        includeFailComments,
        0,
        20,
        sorting,
        sortDirection
      )
    )
    if (response) {
      setTotalRecords(response.totalCount)
    }
  }

  const DownloadCSV = async () => {
    const response = await dispatch(
      getDividendReconReportCSV(
        `DividendReconReport__${input.reportType}_${
          formatISO(Startdate).split('T')[0]
        }`,
        input.reportType,
        formatISO(Startdate).split('T')[0],
        input.reportType == 'daily' ? '' : formatISO(EndDate).split('T')[0],
        input.reportType == 'daily' ? '' : input.cusip,
        '',
        '',
        '',
        0,
        0,
        0,
        sorting,
        sortDirection
      )
    )
  }
  const DownloadEXCEL = async () => {
    const response = await dispatch(
      getDividendReconReportEXCEL(
        `DividendReconReport__${input.reportType}_${
          formatISO(Startdate).split('T')[0]
        }`,
        input.reportType,
        formatISO(Startdate).split('T')[0],
        input.reportType == 'daily' ? '' : formatISO(EndDate).split('T')[0],
        input.reportType == 'daily' ? '' : input.cusip,
        '',
        '',
        '',
        0,
        0,
        0,
        sorting,
        sortDirection
      )
    )
  }

  useEffect(() => {
    dispatch(SetDividendReconReportData({ data: [] }))
    setTotalRecords(0)
  }, [input.reportType])

  return (
    <>
      <FilterContainer>
        <FilterChoiceContainer>
          <FilterOptionWrapper>
            <Label>Type</Label>
            <SelectDropdown
              defaultValue={{
                value: 'daily',
                label: 'Daily',
              }}
              borderRadius={'4px'}
              height="32px"
              options={typeOption}
              onChange={(e: any) => {
                {
                  setStartDate(DefaultDate ? new Date(DefaultDate) : '')
                  setEndDate(DefaultDate ? new Date(DefaultDate) : '')
                  setInput({ ...input, reportType: e })
                }
              }}
            ></SelectDropdown>
          </FilterOptionWrapper>
          {input.reportType == 'daily' ? (
            <div>
              <Label>Select Date</Label>
              <WeekDayCalender
                excludeDates={ExcludeDate}
                open={Calendar}
                onSelect={() => setCalendar(false)}
                onFocus={() => setCalendar(true)}
                onClickOutside={() => setCalendar(false)}
                selected={Startdate}
                maxDate={addDays(new Date(), -1)}
                onChange={(e: Date) => {
                  setStartDate(e)
                }}
              />
            </div>
          ) : (
            <>
              <div>
                <Label>Start Date</Label>
                <WeekDayCalender
                  excludeDates={ExcludeDate}
                  open={startCalendarDate}
                  onSelect={() => setStartCalendarDate(false)}
                  onFocus={() => setStartCalendarDate(true)}
                  onClickOutside={() => setStartCalendarDate(false)}
                  selected={Startdate}
                  startDate={Startdate}
                  selectsStart={true}
                  endDate={EndDate}
                  maxDate={addDays(new Date(), -1)}
                  onChange={(e: Date) => {
                    setStartDate(e)
                  }}
                />
              </div>
              <div>
                <Label>End Date</Label>
                <WeekDayCalender
                  excludeDates={ExcludeDate}
                  open={endCalendar}
                  onSelect={() => setEndCalender(false)}
                  onFocus={() => setEndCalender(true)}
                  onClickOutside={() => setEndCalender(false)}
                  selectsEnd={true}
                  selected={EndDate}
                  startDate={Startdate}
                  endDate={EndDate}
                  minDate={Startdate}
                  maxDate={addDays(new Date(), -1)}
                  onChange={(e: Date) => {
                    setEndDate(e)
                  }}
                />
              </div>
            </>
          )}
          <FilterOptionWrapper>
            <Label>Age By Paydate</Label>
            <SelectDropdown
              defaultValue={{
                value: '',
                label: 'Select',
              }}
              borderRadius={'4px'}
              height="32px"
              options={ageByPaydateOptions}
              onChange={(e: any) => {
                setInput({ ...input, ageByPayDateRange: e })
              }}
              //   value={ageOptions?.find((b: any) => b.value === ageRange)}
            ></SelectDropdown>
          </FilterOptionWrapper>
          <FilterOptionWrapper>
            <Label>Age By Last Activity Date</Label>
            <SelectDropdown
              defaultValue={{
                value: '',
                label: 'Select',
              }}
              borderRadius={'4px'}
              height="32px"
              options={ageByLastActivityDateOptions}
              onChange={(e: any) => {
                setInput({ ...input, ageByLastActivityDateRange: e })
              }}
              //   value={ageOptions?.find((b: any) => b.value === ageRange)}
            ></SelectDropdown>
          </FilterOptionWrapper>
          <FilterOptionWrapper>
            <Label>Account Number</Label>
            <Textbox
              placeholder="Enter Account Number"
              onChange={(e: any) => {
                setInput({ ...input, accountNumber: e })
              }}
              style={{
                borderRadius: '4px',
                height: '32px',
                padding: '4px 8px',
              }}
            />
          </FilterOptionWrapper>
          <FilterOptionWrapper>
            <Label>CUSIP</Label>
            <Textbox
              placeholder="Enter CUSIP"
              onChange={(e: any) => {
                setInput({ ...input, cusip: e })
              }}
              style={{
                borderRadius: '4px',
                height: '32px',
                padding: '4px 8px',
              }}
            />
          </FilterOptionWrapper>
          <RadioCommentWrapper>
            <input
              type="radio"
              value={0}
              name="AllFailComments"
              checked={includeFailComments == 0}
              onChange={onChangeValue}
            />{' '}
            <span>All</span>
          </RadioCommentWrapper>
          <RadioCommentWrapper>
            <input
              type="radio"
              value={1}
              name="includeFailComments"
              checked={includeFailComments == 1}
              onChange={onChangeValue}
            />{' '}
            <span>Include Comments</span>
          </RadioCommentWrapper>
          <RadioCommentWrapper>
            <input
              type="radio"
              value={2}
              name="includeFailComments"
              checked={includeFailComments == 2}
              onChange={onChangeValue}
            />{' '}
            <span>Exclude Comments</span>
          </RadioCommentWrapper>
          <MarginLeftAutoSpace>
            <Button
              hoverBgColor={COLORS.Background.Primary70}
              bgColor={COLORS.Background.Primarytext}
              color={COLORS.Background.Primary}
              padding={'4px 16px'}
              borderRadius={'4px'}
              hover={true}
              type={''}
              activeBgColor={COLORS.Background.Primaryactive}
              borderColor={'transparent'}
              onClick={() => {
                input.reportType == 'historical' &&
                formatISO(EndDate).split('T')[0] !==
                  formatISO(Startdate).split('T')[0] &&
                input.cusip == ''
                  ? setCheckCusip(true)
                  : handleSearch()
              }}
            >
              Search
            </Button>
          </MarginLeftAutoSpace>
        </FilterChoiceContainer>
        {checkCusip && (
          <Label color={COLORS.Background.Warning}>*Please enter Cusip</Label>
        )}
      </FilterContainer>
      <DownloadWrapper>
        <DownloadButtonWrapper
          onClick={() => {
            input.reportType == 'historical' &&
            formatISO(EndDate).split('T')[0] !==
              formatISO(Startdate).split('T')[0] &&
            input.cusip == ''
              ? setCheckCusip(true)
              : DownloadCSV()
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label cursor="pointer">CSV</Label>
          </WDStyledButtonText>
        </DownloadButtonWrapper>
        <DownloadButtonWrapper
          onClick={() => {
            input.reportType == 'historical' &&
            formatISO(EndDate).split('T')[0] !==
              formatISO(Startdate).split('T')[0] &&
            input.cusip == ''
              ? setCheckCusip(true)
              : DownloadEXCEL()
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label cursor="pointer">EXCEL</Label>
          </WDStyledButtonText>
        </DownloadButtonWrapper>
      </DownloadWrapper>
      {input.reportType == 'daily' ? (
        <ReportDetails
          commentDate={input.commentDate}
          reportType={input.reportType}
          startDate={formatISO(Startdate).split('T')[0]}
          endDate={
            input.reportType == 'daily' ? '' : formatISO(EndDate).split('T')[0]
          }
          cusip={input.cusip}
          accountNumber={input.accountNumber}
          ageByPayDateRange={input.ageByPayDateRange}
          ageByLastActivityDateRange={input.ageByLastActivityDateRange}
          includeFailComments={includeFailComments}
          totalRecords={totalRecords}
        />
      ) : (
        <HistoryReportDetails
          reportType={input.reportType}
          startDate={formatISO(Startdate).split('T')[0]}
          endDate={
            input.reportType == 'daily' ? '' : formatISO(EndDate).split('T')[0]
          }
          cusip={input.cusip}
          accountNumber={input.accountNumber}
          ageByPayDateRange={input.ageByPayDateRange}
          ageByLastActivityDateRange={input.ageByLastActivityDateRange}
          includeFailComments={includeFailComments}
          totalRecords={totalRecords}
        />
      )}
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </>
  )
}

export default ReportSearchPanel
