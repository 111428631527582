import styled from 'styled-components'
import { Backgrounds } from '../shared/styles'
import { COLORS } from '../theme/Colors'

export const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  color: black;
  background: ${Backgrounds.White};
`
export const BodyPane = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  background: ${COLORS.Background.Primary};
  // height: 100% !important;
  // width:100% !important;
  padding: 3rem 1rem;
`
export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  margin-left: 25px;
  place-content: end;
`

export const TopNavLayout = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 10px;
  // height: 100% !important;
  // width:100% !important;
  padding: 3rem 1rem;
`
