import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js'

import { Doughnut } from 'react-chartjs-2'
import { DoughnutChartProps } from './styles'

ChartJS.register(ArcElement, Tooltip, Legend)

const DoughnutChart = ({
  legendLabels,
  title,
  dataValues,
  bgColors,
  borderColors,
  borderWidth,
  onClick,
}: DoughnutChartProps) => {
  const data: ChartData<'doughnut'> = {
    labels: legendLabels,
    datasets: [
      {
        label: title,
        data: dataValues,
        backgroundColor: bgColors,
        borderColor: borderColors,
        borderWidth: borderWidth,
      },
    ],
  }

  return (
    <Doughnut
      data={data}
      options={{
        onHover: (event: any, chartElement) => {
          event.native.target.style.cursor = chartElement[0]
            ? 'pointer'
            : 'default'
        },
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              color: '#0F172A',
              usePointStyle: true,
              pointStyle: 'rectRounded',
              boxWidth: 24,
              boxHeight: 24,
            },
          },
          datalabels: {
            display: false,
          },
        },
        aspectRatio: 2,
        onClick,
      }}
    />
  )
}

export default DoughnutChart
