import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/button/Button'
import DatePicker from '../../../components/datePicker/DatePicker'
import Label from '../../../components/label/Label'
import { Textbox } from '../../../components/textbox/Textbox'
import {
  RootContainer,
  StyledButtonWrapper,
  StyledFilterPanel,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledSelectWrapper,
  StyledTableContainer,
  StyledTableTopContainer,
} from '../styles'
import { useState } from 'react'
import PaginationTable from './table/PaginationTable'
import { IconsWrapper } from '../../styles'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { Colors } from '../../../shared/styles'
import {
  getP3UnSpecifiedSecurityUpdateCount,
  p3UnSpecifiedSecurityUpdateSelector,
} from '../../../store/securityUpdate/securityUpdate'
import { getProductColumns } from './securityUnspecifiedColumns'

interface InputModel {
  reportDate?: string | null
}

const SecurityUnspecifiedFieldDetails = () => {
  const dispatch = useDispatch()
  const unSpecifiedSecurityUpdate = useSelector(
    p3UnSpecifiedSecurityUpdateSelector
  )
  const [input, setInput] = useState<InputModel>({
    reportDate: new Date().toISOString().split('T')[0],
  })
  const handleSearch = async () => {
    await dispatch(getP3UnSpecifiedSecurityUpdateCount(input.reportDate))
  }

  return (
    <RootContainer>
      <StyledPanelHead>
        <StyledPanelHeadTitle>Unspecified Field Details</StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledFilterPanel>
          <StyledSelectWrapper>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Report Date
              </Label>
              <DatePicker
                padding={'4px 8px'}
                onChange={(e: string) => {
                  setInput({ ...input, reportDate: e })
                }}
                value={input.reportDate}
                max={new Date().toISOString().split('T')[0]}
                bgColor=""
                borderColor=""
                placeHolder="From"
                maxWidth="295px"
                defaultValue={new Date().toISOString().split('T')[0]}
              />
            </div>

            <StyledButtonWrapper>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
            </StyledButtonWrapper>
          </StyledSelectWrapper>
        </StyledFilterPanel>

        <StyledTableTopContainer>
          <IconsWrapper onClick={() => console.log('download clicked')}>
            <SvgDownloadSmall fillColor={Colors.PrimaryText}></SvgDownloadSmall>
            <Label color={Colors.PrimaryText}>Download</Label>
          </IconsWrapper>
        </StyledTableTopContainer>

        <StyledTableContainer>
          <PaginationTable
            data={
              unSpecifiedSecurityUpdate &&
              unSpecifiedSecurityUpdate.p3UnspecifiedSecurityUpdateModel
                ?.length > 0
                ? unSpecifiedSecurityUpdate.p3UnspecifiedSecurityUpdateModel
                : []
            }
            columns={
              unSpecifiedSecurityUpdate &&
              unSpecifiedSecurityUpdate.dateViewModel?.length > 0
                ? getProductColumns(
                    unSpecifiedSecurityUpdate.dateViewModel[0].previousDate,
                    unSpecifiedSecurityUpdate.dateViewModel[0].currentDate
                  )
                : getProductColumns('', '')
            }
            onClick={() => {
              //
            }}
            hidePagination={false}
          />
          {/* ) : null} */}
        </StyledTableContainer>
      </StyledPanelSelect>
    </RootContainer>
  )
}

export default SecurityUnspecifiedFieldDetails
