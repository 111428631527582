import { combineReducers } from '@reduxjs/toolkit'
import staticDashboard from './static-data'
import settlement from './settlement'
import about from './about'
import jamsJob from './jamsJob'
import errorReport from './error-report'

export const Dashboard = combineReducers({
  staticDashboard,
  settlement,
  about,
  jamsJob,
  errorReport,
})
