/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  betaSecLending: any
  isLoading: boolean
  action: string
  addComment: any
  comments: any
  httpErrorMessage:string,
  successMessage:string
}

let initialState: State = {
  betaSecLending: {},
  isLoading: false,
  action: '',
  addComment: {},
  comments: {},
  httpErrorMessage:'',
  successMessage:''
}

const { actions, reducer }: Slice = createSlice({
  name: 'beta-security-lending',
  initialState,
  reducers: {
    SetBetaSecLendingDetails: (state: State, action: PayloadAction<any>) => {
      return {
        ...state,
        betaSecLending: action.payload.data,
      }
    },

    SetIsLoading: (state: State, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      }
    },
    SetAction: (state: State, action: PayloadAction<string>) => {
      return {
        ...state,
        action: action.payload,
      }
    },
    SetCommentResponse: (state: State, action: PayloadAction<boolean>) => {
      state.addComment = action.payload
    },
    setBetaSecLendingComments: (state: State, action: PayloadAction<any>) => {
      state.comments = action.payload.data
    },
    setHttpErrorMessage: (
      state: State,
      action: PayloadAction<string>
    ) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (
      state: State,
      action: PayloadAction<string>
    ) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  SetAction,
  SetBetaSecLendingDetails,
  SetIsLoading,
  SetCommentResponse,
  setBetaSecLendingComments,
  setHttpErrorMessage,
  setSuccessMessage
} = actions

export const getBetaSecLendingDetails: any =
  (system: string, payload: any) => async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/BuyInIntents/BetaSecurityLendingReport`,
        { ...payload, pageNumber: 0, pageSize: 0 }
      )

      if (status === 200) {
        if(data.errorResponse.statusCode==null){
          dispatch(
          SetBetaSecLendingDetails({
            data: data,
          })
        )}
        else{
          dispatch(
            SetBetaSecLendingDetails({
              data: data,
            })
          )
          dispatch(setHttpErrorMessage(data.errorResponse.message))
        }
        dispatch(SetIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(
        SetBetaSecLendingDetails({
          data: {},
        })
      )
      dispatch(SetIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
  }

export const getBetaSecLendingComments: any =
  (snapShotDate: string, accountNumber: number, securityNumber: number) =>
  async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        `opsfailprocessapi/BuyInIntents/GetBetaSecurityLendingComments?snapshotdate=${snapShotDate}&accountNumber=${accountNumber}&securityNumber=${securityNumber}`
      )
      if (status === 200) {
        dispatch(
          setBetaSecLendingComments({
            data: data,
          })
        )
        dispatch(SetIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(SetIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
  }

export const saveBetaSecLendingComment: any =
  (request: any) => async (dispatch: Dispatch) => {
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/AddBetaSecurityLendingComments',
        request
      )

      if (status === 200) {
        dispatch(
          SetCommentResponse({
            data: data,
          })
        )
        return data
      }
    } catch (e: any) {
      dispatch(
        SetCommentResponse({
          data: { tradeBreakActionModels: [] },
        })
      )
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return {
        data: {
          actionCountModel: [
            {
              actionCount: 0,
            },
          ],
        },
      }
    }
  }
export const getBetaSecLendingExcel: any =
  (fileName: string, payload: {}) => async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/ExportBetaSecurityLendingReport ',
        { ...payload, pageNumber: 0, pageSize: 0 },
        { responseType: 'blob' }
      )
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(SetIsLoading(false))
        return status
      }
    } catch (e: any) {
      dispatch(SetIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }
export const BetaSecLendingSelector = (store: StoreState) =>
  store.BetaSecLendingReducer.BetaSecLending.betaSecLending

export const actionSelector = (store: StoreState) =>
  store.BetaSecLendingReducer.BetaSecLending.action

export const loadingSelector = (store: StoreState) =>
  store.BetaSecLendingReducer.BetaSecLending.isLoading

export const BetaSecLendingCommentsSelector = (store: StoreState) =>
  store.BetaSecLendingReducer.BetaSecLending.comments

  export const HttpErrorMessageSelector = (store: StoreState) =>
store.BetaSecLendingReducer.BetaSecLending.httpErrorMessage

export const HttpSuccessMessageSelector = (store: StoreState) =>
store.BetaSecLendingReducer.BetaSecLending.successMessage
export default reducer
