/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'
export interface ImageProps {
  image?: JSX.Element | string
  alt: string
  width?: string
  height?: string
  onClick?: any
}

export const StyledImage = styled.img``
