//Global styles
import SRPLogo from '../assets/logos/Logo.svg'
import Notifications from '../assets/icons/Notifications.svg'
import Bell from '../assets/icons/Bell.svg'
import Calendar from '../assets/icons/Calendar.svg'
import ChevronLeft from '../assets/icons/ChevronLeft.svg'
import ChevronRight from '../assets/icons/ChevronRight.svg'
import ChevronDown from '../assets/icons/ChevronDown.svg'
import ChevronUp from '../assets/icons/ChevronUp.svg'
import ChevronDownPrimary from '../assets/icons/ChevronDownPrimary.svg'
import Profile from '../assets/icons/Profile.svg'
import UploadFile from '../assets/icons/UploadFile.svg'
import SaveDraft from '../assets/icons/SaveDraft.svg'
import Cancel from '../assets/icons/Cancel.svg'
import QuestionMark from '../assets/icons/QuestionMark.svg'
import Success from '../assets/icons/Success.svg'
import Critical from '../assets/icons/Critical.svg'
import Warning from '../assets/icons/Warning.svg'
import Default from '../assets/icons/Default.svg'
import LogOut from '../assets/icons/Logout.svg'
import Neutral_Search from '../assets/icons/SearchGray.svg'
import White_Search from '../assets/icons/Search.svg'
import White_Plus from '../assets/icons/Plus.svg'
import ArrowDown from '../assets/icons/ArrowDown.svg'
import ExclamationWarning from '../assets/icons/ExclamationWarning.svg'
import BlueChevronDown from '../assets/icons/ChevronDownPrimary.svg'
import RightMark from '../assets/icons/RightMark.svg'
import Document from '../assets/icons/Document.svg'
import Download from '../assets/icons/Download.svg'
import Search from '../assets/icons/Search.svg'
import RightSymbol from '../assets/icons/RightSymbol.svg'
import TwoNumber from '../assets/icons/TwoNumber.svg'
import ThreeNumber from '../assets/icons/ThreeNumber.svg'
import FourNumber from '../assets/icons/FourNumber.svg'
import OperationsPortal from '../assets/logos/OperationsPortal.svg'
import Edit from '../assets/icons/Edit.png'
import Exclamation from '../assets/icons/Exclamation.svg'
import InactiveBackwardDoubleChevron from '../assets/icons/InactiveBackwardDoubleChevron.svg'
import ActiveForwardDoubleChevron from '../assets/icons/ActiveForwardDoubleChevron.svg'
import ActiveBackwardDoubleChevron from '../assets/icons/ActiveBackwardDoubleChevron.svg'
import InactiveForwardDoubleChevron from '../assets/icons/InactiveForwardDoubleChevron.svg'
import BackwardFirst from '../assets/icons/ChevronPrevious.svg'
import ForwardLast from '../assets/icons/ChevronNext.svg'
import ArrowNegativeDownward from '../assets/icons/ArrowNegativeDownward.svg'
import ArrowPositiveDownward from '../assets/icons/ArrowPositiveDownward.svg'
import ArrowNegativeUpward from '../assets/icons/ArrowNegativeUpward.svg'
import ArrowPositiveUpward from '../assets/icons/ArrowPositiveUpward.svg'
import Dash from '../assets/icons/Dash.svg'
import Delete from '../assets/icons/DeleteIcon.svg'

export const Icons = {
  Delete,
  Exclamation,
  Edit,
  OperationsPortal,
  SRPLogo,
  Notifications,
  Bell,
  Calendar,
  ChevronLeft,
  ChevronRight,
  ThreeNumber,
  TwoNumber,
  FourNumber,
  ChevronDown,
  ChevronUp,
  ChevronDownPrimary,
  Profile,
  UploadFile,
  SaveDraft,
  Cancel,
  Document,
  Download,
  QuestionMark,
  Success,
  Critical,
  Warning,
  Default,
  RightSymbol,
  Search,
  LogOut,
  ArrowDown,
  ExclamationWarning,
  RightMark,
  InactiveBackwardDoubleChevron,
  ActiveForwardDoubleChevron,
  ActiveBackwardDoubleChevron,
  InactiveForwardDoubleChevron,
  BackwardFirst,
  ForwardLast,
  ArrowNegativeDownward,
  ArrowPositiveUpward,
  ArrowPositiveDownward,
  ArrowNegativeUpward,
  Dash,
}

export const NeutralIcons = {
  Cancel,
  Neutral_Search,
}

export const WhiteIcons = {
  White_Search,
  White_Plus,
}

export const BlueIcons = {
  BlueChevronDown,
}
