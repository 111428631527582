/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'
import { ReactNode } from 'react'
import { COLORS } from '../../theme/Colors'
export interface LabelProps {
  fontFamily?: string
  fontSize?: string
  fontStyle?: string
  fontWeight?: number
  lineHeight?: string
  color?: string
  children?: ReactNode
  bgColor?: string
  letterSpacing?: string
  onClick?: () => void | any
  cursor?: string
  margin?: string
  padding?: string
  minWidth?: string
  hover?: boolean
  title?:string
}

export const StyledLabel = styled.p<LabelProps>`
  font-family: ${(props: LabelProps) => props.fontFamily};
  font-style: ${(props: LabelProps) => props.fontStyle};
  font-weight: ${(props: LabelProps) => props.fontWeight};
  font-size: ${(props: LabelProps) => props.fontSize};
  line-height: ${(props: LabelProps) => props.lineHeight};
  color: ${(props: LabelProps) => props.color};
  cursor: ${(props: LabelProps) => props.cursor};
  letter-spacing: ${(props: LabelProps) => props.letterSpacing};
  margin: ${(props: LabelProps) => props.margin ? props.margin : 0};
  padding: ${(props: LabelProps) => props.padding};
  min-width: ${(props: LabelProps) => props.minWidth};
  &:hover {
    font-weight:${(props: LabelProps) => props.hover ? '600' : ''};
    color:${(props: LabelProps) => props.hover ? COLORS.Background.Primarytext : ''};
  }
`
