import { ISvg } from './ISvg'

const SvgRedo = ({ fillColor }: ISvg) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7.33331C13.8232 7.33331 13.6536 7.40355 13.5286 7.52858C13.4036 7.6536 13.3333 7.82317 13.3333 7.99998C13.3413 9.24867 12.9135 10.461 12.1238 11.4283C11.334 12.3955 10.2317 13.0571 9.00662 13.2991C7.78158 13.541 6.5105 13.3482 5.41232 12.7538C4.31413 12.1595 3.45758 11.2008 2.9902 10.0428C2.52281 8.88487 2.47384 7.60019 2.85173 6.41003C3.22961 5.21987 4.0107 4.19874 5.06045 3.5225C6.1102 2.84626 7.36291 2.55725 8.60282 2.70525C9.84273 2.85325 10.9922 3.42899 11.8533 4.33331H10.2533C10.0765 4.33331 9.90694 4.40355 9.78191 4.52857C9.65689 4.6536 9.58665 4.82317 9.58665 4.99998C9.58665 5.17679 9.65689 5.34636 9.78191 5.47138C9.90694 5.59641 10.0765 5.66665 10.2533 5.66665H13.2733C13.4501 5.66665 13.6197 5.59641 13.7447 5.47138C13.8697 5.34636 13.94 5.17679 13.94 4.99998V1.99998C13.94 1.82317 13.8697 1.6536 13.7447 1.52857C13.6197 1.40355 13.4501 1.33331 13.2733 1.33331C13.0965 1.33331 12.9269 1.40355 12.8019 1.52857C12.6769 1.6536 12.6066 1.82317 12.6066 1.99998V3.17998C11.4964 2.11869 10.0538 1.47328 8.52266 1.35287C6.99151 1.23245 5.46573 1.64441 4.20326 2.51911C2.94079 3.3938 2.01905 4.67758 1.59387 6.15344C1.16868 7.6293 1.26612 9.2067 1.86972 10.619C2.47332 12.0313 3.54605 13.1919 4.90658 13.9045C6.26711 14.6172 7.83198 14.8382 9.33668 14.5303C10.8414 14.2223 12.1936 13.4043 13.1647 12.2144C14.1359 11.0246 14.6664 9.53586 14.6666 7.99998C14.6666 7.82317 14.5964 7.6536 14.4714 7.52858C14.3464 7.40355 14.1768 7.33331 14 7.33331Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default SvgRedo
