import { useEffect, useState } from 'react'
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  PopUpStyleLoader,
  StyledSelectWrapper,
  LottieWrapper,
  PopUpStyleContent,
  MarginLeftAutoSpace,
  CancelButtonWrapper,
  CommentTableContainer,
} from './RegShoHistoryStyles'
import RegShoHistorySearchPanel from './RegShoHistorySearchPanel'
import { StyledTableContainer } from './RegShoHistoryStyles'
import {
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  RegShoHistoryDataSelector,
  TotalCountSelector,
  getL2AllEmailList,
  getRegShoCommentsHistory,
  loadingSelector,
  setSuccessMessage,
  sethttpErrorMessageSummary,
} from '../../../store/settlement/regSho-History'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  RegShoColumnsBuyIn,
  RegShoColumnsL1ALL,
  RegShoColumnsL2All,
  RegShoColumnsL3All,
} from './RegShoHistoryColumns'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import { COLORS } from '../../../theme/Colors'
import Divider from '../../../components/divider/Divider'
import { Backgrounds } from '../../../shared/styles'
import { EmailList } from './EmailList'
import TableRegSHOHistory from './regshiHistoryTable/Table'
import Table from '../../../components/table/Table'
import { ToastWrapper } from '../SettlementCommonStyles'
import { Toast } from '../../../components/toast/Toast'

export interface IRegShoHistorySearchFilters {
  reportDateFrom: string
  reportDateTo: string
  cusip: string
  symbol: string
  source: string
  pageName: string
  allocationType: string
}

const RegShoHistoryPage = () => {
  const InitialFilterParams = {
    reportDateFrom: new Date().toISOString().split('T')[0],
    reportDateTo: new Date().toISOString().split('T')[0],
    cusip: '',
    symbol: '',
    source: 'Beta',
    pageName: 'CusipLevelSummaryBuyInDetails',
    allocationType: 'all',
  }
  const dispatch = useDispatch()
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [searchParams, setSearchParams] = useState({ ...InitialFilterParams })
  const [tableColumn, setTableColumn] = useState<any>('')
  const [mailList, setMailList] = useState(false)
  const [userComment, setUserComment] = useState<any>([])
  const [AllEmailList, setAllEmailList] = useState<any>([])
  const [viewAllComments, setViewAllComments] = useState<boolean>(false)
  const [showTable, setShowTable] = useState(false)
  const Data = useSelector(RegShoHistoryDataSelector)
  const TotalCount = useSelector(TotalCountSelector)
  const Loading = useSelector(loadingSelector)

  const handleViewComment = async (
    source: string,
    selectedSummaryTableCUSIP: string,
    failDate: string
  ) => {
    const response = await dispatch(
      getRegShoCommentsHistory(source, selectedSummaryTableCUSIP, failDate)
    )
    setUserComment(response)
    await setViewAllComments(true)
  }
  const AllMailList = async (regSHOId: string,allocation_ID:string) => {
    const response = await dispatch(
      getL2AllEmailList(regSHOId, allocation_ID, searchParams.pageName)
    )
    setAllEmailList(response)
    setMailList(true)
  }
  useEffect(() => {
    if (searchParams.pageName === 'CusipLevelSummaryBuyInDetails') {
      setTableColumn(RegShoColumnsL1ALL(AllMailList, handleViewComment))
    }
    if (searchParams.pageName === 'Allocations') {
        setTableColumn(RegShoColumnsL2All(AllMailList))
    }
    if (searchParams.pageName === 'BuyInExecDetails') {
        setTableColumn(RegShoColumnsL3All(AllMailList))
    }
    if (searchParams.pageName === 'BuyInDetails') {
      setTableColumn(RegShoColumnsBuyIn(AllMailList))
    }
  }, [searchParams])

  return (
    <>
      <RootContainer>
        {Loading && (
          <PopUpStyleLoader>
            <StyledSelectWrapper style={{ flexDirection: 'column' }}>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LottieWrapper style={{ left: '53.5%' }}>
                {' '}
                Loading...
              </LottieWrapper>
            </StyledSelectWrapper>
          </PopUpStyleLoader>
        )}
        <StyledPanelHead>
          <StyledPanelHeadTitle>REGSHO Historical Report</StyledPanelHeadTitle>
        </StyledPanelHead>
        <RegShoHistorySearchPanel
          searchParams={searchParams}
          setsearchParams={setSearchParams}
          setShowTable={setShowTable}
        />
        {showTable && (
          <StyledTableContainer>
            <TableRegSHOHistory
              hidePagination={false}
              heading={searchParams.pageName}
              data={Data && Data?.length ? Data : []}
              totalCount={TotalCount ? TotalCount.data : 0}
              columns={tableColumn}
            />
          </StyledTableContainer>
        )}
        {mailList && (
          <EmailList
            AllEmailList={AllEmailList}
            ClosePopUp={() => {
              setMailList(false)
            }}
          />
        )}
        {viewAllComments && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{
                padding: '15px 15px 0px 15px',
                fontWeight: '600',
                borderRadius: '8px 8px 0px 0px',
              }}
            >
              <>All Comments</>
              <MarginLeftAutoSpace>
                <CancelButtonWrapper
                  onClick={() => {
                    setViewAllComments(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </CancelButtonWrapper>
              </MarginLeftAutoSpace>
              <Divider
                thickness={'1px'}
                horizontal={true}
                bgColor={Backgrounds.Gray110}
              />
            </StyledSelectWrapper>
            <CommentTableContainer>
              <Table
                tableColumns={[
                  {
                    Header: 'Comment',
                    accessor: 'comment',
                  },
                  {
                    Header: 'Created by',
                    accessor: 'createdBy',
                  },
                  {
                    Header: 'Create on(EST)',
                    accessor: 'createdOn',
                  },
                ]}
                tableData={[]}
                onClick={() => {
                  //
                }}
              />
              {userComment.length == 0 && <h3>No Comments</h3>}
            </CommentTableContainer>
          </PopUpStyleContent>
        )}
         {(SuccessMessage?.length > 0 ||
        ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success':'danger'}
            openStatusCallback={() => {
              dispatch(setSuccessMessage(''))
              dispatch(sethttpErrorMessageSummary(''))
            }}
          />
        </ToastWrapper>
      )}
      </RootContainer>
    </>
  )
}

export default RegShoHistoryPage
