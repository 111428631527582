/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  BetaLoanetReconReportData: []
  totalCount: number
  isLoading: boolean
  action: string
  comments: any
  includeFailComments: number
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  BetaLoanetReconReportData: [],
  totalCount: 0,
  isLoading: false,
  action: '',
  comments: [],
  includeFailComments: 0,
  httpErrorMessage: '',
  successMessage: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'BetaLoanetReconReport',
  initialState,
  reducers: {
    SetBetaLoanetReconReportData: (
      state: State,
      action: PayloadAction<any>
    ) => {
      state.BetaLoanetReconReportData = action.payload.data
    },
    setTotalCount: (state: State, action: PayloadAction<any>) => {
      state.totalCount = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setComments: (state: State, action: PayloadAction<any>) => {
      state.comments = action.payload.data
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  SetBetaLoanetReconReportData,
  setTotalCount,
  setIsLoading,
  setAction,
  setComments,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getBetaLoanNetReconReport: any =
  (action: string, reportDate: string, pageNumber: number, pageSize: number) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetLoanNetRecon',
        {
          reportDate,
          pageNumber,
          pageSize,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setAction(action))
        dispatch(
          SetBetaLoanetReconReportData({
            data: data.loanNetReconList,
          })
        )
        dispatch(
          setTotalCount({
            totalCount: data.totalCount,
          })
        )
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const putUserComment: any =
  (
    loanNetId: string,
    reportDate: string,
    acct: string,
    brkr: string,
    cusip: string,
    secNo: string,
    loanetQnty: string,
    wedbushQnty: string,
    qtyDiff: string,
    loanetAmt: string,
    wedbushAmt: string,
    amtDiff: string,
    comment: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/AddLoanNetReconComment',
        {
          loanNetId,
          reportDate,
          acct,
          brkr,
          cusip,
          secNo,
          loanetQnty,
          wedbushQnty,
          qtyDiff,
          loanetAmt,
          wedbushAmt,
          amtDiff,
          comment,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('Added Successfully'))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const getComments: any =
  (
    acct: string,
    brkr: string,
    cusip: string,
    secNo: string,
    loanetQnty: string,
    wedbushQnty: string,
    loanetAmt: string,
    wedbushAmt: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetLoanNetReconComment`,
        {
          acct,
          brkr,
          cusip,
          secNo,
          loanetQnty,
          wedbushQnty,
          loanetAmt,
          wedbushAmt,
        }
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(
          setComments({
            data: data,
          })
        )
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setComments({
          data: [],
        })
      )
    }
  }

export const getBetaLoanNetReconCSVReport: any =
  (
    fileName: string,
    reportDate: string,
    pageNumber: number,
    pageSize: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetLoanNetReconCSVReport',
        {
          reportDate,
          pageNumber,
          pageSize,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('File Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const BetaLoanetReconReportDataSelector = (store: StoreState) =>
  store.operationsControl.BetaLoanetReconReport.BetaLoanetReconReportData
export const actionSelector = (store: StoreState) =>
  store.operationsControl.BetaLoanetReconReport.action
export const isLoadingSelector = (store: StoreState) =>
  store.operationsControl.BetaLoanetReconReport.isLoading
export const commentSelector = (store: StoreState) =>
  store.operationsControl.BetaLoanetReconReport.comments
export const TotalCountSelector = (store: StoreState) =>
  store.operationsControl.BetaLoanetReconReport.totalCount
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.operationsControl.BetaLoanetReconReport.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.operationsControl.BetaLoanetReconReport.successMessage
export default reducer
