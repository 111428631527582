export const configJSONDashboard = {
  TradeBreaks: {
    tradeBreaksByAgeEquity: {
      kpi: 'tradebreakapi/TradeBreak/GetTradeBreakSummaryByAge?AssetType=EQ',
    },
    tradeBreaksByAgeFI: {
      kpi: 'tradebreakapi/TradeBreak/GetTradeBreakSummaryByAge?AssetType=FI',
    },
    tradeBreakCountT0: {
      kpi: 'tradebreakapi/TradeBreak/GetTradeBreakCount',
    },
    tradeBreakCountT1: {
      kpi: 'tradebreakapi/TradeBreak/GetTradeBreakCount',
    },
    suspenseAccount: {
      kpi: 'tradebreakapi/TradeBreak/SuspenseAccountCount',
    },
    tradeBreaksTrend: {
      kpi: 'tradebreakapi/TradeBreak/GetTradeBreakTrendDetails',
    },
    alerts: {
      kpi: 'tradebreakapi/TradeBreak/GetAlertsAndNotificationDetails',
    },
  },
  IntraDayTradeBreaks: {
    tradeBreaksByAgeEquity: {
      kpi: 'tradebreakapi/IntraDayTradeBreak/GetIntraDayTradeBreakSummaryByAge?AssetType=EQ',
    },
    tradeBreaksByAgeFI: {
      kpi: 'tradebreakapi/IntraDayTradeBreak/GetIntraDayTradeBreakSummaryByAge?AssetType=FI',
    },
    tradeBreakCountT0: {
      kpi: 'tradebreakapi/IntraDayTradeBreak/GetIntradayTradeBreakCount',
    },
    tradeBreakCountT1: {
      kpi: 'tradebreakapi/IntraDayTradeBreak/GetIntradayTradeBreakCount',
    },
    suspenseAccount: {
      kpi: 'tradebreakapi/TradeBreak/SuspenseAccountCount',
    },
    tradeBreaksTrend: {
      kpi: 'tradebreakapi/IntraDayTradeBreak/GetIntradayTradeBreakTrendDetails',
    },
    alerts: {
      kpi: 'tradebreakapi/TradeBreak/GetAlertsAndNotificationDetails',
    },
  },
  Static: {
    fisPhase: {
      kpi: 'opsstaticdataapi/StaticData/GetP3StaticCount',
    },
    refinitiveBeta: {
      kpi: 'opsstaticdataapi/StaticData/GetBetaStaticCount',
    },
    topSecurity: {
      kpi: 'opsstaticdataapi/StaticData/GetP3StaticSecurityUpdateCount',
    },
    betaPhase3ManualPricing: {
      kpi: 'opsstaticdataapi/StaticData/GetManualPricingStaticData',
    },
  },
  Settlement: {},
}
