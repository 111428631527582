import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export interface AlertProps {
  border?: string
  background?: string
  fontColor?: string
}

export const WidgetContainer = styled.div`
  border: 1px solid black;
  height: fit-content;
  padding: 20px;
`

export const LottieWrapper = styled.div`
  // position: fixed;
  // top: 70%;
  // left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`

export const TitleContainer = styled.div`
  white-space: nowrap;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  padding: 12px 5px;
  color: #ffffff;
`
export const FeatureTile = styled.div``
export const TradeReconInfoTile = styled.div`
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #9d3c72;
  background: #ffaacf;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const SummaryCardContainerStaticData = styled.div`
  border: 1px solid #e2e8f0;
  // border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 20px;
  position: relative;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
`
export const SummaryCardContainerTradeReconcilation = styled.div`
  border: 1px solid #e2e8f0;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 20px;
  display: flex;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: #1e40af;
  width: 100%;
  max-width: 400px;
`
export const SummaryCardContainerSettlement = styled.div`
  border: 1px solid #e2e8f0;
  // border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 20px;
  position: relative;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
`
export const IconConatainer = styled.div``
export const FeatureDescription = styled.div`
  color: #ffffff;
  font-size: 18px;
`
