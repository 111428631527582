export const COLORS = {
  Brand: {
    Primary: '#FFFFFF',
    Secondary: '#',
    Muted: '#374253',
    Brand1: '#00A9CE',
    Brand2: '#326295',
    Brand3: '#00245D',
  },
  UI: {
    Primary: '#192638',
    Primary50: '#3B82F6',
    Secondary: '#596579',
    Tertiary: '#ffffff',
    Quaternary: '#475569', //icon colour
    Activeicon: '#1E293B', //Name has to be provide by UX team
    Disabled: '#',
    DangerBackground: '#EA001E',
    Icon: '#475569',
    Error: '#C72F24',
    Success: '#2E844A',
    Text: '#0F172A',
    Border: '#CBD5E1', //Name has to be provided by the UX team
    TextWeak: '#475569', //Name has to be provided by the UX team
    BorderStrong: '#FE7765', //Name has to be provided by UX team
    BackgroundStrong: '#2563EB', //Name has to be provided by UX team
    PrimaryBorderStrong: '#60A5FA',
    Gray: '#d0d7e2',
  },
  Background: {
    Primary: '#FFFFFF',
    Primarystrong: '#1E3A8A', //Name has to be provide by UX team
    Primarytext: '#2563EB', //Name has to be provided by Ux team
    Primary50: '#3B82F6',
    Primary10: '#DBEAFE',
    Primary70: '#1D4ED8',
    Neutral40: '#A7AFBC',
    Primarybg: '#EFF6FF',
    Secondary: '#E5E5E5', // Name has to be provide by UX team : my comment landing page background color
    Tertiary: '#E6EAF0',
    HighLight: '#1F5EB7',
    NeutralIcons: '#475569', //Name has to be provided by the Ux team
    Settings: '#00245D',
    Border: '#CBD5E1',
    BorderHover: '#64748B',
    StrongBackgound: '#E2E8F0',
    Primaryactive: '#1E40AF',
    WarningBackgroud: '#FFF7ED',
    Neutral50: '#64748B', //Name has to be provided by UX
    Warning: '#F97316', //Name has to be provide by UX team
    NeutralWeak: '#F8FAFC',
    NeutralBackground: '#FFF',
    PrimaryBorderStrong: '#60A5FA',
    NeutralBackgroundMedium: '#F1F5F9',
    NeutralBorder: '#bfdbfe',
    BlurBackground: '#fcfdff',
    DarkCyanBlueBackground: '#181e27',
    MediumDarkRed: '#b30000',
  },
  Text: {
    Primary: '#2563EB',
    Gray80: '#192638',
    Tertiary: '#ffffff',
    Neutral: '#0F172A',
    Neutral70: '#334155',
    NeutralTextWeak: '#475569',
    NeutralTextInverted: '#FFF',
    NeutralTextDisabled: ' #A7AFBC',
    Quaternary: '#1E293B', //neutral-80
    Disabled: '#',
    Error: '#C72F24',
    Success: '#2E844A',
    Success2: '#3BA755', //Name has to be provided by UX team
    Text: '#0F172A',
    TextWeak: '#475569', //Name has to be provided by UX team
    Danger: '#BA0517', //Name has to be provided by UX team
    Black: '#000000', //Name has to be provided by UX team
  },
  Icons: {
    NeutralIconInverted: '#FFFFFF',
    PrimaryIcon: '#2563EB',
    DangerIcon: '#EA001E',
    SuccessIcon: '#3BA755',
    NeutralIcon: '#475569',
  },
}

export const DEFAULT_COLORS = {
  Background: {
    Neutral: '#FFFFFF',
    NeutralWeak: '#F8FAFC',
    NeutralStrong: '#E2E8F0',
    NeutralMedium: '#F1F5F9',
    Primary: '#2563EB',
    PrimaryStrong: '#1E3A8A',
    PrimaryBorderStrong: '#60A5FA',
    PrimaryHover: '#DBEAFE',
    PrimaryActive: '#BFDBFE',
    DangerStrong: '#EA001E',
    Primary10: '#A4C5E8',
  },
  Text: {
    UserDark: '#303030',
    Elevation: '#00245D',
    Neutral: '#0F172A',
    NeutralWeak: '#475569',
    NeutralInverted: '#FFFFFF',
    Primary: '#2563EB',
    PrimaryStrong: '#1E3A8A',
    Success: '#2E844A',
    NeutralDisabled: '#A7AFBC',
    PrimaryActive: '#1E40AF',
  },
  Border: {
    Primary: '#2563EB',
    PrimaryStrong: '#60A5FA',
    PrimaryActive: '#2563EB',
    Neutral: '#CBD5E1',
    NeutralMedium: '#F1F5F9',
    NeutralHover: '#64748B',
    NeutralWeak: '#E2E8F0',
    NeutralDisabled: '#A7AFBC',
    Accent: '#1F5EB7',
  },
  Icons: {
    Primary: '#2563EB',
    PrimaryActive: '#1E3A8A',
    Neutral: '#475569',
    NeutralDisabled: '#A7AFBC',
    NeutralInverted: '#FFFFFF',
    NeutralHover: '#334155',
  },
  BoxShadow: {
    StyledCardShadow: 'rgba(0, 36, 93, 0.3)',
    Shadow: '0px 2px 6px -2px rgba(0, 36, 93, 0.3)',
    PlusMenuBoxShadow: '0px 8px 16px -8px rgba(0, 36, 93, 0.3)',
  },
}
