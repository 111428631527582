import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  regShoHistoryAuditData: {}
  totalCount: number
  isLoading: boolean
  action: string
  httpErrorMessage: string
  toastMessage: string
}

const  initialState: State = {
  regShoHistoryAuditData: {},
  totalCount: 0,
  isLoading: false,
  action: '',
  httpErrorMessage: '',
  toastMessage: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'reg-sho-history-audit',
  initialState,
  reducers: {
    SetRegShoHistoryAuditData: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        regShoHistoryAuditData: action.payload.data.data,
      }
    },

    SetIsLoading: (state: State, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      }
    },
    SetAction: (state: State, action: PayloadAction<string>) => {
      return {
        ...state,
        action: action.payload,
      }
    },
    setTotalCount: (state: State, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    sethttpErrorMessageSummary: (
      state: State,
      action: PayloadAction<string>
    ) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.toastMessage = action.payload
    },
  },
})

export const {
  SetAction,
  SetRegShoHistoryAuditData,
  SetIsLoading,
  setTotalCount,
  sethttpErrorMessageSummary,
  setSuccessMessage
} = actions

export const getRegShoHistoryAudit: any =
  (system: string, payload: any) => async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoHistoricalReportAuditTrail`,
        { ...payload, page: 0, pageSize: 0,})

      if (status === 200) {
        dispatch(
          SetRegShoHistoryAuditData({
            data: data,
          })
        )
        dispatch(
          setTotalCount({
            data: data.totalCount,
          })
        )
        dispatch(SetIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(
        SetRegShoHistoryAuditData({
          data: {},
        })
      )
      dispatch(SetIsLoading(false))
    }
  }

  export const getRegShoHistoryExcel: any =
  (
    payload: any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/RegShoHistoricalReportExcelExport',
        { ...payload, page: 0, pageSize: 0,},
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `RegshoHistory${payload.pageName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(SetIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(SetIsLoading(false))
      return null
    }
  }
export const RegShoHistoryDataSelector = (store: StoreState) =>
  store.Settlement.regShoAudit.regShoHistoryAuditData
export const actionSelector = (store: StoreState) =>
  store.Settlement.regShoAudit.action
export const loadingSelector = (store: StoreState) =>
  store.Settlement.regShoAudit.isLoading
export const TotalCountSelector = (store: StoreState) =>
  store.Settlement.regShoAudit.totalCount
  export const HttpErrorMessageSelector = (store: StoreState) =>
    store.Settlement.regShoAudit.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
    store.Settlement.regShoAudit.toastMessage
export default reducer
