/* eslint-disable @typescript-eslint/no-explicit-any */
import TabList from '../../../components/tab/TabList'
import { BuyInAllocationTabDetails } from '../RegSHOModels'

interface BuyInAllocationTabsProps {
  commonTabProps: {
    regSHOId: number
    source: string
    fromDate: string
    toDate: string
    dropdownCusip: string
    dropdownSymbols: string
    applySearchClick: boolean
    selectedSummaryTableCUSIP: string
    selectedCUSIPReportDate: string
    allocationType: string
  }
  setCommonTabProps: React.Dispatch<React.SetStateAction<any>>
}
const BuyInAllocationTabs = ({
  commonTabProps,
  setCommonTabProps,
}: BuyInAllocationTabsProps) => {
  const disableTab = commonTabProps.selectedSummaryTableCUSIP == ''
  return (
    <div style={{ margin: 'auto' }}>
      <TabList
        activeTabProp={
          BuyInAllocationTabDetails.find(
            (tab) => tab.label === commonTabProps.allocationType
          )?.tabIndex
        }
        tabs={BuyInAllocationTabDetails}
        commonTabProps={commonTabProps}
        setCommonTabProps={setCommonTabProps}
        isDisabled={disableTab}
        setDisabled={(label: string) => label.includes('execution')}
      />
    </div>
  )
}

export default BuyInAllocationTabs
