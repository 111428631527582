import ADRFeesUpload from './adrFeesUpload/ADRFeesUpload'
import ADRPastFileUpload from './adrFeesUpload/ADRPastFileUpload'
import DividendReconReportPage from './dividendReconReport/DividendReconReportPage'
import ReOrgAdmin from './reOrgAdmin/ReOrgAdmin'
import CountryCode from './reOrgAdmin/ReOrgAdmin'
import ReeOrgFeeForAcaps from './reeOrgFeeReeOrgForACAPS/ReeOrgFeeForACAPS'
import { RootContainer } from './styles'

export const ADRFeesUploadPage = () => {
  return (
    <RootContainer>
      <ADRFeesUpload />
    </RootContainer>
  )
}
export const ADRPastFileUploadPage = () => {
  return (
    <RootContainer>
      <ADRPastFileUpload />
    </RootContainer>
  )
}
export const DividendReconReport = () => {
  return (
    <RootContainer>
      <DividendReconReportPage />
    </RootContainer>
  )
}

export const ReeOrgFeeForACAPSPage = () => {
  return (
    <RootContainer>
      <ReeOrgFeeForAcaps />
    </RootContainer>
  )
}

export const ReOrgAdminPage = () => {
  return (
    <RootContainer>
      <ReOrgAdmin />
    </RootContainer>
  )
}
