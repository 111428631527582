import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
} from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { Dispatch } from 'react'
import { z } from 'zod'

export const formattingDate = (paramDate: string) => {
  let formattedDate = ''
  if (paramDate) {
    formattedDate = new Date(paramDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  }
  return formattedDate
}

export const formattingCurrencyWithDecimal = (amount: any) => {
  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)
  return formattedCurrency
}

export const formattingMonthYear = (paramDate: string) => {
  let formattedDate = ''
  if (paramDate) {
    formattedDate = new Date(paramDate).toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    })
  }
  return formattedDate
}

export const formattingMonthYearWithTime = (paramDate: Date) => {
  let formattedDate = ''
  if (paramDate) {
    formattedDate = new Date(paramDate).toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }
  return formattedDate
}

export const formattingCurrencyWithOutDecimal = (amount: any) => {
  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(amount)
  return formattedCurrency
}

export const formattingNumber = (amount: any) => {
  const optionFormat = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }
  const formattedNumber = Number(amount)?.toLocaleString('en-US', optionFormat)
  return formattedNumber
}

export const formattingNumberWithDecimal = (amount: any) => {
  const optionFormat = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 5,
  }
  const formattedNumber = Number(amount)?.toLocaleString('en-US', optionFormat)
  return formattedNumber
}

export const formattingDateMonthOnly = (paramDate: string) => {
  let formattedDate = ''
  if (paramDate) {
    formattedDate = new Date(paramDate).toLocaleDateString('en-US', {
      month: '2-digit',
    })
  }
  return formattedDate
}

export const formattingDateYearOnly = (paramDate: string) => {
  let formattedDate = ''
  if (paramDate) {
    formattedDate = new Date(paramDate).toLocaleDateString('en-US', {
      year: '2-digit',
    })
  }
  return formattedDate
}
export const formattingDateOnly = (paramDate: string) => {
  let formattedDate = ''
  if (paramDate) {
    formattedDate = new Date(paramDate).toLocaleDateString('en-US', {
      day: '2-digit',
    })
  }
  return formattedDate
}

export const getAccessFlag = (allowedEntitlements: string[]) => {
  let response = false
  const entitlements = localStorage.getItem('entitlements')
  const userEntitlements =
    entitlements && entitlements != 'undefined' && entitlements != null
      ? JSON.parse(entitlements.trim().toLowerCase())
      : []

  response = allowedEntitlements.some((allowedEnt) =>
    userEntitlements.some((userEnt: string) =>
      userEnt.includes(allowedEnt.trim().toLowerCase())
    )
  )
  return response
}

export const formattingDateWithTime = (paramDate: Date) => {
  let formattedDate = ''
  if (paramDate) {
    formattedDate = new Date(paramDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
  }
  return formattedDate
}
export const formattingWithTime = (paramDate: string) => {
  let formattedDate = ''
  if (paramDate) {
    formattedDate = new Date(paramDate).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
  }
  return formattedDate
}

export const renderError = (
  element: string,
  error?: z.ZodError[],
  key?: number
) => {
  if (error && error.length) {
    const elementValue = element.charAt(0).toLowerCase() + element.slice(1)
    const errorText = error.find((e: any) => {
      if (e.path[0] == key) {
        return e.path[1] == elementValue
      }
    })
    if (errorText) {
      return `*${errorText.message}`
    } else {
      return null
    }
  } else return null
}

export const handleHttpError = (
  dispatch: Dispatch<any>,
  e: AxiosError,
  sethttpErrorMessage:
    | ActionCreatorWithoutPayload<string>
    | ActionCreatorWithPayload<any, string>
) => {
  if (e.request?.status === 502) {
    return dispatch(
      sethttpErrorMessage(
        'Bad gateway, please try again later or contact prod support if issue persists.'
      )
    )
  }
  if (e.request?.status === 504) {
    return dispatch(
      sethttpErrorMessage(
        'The response from api took too long. Please try again later or contact prod support if issue persists.'
      )
    )
  }
  if (e.request?.status === 404) {
    return dispatch(
      sethttpErrorMessage(
        'Error! The requested API resource was not found.  Please try again later or contact Prod Support if the issue persists'
      )
    )
  }
  if (e.request?.status === 413) {
    return dispatch(sethttpErrorMessage('pls. upload email with size <200 KB'))
  }
  return dispatch(sethttpErrorMessage(e?.request?.statusText))
}

export const updateFilterArray = (
  existingArray: { id: string; value: string }[],
  value: string | string[] | undefined | null,
  id: string
) => {
  if (existingArray?.length > 0) {
    const existingItem = existingArray.find((obj: any) => {
      return obj && obj.id === id
    })
    if (existingItem) {
      return existingArray.map((i: any) => {
        return i && i.id === id ? { id: id, value: value } : i
      })
    } else {
      return [...existingArray, { id: id, value: value }]
    }
  } else {
    return [{ id: id, value: value }]
  }
}
