export const Colors = {
  Brand3: '#00245D',
  PrimaryText: '#2563EB',
  Primary10: '#A4C5E8',
  Primary20: '#6699D2',
  Primary50: '#1F5EB7',
  Primary60: '#3B82F6',
  Primary70: '#0847BE',
  Primary80: '#002698',
  Primary90: '#000833',
  Primary100: '#CFF2FE',
  Primary800: '#05388E',
  White: '#FFFFFF',
  OffWhite: '#e5e5e5',
  MilkyWhite: '#FCFDFF',
  Gray05: '#FAFBFC',
  Gray10: '#F0F2F5',
  Gray20: '#334155',
  Gray30: '#D0D7E2',
  Gray40: '#A7AFBC',
  Gray: '#475569',
  Gray50: ' #7B8698',
  Gray60: '#596579',
  Gray80: '#192638',
  Gray120: '#808080',
  Elevation01: '#00245D',
  Success2: '#22C373',
  Negative2: '#C72F24',
  BlueishBlack: '#181E27',
  Black: '#000000',
  Brand1: '#00A9CE',
  Gray70: '#374253',
  Blue9: '#01155F',
  GrayscaleBlack: '#181E27',
  Brand2: '#326295',
  Gray90: '#0F1825',
  Gray100: '#1E293B',
  Blue1: '#00245D', //name to be provided by UX team
  LightWhite: '#EEF3F5', //,/name to be provided by UX team
  NeutralText: '#0F172A',
  FaintGreen: '#9dbfaf',
  Warning: '#FB923C',
  Danger: ' #BA0517',
}

export const Backgrounds = {
  PrimaryBgStrongActive: '#1E40AF',
  Primary10: '#A4C5E8',
  PrimaryText: '#2563EB',
  Primary20: '#6699D2',
  Primary50: '#1F5EB7',
  Primary60: '#3B82F6',
  Primary70: '#0847BE',
  Primary80: '#002698',
  Primary90: '#000833',
  Primary100: '#CFF2FE',
  Primary800: '#05388E',
  Info100: '#D5FEFD',
  Info800: '#0F5490',
  Warning100: '#FEF9D4',
  White: '#FFFFFF',
  OffWhite3: 'EFF6FF',
  OffWhite1: '#f0f2f5',
  OffWhite: '#e5e5e5',
  OffWhite2: '#e3e3e3',
  Brand1: '#00A9CE',
  Brand2: '#326295',
  Brand3: '#00245D',
  Gray05: '#FAFBFC',
  Gray10: '#F0F2F5',
  Gray20: '#E6EAF0',
  Gray30: '#D0D7E2',
  MilkyWhite: '#FCFDFF',
  Gray40: '#A7AFBC',
  Gray50: '#cbd5e1',
  Gray60: '#596579',
  Gray70: '#374253',
  Gray80: '#F8FAFC',
  Gray90: '#A7AFBC4D',
  Gray110: '#E2E8F0',
  Gray150: '#313A46',
  Saffron: '#CF4242', //name to be provided by UX team
  Green: '#42CF8C', //name to be provided by UX team
  Accent: '#1F5EB7',
  Gray: '#EBF2FA', //name to be provided by UX team
  OffWhite4: '#EBF7E6',
  OffWhite5: '#FEF1EE',
  OffWhite6: '#FFF7ED',
  Success2: '#22C373',
  Success3: '#22683E',
  NeutralMedium: '#F1F5F9',
  Neutral: '#F8FAFC',
  Black: '#000000',
  Salmon: '#fa8072',
  OffGrey: '#F5F5F5',
}

export const BorderColors = {
  PrimaryBorderStrong: '#60A5FA',
  PrimaryText: '#2563EB',
  Primary5: '#bfdbfe',
  Primary10: '#A4C5E8',
  Primary20: '#6699D2',
  Primary30: '#00a9ce',
  Primary50: '#1F5EB7',
  Primary60: '#3B82F6',
  Primary70: '#0847BE',
  Primary80: '#002698',
  Primary90: '#000833',
  Primary100: '#CFF2FE',
  Primary800: '#05388E',
  Info100: '#D5FEFD',
  Info800: '#0F5490',
  Warning100: '#FEF9D4',
  White: '#FFFFFF',
  Brand1: '#00A9CE',
  Brand2: '#326295',
  Brand3: '#00245D',
  Gray05: '#FAFBFC',
  Gray10: '#F0F2F5',
  Gray20: '#E6EAF0',
  Gray30: '#D0D7E2',
  Gray40: '#A7AFBC',
  Gray50: '#cbd5e1',
  Gray60: '#596579',
  Gray70: '#374253',
  Saffron: '#CF4242', //name to be provided by UX team
  Danger: '#C72F24',
  DangerStrong: '#EA001E',
  Green: '#42CF8C', //name to be provided by UX team
  Accent: '#1F5EB7',
  Gray: '#EBF2FA', //name to be provided by UX team
  Success2: '#22C373',
  SuccessStrong: '#41B658',
  Salmon1: '#FE7765',
  Orange: '#FB923C',
  Gray110: '#E2E8F0',
}

export const BoxShadow = {
  Shadow1: 'rgba(0, 36, 93, 0.3)',
  Shadow2: 'rgba(0, 36, 93, 0.3)',
}

export const fonts = {
  SourceSansProBlack: './assets/fonts/SourceSansPro-Black.tff',
  SourceSansProBlackItalic: '',
  SourceSansProBold: '',
  SourceSansProBoldItalic: '',
  SourceSansProExtraLight: '',
  SourceSansProExtraLightItalic: '',
  SourceSansProLight: '',
  SourceSansProLightItalic: '',
  SourceSansProRegular: './assets/fonts/SourceSansPro-Regular.tff',
  SourceSansProSemiBold: '',
  SourceSansProSemiBoldItalic: '',
}

export const GraphColors =['#BFDBFE', '#60A5FA', '#2563EB', '#1E40AF', '#1E3A8A']

export const GraphBorderColors =['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']

export const LineGraphColors =['#1E3A8A', '#2E844A', '#EA580C', '#EA047E', '#00F5FF']
