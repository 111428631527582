/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from "@reduxjs/toolkit";
import { StoreState } from "../index";
import { authApiCall } from "../../utils/api"

interface State {
  contraCorrespondentManagement: any;
  isLoading: boolean;
  action: string;
}

let initialState: State = {
  contraCorrespondentManagement: {},
  isLoading: true,
  action: "",
};

const { actions, reducer }: Slice = createSlice({

  name: "trade-break",
  initialState,
  reducers: {
    setContraCorrespondentDetails: (state: State, action: PayloadAction<any>) => {
      state.contraCorrespondentManagement = action.payload.data;
    },

    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload;
    },
  },
});

export const {
  setAction,
  setContraCorrespondentDetails,
  setIsLoading,
} = actions;


export const getContraCorresondentDetails: any =(
  system:string,
  payload:any
  ) =>
    async (dispatch: Dispatch) => {
      dispatch(setIsLoading(true));
      try {
        const endPoint = system === 'Beta' ? 'BetaPieChartDetails' : system === 'P3' ? 'P3PieChartDetails' : ''
        const { data, status } = await authApiCall.post(
          `opsfailprocessapi/Dashboard/${endPoint}`,
           payload
          
        );

        if (status === 200) {
          dispatch(
            setContraCorrespondentDetails({
              data: data,
            })
          );
          dispatch(setIsLoading(false));
          return data;
        }
      } catch (e: any) {
        dispatch(
          setContraCorrespondentDetails({
            data: [],
          })
        );
        dispatch(setIsLoading(false));
      }
    };


export const ContraCorrespondentsSelector = (store: StoreState) =>
  store.ContraCorrespondents.ContraCorrespondent.contraCorrespondentManagement

export const actionSelector = (store: StoreState) =>
  store.ContraCorrespondents.ContraCorrespondent.action;

export const loadingSelector = (store: StoreState) =>
  store.ContraCorrespondents.ContraCorrespondent.isLoading;

export default reducer;
