import Divider from '../../../components/divider/Divider'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Table from '../../../components/table/Table'
import { Backgrounds } from '../../../shared/styles'
import { COLORS } from '../../../theme/Colors'
import {
  AuditTrailPopUpStyle,
  CancelButtonWrapper,
  CommentTableContainer,
  MarginLeftAutoSpace,
  StyledSelectContainer,
} from '../cusipLevelSummary/styles'

interface IAuditTrailL2L3 {
  setShowAudit: React.Dispatch<React.SetStateAction<boolean>>
  column: any
  data: any
}

const AuditTrailL2L3 = ({ setShowAudit, column, data }: IAuditTrailL2L3) => {
  return (
    <AuditTrailPopUpStyle>
      <StyledSelectContainer
        style={{
          padding: '15px 15px 0px 15px',
          fontWeight: '600',
          borderRadius: '8px 8px 0px 0px',
        }}
      >
        <>Audit Trail</>
        <MarginLeftAutoSpace>
          <CancelButtonWrapper
            onClick={() => {
              setShowAudit(false)
            }}
          >
            <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
          </CancelButtonWrapper>
        </MarginLeftAutoSpace>
        <Divider
          thickness={'1px'}
          horizontal={true}
          bgColor={Backgrounds.Gray110}
        />
      </StyledSelectContainer>
      <CommentTableContainer>
        <Table
          tableColumns={column}
          tableData={data}
          onClick={() => {
            //
          }}
        />
        {data.length == 0 && <h3>No Audit Trail</h3>}
      </CommentTableContainer>
    </AuditTrailPopUpStyle>
  )
}

export default AuditTrailL2L3
