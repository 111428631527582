import styled from 'styled-components'
import { Textbox } from '../../../components/textbox/Textbox'
import { COLORS } from '../../../theme/Colors'

export const RootContainer = styled.div`
  margin: -8px 0px 8px 0px;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* overflow-x: auto; */
`
export const StyledContainer = styled.div`
  /* width: 100%; */
  padding: 12px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`

export const StyledTableContainer = styled(StyledContainer)`
  width: 225vh;
  margin-top: 20px;
  margin-bottom: 10px;
`
export const StyledPanelHead = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 16px 24px 16px;
  border-radius: 8px 8px 0px 0px;
`
export const StyledPanelHeadTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  text-align: left;
  color: #0f172a;
`
export const PopUpStyleLoader = styled.div`
  background: #f0f2f5;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const LottieWrapper = styled.div`
  // position: fixed;
  // top: 70%;
  // left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const StyledSelectWrapper = styled.div`
  /* width: 100%; */
  padding: 12px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-left: auto;
`

export const StyledRightContainer = styled(StyledButtonWrapper)`
  justify-content: end;
`

export const StyledFilterPanel = styled.div`
  background: #ffffff;
  /* Elevation/Elevation-low */
  /* width: 100%; */
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const StyledTextbox = styled(Textbox)`
  && {
    // background-color: #a7afbc;
  }
`
export const PopUpSideBar = styled.div`
  background: #ffff;
  position: fixed;
  height: 100%;
  width: 30%;
  top: 50%;
  left: 85%;
  -webkit-transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  box-shadow: 0px 0px 150rem 150rem rgb(0 36 93 / 30%);
  border: 1px solid #e2e8f0;
  flex-flow: column;
  display: flex;
`
export const ButtonWrapper = styled.div`
  //margin-left: auto;
  cursor: pointer;
  display: flex;
  margin-top: 10px;
  align-items: center;
  flex-direction: row;
  align-items: center;
  background: ${COLORS.Background.Primary};
  > * {
    margin-right: 16px;
  }
`

export const DownloadWrapper = styled.div`
  //margin-left: auto;
  cursor: pointer;
  display: flex;
  margin-top: 10px;
  align-items: center;
  flex-direction: row;
  align-items: center;
  background: ${COLORS.Background.Primary};
  > * {
    margin-right: 16px;
  }
`
export const PopUpStyleContent = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 70%;
  // height: 60%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`
export const CancelButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`
export const ExcelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`
export const CommentTableContainer = styled.div`
  width: 100%;
  padding: 24px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  overflow-y: scroll;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`
export const MarginLeftAutoSpace = styled.div`
  margin-left: auto;
`
export const MailWrapper = styled.button`
 height: 100%;
 overflow-y: auto;
`