const appConfig = {
  envName: 'DEV',
  appName: 'Operation Hub',
  appDomain: 'https://operationshub.dev.wedbush.com/',
  apiEndPoint: 'https://dev.api.wedbush.com/',
  b2cDomain: 'https://wedbushclientsdev.b2clogin.com',
  b2cDomainMfa: 'https://wedbushclientsdev.b2clogin.com/wedbushclientsdev.onmicrosoft.com/B2C_1A_SUSI_ADFED',
  b2cClientId: 'c2d29007-3fdf-45f6-9d15-fb16f0dddd20',
  b2cDomainPwdReset: 'https://wedbushclientsdev.b2clogin.com/wedbushclientsdev.onmicrosoft.com/B2C_1A_RESET_OPERATIONSHUB',
  b2cDomainProfEdit: 'https://wedbushclientsdev.b2clogin.com/wedbushclientsdev.onmicrosoft.com/B2C_1A_EDITPROFILE_OPERATIONSHUB',
  b2cScope: 'https://wedbushclientsdev.onmicrosoft.com/3a0d6004-ab60-4056-b6eb-3d14aa33b105/api_access',
  b2cSecret: 'LUC8Q~KZgJAzoe5LXDZZWeKBF1VcJWmKKstg_cPs',
}

export default appConfig
