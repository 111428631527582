import {
  SummaryCardContainerTradeBreaksTrend,
  TableChartWidgetContainerByPhase3,
  TitleContainer
} from './styles'
import { useEffect, useState } from 'react'
import BarChart from '../../../bar/barChart';
import { formattingDate, formattingMonthYear, formattingMonthYearWithTime } from '../../../../utils/globalFunctions';
import { useSelector } from 'react-redux';
import { FisPhaseSelector } from '../../../../store/dash-board/static-data';


const Phase3 = (props: any) => {
  const phase3Data = useSelector(FisPhaseSelector)

  const [datas, setDatas] = useState<any>([]);
  const [legendLabels, setlegendLabels] = useState<any>([])
  const [startDate] = useState(new Date())
  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)
  let mm: string | number
  let firstDayOfMonth: string | number

  mm = startDate.getMonth()
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth

  useEffect(() => {

    const formatDataChart: {
      label: string,
      data: any,
      barThickness: number,
      backgroundColor: any,
      borderColor: any,
      borderWidth: any,
      stack: string,
    }[] = []
    let responseData = phase3Data
    responseData=responseData?.length && responseData?.slice().sort(function (a: any, b: any) {
      const c = new Date(a.date);
      const d = new Date(b.date);
      return c.valueOf() - d.valueOf();
    });
    console.log("formattedData", responseData)
    const legendLabelsTemp: string[] = []
    responseData?.length && responseData.map((m: { date: string; }) => legendLabelsTemp.push(formattingDate(m.date)))
    const bgColors = ['#BFDBFE', '#60A5FA', '#2563EB', '#1E40AF', '#1E3A8A']
    const borderColors = ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
    if (responseData && responseData.length) {
      formatDataChart.push(
        {
          label: 'Security Add',
          data: responseData.map((value: any) => value?.securityAdd),
          barThickness: 50,
          stack: "Stack 0",
          backgroundColor: bgColors[2],
          borderColor: borderColors[2],
          borderWidth: 0,
        },
        {
          label: 'Security Update',
          data: responseData.map((value: any) => value?.securityUpdate),
          barThickness: 50,
          stack: "Stack 0",
          backgroundColor: bgColors[1],
          borderColor: borderColors[1],
          borderWidth: 0,
        },
      )

    }
    setDatas(formatDataChart)
    setlegendLabels(legendLabelsTemp)


  }, [phase3Data]);

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        fullSize: true,
        align: 'start',
        labels: {
          color: '#0F172A',
          usePointStyle: true,
          pointStyle: "rectRounded",
          boxWidth: 24,
          boxHeight: 24
        }
      },
      datalabels: {
        display: true,
        color: "white",
        formatter: Math.round,
      },
    },
    responsive: true,
  }

  return (
    <>
      <TableChartWidgetContainerByPhase3>
        <TitleContainer>{props.widgetDescription}</TitleContainer>
        <SummaryCardContainerTradeBreaksTrend>
          <BarChart
            dataValues={datas}
            options={options}
            legendLabels={legendLabels}
          />
        </SummaryCardContainerTradeBreaksTrend>
        {<span style={{ fontSize: "10px" }}>Data as of : { formattingMonthYearWithTime(new Date())}</span>} 

      </TableChartWidgetContainerByPhase3>
    </>
  )
}

export default Phase3
