/* eslint-disable @typescript-eslint/no-explicit-any */
import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/button/Button'
import Divider from '../../../../components/divider/Divider'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import Table from '../../../../components/table/Table'
import { Textarea } from '../../../../components/textArea/Textarea'
import { Backgrounds, Colors } from '../../../../shared/styles'
import {
  AddComment,
  dataSelector,
  getComments,
  getP3StalePriceData,
  LoadingSelector,
  PageNumSelectorP3,
  sortDirectionSelectorP3,
  sortingSelectorP3,
  updateFlag,
  UpdateNote,
} from '../../../../store/staticData/stale-pricing-p3'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { COLORS } from '../../../../theme/Colors'

import {
  CancelButtonWrapper,
  CommentTableContainer,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopupCardComment,
  PopUpStyleContent,
  PopUpStyleLoader,
  StyledSelectWrapper,
  StyledTableContainer,
  PopupCardNote,
  RadioButtonWrapper,
  FlagPopUpWrapper,
  ButtonWrapper,
  FlagPopUpTitle,
} from '../styles'

import P3StalePricingTable from './p3StalePricingTable/P3StalePricingTable'
import { P3TableFilterList } from './p3TableFilter/P3TableFilterData'
import Label from '../../../../components/label/Label'
import { SvgVector } from '../../../../components/svg/SvgVector'
import { Radio } from '../../../../components/radio/radio'
import SvgFilter from '../../../../components/svg/SvgFilter'
import { getAccessFlag } from '../../../../utils/globalFunctions'

const P3StaleTable = (props: any) => {
  const dispatch = useDispatch()
  const data = useSelector(dataSelector)
  const [inputComment, setInputComment] = useState<any>([])
  const [addCommentPopup, setAddCommentsPopup] = useState<boolean>(false)
  const [flagPopUp, setFlagPopup] = useState<boolean>(false)
  const [flagedRow, setFlagRow] = useState<any>({
    currentRow: {},
    rowId: null,
    cusip: null,
    type: null,
    followUp_Flag: 0,
  })
  const [userComment, setUserComments] = useState<any>([])
  const PageNum = useSelector(PageNumSelectorP3)
  const sorting = useSelector(sortingSelectorP3)
  const sortDirection = useSelector(sortDirectionSelectorP3)
  const [openComments, setOpenComments] = useState<boolean>(false)
  const loading = useSelector(LoadingSelector)

  const [system, setSystem] = useState<string>('')
  const [input, setInput] = useState({
    commentRow: 0,
    commentDate: new Date().toISOString(),
  })
  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)
  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['SD-RWX', 'OM-RWX']))
  }, [])

  const securityDescription =
    P3TableFilterList[0]?.dropDownArray[0].value &&
    P3TableFilterList[0].selectedData[0]
      ? P3TableFilterList[0].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[0]?.dropDownArray[0].value &&
        !P3TableFilterList[0].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[0].selectedData.join(';').toString()

  let top_Category = ''
  if (
    P3TableFilterList[1].dropDownArray.filter((option) => option.value)
      .length == P3TableFilterList[1].dropDownArray.length
  ) {
    top_Category = ''
  } else {
    top_Category = P3TableFilterList[1].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  let sub_Category = ''
  if (
    P3TableFilterList[2].dropDownArray.filter((option) => option.value)
      .length == P3TableFilterList[2].dropDownArray.length
  ) {
    sub_Category = ''
  } else {
    sub_Category = P3TableFilterList[2].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  const reviewer_LatestComment =
    P3TableFilterList[3]?.dropDownArray[0].value &&
    P3TableFilterList[3].selectedData[0]
      ? P3TableFilterList[3].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[3]?.dropDownArray[0].value &&
        !P3TableFilterList[3].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[3].selectedData.join(';').toString()

  const security_Type_and_Sub_Type_Code =
    P3TableFilterList[4]?.dropDownArray[0].value &&
    P3TableFilterList[4].selectedData[0]
      ? P3TableFilterList[4].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[4]?.dropDownArray[0].value &&
        !P3TableFilterList[4].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[4].selectedData.join(';').toString()

  const lastPrice_In_Current_StaleReport =
    P3TableFilterList[5]?.dropDownArray[0].value &&
    P3TableFilterList[5].selectedData[0]
      ? P3TableFilterList[5].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[5]?.dropDownArray[0].value &&
        !P3TableFilterList[5].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[5].selectedData.join(';').toString()

  let followup_Flag = ''
  if (
    P3TableFilterList[6].dropDownArray.filter((option) => option.value)
      .length == P3TableFilterList[6].dropDownArray.length
  ) {
    followup_Flag = ''
  } else {
    followup_Flag = P3TableFilterList[6].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.labelValue)
      .join(';')
      .toString()
  }

  const cusip_Number =
    P3TableFilterList[7]?.dropDownArray[0].value &&
    P3TableFilterList[7].selectedData[0]
      ? P3TableFilterList[7].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[7]?.dropDownArray[0].value &&
        !P3TableFilterList[7].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[7].selectedData.join(';').toString()

  const isiN_NUMBER =
    P3TableFilterList[8]?.dropDownArray[0].value &&
    P3TableFilterList[8].selectedData[0]
      ? P3TableFilterList[8].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[8]?.dropDownArray[0].value &&
        !P3TableFilterList[8].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[8].selectedData.join(';').toString()

  const comments_Created_By =
    P3TableFilterList[9]?.dropDownArray[0].value &&
    P3TableFilterList[9].selectedData[0]
      ? P3TableFilterList[9].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[9]?.dropDownArray[0].value &&
        !P3TableFilterList[9].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[9].selectedData.join(';').toString()

  const reviewer_Last_Commented_Date =
    P3TableFilterList[10]?.dropDownArray[0].value &&
    P3TableFilterList[10].selectedData[0]
      ? P3TableFilterList[10].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[10]?.dropDownArray[0].value &&
        !P3TableFilterList[10].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[10].selectedData.join(';').toString()

  const icE_Last_Priced =
    P3TableFilterList[11]?.dropDownArray[0].value &&
    P3TableFilterList[11].selectedData[0]
      ? P3TableFilterList[11].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[11]?.dropDownArray[0].value &&
        !P3TableFilterList[11].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[11].selectedData.join(';').toString()

  const icE_Last_Price =
    P3TableFilterList[12]?.dropDownArray[0].value &&
    P3TableFilterList[12].selectedData[0]
      ? P3TableFilterList[12].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[12]?.dropDownArray[0].value &&
        !P3TableFilterList[12].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[12].selectedData.join(';').toString()

  const icE_Notes =
    P3TableFilterList[13]?.dropDownArray[0].value &&
    P3TableFilterList[13].selectedData[0]
      ? P3TableFilterList[13].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[13]?.dropDownArray[0].value &&
        !P3TableFilterList[13].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[13].selectedData.join(';').toString()

  const bbG_Last_Priced =
    P3TableFilterList[14]?.dropDownArray[0].value &&
    P3TableFilterList[14].selectedData[0]
      ? P3TableFilterList[14].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[14]?.dropDownArray[0].value &&
        !P3TableFilterList[14].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[14].selectedData.join(';').toString()

  const bbG_Last_Price =
    P3TableFilterList[15]?.dropDownArray[0].value &&
    P3TableFilterList[15].selectedData[0]
      ? P3TableFilterList[15].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[15]?.dropDownArray[0].value &&
        !P3TableFilterList[15].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[15].selectedData.join(';').toString()

  const boxLocation =
    P3TableFilterList[16]?.dropDownArray[0].value &&
    P3TableFilterList[16].selectedData[0]
      ? P3TableFilterList[16].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[16]?.dropDownArray[0].value &&
        !P3TableFilterList[16].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[16].selectedData.join(';').toString()

  const locationMemo =
    P3TableFilterList[17]?.dropDownArray[0].value &&
    P3TableFilterList[17].selectedData[0]
      ? P3TableFilterList[17].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[17]?.dropDownArray[0].value &&
        !P3TableFilterList[17].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[17].selectedData.join(';').toString()

  const boxDesc =
    P3TableFilterList[18]?.dropDownArray[0].value &&
    P3TableFilterList[18].selectedData[0]
      ? P3TableFilterList[18].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[18]?.dropDownArray[0].value &&
        !P3TableFilterList[18].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[18].selectedData.join(';').toString()

  const country =
    P3TableFilterList[19]?.dropDownArray[0].value &&
    P3TableFilterList[19].selectedData[0]
      ? P3TableFilterList[19].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[19]?.dropDownArray[0].value &&
        !P3TableFilterList[19].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[19].selectedData.join(';').toString()

  const securityTypeCode =
    P3TableFilterList[20]?.dropDownArray[0].value &&
    P3TableFilterList[20].selectedData[0]
      ? P3TableFilterList[20].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[20]?.dropDownArray[0].value &&
        !P3TableFilterList[20].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[20].selectedData.join(';').toString()

  const maturity_WarrantExpire_Date =
    P3TableFilterList[21]?.dropDownArray[0].value &&
    P3TableFilterList[21].selectedData[0]
      ? P3TableFilterList[21].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[21]?.dropDownArray[0].value &&
        !P3TableFilterList[21].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[21].selectedData.join(';').toString()

  const inDefault =
    P3TableFilterList[22]?.dropDownArray[0].value &&
    P3TableFilterList[22].selectedData[0]
      ? P3TableFilterList[22].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[22]?.dropDownArray[0].value &&
        !P3TableFilterList[22].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[22].selectedData.join(';').toString()

  const description2 =
    P3TableFilterList[23]?.dropDownArray[0].value &&
    P3TableFilterList[23].selectedData[0]
      ? P3TableFilterList[23].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[23]?.dropDownArray[0].value &&
        !P3TableFilterList[23].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[23].selectedData.join(';').toString()

  const description3 =
    P3TableFilterList[24]?.dropDownArray[0].value &&
    P3TableFilterList[24].selectedData[0]
      ? P3TableFilterList[24].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[24]?.dropDownArray[0].value &&
        !P3TableFilterList[24].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[24].selectedData.join(';').toString()

  const eodcmvdaT_LastPriced =
    P3TableFilterList[25]?.dropDownArray[0].value &&
    P3TableFilterList[25].selectedData[0]
      ? P3TableFilterList[25].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[25]?.dropDownArray[0].value &&
        !P3TableFilterList[25].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[25].selectedData.join(';').toString()

  const eodcmvdaT_LastPrice =
    P3TableFilterList[26]?.dropDownArray[0].value &&
    P3TableFilterList[26].selectedData[0]
      ? P3TableFilterList[26].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[26]?.dropDownArray[0].value &&
        !P3TableFilterList[26].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[26].selectedData.join(';').toString()

  const issue_Status_Code =
    P3TableFilterList[27]?.dropDownArray[0].value &&
    P3TableFilterList[27].selectedData[0]
      ? P3TableFilterList[27].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[27]?.dropDownArray[0].value &&
        !P3TableFilterList[27].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[27].selectedData.join(';').toString()

  const dtC_Premed_Elig =
    P3TableFilterList[28]?.dropDownArray[0].value &&
    P3TableFilterList[28].selectedData[0]
      ? P3TableFilterList[28].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[28]?.dropDownArray[0].value &&
        !P3TableFilterList[28].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[28].selectedData.join(';').toString()

  const maturityDate =
    P3TableFilterList[29]?.dropDownArray[0].value &&
    P3TableFilterList[29].selectedData[0]
      ? P3TableFilterList[29].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[29]?.dropDownArray[0].value &&
        !P3TableFilterList[29].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[29].selectedData.join(';').toString()

  const couponRate =
    P3TableFilterList[30]?.dropDownArray[0].value &&
    P3TableFilterList[30].selectedData[0]
      ? P3TableFilterList[30].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[30]?.dropDownArray[0].value &&
        !P3TableFilterList[30].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[30].selectedData.join(';').toString()

  const symbol =
    P3TableFilterList[31]?.dropDownArray[0].value &&
    P3TableFilterList[31].selectedData[0]
      ? P3TableFilterList[31].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[31]?.dropDownArray[0].value &&
        !P3TableFilterList[31].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[31].selectedData.join(';').toString()

  const currency =
    P3TableFilterList[32]?.dropDownArray[0].value &&
    P3TableFilterList[32].selectedData[0]
      ? P3TableFilterList[32].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[32]?.dropDownArray[0].value &&
        !P3TableFilterList[32].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[32].selectedData.join(';').toString()

  const lastPriceDate =
    P3TableFilterList[33]?.dropDownArray[0].value &&
    P3TableFilterList[33].selectedData[0]
      ? P3TableFilterList[33].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[33]?.dropDownArray[0].value &&
        !P3TableFilterList[33].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[33].selectedData.join(';').toString()

  const lastPrice =
    P3TableFilterList[34]?.dropDownArray[0].value &&
    P3TableFilterList[34].selectedData[0]
      ? P3TableFilterList[34].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[34]?.dropDownArray[0].value &&
        !P3TableFilterList[34].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[34].selectedData.join(';').toString()

  const cashQnty =
    P3TableFilterList[35]?.dropDownArray[0].value &&
    P3TableFilterList[35].selectedData[0]
      ? P3TableFilterList[35].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[35]?.dropDownArray[0].value &&
        !P3TableFilterList[35].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[35].selectedData.join(';').toString()

  const marginQnty =
    P3TableFilterList[36]?.dropDownArray[0].value &&
    P3TableFilterList[36].selectedData[0]
      ? P3TableFilterList[36].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[36]?.dropDownArray[0].value &&
        !P3TableFilterList[36].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[36].selectedData.join(';').toString()

  const inventory =
    P3TableFilterList[37]?.dropDownArray[0].value &&
    P3TableFilterList[37].selectedData[0]
      ? P3TableFilterList[37].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[37]?.dropDownArray[0].value &&
        !P3TableFilterList[37].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[37].selectedData.join(';').toString()

  const currently_Still_in_StaleReport =
    P3TableFilterList[38]?.dropDownArray[0].value &&
    P3TableFilterList[38].selectedData[0]
      ? P3TableFilterList[38].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[38]?.dropDownArray[0].value &&
        !P3TableFilterList[38].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[38].selectedData.join(';').toString()

  const last_Priced_In_Current_StaleReport =
    P3TableFilterList[39]?.dropDownArray[0].value &&
    P3TableFilterList[39].selectedData[0]
      ? P3TableFilterList[39].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[39]?.dropDownArray[0].value &&
        !P3TableFilterList[39].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[39].selectedData.join(';').toString()

  const commentFilter =
    P3TableFilterList[40]?.dropDownArray[0].value &&
    P3TableFilterList[40].selectedData[0]
      ? P3TableFilterList[40].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[40]?.dropDownArray[0].value &&
        !P3TableFilterList[40].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[40].selectedData.join(';').toString()

  const notes =
    P3TableFilterList[41]?.dropDownArray[0].value &&
    P3TableFilterList[41].selectedData[0]
      ? P3TableFilterList[41].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[41]?.dropDownArray[0].value &&
        !P3TableFilterList[41].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[41].selectedData.join(';').toString()

  const savedComments = async (tb: any, system: string) => {
    const response = await dispatch(
      getComments(
        system,
        data.data[tb].cusip_Number.replace(/'/g, ''),
        '',
        false,
        props.toDate
      )
    )
    setUserComments(response)
    await setOpenComments(true)
  }

  const toggleFlag = async (
    type: string,
    securityNumber: string,
    cusip: string,
    followup_Flag: boolean,
    currentRow: any,
    rowId: any
  ) => {
    setFlagPopup(true)

    setFlagRow({ currentRow, rowId, cusip, type, followup_Flag })
  }

  const updateFlagDetails = async () => {
    const { currentRow, rowId, cusip, type, followup_Flag } = flagedRow

    const tempRow = currentRow
    const tempTable = [...props.data]
    tempTable[rowId] = {
      ...tempRow,
      followup_Flag,
    }
    const response = await dispatch(
      updateFlag(type, '', cusip.replace(/'/g, ''), followup_Flag, {
        data: tempTable,
        totalCount: data?.totalCount,
      })
    )
    setFlagPopup(false)
  }

  const newUserComment = async (
    type: string,
    comment: string,
    rowId: number,
    Cusip: string,
    BoxLocation: any,
    commentedBy: any
  ) => {
    const response = await dispatch(
      AddComment(
        type,
        '',
        comment,
        Cusip.replace(/'/g, ''),
        BoxLocation ? BoxLocation.toString() : '',
        commentedBy,
        input.commentDate,
        props.toDate
      )
    )
    response == 200 &&
      type == 'P3' &&
      (await dispatch(
        getP3StalePriceData(
          'search',
          'P3',
          props.fromDate,
          props.toDate,
          PageNum,
          20,
          sorting,
          sortDirection,
          0,
          securityDescription,
          top_Category,
          sub_Category,
          reviewer_LatestComment,
          security_Type_and_Sub_Type_Code,
          lastPrice_In_Current_StaleReport,
          followup_Flag,
          cusip_Number,
          isiN_NUMBER,
          comments_Created_By,
          reviewer_Last_Commented_Date,
          icE_Last_Priced,
          icE_Last_Price,
          icE_Notes,
          bbG_Last_Priced,
          bbG_Last_Price,
          boxLocation,
          locationMemo,
          boxDesc,
          country,
          securityTypeCode,
          maturity_WarrantExpire_Date,
          inDefault,
          description2,
          description3,
          eodcmvdaT_LastPriced,
          eodcmvdaT_LastPrice,
          issue_Status_Code,
          dtC_Premed_Elig,
          maturityDate,
          couponRate,
          symbol,
          currency,
          lastPriceDate,
          lastPrice,
          cashQnty,
          marginQnty,
          inventory,
          currently_Still_in_StaleReport,
          last_Priced_In_Current_StaleReport,
          commentFilter,
          notes
        )
      ))
    inputComment[rowId] = '  '
    setInputComment({ ...inputComment })
  }

  const newUserNote = async (
    type: string,
    comment: string,
    rowId: number,
    Cusip: string,
    BoxLocation: any,
    commentedBy: any
  ) => {
    const response = await dispatch(
      UpdateNote(
        type,
        '',
        comment,
        Cusip.replace(/'/g, ''),
        commentedBy,
        input.commentDate
      )
    )
    response == 200 &&
      (await dispatch(
        getP3StalePriceData(
          'search',
          'P3',
          props.fromDate,
          props.toDate,
          PageNum,
          20,
          sorting,
          sortDirection,
          0,
          securityDescription,
          top_Category,
          sub_Category,
          reviewer_LatestComment,
          security_Type_and_Sub_Type_Code,
          lastPrice_In_Current_StaleReport,
          followup_Flag,
          cusip_Number,
          isiN_NUMBER,
          comments_Created_By,
          reviewer_Last_Commented_Date,
          icE_Last_Priced,
          icE_Last_Price,
          icE_Notes,
          bbG_Last_Priced,
          bbG_Last_Price,
          boxLocation,
          locationMemo,
          boxDesc,
          country,
          securityTypeCode,
          maturity_WarrantExpire_Date,
          inDefault,
          description2,
          description3,
          eodcmvdaT_LastPriced,
          eodcmvdaT_LastPrice,
          issue_Status_Code,
          dtC_Premed_Elig,
          maturityDate,
          couponRate,
          symbol,
          currency,
          lastPriceDate,
          lastPrice,
          cashQnty,
          marginQnty,
          inventory,
          currently_Still_in_StaleReport,
          last_Priced_In_Current_StaleReport,
          commentFilter,
          notes
        )
      ))
    inputComment[rowId] = '  '
    setInputComment({ ...inputComment })
  }
  const renderColors = (flag: number) => {
    switch (flag) {
      case 1:
        return 'red'
      case 2:
        return '#008000'
      case 3:
        return '#737373'
      default:
        return '#475569'
    }
  }
  const tableColumn = [
    {
      Header: 'Security',
      columns: [
        {
          Header: (
            <>
              Flag{' '}
              {followup_Flag.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'followup_Flag',
          width: '2%',
          Cell: (table: any) => {
            return (
              <>
                <Label
                  cursor="pointer"
                  onClick={() =>
                    toggleFlag(
                      'P3',
                      data.data[table.cell.row.id].securityNbr,
                      data.data[table.cell.row.id].cusip_Number,
                      table?.row?.original?.followup_Flag,
                      table?.row?.original,
                      table.cell.row.id
                    )
                  }
                >
                  <SvgVector
                    fillColor={
                      table?.row?.original?.followup_Flag
                        ? renderColors(
                            Number(table?.row?.original?.followup_Flag)
                          )
                        : '#475569'
                    }
                  />
                </Label>
              </>
            )
          },
        },
        {
          Header: (
            <>
              securityDescription{' '}
              {securityDescription.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'securityDescription',
        },
        {
          Header: (
            <>
              Cusip Number{' '}
              {cusip_Number.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          id: 'cusip_Number',
          accessor: (d: any) => {
            if (d.cusip_Number) return d.cusip_Number.replace(/'/g, '')
          },
        },
        {
          Header: (
            <>
              ISIN NUMBER{' '}
              {isiN_NUMBER.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'isiN_NUMBER',
        },
      ],
    },
    {
      Header: 'Category',
      columns: [
        {
          Header: (
            <>
              Top Category{' '}
              {top_Category.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'top_Category',
        },
        {
          Header: (
            <>
              Sub Category{' '}
              {sub_Category.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'sub_Category',
        },
      ],
    },
    {
      Header: 'Comments',
      columns: [
        {
          Header: (
            <>
              Reviewer LatestComment{' '}
              {reviewer_LatestComment.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'reviewer_LatestComment',
        },
        {
          Header: (
            <>
              Comments Created By{' '}
              {comments_Created_By.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'comments_Created_By',
        },
        {
          Header: (
            <>
              {'Reviewer Last Commented Date(EST)'}{' '}
              {reviewer_Last_Commented_Date.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'reviewer_Last_Commented_Date',
        },
        {
          Header: 'Add Comments',
          Cell: (table: any) => {
            return (
              <>
                <Button
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={'button'}
                  color={COLORS.Background.Primarytext}
                  style={{ whiteSpace: 'nowrap' }}
                  bgColor={'transparent'}
                  disabled={!writeExecuteFlag}
                  onClick={() => {
                    setSystem('P3')
                    setAddCommentsPopup(!addCommentPopup)
                    setInput({
                      ...input,
                      commentRow: table.cell.row.id,
                    })
                  }}
                >
                  Add Comment
                </Button>

                {system == 'P3' &&
                  addCommentPopup &&
                  table.cell.row.id === input.commentRow && (
                    <PopupCardComment>
                      <Textarea
                        onChange={(e: any) => {
                          inputComment[table.cell.row.id] = e.target.value
                          setInputComment(inputComment)
                        }}
                        height="365px"
                        placeholder={'Enter Comment'}
                      ></Textarea>

                      <StyledSelectWrapper
                        style={{
                          padding: '2px 0px 0px 0px',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          style={{
                            marginRight: '16px',
                          }}
                          padding={'4px 16px'}
                          borderRadius={'4px'}
                          type={'button'}
                          color={COLORS.Background.Primarytext}
                          bgColor={'transparent'}
                          onClick={() => {
                            setAddCommentsPopup(false)
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          hoverBgColor={COLORS.Background.Primary70}
                          bgColor={COLORS.Background.Primarytext}
                          color={COLORS.Background.Primary}
                          padding={'4px 16px'}
                          borderRadius={'4px'}
                          hover={true}
                          type={''}
                          activeBgColor={COLORS.Background.Primaryactive}
                          borderColor={'transparent'}
                          onClick={async () => {
                            await newUserComment(
                              'P3',
                              inputComment[table.cell.row.id],
                              table.cell.row.id,
                              data.data[table.cell.row.id].cusip_Number,
                              data.data[table.cell.row.id].boxLocation,
                              localStorage.getItem('AccountName')
                            )
                            setAddCommentsPopup(false)
                          }}
                        >
                          Submit
                        </Button>
                      </StyledSelectWrapper>
                    </PopupCardComment>
                  )}
              </>
            )
          },
        },
        {
          Header: 'All Comments',
          Cell: (table: any) => {
            return (
              <>
                <Button
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={'button'}
                  color={COLORS.Background.Primarytext}
                  style={{ whiteSpace: 'nowrap' }}
                  bgColor={'transparent'}
                  onClick={() => savedComments(table.cell.row.id, 'P3')}
                >
                  View
                </Button>
              </>
            )
          },
        },
        {
          Header: (
            <>
              View Notes {notes.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'notes',
          Cell: (table: any) => {
            return (
              <>
                <Label
                  hover={true}
                  cursor="pointer"
                  onClick={() => {
                    setSystem('P3_ISIN')
                    setAddCommentsPopup(!addCommentPopup)
                    inputComment[table.cell.row.id] =
                      table?.row?.original?.notes
                    setInputComment(inputComment)
                    setInput({
                      ...input,
                      commentRow: table.cell.row.id,
                    })
                  }}
                >
                  {table?.row?.original?.notes
                    ? table?.row?.original?.notes?.length > 25
                      ? `${table?.row?.original?.notes?.slice(0, 26)}...`
                      : table?.row?.original?.notes
                    : '-'}
                </Label>
                {system == 'P3_ISIN' &&
                  addCommentPopup &&
                  table.cell.row.id === input.commentRow && (
                    <PopupCardNote>
                      <Textarea
                        onChange={(e: any) => {
                          inputComment[table.cell.row.id] = e.target.value
                          setInputComment(inputComment)
                        }}
                        defaultValue={inputComment[table.cell.row.id]}
                        height="365px"
                        width="100px"
                        placeholder={'Enter Notes'}
                      ></Textarea>

                      <StyledSelectWrapper
                        style={{
                          padding: '2px 0px 0px 0px',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          style={{
                            marginRight: '16px',
                          }}
                          padding={'4px 16px'}
                          borderRadius={'4px'}
                          type={'button'}
                          color={COLORS.Background.Primarytext}
                          bgColor={'transparent'}
                          onClick={() => {
                            setAddCommentsPopup(false)
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          hoverBgColor={COLORS.Background.Primary70}
                          bgColor={COLORS.Background.Primarytext}
                          color={COLORS.Background.Primary}
                          padding={'4px 16px'}
                          borderRadius={'4px'}
                          hover={true}
                          type={''}
                          activeBgColor={COLORS.Background.Primaryactive}
                          borderColor={'transparent'}
                          onClick={async () => {
                            await newUserNote(
                              'P3',
                              inputComment[table.cell.row.id],
                              table.cell.row.id,
                              data.data[table.cell.row.id].cusip_Number,
                              data.data[table.cell.row.id].boxLocation,
                              localStorage.getItem('UserId')
                            )

                            setAddCommentsPopup(false)
                          }}
                        >
                          Submit
                        </Button>
                      </StyledSelectWrapper>
                    </PopupCardNote>
                  )}
              </>
            )
          },
        },
      ],
    },

    {
      Header: 'ICE',
      columns: [
        {
          Header: (
            <>
              ICE Last Priced{' '}
              {icE_Last_Priced.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'icE_Last_Priced',
        },
        {
          Header: (
            <>
              ICE Last Price{' '}
              {icE_Last_Price.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'icE_Last_Price',
        },
        {
          Header: (
            <>
              ICE Notes{' '}
              {icE_Notes.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'icE_Notes',
        },
      ],
    },
    {
      Header: 'Pricing Vendor',
      columns: [
        {
          Header: (
            <>
              BBG Last Priced{' '}
              {bbG_Last_Priced.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'bbG_Last_Priced',
        },
        {
          Header: (
            <>
              BBG Last Price{' '}
              {bbG_Last_Price.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'bbG_Last_Price',
        },
      ],
    },
    {
      Header: 'P3 Databases',
      columns: [
        {
          Header: (
            <>
              BoxLocation{' '}
              {boxLocation.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'boxLocation',
        },
        {
          Header: (
            <>
              LocationMemo{' '}
              {locationMemo.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'locationMemo',
        },
        {
          Header: (
            <>
              BoxDesc {boxDesc.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'boxDesc',
        },
        {
          Header: (
            <>
              Country {country.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'country',
        },
        {
          Header: (
            <>
              SecurityTypeCode{' '}
              {securityTypeCode.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'securityTypeCode',
        },
        {
          Header: (
            <>
              security Type and Sub Type Code{' '}
              {security_Type_and_Sub_Type_Code.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'security_Type_and_Sub_Type_Code',
        },
        {
          Header: (
            <>
              {' '}
              Expiration Date/Maturity Date Applicable
              {maturity_WarrantExpire_Date.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'maturity_WarrantExpire_Date',
        },
        {
          Header: (
            <>
              InDefault{' '}
              {inDefault.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'inDefault',
        },
        {
          Header: (
            <>
              Description2{' '}
              {description2.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'description2',
        },
        {
          Header: (
            <>
              Description3{' '}
              {description3.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'description3',
        },
        {
          Header: (
            <>
              EODCMVDAT LastPriced{' '}
              {eodcmvdaT_LastPriced.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'eodcmvdaT_LastPriced',
        },
        {
          Header: (
            <>
              EODCMVDAT LastPrice{' '}
              {eodcmvdaT_LastPrice.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'eodcmvdaT_LastPrice',
        },
        {
          Header: (
            <>
              Issue Status Code{' '}
              {issue_Status_Code.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'issue_Status_Code',
        },
      ],
    },
    {
      Header: 'DTC Data',
      columns: [
        {
          Header: (
            <>
              DTC Premed Elig{' '}
              {dtC_Premed_Elig.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'dtC_Premed_Elig',
        },
      ],
    },
    {
      Header: 'P3 RPT058 Report Data',
      columns: [
        {
          Header: (
            <>
              MaturityDate{' '}
              {maturityDate.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'maturityDate',
        },
        {
          Header: (
            <>
              CouponRate{' '}
              {couponRate.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'couponRate',
        },
        {
          Header: (
            <>Symbol {symbol.length > 0 && <SvgFilter fillColor={'grey'} />}</>
          ),
          accessor: 'symbol',
        },
        {
          Header: (
            <>
              Currency {currency.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'currency',
        },
        {
          Header: (
            <>
              Last Priced{' '}
              {lastPriceDate.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'lastPriceDate',
        },
        {
          Header: (
            <>
              LastPrice{' '}
              {lastPrice.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'lastPrice',
        },
        {
          Header: (
            <>
              Cash Acct Quantity{' '}
              {cashQnty.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'cashQnty',
        },
        {
          Header: (
            <>
              Margin Acct Quantity{' '}
              {marginQnty.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'marginQnty',
        },
        {
          Header: (
            <>
              Inventory Acct Quantity{' '}
              {inventory.length > 0 && <SvgFilter fillColor={'grey'} />}
            </>
          ),
          accessor: 'inventory',
        },
      ],
    },
    {
      Header: 'Current RPT058 Report',
      columns: [
        {
          Header: (
            <>
              Currently Still in StaleReport
              {currently_Still_in_StaleReport.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'currently_Still_In_StaleReport',
        },
        {
          Header: (
            <>
              Last Priced In Current StaleReport{' '}
              {last_Priced_In_Current_StaleReport.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'last_Priced_In_Current_StaleReport',
        },
        {
          Header: (
            <>
              LastPrice In Current StaleReport{' '}
              {lastPrice_In_Current_StaleReport.length > 0 && (
                <SvgFilter fillColor={'grey'} />
              )}
            </>
          ),
          accessor: 'lastPrice_In_Current_StaleReport',
        },
      ],
    },
  ]

  return (
    <StyledTableContainer>
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {flagPopUp && (
        <FlagPopUpWrapper>
          <FlagPopUpTitle>Choose the flag type</FlagPopUpTitle>
          <RadioButtonWrapper>
            <Radio
              label={
                <>
                  Un Flag
                  <SvgVector fillColor="" />
                </>
              }
              onChange={() => {
                setFlagRow({ ...flagedRow, followup_Flag: 0 })
              }}
              value={0}
              name={'radio'}
              checked={flagedRow.followup_Flag === 0 ? true : false}
            />
            <Radio
              label={
                <>
                  Follow Up
                  <SvgVector fillColor="red" />
                </>
              }
              onChange={() => {
                setFlagRow({ ...flagedRow, followup_Flag: 1 })
              }}
              value={1}
              name={'radio'}
              checked={flagedRow.followup_Flag === 1 ? true : false}
            />
            <Radio
              label={
                <>
                  Reviewed
                  <SvgVector fillColor="#008000" />
                </>
              }
              value={2}
              onChange={() => {
                setFlagRow({ ...flagedRow, followup_Flag: 2 })
              }}
              name={'radio'}
              checked={flagedRow.followup_Flag === 2 ? true : false}
            />
            <Radio
              label={
                <>
                  No further review required
                  <SvgVector fillColor="#737373" />
                </>
              }
              onChange={() => {
                setFlagRow({ ...flagedRow, followup_Flag: 3 })
              }}
              value={3}
              name={'radio'}
              checked={flagedRow.followup_Flag === 3 ? true : false}
            />
          </RadioButtonWrapper>
          <ButtonWrapper>
            <Button
              hoverBgColor={'#A7AFBC'}
              hoverTextColor={'#E2E8F0'}
              borderRadius={'4px'}
              hover={true}
              padding={'5px 10px'}
              bgColor={'#E2E8F0'}
              color={'#A7AFBC'}
              type={'Button'}
              margin={'0px 6px'}
              onClick={() => {
                setFlagPopup(false)
              }}
            >
              Cancel
            </Button>
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'5px 10px'}
              color={Colors.White}
              bgColor={Colors.PrimaryText}
              type={'Button'}
              fontWeight={'400'}
              onClick={() => {
                updateFlagDetails()
              }}
            >
              Save
            </Button>
          </ButtonWrapper>
        </FlagPopUpWrapper>
      )}
      {openComments && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>All Comments</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenComments(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'Cusip',
                  accessor: 'cusip',
                },
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
                {
                  Header: 'Created by',
                  accessor: 'createdBy',
                },
                {
                  Header: 'Create on(EST)',
                  accessor: 'createdOn',
                },
              ]}
              tableData={userComment}
              onClick={() => {
                //
              }}
            />
            {userComment.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      <P3StalePricingTable
        handleSubmit={props.handleSubmit}
        columns={tableColumn}
        fromDate={props.fromDate}
        toDate={props.toDate}
        data={props.data}
        totalRecords={props.totalRecords}
      />
    </StyledTableContainer>
  )
}
export default P3StaleTable
