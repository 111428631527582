/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { authApiCall } from '../../utils/api'
import { mockData, mockResponse } from './sample'
import { ConfigDetails } from '../../composites/ThresholdConfiguration/IconfigDetails'
// import  mockData  from "./sample.json";
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  isLoading: boolean
  action: string
  configDetails: []
  filterDetails: []
  widgetDetails: []
  addConfigDerail: []
  editConfigDetail: []
  deleteConfigDetail: []
  httpErrorMessage: string
  successMessage: string
}

let initialState: State = {
  isLoading: false,
  action: '',
  configDetails: [],
  filterDetails: [],
  widgetDetails: [],
  addConfigDerail: [],
  editConfigDetail: [],
  deleteConfigDetail: [],
  httpErrorMessage: '',
  successMessage: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'settlements',
  initialState,
  reducers: {
    setConfigDetailsReport: (state: State, action: PayloadAction<any>) => {
      state.configDetails = action.payload.data
    },
    setWidgetsDetailsReport: (state: State, action: PayloadAction<any>) => {
      state.widgetDetails = action.payload.data
    },
    setFilterDetailsReport: (state: State, action: PayloadAction<any>) => {
      state.filterDetails = action.payload.data
    },
    setAddConfigDetailsReport: (state: State, action: PayloadAction<any>) => {
      state.addConfigDerail = action.payload.data
    },
    setEditConfigDetailsReport: (state: State, action: PayloadAction<any>) => {
      state.editConfigDetail = action.payload.data
    },
    setDeleteConfigDetailsReport: (
      state: State,
      action: PayloadAction<any>
    ) => {
      state.deleteConfigDetail = action.payload.data
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },

    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  setAction,
  setIsLoading,
  setConfigDetailsReport,
  setFilterDetailsReport,
  setWidgetsDetailsReport,
  setDeleteConfigDetailsReport,
  setAddConfigDetailsReport,
  setEditConfigDetailsReport,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getConfigDetails: any =
  (payload: ConfigDetails) => async (dispatch: Dispatch) => {
    // return mockData
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/BuyInIntents/GetThresholdConfigDetails?widgetAttributeIds=${payload}`
      )

      if (status === 200) {
        dispatch(
          setConfigDetailsReport({
            data: data,
          })
        )

        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
  }
export const getWidgetDetails: any =
  (category: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsdashboardapi/OpsDashboard/ThresholdConfigWidgets',
        [category]
      )

      if (status === 200) {
        dispatch(
          setWidgetsDetailsReport({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
  }

export const getFilterDetails: any =
  (idValue: number) => async (dispatch: Dispatch) => {
    // return mockResponse;
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/BuyInIntents/GetThresholdDropDownDetails?widgetId=${idValue}`
      )

      if (status === 200) {
        dispatch(
          setFilterDetailsReport({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
  }

export const addConfigDetails: any =
  (details: ConfigDetails) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/BuyInIntents/AddThresholdConfigDetails`,
        details
      )
      if (status === 200) {
        dispatch(
          setFilterDetailsReport({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
    }
  }

export const editConfigDetails: any =
  (payload: ConfigDetails) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.put(
        'opsfailprocessapi/BuyInIntents/UpdateThresholdConfigDetails',
        payload
      )

      if (status === 200) {
        dispatch(
          setFilterDetailsReport({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
    }
  }

export const deleteConfigDetails: any =
  (idValue: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.delete(
        `opsfailprocessapi/BuyInIntents/DeleteThresholdConfigDetails?thresholdId=${idValue}`
      )

      if (status === 200) {
        dispatch(
          setFilterDetailsReport({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
    }
  }

export const ConfigDetailSelector = (store: StoreState) =>
  store.ThresholdConfiguration.ThresholdConfig.configDetails

export const AddConfigDetailSelector = (store: StoreState) =>
  store.ThresholdConfiguration.ThresholdConfig.addConfigDerail

export const EditConfigDetailSelector = (store: StoreState) =>
  store.ThresholdConfiguration.ThresholdConfig.editConfigDetail

export const DeleteConfigDetailSelector = (store: StoreState) =>
  store.ThresholdConfiguration.ThresholdConfig.deleteConfigDetail

export const FilterDetailSelector = (store: StoreState) =>
  // mockResponse;
  store.ThresholdConfiguration.ThresholdConfig.filterDetails

export const WidgetDetailSelector = (store: StoreState) =>
  store.ThresholdConfiguration.ThresholdConfig.widgetDetails

export const actionSelector = (store: StoreState) =>
  store.ThresholdConfiguration.ThresholdConfig.action

export const loadingSelector = (store: StoreState) =>
  store.ThresholdConfiguration.ThresholdConfig.isLoading

export const HttpErrorMessageSelector = (store: StoreState) =>
  store.ThresholdConfiguration.ThresholdConfig.httpErrorMessage

export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.ThresholdConfiguration.ThresholdConfig.successMessage

export default reducer
