/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from "react";
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledBreakTableContainer,
  PopUpStyleLoader,
  PopUpSideBar,
  IconsWrapper,
  LottieWrapper,
} from "../../styles";
import Label from "../../../../components/label/Label";
import Table from "../../table/Table";
import Lottie from 'lottie-react';
import Loader from "../../../../assets/lottiefiles/loader.json"
import {
  getTradeBreakData,
  getCategoryList,
  gettradeBreakEXCEL
} from "../../../../store/trade-break/trade-break";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonDetails from "../commonDetails";
import { Colors } from "../../../../shared/styles";
import Comments from "../../../../composites/comments/comments";
import { SvgDownloadSmall } from "../../../../components/svg/logo/SvgDownload";
import SvgRedo from "../../../../components/svg/SvgRedo";
import RenderColumns from "./tableColumns";

const TradeBreakManagement = () => {
  const commentView = useRef<HTMLInputElement>()
  const location = useLocation();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<any>([]);
  const [tradeData, setTradeData] = useState<any>();
  const [filterKey, setFilterKey] = useState<any>(null)
  const [orginalTableData, setOriginalTableData] = useState<any>([]);
  const [categoryDropdown, SetCategoryDropdown] = useState([]);
  const [topTableData, setTopTableData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [comment, setComment] = useState<string>('');
  const [approvalStatus, setApprovalStatus] = useState<string>('');

  const [checkFilter, setCheckFilter] = useState<any>({
    all: true,
    cancelled: false
  });

  const [checkFilterCount, setCheckFilterCount] = useState<any>({
    all: 0,
    cancelled: 0
  });
  const [toggleComments, SetToggleComments] = useState<boolean>(false);
  const [tableCommmentsData, setTableCommmentsData] = useState<any>([]);
  useState<any>("All rows updated");
  const navigate = useNavigate()

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (commentView.current && !commentView.current.contains(event.target)) {
        SetToggleComments(false);

      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [commentView]);

  const categoryOnchange = (e: any, row: number) => {
    const newSaveArr: any = [];
    tableData.forEach((item: any, id: any) => {
      let tempObjSave: any = {};
      if (row === id.toString()) {
        tempObjSave = Object.assign({}, item, { category: e.value });
      } else {
        tempObjSave = Object.assign({}, item);
      }
      newSaveArr.push(tempObjSave);
    });
    setTableData([...newSaveArr]);
  };



  const getTradeBreakDetails = async (params: any) => {
    if (params.passProps) {
      setApprovalStatus(params.passProps.approvalStatus)
      const users = await dispatch(getTradeBreakData(
        params.passProps.fromDate, params.passProps.toDate,
        params.passProps.settleDate?.split("T")[0], params.passProps.entityCode,
        params.passProps.cusip, params.passProps.symbol, params.passProps.assetType,
        params.passProps.mpid, params.passProps.breakType, params.passProps.buySell, params.passProps.forDate
      ));

      if (users && users.rpT882ViewModel) {
        let tempSave = [];
        const filterValue = filterKey === 'CXL' ? ['CXL'] : ['APR', 'REJ', null, 'NTF', 'NTF', 'F', 'NA']
        const tableArr = users.rpT882ViewModel.filter((element: any) => filterValue.includes(element.breakStatus)
        );
        tempSave = tableArr;
        setTableData(tempSave);
        setOriginalTableData(users.rpT882ViewModel);
        setCheckFilterCount({
          all: users.rpT882ViewModel.filter((element: any) => element.breakStatus !== 'CXL').length,
          cancelled: users.rpT882ViewModel.filter((element: any) => element.breakStatus === 'CXL').length
        })
      }
      if (users && users.tradeBreakSummary) {
        setTopTableData(users.tradeBreakSummary);
      }
    }
    else {
      const users = await dispatch(getTradeBreakData(
        new Date().toISOString().split("T")[0], new Date().toISOString().split("T")[0],
        null, null,
        null, null, null,
        null, null, null, null
      ));
    }
    setLoading(false)
  };

  const getCategoryData = async () => {
    const category = await dispatch(getCategoryList())
    const categoryArray = category && category.length && category.map((item: any) => ({
      value: item.categoryName,
      label: item.categoryName,
    }));
    SetCategoryDropdown(categoryArray);
  }

  useEffect(() => {
    if (location.state) {
      getTradeBreakDetails(location.state);
    }
    else {
      setLoading(false)
    }
    getCategoryData()
  }, []);



  const filterOnChange = (e: any) => {
    const { id, checked } = e.target;
    switch (id) {
      case "cancelled":
        {
          checkFilter.all = false;
          checkFilter.reject = false
          checkFilter.approve = false
          checkFilter[id] = checked;
          checkFilter.notAct = false
          setCheckFilter({ ...checkFilter });
          const tableCancelled = checked
            ? orginalTableData.filter(
              (obj: any) =>
                obj.breakStatus === "CXL"
            )
            : [];
          setFilterKey("CXL")

          setTableData(tableCancelled);
          break;
        }
      case "all":
        {
          checkFilter[id] = checked;
          checkFilter.cancelled = false
          setCheckFilter({ ...checkFilter });
          const tableAll = checked
            ? orginalTableData.filter(
              (obj: any) =>
                obj.breakStatus === null || obj.breakStatus !== "CXL"
            )
            : [];
          setFilterKey(null)
          if (checked) {
            setTableData(tableAll);
          } else {
            setTableData([]);
          }
          break;
        }
      default:
        break;
    }
  };

  const commentsOnChange = (e: any) => {
    const { value } = e.target
    setComment(value)
  };

  const refreashData = async () => {
    setLoading(true)
    await getTradeBreakDetails(location.state);
    setLoading(false);
  };

  const downloadExcel = async (params: any) => {
    setLoading(true)
    await dispatch(gettradeBreakEXCEL(
      `TradeBreakDetails_${params.passProps.fromDate}`,
      params.passProps.fromDate, params.passProps.toDate,
      params.passProps.settleDate?.split("T")[0], params.passProps.entityCode,
      params.passProps.cusip, params.passProps.symbol, params.passProps.assetType,
      params.passProps.mpid, params.passProps.breakType, params.passProps.buySell));
    setLoading(false)
  }

  const viewTradeFix = async (select_row: any) => {
    navigate('/tradeFix', {
      state: { passProps: select_row }
    });
  };

  return (
    <RootContainer>
      {loading && <PopUpStyleLoader>
        <LottieWrapper>
          <Lottie animationData={Loader} loop={true} />
        </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div>
      </PopUpStyleLoader>}
      <StyledPanelHead>
        <StyledPanelHeadTitle>Trade Break Details (RPT 882)</StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect id="panelHead">
        <CommonDetails topTableData={topTableData} />
        <div
          style={{
            width: "100%", display: "flex", justifyContent: "flex-end",
            alignContent: 'center', gap: '4px', marginTop: "10px"
          }}
        >
          <IconsWrapper onClick={() => refreashData()}>
            <SvgRedo
              fillColor={Colors.PrimaryText}
            ></SvgRedo><Label color={Colors.PrimaryText} >Refresh</Label>
          </IconsWrapper>
          <IconsWrapper onClick={() => downloadExcel(location.state)}>
            <SvgDownloadSmall
              fillColor={Colors.PrimaryText}
            ></SvgDownloadSmall><Label color={Colors.PrimaryText}>Download</Label>
          </IconsWrapper>
        </div>
        <StyledBreakTableContainer id="tableHead">
          <Table
            hidePagination={false}
            heading={`Details (Total breaks: ${orginalTableData.length})`}
            filterOnChange={filterOnChange}
            checkFilter={checkFilter}
            isFilter={false}
            data={tableData}
            columns={RenderColumns(approvalStatus,categoryOnchange,categoryDropdown)}
            checkFilterCount={checkFilterCount}
            showCancel={true}
          />
        </StyledBreakTableContainer>
      </StyledPanelSelect>
    
    </RootContainer>
  );
};

export default TradeBreakManagement;