import styled from 'styled-components'
import { BoxShadow } from '../../shared/styles'

export interface ToastProps {
  title?: string
  icon?: string
  image?: string
  text?: string
  Height?: string
  Width?: string
  type?: string
  span?: string
  openStatusCallback: (status: boolean) => void
}

const handleBgColor = (type: string) => {
  switch (type) {
    case 'success':
      return 'background:#EBF7E6; border:1px solid #41b658;'
    case 'danger':
      return 'background:#FEF1EE; border:1px solid #fe7765'
    case 'warning':
      return 'background:#FFF7ED; border:1px solid #fb923c'
    default:
      return 'background:#EFF6FF; border:1px solid #60a5fa'
  }
}
export const Master = styled.div<ToastProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Main = styled.div<ToastProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  animation: fadeOut ease 12s;
  padding: 8px 16px;
  width: 470px;
  ${(Props: { type: string }) => handleBgColor(Props.type)};
  gap: 8px;
  box-shadow: 0px 2px 6px -2px ${BoxShadow.Shadow1};
  border-radius: 4px;
`
export const ToastWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
export const ToastIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
export const ToastTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 400px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 8px;
`
export const ToastImage = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 15px;
  height: 15px;
  cursor: pointer;
`
export const ToastText = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: flex-start;
  margin-top: 8px;
`
