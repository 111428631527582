import { useEffect, useState } from "react";
import { FeatureDescription, FeatureTile, IconConatainer, SummaryCardContainerTradeReconcilation, TitleContainer, TradeReconInfoTile } from './styles'
import { authApiCall } from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import { configJSONDashboard } from "../../../utils/config";
import SvgDollor from "../../svg/SvgDollor";

const Settlement = () => {
  const navigate = useNavigate();

  const [cardTitle, setCardTitle] = useState<any>([]);

  useEffect(() => {
    const getAlerts = async () => {
      const api = configJSONDashboard.TradeBreaks.alerts.kpi

      try {
        const { data, status } = await authApiCall.get(
          api,
        );
        if (status === 200) {
          setCardTitle(data.alertsandNotificationViewModels);
        }
      } catch (e) {
        const error = e
      }
    };
    getAlerts();
  }, []);

  const addBreadCrumb = async (date: any) => {
    const breadCrumbArray = [
      {
        key: "Dashboard",
        link: "/settlementDashboard",
        active: false
      },
    ]
    sessionStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbArray));
    navigate("/settlementDashboard", {
      state: {
        passProps: {}
      },
    });
  }



  return (
    <SummaryCardContainerTradeReconcilation onClick={addBreadCrumb}>
      <TitleContainer>Street Settlements
      </TitleContainer>
      <IconConatainer>
        <SvgDollor fillColor={"#FFFFFF"} />
      </IconConatainer>
      <FeatureDescription>
        Manage fail analysis, tracking, clearance, and control processes
      </FeatureDescription>
    </SummaryCardContainerTradeReconcilation>
  )
}

export default Settlement
