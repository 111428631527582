import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js'

import { Bar } from 'react-chartjs-2'
import { BarChartProps } from './styles'
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);


const BarChart = ({
  legendLabels,
  options,
  dataValues,
  
}: BarChartProps) => {
  const data: ChartData<'bar'> = {
    labels: legendLabels,
    datasets: dataValues
  }
  return <Bar
    options={options}
    data={data}
  />
}

export default BarChart
