import {
  BuyInIntent1,
  LottieWrapper,
  RootContainerTable,
  TitleContainer
} from './styles'
import { useEffect, useState } from 'react'
import { authApiCall } from "../../../../utils/api";
import { configJSONDashboard } from '../../../../utils/config'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles';
import Loader from "../../../../assets/lottiefiles/loader.json";
import Lottie from 'lottie-react';
import Label from '../../../label/Label';
import CreateTable from '../createTable';
import { useDispatch, useSelector } from 'react-redux';
import { getRefinitiveBeta, RefinitiveBetaSelector } from '../../../../store/dash-board/static-data';
import { formattingMonthYear } from '../../../../utils/globalFunctions';


const BuyInIntents1 = (props: any) => {
  const dispatch = useDispatch()
  const data = useSelector(RefinitiveBetaSelector)

  const columns = [
    { Header: 'Month/Year', accessor: 'createdDate' },
    { Header: 'Manual Pricing', accessor: 'securityCount' },
  ]

  const [loading, isLoading] = useState<boolean>(true)


  const Table = (props: any) => {


    const formattedData = props.data && props.data.betaViewModel && props.data?.betaViewModel?.map((r: { createdDate: string; number: string; amount: number, quantity: number }) => {
      return {
        ...r, createdDate: r.createdDate ? formattingMonthYear(r.createdDate) : null
      }
    });

    return (
      <RootContainerTable>
        <CreateTable data={formattedData || []} columns={props.columns} />
      </RootContainerTable>
    )
  }

  useEffect(() => {
    const getBetaManual = async () => {
      await dispatch(getRefinitiveBeta())
    }
    isLoading(false)
    getBetaManual();
  }, [])
  return (
    <>
      {loading && <PopUpStyleLoader>
        <LottieWrapper>
          <Lottie animationData={Loader} loop={true} />
        </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div>
      </PopUpStyleLoader>}
      <BuyInIntent1>
        <TitleContainer>{props.widgetDescription}</TitleContainer>
      

      </BuyInIntent1>
    </>
  )
}


export default BuyInIntents1
