import styled from "styled-components";
import { COLORS } from "../../../theme/Colors";

export const SvgButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`

export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${COLORS.Background.Primary};

  > * {
    margin-right: 16px;
  }
`

export const MarginLeftAutoSpace = styled.div`
  margin-left: auto;
`