import { HeadingDownloadContainer, RootContainer } from './styles'
import { WDStyledHeading } from '../../../components/ui/WDTypography'
import Label from '../../../components/label/Label'
import ReportSearchPanel from './ReportSearchPanel'

const DividendReconReportPage = () => {
  return (
    <RootContainer>
      <HeadingDownloadContainer>
        <WDStyledHeading>
          <Label>
            {'Dividend open items reconciliation and tracking tool'}
          </Label>
        </WDStyledHeading>
      </HeadingDownloadContainer>
      <ReportSearchPanel></ReportSearchPanel>
    </RootContainer>
  )
}

export default DividendReconReportPage
