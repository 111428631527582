import {
  FeatureDescription,
  IconConatainer,
  SummaryCardContainerTradeReconcilation,
  TitleContainer,
} from './styles'
import { useNavigate } from 'react-router-dom'
import { SvgAccountsBig } from '../../svg/SvgAccounts'

const AssetServicing = () => {
  const navigate = useNavigate()

  const handleClickNavigation = () => {
    navigate('/ADR-Fees-Upload', {
      state: {
        passProps: {},
      },
    })
  }

  return (
    <SummaryCardContainerTradeReconcilation onClick={handleClickNavigation}>
      <TitleContainer>Asset Servicing</TitleContainer>
      <IconConatainer>
        <SvgAccountsBig fillColor={'#FFFFFF'} />
      </IconConatainer>
      <FeatureDescription>
        Manage corporate actions, income payments, and tax reporting processes
      </FeatureDescription>
    </SummaryCardContainerTradeReconcilation>
  )
}

export default AssetServicing
