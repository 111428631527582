import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledTableContainer,
  PopUpStyleLoader,
  LottieWrapper,
  StyledFilterPanel,
  StyledButtonWrapper,
  StyledSelectWrapper,
} from './styles'
import Table from './table/Table'
import { P3ContraCorresTestData } from './testData'
import { ContraCorresColumns } from './table/tableColumns'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import {
  ContraCorrespondentsSelector,
  getContraCorresondentDetails,
  loadingSelector,
} from '../../../store/contraCorrespondent/contracorrespondent'
import Loader from '../../../assets/lottiefiles/loader.json'
import Label from '../../../components/label/Label'
import { StyledPanelSelect } from '../buyInSettlements/styles'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import Button from '../../../components/button/Button'

const ContraCorrespondent = () => {
  const dispatch = useDispatch()
  const contraCorrespondentDetails = useSelector(ContraCorrespondentsSelector)
  const loading = useSelector(loadingSelector)
  const location = useLocation()
  const [tableData, setTableData] = useState<any>([])
  const [system, setSystem] = useState<any>('')
  const [type, setType] = useState<any>('')
  const [name, setName] = useState<any>('')
  const [product, setProduct] = useState<any>('')
  const [accountType, setAccountType] = useState<any>([])

  const productOption = [
    { value: 'EQ', label: 'EQ' },
    { value: 'FI', label: 'FI' },
    { value: 'All', label: 'All' },
  ]
  const systemOption = [
    { value: 'Beta', label: 'Beta' },
    { value: 'P3', label: 'P3' },
  ]
  const typeOption = [
    { value: 'Contra', label: 'Contra' },
    { value: 'Correspondent', label: 'Correspondent' },
  ]

  useEffect(() => {
    const TopContras = async () => {
      const {
        state: {
          name = '',
          type = '',
          system = '',
          accounts = [],
          product = '',
        },
      }: any = location
      setSystem(system)
      setType(type)
      setName(name)
      setProduct(product)
      if (accounts && accounts.length) {
        const accountArray: any = []
        accounts.forEach((element: string) => {
          accountArray.push({ value: element, label: element })
        })
        setAccountType(accountArray)
      }
      if (system && type) {
        await dispatch(
          getContraCorresondentDetails(system, { type, accountName: name })
        )
      }
    }
    TopContras()
  }, [])

  useEffect(() => {
    if (system && system === 'Beta') {
      setTableData(
        contraCorrespondentDetails?.betaContraOrCorrespondentDetailListModel
      )
    } else if (system && system === 'P3') {
      setTableData(
        contraCorrespondentDetails?.p3ContraOrCorrespondentDetailListModel
      )
    }
  }, [contraCorrespondentDetails])

  const handleSearch = async () => {
    if (system && type && name) {
      await dispatch(
        getContraCorresondentDetails(system, {
          type,
          accountName: name,
          productType: product,
        })
      )
    }
  }

  return (
    <RootContainer>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>{type} Details</StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledFilterPanel>
          <StyledSelectWrapper>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Product
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={productOption}
                onChange={(options: any) => {
                  setProduct(options)
                }}
                value={{ value: product, label: product }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Source
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={systemOption}
                onChange={(options: any) => {
                  setSystem(options)
                }}
                value={{ value: system, label: system }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Account Name
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="200px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={accountType}
                onChange={(options: any) => {
                  setName(options)
                }}
                value={{ value: name, label: name }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <StyledButtonWrapper>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
            </StyledButtonWrapper>
          </StyledSelectWrapper>
        </StyledFilterPanel>
        <StyledTableContainer>
          <Table
            hidePagination={false}
            showCancel={false}
            heading={`${type} Details`}
            data={tableData?.length ? tableData : []}
            columns={ContraCorresColumns.columns}
            totalCount={contraCorrespondentDetails?.totalFailCount}
          />
        </StyledTableContainer>
      </StyledPanelSelect>
    </RootContainer>
  )
}

export default ContraCorrespondent
