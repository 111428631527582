import { StyledLabel, InfoLabelProps, CloseBtn } from './styles'


export const InfoLabel = ({
  title,
  onCloseLabel
}: InfoLabelProps) => {

  return (
    <StyledLabel>
      {title}
      <CloseBtn onClick={onCloseLabel}>&times;</CloseBtn>
    </StyledLabel>
  )
}
