import React from 'react'


const TableCell: React.FC<any> = ({ row }) => {

  return row?.cells?.map((cell: any, i: any) => {
    switch (cell.column.Cell?.toString()) {
     
      default:
        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
    }
  })
}

export default TableCell
