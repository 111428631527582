/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */

import { useTable, useSortBy, usePagination } from 'react-table'
import {
  RootContainerTable,
  StyledTableHeader,
  PaginationContainer,
  GoToPageWrapper,
  NextPriviousWrapper,
  StyledNoData,
  TableHeader,
  TableContainer,
  StyledCheckBox,
} from './tableStyles'
import Label from "../../../../components/label/Label";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { Icon } from '../../../../components/icon/Icon';
import { Icons } from '../../../../shared/GlobalStyle';
import { useMemo } from 'react';
import { CardHeader } from '../../../../composites/cardHeader/CardHeader';
import { formattingNumber, formattingNumberWithDecimal } from '../../../../utils/globalFunctions';

const CreateTable = (props: any) => {
  const data = useMemo(() => props.data, [props.data])
  const columns = useMemo(() => props.columns, [props.columns])
  const isTradeDetails = props.showCancel //remove
  const tableInstance = useTable({ columns, data, autoResetSortBy: false, autoResetPage: false }, useSortBy, usePagination);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,

  } = tableInstance;

  const actionType = ['Action', 'Comments/Activity', 'Select', 'Category', 'View Details']

  const { pageIndex } = state
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page && page.length ? page.map((row, key) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={key}>
                {row && row.cells.map((cell) => {
                  const headerValue = cell.column.Header?.toString() || cell.column.id?.toString() || ''
                  const isActionType = actionType.includes(headerValue)
                  return <td {...cell.getCellProps()} >{((cell.value || isTradeDetails) || isActionType) ? cell.render('Cell') : "-"}</td>
                })}
              </tr>
            )
          }) : <tr><td colSpan={columns[0] && columns[0].columns ? columns[0].columns.length : columns?.length}><StyledNoData>No Data Found</StyledNoData></td></tr>}
        </tbody>
      </table>
      {page && page.length ? (
        <PaginationContainer>
          <Label>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </Label>
          <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
          <GoToPageWrapper>
            <Label>Go to page:</Label>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              max={pageOptions.length}
              min={1}
              onChange={(e) => {
                e.stopPropagation()
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(pageNumber)
              }}
              style={{ width: '50px' }}
            />
          </GoToPageWrapper>
          <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
          <NextPriviousWrapper>
            <Icon icon={Icons.BackwardFirst} onClick={() => gotoPage(0)}></Icon>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              style={{ border: 'none', background: '#FCFDFF' }}
            >
              Previous
            </button>

            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={{ border: 'none', background: '#FCFDFF' }}
            >
              Next
            </button>
            <Icon
              icon={Icons.ForwardLast}
              onClick={() => gotoPage(pageCount - 1)}
            ></Icon>
          </NextPriviousWrapper>
        </PaginationContainer>
      ) : null}
    </>

  )
}

const Table = (props: any) => {
  return (
    <RootContainerTable>
      <TableHeader>
      <CardHeader
          label={props.heading}
          icon={false}
          amountValue={`Total FailCount: ${props?.totalCount ? formattingNumberWithDecimal(props?.totalCount) : 0}`}
        />
      </TableHeader>

      {props.isFilter ? <StyledTableHeader>

        <StyledCheckBox>

          <Checkbox
            checkBoxId="notAct"
            buttonCheckbox={true}
            onChange={props.filterOnChange}
            title={`NA (Needs Attention) (${props.checkFilterCount.notAct} breaks)`}
            checked={props.checkFilter.notAct}
          />
        </StyledCheckBox>
        <StyledCheckBox>

          <Checkbox
            checkBoxId="approve"
            buttonCheckbox={true}
            onChange={props.filterOnChange}
            title={`Approved (${props.checkFilterCount.approve} breaks)`}
            checked={props.checkFilter.approve}
          />
        </StyledCheckBox>
        <StyledCheckBox>

          <Checkbox
            checkBoxId="reject"
            buttonCheckbox={true}
            onChange={props.filterOnChange}
            title={`Rejected (${props.checkFilterCount.reject} breaks)`}
            checked={props.checkFilter.reject}
          />
        </StyledCheckBox>
      </StyledTableHeader>
        : null}

      {props.showCancel ?
        <StyledTableHeader>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="all"
              title={`Valid (${props.checkFilterCount.all} breaks)`}
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              checked={props.checkFilter.all}
            />
          </StyledCheckBox>
          <StyledCheckBox>

            <Checkbox
              checkBoxId="cancelled"
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              title={`Cancelled (${props.checkFilterCount.cancelled} breaks)`}
              checked={props.checkFilter.cancelled}
            />
          </StyledCheckBox>
        </StyledTableHeader> : null}
      <TableContainer>
        <CreateTable data={props.data} columns={props.columns} hidePagination={props.hidePagination}
          showCancel={props.showCancel}
        />
      </TableContainer>
    </RootContainerTable>
  )
}

export default Table