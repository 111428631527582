import {
  BaseSyntheticEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import Button from '../../../../../components/button/Button'
import Divider from '../../../../../components/divider/Divider'
import Label from '../../../../../components/label/Label'
import { Textbox } from '../../../../../components/textbox/Textbox'
import { WDButtonTransparent } from '../../../../../components/ui/WDButton'
import { Icons } from '../../../../../shared/GlobalStyle'
import { COLORS } from '../../../../../theme/Colors'
import { LeftSpace } from '../../styles'
import { ClearAllProps } from './IP3TableFilter'
import {
  AddButtonStyleWrapper,
  ButtonStyleWidth,
  CloseButtonStyleWrapper,
  ColumnCheckboxWrapper,
  DefaultFilterWrapper,
  DropDownWrapper,
  FilterDropDownWrapper,
  LabelCheckboxWrapper,
  LabelWrapper,
  MainFilterContainer,
  MoreFilterButtonWrapper,
  MoreFiltersWrapper,
  SaveCancelButtonWrapper,
  SavedDataWrapper,
  TextAreaCancelIconWrapper,
  TextAreaStyleWrapper,
} from '../../betaStalePricing/tableFilter/styles'
import { P3TableFilterList } from './P3TableFilterData'
import Image from '../../../../../components/image/Image'
import { SvgPlus } from '../../../../../components/svg/SvgPlus'
import SvgClose from '../../../../../components/svg/SvgClose'
import { WDTextbox } from '../../../../../components/ui/WDTextbox'

const P3TableFilter = ({ reference, checkResetStatus }: ClearAllProps) => {
  const [toggleFilter, setToggleFilter] = useState<boolean>(false)
  const [toggle, setToggle] = useState<boolean>(false)
  const [toggleWindow, setToggleWindow] = useState(false)
  const [toggleMoreFilterWindow, setToggleMoreFilterWindow] = useState(false)

  const wrapperRef = useRef(null)

  const validateColumn = (name: string) => {
    if (
      name !== 'Security Description' &&
      name !== 'Top Category' &&
      name !== 'Sub Category' &&
      name !== 'Reviewer Latest Comment' &&
      name !== 'Security Type and Sub Type Code' &&
      name !== 'Last price in Current Stale Report' &&
      name !== 'Flag'
    ) {
      return true
    } else {
      return false
    }
  }

  const checkIfAnyValueSelectedInDropdownFilters = () => {
    let selectionStatus = false
    P3TableFilterList.forEach((filter, index) => {
      if (filter.dropDownArray.length) {
        filter.dropDownArray.forEach((dropdown, index) => {
          if (dropdown.value) {
            selectionStatus = true
          }
        })
      }
      if (filter.addData.length > 0 && filter.addData[0] !== '') {
        selectionStatus = true
      }
      if (filter.value && index > 4) {
        selectionStatus = true
      }
    })
    checkResetStatus(selectionStatus)
  }

  const handleChange = (tableindex: number, event: BaseSyntheticEvent) => {
    const checked = (event.target as HTMLInputElement).checked

    if (checked) {
      P3TableFilterList[tableindex].value = true
      checkResetStatus(true)
    } else {
      P3TableFilterList[tableindex].value = false
      checkIfAnyValueSelectedInDropdownFilters()
    }
    setToggle(!toggle)
  }

  const toggleDropdown = (tableindex: number) => {
    P3TableFilterList[tableindex].showDropdown =
      !P3TableFilterList[tableindex].showDropdown
    P3TableFilterList.forEach((filter, index) => {
      if (tableindex != index) {
        filter.showDropdown = false
      }
    })
    setToggle(!toggle)
  }
  const saveData = (tableIndex: number, source: string) => {
    if (source !== 'fromCancel')
      P3TableFilterList[tableIndex].selectedData =
        P3TableFilterList[tableIndex].addData
    else {
      P3TableFilterList[tableIndex].addData = ['']
      P3TableFilterList[tableIndex].selectedData = ['']
      if (
        P3TableFilterList[tableIndex].dropDownArray.filter(
          (data) => data.label == 'Blank'
        ).length > 0 &&
        P3TableFilterList[tableIndex].Header !== 'Top Category' &&
        P3TableFilterList[tableIndex].Header !== 'Sub Category' &&
        P3TableFilterList[tableIndex].Header !== 'Flag'
      ) {
        P3TableFilterList[tableIndex].dropDownArray.forEach((data) => {
          if (data.label == 'Blank') {
            data.value = false
          }
        })
      }
      checkIfAnyValueSelectedInDropdownFilters()
    }
    P3TableFilterList[tableIndex].showDropdown = false
    setToggle(!toggle)
  }
  const addTextArea = (index: number) => {
    P3TableFilterList[index].addData.push('')
    setToggle(!toggle)
  }

  const setUpdateData = (value: string, index: number, tableIndex: number) => {
    P3TableFilterList[tableIndex].addData[index] = value
    setToggle(!toggle)
  }
  const delTextArea = (index: number, tableIndex: number) => {
    P3TableFilterList[tableIndex].addData.splice(index, 1)
    setToggle(!toggle)
  }

  const handleOptions = (
    tableIndex: number,
    optionIndex: number,
    event: BaseSyntheticEvent
  ) => {
    const checked = (event.target as HTMLInputElement).checked
    if (checked) {
      P3TableFilterList[tableIndex].dropDownArray[optionIndex].value = true
      checkResetStatus(true)
    } else {
      P3TableFilterList[tableIndex].dropDownArray[optionIndex].value = false
      checkIfAnyValueSelectedInDropdownFilters()
    }
    setToggle(!toggle)
  }

  const useOutsideAlerter = (ref: RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          if (!reference?.current?.contains(event.target as Node)) {
            setToggleWindow(false)
            setToggleMoreFilterWindow(false)
            setToggleFilter(false)
            P3TableFilterList.forEach((filter, index) => {
              filter.showDropdown = false
            })
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  useOutsideAlerter(wrapperRef)

  const closeDropDownToggle = () => {
    setToggleWindow(true)
  }

  const closeMoreFilterDropDownToggle = () => {
    setToggleMoreFilterWindow(true)
    setToggleWindow(false)
    P3TableFilterList.forEach((filter, index) => {
      filter.showDropdown = false
    })
  }

  const verifyChecked = (index: number) => {
    let flag = true
    if (
      P3TableFilterList[index].dropDownArray.filter((option) => option.value)
        .length == P3TableFilterList[index].dropDownArray.length
    ) {
      flag = true
    } else flag = false
    return flag
  }

  const selectAll = (index: number, event: BaseSyntheticEvent) => {
    const checked = (event.target as HTMLInputElement).checked
    if (checked) {
      P3TableFilterList[index].dropDownArray.forEach((option) => {
        option.value = true
      })
    } else {
      P3TableFilterList[index].dropDownArray.forEach((option) => {
        option.value = false
      })
      checkResetStatus(true)
    }
    setToggle(!toggle)
  }

  return (
    <MainFilterContainer ref={wrapperRef}>
      <DefaultFilterWrapper>
        {P3TableFilterList.map((column, index) => {
          return (
            column.value && (
              <DefaultFilterWrapper key={index}>
                <>
                  <Button
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    type={'button'}
                    color={COLORS.Text.Neutral}
                    borderColor={COLORS.Background.Border}
                    bgColor={'transparent'}
                    onClick={() => {
                      toggleDropdown(index)
                      setToggleFilter(false)
                      closeDropDownToggle()
                    }}
                  >
                    <Label>{column.Header}</Label>
                    <Image
                      image={
                        column.showDropdown && toggleWindow
                          ? Icons.ChevronUp
                          : Icons.ChevronDown
                      }
                      alt="Chevron Down"
                    />
                  </Button>
                  {column.showDropdown && toggleWindow && (
                    <DropDownWrapper>
                      {column.Header !== 'Top Category' &&
                        column.Header !== 'Sub Category' &&
                        column.Header !== 'Flag' && (
                          <>
                            {column.addData.map((option, i) => {
                              return (
                                <TextAreaCancelIconWrapper key={i}>
                                  {column.Header == 'Maturity Date' ||
                                  column.Header ==
                                    'Expiration Date/Maturity Date Applicable' ||
                                  column.Header == 'Last Priced' ||
                                  column.Header == 'EODCMVDAT Last Priced' ||
                                  column.Header == 'BBG Last Priced' ||
                                  column.Header == 'ICE Last Priced' ||
                                  column.Header ==
                                    'Last priced in Current Stale Report' ||
                                  column.Header ==
                                    'Reviewer Last Commented Date(EST)' ? (
                                    <TextAreaStyleWrapper>
                                      <WDTextbox>
                                        <Textbox
                                          value={option}
                                          placeholder="MM/DD/YYYY"
                                          onChange={(e) => {
                                            setUpdateData(e, i, index)
                                            checkIfAnyValueSelectedInDropdownFilters()
                                          }}
                                          style={{
                                            borderRadius: '4px',
                                            height: '32px',
                                            padding: '4px 8px',
                                          }}
                                        />
                                      </WDTextbox>
                                    </TextAreaStyleWrapper>
                                  ) : (
                                    <TextAreaStyleWrapper>
                                      <WDTextbox>
                                        <Textbox
                                          value={option}
                                          onChange={(e) => {
                                            setUpdateData(e, i, index)
                                            checkIfAnyValueSelectedInDropdownFilters()
                                          }}
                                          style={{
                                            borderRadius: '4px',
                                            height: '32px',
                                            padding: '4px 8px',
                                          }}
                                        />
                                      </WDTextbox>
                                    </TextAreaStyleWrapper>
                                  )}
                                  {column.addData.length > 1 && (
                                    <CloseButtonStyleWrapper>
                                      <WDButtonTransparent>
                                        <Button
                                          type={'button'}
                                          color={''}
                                          onClick={() => {
                                            delTextArea(i, index)
                                          }}
                                          subSuffixIcon={
                                            <SvgClose fillColor=""></SvgClose>
                                          }
                                        ></Button>
                                      </WDButtonTransparent>
                                    </CloseButtonStyleWrapper>
                                  )}
                                </TextAreaCancelIconWrapper>
                              )
                            })}
                            {column.dropDownArray &&
                              column.dropDownArray.length > 0 &&
                              column.dropDownArray.map((option, i) => {
                                return (
                                  <ColumnCheckboxWrapper key={i}>
                                    <WDButtonTransparent>
                                      <input
                                        type="checkbox"
                                        checked={option.value}
                                        onChange={(event) => {
                                          handleOptions(index, i, event)
                                        }}
                                      ></input>
                                      <Button type="button" color={''}>
                                        <LabelWrapper>
                                          {option.label}
                                        </LabelWrapper>
                                      </Button>
                                    </WDButtonTransparent>
                                  </ColumnCheckboxWrapper>
                                )
                              })}
                            <AddButtonStyleWrapper>
                              <WDButtonTransparent>
                                <Button
                                  type={'button'}
                                  color={COLORS.Background.Primarytext}
                                  onClick={() => {
                                    addTextArea(index)
                                  }}
                                  subPrefixedIcon={
                                    <SvgPlus
                                      fillColor={COLORS.Background.Primarytext}
                                    ></SvgPlus>
                                  }
                                >
                                  Add
                                </Button>
                              </WDButtonTransparent>
                            </AddButtonStyleWrapper>
                            <SavedDataWrapper>
                              {column.selectedData.map((option, i) => {
                                return <Label key={i}>{option}</Label>
                              })}
                            </SavedDataWrapper>
                            <SaveCancelButtonWrapper>
                              <Button
                                hoverBgColor={COLORS.Background.Primary70}
                                padding={'4px 16px'}
                                borderRadius={'4px'}
                                type={'button'}
                                color={COLORS.Background.Primarytext}
                                bgColor={'transparent'}
                                onClick={() => {
                                  saveData(index, 'fromCancel')
                                  checkIfAnyValueSelectedInDropdownFilters()
                                }}
                              >
                                Clear All
                              </Button>
                              <Button
                                hoverBgColor={COLORS.Background.Primary70}
                                bgColor={COLORS.Background.Primarytext}
                                color={COLORS.Background.Primary}
                                padding={'4px 16px'}
                                borderRadius={'4px'}
                                hover={true}
                                type={''}
                                activeBgColor={COLORS.Background.Primaryactive}
                                borderColor={'transparent'}
                                onClick={() => {
                                  saveData(index, 'fromSave')
                                }}
                              >
                                Save
                              </Button>
                            </SaveCancelButtonWrapper>
                          </>
                        )}
                      {(column.Header == 'Top Category' ||
                        column.Header == 'Sub Category' ||
                        column.Header == 'Flag') && (
                        <ColumnCheckboxWrapper>
                          <WDButtonTransparent>
                            <input
                              type="checkbox"
                              checked={verifyChecked(index)}
                              onChange={(event) => {
                                selectAll(index, event)
                              }}
                            ></input>
                            <Button type="button" color={''}>
                              <LabelWrapper>Select All</LabelWrapper>
                            </Button>
                          </WDButtonTransparent>
                        </ColumnCheckboxWrapper>
                      )}
                      {column.dropDownArray &&
                        (column.Header == 'Top Category' ||
                          column.Header == 'Sub Category' ||
                          column.Header == 'Flag') &&
                        column.dropDownArray.map((option, i) => {
                          return (
                            <ColumnCheckboxWrapper key={i}>
                              <WDButtonTransparent>
                                <input
                                  type="checkbox"
                                  checked={option.value}
                                  onChange={(event) => {
                                    handleOptions(index, i, event)
                                  }}
                                ></input>
                                <Button type="button" color={''}>
                                  <LabelWrapper>{option.label}</LabelWrapper>
                                </Button>
                              </WDButtonTransparent>
                            </ColumnCheckboxWrapper>
                          )
                        })}
                    </DropDownWrapper>
                  )}
                </>
              </DefaultFilterWrapper>
            )
          )
        })}
      </DefaultFilterWrapper>
      <FilterDropDownWrapper>
        <MoreFilterButtonWrapper>
          <ButtonStyleWidth>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Text.Neutral}
              borderColor={COLORS.Background.Border}
              bgColor={'transparent'}
              onClick={() => {
                setToggleFilter(!toggleFilter)
                closeMoreFilterDropDownToggle()
              }}
              subSuffixIcon={
                <SvgPlus fillColor={COLORS.Background.NeutralIcons}></SvgPlus>
              }
            >
              More Filter
            </Button>
          </ButtonStyleWidth>
          {toggleFilter && toggleMoreFilterWindow && (
            <MoreFiltersWrapper>
              {P3TableFilterList.map((column, index) => {
                return (
                  <ColumnCheckboxWrapper key={index}>
                    {validateColumn(column.Header) && (
                      <>
                        <LabelCheckboxWrapper>
                          <input
                            type="checkbox"
                            checked={column.value}
                            onChange={(event) => {
                              handleChange(index, event)
                            }}
                          ></input>
                          <LabelWrapper>{column.Header}</LabelWrapper>
                        </LabelCheckboxWrapper>
                        {P3TableFilterList.length - 1 !=
                          P3TableFilterList.indexOf(column) && (
                          <Divider
                            thickness={'1px'}
                            bgColor={COLORS.Background.StrongBackgound}
                            horizontal={true}
                          />
                        )}
                      </>
                    )}
                  </ColumnCheckboxWrapper>
                )
              })}
            </MoreFiltersWrapper>
          )}
        </MoreFilterButtonWrapper>
      </FilterDropDownWrapper>
    </MainFilterContainer>
  )
}

export default P3TableFilter
