import {
  FeatureDescription,
  IconConatainer,
  SummaryCardContainerTradeReconcilation,
  TitleContainer,
} from './styles'
import { useNavigate } from 'react-router-dom'
import { SvgBooksBig } from '../../svg/SvgBooks'

const RegulatoryOperations = () => {
  const navigate = useNavigate()

  const handleClickNavigation = () => {
    navigate('/CaisCatsDashoard', {
      state: {
        passProps: {},
      },
    })
  }

  return (
    <SummaryCardContainerTradeReconcilation onClick={handleClickNavigation}>
      <TitleContainer>Regulatory Operations</TitleContainer>
      <IconConatainer>
        <SvgBooksBig fillColor={'#FFFFFF'} />
      </IconConatainer>
      <FeatureDescription>
        Manage trade regulatory reporting and governance processes
      </FeatureDescription>
    </SummaryCardContainerTradeReconcilation>
  )
}

export default RegulatoryOperations
