import styled from 'styled-components'
import { BorderColors, Colors } from '../../shared/styles'

interface InputFunc {
  (value: string): void | string | boolean | number
}

export interface DatePickerProps {
  borderColor: string
  bgColor: string
  placeHolder: string
  padding: string
  onChange: InputFunc
  maxWidth: string
  max?: any
  min?: any
  defaultValue?: any
  value?: any
  name?:string
  disabled?:boolean
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 4px;
  max-width: ${(props: DatePickerProps) => props.maxWidth};
`
export const StyledInput = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: ${(props: DatePickerProps) => props.padding};
  outline: none;
  border-radius: 8px;
  background: #ffffff;
  mix-blend-mode: normal;

  border: 1px solid #cbd5e1;
  border-radius: 8px;

  align-self: stretch;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  color: #475569;
  &:disabled {
    border: 1px solid ${BorderColors.Gray50};
    background: #e2e8f0;
    color: ${Colors.Gray};
    cursor: no-drop;
  }
`
