import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const RootContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`
export const RootContainerTable = styled.div`
  table {
    width: 100% !important;
    border: 1px solid black;
    border-collapse: collapse;
    th {
      font-size: 18px;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      cursor: default;
      line-height: 20px;
      color: #0000ff;
      background-color: #dae2ef;
      padding: 4px 4px;
      border: none;

      /* border-right: 1px solid ${COLORS.UI.Gray}; */
      /* border-bottom: 1px solid ${COLORS.UI.Gray}; */
      /* &:active {
        box-shadow: inset 0 3px 0 0 rgb(0 0 0 / 60%);
      } */
    }

    tr {
      td {
        padding: 4px;
        color: #ffffff;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        background-color: red;
        box-shadow: inset 0px -1px 0px ${COLORS.UI.Gray};
        :first-child {
          background-color: green;
        }
        :last-child {
          color: black;
          background-color: white;
        }
      }
    }
  }
`
export const HeadingExcelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const CommentHeaderSub1 = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`
export const CommentHeaderSub2 = styled.div`
  margin-top: 70px;
  margin-left: 20px;
  width: 500px;
  display: flex;
  flex-direction: column;
`
export const CommentHeader1 = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`
export const CommentBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

export const ButtonWrapper = styled.button`
  display: flex;
  margin-left: auto;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`
export const SecurityDescriptionWrapper = styled.div`
  margin-top: 20px;
`

export const TableRowContainer = styled.tr``

export const TableData = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 15px;
  width: 100px;
`
export const MainDataContainer = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
`
export const FirstHalf = styled.div`
  width: 35%;
`
export const SecondHalf = styled.div`
  margin-left: 5%;
  width: 100%;
`
export const SecondTopHalf = styled.div`
  // width: 40%;
`
export const FirstBottomHalf = styled.div`
  margin-top: 20px;
`
export const DataTable = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
`
export const BoldTableData = styled.td`
  padding: 15px;
  border: 1px solid black;
  border-collapse: collapse;
  font-weight: bold;
  width: 150px;
`
export const AllocationDataWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`
export const LabelTextboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 1%;
`
export const LabelWrapper = styled.div`
  width: 25%;
`
export const TextboxWrapper = styled.div`
  // width: 30%;
`

export const CommentLabel = styled.div`
  margin-top: 20px;
`
export const MarginLeftAutoSpace = styled.div`
  float: right;
`
export const ViewSubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
`
export const PopUpStyleContent = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  // height: 60%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`
export const CancelButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`
export const CommentTableContainer = styled.div`
  width: 100%;
  padding: 24px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  overflow-y: scroll;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`
export const DatePickerWrapper = styled.div`
  // width: 30%;
`
export const EmailButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const AuditTrailButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;
  margin-top: 20px;
`
export const AuditTrailPopUpStyle = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  max-height: 80%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`
export const StyledSelectContainer = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${COLORS.Background.Primary};

  > * {
    margin-right: 16px;
  }
`
