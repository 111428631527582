
import ThresholdConfig from "../../../composites/ThresholdConfiguration/ThresholdConfig"
import {  RootContainerPage } from "./styles"

export const SettlementConfiguration = () => {
    return (
      <RootContainerPage>
        <ThresholdConfig  Department={"Settlement"}/>
      </RootContainerPage>
    )
  }
