import { SvgButtonWrapper, MarginLeftAutoSpace ,StyledSelectWrapper } from "./styles";
import { Backgrounds } from "../../../shared/styles";
import { COLORS } from "../../../theme/Colors";
import Button from "../../button/Button";
import Divider from "../../divider/Divider";
import Label from "../../label/Label";
import SvgCancel from "../../svg/logo/SvgCancel";
import { SvgExclamation } from "../../svg/logo/SvgExclamation";

 interface Props {
    title: string;
    message: string;
    onCancel: ()=>void;
    onDelete: ()=>void;
    ButtonLabel?:string;
 }


 const ConfirmDeletePopUp = ({title,message,onCancel,onDelete,ButtonLabel}:Props) =>{
  
return(
    <>
    <StyledSelectWrapper
    style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
  >
    <SvgButtonWrapper>
      <SvgExclamation
        fillColor={COLORS.Icons.DangerIcon}
      ></SvgExclamation>
    </SvgButtonWrapper>
    {title}
    <MarginLeftAutoSpace>
      <SvgButtonWrapper
        onClick={() => {
            onCancel()
        }}
      >
        <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
      </SvgButtonWrapper>
    </MarginLeftAutoSpace>
  </StyledSelectWrapper>
  <Divider
    thickness={'1px'}
    horizontal={true}
    bgColor={Backgrounds.Gray110}
  />
  <StyledSelectWrapper style={{ padding: 15 }}>
    <>
      {message}
    </>
  </StyledSelectWrapper>
  <StyledSelectWrapper
    style={{
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 20,
    }}
  >
    <Button
      padding={'4px 16px'}
      borderRadius={'4px'}
      type={'button'}
      color={COLORS.Background.Primarytext}
      bgColor={'transparent'}
      onClick={() => {
        onCancel()
      }}
    >
      <Label fontWeight={600}> Cancel</Label>
    </Button>
    <Button
      hoverBgColor={COLORS.Background.Primary70}
      bgColor={COLORS.Background.Primarytext}
      color={COLORS.Background.Primary}
      padding={'4px 16px'}
      borderRadius={'4px'}
      hover={true}
      type={''}
      activeBgColor={COLORS.Background.Primaryactive}
      borderColor={'transparent'}
      onClick={() => {
        onDelete()
      }}
    >
      {ButtonLabel ?? 'Delete'}
    </Button>
  </StyledSelectWrapper>
  </>
)

 }
  export default ConfirmDeletePopUp