import styled from 'styled-components'
import { Colors } from '../../shared/styles'

export const CommentRootContainer=styled.div`
overflow-y: auto;
/* overflow-x: hidden; */
`
export const CommentHeader=styled.div`
width: 95%;
margin: auto;
display: flex;
border-bottom: 1px solid #E2E8F0;
box-sizing: border-box;
width: 100%;
padding: 20px;
left: 0;
padding: 56px 16px 16px 16px;

`

export const StyledSortContainer=styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-left: auto;
float: right;

`

export const CommentTile=styled.div`
border: 1px solid #E2E8F0;
border-radius: 16px;
display: flex;
flex-flow: row wrap;
`

export const DataContainer=styled.div`
width:100%;
overflow-y: auto;
position: relative;
display: flex;
flex-flow: column nowrap;
gap: 10px;
padding-top: 10px;
padding-right: 5px;
`

export const AvatarStyle=styled.div`
display: flex;
align-items: center;
justify-content: center;
font-size: 0.75rem;
padding: 0.5rem;
height: 1.75rem;
width: 1.75rem;
border-radius: 2rem;
background-color: ${Colors.PrimaryText};
color: ${Colors.White};
cursor: default;
`
export const CommentContent=styled.div`
width: 100%;
padding :10px;
display: flex;
gap: 10px;
flex-flow: row wrap;
`

export const EditorContainer=styled.div`
    width: 90%;
    margin: 12px;
`

export const ReactQuillStyles=styled.div`
    width: 100%;
   .ql-toolbar.ql-snow{
    display: none;
   }
   
    >div >div {border:none!important;}
`

