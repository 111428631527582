import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/button/Button'
import DatePicker from '../../../../components/datePicker/DatePicker'
import Label from '../../../../components/label/Label'
import Lottie from 'lottie-react'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  formattingCurrencyWithDecimal,
  formattingDate,
  formattingNumber,
} from '../../../../utils/globalFunctions'
import { OptionProps } from '../../../../components/selectDropdown/styles'
import {
  getEntityType,
  getTradeBreak,
  tradeManageSelector,
  loadingSelector,
  entityCodeSelector,
  getEntityCode,
  HttpSuccessMessageSelector,
  HttpErrorMessageSelector,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../../store/Equity-break/Equity-break'
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledSelectWrapper,
  StyledTableContainer,
  PopUpStyleLoader,
  StyledFilterPanel,
  StyledButtonWrapper,
  LottieWrapper,
  ToastWrapper,
} from '../../styles'
import Table from '../../table/Table'
import { SvgEye } from '../../../../components/svg/SvgEye'
import SelectDropdownWithCheckBox from '../../../../components/selectDropdownWithCheckbox/SelectDropdown'
import { EClocationValueModel, tradeBreaksByECModel } from '../ItradeBreaksByEC'
import { EntityCodeModel } from '../../ItradeManagement'
import { TableInstance } from 'react-table'
import { Toast } from '../../../../components/toast/Toast'

interface InputModel {
  startDate?: string | null
  endDate?: string | null
  mpid?: string | null | undefined
  symbol?: null
  cusip?: string
}

const IntraDayManagementByEC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const tradeData = useSelector(tradeManageSelector)
  const entityCode = useSelector(entityCodeSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)

  const location = useLocation()
  const loading = useSelector(loadingSelector)
  const [input, setInput] = useState<InputModel>({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
    mpid: '',
    symbol: null,
    cusip: '',
  })
  const [entityType, setentityType] = useState<any>([])

  const entityOption: OptionProps[] = []

  const today = new Date()
  const priorDate = new Date().setDate(today.getDate() - 30)

  useEffect(() => {
    const paramValues: any = location.state
    dispatch(getEntityCode())
    dispatch(getEntityType(input.startDate, input.endDate, null, null))
    dispatch(
      getTradeBreak(
        'search',
        paramValues && paramValues.passProps
          ? paramValues.passProps.fromDate
          : input.startDate,
        paramValues && paramValues.passProps
          ? paramValues.passProps.toDate
          : input.endDate,
        null,
        paramValues && paramValues.passProps
          ? paramValues.passProps.entityCode
          : entityType.value && entityType.value.length > 0
          ? entityType.value
          : null,
        paramValues && paramValues.passProps
          ? paramValues.passProps.entityCode
          : entityType.value && entityType.value.length > 0
          ? entityType.label
          : null,
        null,
        null,
        input.mpid ? input.mpid : null,
        null
      )
    )
    if (paramValues && paramValues.passProps) {
      setInput({
        ...input,
        startDate: paramValues.passProps.fromDate,
        endDate: paramValues.passProps.toDate,
      })
    }
  }, [])

  useEffect(() => {
    const paramValues: any = location.state
    if (
      paramValues?.passProps &&
      paramValues.passProps.entityCode &&
      paramValues.passProps.entityCode.length
    ) {
      const tempFilter = entityCode.find(
        (element: EntityCodeModel) => paramValues?.passProps.entityCode
      )
      const temp = [
        {
          value: paramValues.passProps.entityCode,
          label:
            entityCode && entityCode.length
              ? `${paramValues.passProps.entityCode}-${tempFilter?.firmName}`
              : paramValues.passProps.entityCode,
        },
      ]
      setentityType(temp)
    }
  }, [entityCode])

  const editItem = (e: TableInstance) => {
    return (
      <span
        role="presentation"
        title="View"
        style={{ cursor: 'pointer', width: '25px' }}
        onClick={() => {
          const passProps = {
            fromDate: input.startDate,
            toDate: input.endDate,
            ...e.data[e.cell.row.id],
            entityCode:
              e.data[e.cell.row.id].entityCode == ''
                ? null
                : e.data[e.cell.row.id].entityCode,
          }
          const data = sessionStorage.getItem('breadCrumbData')
          if (data) {
            const dataArray = JSON.parse(data)
            dataArray[dataArray.length - 1].active = false
            dataArray[dataArray.length - 1].passProps = passProps
            dataArray.push({
              key: 'TradeBreaksByMPID',
              link: '/tradePageMPID',
              active: true,
              passProps,
            })
            sessionStorage.setItem('breadCrumbData', JSON.stringify(dataArray))
          }
          navigate('/tradePageMPID', {
            state: {
              passProps,
            },
          })
        }}
      >
        <SvgEye fillColor="#475569" />
      </span>
    )
  }

  entityCode &&
    entityCode.forEach((element: EntityCodeModel) => {
      entityOption.push({
        value: element?.entityCode,
        label: `${element?.entityCode}-${element?.firmName}`,
      })
    })

  const handleSearch = async () => {
    const entityCodeArray: string[] = []
    if (entityType && entityType.length > 0) {
      entityType.forEach((element: OptionProps) => {
        entityCodeArray.push(element.value)
      })
    }
    await dispatch(
      getTradeBreak(
        'search',
        input.startDate,
        input.endDate,
        null,
        entityCodeArray && entityCodeArray.length > 0
          ? entityCodeArray.toString()
          : null,
        entityCodeArray && entityCodeArray.length > 0
          ? entityCodeArray.toString()
          : null,
        null,
        input.cusip,
        input.symbol,
        input.mpid ? input.mpid : null,
        null
      )
    )
  }

  return (
    <RootContainer>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>Trade Breaks By Entity Code</StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledFilterPanel>
          <StyledSelectWrapper>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                From Date
              </Label>
              <DatePicker
                padding={'4px 8px'}
                onChange={(e: string) => {
                  setInput({ ...input, startDate: e })
                }}
                value={input.startDate}
                max={new Date().toISOString().split('T')[0]}
                min={new Date(priorDate).toISOString().split('T')[0]}
                bgColor=""
                borderColor=""
                placeHolder="From"
                maxWidth="295px"
                defaultValue={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                To Date
              </Label>
              <DatePicker
                onChange={(e: string) => {
                  setInput({ ...input, endDate: e })
                }}
                value={input.endDate}
                max={new Date().toISOString().split('T')[0]}
                min={input.startDate}
                bgColor=""
                borderColor=""
                placeHolder="From"
                padding={'4px 8px'}
                maxWidth="295px"
                defaultValue={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Entity Code
              </Label>
              <SelectDropdownWithCheckBox
                height="32px"
                isMulti={true}
                buttonWidth="400px"
                padding="4px 16px"
                borderRadius="4px"
                isSearchable={true}
                fw={100}
                lh="24px"
                fs="16px"
                options={entityOption}
                optionValues={entityOption}
                onChange={(options: OptionProps[]) => {
                  setentityType(options)
                }}
                allowSelectAll={true}
                value={entityType}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
                whiteSpace={'nowrap'}
                overflow={'hidden'}
                flexWrap={'nowrap'}
                labelValue={'EntityCodes'}
              />{' '}
            </div>
            <StyledButtonWrapper>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
            </StyledButtonWrapper>
          </StyledSelectWrapper>
        </StyledFilterPanel>
        <StyledTableContainer>
          {tradeData && tradeData.tradeBreakViewModels ? (
            <Table
              hidePagination={false}
              heading={'Trade Breaks By Entity Code'}
              data={
                tradeData && tradeData.tradeBreakViewModels
                  ? tradeData.tradeBreakViewModels
                  : []
              }
              columns={[
                {
                  Header: ' ',
                  columns: [
                    {
                      Header: 'Trade Date',
                      accessor: (d: tradeBreaksByECModel) => {
                        return d.tradeDate && formattingDate(d.tradeDate)
                      },
                    },
                    {
                      Header: 'Settle Date',
                      accessor: (d: tradeBreaksByECModel) => {
                        return d.settleDate && formattingDate(d.settleDate)
                      },
                    },
                    {
                      Header: 'EC',
                      accessor: 'entityCode',
                    },
                    {
                      Header: 'MPID',
                      accessor: 'mpid',
                    },
                    {
                      Header: 'Description',
                      accessor: 'name',
                    },
                    {
                      Header: 'Trade Quantity',
                      accessor: (d: tradeBreaksByECModel) => {
                        return d.houseQuantity
                          ? formattingNumber(d.houseQuantity)
                          : null
                      },
                    },
                    {
                      Header: 'Street Quantity',
                      accessor: (d: tradeBreaksByECModel) => {
                        return d.streetQuantity
                          ? formattingNumber(d.streetQuantity)
                          : null
                      },
                    },
                    {
                      Header: 'Break Count',
                      accessor: (d: tradeBreaksByECModel) => {
                        return d.breakCount
                          ? formattingNumber(d.breakCount)
                          : null
                      },
                    },
                    {
                      Header: 'Trade Amount',
                      accessor: (d: tradeBreaksByECModel) => {
                        if (d.houseAmount) {
                          return formattingCurrencyWithDecimal(d.houseAmount)
                        }
                      },
                    },
                    {
                      Header: 'Street Amount',
                      accessor: (d: tradeBreaksByECModel) => {
                        if (d.streetAmount) {
                          return formattingCurrencyWithDecimal(d.streetAmount)
                        }
                      },
                    },
                    {
                      Header: 'NetBreak Quantity',
                      accessor: (d: tradeBreaksByECModel) => {
                        if (d.netBreakQuantity) {
                          return formattingNumber(d.netBreakQuantity)
                        }
                      },
                    },
                    {
                      Header: 'NetBreak Amount',
                      accessor: (d: tradeBreaksByECModel) => {
                        if (d.netBreakAmount)
                          return formattingCurrencyWithDecimal(d.netBreakAmount)
                      },
                    },
                    {
                      Header: 'View Details',
                      Cell: editItem,
                    },
                  ],
                },
              ]}
            />
          ) : null}
        </StyledTableContainer>
      </StyledPanelSelect>
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}
export default IntraDayManagementByEC
