import styled from 'styled-components'
import { BorderColors, Backgrounds } from '../../../../shared/styles'

export const RootContainerTable = styled.div`
  /* background-color: pink; */
  table {
    width: 100% !important;
    padding: 36px 24px 0px 24px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    /* background-color: green; */
    th {
      font-size: 12px;
      align-self: stretch;
      font-style: normal;
      font-weight: 700;
      cursor: pointer;
      line-height: 16px;
      color: black;
      text-align: left;
      border-radius: 1px;
      padding: 4px 4px;
      border-right: 1px solid #d0d7e2;
      border-bottom: 1px solid #d0d7e2;
      &:active {
        box-shadow: inset 0 3px 0 0 rgb(0 0 0 / 60%);
      }

      /* &:nth-child(1) {
        text-align: left;
      } */

      // :last-child {
      //   text-align: right;
      // }
    }

    tr {
      td {
        padding: 4px;
        color: black;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        box-shadow: inset 0px -1px 0px #d0d7e2;

        // :last-child {
        //   /* background: #22c373; */
        //   text-align: left;
        //   border-radius: 4px;
        //   padding: 4px 10px;
        //   font-style: normal;
        //   font-weight: 700;
        //   font-size: 14px;
        //   line-height: 16px;
        //   text-align: right;

        //   /* color: #ffffff; */
        // }
      }
    }
  }
`
export const StyledTable = styled.div`
  margin-top: 20px;
  padding: 24px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  overflow-x: scroll;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`
export const StyledNoData = styled.div`
  text-align: center;
  font-size: 15px;
  padding: 8px;
`
export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  background: #2563eb;
  border-radius: 8px 8px 0px 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  /* neutral/text-inverted */

  color: #ffffff;
  width: 100%;
  padding: 12px 24px;
`
export const TableContainer = styled.div`
  background: #fcfdff;
  /* Neutrals/gray-30 */
  overflow: auto;

  border: 1px solid #d0d7e2;
  /* Elevation/Elevation-02 */

  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`

export const StyledCheckBox = styled.div`
  input[type='checkbox'] {
    display: none;
  }
`

export const StyledTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 4px 16px;
  gap: 16px;
  padding: 24px 12px;
  width: 100%;
`

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 28px;
  align-items: center;
  padding: 17px 24px;
`
export const GoToPageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`
export const NextPriviousWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  > * {
    margin-right: 10px !important;
  }
`

export const ButtonStyle = styled.button`
  border: none;
  background: #fcfdff;
  cursor: pointer;
  &:hover {
    color: #2563eb;
  }
`
export const CheckboxStyle = styled.input`
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  margin: 0rem 0.625rem;
  border: 0.0625rem solid ${BorderColors.PrimaryText};
  accent-color: ${BorderColors.PrimaryText};
  outline: none;
  &:disabled {
    background: ${Backgrounds.Primary80};
    border-radius: 4px;
    cursor: no-drop;
  }
  &:checked {
    accent-color: ${Backgrounds.Primary50};
    border-radius: 4px;
  }
`
export const BackwardButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 5px;
`
export const ForwardButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 15px;
`
export const TableIconRoot = styled.button`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  gap: 4px;
  margin-top: 10px;
`

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  /* margin-top: 16px; */
  cursor: pointer;
  margin-left: auto;
  /* place-content: end; */
`
export const AddFieldWrapper = styled.div`
  position: fixed;
  top: 55%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fcfdff;
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 70%;
  padding: 10px 15px 10px 15px;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`

export const CancelModal = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
`
export const CancelSendButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 20px;
`
export const AddFieldTable = styled.div`
  overflow-y: scroll;
  height: 90%;
`
export const AddFieldHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`
export const MarginLeft = styled.div`
  padding-top: 5px;
  display: flex;
  flex-direction: row-reverse;
`

export const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const TextWrapper = styled.div`
  /* height: 32px; */
`

export const DropDownWrapper = styled.div`
  min-width: 200px;
  margin: 6px;
`
