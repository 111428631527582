/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  DividendReconReportData: []
  totalCount: number
  isLoading: boolean
  action: string
  comments: any
  sorting: any
  sortDirection: any
  includeFailComments: number
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  DividendReconReportData: [],
  totalCount: 0,
  isLoading: false,
  action: '',
  comments: [],
  sorting: 'Cusip',
  sortDirection: 'ASC',
  includeFailComments: 0,
  httpErrorMessage: '',
  successMessage: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'DividendReconReport',
  initialState,
  reducers: {
    SetDividendReconReportData: (state: State, action: PayloadAction<any>) => {
      state.DividendReconReportData = action.payload.data
    },
    setTotalCount: (state: State, action: PayloadAction<any>) => {
      state.totalCount = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setComments: (state: State, action: PayloadAction<any>) => {
      state.comments = action.payload.data
    },
    setSorting: (state: State, action: PayloadAction<any>) => {
      state.sorting = action.payload
    },
    setSortDirection: (state: State, action: PayloadAction<any>) => {
      state.sortDirection = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  SetDividendReconReportData,
  setTotalCount,
  setIsLoading,
  setAction,
  setComments,
  setSorting,
  setSortDirection,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getReportData: any =
  (
    action: string,
    type: string,
    fromDate: string,
    toDate: string,
    cusip: string,
    account: string,
    ageByPayDate: string,
    ageByLastActivityDate: string,
    includeRpt172Comments: number,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/GetRPT172Report',
        {
          type,
          fromDate,
          toDate,
          cusip,
          account,
          ageByPayDate,
          ageByLastActivityDate,
          includeRpt172Comments,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setAction(action))
        dispatch(SetDividendReconReportData(data))
        dispatch(setTotalCount(data.totalCount))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const getDividendReconReportCSV: any =
  (
    fileName: string,
    type: string,
    fromDate: string,
    toDate: string,
    cusip: string,
    account: string,
    ageByPayDate: string,
    ageByLastActivityDate: string,
    includeRpt172Comments: number,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/GetRPT172CSVExport',
        {
          type,
          fromDate,
          toDate,
          cusip,
          account,
          ageByPayDate,
          ageByLastActivityDate,
          includeRpt172Comments,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('File Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const getDividendReconReportEXCEL: any =
  (
    fileName: string,
    type: string,
    fromDate: string,
    toDate: string,
    cusip: string,
    account: string,
    ageByPayDate: string,
    ageByLastActivityDate: string,
    includeRpt172Comments: number,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/GetRPT172ExcelExport',
        {
          type,
          fromDate,
          toDate,
          cusip,
          account,
          ageByPayDate,
          ageByLastActivityDate,
          includeRpt172Comments,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        },
        { responseType: 'blob' }
      )
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('File Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const putUserComment: any =
  (
    rpt172UniqueId: string,
    comment: string,
    createdBy: string,
    createdOn: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/AddRPT172Comment',
        {
          rpt172UniqueId,
          comment,
          createdBy,
          createdOn,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('Added Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const getComments: any =
  (rpt172UniqueId: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/AssetServicing/GetRpt172Comments?rpt172UniqueId=${rpt172UniqueId}`
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(
          setComments({
            data: data,
          })
        )
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setComments({
          data: [],
        })
      )
    }
  }

export const DividendReconReportDataSelector = (store: StoreState) =>
  store.assetService.DividendReconReport.DividendReconReportData
export const actionSelector = (store: StoreState) =>
  store.assetService.DividendReconReport.action
export const isLoadingSelector = (store: StoreState) =>
  store.assetService.DividendReconReport.isLoading
export const commentSelector = (store: StoreState) =>
  store.assetService.DividendReconReport.comments
export const sortingSelector = (store: StoreState) =>
  store.assetService.DividendReconReport.sorting
export const sortDirectionSelector = (store: StoreState) =>
  store.assetService.DividendReconReport.sortDirection
export const TotalCountSelector = (store: StoreState) =>
  store.assetService.DividendReconReport.totalCount
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.assetService.DividendReconReport.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.assetService.DividendReconReport.successMessage
export default reducer
