import { useState, Dispatch } from 'react'

import { StyledAnchor, AnchorTagProps, Visited } from './styles'

import Image from '../image/Image'

export const AnchorTag = ({
  title,
  href,
  icon,
  color,
  fontSize,
  fontWeight,
  disabled,
  pointer,
  target,
  cursor,
  textDecoration,
  rel,
  lineHeight,
  download
}: AnchorTagProps) => {
  const [visited, setVisited] = useState<Visited>({ visited: false })

  const clickHandler = (stateSetter: Dispatch<Visited>) =>
    stateSetter({ visited: true })

  return (
    <StyledAnchor
      href={href}
      onClick={() => clickHandler(setVisited)}
      visited={visited}
      icon={icon}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      disabled={disabled}
      pointer={pointer}
      target={target}
      cursor={cursor}
      textDecoration={textDecoration}
      rel={rel}
      lineHeight={lineHeight}
      download={download}
    >
       {icon}
      {title}
    </StyledAnchor>
  )
}
