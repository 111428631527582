/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall, authApiUpload } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  regSHOSummaryData: []
  regSHOCusipSummaryData: []
  regSHOCusipSymbolData: []
  regSHONextBuyInData: []
  buyInDetails: []
  auditReportData: []
  dtccTime: any
  isLoading: boolean
  action: string
  status: string
  response: boolean
  sorting: any
  sortDirection: any
  pageNumber: number
  TotalCount: number
  httpErrorMessage: string
  toastMessage: string
}

const initialState: State = {
  regSHOSummaryData: [],
  regSHOCusipSummaryData: [],
  regSHOCusipSymbolData: [],
  regSHONextBuyInData: [],
  buyInDetails: [],
  auditReportData: [],
  dtccTime: null,
  isLoading: false,
  action: '',
  status: '',
  response: false,
  sorting: 'CUSIP_Number',
  sortDirection: 'ASC',
  pageNumber: 0,
  TotalCount: 0,
  httpErrorMessage: '',
  toastMessage: '',
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'reg-SHO-Summary',
  initialState,
  reducers: {
    SetRegSHOSummaryData: (state: State, action: PayloadAction<any>) => {
      state.regSHOSummaryData = action.payload.data
    },
    SetRegSHOCusipSummaryData: (state: State, action: PayloadAction<any>) => {
      state.regSHOCusipSummaryData = action.payload.data
    },
    SetRegSHOCusipSymbolData: (state: State, action: PayloadAction<any>) => {
      state.regSHOCusipSymbolData = action.payload.data
    },
    SetRegSHONextBuyInData: (state: State, action: PayloadAction<any>) => {
      state.regSHONextBuyInData = action.payload.data
    },
    SetDtccTime: (state: State, action: PayloadAction<any>) => {
      state.dtccTime = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setBuyInDetails: (state: State, action: PayloadAction<any>) => {
      state.buyInDetails = action.payload.data
    },
    setAuditReportData: (state: State, action: PayloadAction<any>) => {
      state.auditReportData = action.payload.data
    },
    setSorting: (state: State, action: PayloadAction<any>) => {
      state.sorting = action.payload
    },
    setSortDirection: (state: State, action: PayloadAction<any>) => {
      state.sortDirection = action.payload
    },
    setResponse: (state: State, action: PayloadAction<boolean>) => {
      state.response = action.payload
    },
    setStatus: (state: State, action: PayloadAction<string>) => {
      state.status = action.payload
    },
    setPageNumberRegSHO: (state: State, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    setTotalCount: (state: State, action: PayloadAction<number>) => {
      state.TotalCount = action.payload
    },
    sethttpErrorMessageSummary: (
      state: State,
      action: PayloadAction<string>
    ) => {
      state.httpErrorMessage = action.payload
    },
    setToastMessage: (state: State, action: PayloadAction<string>) => {
      state.toastMessage = action.payload
    },
  },
})

export const {
  SetRegSHOSummaryData,
  SetRegSHOCusipSummaryData,
  SetRegSHOCusipSymbolData,
  SetRegSHONextBuyInData,
  setIsLoading,
  setAction,
  setBuyInDetails,
  setAuditReportData,
  setSorting,
  setSortDirection,
  setStatus,
  setResponse,
  setPageNumberRegSHO,
  setTotalCount,
  sethttpErrorMessageSummary,
  setToastMessage,
  SetDtccTime,
} = actions

export const getRegShoCUSIPSymbolList: any =
  (
    action: string,
    source: string,
    reportDateFrom: string,
    reportDateTo: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    cusip: string,
    symbol: string,
    regSHO_Id: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetRegShoCUSIPSymbolList',
        {
          source,
          reportDateFrom,
          reportDateTo,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          cusip,
          symbol,
          regSHO_Id,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetRegSHOCusipSymbolData({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      dispatch(
        SetRegSHOCusipSymbolData({
          data: '',
        })
      )
      return null
    }
  }

export const getRegSHOSummaryData: any =
  (
    action: string,
    source: string,
    reportDateFrom: string,
    reportDateTo: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    cusip: string,
    symbol: string,
    regSHO_Id: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetRegShoListP3',
        {
          source,
          reportDateFrom,
          reportDateTo,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          cusip,
          symbol,
          regSHO_Id,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setAction(action))
        regSHO_Id
          ? dispatch(
              SetRegSHOCusipSummaryData({
                data: data,
              })
            )
          : dispatch(
              SetRegSHOSummaryData({
                data: data,
              })
            )
        !regSHO_Id && dispatch(setTotalCount(data.totalCount))
        dispatch(sethttpErrorMessageSummary(''))

        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(
        SetRegSHOSummaryData({
          data: '',
        })
      )
      return null
    }
  }

export const getUpdateRegShoL1BuyIns: any =
  (
    regSho_L1BuyIns_Id: number | null,
    regSHO_Id: number,
    cusip: string,
    source: string,
    buyInType: string,
    nextBuyinDate: string,
    buyinAmount: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/AddOrUpdateRegShoL1BuyInDetail',
        {
          regSho_L1BuyIns_Id,
          regSHO_Id,
          cusip,
          source,
          buyInType,
          nextBuyinDate,
          buyinAmount,
        }
      )
      if (status === 200) {
        dispatch(setToastMessage('Updated successfully'))
        dispatch(
          SetRegSHONextBuyInData({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setIsLoading(false))
      dispatch(
        SetRegSHONextBuyInData({
          data: '',
        })
      )
      return null
    }
  }

export const deleteRegShoL1BuyIns: any =
  (regSho_L1BuyIns_Id: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiUpload.post(
        'opsfailprocessapi/FailAnalysisReport/DeleteRegShoCusipLevelBuyInDetail',
        { regSho_L1BuyIns_Id }
      )
      if (status === 200) {
        dispatch(setToastMessage(data.message))
        // dispatch(setToastResponse(true))
        dispatch(setIsLoading(false))
        return true
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return false
    }
  }

export const getRegShoL1BuyInDetails: any =
  (regSHO_Id: string, source: string, buyInType: string, currHist: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoL1BuyInDetail`,
        {
          regSHO_Id,
          source,
          buyInType,
          currHist,
        }
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(
          setBuyInDetails({
            data: data,
          })
        )
        return status
      }
    } catch {
      dispatch(setIsLoading(false))
      dispatch(
        setBuyInDetails({
          data: [],
        })
      )
    }
  }

export const addRegShoListComments: any =
  (
    source: string,
    comment: string,
    regSHO_Id: number,
    cusip: string,
    settleDate: string,
    Tn: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/AddRegShoListComments?regSHO_Id=${regSHO_Id}&source=${source}&comment=${comment}&cusip=${cusip}&settleDate=${settleDate}&Tn=${Tn}`
      )

      if (status === 200) {
        dispatch(setToastMessage('Added successfully'))
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setIsLoading(false))
      return null
    }
  }

export const latestComment: any =
  (Source: string, Cusip: string, settleDate: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status, data } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoLatestComment?Source=${Source}&Cusip=${Cusip}&settleDate=${settleDate}`
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setIsLoading(false))
      return []
    }
  }

export const updateRegShoListComments: any =
  (source: string, L1_Comment_Id: number, Comment: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/UpdateRegShoComment?source=${source}&L1_Comment_Id=${L1_Comment_Id}&Comment=${Comment}`
      )

      if (status === 200) {
        dispatch(setToastMessage('Updated successfully'))
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setIsLoading(false))
      return null
    }
  }

export const GetRegShoListBuyInsAuditTrail: any =
  (regSho_L1BuyIns_Id: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoListBuyInsAuditTrail?regShoL1buyInsId=${regSho_L1BuyIns_Id}`
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(
          setAuditReportData({
            data: data,
          })
        )
        return status
      }
    } catch {
      dispatch(setIsLoading(false))
      dispatch(
        setAuditReportData({
          data: [],
        })
      )
    }
  }

export const getRegShoListComments: any =
  (source: string, Cusip: number, settleDate: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `/opsfailprocessapi/FailAnalysisReport/GetRegShoListComments?Source=${source}&Cusip=${Cusip}&settleDate=${settleDate}&CurrHist="C"`
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setIsLoading(false))
      //
    }
  }

export const getRegSho204ExcelReportForP3: any =
  (
    fileName: string,
    source: string,
    reportDateFrom: string,
    reportDateTo: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    cusip: string,
    symbol: string,
    regSHO_Id: number,
    settleDate?: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetRegSho204ExcelReportForP3',
        {
          source,
          reportDateFrom,
          reportDateTo,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          cusip,
          symbol,
          regSHO_Id,
          settleDate,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setIsLoading(false))
      return null
    }
  }

export const getRegShoSummaryLevelExcelReportForP3: any =
  (
    fileName: string,
    source: string,
    reportDateFrom: string,
    reportDateTo: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    cusip: string,
    symbol: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetRegShoSummaryLevelExcelReportForP3',
        {
          source,
          reportDateFrom,
          reportDateTo,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          cusip,
          symbol,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setIsLoading(false))
      return null
    }
  }

export const getRegSHOTimeStamp: any =
  (source: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoSummaryPageDate?source=${source}`
      )
      if (status === 200) {
        dispatch(SetDtccTime(data))
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setIsLoading(false))
      return ''
    }
  }

export const saveActionedOnChanges: any =
  (actionedItems: { id: number; value: boolean }) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/UpdateActionedOnRegShoSummaryPage`,
        actionedItems
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        dispatch(setToastMessage(data.message))
        return '200'
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setIsLoading(false))
      return ''
    }
  }

export const putUserBulkComment: any = (payload: any) => async () => {
  try {
    const { status } = await authApiCall.post(
      'opsstaticdataapi/StaticData/AddStalePriceComments',
      payload
    )

    if (status === 200) {
      return status
    }
  } catch {
    return 400
  }
}
export const emailUploadL1: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiUpload.post(
        `opsfailprocessapi/FailAnalysisReport/UploadEmailAttachment`,
        {
          AllocationId: null,
          RegShoId: payload.regSHO_Id,
          FileData: payload.fileData,
          FileName: payload.fileName,
          ScreenName: 'L1',
        }
      )
      if (status === 200) {
        dispatch(setToastMessage('Uploaded successfully'))
        dispatch(setIsLoading(false))
        return true
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setIsLoading(false))
      return false
    }
  }
export const getRegShoBuyIns: any =
  (
    regSHO_Id: number,
    allocationType: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetRegShoBuyIns',
        {
          regSHO_Id,
          allocationType,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))

        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      return null
    }
  }
export const getRegSHOExcelBuyIn: any =
  (fileName: string, payload: {}) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/RegShoBuyInsExcelExport',
        payload,
        { responseType: 'blob' }
      )
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return null
    }
  }
export const ApproveRegShoData: any =
  (actionDate: string, actionTodate: string, source: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status, data } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/ApproveRegShoData?actionDate=${actionDate}&actionTodate=${actionTodate}&source=${source}`
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setToastMessage(data?.successMessage))
        dispatch(sethttpErrorMessageSummary(''))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setToastMessage(''))
      dispatch(setIsLoading(false))
      return []
    }
  }

export const getApprovalAudit: any =
  (payload: {}) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status, data } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoApprovedHistoryReport`,
        payload
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(setToastMessage(''))
      dispatch(setIsLoading(false))
      return []
    }
  }

export const dataSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.regSHOSummaryData
export const currentDataSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.data
export const cusipSymbolDataSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.regSHOCusipSymbolData
export const nextBuyInDataSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.regSHONextBuyInData
export const LoadingSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.isLoading
export const actionSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.action
export const sortingSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.sorting
export const sortDirectionSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.sortDirection
export const statusSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.status
export const ResponseSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.response
export const PageNumSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.pageNumber
export const TotalCountSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.TotalCount
export const ErrorMessageSummarySelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.httpErrorMessage
export const ToastMessageSummarySelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.toastMessage
export const buyInDetailsSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.buyInDetails
export const auditReportDataSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.auditReportData
export const DTCCTimeSelector = (store: StoreState) =>
  store.regSHO204Tool.regSHOSummary.dtccTime

export default reducer
