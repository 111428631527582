import { useRowSelect, useTable } from 'react-table'
import { Wrapper } from './styles'
import { useMemo } from 'react'
import { TableProps } from './ITable'
import { TableFooter, TableHeader } from './TableUtils'
import TableCell from './TableCell'

const AllocationTable = ({
  tableColumns,
  setRowId,
  tableData,
  onClick,
  setMailPOPUP,
  setMailList,
  hasFooter = false,
  disableComponent,
}: TableProps) => {
  const data = useMemo(() => tableData, [tableData])
  const columns = useMemo(() => tableColumns, [tableColumns])
  const tableInstance = useTable({ columns, data }, useRowSelect)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const selectedColor = (e: any) => {
    for (const elem of (document as any).getElementsByClassName(
      'active-selected'
    )) {
      elem.classList.remove('active-selected')
    }
    for (const elem of (document as any).getElementsByClassName('table-row')) {
      elem.classList.remove('table-row')
    }
    e.currentTarget.classList.add('active-selected')
  }

  return (
    <Wrapper id="id">
      <table {...getTableProps()}>
        <TableHeader headerGroups={headerGroups} />
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                key={index}
                onClick={(e) => {
                  onClick(row.original)
                  selectedColor(e)
                }}
                className="table-row"
              >
                <TableCell
                  row={row}
                  setMailList={setMailList}
                  setMailPOPUP={setMailPOPUP}
                  setRowId={setRowId}
                  disableComponent={disableComponent}
                />
              </tr>
            )
          })}
        </tbody>
        {hasFooter ? <TableFooter footerGroups={footerGroups} /> : null}
      </table>
    </Wrapper>
  )
}

export default AllocationTable
