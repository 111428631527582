import { ISvg } from './ISvg'

export const SvgPlus = ({ fillColor }: ISvg) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.668 5.33341H6.66797V1.33341C6.66797 1.1566 6.59773 0.987035 6.47271 0.86201C6.34768 0.736986 6.17811 0.666748 6.0013 0.666748C5.82449 0.666748 5.65492 0.736986 5.5299 0.86201C5.40487 0.987035 5.33464 1.1566 5.33464 1.33341V5.33341H1.33464C1.15782 5.33341 0.988255 5.40365 0.863231 5.52868C0.738207 5.6537 0.667969 5.82327 0.667969 6.00008C0.667969 6.17689 0.738207 6.34646 0.863231 6.47149C0.988255 6.59651 1.15782 6.66675 1.33464 6.66675H5.33464V10.6667C5.33464 10.8436 5.40487 11.0131 5.5299 11.1382C5.65492 11.2632 5.82449 11.3334 6.0013 11.3334C6.17811 11.3334 6.34768 11.2632 6.47271 11.1382C6.59773 11.0131 6.66797 10.8436 6.66797 10.6667V6.66675H10.668C10.8448 6.66675 11.0143 6.59651 11.1394 6.47149C11.2644 6.34646 11.3346 6.17689 11.3346 6.00008C11.3346 5.82327 11.2644 5.6537 11.1394 5.52868C11.0143 5.40365 10.8448 5.33341 10.668 5.33341Z"
        fill={fillColor}
      />
    </svg>
  )
}
