import Lottie from 'lottie-react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/button/Button'
import Divider from '../../../../components/divider/Divider'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import Table from '../../../../components/table/Table'
import { Toast } from '../../../../components/toast/Toast'
import { Backgrounds } from '../../../../shared/styles'
import {
  dataSelectorHistory,
  getComments,
  LoadingSelector,
  ResponseSelector,
  statusSelector,
} from '../../../../store/staticData/stale-pricing-p3'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { COLORS } from '../../../../theme/Colors'

import {
  CancelButtonWrapper,
  CommentTableContainer,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  PopUpStyleLoader,
  StyledSelectWrapper,
  StyledTableContainer,
  ToastWrapper,
} from '../styles'

import P3StalePricingTableHistory from './p3StalePricingTable/P3StalePricingHistoryTable'
import { SvgVector } from '../../../../components/svg/SvgVector'

const P3StaleColumnHistory = (props: any) => {
  const dispatch = useDispatch()
  const data = useSelector(dataSelectorHistory)
  const [userComment, setUserComments] = useState<any>([])
  const response = useSelector(ResponseSelector)
  const status = useSelector(statusSelector)
  const [openComments, setOpenComments] = useState<boolean>(false)
  const loading = useSelector(LoadingSelector)
  const [toastPopup, setToastPopup] = useState<boolean>(false)

  const savedComments = async (tb: any, system: string) => {
    const response = await dispatch(
      getComments(
        system,
        data.data[tb].cusip_Number.replace(/'/g, ''),
        '',
        true,
        props.toDate
      )
    )
    setUserComments(response)
    await setOpenComments(true)
  }
  
  const renderColors = (flag: number) => {
    switch (flag) {
      case 1:
        return 'red'
      case 2:
        return '#008000'
      case 3:
        return '#737373'
      default:
        return '#475569'
    }
  }

  const tableColumn = [
    {
      Header: 'Security',
      columns: [
        {
          Header:"Flag",
          accessor: 'followup_Flag',
          width: '2%',
          Cell: (table: { row: { original: { followup_Flag: number } } }) => {
            return (
              <>
                  <SvgVector
                    fillColor={
                      table?.row?.original?.followup_Flag
                        ? renderColors(table?.row?.original?.followup_Flag)
                        : '#475569'
                    }
                  />
              </>
            )
          },
        }, 
        {
          Header: 'Cusip_Number',
          accessor: (d: any) => {
            if (d.cusip_Number) return d.cusip_Number.replace(/'/g, '')
          },
        },
        {
          Header: 'ISIN',
          accessor: 'isiN_NUMBER',
        },
      ],
    },
    {
      Header: 'Category',
      columns: [
        {
          Header: 'Top Category',
          accessor: 'top_Category',
        },
        {
          Header: 'Sub Category',
          accessor: 'sub_Category',
        },
      ],
    },
    {
      Header: 'Comments',
      columns: [
        {
          Header: 'All Comments',
          Cell: (table: any) => {
            return (
              <>
                <Button
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={'button'}
                  color={COLORS.Background.Primarytext}
                  style={{ whiteSpace: 'nowrap' }}
                  bgColor={'transparent'}
                  onClick={() => savedComments(table.cell.row.id, 'P3')}
                >
                  View
                </Button>
              </>
            )
          },
        },
        {
          Header:"Notes",
          accessor:"notes"
        }
      ],
    },
    {
      Header: 'ICE',
      columns: [
        {
          Header: 'ICE Last Priced',
          accessor: 'icE_Last_Priced',
        },
        {
          Header: 'ICE Last Price',
          accessor: 'icE_Last_Price',
        },
        {
          Header: 'ICE Notes',
          accessor: 'icE_Notes',
        },
      ],
    },
    {
      Header: 'Pricing Vendor',
      columns: [
        {
          Header: 'BBG Last Priced',
          accessor: 'bbG_Last_Priced',
        },
        {
          Header: 'BBG Last Price',
          accessor: 'bbG_Last_Price',
        },
      ],
    },
    {
      Header: 'P3 Databases',
      columns: [
        {
          Header: 'Box Location',
          accessor: 'boxLocation',
        },
        {
          Header: 'Location Memo',
          accessor: 'locationMemo',
        },
        {
          Header: 'Box Desc',
          accessor: 'boxDesc',
        },
        {
          Header: 'Country',
          accessor: 'country',
        },
        {
          Header: 'Security Type Code',
          accessor: 'securityTypeCode',
        },
        {
          Header: 'Security Type and Sub Type Code',
          accessor: 'security_Type_and_Sub_Type_Code',
        },
        {
          Header: 'Expiration Date/Maturity Date Applicable',
          accessor: 'maturity_WarrantExpire_Date',
        },
        {
          Header: 'In Default',
          accessor: 'inDefault',
        },
        {
          Header: 'Description 2',
          accessor: 'description2',
        },
        {
          Header: 'Description 3',
          accessor: 'description3',
        },
        {
          Header: 'EODCMVDAT Last Priced',
          accessor: 'eodcmvdaT_LastPriced',
        },
        {
          Header: 'EODCMVDAT Last Price',
          accessor: 'eodcmvdaT_LastPrice',
        },
        {
          Header: 'ISSUE STATUS CODE',
          accessor: 'issue_Status_Code',
        },
      ],
    },
    {
      Header: 'DTC Data',
      columns: [
        {
          Header: 'DTC Premed Elig',
          accessor: 'dtC_Premed_Elig',
        },
      ],
    },
    {
      Header: 'P3 RPT058 Report Data',
      columns: [
        {
          Header: 'Maturity Date',
          accessor: 'maturityDate',
        },
        {
          Header: 'Coupon Rate',
          accessor: 'couponRate',
        },
        {
          Header: 'SYMBOL',
          accessor: 'symbol',
        },
        {
          Header: 'CUR',
          accessor: 'currency',
        },
        {
          Header: 'Last Priced',
          accessor: 'lastPriceDate',
        },
        {
          Header: 'Last Price',
          accessor: 'lastPrice',
        },
        {
          Header: 'Cash Acct Quantity',
          accessor: 'cashQnty',
        },
        {
          Header: 'Margin Acct Quantity',
          accessor: 'marginQnty',
        },
        {
          Header: 'Inventory Acct Quantity',
          accessor: 'inventory',
        },
      ],
    },
    {
      Header: 'Current RPT058 Report',
      columns: [
        {
          Header: 'Currently still in stale report',
          accessor: 'currently_Still_In_StaleReport',
        },
        {
          Header: 'Last priced in Current Stale Report',
          accessor: 'last_Priced_In_Current_StaleReport',
        },
        {
          Header: 'Last price in Current Stale Report',
          accessor: 'lastPrice_In_Current_StaleReport',
        },
      ],
    },
  ]

  return (
    <StyledTableContainer>
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {openComments && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>All Comments</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenComments(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'Cusip',
                  accessor: 'cusip',
                },
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
                {
                  Header: 'Created by',
                  accessor: 'createdBy',
                },
                {
                  Header: 'Create on(EST)',
                  accessor: 'createdOn',
                },
              ]}
              tableData={userComment}
              onClick={() => {
                //
              }}
            />
            {userComment.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      {toastPopup && (
        <ToastWrapper>
          <Toast
            text={status}
            type={response ? 'success' : 'danger'}
            openStatusCallback={() => {
              setToastPopup(false)
            }}
          />
        </ToastWrapper>
      )}
      <P3StalePricingTableHistory
        handleSubmit={props.handleSubmit}
        columns={tableColumn}
        fromDate={props.fromDate}
        toDate={props.toDate}
        data={props.data}
        totalRecords={props.totalRecords}
      />
    </StyledTableContainer>
  )
}
export default P3StaleColumnHistory
