import { useLocation } from 'react-router-dom'
import Label from '../../../../../components/label/Label'
import { SvgPlus } from '../../../../../components/svg/SvgPlus'
import SvgRedo from '../../../../../components/svg/SvgRedo'
import { SvgDownloadSmall } from '../../../../../components/svg/logo/SvgDownload'
import { Colors } from '../../../../../shared/styles'
import { IconsWrapper, TableTopBarRoot } from '../../../styles'
import { useState } from 'react'
import AddContactMpid from '../../addContacts/AddContactMpid'

const TableTopBar = (props: any) => {
  const location = useLocation()
  const [showAddContact, SetshowAddContact] = useState<boolean>(false)
  const {
    displayFlag,
    refreshData,
    downloadExcel,
    setLoadingt,
    writeExecuteFlag,
  } = props

  return (
    <TableTopBarRoot>
      {displayFlag && (
        <IconsWrapper
          onClick={() => writeExecuteFlag && SetshowAddContact(true)}
        >
          <SvgPlus fillColor={Colors.PrimaryText}></SvgPlus>
          <Label color={Colors.PrimaryText}>Add Contacts</Label>
        </IconsWrapper>
      )}
      <IconsWrapper onClick={() => refreshData()}>
        <SvgRedo fillColor={Colors.PrimaryText}></SvgRedo>
        <Label color={Colors.PrimaryText}>Refresh</Label>
      </IconsWrapper>
      <IconsWrapper
        onClick={() => {
          downloadExcel(location.state)
        }}
      >
        <SvgDownloadSmall fillColor={Colors.PrimaryText}></SvgDownloadSmall>
        <Label color={Colors.PrimaryText}>Download</Label>
      </IconsWrapper>
      {showAddContact && (
        <AddContactMpid
          PopUpClose={() => {
            SetshowAddContact(false)
          }}
        />
      )}
    </TableTopBarRoot>
  )
}
export default TableTopBar
