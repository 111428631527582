import {
  PopUpStyleContent,
  StyledSelectWrapper,
  SvgButtonWrapper,
  MarginLeftAutoSpace,
  ConfirmationModalProps
} from './styles'
import Label from '../../components/label/Label'
import { COLORS } from '../../theme/Colors'
import { SvgExclamation } from '../../components/svg/logo/SvgExclamation'
import SvgCancel from '../../components/svg/logo/SvgCancel'
import Divider from '../../components/divider/Divider'
import { Backgrounds } from '../../shared/styles'
import Button from '../../components/button/Button'

const ConfirmationModal = ({
  cancelClick,
  headingText,
  closeModal,
  message,
  successClick,
  cancelText,
  confirmText
}: ConfirmationModalProps) => {

  return (
    <PopUpStyleContent>
      <StyledSelectWrapper
        style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
      >
        <SvgButtonWrapper
          onClick={cancelClick}
        >
          <SvgExclamation
            fillColor={COLORS.Icons.DangerIcon}
          ></SvgExclamation>
        </SvgButtonWrapper>
        {headingText}
        <MarginLeftAutoSpace>
          <SvgButtonWrapper
            onClick={closeModal}
          >
            <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
          </SvgButtonWrapper>
        </MarginLeftAutoSpace>
      </StyledSelectWrapper>
      <Divider
        thickness={'1px'}
        horizontal={true}
        bgColor={Backgrounds.Gray110}
      />
      <StyledSelectWrapper style={{ padding: 15 }}>
        <>
          {message}
        </>
      </StyledSelectWrapper>
      <StyledSelectWrapper
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: 20,
        }}
      >
        <Button
          padding={'4px 16px'}
          borderRadius={'4px'}
          type={'button'}
          color={COLORS.Background.Primarytext}
          bgColor={'transparent'}
          onClick={cancelClick}
        >
          <Label fontWeight={600}> {cancelText}</Label>
        </Button>
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={successClick}
        >
          {confirmText}
        </Button>
      </StyledSelectWrapper>
    </PopUpStyleContent>
  )
}

export default ConfirmationModal
