/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Select, {
  CSSObjectWithLabel,
  IndicatorsContainerProps,
  components
} from 'react-select'
import { BorderColors } from '../../shared/styles'
import { SelectDropdownProps, Wrapper } from './styles'


const SelectDropdownWithCheckBox = ({
  onChange,
  onBlur,
  defaultValue,
  value,
  isMulti,
  borderRadius,
  optionValues,
  isDisabled,
  isSearchable,
  indicatorSeparator,
  width,
  height,
  name,
  placeholder,
  innerRef,
  border,
  color,
  buttonWidth,
  whiteSpace,
  flexWrap,
  overflow,
  labelValue,
  defaultCheckAll
}: SelectDropdownProps) => {

  const [selectAll, setSelectall] = useState<any>(false);
  const [selectedLabels, setSelected] = useState<any>(false);

  const handleChange = (newValue: any) => {
    if (newValue !== null &&
      newValue.length > 0 &&
      newValue[newValue.length - 1].value === "all"
    ) {
      if (!selectAll) {
        onChange && onChange(optionValues)
        setSelected(optionValues)

      }
      else {
        setSelected([])

        onChange && onChange([])
      }
      setSelectall(!selectAll)
    }
    else {
      setSelectall(false)
      if (newValue.length == optionValues.length) {
        setSelectall(true)
      }
      const inputValue = newValue
      setSelected(newValue)

      onChange && onChange(inputValue)
    }

  }

  useEffect(()=>{
    if(defaultCheckAll){
      onChange && onChange(optionValues)
      setSelected(optionValues)
      setSelectall(!selectAll)
    }
  },[defaultCheckAll])

  const customStyles = {
    control: (provided: CSSObjectWithLabel) => {
      return {
        ...provided,
        width: buttonWidth ? buttonWidth : width,
        height: height,
        minHeight: height,
        borderRadius: borderRadius,
        // borderColor: '#CBD5E1',
        border: border ? border : '1px solid ' + BorderColors.Gray50,
        color: color ? color : '#475569'
      }
    },
    placeholder: (provided: CSSObjectWithLabel) => {
      return {
        ...provided,
        color: '#475569',
      }
    },
    singleValue: (provided: CSSObjectWithLabel) => {
      return {
        ...provided,
        color: '#475569',
      }
    },
    input: (provided: CSSObjectWithLabel) => {
      return{
        ...provided,
        margin:'0px',
        paddingTop:'0px',
        paddingBottom:'0px'
      }
    },
    indicatorContainer: (provided: IndicatorsContainerProps) => {
      return {
        ...provided,
        color: '#475569',
      }
    },
    dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: '#475569', // Custom colour
    }),
    valueContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      whiteSpace: whiteSpace,
      overflow: overflow,
      flexWrap: flexWrap,
      height:'24px'
    }),
  }


  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected || selectAll}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const renderlabels = (children: any) => {
    if (children[0]?.length > 3) {
      return children[0].slice(0, 3)
    }
    else {
      return children
    }
  }

  const renderToolTip = (selected: any) => {
    let test = ""
    selected.slice(3, selected.length + 1).map((ele: any) => {
      test = test.concat(",", ele.label)
    })
    return test
  }

  const ValueContainer = (props: any) => {
    const { selectProps: { value: { label } }, children } = props;
    return (
      <components.ValueContainer {...props}>
        {selectAll ? `All ${labelValue} Selected` : renderlabels(children)}
        {selectedLabels?.length > 3 && !selectAll ? <span style={{ fontWeight: 'bold', fontSize: '14px',lineHeight:'32px' }} title={renderToolTip(selectedLabels) || ""}>
          +{selectedLabels?.length - 3}
        </span> : null}

      </components.ValueContainer>
    );
  };
  return (
    <>
      <Wrapper width={width}>
        <Select
          options={[{
            label: `All ${labelValue} Selected`,
            value: "all"
          }, ...optionValues]}
          onChange={handleChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
          isMulti={isMulti}
          isDisabled={isDisabled}
          isSearchable={isSearchable}
          name={name}
          value={value}
          styles={customStyles}
          placeholder={placeholder}
          ref={innerRef}
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          components={{
            ValueContainer,
            Option
          }}
        />

      </Wrapper>

    </>
  )
}

export default SelectDropdownWithCheckBox
