import { useState } from 'react'
import AddPopUp from '../../../../../components/popUp/addPopUp/AddEditPopUp'
import { BuyInReceivesProducts, getInputTypeList } from '../constants'
import { ValidateBuyInProductSchema } from './validations'
import { useDispatch } from 'react-redux'
import { z } from 'zod'
import {
  getFTRProducts,
  saveFTRProduct,
  updateFTRProduct,
} from '../../../../../store/buyIns/buyInSettlements'

interface AddProduct {
  setShowAddProduct: React.Dispatch<React.SetStateAction<boolean>>
  setProducts: React.Dispatch<React.SetStateAction<BuyInReceivesProducts>>
  products: BuyInReceivesProducts
  buttonLabel: boolean
  setButtonLabel: React.Dispatch<React.SetStateAction<boolean>>
}

const BuyInReceivesAddProduct = ({
  setShowAddProduct,
  products,
  setProducts,
  buttonLabel,
  setButtonLabel,
}: AddProduct) => {
  const [validationError, setValidationError] = useState<z.ZodIssue[]>([])

  const dispatch = useDispatch()
  const [data, setData] = useState<BuyInReceivesProducts>(products)
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    attribute: string
  ) => {
    e.target.type === 'checkbox'
      ? setData({ ...data, [attribute]: e.target.checked })
      : setData({ ...data, [attribute]: e.target.value })
  }

  const addNewProduct = async () => {
    try {
      const validatedForm = ValidateBuyInProductSchema.parse(data)
      if (validatedForm) {
        await dispatch(saveFTRProduct(data))
        dispatch(getFTRProducts())
        setProducts({
          source: 'Beta',
          secType: '',
          description: '',
          assetType: '',
          dTCCSecType: '',
          buyInActiveYN: false,
          sdBusinessDays: undefined,
        })
        setShowAddProduct(false)
        setButtonLabel(false)
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        const errors = err.issues
        if (errors) {
          setValidationError(errors)
        }
      }
    }
  }
  const updateProduct = async () => {
    await dispatch(updateFTRProduct(data))
    dispatch(getFTRProducts())
    setProducts({
      source: 'Beta',
      secType: '',
      description: '',
      assetType: '',
      dTCCSecType: '',
      buyInActiveYN: false,
      sdBusinessDays: undefined,
    })
    setShowAddProduct(false)
  }

  return (
    <>
      <AddPopUp
        maxContainerHeight="500px"
        maxContainerWidth="700px"
        title={buttonLabel ? 'Add Product' : 'Update Product'}
        buttonLabel={buttonLabel ? 'Add' : 'Update'}
        onCancelClick={() => {
          setButtonLabel(false)
          setShowAddProduct(false)
          setProducts({
            source: 'Beta',
            secType: '',
            description: '',
            assetType: '',
            dTCCSecType: '',
            buyInActiveYN: false,
            sdBusinessDays: undefined,
          })
        }}
        onButtonClick={buttonLabel ? addNewProduct : updateProduct}
        listItems={getInputTypeList(data)}
        onChange={onChange}
        error={validationError}
      />
    </>
  )
}

export default BuyInReceivesAddProduct
