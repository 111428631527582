/* eslint-disable react-hooks/exhaustive-deps */
import {
  RootContainer,
  StyledTableContainer,
  OptionsWrapper,
  DownloadWrapper,
  LottieWrapper,
  ToastWrapper,
} from './styles'

import {
  getCountryCodeRecon,
  isLoadingSelector,
  getCountryCodeReconCSV,
  dataSelector,
  setCurrentData,
  HttpSuccessMessageSelector,
  HttpErrorMessageSelector,
  setSuccessMessage,
  setHttpErrorMessage,
} from '../../../store/staticData/country-code-recon'

import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import Table from '../../../components/table/Table'
import Loader from '../../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import { Toast } from '../../../components/toast/Toast'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { COLORS } from '../../../theme/Colors'

const FISIRSISOCountryReconciliation = () => {
  const [isTableVisible, setIsTableVisible] = useState(false)
  const dispatch = useDispatch()
  const buffering = useSelector(isLoadingSelector)
  const data = useSelector(dataSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  

  const date = new Date().toISOString().split('T')[0]

  useEffect(() => {
    dispatch(setCurrentData([]))
  }, [])
  const TableColumnsDaily = [
    {
      Header: 'CUSIP NUMBER',
      accessor: 'cusip_Number',
    },
    {
      Header: 'P3 Country of Domicile',
      accessor: 'country_Of_Domicile',
    },
    {
      Header: 'P3 IRS Code',
      accessor: 'foreign_Country',
    },
    {
      Header: 'PAY DATE',
      accessor: 'pay_Date',
    },
    {
      Header: 'Reference Country of Domicile',
      accessor: 'isoCountryCode',
    },
    {
      Header: 'Reference IRS code',
      accessor: 'irsCountryCode',
    },
  ]

  const handleRunReport = async () => {
    const response = await dispatch(getCountryCodeRecon())
    setIsTableVisible(true)
    if (response) {
      setIsTableVisible(true)
    }
  }

  const DownloadCSV = async () => {
    const response = await dispatch(
      getCountryCodeReconCSV(`FIS_IRS_ISOCountryReconciliation_${date}`)
    )
  }

  return (
    <RootContainer>
      <WDCard>
        <CardHeader
          label={'FIS IRS ISOCountry Reconciliation'}
          icon={false}
          amountValue={`Total: ${data && data.length}`}
        />
      </WDCard>
      <StyledTableContainer>
        <OptionsWrapper>
          <DownloadWrapper
            onClick={() => {
              DownloadCSV()
            }}
          >
            <SvgDownloadSmall
              fillColor={`${COLORS.UI.Primary50}`}
            ></SvgDownloadSmall>
            <WDStyledButtonText>
              <Label>CSV Download</Label>
            </WDStyledButtonText>
          </DownloadWrapper>
          <Button
            hoverBgColor={'#1D4ED8'}
            bgColor={'#2563EB'}
            color={'#ffffff'}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            gap={'8px'}
            type={''}
            activeBgColor={'#1E40AF'}
            borderColor={'transparent'}
            onClick={() => {
              handleRunReport()
            }}
          >
            Run Report
          </Button>
        </OptionsWrapper>
        {buffering && (
          <div>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '54%' }}> Loading...</LottieWrapper>
          </div>
        )}
        {isTableVisible && (
          <div style={{ marginTop: '16px' }}>
            <Table
              tableColumns={TableColumnsDaily}
              tableData={data}
              onClick={() => {
                //
              }}
            />
            {data.length == 0 && (
             <><Label fontWeight={700} margin="5% 0 0 50%">
             No Records Found 
           </Label><Label fontWeight={700} margin="0.5% 0 0 49%">
             No Exceptions Identified
             </Label></>
            )}
          </div>
        )}
      </StyledTableContainer>
      {(SuccessMessage?.length > 0 ||
        ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success':'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default FISIRSISOCountryReconciliation
