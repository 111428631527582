import {
  FeatureDescription,
  IconConatainer,
  SummaryCardContainerTradeReconcilation,
  TitleContainer,
} from './styles'
import { useNavigate } from 'react-router-dom'
import { SvgWrenchBig } from '../../svg/SvgWrench'

const OperationsControl = () => {
  const navigate = useNavigate()

  const handleClickNavigation = () => {
    navigate('/ADR-Fees-Upload', {
      state: {
        passProps: {},
      },
    })
  }

  return (
    <SummaryCardContainerTradeReconcilation onClick={handleClickNavigation}>
      <TitleContainer>Operations Control</TitleContainer>
      <IconConatainer>
        <SvgWrenchBig fillColor={'#FFFFFF'} />
      </IconConatainer>
      <FeatureDescription>
        Manage the performance of quality assurance controls across operational
        functions
      </FeatureDescription>
    </SummaryCardContainerTradeReconcilation>
  )
}

export default OperationsControl
