import Lottie from 'lottie-react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/button/Button'
import Divider from '../../../../components/divider/Divider'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import Table from '../../../../components/table/Table'
import { Textarea } from '../../../../components/textArea/Textarea'
import { Toast } from '../../../../components/toast/Toast'
import { Backgrounds } from '../../../../shared/styles'
import {
  dataSelectorHistory,
  getComments,
  LoadingSelector,
  ResponseSelector,
  statusSelector,
} from '../../../../store/staticData/stale-pricing-beta'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { COLORS } from '../../../../theme/Colors'

import {
  CancelButtonWrapper,
  CommentTableContainer,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  PopUpStyleLoader,
  StyledSelectWrapper,
  StyledTableContainer,
  ToastWrapper,
} from '../styles'

import BetaStalePricingTable from './betaStalePricingTable/BetaStalePricingTable'
import BetaStalePricingTableHistory from './betaStalePricingTable/BetaStalePricingHistoryTable'
import { SvgVector } from '../../../../components/svg/SvgVector'

const BetaStaleColumnHistory = (props: any) => {
  const dispatch = useDispatch()
  const data = useSelector(dataSelectorHistory)
  const [userComment, setUserComments] = useState<any>([])
  const response = useSelector(ResponseSelector)
  const status = useSelector(statusSelector)
  const [openComments, setOpenComments] = useState<boolean>(false)
  const loading = useSelector(LoadingSelector)
  const [toastPopup, setToastPopup] = useState<boolean>(false)

  const savedComments = async (tb: any) => {
    const response = await dispatch(
      getComments(
        'beta',
        data.data[tb].cusip.replace(/'/g, ''),
        data.data[tb].securityNbr,
        true,
        props.toDate
      )
    )
    setUserComments(response)
    await setOpenComments(true)
  }
  const renderColors = (flag: number) => {
    switch (flag) {
      case 1:
        return 'red'
      case 2:
        return '#008000'
      case 3:
        return '#737373'
      default:
        return '#475569'
    }
  }
  const tableColumn = [
    {
      Header: 'Security',
      columns: [
        {
          Header:"Flag",
          accessor: 'followup_Flag',
          width: '2%',
          Cell: (table: { row: { original: { followup_Flag: number } } }) => {
            return (
              <>
                  <SvgVector
                    fillColor={
                      table?.row?.original?.followup_Flag
                        ? renderColors(table?.row?.original?.followup_Flag)
                        : '#475569'
                    }
                  />
              </>
            )
          },
        }, 
        {
          Header: 'Security Description',
          accessor: 'securityDesc',
        },
        {
          Header: 'Security Number',
          accessor: 'securityNbr',
        },
        {
          Header: 'CUSIP',
          accessor: (d: any) => {
            if (d.cusip) return d.cusip.replace(/'/g, '')
          },
        },
        {
          Header: 'ISIN',
          accessor: 'isiN_Number',
        },
      ],
    },
    {
      Header: 'Category',
      columns: [
        {
          Header: 'Top Category',
          accessor: 'top_Category',
        },
        {
          Header: 'Sub Category',
          accessor: 'sub_Category',
        },
      ],
    },
    {
      Header: 'Comments',
      columns: [
        {
          Header: 'All Comments',
          Cell: (table: any) => {
            return (
              <>
                <Button
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={'button'}
                  color={COLORS.Background.Primarytext}
                  style={{ whiteSpace: 'nowrap' }}
                  bgColor={'transparent'}
                  onClick={() => savedComments(table.cell.row.id)}
                >
                  View
                </Button>
              </>
            )
          },
        },
        {
          Header:"Notes",
          accessor:"notes"
        }
      ],
    },
    {
      Header: 'ICE',
      columns: [
        {
          Header: 'ICE Last Priced',
          accessor: 'icE_Last_Priced',
        },
        {
          Header: 'ICE Last Price',
          accessor: 'icE_Last_Price',
        },
        {
          Header: 'ICE Notes',
          accessor: 'icE_Notes',
        },
      ],
    },
    {
      Header: 'Pricing Vendor',
      columns: [
        {
          Header: 'BBG Last Priced',
          accessor: 'bbG_Last_Priced',
        },
        {
          Header: 'BBG Last Price',
          accessor: 'bbG_Last_Price',
        },
      ],
    },
    {
      Header: 'Beta Databases',
      columns: [
        {
          Header: 'Box Location',
          accessor: 'box_Location',
        },
        {
          Header: 'Box Description',
          accessor: 'box_Description',
        },
        {
          Header: 'Country',
          accessor: 'country',
        },
        {
          Header: 'Security Type Code',
          accessor: 'securityTypeCode',
        },
        {
          Header: 'Expiration Date/Maturity Date',
          accessor: 'expirationDate',
        },
        {
          Header: 'In Default',
          accessor: 'inDefault',
        },
        {
          Header: 'Corporate Action 1',
          accessor: 'corporateAction1',
        },
        {
          Header: 'Corporate Action 2',
          accessor: 'corporateAction2',
        },
        {
          Header: 'Surf-User Pricing/Manual',
          accessor: 'surf_User_Pricing_Manual',
        },
        {
          Header: 'Surf Last Updated',
          accessor: 'surf_LastUpdated',
        },
        {
          Header: 'Surf Last Price',
          accessor: 'surf_LastPrice',
        },
        {
          Header: 'User Initial',
          accessor: 'userIntial',
        },
        {
          Header: 'Changed Date',
          accessor: 'changedDate',
        },
        {
          Header: 'Market Price',
          accessor: 'marketPrice',
        },
        {
          Header: 'Changed Terminal',
          accessor: 'changeTerminal',
        },
      ],
    },
    {
      Header: 'DTC Data',
      columns: [
        {
          Header: 'DTC Premed',
          accessor: 'dtC_Premed',
        },
      ],
    },
    {
      Header: 'WED14509A10 Report Dat',
      columns: [
        {
          Header: 'Last Update',
          accessor: 'lastUpdateDate',
        },
        {
          Header: 'Last Price',
          accessor: 'lastPrice',
        },
        {
          Header: 'S/D',
          accessor: 's_D',
        },
        {
          Header: 'Cash',
          accessor: 'cash',
        },
        {
          Header: 'Margin',
          accessor: 'margin',
        },
        {
          Header: 'IRA',
          accessor: 'ira',
        },
        {
          Header: 'UPX/Z',
          accessor: 'upX_Z',
        },
      ],
    },
    {
      Header: 'Current WED14509ABCD10 Report',
      columns: [
        {
          Header: 'Currently still in stale report',
          accessor: 'currently_Still_In_StaleReport',
        },
        {
          Header: 'Last priced in Current Stale Report',
          accessor: 'last_Priced_In_Current_StaleReport',
        },
        {
          Header: 'Last price in Current Stale Report',
          accessor: 'lastPrice_In_Current_StaleReport',
        },
      ],
    },
  ]

  return (
    <StyledTableContainer>
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {openComments && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>All Comments</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenComments(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'Security Number',
                  accessor: 'securityNumber',
                },
                {
                  Header: 'Cusip',
                  accessor: 'cusip',
                },
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
                {
                  Header: 'Created by',
                  accessor: 'createdBy',
                },
                {
                  Header: 'Create on(EST)',
                  accessor: 'createdOn',
                },
              ]}
              tableData={userComment}
              onClick={() => {
                //
              }}
            />
            {userComment.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      {toastPopup && (
        <ToastWrapper>
          <Toast
            text={status}
            type={response ? 'success' : 'danger'}
            openStatusCallback={() => {
              setToastPopup(false)
            }}
          />
        </ToastWrapper>
      )}
      <BetaStalePricingTableHistory
        handleSubmit={props.handleSubmit}
        columns={tableColumn}
        fromDate={props.fromDate}
        toDate={props.toDate}
        data={props.data}
        totalRecords={props.totalRecords}
      />
    </StyledTableContainer>
  )
}
export default BetaStaleColumnHistory
