/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  p3FailAnalysis: []
  totalCount: number
  isLoading: boolean
  action: string
  comments: any
  sorting: any
  sortDirection: any
  includeFailComments: number
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  p3FailAnalysis: [],
  totalCount: 0,
  isLoading: false,
  action: '',
  comments: [],
  sorting: 'SnapShotDate, Age',
  sortDirection: 'ASC',
  includeFailComments: 1,
  httpErrorMessage: '',
  successMessage: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'p3-fail-analysis',
  initialState,
  reducers: {
    SetP3FailAnalysis: (state: State, action: PayloadAction<any>) => {
      state.p3FailAnalysis = action.payload.data
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },

    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setComments: (state: State, action: PayloadAction<any>) => {
      state.comments = action.payload.data
    },
    setSorting: (state: State, action: PayloadAction<any>) => {
      state.sorting = action.payload
    },
    setSortDirection: (state: State, action: PayloadAction<any>) => {
      state.sortDirection = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  setAction,
  SetP3FailAnalysis,
  setIsLoading,
  setP3FailEXCEL,
  setSelectedRow,
  setComments,
  setSorting,
  setSortDirection,
  setSuccessMessage,
  setHttpErrorMessage,
} = actions

export const getAction: any =
  (action: string) => async (dispatch: Dispatch) => {
    dispatch(setAction(action))
    dispatch(
      SetP3FailAnalysis({
        data: [],
      })
    )
  }
export const putUserComment: any =
  (
    system: string,
    failUniqueId: string,
    comment: string,
    createdBy: string,
    createdOn: string
  ) =>
  async (dispatch: Dispatch) => {
    try {
      const { status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/AddComment',
        {
          system,
          failUniqueId,
          comment,
          createdBy,
          createdOn,
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Added Successfully'))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return 400
    }
  }

export const getP3FailAnalysis: any =
  (
    action: string,
    report_Type: string,
    fromDate: string,
    toDate: string,
    ageFilter: string,
    accountNumber: string,
    branch: string,
    entity: string,
    buySell: string,
    cusip: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    searchFilter = '',
    includeFailComments: number,
    type: string,
    moneyFilter: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    dispatch(
      SetP3FailAnalysis({
        data: [],
      })
    )
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetP3FailAnalysisReport',
        {
          report_Type,
          fromDate,
          toDate,
          ageFilter,
          accountNumber,
          branch,
          entity,
          buySell,
          cusip,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          searchFilter,
          includeFailComments,
          type,
          moneyFilter,
        }
      )

      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetP3FailAnalysis({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      dispatch(
        SetP3FailAnalysis({
          data: '',
        })
      )
      if (e.response.status === 401) return e.response.status
    }
  }

export const getComments: any =
  (system: string, failUniqueId: string) => async (dispatch: Dispatch) => {
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetComments?system=${system}&failUniqueId=${failUniqueId}`
      )

      if (status === 200) {
        dispatch(
          setComments({
            data: data,
          })
        )
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setComments({
          data: [],
        })
      )
    }
  }
export const getP3EXCEL: any =
  (
    fileName: string,
    report_Type: string,
    fromDate: string,
    toDate: string,
    ageFilter: string,
    accountNumber: string,
    branch: string,
    entity: string,
    buySell: string,
    cusip: string,
    pageNumber = 0,
    pageSize = 0,
    sortBy = 'SnapShotDate, Age',
    sortDirection = 'ASC',
    searchFilter = '',
    includeFailComments: number,
    type: string,
    moneyFilter: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetP3FailAnalysisExcelReport',
        {
          report_Type,
          fromDate,
          toDate,
          ageFilter,
          accountNumber,
          branch,
          entity,
          buySell,
          cusip,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          searchFilter,
          includeFailComments,
          type,
          moneyFilter,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Downloaded Successfully'))
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        return status
      }
      dispatch(setIsLoading(false))
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      return 400
    }
  }
export const getP3CSV: any =
  (
    fileName: string,
    report_Type: string,
    fromDate: string,
    toDate: string,
    ageFilter: string,
    accountNumber: string,
    branch: string,
    entity: string,
    buySell: string,
    cusip: string,
    pageNumber = 0,
    pageSize = 0,
    sortBy = 'SnapShotDate, Age',
    sortDirection = 'ASC',
    searchFilter = '',
    includeFailComments: number,
    type: string,
    moneyFilter: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetP3FailAnalysisCSVReport',
        {
          report_Type,
          fromDate,
          toDate,
          ageFilter,
          accountNumber,
          branch,
          entity,
          buySell,
          cusip,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          searchFilter,
          includeFailComments,
          type,
          moneyFilter,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        dispatch(setSuccessMessage('Downloaded Successfully'))
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        return status
      }
      dispatch(setIsLoading(false))
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      return 400
    }
  }

export const p3FailAnalysisDataSelector = (store: StoreState) =>
  store.failAnalysis.p3failanalysis.p3FailAnalysis
export const selectedRowDataSelector = (store: StoreState) =>
  store.failAnalysis.p3failanalysis.selectedRow
export const actionSelector = (store: StoreState) =>
  store.failAnalysis.p3failanalysis.action
export const errorSelector = (store: StoreState) =>
  store.failAnalysis.p3failanalysis.error
export const isLoadingSelector = (store: StoreState) =>
  store.failAnalysis.p3failanalysis.isLoading
export const commentSelector = (store: StoreState) =>
  store.failAnalysis.p3failanalysis.comments
export const sortingSelector = (store: StoreState) =>
  store.failAnalysis.p3failanalysis.sorting
export const sortDirectionSelector = (store: StoreState) =>
  store.failAnalysis.p3failanalysis.sortDirection
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.failAnalysis.p3failanalysis.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.failAnalysis.p3failanalysis.successMessage
export default reducer
