import {
  LottieWrapper,
  RootContainerTable,
  TableChartWidgetContainerByFISPhase,
  TitleContainer
} from './styles'
import { useEffect, useState } from 'react'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles';
import Loader from "../../../../assets/lottiefiles/loader.json";
import Lottie from 'lottie-react';
import Label from '../../../label/Label';
import CreateTable from '../createTable';
import { FisPhaseSelector, getFisPhase } from '../../../../store/dash-board/static-data';
import { useDispatch, useSelector } from 'react-redux';
import { formattingMonthYear, formattingMonthYearWithTime } from '../../../../utils/globalFunctions';


const FisPhase3 = (props: any) => {
  const dispatch = useDispatch()
  const data = useSelector(FisPhaseSelector)
  const [startDate] = useState(new Date())
  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)
  let mm: string | number
  let firstDayOfMonth: string | number

  mm = startDate.getMonth() 
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth
  const columns = [
    { Header: 'Month/Year', accessor: 'date' },
    { Header: 'Security Add', accessor: 'securityAdd' },
    { Header: 'Security Update', accessor: 'securityUpdate' },
  ]

  
    const [loading, isLoading] = useState<boolean>(true)
  
    const Table = (props: any) => {
      const formattedData = props.data?.map((r: { date: string; }) => {
        return {
          ...r, date: r.date ? formattingMonthYear(r.date) : null
        }
      });
      return (
        <RootContainerTable>
          <CreateTable data={formattedData} columns={props.columns} />
        </RootContainerTable>
      )
    }
  
    useEffect(() => {
      const getFIS = async () => {
       await  dispatch( getFisPhase() )}
        isLoading(false)
      getFIS();
    }, [])
  
    return (
      <>
      {loading && <PopUpStyleLoader>
        <LottieWrapper>
        <Lottie animationData={Loader} loop={true} />
      </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div>
      </PopUpStyleLoader>}
        <TableChartWidgetContainerByFISPhase>
          <TitleContainer>{props.widgetDescription}</TitleContainer>
          <Table data={data ? data : []} columns={columns} />
          {<span style={{ fontSize: "10px" }}>Data as of : { formattingMonthYearWithTime(new Date())}</span>} 

        </TableChartWidgetContainerByFISPhase>
      </>
    )
  }
  

export default FisPhase3
