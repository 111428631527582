import { useEffect, useRef, useState } from "react"
import { PopUpSideBar } from "../../styles"
import Comments from "../../../../composites/comments/comments"
import { useDispatch } from "react-redux"
import { getViewComments, saveComment } from "../../../../store/trade-break/trade-break"
import { getIntraDayViewComments, intraDaySaveComment } from "../../../../store/trade-break/intraday-trade-breaks"
import { tradeBreaksByMPIDModel } from "../tradeBreaks/ItradebreaksByMpid"

interface CommentPropsModel {
    SetToggleComments?: (value: boolean) => void,
    commentView: any,
    isIntraday?: boolean,
    tradeRowData: tradeBreaksByMPIDModel
    writeExecuteFlag:boolean
}

const CommentTradeByMpid = (props: CommentPropsModel) => {
    console.log("props-->", props)
    const dispatch = useDispatch()
    const { tradeRowData, commentView,writeExecuteFlag } = props
    const [comment, setComment] = useState<string>('')
    const [tableCommmentsData, setTableCommmentsData] = useState<any>([])

    const commentsOnChange = (e: any) => {
        const { value } = e.target
        setComment(value)
    }

    const setCommentTable = async (select_row: any) => {
        if (props.isIntraday) {
            const getCommentsData = await dispatch(
                getIntraDayViewComments({ tradeBreakId: select_row.aggregateId })
            )
            if (getCommentsData && getCommentsData.tradeBreakActionModels) {
                setTableCommmentsData([...getCommentsData.tradeBreakActionModels])
            }
        }
        else {
            const getCommentsData = await dispatch(
                getViewComments({ tradeBreakId: select_row.rpt880Id })
            )
            if (getCommentsData && getCommentsData.tradeBreakActionModels) {
                setTableCommmentsData([...getCommentsData.tradeBreakActionModels])
            }
        }


    }

    useEffect(() => {
        setCommentTable(tradeRowData)
    }, [])

    const commentTrade = async () => {
        if (props.isIntraday) {
            const request = {
                tradeBreakIds: tradeRowData.aggregateId?.toString(),
                category: tradeRowData.category,
                comments: comment,
                userId: localStorage.getItem('AccountName'),
            }
            await dispatch(intraDaySaveComment(request))
        }
        else {
            const request = {
                tradeBreakIds: tradeRowData.rpt880Id?.toString(),
                category: tradeRowData.category,
                comments: comment,
                userId: localStorage.getItem('AccountName'),
            }
            await dispatch(saveComment(request))
        }
        setComment('')
        await setCommentTable(tradeRowData)
    }

    return (
        <PopUpSideBar ref={commentView}>
            <Comments
                tableCommmentsData={tableCommmentsData}
                commentsOnChange={commentsOnChange}
                tradeData={tradeRowData}
                comment={comment}
                commentTrade={commentTrade}
                writeExecuteFlag={writeExecuteFlag}
            />
        </PopUpSideBar>
    )

}

export default CommentTradeByMpid;