/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */

import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig, protectedResources } from './authConfig'
import appConfig from './appConfig'

let failedApiCall = true

const RefreshToken = async () => {
  const msalInstance = new PublicClientApplication(msalConfig)
  const getAcct: any = localStorage.getItem('AcctInfo')
  const newRequestToken = await msalInstance.acquireTokenSilent({
    scopes: protectedResources.api.scopes,
    account: JSON.parse(getAcct),
    forceRefresh: true,
  })
  localStorage.setItem('AcctInfo', JSON.stringify(newRequestToken.account))
  localStorage.setItem('AccessToken', newRequestToken.accessToken)
  return newRequestToken.accessToken
}

let authApiCall: AxiosInstance, authApiUpload: AxiosInstance
const baseURL = appConfig.apiEndPoint

authApiCall = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})
authApiUpload = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

authApiCall.interceptors.response.use(
  (response: AxiosResponse | any) => {
    return response
  },
  async (error: AxiosError) => {
    const getAcctInfo: any = localStorage.getItem('AcctInfo')
    const expiryCheck = JSON.parse(getAcctInfo)
    const prevRequest: any = error?.config
    const expDate = new Date(expiryCheck?.idTokenClaims?.exp * 1000),
      now = new Date()
    const forceRefreshPage = expDate < now

    if (forceRefreshPage) {
      failedApiCall = true
    }

    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true
      if (failedApiCall) {
        failedApiCall = false
        const newAccessToken = await RefreshToken()
        if (newAccessToken) {
          prevRequest.headers.Authorization = `Bearer ${localStorage.getItem(
            'AccessToken'
          )}`
          return authApiCall(prevRequest)
        }
      } else {
        prevRequest.headers.Authorization = `Bearer ${localStorage.getItem(
          'AccessToken'
        )}`
        return authApiCall(prevRequest)
      }
    }
    return Promise.reject(error)
  }
)
authApiUpload.interceptors.response.use(
  (response: AxiosResponse | any) => {
    return response
  },
  async (error: AxiosError) => {
    const getAcctInfo: any = localStorage.getItem('AcctInfo')
    const expiryCheck = JSON.parse(getAcctInfo)
    const prevRequest: any = error?.config
    const expDate = new Date(expiryCheck?.idTokenClaims?.exp * 1000),
      now = new Date()
    const forceRefreshPage = expDate < now

    if (forceRefreshPage) {
      failedApiCall = true
    }

    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true
      if (failedApiCall) {
        failedApiCall = false
        const newAccessToken = await RefreshToken()
        if (newAccessToken) {
          prevRequest.headers.Authorization = `Bearer ${localStorage.getItem(
            'AccessToken'
          )}`
          return authApiCall(prevRequest)
        }
      } else {
        prevRequest.headers.Authorization = `Bearer ${localStorage.getItem(
          'AccessToken'
        )}`
        return authApiCall(prevRequest)
      }
    }
    return Promise.reject(error)
  }
)

/**
 * Intercepts every request made with authApiCall instance
 */
authApiCall.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    // Sets the Authorization header with the token stored in localStorage
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      'AccessToken'
    )}`
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)
authApiUpload.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    // Sets the Authorization header with the token stored in localStorage
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      'AccessToken'
    )}`
    return config
  },
  (error: AxiosError) => {
    // Handle error
    return Promise.reject(error)
  }
)

export { authApiCall, baseURL, authApiUpload }
