import styled from 'styled-components'

export interface Props {
  open: boolean
  maxDate?: Date
  onClickOutside: () => void
  onFocus: () => void
  selected: Date | null
  onChange: any
  minDate?: Date
  endDate?: Date
  startDate?: Date
  selectsStart?: boolean
  selectsEnd?: boolean
  excludeDates?: []
  onSelect?: () => void
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 90%;
  flex-direction: row;
  border: groove;
  border-radius: 4px;
  align-items: center;
  padding: 2px;
  .item {
    border: none;
  }
`
