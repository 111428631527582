import styled from 'styled-components'

export const DashboardContainer = styled.div`
display: grid;
grid-gap: 20px;
max-width: 1366px;
grid-auto-flow: column;
margin: auto;
padding:15px;
//height: 100vh;
// max-height: 750px;
grid-auto-columns: minmax(0, 1fr);
grid-auto-srows: minmax(0, 1fr);
`
export const LottieWrapper = styled.div`
  // position: fixed;
  // top: 70%;
  // left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const LodingTable = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 28px 0 28px 0;
`
