import styled from 'styled-components'

export const RootContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  // background: #e5e5e5;
`
export const StyledTableContainer = styled.div`
  width: 100%;
  padding: 24px;
  background: #ffffff;
  border-top: none;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
  /* width */
  webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }

  /* Handle on hover */
  webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
  & table tbody tr td {
    padding: 4px;
    justify-content: center;
    text-align: center;
    background: #fff;
    line-height: 16px;
  }
  & table thead tr th {
    padding: 4px;
    text-align: center;
    background: #fff;
    line-height: 16px;
  }
  & table tbody tr th {
    :nth-child(2) {
      text-align: right;
    }
  }
  & table tbody tr td {
    justify-content: center;
    text-align: center;
    height: 32px;
    width: 88px !important;
  }
  margin-bottom: 10px;
  width: 100%;
  padding: 24px;
  background: #fcfdff;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`
export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`
export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 24px;
`
export const LottieWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
