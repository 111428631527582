import { ColumnInstance, Row } from 'react-table'
import {
  BuyInExecutionInput,
  BuyInExecutionButton,
} from '../buyInCommonComponents'
import {
  IExecutionUserInputs,
  commonTabProps,
} from '../IBuyInAllocationInterface'
import { DisabledFunction } from '../../utils'

type SetValue = React.Dispatch<React.SetStateAction<IExecutionUserInputs[]>>
interface RegSHOCustomColumnInstance<D extends object = {}>
  extends ColumnInstance<D> {
  objectKey?: string
}
interface ICell<D extends object = {}> {
  column: RegSHOCustomColumnInstance
  row: Row<D>
}

export const getCorrespondentL2SpecificColumn = (
  accessFlag: boolean,
  value: any,
  setValue: SetValue,
  UpdateBuyInExeCution: any,
  DeleteL2BuyInExeCution: any,
  AuditL2BuyInInformation: any,
  commonTabProps: commonTabProps
) => {
  return [
    {
      Header: ' ',
      columns: [
        {
          Header: 'CUSIP',
          accessor: 'cusiP_Number',
        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
        },
        {
          Header: 'Type',
          accessor: 'type',
        },
        {
          Header: 'T+N',
          accessor: 'tn',
        },
        {
          Header: 'BorrowDesc',
          accessor: 'borrowDesc',
        },
        {
          Header: 'ThresholdInd',
          accessor: 'thresholdIndex',
        },
        {
          Header: 'FailQty',
          accessor: 'failQTY',
        },
        {
          Header: 'AcctNbr',
          objectKey: 'acct_Nbr',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'LongShort',
          objectKey: 'longShort',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'TradeNet Quantity',
          objectKey: 'tradeNetQuantity',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'SR_Prior_TD_QTY',
          objectKey: 'sR_Prior_TD_QTY',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'SR_Current_TD_QTY',
          objectKey: 'sR_Current_TD_QTY',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'SR_Net_SD_QTY',
          objectKey: 'sR_Net_SD_QTY',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'CloseOut Status',
          objectKey: 'closeOutStatus',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
      ],
    },
    {
      Header: 'Current Day Activity',
      columns: [
        {
          Header: 'New',
          accessor: 'currentNew',
        },
        {
          Header: 'Made',
          accessor: 'currentMade',
        },
        {
          Header: 'EarlySales ReleaseInd',
          accessor: 'earlySalesReleaseInd',
        },
        {
          Header: 'MISC',
          accessor: 'currentMisc',
        },
        {
          Header: 'NetItemToWorkToday',
          accessor: 'netItemToWorkToday',
        },
      ],
    },
    {
      Header: ' Correspondent Details',
      columns: [
        {
          Header: 'Today204Need ',
          accessor: 'today204Need',
        },
        {
          Header: 'Shares Allocated ',
          objectKey: 'allocateQTY',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  value?.current[row.index]?.createBy,
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Account',
          objectKey: 'allocateAccount',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  value?.current[row.index]?.createBy,
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Description',
          accessor: 'description',
        },
      ],
    },
    {
      Header: '--',
      columns: [
        {
          Header: 'Add Comments',
          objectKey: 'comments',
          Cell: ({ row, column }: ICell) => {
            return (
              <BuyInExecutionInput
                disableFunction={DisabledFunction(
                  value?.current[row.index]?.source,
                  '',
                  value?.current[row.index]?.reportDate,
                  commonTabProps.approveFlag
                )}
                type={'text'}
                parentValue={value}
                objectKey={column.objectKey}
                rowIndex={row.index}
                setParentValue={setValue}
              />
            )
          },
        },
        {
          Header: 'Audit Trail',
          objectKey: 'audit Trail',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={false}
                parentValue={value}
                label={'Audit Trail'}
                tableId={row.index}
                UpdateBuyInExeCution={AuditL2BuyInInformation}
              />
            )
          },
        },
        {
          Header: 'Update',
          objectKey: 'update',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={
                  accessFlag ||
                  DisabledFunction(
                    value?.current[row.index]?.source,
                    '',
                    value?.current[row.index]?.reportDate,
                    commonTabProps.approveFlag
                  )
                }
                label={'Update'}
                tableId={row.index}
                UpdateBuyInExeCution={UpdateBuyInExeCution}
              />
            )
          },
        },
        {
          Header: 'Delete',
          objectKey: 'delete',
          Cell: ({ row }: ICell) => {
            return (
              <BuyInExecutionButton
                disableFunction={
                  accessFlag ||
                  DisabledFunction(
                    value?.current[row.index]?.source,
                    '',
                    value?.current[row.index]?.reportDate,
                    commonTabProps.approveFlag
                  )
                }
                parentValue={value}
                label={'Delete'}
                tableId={row.index}
                UpdateBuyInExeCution={DeleteL2BuyInExeCution}
              />
            )
          },
        },
      ],
    },
  ]
}
