import Button from '../../components/button/Button'
import Divider from '../../components/divider/Divider'
import SvgCancel from '../../components/svg/logo/SvgCancel'
import { WDStyledMainHeading } from '../../components/ui/WDTypography'
import { COLORS } from '../../theme/Colors'
import { SvgButtonWrapper } from '../confirmationModal/styles'
import { PanelContainer, PanelWrapper, TitleWrapper, IconWrapper, BodyWrapper, MessageWrapper, FooterWrapper } from './styles'

interface IButtonDetails {
  buttonText: string
  onClick: () => void
}

interface ISidePanelProps {
  title: string
  messages: string[]
  buttonDetails: IButtonDetails[]
  onCancelClick: () => void
  panelWidth?: string
}

const SidePanel = ({
  title,
  messages,
  onCancelClick,
  buttonDetails,
  panelWidth = '1000px',
}: ISidePanelProps) => {
  return (
    <PanelContainer >
      <PanelWrapper panelWidth={panelWidth}>
        <TitleWrapper>
          <WDStyledMainHeading> {title} </WDStyledMainHeading>
    <IconWrapper>         
            <SvgButtonWrapper
              onClick={() => {
                onCancelClick()
              }}
            >
              <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
            </SvgButtonWrapper>
          </IconWrapper>
        </TitleWrapper>
        <Divider
          thickness={'2px'}
          horizontal={true}
          bgColor={COLORS.Background.Primarytext}
        />
       <BodyWrapper>  
          {messages.map((message, index) => {
            return (
              <MessageWrapper key={index}> 
                {index + 1}
                {'. '}
                {message}
              </MessageWrapper>
            )
          })}
        </BodyWrapper>
        <Divider
          thickness={'2px'}
          horizontal={true}
          bgColor={COLORS.Background.Primarytext}
        />
        <FooterWrapper>
          {buttonDetails.map((buttonDetails, index) => {
            return (
              <Button
                key={index}
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  buttonDetails.onClick()
                }}
                iconHeight={'40px'}
              >
                {buttonDetails.buttonText}
              </Button>
            )
          })}
        </FooterWrapper>
      </PanelWrapper>
    </PanelContainer>
  )
}

export default SidePanel
