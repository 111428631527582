


import {
  RootContainerTable,
  TitleContainer,
  FailToDeliver,
  LottieWrapper,
  FailToReceive,
  ChartContainerByByFailToReceive,
  TitleContainerBuyIn
} from './styles'
import CreateTable from '../createTable'
import { useEffect, useState } from 'react'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles'
import { authApiCall } from "../../../../utils/api";
import BarChart from '../../../bar/barChart';
import { formattingDateWithTime, formattingCurrencyWithOutDecimal, formattingNumber } from '../../../../utils/globalFunctions'
import { configJSONDashboard } from '../../../../utils/config';
import Loader from "../../../../assets/lottiefiles/loader.json";
import Label from '../../../label/Label';
import Lottie from 'lottie-react';
import { useDispatch, useSelector } from 'react-redux';
import { BuyInLoadingSelector, FailDeliverAnalysisReport, FailReceiveAnalysisReport, getFailAnalysisKPIFailToDeliverSummary, getFailAnalysisKPIFailToReceiveSummary, isLoadingSelector } from '../../../../store/dash-board/settlement';
import SelectDropdown from '../../../selectDropdown/SelectDropdown';
import { get } from 'https';
import { useNavigate } from 'react-router-dom';

const columns = [
  { Header: 'Age', accessor: 'age' },
  { Header: 'Fail Count', accessor: 'totalFails' },
  { Header: 'Quantity', accessor: 'totalQuantity' },
  { Header: 'Amount', accessor: 'totalAmount' },
  { Header: 'Market Value', accessor: 'totalMarketValue' }

]

const FailsToDeliver = (props: any) => {
  const dispatch = useDispatch()
  const loading = useSelector(BuyInLoadingSelector)
  const navigate = useNavigate()
  const failToDeliverDetails = useSelector(FailDeliverAnalysisReport)
  const [datas, setDatas] = useState<any>([])
  const [sum, setSum] = useState<any>(0)
  const [legendLabels, setlegendLabels] = useState<any>([])
  const [responseData, seResponseData] = useState<any>([])
  const [sourceOption, setSourceOption] = useState<any>('BETA')
  const sourceValues = [{
    value: "BETA",
    label: "BETA",
  },
  {
    value: "P3",
    label: "P3",
  }]

  const viewDetails = (table: any) => {
    const data = table?.original
    if (data.system === 'beta') {
      navigate('/Beta-FailTracking', {
        state: { data }
      });
    }
    else {
      navigate('/P3-FailTracking', {
        state: { data }
      });
    }

  }

  const Table = (props: any) => {

    const formattedData = props.data.map((r: { age: string; totalQuantity: string; totalAmount: number, quantity: number, totalMarketValue: number }) => {
      return {
        ...r, totalQuantity: r.totalQuantity ? formattingNumber(r.totalQuantity) : null
        , totalAmount: r.totalAmount && formattingCurrencyWithOutDecimal(r.totalAmount),
        totalMarketValue: r.totalMarketValue && formattingCurrencyWithOutDecimal(r.totalMarketValue),
      }
    });

    return (
      <RootContainerTable>
        <CreateTable data={formattedData} columns={props.columns} viewDetails={viewDetails} />
      </RootContainerTable>
    )
  }


  const setData = (responseData: any) => {
    const formatDataChart: {
      label: string,
      data: any,
      barThickness: number,
      backgroundColor: any,
      borderColor: any,
      borderWidth: any,
      stack: string,
    }[] = []
    seResponseData(responseData)
    const legendLabels = responseData?.length && responseData.map((m: { age: string; totalFails: string }) => (m.age) + ': ' + (m.totalFails) + ' Fails')
    const bgColors = ['#BFDBFE', '#60A5FA', '#2563EB', '#1E40AF', '#1E3A8A']
    const borderColors = ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']

    if (responseData && responseData.length) {
      responseData.map((value: any, index: any) => formatDataChart.push({
        label: legendLabels[index],
        data: [value.totalFails],
        barThickness: 170,
        stack: "Stack 0",
        backgroundColor: bgColors[index],
        borderColor: borderColors[index],
        borderWidth: 0,
      }))
    }
    setDatas(formatDataChart)
    setlegendLabels(legendLabels)
    const sum = responseData?.reduce((a: any, b: any) => a + (b['totalFails'] || 0), 0)
    setSum(sum)

  }

  useEffect(() => {
    setData(failToDeliverDetails?.data)
  }, [failToDeliverDetails])

  const getFailsToReceiveDetails = async (source: string) => {
  await dispatch(getFailAnalysisKPIFailToDeliverSummary(source, "2022-01-11", "2022-01-11"));
  }


  useEffect(() => {
    getFailsToReceiveDetails('BETA')
  }, [])


  const clickOnChart = (dataIndex: any) => {
    const indexValue = dataIndex?.[0]?.datasetIndex
    const data = responseData[indexValue]
    if (data.system === 'beta') {
      navigate('/Beta-FailTracking', {
        state: { data }
      });
    }
    else {
      navigate('/P3-FailTracking', {
        state: { data }
      });
    }
  }
  const options = {


    plugins: {
      tooltip: {
        callbacks: {
          title: function () { return null },
          label: function (context: any) {
            const label = context.dataset.label || '';
            return label
          }
        },

      },
      title: {
        display: true,
        text: sum ? `Total Breaks ${sum}` : '',
        align: 'start',
        color: '#0F172A'
      },
      datalabels: {
        display: false
      },
      legend: {
        display: true,
        position: 'bottom',
        fullSize: true,
        align: 'start',
        labels: {
          color: '#0F172A',
          usePointStyle: true,
          pointStyle: "rectRounded",
          boxWidth: 24,
          boxHeight: 24
        }
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 30
      }
    },

    responsive: true,
    onClick: function (e: any, items: any) {
      if (items.length == 0) return; //Clicked outside any bar.

      clickOnChart(items);
    },
    onHover: (event: any, chartElement:any) => {
      event.native.target.style.cursor = chartElement[0]
        ? 'pointer'
        : 'default'
    },
    scales: {
      y: {
        stacked: true,
        display: false,
      },
      x: {
        display: false,
      },
    },
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
  }

  return (
    <>
      {loading && <PopUpStyleLoader>
        <LottieWrapper>
          <Lottie animationData={Loader} loop={true} />
        </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div>
      </PopUpStyleLoader>}
      <FailToDeliver>
        <div style={{ display: 'flex' }}>
          <TitleContainer>{props.widgetDescription}</TitleContainer>
          <div style={{ margin: '10px' }}>
            <SelectDropdown
              borderRadius={'4px'}
              options={sourceValues}
              onChange={(options) => {
                setSourceOption(options);
                getFailsToReceiveDetails(options)
              }}
              value={{
                value: sourceOption,
                label: sourceOption,
              }}
            />
          </div>
        </div>
        <Table data={responseData ? responseData : []} columns={columns} />
        <ChartContainerByByFailToReceive>
          <BarChart
            legendLabels={legendLabels}
            options={options}
            dataValues={datas}
          />
        </ChartContainerByByFailToReceive>
        {<span style={{ fontSize: "10px" }}>Data as of: {failToDeliverDetails.tradeDate ? formattingDateWithTime(failToDeliverDetails.tradeDate) : null}</span>}

      </FailToDeliver>
    </>)

}




export default FailsToDeliver

