import {
  ButtonWrapper,
  StyledContainer,
  StyledRightContainer,
  StyledSelectWrapper,
} from '../SettlementCommonStyles'
import {
  ProppedDatePicker,
  ProppedLabel,
  ProppedTextbox,
} from '../commonComponents'
import Button from '../../../components/button/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  BetaSecLendingSelector,
  getBetaSecLendingDetails,
  getBetaSecLendingExcel,
} from '../../../store/betaSecLending/beta-sec-lending'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import Label from '../../../components/label/Label'
import { COLORS } from '../../../theme/Colors'
export interface IBetaSecLendingSearchFilters {
  fromDate: string
  toDate: string
  accountNumber: string
  securityNumber: string
}
interface BetaSecLendingSearchPanelProps {
  searchParams: IBetaSecLendingSearchFilters
  setsearchParams: React.Dispatch<
    React.SetStateAction<IBetaSecLendingSearchFilters>
  >
  setSearchFlag: React.Dispatch<React.SetStateAction<any>>
}

const BetaSecLendingSearchPanel = ({
  searchParams,
  setsearchParams,
  setSearchFlag,
}: BetaSecLendingSearchPanelProps) => {
  const resetSearchFilters = () => {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    const isoStringDate = date.toISOString()
    const splitDate = isoStringDate.split('T')[0]
    setsearchParams({
      fromDate: splitDate,
      toDate: splitDate,
      accountNumber: '',
      securityNumber: '',
    })
  }
  const dispatch = useDispatch()
  const BetaSecLendingData = useSelector(BetaSecLendingSelector)

  const handleSearch = () => {
    const fetchData = async () => {
      await dispatch(
        getBetaSecLendingDetails('Beta', {
          ...searchParams,
          accountNumber:
            searchParams.accountNumber.trim() === ''
              ? null
              : searchParams.accountNumber,
          securityNumber:
            searchParams.securityNumber.trim() === ''
              ? null
              : searchParams.securityNumber,
        })
      )
    }

    fetchData()
    setSearchFlag((previousValue: any) => !previousValue)
  }

  return (
    <StyledContainer>
      <StyledSelectWrapper>
        <div>
          <ProppedLabel>From Date</ProppedLabel>
          <ProppedDatePicker
            value={searchParams.fromDate}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                fromDate: v,
              }))
            }}
          />
        </div>
        <div>
          <ProppedLabel>To Date</ProppedLabel>
          <ProppedDatePicker
            value={searchParams.toDate}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                toDate: v,
              }))
            }}
          />
        </div>
        <div>
          <ProppedLabel>Account Number</ProppedLabel>
          <ProppedTextbox
            value={searchParams.accountNumber}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                accountNumber: v,
              }))
            }}
            placeHolderText={'ACCOUNT NUMBER'}
          />
        </div>
        <div>
          <ProppedLabel>Security Number</ProppedLabel>
          <ProppedTextbox
            value={searchParams.securityNumber}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                securityNumber: v,
              }))
            }}
            placeHolderText={'SECURITY NUMBER'}
          />
        </div>
        <ButtonWrapper
          onClick={async () => {
            dispatch(
              getBetaSecLendingExcel('BetaSecLendingReport', {
                ...searchParams,
                accountNumber:
                  searchParams.accountNumber.trim() === ''
                    ? null
                    : searchParams.accountNumber,
                securityNumber:
                  searchParams.securityNumber.trim() === ''
                    ? null
                    : searchParams.securityNumber,
              })
            )
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label>Excel</Label>
          </WDStyledButtonText>
        </ButtonWrapper>
      </StyledSelectWrapper>
      <div>
        <StyledRightContainer>
          <Button
            hoverBgColor={'#A7AFBC'}
            hoverTextColor={'#E2E8F0'}
            borderRadius={'4px'}
            hover={true}
            padding={'5px 10px'}
            bgColor={'#E2E8F0'}
            color={'#A7AFBC'}
            type={'Button'}
            margin={'0px 6px'}
            onClick={() => {
              resetSearchFilters()
            }}
            borderColor={'#A7AFBC'}
          >
            Reset
          </Button>
          <Button
            hoverBgColor={'#FFFFFF'}
            hoverTextColor={searchParams.accountNumber ? '#2563EB' : '#A7AFBC'}
            borderRadius={'4px'}
            hover={true}
            padding={'4px 16px'}
            bgColor={'#2563EB'}
            color={'White'}
            type={'Button'}
            margin={'0px 6px'}
            onClick={() => {
              handleSearch()
            }}
            borderColor={'#2563EB'}
          >
            Search
          </Button>
        </StyledRightContainer>
      </div>
    </StyledContainer>
  )
}

export default BetaSecLendingSearchPanel
