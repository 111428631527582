/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnInstance, Row } from 'react-table'
import Divider from '../../../components/divider/Divider'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Table from '../../../components/table/Table'
import { Backgrounds } from '../../../shared/styles'
import { COLORS } from '../../../theme/Colors'
import { IExecutionUserInputs } from '../BuyInAllocationAndExecution/IBuyInAllocationInterface'

import { StyledSelectWrapper } from '../styles'
import {
  CancelButtonWrapper,
  CommentTableContainer,
  MarginLeftAutoSpace,
  PopUpStyleContent,
} from './styles'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  GetRegShoListBuyInsAuditTrail,
  deleteRegShoL1BuyIns,
  getUpdateRegShoL1BuyIns,
} from '../../../store/reg-SHO-Tool/Reg-SHO-Summary'
import { DisabledFunction } from '../utils'
import { EditColumn } from './CusipL1Columns'
import AuditTrailReport from './AuditTrailReport'
type SetValue = React.Dispatch<React.SetStateAction<IExecutionUserInputs[]>>

interface RegSHOCustomColumnInstance<D extends object = {}>
  extends ColumnInstance<D> {
  objectKey?: string
}
interface ICell<D extends object = {}> {
  column: RegSHOCustomColumnInstance
  row: Row<any>
}

const ViewAllocationDetails = (props: any) => {
  const dispatch = useDispatch()
  const [openAuditTrail, setOpenAuditTrail] = useState<boolean>(false)

  const [executionUserInputs, setExecutionUserInputs] = useState<any>(
    props.buyInData
  )
  const [column, setColumn] = useState<any>([])

  const executionUserInputsRef = useRef(executionUserInputs)

  useEffect(() => {
    executionUserInputsRef.current = executionUserInputs
  }, [executionUserInputs])

  const handleBuyInSubmit = async (id: number) => {
    const response = await dispatch(
      getUpdateRegShoL1BuyIns(
        executionUserInputsRef.current[id].regSho_L1BuyIns_Id,
        props.regSHOId,
        props.selectedSummaryTableCUSIP,
        props.source,
        executionUserInputsRef.current[id].buyInType,
        executionUserInputsRef.current[id].nextBuyInDate,
        executionUserInputsRef.current[id].buyInAmount
      )
    )
  }

  const handleDelete = async (id: number) => {
    const response = await dispatch(
      deleteRegShoL1BuyIns(
        executionUserInputsRef.current[id].regSho_L1BuyIns_Id
      )
    )
    if (response) {
      props.setOpenAllocationDetails(false)
      props.handleBuyInView(executionUserInputsRef.current[id].buyInType)
    }
  }

  const handleViewAuditTrail = async (id: number) => {
    const response = await dispatch(
      GetRegShoListBuyInsAuditTrail(
        executionUserInputsRef.current[id].regSho_L1BuyIns_Id
      )
    )
    await setOpenAuditTrail(true)
  }

  useEffect(() => {
    setColumn(
      EditColumn(
        executionUserInputsRef,
        setExecutionUserInputs,
        handleBuyInSubmit,
        handleDelete,
        handleViewAuditTrail,
        DisabledFunction,
        props.pastActionDateDisableFlag
      )
    )
  }, [])

  return (
    <>
      <PopUpStyleContent>
        <StyledSelectWrapper
          style={{
            padding: '15px 15px 0px 15px',
            fontWeight: '600',
            borderRadius: '8px 8px 0px 0px',
          }}
        >
          <>Allocation Details:</>
          <MarginLeftAutoSpace>
            <CancelButtonWrapper
              onClick={() => {
                props.setOpenAllocationDetails(false)
              }}
            >
              <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
            </CancelButtonWrapper>
          </MarginLeftAutoSpace>
          <Divider
            thickness={'1px'}
            horizontal={true}
            bgColor={Backgrounds.Gray110}
          />
        </StyledSelectWrapper>
        <CommentTableContainer>
          <Table
            tableColumns={column}
            tableData={props.buyInData}
            onClick={() => {
              //
            }}
          />
          {props.buyInData.length == 0 && <h3>No allocation details</h3>}
        </CommentTableContainer>
      </PopUpStyleContent>
      {openAuditTrail && (
        <AuditTrailReport
          setOpenAuditTrail={setOpenAuditTrail}
        ></AuditTrailReport>
      )}
    </>
  )
}

export default ViewAllocationDetails
