import {
  RootContainerTable,
  TableHeadingWrapper,
  StyledTableContainer,
  IconsWrapper,
  PopUpStyleLoader,
  StyledSelectWrapper,
  LottieWrapper,
} from './broker/styles'
import { SetStateAction, useEffect, useRef, useState } from 'react'
import { Colors } from '../../../shared/styles'
import FileUpload from './FileUploadModal'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import AllocationTable from './table/Table'
import {
  IExecutionUserInputs,
  commonTabProps,
  setCommonTabProps,
} from './IBuyInAllocationInterface'
import { useDispatch, useSelector } from 'react-redux'
import { AddNewBuyIn } from './AddNewBuyIn'
import {
  LoadingSelector,
  ToastMessageSelector,
  ToastResponseSelector,
  addRegSHOBuyInAllocation,
  auditTrailL2RegSHOData,
  auditTrailL3RegSHOData,
  deleteL2RegSHOData,
  deleteRegSHOBuyInAllocation,
  emailUploadL2RegSHOData,
  setToastMessage,
  setToastResponse,
  updateL2RegSHOData,
  updateRegSHOBuyInAllocation,
} from '../../../store/reg-SHO-Tool/reg-sho-buyInAllocation'
import { SvgPlus } from '../../../components/svg/SvgPlus'
import Label from '../../../components/label/Label'
import { PopupData } from '../../../models/PopUpData'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { ToastWrapper } from '../styles'
import { Toast } from '../../../components/toast/Toast'
import { getAccessFlag } from '../../../utils/globalFunctions'
import { EmailList } from './EmailList'
import AuditTrailL2L3 from './AuditTrailL2L3'
import { AuditTrailColumn } from './AuditTrailColumn'
import { DisabledFunction } from '../utils'

export interface IL2L3BuyInDetails {
  commonTabProps: commonTabProps
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  setCommonTabProps: setCommonTabProps
  setReset: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  specificColumnL2: any
  specificColumnL3: any
  data: any
  dataLevel: string
  buyInData: any
}

const L2L3BuyInDetails = ({
  commonTabProps,
  setActiveTab,
  setCommonTabProps,
  title,
  setReset,
  specificColumnL2,
  specificColumnL3,
  data,
  dataLevel,
  buyInData,
}: IL2L3BuyInDetails) => {
  const dispatch = useDispatch()
  const loader = useSelector(LoadingSelector)
  const ToastMessage = useSelector(ToastMessageSelector)
  const [fileData, setFileData] = useState<File>()
  const [mailPOPUP, setMailPOPUP] = useState<boolean>(false)
  const [mailList, setMailList] = useState<boolean>(false)
  const [showAudit, setShowAudit] = useState<boolean>(false)
  const [rowId, setRowId] = useState<number>(0)
  const [L2L3Columns, setL2L3Columns] = useState<any>([])
  const [dataAuditTrail, setDataAuditTrail] = useState<any>([])
  const writeExecuteFlag = getAccessFlag(['SS-RWX', 'OM-RWX'])
  const [executionUserInputs, setExecutionUserInputs] =
    useState<IExecutionUserInputs[]>(buyInData)
  const executionUserInputsRef = useRef(executionUserInputs)

  const currentDate = new Date()

  const addL3BuyInExeCution = async () => {
    const response = await dispatch(
      updateRegSHOBuyInAllocation({
        regSHOBuyInExec_ID: null,
        allocationId: commonTabProps.allocationId,
        regShoid: commonTabProps.regSHOId,
        source: commonTabProps.source,
        reportDate: commonTabProps.selectedCUSIPReportDate,
        cusip: commonTabProps.selectedSummaryTableCUSIP,
        regSHOBuyInId: commonTabProps.regSHOBuyIn_ID,
        buyInExecTradeRef: null,
        buyInExecTradeDate: null,
        buyInExecSettleDate: null,
        buyInExecCounterParty: null,
        buyInExecTicker: null,
        buyInExecShares: 0,
        buyInExecPrice: 0,
        buyInExecAmount: 0,
        recallDate: null,
        recallShare: 0,
        dateToBuyIn: '',
        buyInNotice: null,
        buyInTicketNumber: null,
        buyInComment: null,
        buyInNoticeDate: '',
        buyInQty: 0,
        offset: null,
        buyInType: commonTabProps.allocationType.split('Buyin')[0],
      })
    )
    response && (await setReset(true))
  }
  const UpdateL3BuyInExeCution = async (id: number) => {
    const {
      regSHOBuyInExec_ID,
      regSHOBuyIn_ID: regSHOBuyInId,
      allocation_ID: allocationId,
      regSHO_Id: regShoId,
      source,
      reportDate,
      cusiP_Number: cusip,
      recallDate,
      recallShare,
    } = data[id] ? data[id] : []
    const response = await dispatch(
      updateRegSHOBuyInAllocation({
        regShoId,
        regSHOBuyInExec_ID,
        regSHOBuyInId,
        allocationId,
        source,
        reportDate,
        cusip,
        recallDate,
        recallShare,
        buyInType: commonTabProps.allocationType.split('Buyin')[0],
        ...executionUserInputsRef.current[id],
      })
    )
    response && (await setReset(true))
  }
  const DeleteL3BuyInExeCution = async (id: number) => {
    const { regSHOBuyInExec_ID } = data[id] ? data[id] : []
    const response = await dispatch(
      deleteRegSHOBuyInAllocation(regSHOBuyInExec_ID)
    )
    response && (await setReset(true))
  }
  const AuditL3BuyInInformation = async (id: number) => {
    const { regSHOBuyInExec_ID } = data[id] ? data[id] : []
    const response = await dispatch(auditTrailL3RegSHOData(regSHOBuyInExec_ID))
    setDataAuditTrail(response)
    await setShowAudit(true)
  }
  const addL2BuyInInformation = async () => {
    const response = await dispatch(
      updateL2RegSHOData({
        regSHOBuyInId: null,
        allocationId: 0,
        regShoid: commonTabProps.regSHOId,
        allocationType: commonTabProps.allocationType.split('Buyin')[0],
        source: commonTabProps.source,
        reportDate: commonTabProps.selectedCUSIPReportDate,
        cusip: commonTabProps.selectedSummaryTableCUSIP,
        dateToBuyIn: '',
        settleDate: commonTabProps.failDate,
        pageName: '',
        buyInNotice: '',
        buyInTicketNumber: '',
        buyInComment: '',
      })
    )
    response && (await setReset(true))
  }
  const UpdateL2BuyInInformation = async (id: number) => {
    const { regSHOBuyIn_ID, allocation_ID, regSHO_Id, source } = data[id]
      ? data[id]
      : []
    const response = await dispatch(
      updateL2RegSHOData({
        regSHOBuyInId: regSHOBuyIn_ID,
        allocationId: allocation_ID,
        regShoid: regSHO_Id,
        source: source,
        allocationType: commonTabProps.allocationType.split('Buyin')[0],
        ...executionUserInputsRef.current[id],
        reportDate: commonTabProps.selectedCUSIPReportDate,
        cusip: commonTabProps.selectedSummaryTableCUSIP,
        pageName: '',
        buyInType: null,
      })
    )
    response && (await setReset(true))
  }
  const DeleteL2BuyInInformation = async (id: number) => {
    const { regSHOBuyIn_ID } = data[id] ? data[id] : []
    const response = await dispatch(deleteL2RegSHOData(regSHOBuyIn_ID))
    response && (await setReset(true))
  }
  const AuditL2BuyInInformation = async (id: number) => {
    const { allocation_ID } = data[id] ? data[id] : []
    const response = await dispatch(auditTrailL2RegSHOData(allocation_ID))
    setDataAuditTrail(response)
    await setShowAudit(true)
  }
  const EmailUploadL2BuyInInformation = async (fileName: string) => {
    const { regSHO_Id, allocation_ID } = data[rowId] ? data[rowId] : []
    const response = await dispatch(
      emailUploadL2RegSHOData({
        fileName,
        regSHO_Id,
        allocation_ID,
        fileData,
        dataLevel,
      })
    )
    setMailPOPUP(false)
  }

  const BuyInInformationL2Column = specificColumnL2(
    !writeExecuteFlag,
    executionUserInputsRef,
    setExecutionUserInputs,
    UpdateL2BuyInInformation,
    DeleteL2BuyInInformation,
    AuditL2BuyInInformation,
    commonTabProps,
    setActiveTab,
    setCommonTabProps
  )

  const BuyInExecutionL3Columns = specificColumnL3(
    !writeExecuteFlag,
    executionUserInputsRef,
    setExecutionUserInputs,
    UpdateL3BuyInExeCution,
    DeleteL3BuyInExeCution,
    AuditL3BuyInInformation,
    commonTabProps
  )
  useEffect(() => {
    executionUserInputsRef.current = executionUserInputs
  }, [executionUserInputs])
  useEffect(() => {
    dataLevel == 'L2'
      ? setL2L3Columns(BuyInInformationL2Column)
      : setL2L3Columns(BuyInExecutionL3Columns)
  }, [])
  return (
    <TableHeadingWrapper>
      {loader && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      <WDCard>
        <CardHeader label={title} />
        <IconsWrapper
          // writeExecuteFlag &&
          // !commonTabProps.approveFlag &&
          // commonTabProps.selectedCUSIPReportDate >=
          //   currentDate?.toISOString()?.split('T')[0]

          disabled={
            writeExecuteFlag &&
            !commonTabProps.approveFlag &&
            commonTabProps.selectedCUSIPReportDate >=
              currentDate?.toISOString()?.split('T')[0]
          }
          onClick={() =>
            writeExecuteFlag &&
            !commonTabProps.approveFlag &&
            commonTabProps.selectedCUSIPReportDate >=
              currentDate?.toISOString()?.split('T')[0] &&
            (dataLevel === 'L2'
              ? addL2BuyInInformation()
              : addL3BuyInExeCution())
          }
        >
          <SvgPlus fillColor={Colors.White}></SvgPlus>
          <Label color={Colors.White}> Add</Label>
        </IconsWrapper>
      </WDCard>
      <StyledTableContainer>
        <RootContainerTable>
          <AllocationTable
            setRowId={setRowId}
            setMailPOPUP={setMailPOPUP}
            setMailList={setMailList}
            tableColumns={L2L3Columns}
            tableData={data}
            disableComponent={DisabledFunction(
              '',
              '',
              commonTabProps.selectedCUSIPReportDate,
              commonTabProps.approveFlag
            )}
            onClick={() => {
              //
            }}
          />
        </RootContainerTable>
        {mailPOPUP && (
          <FileUpload
            ClosePopUp={() => {
              setMailPOPUP(false)
              setFileData(undefined)
            }}
            FileData={(e: File) => {
              setFileData(e)
            }}
            onSubmit={EmailUploadL2BuyInInformation}
          />
        )}
        {mailList && (
          <EmailList
            dataLevel={dataLevel}
            allocationId={data[rowId].allocation_ID}
            commonTabProps={commonTabProps}
            ClosePopUp={() => {
              setMailList(false)
            }}
          />
        )}
        {showAudit && (
          <AuditTrailL2L3
            data={dataAuditTrail}
            setShowAudit={setShowAudit}
            column={AuditTrailColumn}
          />
        )}
      </StyledTableContainer>
      {ToastMessage.length > 0 && (
        <ToastWrapper>
          <Toast
            text={ToastMessage}
            type={'success'}
            openStatusCallback={() => {
              dispatch(setToastMessage(''))
              dispatch(setToastResponse(null))
            }}
          />
        </ToastWrapper>
      )}
    </TableHeadingWrapper>
  )
}

export default L2L3BuyInDetails
