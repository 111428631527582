import { z } from 'zod'

export const ValidateUserMPIDSchema = z.object({
  mPID:z.string().regex(/^[a-zA-Z]+$/,{ message: "Invalid MPID" }),
  firstName: z.string().regex(/^[a-zA-Z]+$/,{ message: "Invalid FirstName" }),
  lastName: z.string().regex(/^[a-zA-Z]+$/,{ message: "Invalid LastName" }),
  company: z.string().regex(/^[a-zA-Z]+$/,{ message: "Invalid Company" }),
  email: z.string().regex(/^[^@]+@[^@]+\.[^@]+$/,{ message: "Invalid Email" }),
  phone:z.string().regex(/^[+(0-9) 0-9-0-9 ]+$/,{ message: "Invalid PhoneNumber" }).optional().or(z.literal('')),
  
}) 