import BetaFailAnalysisDaily from './betaFail/BetaFailDaily'
import BetaFailAnalysisHistory from './betaFail/BetaFailHistory'
import P3FailDaily from './p3Fail/P3FailDaily'
import P3FailHistory from './p3Fail/P3FailHistory'
import { RootContainer } from './styles'

export const BetaFailDaily = () => {
  return (
    <RootContainer>
      <BetaFailAnalysisDaily />
    </RootContainer>
  )
}

export const BetaFailHistory = () => {
  return (
    <RootContainer>
      <BetaFailAnalysisHistory />
    </RootContainer>
  )
}

export const P3FailAnalysisDaily = () => {
  return (
    <RootContainer>
      <P3FailDaily />
    </RootContainer>
  )
}
export const P3FailAnalysisHistory = () => {
  return (
    <RootContainer>
      <P3FailHistory />
    </RootContainer>
  )
}
