import {
  GraphBorderColors,
  GraphColors,
  LottieWrapper,
  TitleContainer,
  TradeReconJobRoot,
} from './styles'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import Label from '../../../label/Label'
import { useDispatch, useSelector } from 'react-redux'
import {
  TradeReconJobSelector,
  getTradeReconJob,
  isLoadingSelector,
} from '../../../../store/dash-board/jamsJob'
import { Colors } from '../../../../shared/styles'
import { useNavigate } from 'react-router-dom'
import PieChart from '../../../pieChart/PieChart'
import DatePicker from '../../../datePicker/DatePicker'
import WidgetHeader from './widgetHeader'

const TradeReconJob = (props: any) => {
  const dispatch = useDispatch()
  const tradeReconJobDetails = useSelector(TradeReconJobSelector)
  const loading = useSelector(isLoadingSelector)
  const [name, setName] = useState<any>([])
  const [quantity, setQuantity] = useState<any>([])
  const [dateValue, setDate] = useState<any>(
    new Date().toISOString().split('T')[0]
  )

  const renderStatus = (statusValue: string) => {
    switch (statusValue) {
      case 'C':
        return 'Completed'
      case 'R':
        return 'Running'
      case 'F':
        return 'Failed'
    }
  }

  useEffect(() => {
    const tempName: any = []
    const tempQuantity: any = []
    tradeReconJobDetails.modulesViewModel &&
      tradeReconJobDetails.modulesViewModel.map((value: any) => {
        tempQuantity.push(value.count)
      })
    tradeReconJobDetails.modulesViewModel &&
      tradeReconJobDetails.modulesViewModel.map((value: any) => {
        if (tempQuantity.some((el: number) => el > 0))
          tempName.push(renderStatus(value.status))
      })

    setName(tempName)
    setQuantity(tempQuantity)
  }, [tradeReconJobDetails.modulesViewModel])

  useEffect(() => {
    const TradeReconJobDetails = async () => {
      await dispatch(getTradeReconJob(dateValue))
    }
    TradeReconJobDetails()
  }, [])

  return (
    <>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <TradeReconJobRoot>
        <WidgetHeader
          setDate={setDate}
          getDetails={getTradeReconJob}
          widgetDescription={props.widgetDescription}
        />
        {name.length ? (
          <PieChart
            legendLabels={name}
            title={''}
            dataValues={quantity}
            bgColors={GraphColors}
            borderColors={GraphBorderColors}
            borderWidth={0}
          />
        ) : (
          <span>No Data Found</span>
        )}
      </TradeReconJobRoot>
    </>
  )
}

export default TradeReconJob
