/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall, authApiUpload } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  data: any
  isLoading: boolean
  successMessage: string
  errorMessage: string
}

const initialState: State = {
  data: [],
  isLoading: false,
  successMessage: '',
  errorMessage: ''
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'reOrg-admin',
  initialState,
  reducers: {
    setCurrentData: (state: State, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
    setErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
  },
})

export const { setCurrentData, setIsLoading,  setErrorMessage,
  setSuccessMessage, } = actions

export const getDataList: any = () => async (dispatch: Dispatch) => {
  try {
    const { data, status } = await authApiCall.get(
      'opsstaticdataapi/AssetServicing/GetReOrgFeeReportTrailerEvents'
    )
    dispatch(setIsLoading(true))
    if (status === 200) {
      dispatch(setCurrentData(data))
      dispatch(setIsLoading(false))
      return 200
    }
  } catch (e: any) {
    handleHttpError(dispatch, e, setErrorMessage)
    dispatch(setIsLoading(false))
     }
  dispatch(setIsLoading(false))
}

export const addReOrgFeeReport: any =
(id:number, eventType: string, eventComment: string,commentPattren:string, isActive: boolean,updatedBy=localStorage.getItem('AccountName'),updatedDate=new Date().toISOString().split('T')[0]) =>
async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { status } = await authApiCall.post(
      'opsstaticdataapi/AssetServicing/AddReOrgFeeReportTrailerEvent',
      {
    
        eventType,
        eventComment,
        commentPattren
      }
    )
      if (status === 200) {
        dispatch(setSuccessMessage('Added Successfully'))
        dispatch(setIsLoading(false))
            }
    } catch (e: any) {
      handleHttpError(dispatch, e, setErrorMessage)
      dispatch(setIsLoading(false))
         }
  }
export const updateList: any =
(id:number, eventType: string, eventComment: string,commentPattren:string, isActive: boolean,updatedBy=localStorage.getItem('AccountName'),updatedDate=new Date().toISOString().split('T')[0]) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/UpdateReOrgFeeReportTrailerEvent',
        {
          
          id,
          eventType,
          eventComment,
          commentPattren,
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Updated Successfully'))
        dispatch(setIsLoading(false))
            }
    } catch (e: any) {
      handleHttpError(dispatch, e, setErrorMessage)
      dispatch(setIsLoading(false))
         }
  }

export const deactivateReorg: any =
(id:number, is_DeActive:boolean) =>
async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { status } = await authApiCall.post(
      'opsstaticdataapi/AssetServicing/Update_IsActive_ReOrgFeeReportTrailerEvent',
      {
        
          id,
          is_DeActive,
        
      }
    )
      if (status === 200) {
        dispatch(setSuccessMessage('Updated Successfully'))
        dispatch(setIsLoading(false))
            }
    } catch (e: any) {
      handleHttpError(dispatch, e, setErrorMessage)
      dispatch(setIsLoading(false))
         }
  }
 
export const currentDataSelector = (store: StoreState) =>
  store.assetService.reOrgFeeAdmin.data
export const LoadingSelector = (store: StoreState) =>
  store.assetService.reOrgFeeAdmin.isLoading
  export const SuccessMessageSelector = (store: StoreState) =>
  store.assetService.reOrgFeeAdmin.successMessage
  export const ErrorMessageSelector = (store: StoreState) =>
  store.assetService.reOrgFeeAdmin.errorMessage


export default reducer
