/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'
import { useState } from 'react'
import {
  CancelButtonWrapper,
  CommentTableContainer,
  LottieWrapper,
  MarginLeftAutoSpace,
  MarginTopSpace,
  PopUpStyleContent,
  PopUpStyleLoader,
  StyledSelectWrapper,
  StyledTableContainer,
  ToastWrapper,
} from './styles'
import {
  DividendReconReportDataSelector,
  commentSelector,
  getComments,
  isLoadingSelector,
} from '../../../store/asset-Servicing/DividendReconReport'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import ReportTable from './table/ReportTable'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import Table from '../../../components/table/Table'
import { Backgrounds } from '../../../shared/styles'
import Lottie from 'lottie-react'
import { Toast } from '../../../components/toast/Toast'
import Loader from '../../../assets/lottiefiles/loader.json'

const HistoryReportDetails = (props: any) => {
  const dispatch = useDispatch()
  const data = useSelector(DividendReconReportDataSelector)
  const Usercomments = useSelector(commentSelector)
  const loading = useSelector(isLoadingSelector)
  const [openComments, setOpenComments] = useState<boolean>(false)

  const savedComments = async (tb: any) => {
    await dispatch(getComments(data[tb].rpt172UniqueId))
    setOpenComments(true)
  }

  const HistoryTableColumns = [
    {
      Header: 'Snapshot Date',
      accessor: 'snapshotdate',
    },
    {
      Header: 'Cusip',
      accessor: 'cusip',
    },
    {
      Header: 'View Comments',
      Cell: (table: any) => {
        return (
          <>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                savedComments(table.cell.row.id)
              }}
            >
              Comments
            </Button>
          </>
        )
      },
    },
    {
      Header: 'Currency',
      accessor: 'currency',
    },
    {
      Header: 'Account Number',
      accessor: 'accountNumber',
    },
    {
      Header: 'Short Name',
      accessor: 'shortName',
    },
    {
      Header: 'Pay Date',
      accessor: 'payDate',
    },
    {
      Header: 'Last Activity Date',
      accessor: 'lastActivityDate',
    },
    {
      Header: 'Div Type',
      accessor: 'divType',
    },
    {
      Header: 'Original',
      accessor: 'original',
    },
    {
      Header: 'Under 7',
      accessor: 'under7',
    },
    {
      Header: 'Over 7',
      accessor: 'over7',
    },
    {
      Header: 'Over 30',
      accessor: 'over30',
    },
    {
      Header: 'Net Amount',
      accessor: 'netAmount',
    },
    {
      Header: 'Grand Total',
      accessor: 'grandTotal',
    },
    {
      Header: 'Age By PayDate',
      accessor: 'ageByPayDate',
    },
    {
      Header: 'Age By LastActivityDate',
      accessor: 'ageByLastActivityDate',
    },
  ]
  return (
    <>
      {openComments && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>View Comment</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenComments(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'CreatedBy',
                  accessor: 'createdBy',
                },
                {
                  Header: 'CreatedOn(EST)',
                  accessor: 'createdOn',
                },
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
              ]}
              tableData={Usercomments ? Usercomments : []}
              onClick={() => {
                //
              }}
            />
            {Usercomments.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      <MarginTopSpace>
        <WDCard>
          <CardHeader
            label={'Dividend open items reconciliation and tracking tool'}
            icon={false}
            amountValue={`Total: ${props.totalRecords}`}
          />
        </WDCard>
      </MarginTopSpace>
      <StyledTableContainer>
        <ReportTable
          columns={HistoryTableColumns}
          data={data}
          reportType={props.reportType}
          startDate={props.startDate}
          endDate={props.startDate}
          cusip={props.cusip}
          accountNumber={props.accountNumber}
          ageByPayDateRange={props.ageByPayDateRange}
          ageByLastActivityDateRange={props.ageByLastActivityDateRange}
          includeFailComments={props.includeFailComments}
          totalRecords={props.totalRecords}
        ></ReportTable>
      </StyledTableContainer>
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
    </>
  )
}

export default HistoryReportDetails
