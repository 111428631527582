import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'
import { Colors } from '../../../shared/styles'


export const RootContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`
export const FilterContainer = styled.div`
  align-items: center;
  width: 97.3vw;
  margin-top: 16px;
  border: 1px solid ${COLORS.Background.StrongBackgound};
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  padding: 16px 25px 4px 25px;
`
export const FirstContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const LeftSpace = styled.div`
  margin-left: 1.5%;
  position: relative;
`
export const FilterLabelWrapper = styled.div`
  margin-bottom: 20px;
  margin-left: 1.5%;
`
export const ApplyResetButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: auto;
`
export const MarginTopSpace = styled.div`
  margin-top: 16px;
`
export const PopUpStyleLoader = styled.div`
  background: #fcfdff;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`
export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${COLORS.Background.Primary};

  > * {
    margin-right: 16px;
  }
`
export const LottieWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
export const StyledTableContainer = styled.div`
  width: 100%;
  padding: 8px 16px 24px 16px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
  overflow: auto;
`
export const TabContainer = styled.div`
  width: 100%;
  margin: 10px 0px;
  overflow-x: visible;
`
export const SearchContainer = styled.div`
  width: 100%;
  overflow-x: visible;
`
export const MarginAutoContainer = styled.div`
  margin: auto;
`
export const ButtonWrapper = styled.button`
  display: flex;
  margin-left: auto;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`

export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`
export const LabelHeader = styled.div`
  order: 1;
  text-align: left;
  color: ${Colors.White};
  order: 0;
  white-space: pre;
`
export const LabelAmount = styled.div`
  order: 2;
  color: ${Colors.White};
  text-align: right;
  cursor: default;
  white-space: pre;
  margin-left: 18%;
`
