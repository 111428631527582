import { COLORS } from '../../theme/Colors'
import { ISvg } from './ISvg'

export const SvgLeftArrowSmall = ({ fillColor = COLORS.UI.Quaternary }: ISvg) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M7.28982 7.9997L10.8298 4.4597C11.0161 4.27234 11.1206 4.01889 11.1206 3.7547C11.1206 3.49052 11.0161 3.23707 10.8298 3.0497C10.7369 2.95598 10.6263 2.88158 10.5044 2.83081C10.3825 2.78004 10.2518 2.75391 10.1198 2.75391C9.98781 2.75391 9.8571 2.78004 9.73524 2.83081C9.61339 2.88158 9.50278 2.95598 9.40982 3.0497L5.16982 7.2897C5.07609 7.38267 5.0017 7.49327 4.95093 7.61513C4.90016 7.73699 4.87402 7.86769 4.87402 7.9997C4.87402 8.13172 4.90016 8.26242 4.95093 8.38428C5.0017 8.50614 5.07609 8.61674 5.16982 8.7097L9.40982 12.9997C9.50326 13.0924 9.61408 13.1657 9.73592 13.2155C9.85775 13.2652 9.98821 13.2905 10.1198 13.2897C10.2514 13.2905 10.3819 13.2652 10.5037 13.2155C10.6256 13.1657 10.7364 13.0924 10.8298 12.9997C11.0161 12.8123 11.1206 12.5589 11.1206 12.2947C11.1206 12.0305 11.0161 11.7771 10.8298 11.5897L7.28982 7.9997Z" 
    fill={fillColor}/>
    </svg>
  )
}

export const SvgLeftArrowMedium = ({
  fillColor = COLORS.UI.Quaternary,
}: ISvg) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path 
    d="M7.28982 7.9997L10.8298 4.4597C11.0161 4.27234 11.1206 4.01889 11.1206 3.7547C11.1206 3.49052 11.0161 3.23707 10.8298 3.0497C10.7369 2.95598 10.6263 2.88158 10.5044 2.83081C10.3825 2.78004 10.2518 2.75391 10.1198 2.75391C9.98781 2.75391 9.8571 2.78004 9.73524 2.83081C9.61339 2.88158 9.50278 2.95598 9.40982 3.0497L5.16982 7.2897C5.07609 7.38267 5.0017 7.49327 4.95093 7.61513C4.90016 7.73699 4.87402 7.86769 4.87402 7.9997C4.87402 8.13172 4.90016 8.26242 4.95093 8.38428C5.0017 8.50614 5.07609 8.61674 5.16982 8.7097L9.40982 12.9997C9.50326 13.0924 9.61408 13.1657 9.73592 13.2155C9.85775 13.2652 9.98821 13.2905 10.1198 13.2897C10.2514 13.2905 10.3819 13.2652 10.5037 13.2155C10.6256 13.1657 10.7364 13.0924 10.8298 12.9997C11.0161 12.8123 11.1206 12.5589 11.1206 12.2947C11.1206 12.0305 11.0161 11.7771 10.8298 11.5897L7.28982 7.9997Z" 
    fill={fillColor}/>
    </svg>
  )
}
