import { useEffect, useState } from 'react'
import Button from '../../../../../components/button/Button'
import Label from '../../../../../components/label/Label'
import {
  WDStyledButtonText,
  WDStyledMainHeading,
} from '../../../../../components/ui/WDTypography'
import { COLORS } from '../../../../../theme/Colors'
import {
  BorderlessButton,
  DeleteContactPopup,
  HeaderContainer,
  PopUpButtonWrapper,
  PopUpStyleContent,
  StyledTableContainer,
} from '../../styles'
import BuyInReceivesAddProduct from './AddProduct'
import { useDispatch, useSelector } from 'react-redux'
import {
  ReceivableProductsSelector,
  deactivateFTRProducts,
  getFTRProducts,
} from '../../../../../store/buyIns/buyInSettlements'
import { getProductColumns } from './ProductColumns'
import Table from '../../../../../components/table/Table'
import { BuyInReceivesProducts } from '../constants'
import ConfirmDeletePopUp from '../../../../../components/popUp/confirmDeletePopUp/ConfirmPopUp'

interface ProductListProps {
  setShowProductList: React.Dispatch<React.SetStateAction<boolean>>
}

const ProductList = ({ setShowProductList }: ProductListProps) => {
  const dispatch = useDispatch()
  const [buttonLabel, setButtonLabel] = useState<boolean>(false)
  const [products, setProducts] = useState<BuyInReceivesProducts>({
    source: 'Beta',
    secType: '',
    description: '',
    assetType: '',
    dTCCSecType: '',
    buyInActiveYN: false,
    sdBusinessDays: undefined,
  })
  const [showAddProduct, setShowAddProduct] = useState<boolean>(false)
  const [deactivate, setDeactivate] = useState<boolean>(false)
  const ProductData = useSelector(ReceivableProductsSelector)

  useEffect(() => {
    async function GetFTRProducts() {
      await dispatch(getFTRProducts())
    }

    GetFTRProducts()
  }, [])

  const DeActivate = async () => {
    await dispatch(deactivateFTRProducts(products))
    await dispatch(getFTRProducts())
    setProducts({
      source: 'Beta',
      secType: '',
      description: '',
      assetType: '',
      dTCCSecType: '',
      buyInActiveYN: false,
      sdBusinessDays: undefined,
    })
    setDeactivate(false)
  }

  return (
    <PopUpStyleContent>
      <HeaderContainer>
        {' '}
        <WDStyledMainHeading> Buy-In Products </WDStyledMainHeading>{' '}
        <PopUpButtonWrapper>
          {' '}
          <BorderlessButton
            onClick={() => {
              setShowProductList(false)
            }}
          >
            {' '}
            <WDStyledButtonText>
              <Label>Cancel</Label>
            </WDStyledButtonText>{' '}
          </BorderlessButton>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            gap={'8px'}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              setButtonLabel(true)
              setShowAddProduct(true)
            }}
          >
            Add New
          </Button>
        </PopUpButtonWrapper>
      </HeaderContainer>
      <div style={{ overflow: 'auto' }}>
        <Table
          tableData={
            ProductData?.data?.secTypeMasterModel?.length > 0
              ? ProductData.data.secTypeMasterModel
              : []
          }
          tableColumns={getProductColumns(
            ProductData?.data?.secTypeMasterModel,
            setShowAddProduct,
            setProducts,
            setDeactivate
          )}
          onClick={() => {
            //
          }}
        />
      </div>
      {showAddProduct && (
        <BuyInReceivesAddProduct
          setShowAddProduct={setShowAddProduct}
          products={products}
          setProducts={setProducts}
          buttonLabel={buttonLabel}
          setButtonLabel={setButtonLabel}
        />
      )}
      {deactivate && (
        <DeleteContactPopup>
          <ConfirmDeletePopUp
            title={'Delete BuyIn'}
            message={'Are you sure want to delete the product'}
            onCancel={() => {
              setDeactivate(false)
              setProducts({
                source: 'Beta',
                secType: '',
                description: '',
                assetType: '',
                dTCCSecType: '',
                buyInActiveYN: false,
                sdBusinessDays: undefined,
              })
            }}
            onDelete={DeActivate}
          />
        </DeleteContactPopup>
      )}
    </PopUpStyleContent>
  )
}

export default ProductList
