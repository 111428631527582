import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Backgrounds, Colors } from '../../shared/styles'

export interface AnchorTagProps {
  title: ReactNode
  href: string
  icon?: ReactNode
  color?: string
  fontSize?: string
  fontWeight?: number
  pointer?: string
  disabled?: boolean
  target?: string
  cursor?: string
  textDecoration?: string
  rel?: string
  lineHeight?: string
  download?: boolean
}

export interface Visited {
  visited: boolean
}

export const StyledAnchor = styled.a<AnchorTagProps>`
  gap: 8px;
  color: ${(props: AnchorTagProps) => props.color};
  pointer-events: ${(props: AnchorTagProps) => props.pointer};
  cursor: ${(props: AnchorTagProps) => props.cursor};
  text-decoration: ${(props: AnchorTagProps) => props.textDecoration};
  font-size: ${(props: AnchorTagProps) => props.fontSize};
  font-weight: ${(props: AnchorTagProps) => props.fontWeight};
  line-height: ${(props: AnchorTagProps) => props.lineHeight};

  &:active {
    /* color: ${Backgrounds.Primary50}; */
    text-decoration: ${(props: AnchorTagProps) => props.textDecoration};
  }
  &:visited {
    /* color: ${Colors.Gray50}; */
    text-decoration: ${(props: AnchorTagProps) => props.textDecoration};
  }
  /* &:link {
    color: ${Backgrounds.Primary70};
  } */
  &:hover {
    /* color: ${Colors.Primary50}; */
    text-decoration: ${(props: AnchorTagProps) => props.textDecoration};
  }
`
