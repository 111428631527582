import React, { useEffect } from "react";
import { protectedResources } from "../../utils/authConfig"
import { useAccount, useMsal } from '@azure/msal-react'
import { ErrorContainer, ErrorHeading, ErrorTextContainer } from "./errorStyles";

const ErrorPage = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  useEffect(() => {
    if (account && inProgress === 'none') {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.api.scopes,
          account: account,
        })

        .then(async(response) => {
          const token = response.accessToken
          const account: any = response.account?.name
          const AccountIcon: any = account.charAt(0)
          localStorage.setItem('AccountIcon', AccountIcon)
          await localStorage.setItem('AccountName', account)
          await localStorage.setItem('AccessToken', token)
        })
    }
  }, [account, inProgress, instance])
  return (
  
    <ErrorContainer>
      <ErrorTextContainer>
        <ErrorHeading>Unauthorized Access</ErrorHeading>

      </ErrorTextContainer>
    </ErrorContainer>
  )
}

export default ErrorPage
