/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from "@reduxjs/toolkit";
import { StoreState } from "../index";
import { authApiCall } from "../../utils/api"

interface State {
  accountManagement:any;
  suspenseManagement: any;
  isLoading: boolean;
  action: string;
}

let initialState: State = {
  accountManagement:{},
  suspenseManagement: {},
  isLoading: true,
  action: "",
};

const { actions, reducer }: Slice = createSlice({

  name: "trade-break",
  initialState,
  reducers: {
    setSuspenseAccount: (state: State, action: PayloadAction<any>) => {
      state.suspenseManagement = action.payload.data;
    },
    setAccountConfig: (state: State, action: PayloadAction<any>) => {
      state.accountManagement = action.payload.data;
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload;
    },
  },
});

export const {
  setAction,
  setSuspenseAccount,
  setIsLoading,
  setAccountConfig
} = actions;


export const getSuspenseAccount: any =
  (
    action: string,
    assetType: any,
    settleDate: any,
    cusip: any,
    acctNbr: any,
    entityCode: any

  ) =>
    async (dispatch: Dispatch) => {
      dispatch(setIsLoading(true));
      try {
        const { data, status } = await authApiCall.post(
          "tradebreakapi/TradeBreak/SuspenseAccountDetails",
          {
            assetType,
            settleDate,
            cusip,
            acctNbr,
            entityCode
          }
        );

        if (status === 200) {
          dispatch(
            setSuspenseAccount({
              data: data,
            })
          );
          dispatch(setAction(action));
          dispatch(setIsLoading(false));
          return data;
        }
      } catch (e: any) {
        dispatch(
          setSuspenseAccount({
            data: [],
          })
        );
        dispatch(setIsLoading(false));
      }
    };

    export const getAccountConfiguration: any = () =>
    async (dispatch: Dispatch) => {
      dispatch(setIsLoading(true));
      try {
        const { data, status } = await authApiCall.get(
          "tradebreakapi/TradeBreak/AccountNumbers"
        );

        if (status === 200) {
          dispatch(
            setAccountConfig({
              data: data,
            })
          );
          dispatch(setIsLoading(false));
          return data;
        }
      } catch (e: any) {
        dispatch(
          setAccountConfig({
            data: [],
          })
        );
        dispatch(setIsLoading(false));
      }
    };


export const suspenseAccountSelector = (store: StoreState) =>
  store.SuspenseAccountManage.SuspenseAccount.suspenseManagement

export const accountConfigSelector = (store: StoreState) =>
  store.SuspenseAccountManage.SuspenseAccount.accountManagement

export const actionSelector = (store: StoreState) =>
  store.SuspenseAccountManage.SuspenseAccount.action;

export const loadingSelector = (store: StoreState) =>
store.SuspenseAccountManage.SuspenseAccount.isLoading;



export default reducer;
