import { ISvg } from './ISvg'

export const SvgEye = ({ fillColor }: ISvg) => {
    return (
        <svg
            width="22px"
            height="22px"
            viewBox="0 0 22 22"
            id="magicoon-Regular"
            xmlns="http://www.w3.org/2000/svg"
        >
           
            <title>{"eye"}</title>
            <g id="eye-Regular">
                <path
                    id="eye-Regular-2"
                    data-name="eye-Regular"
                    className="cls-1"
                    d="M21.229,10.116C19.914,7.925,16.966,4.25,12,4.25S4.086,7.925,2.771,10.116a3.656,3.656,0,0,0,0,3.768C4.086,16.075,7.035,19.75,12,19.75s7.914-3.675,9.229-5.866h0A3.652,3.652,0,0,0,21.229,10.116Zm-1.286,3C18.792,15.031,16.228,18.25,12,18.25s-6.791-3.219-7.943-5.138a2.16,2.16,0,0,1,0-2.224C5.209,8.969,7.773,5.75,12,5.75s6.792,3.219,7.943,5.138A2.155,2.155,0,0,1,19.943,13.112ZM12,7.75A4.25,4.25,0,1,0,16.25,12,4.255,4.255,0,0,0,12,7.75Zm0,7A2.75,2.75,0,1,1,14.75,12,2.752,2.752,0,0,1,12,14.75Z"
                    fill={fillColor}
               />
            </g>
        </svg>
    )
}
