/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux'
import WeekDayCalender from '../../../components/WeekDayCalander/WeekDayCalender'
import Label from '../../../components/label/Label'
import {
  WDStyledButtonText,
  WDStyledTitleHeading,
} from '../../../components/ui/WDTypography'
import {
  CalendarWrapper,
  DownloadButtonWrapper,
  DownloadSearchWrapper,
  FilterContainer,
  RootContainer,
  ToastWrapper,
} from './styles'
import { FormateDateSelector } from '../../../store/dash-board/about'
import { useState } from 'react'
import { addDays, formatISO } from 'date-fns'
import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import BETALoanetReportDetails from './BETALoanetReportDetails'
import {
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  getBetaLoanNetReconCSVReport,
  getBetaLoanNetReconReport,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../store/operations-Control/BETALoanetReconReport'
import { Toast } from '../../../components/toast/Toast'

const BETALoanetRecon = () => {
  const dispatch = useDispatch()
  const ExcludeDate = useSelector(FormateDateSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [Calendar, setCalendar] = useState<boolean>(false)
  const [responseData, setResponseData] = useState<any>([])
  const DefaultDate: string =
    localStorage.getItem('DefaultDate') &&
    localStorage.getItem('DefaultDate') != 'undefined'
      ? JSON.parse(localStorage.getItem('DefaultDate') || '')
      : ''
  const [startDate, setStartDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )

  const handleSearch = async () => {
    const response = await dispatch(
      getBetaLoanNetReconReport(
        'search',
        formatISO(startDate).split('T')[0],
        0,
        20
      )
    )
    if (response) {
      setResponseData(response)
    }
  }

  const DownloadCSV = async () => {
    const response = await dispatch(
      getBetaLoanNetReconCSVReport(
        `BetaLoanNetReconReport__${formatISO(startDate).split('T')[0]}`,
        formatISO(startDate).split('T')[0],
        0,
        0
      )
    )
  }

  return (
    <RootContainer>
      <WDStyledTitleHeading>
        {'Beta Loanet Reconciliation'}
      </WDStyledTitleHeading>
      <FilterContainer>
        <CalendarWrapper>
          <Label>Select Date</Label>
          <WeekDayCalender
            excludeDates={ExcludeDate}
            open={Calendar}
            onSelect={() => setCalendar(false)}
            onFocus={() => setCalendar(true)}
            onClickOutside={() => setCalendar(false)}
            selected={startDate}
            maxDate={addDays(new Date(), -1)}
            onChange={(e: Date) => {
              setStartDate(e)
            }}
          />
        </CalendarWrapper>
        <DownloadSearchWrapper>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              handleSearch()
            }}
          >
            Search
          </Button>
          <DownloadButtonWrapper
            onClick={() => {
              DownloadCSV()
            }}
          >
            <SvgDownloadSmall
              fillColor={`${COLORS.UI.Primary50}`}
            ></SvgDownloadSmall>
            <WDStyledButtonText>
              <Label cursor="pointer">CSV</Label>
            </WDStyledButtonText>
          </DownloadButtonWrapper>
        </DownloadSearchWrapper>
      </FilterContainer>
      <BETALoanetReportDetails
        startDate={formatISO(startDate).split('T')[0]}
      ></BETALoanetReportDetails>
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default BETALoanetRecon
