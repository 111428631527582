import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'

interface State {
  failReportData: []
  isLoading: boolean
  action: string
  status: string
  response: boolean
  sorting: any
  sortDirection: any
}

const initialState: State = {
  failReportData: [],
  isLoading: false,
  action: '',
  status: '',
  response: false,
  sorting: 'AccountNumber',
  sortDirection: 'ASC',
}

const { actions, reducer }: Slice = createSlice({
  name: 'fail-report',
  initialState,
  reducers: {
    SetFailReportData: (state: State, action: PayloadAction<any>) => {
      state.failReportData = action.payload.data
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setSorting: (state: State, action: PayloadAction<any>) => {
      state.sorting = action.payload
    },
    setSortDirection: (state: State, action: PayloadAction<any>) => {
      state.sortDirection = action.payload
    },
    setResponse: (state: State, action: PayloadAction<boolean>) => {
      state.response = action.payload
    },
    setStatus: (state: State, action: PayloadAction<string>) => {
      state.status = action.payload
    },
  },
})

export const {
  SetFailReportData,
  setIsLoading,
  setAction,
  setSorting,
  setSortDirection,
  setStatus,
  setResponse,
} = actions

export const getFailReportData: any =
  (
    action: string,
    source: string,
    reportDate: string,
    fileDate: string,
    symbol: string,
    cusip: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetCNS204FailSettlement',
        {
          source,
          reportDate,
          fileDate,
          symbol,
          cusip,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetFailReportData({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      dispatch(
        SetFailReportData({
          data: '',
        })
      )
      return null
    }
  }

export const putUserComment: any =
  (
    source: string,
    reportDate: string,
    fileDate: string,
    settleDate: string,
    comment: string,
    symbol: string,
    cusip: string,
    accountNumber: string,
    createdBy: string,
    createdOn: string
  ) =>
  async () => {
    try {
      const { status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/AddCNS204FailSettlementDataComments',
        {
          source,
          reportDate,
          fileDate,
          settleDate,
          symbol,
          cusip,
          accountNumber,
          comment,
          createdBy,
          createdOn,
        }
      )

      if (status === 200) {
        return status
      }
    } catch {
      return 400
    }
  }

export const getComments: any =
  (
    source: string,
    reportDate: string,
    fileDate: string,
    settleDate: string,
    symbol: string,
    cusip: string,
    accountNumber: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `/opsfailprocessapi/FailAnalysisReport/GetCNS204FailSettlementDataComments?ReportDate=${reportDate}&FileDate=${fileDate}&SettleDate=${settleDate}&SYMBOL=${symbol}&CUSIP=${cusip}&AccountNumber=${accountNumber}&Source=${source}`
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        return data
      }
    } catch {
      dispatch(setIsLoading(false))
    }
  }

export const getFailReportDataCSV: any =
  (
    fileName: string,
    source: string,
    reportDate: string,
    fileDate: string,
    symbol: string,
    cusip: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetCNS204FailSettlement_CsvDownload',
        {
          source,
          reportDate,
          fileDate,
          symbol,
          cusip,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return 400
    }
  }

export const dataSelector = (store: StoreState) =>
  store.FailReport.FailRepo.failReportData
export const currentDataSelector = (store: StoreState) =>
  store.FailReport.FailRepo.data
export const LoadingSelector = (store: StoreState) =>
  store.FailReport.FailRepo.isLoading
export const actionSelector = (store: StoreState) =>
  store.FailReport.FailRepo.action
export const sortingSelector = (store: StoreState) =>
  store.FailReport.FailRepo.sorting
export const sortDirectionSelector = (store: StoreState) =>
  store.FailReport.FailRepo.sortDirection
export const statusSelector = (store: StoreState) =>
  store.FailReport.FailRepo.status
export const ResponseSelector = (store: StoreState) =>
  store.FailReport.FailRepo.response

export default reducer
