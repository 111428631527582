/* eslint-disable @typescript-eslint/no-explicit-any */
import Table from '../../../components/table/Table'
import { StyledTableContainer } from './styles'

interface ADRFileDataProps {
  cusip: string
  recordDate: string
  depository: string
  status: string
  rate: number
  position: number
  amount: number
  notice: string
  reportDate: string
}

interface ADRFileUploadProps {
  data: any
  totalRecords: number
}

const ADRPastFileUploadColumn = (props: ADRFileUploadProps) => {
  const tableColumns = [
    {
      Header: 'CUSIP',
      accessor: 'cusip',
    },
    {
      Header: 'Record Date',
      accessor: 'recordDate',
    },
    {
      Header: 'Depositary',
      accessor: 'depository',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Rate',
      accessor: 'rate',
    },
    {
      Header: 'Position',
      accessor: 'position',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Notice',
      accessor: 'notice',
    },
    {
      Header: 'ReportDate',
      accessor: 'reportDate',
    },
  ]
  return (
    <StyledTableContainer>
      <Table
        tableColumns={tableColumns}
        tableData={props.data}
        onClick={() => {
          //
        }}
      />
    </StyledTableContainer>
  )
}

export default ADRPastFileUploadColumn
