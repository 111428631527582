import { useTable, useSortBy } from 'react-table'
import { StyledNoData } from './tradeBreaks/styles'

const CreateTable = (props: any) => {
  const { data, columns } = props

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useSortBy
  )

  const getCellProps = (cellInfo: any) => {
    const rowValue = cellInfo?.row?.original
    const coloumnName = cellInfo?.column?.id + 'Color'
    return {
      style: {
        // background: rowValue[coloumnName]
        //   ? rowValue[coloumnName]
        //   : '',
        border: 'none',
        color: rowValue[coloumnName] ? rowValue[coloumnName] : 'black',
      },
    }
    return {}
  }

  return (
    <table>
      <thead>
        {headerGroups.map((headerGroup, key) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={key}>
            {headerGroup.headers.map((column: any, index: any) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={index}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows?.length ? (
          rows.map((row, key) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                key={key}
                onClick={() => {
                  if (props.viewDetails) props.viewDetails(row)
                }}
                style={props.viewDetails ? { cursor: 'pointer' } : {}}
              >
                {row.cells.map((cell, key) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={key}
                      {...getCellProps(cell)}
                    >
                      {cell.value ? cell.render('Cell') : '-'}
                    </td>
                  )
                })}
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={columns.length}>
              <StyledNoData>No Data Found</StyledNoData>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default CreateTable
