import {
  Wrapper,
  StyledCheckbox,
  CheckboxProps,
  StyledLabel,
  StyledButtonLabel,
  ToggleButtonLabel,
} from './styles'

const Checkbox = ({
  title,
  name,
  checkBoxId,
  disabled,
  checked,
  indeterminate,
  onChange,
  defaultChecked,
  required,
  toggleCheckbox,
  buttonCheckbox,
  ToggleButtonPadding,
}: CheckboxProps) => {
  return (
    <Wrapper>
      <StyledCheckbox
        type={'checkbox'}
        id={checkBoxId}
        defaultChecked={defaultChecked}
        disabled={disabled}
        checked={checked}
        indeterminate={indeterminate}
        onChange={onChange}
        name={name}
        required={required}
      />
      {title && !buttonCheckbox && !toggleCheckbox && (
        <StyledLabel htmlFor={checkBoxId}>{title}</StyledLabel>
      )}
      {buttonCheckbox && (
        <StyledButtonLabel htmlFor={checkBoxId} checked={checked}>
          {title}
        </StyledButtonLabel>
      )}
      {toggleCheckbox && (
        <ToggleButtonLabel
          htmlFor={checkBoxId}
          checked={checked}
          ToggleButtonPadding={ToggleButtonPadding}
        >
          {title}
        </ToggleButtonLabel>
      )}
    </Wrapper>
  )
}

export default Checkbox
