/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { StoreState } from '..'

interface State {
  tradeBreakManagement: []
  isLoading: boolean
  action: string
  approveTradeData: []
  isApproveLoading: boolean
  rejectTradeData: []
  isRejectLoading: boolean
  commentsData: []
  isCommentLoading: boolean
  categoryList: []
  emailList: []
}

let initialState: State = {
  tradeBreakManagement: [],
  isLoading: false,
  action: '',
  approveTradeData: [],
  isApproveLoading: false,
  rejectTradeData: [],
  isRejectLoading: false,
  commentsData: [],
  isCommentLoading: false,
  categoryList: [],
  emailList: []
}

const { actions, reducer }: Slice = createSlice({
  name: 'trade-break-page',
  initialState,
  reducers: {
    SetTrade: (state: State, action: PayloadAction<any>) => {
      state.tradeBreakManagement = action.payload.data
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    SetApproveData: (state: State, action: PayloadAction<any>) => {
      state.approveTradeData = action.payload.data
    },
    SetEmailList: (state: State, action: PayloadAction<any>) => {
      state.emailList = action.payload.data
    },
    setIsApproveLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isApproveLoading = action.payload
    },
    SetRejectData: (state: State, action: PayloadAction<any>) => {
      state.rejectTradeData = action.payload.data
    },
    setIsRejectLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isRejectLoading = action.payload
    },
    SetCommentData: (state: State, action: PayloadAction<any>) => {
      state.commentsData = action.payload.data
    },
    setIsCommentLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isCommentLoading = action.payload
    },
    setCategoryData: (state: State, action: PayloadAction<any>) => {
      state.categoryList = action.payload
    },
    SetCommentResponse: (state: State, action: PayloadAction<boolean>) => {
      state.isCommentLoading = action.payload
    },
  },
})

export const {
  setIsLoading,
  SetTrade,
  SetApproveData,
  setIsApproveLoading,
  SetRejectData,
  setIsRejectLoading,
  SetCommentData,
  setIsCommentLoading,
  setCategoryData,
  SetCommentResponse,
  SetEmailList
} = actions

export const getTradeBreakData: any =
  (
    fromDate: string,
    toDate: string,
    settleDate: string,
    entitycode: string,
    cusip: string,
    symbol: string,
    assetType: string,
    mpid: string,
    breakType: string,
    buySell: string,
    forDate: any
  ) =>
    async (dispatch: Dispatch) => {
      dispatch(setIsLoading(true))
      try {
        const { data, status } = await authApiCall.post(
          'tradebreakapi/TradeBreak/GetTradeBreakDetails',
          {
            fromDate,
            toDate,
            settleDate,
            entitycode,
            cusip,
            symbol,
            assetType,
            mpid,
            breakType,
            buySell,
            forDate,
          }
        )
        if (status === 200) {
          dispatch(
            SetTrade({
              data: data,
            })
          )
          dispatch(setIsLoading(false))
          return data
        }
      } catch (e: any) {
        dispatch(setIsLoading(false))
        dispatch(
          SetTrade({
            data: e,
          })
        )
        // return {data:{tradeBreakSummary:{},tradeBreakDetailsModel:[]}};
        return e
      }
    }

export const notifyTrade: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/TradeBreak/SendEmail',
        payload
      )
      if (status === 200) {
        dispatch(
          SetTrade({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      dispatch(
        SetTrade({
          data: e,
        })
      )
      // return {data:{tradeBreakSummary:{},tradeBreakDetailsModel:[]}};
      return e
    }
  }
export const getEmailList: any = (mpidList: any) => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.post(
      'tradebreakapi/TradeBreak/GetMpidEmailList',
      {mpidList}
    )
    if (status === 200) {
      dispatch(
        SetEmailList({
          data: data,
        })
      )
      dispatch(setIsLoading(false))
      return data
    }
  } catch (e: any) {
    dispatch(setIsLoading(false))
    dispatch(
      SetEmailList({
        data: e,
      })
    )
    // return {data:{tradeBreakSummary:{},tradeBreakDetailsModel:[]}};
    return e
  }
}

export const approveDataPost: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setIsApproveLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/TradeBreak/ApproveTradeBreakAction',
        payload
      )

      if (status === 200) {
        dispatch(
          SetApproveData({
            data: data,
          })
        )
        dispatch(setIsApproveLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsApproveLoading(false))
      dispatch(
        SetApproveData({
          data: { actionCountModel: [] },
        })
      )
      return { data: { actionCountModel: [] } }
    }
  }

export const rejectDataPost: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setIsRejectLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/TradeBreak/RejectTradeBreakAction',
        payload
      )

      if (status === 200) {
        dispatch(
          SetRejectData({
            data: data,
          })
        )
        dispatch(setIsRejectLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsRejectLoading(false))
      dispatch(
        SetRejectData({
          data: { actionCountModel: [] },
        })
      )
      return { data: { actionCountModel: [] } }
    }
  }

export const getViewComments: any =
  (tradeBreakId: string) => async (dispatch: Dispatch) => {
    dispatch(setIsCommentLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/TradeBreak/GetTradeBreakComments',
        tradeBreakId
      )

      if (status === 200) {
        dispatch(
          SetCommentData({
            data: data,
          })
        )
        dispatch(setIsCommentLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsCommentLoading(false))
      dispatch(
        SetCommentData({
          data: e,
        })
      )
      return e
    }
  }

export const getCategoryList: any = () => async (dispatch: Dispatch) => {
  dispatch(setIsApproveLoading(true))
  try {
    const { data, status } = await authApiCall.get(
      'tradebreakapi/TradeBreak/GetCategoryList'
    )
    const categorylist = data?.categorylist
    if (status === 200) {
      dispatch(
        setCategoryData({
          data: categorylist,
        })
      )
      dispatch(setIsApproveLoading(false))
      return data
    }
  } catch (e: any) {
    dispatch(setIsApproveLoading(false))
    dispatch(
      setCategoryData({
        data: { actionCountModel: [] },
      })
    )
    return { failed: true }
  }
}

export const saveComment: any =
  (request: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/TradeBreak/UpdateTradeBreakComments',
        request
      )

      if (status === 200) {
        dispatch(
          SetCommentResponse({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      dispatch(
        SetCommentResponse({
          data: { tradeBreakActionModels: [] },
        })
      )
      return {
        data: {
          actionCountModel: [
            {
              actionCount: 0,
            },
          ],
        },
      }
    }
  }

export const gettradeBreakEXCEL: any =
  (
    filename: string,
    fromDate: string,
    toDate: string,
    settleDate: string,
    entitycode: string,
    cusip: string,
    symbol: string,
    assetType: string,
    mpid: string,
    breakType: string,
    buySell: string
  ) =>
    async (dispatch: Dispatch) => {
      dispatch(setIsLoading(true))
      try {
        const { data, status } = await authApiCall.post(
          'tradebreakapi/TradeBreak/ExportToExcelRPT882',
          {
            fromDate,
            toDate,
            settleDate,
            entitycode,
            cusip,
            symbol,
            assetType,
            mpid,
            breakType,
            buySell,
          },
          { responseType: 'blob' }
        )

        if (status === 200) {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}.xlsx`)
          document.body.appendChild(link)
          link.click()
          return status
        }
        dispatch(setIsLoading(false))
      } catch (e: any) {
        dispatch(setIsLoading(false))
        return 400
      }
    }
export const EmailIdListSelector = (store: StoreState) =>
  store.tradeBreakManage.TradeBreakManagement.emailList

export default reducer
