export const RegShoColumnsAudit = (columnId: string) => {
  return [
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Cusip',
      accessor: 'cusip',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'AllocationType',
      accessor: 'allocationType',
    },
    {
      Header: 'ID NO',
      accessor: `${columnId}`,
    },
    {
      Header: 'FieldChanged',
      accessor: 'fieldChanged',
    },
    {
      Header: 'OldValue',
      accessor: 'oldValue',
    },
    {
      Header: 'NewValue',
      accessor: 'newValue',
    },
    {
      Header: 'CreatedBy',
      accessor: 'createdBy',
    },
    {
      Header: 'CreatedOn',
      accessor: 'createdOn',
    },
  ]
}
