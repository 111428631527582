/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */

import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useMountedLayoutEffect,
} from 'react-table'
import {
  RootContainerTable,
  StyledTableHeader,
  PaginationContainer,
  GoToPageWrapper,
  NextPriviousWrapper,
  StyledNoData,
  TableHeader,
  TableContainer,
  StyledCheckBox,
  CheckboxStyle,
  BackwardButtonWrapper,
  ForwardButtonWrapper,
} from './tableStyles'
import Label from '../../../components/label/Label'
import Checkbox from '../../../components/checkbox/Checkbox'
import { Icon } from '../../../components/icon/Icon'
import { Icons } from '../../../shared/GlobalStyle'
import { useEffect, useMemo, useState } from 'react'
import SvgBackwardDoubleChevron from '../../../components/svg/SvgBackwardDoubleChevron'
import SvgForwardDoubleChevron from '../../../components/svg/SvgForwardDoubleChevron'
import { COLORS } from '../../../theme/Colors'
import { OnSelectChange } from '../../../helper/tradeRecon/tradeRecon'
import { OnSelectChangeIntraday } from '../../../helper/tradeRecon/intraday'

const IndeterminateCheckbox = ({ ...rest }) => {
  return (
    <>
      <CheckboxStyle type="checkbox" {...rest} />
    </>
  )
}

const CreateTable = (props: any) => {
  const data = useMemo(() => props.data, [props.data])
  const columns = useMemo(() => props.columns, [props.columns])
  const isTradeDetails = props.showCancel
  const INITIAL_SELECTED_ROW_IDS = {}
  const [pageNumberCall, setPageNumberCall] = useState<any>(1)

  const [pageStatus, setPageStatus] = useState<boolean>(false)

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: INITIAL_SELECTED_ROW_IDS,
      },
      autoResetPageIndex: true,
    },

    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
    setPageSize,
  } = tableInstance

  const actionType = [
    'Action',
    'Comments/Activity',
    'Select',
    'Category',
    'View Details',
    'selectAll',
    'selection',
  ]

  const { pageSize, pageIndex, selectedRowIds } = state

  useMountedLayoutEffect(() => {
    const selectedIds = Object.keys(selectedRowIds)
    const selectedRowsData = selectedIds
      .map((x) => data[x])
      .filter(function (x) {
        return x != null
      })
    if (props.isIntraday) {
      OnSelectChangeIntraday(selectedRowsData, data)
    } else {
      OnSelectChange(selectedRowsData, data)
    }
    props.onSelectedRowChange(selectedRowsData)
  }, [OnSelectChange, selectedRowIds, props.onSelectedRowChange])
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page && page.length ? (
            page.map((row, key) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={key}>
                  {row &&
                    row.cells.map((cell) => {
                      const headerValue =
                        cell.column.id?.toString() ||
                        cell.column.Header?.toString() ||
                        ''
                      const isActionType = actionType.includes(headerValue)
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.value || isTradeDetails || isActionType
                            ? cell.render('Cell')
                            : '-'}
                        </td>
                      )
                    })}
                </tr>
              )
            })
          ) : (
            <tr>
              <td
                colSpan={
                  columns[0] && columns[0].columns
                    ? columns[0].columns.length
                    : columns?.length
                }
              >
                <StyledNoData>No Data Found</StyledNoData>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {page && page.length ? (
        <PaginationContainer>
          <Label>
            Page{' '}
            <strong>
              {pageNumberCall} of {pageOptions.length}
            </strong>{' '}
          </Label>
          <span
            style={{
              marginLeft: '20px',
              marginRight: '20px',
              color: '#E2E8F0',
            }}
          >
            |
          </span>
          <div>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50, 100, 200, 300, 400, 500].map(
                (pageSizeItem) => (
                  <option key={pageSizeItem} value={pageSizeItem}>
                    Show {pageSizeItem}
                  </option>
                )
              )}
            </select>
          </div>
          <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
          <GoToPageWrapper>
            <Label>Go to page:</Label>
            <input
              type="number"
              defaultValue={pageNumberCall}
              max={pageOptions.length}
              min={1}
              onChange={(e) => {
                if (Number(e.target.value) <= pageOptions.length) {
                  e.stopPropagation()
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0
                  gotoPage(pageNumber)
                }
              }}
              style={{ width: '50px' }}
            />
          </GoToPageWrapper>
          <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
          <NextPriviousWrapper>
            <BackwardButtonWrapper
              onClick={() => {
                setPageStatus(true)
                gotoPage(1)
                setPageNumberCall(1)
              }}
            >
              {pageNumberCall !== 1 ? (
                <SvgBackwardDoubleChevron
                  fillColor={`${COLORS.Icons.NeutralIcon}`}
                />
              ) : (
                <SvgBackwardDoubleChevron
                  fillColor={`${COLORS.Background.Neutral40}`}
                />
              )}
            </BackwardButtonWrapper>
            <button
              onClick={() => {
                setPageStatus(true)
                previousPage()
                setPageNumberCall(pageNumberCall - 1)
              }}
              disabled={pageNumberCall === 1}
              style={{
                border: 'none',
                background: '#FCFDFF',
                cursor: 'pointer',
              }}
            >
              Previous
            </button>
            <button
              onClick={() => {
                setPageStatus(true)
                nextPage()
                setPageNumberCall(pageNumberCall + 1)
              }}
              disabled={pageNumberCall === pageCount}
              style={{
                border: 'none',
                background: '#FCFDFF',
                cursor: 'pointer',
              }}
            >
              Next
            </button>
            <ForwardButtonWrapper
              onClick={() => {
                setPageStatus(true)
                gotoPage(pageCount)
                setPageNumberCall(pageCount)
              }}
            >
              {pageNumberCall !== pageCount ? (
                <SvgForwardDoubleChevron
                  fillColor={`${COLORS.Icons.NeutralIcon}`}
                />
              ) : (
                <SvgForwardDoubleChevron
                  fillColor={`${COLORS.Background.Neutral40}`}
                />
              )}
            </ForwardButtonWrapper>
          </NextPriviousWrapper>
        </PaginationContainer>
      ) : null}
    </>
  )
}

const Table = (props: any) => {
  return (
    <RootContainerTable>
      <TableHeader>{props.heading}</TableHeader>

      {props.isFilter ? (
        <StyledTableHeader>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="notAct"
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              title={`NA (Needs Attention) (${props.checkFilterCount.notAct} breaks)`}
              checked={props.checkFilter.notAct}
            />
          </StyledCheckBox>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="approve"
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              title={`Approved (${props.checkFilterCount.approve} breaks)`}
              checked={props.checkFilter.approve}
            />
          </StyledCheckBox>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="reject"
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              title={`Rejected (${props.checkFilterCount.reject} breaks)`}
              checked={props.checkFilter.reject}
            />
          </StyledCheckBox>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="notified"
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              title={`Notified (${props.checkFilterCount.notified} breaks)`}
              checked={props.checkFilter.notified}
            />
          </StyledCheckBox>
        </StyledTableHeader>
      ) : null}

      {props.showCancel ? (
        <StyledTableHeader>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="all"
              title={`Valid (${props.checkFilterCount.all} breaks)`}
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              checked={props.checkFilter.all}
            />
          </StyledCheckBox>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="cancelled"
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              title={`Cancelled (${props.checkFilterCount.cancelled} breaks)`}
              checked={props.checkFilter.cancelled}
            />
          </StyledCheckBox>
        </StyledTableHeader>
      ) : null}
      <TableContainer>
        <CreateTable
          data={props.data}
          columns={props.columns}
          hidePagination={props.hidePagination}
          showCancel={props.showCancel}
          onSelectedRowChange={props.onSelectedRowChange}
          isIntraday={props.isIntraday}
        />
      </TableContainer>
    </RootContainerTable>
  )
}

export default Table
