/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  BackButton,
  DateWrapper,
  FilterContainer,
  FirstContainer,
  LottieWrapper,
  MarginLeftAutoSpace,
  MarginTopSpace,
  MonthPickerLabelWrapper,
  PopUpStyleLoader,
  RootContainer,
  StyledSelectWrapper,
  ToastWrapper,
} from './styles'
import {
  WDStyledButtonText,
  WDStyledHeading,
} from '../../../components/ui/WDTypography'
import { COLORS } from '../../../theme/Colors'
import { SvgLeftArrowMedium } from '../../../components/svg/SvgLeftArrow'
import Label from '../../../components/label/Label'
import Button from '../../../components/button/Button'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import ADRPastFileUploadColumn from './ADRPastFileUploadColumn'
import { useDispatch, useSelector } from 'react-redux'
import {
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  LoadingSelector,
  SetADRPastFileUploadDataHistory,
  TotalCountSelectorADRFee,
  actionSelector,
  getADRPastFileUploadData,
  pastFiledataSelector,
  setAction,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../store/asset-Servicing/ADR-Fee-Upload-Report'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { Toast } from '../../../components/toast/Toast'

const ADRPastFileUpload = () => {
  const navigation = useNavigate()
  const loading = useSelector(LoadingSelector)
  const data = useSelector(pastFiledataSelector)
  const action = useSelector(actionSelector)
  const TotalCount = useSelector(TotalCountSelectorADRFee)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const [resetTable, setResetTable] = useState<boolean>(false)

  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  const lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)

  let mm: string | number
  let firstDayOfMonth: string | number
  let lastDayOfMonth: string | number

  mm = startDate.getMonth() + 1
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  lastDayOfMonth = lastDay.getDate()
  lastDayOfMonth = '' + lastDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth
  const toDate = yyyy + '-' + mm + '-' + lastDayOfMonth

  const current = new Date()

  // const [requestSubmit, setrequestSubmit] = useState<{
  //   status: boolean | null
  //   text: string
  // }>({
  //   status: null,
  //   text: '',
  // })

  const handleSubmit = async () => {
    setResetTable(true)
    const response = await dispatch(
      getADRPastFileUploadData('search', fromDate, toDate)
    )
    if (response !== 200) {
      setResetTable(false)
      // setrequestSubmit({
      //   status: false,
      //   text: 'Sorry, Something went wrong',
      // })
    }
  }
  useEffect(() => {
    setResetTable(false)
    dispatch(SetADRPastFileUploadDataHistory([]))
    dispatch(setAction(''))
  }, [])
  return (
    <RootContainer>
      <WDStyledHeading>{'ADR Past File Upload'}</WDStyledHeading>
      <FilterContainer>
        <FirstContainer>
          <BackButton
            onClick={() => {
              navigation('/ADR-Fees-Upload')
            }}
          >
            <SvgLeftArrowMedium fillColor={`${COLORS.UI.Primary50}`} />
            <WDStyledButtonText>
              <Label margin="0px 20px 0px 0px">Back</Label>
            </WDStyledButtonText>
          </BackButton>
          <MonthPickerLabelWrapper>
            <Label>Select Month</Label>
            <DateWrapper>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                startDate={startDate}
                dateFormat="MM/yyyy"
                maxDate={current}
                showMonthYearPicker
              />
            </DateWrapper>
          </MonthPickerLabelWrapper>
          <MarginLeftAutoSpace>
            <Button
              hoverBgColor={COLORS.Background.Primary70}
              bgColor={COLORS.Background.Primarytext}
              color={COLORS.Background.Primary}
              padding={'4px 16px'}
              borderRadius={'4px'}
              hover={true}
              type={''}
              margin={'10px'}
              activeBgColor={COLORS.Background.Primaryactive}
              borderColor={'transparent'}
              onClick={() => {
                handleSubmit()
              }}
            >
              Submit
            </Button>
          </MarginLeftAutoSpace>
        </FirstContainer>
      </FilterContainer>
      {resetTable && action === 'search' && (
        <>
          <MarginTopSpace>
            <WDCard>
              <CardHeader
                label={'ADR past File upload data'}
                icon={false}
                amountValue={`Total: ${TotalCount.data}`}
              />
            </WDCard>
          </MarginTopSpace>
          <ADRPastFileUploadColumn
            data={data.data ? data.data : []}
            totalRecords={TotalCount.data}
          />
        </>
      )}
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default ADRPastFileUpload
