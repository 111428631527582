import { combineReducers } from '@reduxjs/toolkit'
import regShoHistory from './regSho-History'
import outStanding from './out-standing'
import regShoAudit from './regSho-AuditTrail'

export const Settlement = combineReducers({
  regShoHistory,
  outStanding,
  regShoAudit
})
