import styled from 'styled-components'

export const RootContainer = styled.div`
  margin: -8px 0px 8px 0px;
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 `

export const StyledTableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  padding: 24px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`
export const StyledPanelHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  /* background: #fcfdff; */
  /* color: #192638; */
  // color: #ffffff;
  // background: #1f5eb7;

  /* padding: 32px 32px 0px 32px; */
  padding: 16px 24px 16px;
  border-radius: 8px 8px 0px 0px;
`
export const StyledPanelHeadTitle = styled.div`
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 48px;
text-align: left;
color: #0F172A;
`

export const PopUpStyleLoader = styled.div`
  background: #f0f2f5;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const LottieWrapper = styled.div`
  // position: fixed;
  // top: 70%;
  // left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 24px 24px 26px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  > * {
    margin-right: 16px;
  }
`

export const StyledButtonWrapper = styled.div`
display: flex;
flex-direction: row;
margin-top: 28px;
justify-content: space-between;
margin-left: auto;
`
export const StyledFilterPanel = styled.div`

background: #FFFFFF;
/* Elevation/Elevation-low */
width: 100%;
box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
border-radius: 8px;
`