import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,

} from 'chart.js'

import { Line } from 'react-chartjs-2'
import { LineChartProps } from './styles'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
  )

const LineChart = ({
  legendLabels,
  title,
  dataValues,
  bgColors,
  borderColors,
  borderWidth,
}: LineChartProps) => {
  const data: ChartData<'line'> = {
    labels: legendLabels,
    datasets: dataValues,
  }

  return <Line data={data} options={ {
    responsive:true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels:{
          color:'#0F172A',
          usePointStyle:true,
          pointStyle:"rectRounded",
        }
      },
      datalabels: {
        display: false
      },
    },
  }
} />
}

export default LineChart
