import Lottie from 'lottie-react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/button/Button'
import { Textarea } from '../../../components/textArea/Textarea'
import { Toast } from '../../../components/toast/Toast'
import {
  getComments,
  putUserComment,
} from '../../../store/failReport-204/Fail-Report-204'
import { COLORS } from '../../../theme/Colors'
import FailReport204Table from './failReport204Table/FailReport204Table'
// import { data } from './Json'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  CancelButtonWrapper,
  CommentTableContainer,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopupCard,
  PopUpStyleContent,
  PopUpStyleLoader,
  StyledSelectWrapper,
  StyledTableContainer,
  ToastWrapper,
} from './styles'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import { Backgrounds } from '../../../shared/styles'
import Table from '../../../components/table/Table'

const FailReport204TableColumn = (props: any) => {
  const dispatch = useDispatch()
  const [comment, setComments] = useState<boolean>(false)
  const [input, setInput] = useState({
    commentRow: 0,
    commentDate: new Date().toISOString(),
  })
  const [inputComment, setInputComment] = useState<any>([])
  const [popUpLoader, setPopUpLoader] = useState<boolean>(false)
  const [userComment, setUserComments] = useState<any>([])
  const [openComments, setOpenComments] = useState<boolean>(false)

  const [requestSubmit, setrequestSubmit] = useState<{
    status: boolean | null
    text: string
  }>({
    status: null,
    text: '',
  })

  const data = props.data

  const savedComments = async (tb: any) => {
    setPopUpLoader(true)
    const response = await dispatch(
      getComments(
        'P3',
        data[tb].reportDate,
        data[tb].fileDate,
        data[tb].settleDate,
        data[tb].symbol.length > 0 ?   data[tb].symbol :"",
        data[tb].cusip.replace(/'/g, ''),
        data[tb].accountNumber
      )
    )
    setUserComments(response)
    await setOpenComments(true)
    setPopUpLoader(false)
  }

  const newUserComment = async (
    type: string,
    reportDate: string,
    fileDate: string,
    settleDate: string,
    comment: string,
    symbol: string,
    cusip: string,
    accNumber: string,
    createdBy: any,
    rowId: number
  ) => {
    setPopUpLoader(true)
    const response = await dispatch(
      putUserComment(
        'P3',
        reportDate,
        fileDate,
        settleDate,
        comment,
        symbol,
        cusip.replace(/'/g, ''),
        accNumber,
        createdBy,
        input.commentDate
      )
    )
    inputComment[rowId] = '  '
    setInputComment({ ...inputComment })
    setPopUpLoader(false)

    if (response === 200) {
      setrequestSubmit({
        status: true,
        text: 'Comment added',
      })
    }
    if (response === 400) {
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }

  const tableColumn = [
    {
      Header: 'Report Date',
      accessor: 'reportDate',
    },
    {
      Header: 'File204 Date',
      accessor: 'fileDate',
    },
    {
      Header: 'Settle Date',
      accessor: 'settleDate',
    },
    {
      Header: 'Add Note',
      Cell: (table: any) => {
        return (
          <>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                setComments(!comment)
                setInput({
                  ...input,
                  commentRow: table.cell.row.id,
                })
              }}
            >
              Add Notes
            </Button>
            {comment && table.cell.row.id === input.commentRow && (
              <PopupCard>
                <Textarea
                  onChange={(e: any) => {
                    inputComment[table.cell.row.id] = e.target.value
                    setInputComment(inputComment)
                  }}
                  height="365px"
                  placeholder={'Enter Comment'}
                ></Textarea>

                <StyledSelectWrapper
                  style={{
                    padding: '2px 0px 0px 0px',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    type={'button'}
                    color={COLORS.Background.Primarytext}
                    bgColor={'transparent'}
                    onClick={() => {
                      setComments(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    hoverBgColor={COLORS.Background.Primary70}
                    bgColor={COLORS.Background.Primarytext}
                    color={COLORS.Background.Primary}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    hover={true}
                    type={''}
                    activeBgColor={COLORS.Background.Primaryactive}
                    borderColor={'transparent'}
                    onClick={async () => {
                      await newUserComment(
                        'P3',
                        data[table.cell.row.id].reportDate,
                        data[table.cell.row.id].fileDate,
                        data[table.cell.row.id].settleDate,
                        inputComment[table.cell.row.id],
                        data[table.cell.row.id].symbol,
                        data[table.cell.row.id].cusip,
                        data[table.cell.row.id].accountNumber,
                        localStorage.getItem('AccountName'),
                        table.cell.row.id
                      )
                      setComments(false)
                    }}
                  >
                    Submit
                  </Button>
                </StyledSelectWrapper>
              </PopupCard>
            )}
          </>
        )
      },
    },
    {
      Header: 'View Comments',
      Cell: (table: any) => {
        return (
          <>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                savedComments(table.cell.row.id)
              }}
            >
              Comments
            </Button>
          </>
        )
      },
    },
    {
      Header: 'SYMBOL',
      accessor: 'symbol',
    },
    {
      Header: 'CUSIP',
      accessor: (d: any) => {
        if (d.cusip) return d.cusip.replace(/'/g, '')
      },
    },
    {
      Header: 'Security Type',
      accessor: 'securityType',
    },
    {
      Header: 'T + N',
      accessor: 'tn',
    },
    {
      Header: 'Borrow Desc',
      accessor: 'borrowDesc',
    },
    {
      Header: 'Status 932222220',
      accessor: 'status932222220',
    },
    {
      Header: 'Qty 932222220_204',
      accessor: 'qty932222220_204',
    },
    {
      Header: 'Qty 932222220_CURRENT',
      accessor: 'qty932222220_CURRENT',
    },
    {
      Header: 'Qty 9300OP09_Pledge',
      accessor: 'qty9300OP09_Pledge',
    },
    {
      Header: 'Account Number',
      accessor: 'accountNumber',
    },
    {
      Header: 'Account ShortName',
      accessor: 'accountShortName',
    },
    {
      Header: 'Account Type',
      accessor: 'accountType',
    },
    {
      Header: 'SODCNS FailDayQty',
      accessor: 'soD_CNS_FailDayQty',
    },
    {
      Header: 'QtyDiff 204',
      accessor: 'qtyDiff_204',
    },
    {
      Header: 'Fail Amount',
      accessor: 'failAmount',
    },
    {
      Header: 'Settle Qty',
      accessor: 'settleQty',
    },
    {
      Header: 'Is Warrant',
      accessor: 'isWarrant',
    },
    {
      Header: 'Threshold Index',
      accessor: 'thresholdIndex',
    },
  ]
  return (
    <StyledTableContainer>
      {openComments && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>View Comment</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenComments(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
                {
                  Header: 'Created by',
                  accessor: 'createdBy',
                },
                {
                  Header: 'Create on(EST)',
                  accessor: 'createdOn',
                },
              ]}
              tableData={userComment}
              onClick={() => {
                //
              }}
            />
            {userComment.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      <FailReport204Table
        columns={tableColumn}
        data={data}
        reportDate={props.reportDate}
        fileDate={props.fileDate}
        symbol={props.symbol}
        cusip={props.cusip}
        totalRecords={props.totalRecords}
      />

      {popUpLoader && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {requestSubmit.status !== null && (
        <ToastWrapper>
          <Toast
            text={requestSubmit.text}
            type={requestSubmit.status ? 'success' : 'danger'}
            openStatusCallback={(status: boolean) =>
              setrequestSubmit({
                status: status ? status : null,
                text: '',
              })
            }
          />
        </ToastWrapper>
      )}
    </StyledTableContainer>
  )
}

export default FailReport204TableColumn
