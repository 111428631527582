import {
  LottieWrapper,
  TitleContainer,
  SummaryCardContainerTradeBreaksAlerts,
  RootContainerTable,
} from './styles'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { SetStateAction, useEffect, useMemo, useState } from 'react'
import Lottie from 'lottie-react'
import Label from '../../../label/Label'
import DoughnutChart from '../../../doughnutChart/DoughnutChart'
import { Colors } from '../../../../shared/styles'
import { authApiCall } from '../../../../utils/api'
import MultipleDropDown from './multipleDropDown'
import { SelectColumnFilter } from '../CommonComponents'
import { TableComponent } from '../TableWithDropDown'

const ErrorBreakDown = (props: { selectedDate: string }) => {
  const [data, setData] = useState<any>([])
  const [name, setName] = useState<any>([])
  const [quantity, setQuantity] = useState<any>([])
  const [source, setSource] = useState<string>('Beta')
  const [allLOB, setAllLOB] = useState<boolean>(false)
  const [showTable, setShowTable] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const columns = useMemo(
    () => [
      {
        Header: 'LOB',
        accessor: 'lob',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'RecordType',
        accessor: 'recordType',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'ErrorCount',
        accessor: 'errorCount',
        Filter: SelectColumnFilter,
      },
    ],
    []
  )
  useEffect(() => {
    setLoading(true)
    const getLOB = async () => {
      setLoading(true)
      setShowTable(false)
      try {
        const { data, status } = await authApiCall.get(
          `opsdashboardapi/OpsDashboard/Get_CAIS_ErrorBreakDownByLOB?source=${source}&reportDate=${props?.selectedDate}&showAll=${allLOB}`
        )
        if (status === 200) {
          setData(data)
          setLoading(false)
          const tempName: any = []
          const tempQuantity: any = []
          data &&
            data.slice(0, 11).map((value: any) => {
              tempName.push(value.recordType)
            })
          data &&
            data.slice(0, 11).map((value: any) => {
              tempQuantity.push(value?.errorCount)
            })
          setName(tempName)
          setQuantity(tempQuantity)
          setShowTable(true)
        }
      } catch (e) {
        setShowTable(true)
        setLoading(false)
      }
    }
    getLOB()
  }, [source, allLOB, props?.selectedDate])
  return (
    <>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <SummaryCardContainerTradeBreaksAlerts>
        <div style={{ display: 'flex' }}>
          <TitleContainer>{'Error BreakDown By LOB'}</TitleContainer>
        </div>
        <MultipleDropDown
          dropDowns={[
            {
              label: 'Source',
              attribute: 'Source',
              element: 'select',
              options: ['Beta', 'P3'],
              onChange: async (e: {
                target: { value: SetStateAction<string> }
              }) => {
                setSource(e.target.value)
              },
            },
            {
              label: 'Show ALL',
              attribute: 'showAll',
              type: 'checkbox',
              element: 'input',
              onChange: async (e: {
                target: { value: SetStateAction<string> }
              }) => {
                setAllLOB(!allLOB)
              },
            },
          ]}
        />
        {showTable && (
          <RootContainerTable minHeight={'130px'} fontSize={'11px'}>
            <div style={{ width: '100%', overflow: 'auto' }}>
              <TableComponent
                data={data ? data.slice(0, 11) : []}
                columns={columns}
              />
            </div>
          </RootContainerTable>
        )}
        {name.length ? (
          <DoughnutChart
            legendLabels={name}
            title={'chart'}
            dataValues={quantity}
            bgColors={[
              '#7CB9E8',
              '#72A0C1',
              '#00308F',
              '#003f5c',
              '#2f4b7c',
              '#665191',
              '#a05195',
              '#d45087',
              '#f95d6a',
              '#ff7c43',
              '#ffa600',
              '#6F00FF',
              '#720e9e',
            ]}
            borderColors={[
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
              Colors.White,
            ]}
            borderWidth={0}
            onClick={() => {
              //
            }}
          />
        ) : (
          <span>No Data Found</span>
        )}
      </SummaryCardContainerTradeBreaksAlerts>
    </>
  )
}

export default ErrorBreakDown
