import Button from '../../components/button/Button'
import Label from '../../components/label/Label'
import { useState } from 'react'
import { Textarea } from '../../components/textArea/Textarea'
import { Icons } from '../../shared/GlobalStyle'
import { Colors } from '../../shared/styles'
import {
  CommentRootContainer,
  CommentHeader,
  StyledSortContainer,
  CommentTile,
  DataContainer,
  CommentContent,
  AvatarStyle,
  EditorContainer,
  ReactQuillStyles,
} from './commentStyles'
import { formattingDate } from '../../utils/globalFunctions'
import ReactQuill from 'react-quill'
import { BuyInReceiveExceptionModel } from '../../pages/settlement/buyInSettlements/IbuyinReceive'
import { CommentsArray, commentsModel } from './Icomments'
import { tradeBreaksByMPIDModel } from '../../pages/tradeRecon/tradeBreaksbyMPID/tradeBreaks/ItradebreaksByMpid'

interface CommentProps {
  tradeData?: tradeBreaksByMPIDModel
  ftrRowData?: BuyInReceiveExceptionModel
  tableCommmentsData: CommentsArray
  comment: string
  commentsOnChange: (e: any) => void
  commentTrade: () => void
  writeExecuteFlag?: boolean
}

const Comments = (props: CommentProps) => {
  const [isButtonDisabled, setButton] = useState<boolean>(false)
  const modules = {
    toolbar: [],
  }
  const renderStatus = (status: string) => {
    switch (status) {
      case 'NA':
        return 'Needs Attention'
      case 'APR':
        return 'Approved'
      case 'REJ':
        return 'Rejected'
      case 'NTF':
        return 'Notified'
      case 'F':
        return 'Fixed'
      case 'P':
        return 'Pending'
      case 'E':
        return 'Exception'
      default:
        return 'Needs Attention'
    }
  }

  const renderBackgroundColor = (status: string) => {
    switch (status) {
      case 'REJ':
        return '#EBF7E6'
      case 'F':
        return '#EBF7E6'
      case 'P':
        return '#DBEAFE'
      case 'E':
        return '#ffcccc'
      default:
        return '#DBEAFE'
    }
  }

  const renderColor = (status: string) => {
    switch (status) {
      case 'REJ':
        return '#2E844A'
      case 'P':
        return '#2563EB'
      case 'F':
        return '#2E844A'
      case 'E':
        return '#ff3333'
      default:
        return '#2563EB'
    }
  }

  return (
    <CommentRootContainer>
      <CommentHeader>
        <Button
          type={'button'}
          color={'#000000'}
          bgColor={'#FFFF'}
          borderColor={'#FFFF'}
          padding={'4px 8px'}
        >
          Comments/Activity
        </Button>

        <StyledSortContainer>
          <Button
            type={'button'}
            color={'#0F172A'}
            bgColor={'#ffff'}
            borderColor={'#ffff'}
            iconHeight={'20px'}
            suffixedIcon={Icons.ArrowDown}
          >
            Newest First
          </Button>
        </StyledSortContainer>
      </CommentHeader>
      <DataContainer>
        <CommentTile>
          {props?.tradeData?.tradeDate ? (
            <CommentContent>
              <Label padding={'6px 0px'}>
                {props.tradeData.tradeDate
                  ? `Received On ${new Date(
                      props.tradeData.tradeDate
                    ).toLocaleTimeString('en-US')},${formattingDate(
                      props.tradeData.tradeDate
                    )}`
                  : null}
              </Label>
              <Button
                type={'button'}
                color={'#C2410C'}
                bgColor={'#FFF7ED'}
                borderColor={'#FFF7ED'}
                padding={'4px 8px'}
                borderRadius={'1000px'}
                margin={'4px'}
              >
                Break
              </Button>
            </CommentContent>
          ) : null}
          {props?.ftrRowData?.jobRunTimeStamp ? (
            <CommentContent>
              <Label padding={'6px 0px'}>
                {props.ftrRowData.jobRunTimeStamp
                  ? `Received On ${new Date(
                      props.ftrRowData.jobRunTimeStamp
                    ).toLocaleTimeString('en-US')},${formattingDate(
                      props.ftrRowData.jobRunTimeStamp
                    )}`
                  : null}
              </Label>
              <Button
                type={'button'}
                color={'#C2410C'}
                bgColor={'#FFF7ED'}
                borderColor={'#FFF7ED'}
                padding={'4px 8px'}
                borderRadius={'1000px'}
                margin={'4px'}
              >
                Exception
              </Button>
            </CommentContent>
          ) : null}
        </CommentTile>
        {props.tableCommmentsData && props.tableCommmentsData.length
          ? props.tableCommmentsData.map(
              (element: commentsModel, key: number) => {
                return (
                  <CommentTile key={key}>
                    <CommentContent>
                      <AvatarStyle>
                        {element.userId?.charAt(0).toUpperCase()}
                      </AvatarStyle>
                      <Label padding={'6px 0px'}>{element.userId}</Label>
                      <Label padding={'6px 0px'} minWidth={'200px'}>
                        {element.utcModifiedOn
                          ? new Date(element.utcModifiedOn).toLocaleTimeString(
                              'en-US'
                            )
                          : null}
                        ,{formattingDate(element.utcModifiedOn as string)}
                      </Label>
                      {element.approvalStatus || element.status ? (
                        <Button
                          type={'button'}
                          color={renderColor(
                            (element.approvalStatus as string)
                              ? (element.approvalStatus as string)
                              : (element.status as string)
                          )}
                          bgColor={renderBackgroundColor(
                            (element.approvalStatus as string)
                              ? (element.approvalStatus as string)
                              : (element.status as string)
                          )}
                          borderColor={renderBackgroundColor(
                            (element.approvalStatus as string)
                              ? (element.approvalStatus as string)
                              : (element.status as string)
                          )}
                          padding={'4px 8px'}
                          borderRadius={'1000px'}
                          margin={'4px'}
                        >
                          {renderStatus(
                            (element.workFlowStatus as string)
                              ? (element.workFlowStatus as string)
                              : (element.status as string)
                          )}
                        </Button>
                      ) : null}
                      <ReactQuillStyles>
                        <ReactQuill
                          readOnly
                          modules={modules}
                          value={element.comments}
                          onChange={() => {
                            null
                          }}
                        />
                      </ReactQuillStyles>
                    </CommentContent>
                  </CommentTile>
                )
              }
            )
          : null}
      </DataContainer>
      <EditorContainer>
        <Textarea
          {...props}
          name={'comments'}
          value={props.comment}
          onChange={(e) => props.commentsOnChange(e)}
          placeholder={'Comments....'}
        ></Textarea>
        <div>
          <Button
            hoverBgColor={Colors.White}
            hoverTextColor={Colors.PrimaryText}
            borderColor={Colors.PrimaryText}
            borderRadius={'4px'}
            hover={true}
            padding={'5px 10px'}
            color={Colors.White}
            bgColor={Colors.PrimaryText}
            type={'Button'}
            fontWeight={'400'}
            onClick={async () => {
              setButton(true)
              await props.commentTrade()
              setButton(false)
            }}
            margin={'10px'}
            disabled={isButtonDisabled || !props.writeExecuteFlag}
          >
            Comment
          </Button>
        </div>
      </EditorContainer>
    </CommentRootContainer>
  )
}
export default Comments
