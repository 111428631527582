/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux'
import Divider from '../../../components/divider/Divider'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Table from '../../../components/table/Table'
import { Backgrounds } from '../../../shared/styles'
import { COLORS } from '../../../theme/Colors'
import {
  AuditTrailPopUpStyle,
  CancelButtonWrapper,
  CommentTableContainer,
  MarginLeftAutoSpace,
  StyledSelectContainer,
} from './styles'
import { auditReportDataSelector } from '../../../store/reg-SHO-Tool/Reg-SHO-Summary'

const AuditTrailReport = (props: any) => {
  const auditReportData = useSelector(auditReportDataSelector)
  return (
    <AuditTrailPopUpStyle>
      <StyledSelectContainer
        style={{
          padding: '15px 15px 0px 15px',
          fontWeight: '600',
          borderRadius: '8px 8px 0px 0px',
        }}
      >
        <>Audit Trail</>
        <MarginLeftAutoSpace>
          <CancelButtonWrapper
            onClick={() => {
              props.setOpenAuditTrail(false)
            }}
          >
            <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
          </CancelButtonWrapper>
        </MarginLeftAutoSpace>
        <Divider
          thickness={'1px'}
          horizontal={true}
          bgColor={Backgrounds.Gray110}
        />
      </StyledSelectContainer>
      <CommentTableContainer>
        <Table
          tableColumns={[
            {
              Header: 'Field Changed',
              accessor: 'fieldChanged',
            },
            {
              Header: 'Old Value',
              accessor: 'oldValue',
            },
            {
              Header: 'New Value',
              accessor: 'newValue',
            },
            {
              Header: 'Created By',
              accessor: 'createdBy',
            },
            {
              Header: 'Create On',
              accessor: 'createdOn',
            },
          ]}
          tableData={auditReportData}
          onClick={() => {
            //
          }}
        />
        {auditReportData.length == 0 && <h3>No Audit Trail</h3>}
      </CommentTableContainer>
    </AuditTrailPopUpStyle>
  )
}

export default AuditTrailReport
