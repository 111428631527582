/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  GoToPageWrapper,
  NextPriviousWrapper,
  PaginationContainer,
  RootContainerTable,
  SpaceMaker,
  ColoumnPaddingTop,
  ForwardButtonWrapper,
  BackwardButtonWrapper,
  ThWrapper,
  ThContentWrapper,
  ViewColumnsContainer,
  ViewColumnsPopupWrapper,
  ActionsButtonWrapper,
  CancelSaveWrapper,
  CheckboxStyle,
} from './styles'
import {
  setSorting,
  setSortDirection,
  getBetaStalePriceData,
  sortingSelectorBeta,
  sortDirectionSelectorBeta,
  setPageNumberBeta,
  PageNumSelectorBeta,
  LoadingSelector,
} from '../../../../../store/staticData/stale-pricing-beta'
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useMountedLayoutEffect,
} from 'react-table'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LottieWrapper, PopUpStyleLoader, SvgButtonWrapper } from '../../styles'
import Lottie from 'lottie-react'
import Label from '../../../../../components/label/Label'
import Loader from '../../../../../assets/lottiefiles/loader.json'
import { COLORS } from '../../../../../theme/Colors'
import SvgBackwardDoubleChevron from '../../../../../components/svg/SvgBackwardDoubleChevron'
import SvgForwardDoubleChevron from '../../../../../components/svg/SvgForwardDoubleChevron'
import Button from '../../../../../components/button/Button'
import { WDTextStrong } from '../../../../../components/ui/WDTypography'
import BulkComment from '../../BulkComment'
import EmailModal from '../../EmailModal'
import AddEmail from '../../AddEmail'
import { TableFilterList } from '../tableFilter/TableFilterData'
import Checkbox from '../../../../../components/checkbox/Checkbox'
import Divider from '../../../../../components/divider/Divider'
import { Backgrounds } from '../../../../../shared/styles'
import SvgCancel from '../../../../../components/svg/logo/SvgCancel'
import BulkFlag from '../../BulkFlag'
import { getAccessFlag } from '../../../../../utils/globalFunctions'

const IndeterminateCheckbox = ({ ...rest }) => {
  return (
    <>
      <CheckboxStyle type="checkbox" {...rest} />
    </>
  )
}

const CreateTable = (props: any) => {
  const dispatch = useDispatch()
  const sortingCol = useSelector(sortingSelectorBeta)
  const sortingDir = useSelector(sortDirectionSelectorBeta)
  const pageBeta = useSelector(PageNumSelectorBeta)
  const loading = useSelector(LoadingSelector)
  const [sortDirect, setSortDirect] = useState<any>('ASC')
  let [sortBy, setSortBy] = useState<any>('')
  const [sortAction, setSortAction] = useState<boolean>(false)
  const [tempEvent, setTempEvent] = useState<string>('')
  const [pageNumberCall, setPageNumberCall] = useState<any>(1)
  const [pageStatus, setPageStatus] = useState<boolean>(false)
  const [timeoutId, setTimeoutId] = useState()
  const [showColumnPopup, setShowColumnPopup] = useState<boolean>(false)
  const [showEmailPopup, setShowEmailPopup] = useState<boolean>(false)
  const [showAddEmail, setShowAddEmail] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any>()
  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)

  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['SD-RWX', 'OM-RWX']))
  }, [])
  const TopColumn: any = []
  ;(TopColumn[0] = 'Beta Databases'),
    (TopColumn[15] = 'DTC Data'),
    (TopColumn[16] = 'WED14509A10 Report Data'),
    (TopColumn[23] = 'Current WED14509ABCD10 Report')
  const ChooseColumnList = [
    'box_Location',
    'box_Description',
    'country',
    'securityTypeCode',
    'expirationDate',
    'inDefault',
    'corporateAction1',
    'corporateAction2',
    'surf_User_Pricing_Manual',
    'surf_LastUpdated',
    'surf_LastPrice',
    'userIntial',
    'changedDate',
    'marketPrice',
    'changeTerminal',
    'dtC_Premed',
    'lastUpdateDate',
    'lastPrice',
    's_D',
    'cash',
    'margin',
    'ira',
    'upX_Z',
    'currently_Still_In_StaleReport',
  ]
  const [hide, setHide] = useState<any>([
    'box_Location',
    'box_Description',
    'country',
    'securityTypeCode',
    'expirationDate',
    'inDefault',
    'corporateAction1',
    'corporateAction2',
    'surf_User_Pricing_Manual',
    'surf_LastUpdated',
    'surf_LastPrice',
    'userIntial',
    'changedDate',
    'marketPrice',
    'changeTerminal',
    'dtC_Premed',
    'lastUpdateDate',
    'lastPrice',
    's_D',
    'cash',
    'margin',
    'ira',
    'upX_Z',
    'currently_Still_In_StaleReport',
  ])

  const INITIAL_SELECTED_ROW_IDS = {}
  let data = props.data
  const columns = props.columns
  const totalRecords = props.totalRecords
  const fromDate = props.fromDate
  const toDate = props.toDate

  useEffect(() => {
    if (pageBeta == 0) {
      setPageNumberCall(1)
    }
  }, [pageBeta])

  let controlledPageCount: number
  controlledPageCount = Math.ceil(totalRecords / 20)

  const securityNbr =
    TableFilterList[0]?.dropDownArray[0].value &&
    TableFilterList[0].selectedData[0]
      ? TableFilterList[0].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[0]?.dropDownArray[0].value &&
        !TableFilterList[0].selectedData[0]
      ? 'Blank'
      : TableFilterList[0].selectedData.join(';').toString()

  let top_Category = ''
  if (
    TableFilterList[1].dropDownArray.filter((option) => option.value).length ==
    TableFilterList[1].dropDownArray.length
  ) {
    top_Category = ''
  } else {
    top_Category = TableFilterList[1].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  let sub_Category = ''
  if (
    TableFilterList[2].dropDownArray.filter((option) => option.value).length ==
    TableFilterList[2].dropDownArray.length
  ) {
    sub_Category = ''
  } else {
    sub_Category = TableFilterList[2].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  const reviewer_LatestComment =
    TableFilterList[3]?.dropDownArray[0].value &&
    TableFilterList[3].selectedData[0]
      ? TableFilterList[3].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[3]?.dropDownArray[0].value &&
        !TableFilterList[3].selectedData[0]
      ? 'Blank'
      : TableFilterList[3].selectedData.join(';').toString()

  const lastPrice_In_Current_StaleReport =
    TableFilterList[4]?.dropDownArray[0].value &&
    TableFilterList[4].selectedData[0]
      ? TableFilterList[4].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[4]?.dropDownArray[0].value &&
        !TableFilterList[4].selectedData[0]
      ? 'Blank'
      : TableFilterList[4].selectedData.join(';').toString()

  let followup_Flag: string
  if (
    TableFilterList[5].dropDownArray.filter((option) => option.value).length ==
    TableFilterList[5].dropDownArray.length
  ) {
    followup_Flag = ''
  } else {
    followup_Flag = TableFilterList[5].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.labelValue)
      .join(';')
      .toString()
  }

  const securityDesc =
    TableFilterList[6]?.dropDownArray[0].value &&
    TableFilterList[6].selectedData[0]
      ? TableFilterList[6].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[6]?.dropDownArray[0].value &&
        !TableFilterList[6].selectedData[0]
      ? 'Blank'
      : TableFilterList[6].selectedData.join(';').toString()

  const cusip =
    TableFilterList[7]?.dropDownArray[0].value &&
    TableFilterList[7].selectedData[0]
      ? TableFilterList[7].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[7]?.dropDownArray[0].value &&
        !TableFilterList[7].selectedData[0]
      ? 'Blank'
      : TableFilterList[7].selectedData.join(';').toString()

  const isiN_Number =
    TableFilterList[8]?.dropDownArray[0].value &&
    TableFilterList[8].selectedData[0]
      ? TableFilterList[8].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[8]?.dropDownArray[0].value &&
        !TableFilterList[8].selectedData[0]
      ? 'Blank'
      : TableFilterList[8].selectedData.join(';').toString()

  const comments_Created_By =
    TableFilterList[9]?.dropDownArray[0].value &&
    TableFilterList[9].selectedData[0]
      ? TableFilterList[9].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[9]?.dropDownArray[0].value &&
        !TableFilterList[9].selectedData[0]
      ? 'Blank'
      : TableFilterList[9].selectedData.join(';').toString()

  const reviewer_Last_Commented_Date =
    TableFilterList[10]?.dropDownArray[0].value &&
    TableFilterList[10].selectedData[0]
      ? TableFilterList[10].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[10]?.dropDownArray[0].value &&
        !TableFilterList[10].selectedData[0]
      ? 'Blank'
      : TableFilterList[10].selectedData.join(';').toString()

  const icE_Last_Priced =
    TableFilterList[11]?.dropDownArray[0].value &&
    TableFilterList[11].selectedData[0]
      ? TableFilterList[11].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[11]?.dropDownArray[0].value &&
        !TableFilterList[11].selectedData[0]
      ? 'Blank'
      : TableFilterList[11].selectedData.join(';').toString()

  const icE_Last_Price =
    TableFilterList[12]?.dropDownArray[0].value &&
    TableFilterList[12].selectedData[0]
      ? TableFilterList[12].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[12]?.dropDownArray[0].value &&
        !TableFilterList[12].selectedData[0]
      ? 'Blank'
      : TableFilterList[12].selectedData.join(';').toString()

  const icE_Notes =
    TableFilterList[13]?.dropDownArray[0].value &&
    TableFilterList[13].selectedData[0]
      ? TableFilterList[13].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[13]?.dropDownArray[0].value &&
        !TableFilterList[13].selectedData[0]
      ? 'Blank'
      : TableFilterList[13].selectedData.join(';').toString()

  const bbG_Last_Priced =
    TableFilterList[14]?.dropDownArray[0].value &&
    TableFilterList[14].selectedData[0]
      ? TableFilterList[14].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[14]?.dropDownArray[0].value &&
        !TableFilterList[14].selectedData[0]
      ? 'Blank'
      : TableFilterList[14].selectedData.join(';').toString()

  const bbG_Last_Price =
    TableFilterList[15]?.dropDownArray[0].value &&
    TableFilterList[15].selectedData[0]
      ? TableFilterList[15].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[15]?.dropDownArray[0].value &&
        !TableFilterList[15].selectedData[0]
      ? 'Blank'
      : TableFilterList[15].selectedData.join(';').toString()

  const box_Location =
    TableFilterList[16]?.dropDownArray[0].value &&
    TableFilterList[16].selectedData[0]
      ? TableFilterList[16].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[16]?.dropDownArray[0].value &&
        !TableFilterList[16].selectedData[0]
      ? 'Blank'
      : TableFilterList[16].selectedData.join(';').toString()

  const box_Description =
    TableFilterList[17]?.dropDownArray[0].value &&
    TableFilterList[17].selectedData[0]
      ? TableFilterList[17].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[17]?.dropDownArray[0].value &&
        !TableFilterList[17].selectedData[0]
      ? 'Blank'
      : TableFilterList[17].selectedData.join(';').toString()

  const country =
    TableFilterList[18]?.dropDownArray[0].value &&
    TableFilterList[18].selectedData[0]
      ? TableFilterList[18].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[18]?.dropDownArray[0].value &&
        !TableFilterList[18].selectedData[0]
      ? 'Blank'
      : TableFilterList[18].selectedData.join(';').toString()

  const securityTypeCode =
    TableFilterList[19]?.dropDownArray[0].value &&
    TableFilterList[19].selectedData[0]
      ? TableFilterList[19].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[19]?.dropDownArray[0].value &&
        !TableFilterList[19].selectedData[0]
      ? 'Blank'
      : TableFilterList[19].selectedData.join(';').toString()

  const expirationDate =
    TableFilterList[20]?.dropDownArray[0].value &&
    TableFilterList[20].selectedData[0]
      ? TableFilterList[20].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[20]?.dropDownArray[0].value &&
        !TableFilterList[20].selectedData[0]
      ? 'Blank'
      : TableFilterList[20].selectedData.join(';').toString()

  const inDefault =
    TableFilterList[21]?.dropDownArray[0].value &&
    TableFilterList[21].selectedData[0]
      ? TableFilterList[21].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[21]?.dropDownArray[0].value &&
        !TableFilterList[21].selectedData[0]
      ? 'Blank'
      : TableFilterList[21].selectedData.join(';').toString()

  const corporateAction1 =
    TableFilterList[22]?.dropDownArray[0].value &&
    TableFilterList[22].selectedData[0]
      ? TableFilterList[22].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[22]?.dropDownArray[0].value &&
        !TableFilterList[22].selectedData[0]
      ? 'Blank'
      : TableFilterList[22].selectedData.join(';').toString()

  const corporateAction2 =
    TableFilterList[23]?.dropDownArray[0].value &&
    TableFilterList[23].selectedData[0]
      ? TableFilterList[23].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[23]?.dropDownArray[0].value &&
        !TableFilterList[23].selectedData[0]
      ? 'Blank'
      : TableFilterList[23].selectedData.join(';').toString()

  const surf_User_Pricing_Manual =
    TableFilterList[24]?.dropDownArray[0].value &&
    TableFilterList[24].selectedData[0]
      ? TableFilterList[24].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[24]?.dropDownArray[0].value &&
        !TableFilterList[24].selectedData[0]
      ? 'Blank'
      : TableFilterList[24].selectedData.join(';').toString()

  const surf_LastUpdated =
    TableFilterList[25]?.dropDownArray[0].value &&
    TableFilterList[25].selectedData[0]
      ? TableFilterList[25].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[25]?.dropDownArray[0].value &&
        !TableFilterList[25].selectedData[0]
      ? 'Blank'
      : TableFilterList[25].selectedData.join(';').toString()

  const surf_LastPrice =
    TableFilterList[26]?.dropDownArray[0].value &&
    TableFilterList[26].selectedData[0]
      ? TableFilterList[26].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[26]?.dropDownArray[0].value &&
        !TableFilterList[26].selectedData[0]
      ? 'Blank'
      : TableFilterList[26].selectedData.join(';').toString()

  const userIntial =
    TableFilterList[27]?.dropDownArray[0].value &&
    TableFilterList[27].selectedData[0]
      ? TableFilterList[27].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[27]?.dropDownArray[0].value &&
        !TableFilterList[27].selectedData[0]
      ? 'Blank'
      : TableFilterList[27].selectedData.join(';').toString()

  const changedDate =
    TableFilterList[28]?.dropDownArray[0].value &&
    TableFilterList[28].selectedData[0]
      ? TableFilterList[28].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[28]?.dropDownArray[0].value &&
        !TableFilterList[28].selectedData[0]
      ? 'Blank'
      : TableFilterList[28].selectedData.join(';').toString()

  const marketPrice =
    TableFilterList[29]?.dropDownArray[0].value &&
    TableFilterList[29].selectedData[0]
      ? TableFilterList[29].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[29]?.dropDownArray[0].value &&
        !TableFilterList[29].selectedData[0]
      ? 'Blank'
      : TableFilterList[29].selectedData.join(';').toString()

  const changeTerminal =
    TableFilterList[30]?.dropDownArray[0].value &&
    TableFilterList[30].selectedData[0]
      ? TableFilterList[30].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[30]?.dropDownArray[0].value &&
        !TableFilterList[30].selectedData[0]
      ? 'Blank'
      : TableFilterList[30].selectedData.join(';').toString()

  const dtC_Premed =
    TableFilterList[31]?.dropDownArray[0].value &&
    TableFilterList[31].selectedData[0]
      ? TableFilterList[31].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[31]?.dropDownArray[0].value &&
        !TableFilterList[31].selectedData[0]
      ? 'Blank'
      : TableFilterList[31].selectedData.join(';').toString()

  const lastUpdateDate =
    TableFilterList[32]?.dropDownArray[0].value &&
    TableFilterList[32].selectedData[0]
      ? TableFilterList[32].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[32]?.dropDownArray[0].value &&
        !TableFilterList[32].selectedData[0]
      ? 'Blank'
      : TableFilterList[32].selectedData.join(';').toString()

  const lastPrice =
    TableFilterList[33]?.dropDownArray[0].value &&
    TableFilterList[33].selectedData[0]
      ? TableFilterList[33].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[33]?.dropDownArray[0].value &&
        !TableFilterList[33].selectedData[0]
      ? 'Blank'
      : TableFilterList[33].selectedData.join(';').toString()

  const s_D =
    TableFilterList[34]?.dropDownArray[0].value &&
    TableFilterList[34].selectedData[0]
      ? TableFilterList[34].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[34]?.dropDownArray[0].value &&
        !TableFilterList[34].selectedData[0]
      ? 'Blank'
      : TableFilterList[34].selectedData.join(';').toString()

  const cash =
    TableFilterList[35]?.dropDownArray[0].value &&
    TableFilterList[35].selectedData[0]
      ? TableFilterList[35].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[35]?.dropDownArray[0].value &&
        !TableFilterList[35].selectedData[0]
      ? 'Blank'
      : TableFilterList[35].selectedData.join(';').toString()

  const margin =
    TableFilterList[36]?.dropDownArray[0].value &&
    TableFilterList[36].selectedData[0]
      ? TableFilterList[36].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[36]?.dropDownArray[0].value &&
        !TableFilterList[36].selectedData[0]
      ? 'Blank'
      : TableFilterList[36].selectedData.join(';').toString()

  const ira =
    TableFilterList[37]?.dropDownArray[0].value &&
    TableFilterList[37].selectedData[0]
      ? TableFilterList[37].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[37]?.dropDownArray[0].value &&
        !TableFilterList[37].selectedData[0]
      ? 'Blank'
      : TableFilterList[37].selectedData.join(';').toString()

  const upX_Z =
    TableFilterList[38]?.dropDownArray[0].value &&
    TableFilterList[38].selectedData[0]
      ? TableFilterList[38].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[38]?.dropDownArray[0].value &&
        !TableFilterList[38].selectedData[0]
      ? 'Blank'
      : TableFilterList[38].selectedData.join(';').toString()

  const currently_Still_in_StaleReport =
    TableFilterList[39]?.dropDownArray[0].value &&
    TableFilterList[39].selectedData[0]
      ? TableFilterList[39].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[39]?.dropDownArray[0].value &&
        !TableFilterList[39].selectedData[0]
      ? 'Blank'
      : TableFilterList[39].selectedData.join(';').toString()

  const last_Priced_In_Current_StaleReport =
    TableFilterList[40]?.dropDownArray[0].value &&
    TableFilterList[40].selectedData[0]
      ? TableFilterList[40].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[40]?.dropDownArray[0].value &&
        !TableFilterList[40].selectedData[0]
      ? 'Blank'
      : TableFilterList[40].selectedData.join(';').toString()

  const commentFilter =
    TableFilterList[41]?.dropDownArray[0].value &&
    TableFilterList[41].selectedData[0]
      ? TableFilterList[41].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[41]?.dropDownArray[0].value &&
        !TableFilterList[41].selectedData[0]
      ? 'Blank'
      : TableFilterList[41].selectedData.join(';').toString()

  const notes =
    TableFilterList[42]?.dropDownArray[0].value &&
    TableFilterList[42].selectedData[0]
      ? TableFilterList[42].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[42]?.dropDownArray[0].value &&
        !TableFilterList[42].selectedData[0]
      ? 'Blank'
      : TableFilterList[42].selectedData.join(';').toString()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        pageIndex: 1,
        pageSize: 20,
        selectedRowIds: INITIAL_SELECTED_ROW_IDS, // Do not just use {}
        hiddenColumns: hide,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: '2%',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const { selectedRowIds } = state

  useMountedLayoutEffect(() => {
    const selectedIds = Object.keys(selectedRowIds)
    const selectedRowsData = selectedIds
      .map((x) => data[x])
      .filter(function (x) {
        return x != null
      })
    setSelectedRows(selectedRowsData)
  }, [selectedRowIds])

  const sorting = async (e: any) => {
    setTempEvent(e)

    sortBy = e.replace(/_Formatted/g, '')
    setSortBy([...sortBy])
    dispatch(setSorting(sortBy))

    if (sortDirect == 'ASC') {
      setSortDirect('DESC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(true)
    }

    if (sortDirect == 'DESC') {
      setSortDirect('ASC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(false)
    }

    await dispatch(
      getBetaStalePriceData(
        'sort',
        'Beta',
        fromDate,
        toDate,
        pageNumberCall - 1,
        20,
        sortBy,
        sortDirect,
        0,
        securityNbr,
        top_Category,
        sub_Category,
        reviewer_LatestComment,
        lastPrice_In_Current_StaleReport,
        followup_Flag,
        securityDesc,
        cusip,
        isiN_Number,
        comments_Created_By,
        reviewer_Last_Commented_Date,
        icE_Last_Priced,
        icE_Last_Price,
        icE_Notes,
        bbG_Last_Priced,
        bbG_Last_Price,
        box_Location,
        box_Description,
        country,
        securityTypeCode,
        expirationDate,
        inDefault,
        corporateAction1,
        corporateAction2,
        surf_User_Pricing_Manual,
        surf_LastUpdated,
        surf_LastPrice,
        userIntial,
        changedDate,
        marketPrice,
        changeTerminal,
        dtC_Premed,
        lastUpdateDate,
        lastPrice,
        s_D,
        cash,
        margin,
        ira,
        upX_Z,
        currently_Still_in_StaleReport,
        last_Priced_In_Current_StaleReport,
        commentFilter,
        notes
      )
    )
  }

  const pageCallFunction = async (pageIndex: any, rowRange: any) => {
    dispatch(setPageNumberBeta(pageIndex - 1))

    await dispatch(
      getBetaStalePriceData(
        'search',
        'Beta',
        fromDate,
        toDate,
        pageIndex - 1,
        rowRange,
        sortingCol,
        sortingDir,
        0,
        securityNbr,
        top_Category,
        sub_Category,
        reviewer_LatestComment,
        lastPrice_In_Current_StaleReport,
        followup_Flag,
        securityDesc,
        cusip,
        isiN_Number,
        comments_Created_By,
        reviewer_Last_Commented_Date,
        icE_Last_Priced,
        icE_Last_Price,
        icE_Notes,
        bbG_Last_Priced,
        bbG_Last_Price,
        box_Location,
        box_Description,
        country,
        securityTypeCode,
        expirationDate,
        inDefault,
        corporateAction1,
        corporateAction2,
        surf_User_Pricing_Manual,
        surf_LastUpdated,
        surf_LastPrice,
        userIntial,
        changedDate,
        marketPrice,
        changeTerminal,
        dtC_Premed,
        lastUpdateDate,
        lastPrice,
        s_D,
        cash,
        margin,
        ira,
        upX_Z,
        currently_Still_in_StaleReport,
        last_Priced_In_Current_StaleReport,
        commentFilter,
        notes
      )
    )
  }

  useEffect(() => {
    if (pageStatus) {
      pageCallFunction(pageNumberCall, 20)
    }
  }, [pageNumberCall])

  const onChangeFunction = (pageValue: number) => {
    if (pageValue > 0 && pageValue <= pageCount) {
      const pageNumber = pageValue ? pageValue : 1
      setPageStatus(true)
      clearTimeout(timeoutId)
      const timeout: any = setTimeout(() => {
        gotoPage(pageNumber)
        setPageNumberCall(pageNumber)
      }, 3000)
      setTimeoutId(timeout)
    }
  }

  const renderColors = (flag: number) => {
    switch (flag) {
      case 1:
        return '#ffe6cc'
      case 2:
        return '#e6ffe6'
      case 3:
        return '#f2f2f2'
      default:
        return ''
    }
  }

  const getTrProps = (rowInfo: any) => {
    return {
      style: {
        background: rowInfo?.original?.followup_Flag
          ? renderColors(Number(rowInfo?.original?.followup_Flag))
          : '',
        border: 'none',
      },
    }
    return {}
  }

  return (
    <>
      <PaginationContainer>
        <Label>
          Page{' '}
          <strong>
            {pageNumberCall} of {pageOptions.length}
          </strong>{' '}
        </Label>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <GoToPageWrapper>
          <Label>Go to page:</Label>
          <input
            type="number"
            min="1"
            max={pageCount}
            defaultValue={pageNumberCall}
            onChange={(e) => {
              onChangeFunction(Number(e.target.value))
            }}
            style={{ width: '50px' }}
          />
        </GoToPageWrapper>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <NextPriviousWrapper>
          <BackwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(1)
              setPageNumberCall(1)
            }}
          >
            {pageNumberCall !== 1 ? (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </BackwardButtonWrapper>
          <button
            onClick={() => {
              setPageStatus(true)
              previousPage()
              setPageNumberCall(pageNumberCall - 1)
            }}
            disabled={pageNumberCall === 1}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              setPageStatus(true)
              nextPage()
              setPageNumberCall(pageNumberCall + 1)
            }}
            disabled={pageNumberCall === pageCount}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Next
          </button>
          <ForwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(pageCount)
              setPageNumberCall(pageCount)
            }}
          >
            {pageNumberCall !== pageCount ? (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </ForwardButtonWrapper>
        </NextPriviousWrapper>
      </PaginationContainer>
      <ViewColumnsContainer>
        <BulkFlag
          fromDate={fromDate}
          toDate={toDate}
          handleSubmit={props.handleSubmit}
          system={'Beta'}
          selectedRows={selectedRows}
          toggleAllRowsSelected={toggleAllRowsSelected}
        />
        <BulkComment
          fromDate={fromDate}
          toDate={toDate}
          handleSubmit={props.handleSubmit}
          system={'Beta'}
          selectedRows={selectedRows}
          toggleAllRowsSelected={toggleAllRowsSelected}
        />
        <Button
          padding={'4px 16px'}
          borderRadius={'4px'}
          type={'button'}
          color={COLORS.Background.Primarytext}
          style={{ whiteSpace: 'nowrap' }}
          bgColor={'transparent'}
          disabled={!writeExecuteFlag}
          onClick={() => {
            setShowAddEmail(!showAddEmail)
          }}
        >
          Add Contact
        </Button>
        {showAddEmail && (
          <AddEmail
            fromDate={fromDate}
            System={'Beta'}
            selectedRows={selectedRows}
            toggleAllRowsSelected={toggleAllRowsSelected}
            setShowAddEmail={setShowAddEmail}
          ></AddEmail>
        )}
        {selectedRows && selectedRows.length > 0 ? (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Primarytext}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
            disabled={!writeExecuteFlag}
            onClick={() => {
              setShowEmailPopup(!showEmailPopup)
            }}
          >
            Send Email
          </Button>
        ) : (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Neutral40}
            borderColor={COLORS.Background.Neutral40}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
          >
            Send Email
          </Button>
        )}

        {showEmailPopup && (
          <EmailModal
            fromDate={fromDate}
            System={'Beta'}
            selectedRows={selectedRows}
            toggleAllRowsSelected={toggleAllRowsSelected}
            setShowEmailPopup={setShowEmailPopup}
          ></EmailModal>
        )}
        <Button
          padding={'4px 16px'}
          borderRadius={'4px'}
          type={'button'}
          color={COLORS.Background.Primarytext}
          style={{ whiteSpace: 'nowrap' }}
          bgColor={'transparent'}
          onClick={() => {
            setShowColumnPopup(!showColumnPopup)
            !showColumnPopup == false && pageCallFunction(pageNumberCall, 20)
          }}
        >
          View Columns
        </Button>
        {showColumnPopup && (
          <ViewColumnsPopupWrapper>
            <div style={{ height: '90%', overflow: 'auto' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <WDTextStrong>Choose columns</WDTextStrong>
                <SvgButtonWrapper
                  style={{ marginLeft: 'auto' }}
                  onClick={() => {
                    setShowColumnPopup(false)
                    pageCallFunction(pageNumberCall, 20)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </div>

              {ChooseColumnList?.map((item: any, index: number) => {
                //
                return (
                  <>
                    {
                      <Label padding="2px" fontWeight={600}>
                        {TopColumn[index]}
                      </Label>
                    }
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '4px',
                      }}
                      key={index}
                    >
                      <Checkbox
                        defaultChecked={!hide.includes(item)}
                        onChange={(e: any) => {
                          const index = hide.indexOf(item)
                          {
                            e.target.checked
                              ? index > -1 && hide.splice(index, 1)
                              : setHide([...hide, item])
                          }
                        }}
                      ></Checkbox>{' '}
                      {item.toUpperCase().replace(/_/g, ' ')}
                    </div>
                    <Divider
                      thickness={'1px'}
                      horizontal={true}
                      bgColor={Backgrounds.Gray110}
                    />
                  </>
                )
              })}
            </div>
            <ActionsButtonWrapper>
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                style={{ whiteSpace: 'nowrap' }}
                bgColor={'transparent'}
                onClick={() => {
                  setHide(ChooseColumnList)
                  pageCallFunction(pageNumberCall, 20)
                  setShowColumnPopup(!showColumnPopup)
                }}
              >
                Default
              </Button>
              <CancelSaveWrapper>
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={() => {
                    pageCallFunction(pageNumberCall, 20)
                    setShowColumnPopup(!showColumnPopup)
                  }}
                >
                  Save
                </Button>
              </CancelSaveWrapper>
            </ActionsButtonWrapper>
          </ViewColumnsPopupWrapper>
        )}
      </ViewColumnsContainer>

      <table {...getTableProps()}>
        {loading && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <SpaceMaker>
              <Label> Loading...</Label>
            </SpaceMaker>{' '}
          </PopUpStyleLoader>
        )}
        <thead>
          {headerGroups.map((headerGroup, rowIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={rowIndex}>
              {headerGroup.headers.map((column: any, index) => (
                <th
                  {...column.getHeaderProps({
                    style: { width: column.width },
                  })}
                  onClick={(e) => {
                    //trigger the react-table header onClick manually
                    column
                      .getHeaderProps(column.getSortByToggleProps())
                      .onClick(e)

                    sorting(column.id)
                  }}
                  key={index}
                >
                  <ThWrapper>
                    <ThContentWrapper>
                      {column.render('Header')}
                    </ThContentWrapper>
                    <ColoumnPaddingTop>
                      {column.id == tempEvent
                        ? sortAction
                          ? '🔼'
                          : '🔽 '
                        : ''}
                    </ColoumnPaddingTop>

                    {headerGroup.headers.length - 1 !== index && <div></div>}
                  </ThWrapper>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} {...getTrProps(row)} key={index}>
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data.length == 0 && (
        <>
          <Label fontWeight={700} margin="5% 0 0 50%">
            No Records Found
          </Label>
          <Label fontWeight={700} margin="0.5% 0 0 49%">
            No Exceptions Identified
          </Label>
        </>
      )}

      <PaginationContainer>
        <Label>
          Page{' '}
          <strong>
            {pageNumberCall} of {pageOptions.length}
          </strong>{' '}
        </Label>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <GoToPageWrapper>
          <Label>Go to page:</Label>
          <input
            type="number"
            min="1"
            max={pageCount}
            defaultValue={pageNumberCall}
            onChange={(e) => {
              onChangeFunction(Number(e.target.value))
            }}
            style={{ width: '50px' }}
          />
        </GoToPageWrapper>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <NextPriviousWrapper>
          <BackwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(1)
              setPageNumberCall(1)
            }}
          >
            {pageNumberCall !== 1 ? (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </BackwardButtonWrapper>
          <button
            onClick={() => {
              setPageStatus(true)
              previousPage()
              setPageNumberCall(pageNumberCall - 1)
            }}
            disabled={pageNumberCall === 1}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              setPageStatus(true)
              nextPage()
              setPageNumberCall(pageNumberCall + 1)
            }}
            disabled={pageNumberCall === pageCount}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Next
          </button>
          <ForwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(pageCount)
              setPageNumberCall(pageCount)
            }}
          >
            {pageNumberCall !== pageCount ? (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </ForwardButtonWrapper>
        </NextPriviousWrapper>
      </PaginationContainer>
    </>
  )
}

const BetaStalePricingTable = (props: any) => {
  return (
    <RootContainerTable>
      <CreateTable
        handleSubmit={props.handleSubmit}
        fromDate={props.fromDate}
        toDate={props.toDate}
        data={props.data}
        columns={props.columns}
        totalRecords={props.totalRecords}
      />
    </RootContainerTable>
  )
}

export default BetaStalePricingTable
