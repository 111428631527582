/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall, authApiUpload } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  p3StalePriceData: []
  p3StalePriceDataHistory: []
  data: any
  isLoading: boolean
  action: string
  status: string
  response: boolean
  sorting: any
  sortDirection: any
  pageNumber: number
  TotalCount: number
}

const initialState: State = {
  p3StalePriceData: [],
  p3StalePriceDataHistory: [],
  data: [],
  isLoading: false,
  action: '',
  status: '',
  response: false,
  sorting: 'CusIp_Number',
  sortDirection: 'ASC',
  pageNumber: 0,
  TotalCount: 0,
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'stale-pricing-p3',
  initialState,
  reducers: {
    SetP3StalePriceData: (state: State, action: PayloadAction<any>) => {
      state.p3StalePriceData = action.payload.data
    },
    SetP3StalePriceDataHistory: (state: State, action: PayloadAction<any>) => {
      state.p3StalePriceDataHistory = action.payload.data
    },
    setCurrentData: (state: State, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    updateTableDetails: (state: State, action: PayloadAction<any>) => {
      state.p3StalePriceData = action.payload.currentTable
    },
    setSorting: (state: State, action: PayloadAction<any>) => {
      state.sorting = action.payload
    },
    setSortDirection: (state: State, action: PayloadAction<any>) => {
      state.sortDirection = action.payload
    },
    setResponse: (state: State, action: PayloadAction<boolean>) => {
      state.response = action.payload
    },
    setStatusMessage: (state: State, action: PayloadAction<string>) => {
      state.status = action.payload
    },
    setPageNumberP3: (state: State, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    setTotalCount: (state: State, action: PayloadAction<number>) => {
      state.TotalCount = action.payload
    },
  },
})

export const {
  SetP3StalePriceData,
  SetP3StalePriceDataHistory,
  setCurrentData,
  setIsLoading,
  setAction,
  setSorting,
  setSortDirection,
  setStatusMessage,
  setResponse,
  setPageNumberP3,
  setTotalCount,
  updateTableDetails,
} = actions

export const SendMail: any =
  (Subject: string, ToEmail: string, Body: string, Attachments: any) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiUpload.post(
        '/opsstaticdataapi/StaticData/StalePrice_SendMail',
        {
          Subject,
          ToEmail,
          Body,
          Attachments,
        }
      )
      if (status === 200) {
        dispatch(setStatusMessage('Mail Send Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        return true
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      return false
    }
  }
export const bbgUpload: any =
  (FormFile: any, Source: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiUpload.post(
        '/opsstaticdataapi/StaticData/StalePrice_BackGroundUpload',
        {
          FormFile,
          Source,
        }
      )
      if (status === 200) {
        dispatch(
          setStatusMessage(
            `File upload successfully completed, processing in progress. \n Email will be sent to 'SecurityMaster@wedbush.com' once processing is completed.`
          )
        )
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        return 200
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      return [e?.response?.status, e?.response?.data]
    }
  }
export const UpdateNote: any =
  (
    system: string,
    securityNumber: string,
    notes: string,
    cusip: string,
    notes_LastUpdatedBy: string,
    notes_LastUpdatedOn: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        '/opsstaticdataapi/StaticData/UpdateStalePrice_Notes',
        {
          securityNumber,
          cusip,
          system,
          notes,
          notes_LastUpdatedBy,
          notes_LastUpdatedOn,
        }
      )

      if (status === 200) {
        dispatch(setStatusMessage('Added Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        return status
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      return
    }
  }
export const getToMail: any =
  (ToMail: string) => async (dispatch: Dispatch) => {
    try {
      const { data, status } = await authApiCall.get(
        '/opsstaticdataapi/StaticData/GetToMailFromAddressBook',
        {
          params: {
            ToMail,
          },
        }
      )

      if (status === 200) {
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setStatusMessage)
    }
  }
export const getComments: any =
  (
    system: string,
    cusip: string,
    securityNumber: string,
    IsHistoric: boolean,
    ReportRunDate: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `/opsstaticdataapi/StaticData/GetStalePriceComments?system=${system}&cusip=${cusip}&securityNumber=${securityNumber}&IsHistoric=${IsHistoric}&ReportRunDate=${ReportRunDate}`
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      //
    }
  }
export const getP3StalePriceData: any =
  (
    action: string,
    system: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    totalCount: number,
    securityDescription: string,
    top_Category: string,
    sub_Category: string,
    reviewer_LatestComment: string,
    security_Type_and_Sub_Type_Code: string,
    lastPrice_In_Current_StaleReport: string,
    followup_Flag: string,
    cusip: string,
    isiN_NUMBER: string,
    comments_Created_By: string,
    reviewer_Last_Commented_Date: string,
    icE_Last_Priced: string,
    icE_Last_Price: string,
    icE_Notes: string,
    bbG_Last_Priced: string,
    bbG_Last_Price: string,
    boxLocation: string,
    locationMemo: string,
    boxDesc: string,
    country: string,
    securityTypeCode: string,
    maturity_WarrantExpire_Date: string,
    inDefault: string,
    description2: string,
    description3: string,
    eodcmvdaT_LastPriced: string,
    eodcmvdaT_LastPrice: string,
    issue_Status_Code: string,
    dtC_Premed_Elig: string,
    maturityDate: string,
    couponRate: string,
    symbol: string,
    currency: string,
    lastPriceDate: string,
    lastPrice: string,
    cashQnty: string,
    marginQnty: string,
    inventory: string,
    currently_Still_in_StaleReport: string,
    last_Priced_In_Current_StaleReport: string,
    commentFilter: string,
    notes: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/GetP3StalePriceReport',
        {
          system,
          fromDate,
          toDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          totalCount,
          securityDescription,
          top_Category,
          sub_Category,
          reviewer_LatestComment,
          security_Type_and_Sub_Type_Code,
          lastPrice_In_Current_StaleReport,
          followup_Flag,
          cusip,
          isiN_NUMBER,
          comments_Created_By,
          reviewer_Last_Commented_Date,
          icE_Last_Priced,
          icE_Last_Price,
          icE_Notes,
          bbG_Last_Priced,
          bbG_Last_Price,
          boxLocation,
          locationMemo,
          boxDesc,
          country,
          securityTypeCode,
          maturity_WarrantExpire_Date,
          inDefault,
          description2,
          description3,
          eodcmvdaT_LastPriced,
          eodcmvdaT_LastPrice,
          issue_Status_Code,
          dtC_Premed_Elig,
          maturityDate,
          couponRate,
          symbol,
          currency,
          lastPriceDate,
          lastPrice,
          cashQnty,
          marginQnty,
          inventory,
          currently_Still_in_StaleReport,
          last_Priced_In_Current_StaleReport,
          commentFilter,
          notes,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetP3StalePriceData({
            data: data,
          })
        )
        dispatch(
          setTotalCount({
            data: data.totalCount,
          })
        )

        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(
        SetP3StalePriceData({
          data: '',
        })
      )
      return null
    }
  }
export const getP3StalePriceDataHistory: any =
  (
    action: string,
    system: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    totalCount: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        '/opsstaticdataapi/StaticData/GetP3StalePriceReportArchive',
        {
          system,
          fromDate,
          toDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          totalCount,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetP3StalePriceDataHistory({
            data: data,
          })
        )
        dispatch(
          setTotalCount({
            data: data.totalCount,
          })
        )
        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(
        SetP3StalePriceDataHistory({
          data: '',
        })
      )
      return null
    }
  }
export const getP3StalePriceDataCSV: any =
  (
    fileName: string,
    system: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    totalCount: number,
    cusip: string,
    securityDescription: string,
    top_Category: string,
    sub_Category: string,
    reviewer_LatestComment: string,
    bbG_Last_Priced: string,
    bbG_Last_Price: string,
    icE_Last_Priced: string,
    icE_Last_Price: string,
    icE_Notes: string,
    boxLocation: string,
    locationMemo: string,
    boxDesc: string,
    country: string,
    securityTypeCode: string,
    security_Type_and_Sub_Type_Code: string,
    maturity_WarrantExpire_Date: string,
    inDefault: string,
    description2: string,
    description3: string,
    eodcmvdaT_LastPriced: string,
    eodcmvdaT_LastPrice: string,
    issue_Status_Code: string,
    dtC_Premed_Elig: string,
    maturityDate: string,
    couponRate: string,
    symbol: string,
    currency: string,
    lastPriceDate: string,
    lastPrice: string,
    cashQnty: string,
    marginQnty: string,
    inventory: string,
    currently_Still_In_StaleReport: string,
    last_Priced_In_Current_StaleReport: string,
    lastPrice_In_Current_StaleReport: string,
    commentFilter: string,
    notes: string,
    followup_Flag: string,
    isiN_NUMBER: string,
    reviewer_Last_Commented_Date: string,
    comments_Created_By: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/GetP3StalePriceReport_CsvDownload',
        {
          system,
          fromDate,
          toDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          totalCount,
          cusip,
          securityDescription,
          top_Category,
          sub_Category,
          reviewer_LatestComment,
          bbG_Last_Priced,
          bbG_Last_Price,
          icE_Last_Priced,
          icE_Last_Price,
          icE_Notes,
          boxLocation,
          locationMemo,
          boxDesc,
          country,
          securityTypeCode,
          security_Type_and_Sub_Type_Code,
          maturity_WarrantExpire_Date,
          inDefault,
          description2,
          description3,
          eodcmvdaT_LastPriced,
          eodcmvdaT_LastPrice,
          issue_Status_Code,
          dtC_Premed_Elig,
          maturityDate,
          couponRate,
          symbol,
          currency,
          lastPriceDate,
          lastPrice,
          cashQnty,
          marginQnty,
          inventory,
          currently_Still_In_StaleReport,
          last_Priced_In_Current_StaleReport,
          lastPrice_In_Current_StaleReport,
          commentFilter,
          notes,
          followup_Flag,
          isiN_NUMBER,
          reviewer_Last_Commented_Date,
          comments_Created_By,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        dispatch(setStatusMessage('File Downloaded'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setStatusMessage)
      return 400
    }
  }
export const getP3StalePriceDataCSVHistory: any =
  (
    fileName: string,
    system: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    totalCount: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/GetP3ArchiveStalePriceReport_CsvDownload',
        {
          system,
          fromDate,
          toDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          totalCount,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      return 400
    }
  }

export const putUserBulkComment: any = (payload: any) => async () => {
  try {
    const { status } = await authApiCall.post(
      'opsstaticdataapi/StaticData/AddStalePriceComments',
      payload
    )

    if (status === 200) {
      return status
    }
  } catch {
    return 400
  }
}

export const AddComment: any =
  (
    system: string,
    securityNumber: string,
    comment: string,
    cusip: string,
    BoxLocation: string,
    createdBy: string,
    createdOn: string,
    reportRunDate: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        '/opsstaticdataapi/StaticData/AddStalePriceComments',
        [
          {
            securityNumber,
            cusip,
            BoxLocation,
            system,
            comment,
            createdBy,
            createdOn,
            reportRunDate,
          },
        ]
      )

      if (status === 200) {
        dispatch(setStatusMessage('Added Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      dispatch(setResponse(false))
      return
    }
  }

export const updateFlagBulk: any = (payload: any) => async () => {
  try {
    const { status } = await authApiCall.post(
      'opsstaticdataapi/StaticData/UpdateStalePrice_FollowUpFlag',
      payload
    )

    if (status === 200) {
      return status
    }
  } catch (e: any) {
    return 400
  }
}

export const updateFlag: any =
  (
    system: string,
    securityNumber: string,
    cusip: string,
    followUp_Flag: boolean,
    currentTable: any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/StaticData/UpdateStalePrice_FollowUpFlag`,
        [
          {
            system,
            cusip,
            securityNumber,
            followUp_Flag,
          },
        ]
      )

      if (status === 200) {
        dispatch(setStatusMessage('Updated Successfully'))
        dispatch(
          updateTableDetails({
            currentTable,
          })
        )
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        return status
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      return
    }
  }

export const dataSelector = (store: StoreState) =>
  store.valdi.stalePriceP3.p3StalePriceData
export const dataSelectorHistory = (store: StoreState) =>
  store.valdi.stalePriceP3.p3StalePriceDataHistory

export const currentDataSelector = (store: StoreState) =>
  store.valdi.stalePriceP3.data
export const LoadingSelector = (store: StoreState) =>
  store.valdi.stalePriceP3.isLoading
export const actionSelector = (store: StoreState) =>
  store.valdi.stalePriceP3.action
export const sortingSelectorP3 = (store: StoreState) =>
  store.valdi.stalePriceP3.sorting
export const sortDirectionSelectorP3 = (store: StoreState) =>
  store.valdi.stalePriceP3.sortDirection
export const statusSelector = (store: StoreState) =>
  store.valdi.stalePriceP3.status
export const ResponseSelector = (store: StoreState) =>
  store.valdi.stalePriceP3.response
export const PageNumSelectorP3 = (store: StoreState) =>
  store.valdi.stalePriceP3.pageNumber
export const TotalCountSelectorP3 = (store: StoreState) =>
  store.valdi.stalePriceP3.TotalCount

export default reducer
