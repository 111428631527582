/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import Button from '../../components/button/Button'
import { Colors } from '../../shared/styles'
import { FilterSVGWrapper } from './styles'
import SvgFilter12 from '../../components/svg/SvgFilter12'
import Checkbox from '../../components/checkbox/Checkbox'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'
import { updateFilterArray } from '../../utils/globalFunctions'

const FreeFormInputFilter = ({
  pageIndex,
  gotoPage,
  filterData,
  setSummaryFilters,
  column,
}: any) => {
  const { filterValue, setFilter, id } = column

  useEffect(() => {
    if (
      filterData.length === 0 ||
      filterData === undefined ||
      filterData === null
    ) {
      setFilter('')
    }
  }, [filterData])

  return (
    <span>
      <input
        style={{ marginTop: '5px', maxWidth: '100px' }}
        value={filterValue}
        placeholder="Filter..."
        onChange={(e) => {
          setFilter(e.target.value)

          setSummaryFilters((prev: any) => {
            return updateFilterArray(prev, e.target.value, id)
          })

          if (pageIndex != 0) gotoPage(0)
        }}
      />
    </span>
  )
}

export const ExclusiveCheckboxFilter = ({
  pageIndex,
  gotoPage,
  column,
  handleActionedOnSaveClick,
  filterData,
  setSummaryFilters,
}: any) => {
  const { filterValue, setFilter, id } = column
  const [showOptions, setShowOptions] = useState(false)
  const [optionsCheckedState, setOptionsCheckedState] = useState<{
    [key: string]: boolean
  }>({
    all:
      filterValue === undefined ||
      filterValue === 'all' ||
      filterValue === null,
    actioned: filterValue === 'actioned',
    notActioned: filterValue === 'notActioned',
  })

  useEffect(() => {
    if (
      filterData.length === 0 ||
      filterData === undefined ||
      filterData === null
    ) {
      setOptionsCheckedState({
        all: true,
        actioned: false,
        notActioned: false,
      })
    }
  }, [filterData])

  const optionsDivRef = useRef<any>(null)

  const handleClickOutsideOptionsDiv = (event: MouseEvent) => {
    if (
      optionsDivRef.current &&
      !optionsDivRef.current.contains(event.target)
    ) {
      setShowOptions(false)
    }
  }

  const handleOptionsCheckboxChange = (name: string) => {
    setOptionsCheckedState({
      ...(!optionsCheckedState[name] && {
        ...{
          all: false,
          actioned: false,
          notActioned: false,
        },
        [name]: !optionsCheckedState[name],
      }),
      ...(optionsCheckedState[name] && {
        all: false,
        actioned: false,
        notActioned: false,
      }),
    })
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideOptionsDiv, true)
    return () => {
      document.addEventListener('click', handleClickOutsideOptionsDiv, true)
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {' '}
      <input
        style={{ marginTop: '5px', maxWidth: '120px' }}
        value={''}
        placeholder="Filter..."
        onClick={() => setShowOptions(true)}
        onChange={(e) => {
          setFilter(e.target.value)
          if (pageIndex != 0) gotoPage(0)
        }}
      />
      {(filterValue === 'actioned' || filterValue === 'notActioned') && (
        <FilterSVGWrapper onClick={() => setShowOptions(true)}>
          <SvgFilter12 fillColor={Colors.Black}></SvgFilter12>
        </FilterSVGWrapper>
      )}
      {showOptions && (
        <div
          ref={optionsDivRef}
          style={{
            position: 'absolute',
            top: '20px',
            left: 0,
            border: '1px solid black',
            backgroundColor: 'white',
            padding: '2px',
            width: '100%',
            zIndex: '999',
          }}
        >
          <Checkbox
            checkBoxId="All"
            onChange={() => handleOptionsCheckboxChange('all')}
            title={`All`}
            checked={optionsCheckedState.all}
          />
          <Checkbox
            checkBoxId="Actioned"
            onChange={() => handleOptionsCheckboxChange('actioned')}
            title={`Actioned`}
            checked={optionsCheckedState.actioned}
          />
          <Checkbox
            checkBoxId="NotActioned"
            onChange={() => handleOptionsCheckboxChange('notActioned')}
            title={`Not Actioned`}
            checked={optionsCheckedState.notActioned}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '3px auto',
            }}
          >
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'6px'}
              margin={'0px 6px'}
              color={Colors.PrimaryText}
              bgColor={Colors.White}
              type={'Button'}
              fontWeight={'400'}
              onClick={() => {
                setShowOptions(false)
              }}
            >
              Cancel
            </Button>
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'6px'}
              margin={'0px 6px'}
              color={Colors.White}
              bgColor={Colors.PrimaryText}
              type={'Button'}
              fontWeight={'400'}
              disabled={
                !Object.values(optionsCheckedState).some(
                  (value) => value === true
                )
              }
              onClick={() => {
                const filterValue = Object.entries(optionsCheckedState).find(
                  ([key, value]) => value === true
                )?.[0]
                setFilter(filterValue)

                setSummaryFilters((prev: any) => {
                  return updateFilterArray(prev, filterValue, id)
                })

                if (pageIndex != 0) gotoPage(0)
                setShowOptions(false)
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          top: '24px',
          left: '30%',
          backgroundColor: 'white',
          padding: '2px',
          width: '50%',
        }}
      >
        <Button
          hoverBgColor={Colors.White}
          hoverTextColor={Colors.PrimaryText}
          borderColor={Colors.PrimaryText}
          borderRadius={'4px'}
          hover={true}
          padding={'3px'}
          margin={'0px 3px'}
          color={Colors.White}
          bgColor={Colors.PrimaryText}
          type={'Button'}
          fontWeight={'400'}
          onClick={() => {
            handleActionedOnSaveClick()
            handleOptionsCheckboxChange('all')
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export const MultiSelectFilter = ({
  gotoPage,
  displayedRows,
  column,
  filterData,
  setSummaryFilters,
}: any) => {
  const {
    filterValue,
    setFilter,
    preFilteredRows,
    id,
    rows,
    filteredRows,
    filteredFlatRows,
  } = column

  const selectAllCheckboxRef = useRef<HTMLInputElement>(null)
  const dropdownFilterDivRef = useRef<any>(null)
  const [filters, setFilters] = useState(filterValue)
  const [filtersClickOutside, setFiltersClickOutside] = useState([])

  const [unselectedValues, setUnselectedValues] = useState<any>([])
  const [textInputFilter, setTextInputFilter] = useState('')
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [isShowDropdownOptions, setisShowDropdownOptions] = useState(false)
  const handleCancelClick = () => {
    setUnselectedValues(() => {
      if (
        (filterValue && filterValue.length === 0) ||
        filterValue === undefined ||
        filterValue === null
      ) {
        return []
      } else {
        setIsSelectAll(false)
        return filterValue
      }
    })
    setisShowDropdownOptions(false)
  }

  const handleClickOutsideDiv = (event: MouseEvent) => {
    if (
      dropdownFilterDivRef.current &&
      !dropdownFilterDivRef.current.contains(event.target)
    ) {
      setisShowDropdownOptions(false)
    }
  }

  useEffect(() => {
    if (
      filterData.length === 0 ||
      filterData === undefined ||
      filterData === null
    ) {
      setUnselectedValues([])
    }
  }, [filterData])

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDiv, true)
    return () => {
      document.addEventListener('click', handleClickOutsideDiv, true)
    }
  }, [filtersClickOutside])

  useEffect(() => {
    unselectedValues?.length === 0 && setIsSelectAll(true)
    unselectedValues?.length > 0 && setIsSelectAll(false)
  }, [unselectedValues])

  useEffect(() => {
    if (
      filterValue !== undefined &&
      filterValue !== null &&
      filterValue?.length !== 0
    ) {
      setUnselectedValues(filterValue)
    }
  }, [isShowDropdownOptions])

  const inputFilteredValues = useMemo(() => {
    return preFilteredRows.filter((row: any) => {
      const value = row.values[id]
      return value
        ?.toLowerCase()
        .includes(textInputFilter.toLowerCase().trimStart().trimEnd())
    })
  }, [preFilteredRows, id, textInputFilter])

  const dropdownValues = useMemo(() => {
    const optionsSet: any = new Set()
    inputFilteredValues.forEach((row: any) => {
      optionsSet.add(row.values[id])
    })
    const dropdownOptions = [...optionsSet].filter(
      (option) =>
        displayedRows.some((item: any) => item.original[id] == option) ||
        unselectedValues.includes(option) ||
        filterValue.includes(option)
    )
    return dropdownOptions
  }, [
    id,
    displayedRows,
    preFilteredRows,
    unselectedValues,
    isShowDropdownOptions,
    inputFilteredValues,
  ])

  const handleTextFilterOnFocus = () => {
    if (!isShowDropdownOptions) {
      if (
        filterValue === undefined ||
        filterValue === null ||
        unselectedValues?.length === 0 ||
        filterValue.length === 0
      ) {
        setUnselectedValues([])
        setIsSelectAll(true)
      } else {
        setUnselectedValues(filterValue)
      }
      setisShowDropdownOptions(true)
    }
  }

  const toggleSelectAllOption = () => {
    if (isSelectAll) {
      setIsSelectAll(false)
      setUnselectedValues(dropdownValues)
    } else {
      setUnselectedValues([])
      setIsSelectAll(true)
    }
  }

  const toggleDropdownOptions = (option: any) => {
    setUnselectedValues((prevState: any) => {
      if (prevState.includes(option)) {
        return prevState.filter((prevOption: any) => prevOption != option)
      } else {
        setIsSelectAll(false)
        return [...prevState, option]
      }
    })
  }

  const getDropdownCheckedFlag = (option: any) => {
    if (isSelectAll) {
      return true
    } else {
      return !unselectedValues.includes(option)
    }
  }

  return (
    <div ref={dropdownFilterDivRef} style={{ position: 'relative' }}>
      <>
        <input
          style={{ marginTop: '5px', maxWidth: '125px' }}
          type="text"
          value={textInputFilter}
          onChange={(e) => {
            isSelectAll && toggleSelectAllOption()
            setTextInputFilter(e.target.value)
          }}
          onFocus={() => handleTextFilterOnFocus()}
          placeholder="Filter..."
        />
        {!isSelectAll && unselectedValues.length != dropdownValues.length && (
          <FilterSVGWrapper onClick={() => handleTextFilterOnFocus()}>
            <SvgFilter12 fillColor={Colors.Black}></SvgFilter12>
          </FilterSVGWrapper>
        )}
      </>
      {isShowDropdownOptions && (
        <div
          style={{
            position: 'absolute',
            top: '20px',
            left: 0,
            border: '1px solid black',
            backgroundColor: 'white',
            padding: '2px',
            width: '100%',
          }}
        >
          <div
            style={{
              maxHeight: '250px',
              width: 'auto',
              overflowY: 'auto',
            }}
          >
            <div
              key={id}
              style={{
                margin: '3px auto',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <Checkbox
                checkBoxId={'SelectAll'}
                checked={isSelectAll}
                onChange={() => toggleSelectAllOption()}
                title="Select all"
              />
            </div>
            {dropdownValues.map((option, id) => (
              <div key={id} style={{ margin: '3px auto' }}>
                <Checkbox
                  checked={getDropdownCheckedFlag(option)}
                  onChange={() => toggleDropdownOptions(option)}
                  title={option}
                />
              </div>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '3px auto',
            }}
          >
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'6px'}
              margin={'0px 6px'}
              color={Colors.PrimaryText}
              bgColor={Colors.White}
              type={'Button'}
              fontWeight={'400'}
              onClick={() => {
                handleCancelClick()
              }}
            >
              Cancel
            </Button>
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'6px'}
              margin={'0px 6px'}
              color={Colors.White}
              bgColor={Colors.PrimaryText}
              type={'Button'}
              fontWeight={'400'}
              disabled={dropdownValues.length === unselectedValues?.length}
              onClick={() => {
                if (isSelectAll) {
                  setFilter([])
                } else {
                  setFilter(unselectedValues)
                }
                setSummaryFilters((prev: any) => {
                  return isSelectAll
                    ? updateFilterArray(prev, [], id)
                    : updateFilterArray(prev, unselectedValues, id)
                })

                gotoPage(0)
                setisShowDropdownOptions(false)
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default FreeFormInputFilter
