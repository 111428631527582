import { Bar } from "react-chartjs-2";


interface verticalChart{
    labels:string[],
    data:any,
    option?:{},
    backgroundColor:any,
    barLabel:string
}
const VerticalBarChart =({ labels,data,option,backgroundColor,barLabel }:verticalChart)=> {
  const options={
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              font: { size: 12 },
            },
          },
          y: {
            grid: {
              borderDash: [2, 2],
              color: "#e8e8e8",
            },
            ticks: {
              font: { size: 12 },
              count: 3,
            },
          },
        },
      }
  
    return <Bar data={{
        labels: labels,
        datasets: [
            {  
              barThickness: 60,
                maxBarThickness: 80,
                label: barLabel,
                data: data,
                backgroundColor: backgroundColor,
                stack: 'Stack 0',
            },
        ],
    }} options={option ?? options} />;
  }
  
  export default VerticalBarChart