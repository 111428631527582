import {
  LottieWrapper,
  RootContainerTable,
  TableWidgetContainerByRefinitiveData,
  TitleContainer
} from './styles'
import { useEffect, useState } from 'react'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles';
import Loader from "../../../../assets/lottiefiles/loader.json";
import Lottie from 'lottie-react';
import Label from '../../../label/Label';
import CreateTable from '../createTable';
import { useDispatch, useSelector } from 'react-redux';
import { betaphase3manualpricingSelector, getRefinitiveBeta, RefinitiveBetaSelector } from '../../../../store/dash-board/static-data';
import { formattingMonthYear, formattingMonthYearWithTime } from '../../../../utils/globalFunctions';


const Beta = (props: any) => {
  const dispatch = useDispatch()
  const beataPhase3Data = useSelector(betaphase3manualpricingSelector)
  const [startDate] = useState(new Date())
  const [tableData, setTableData] = useState([])
  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)
  let mm: string | number
  let firstDayOfMonth: string | number

  mm = startDate.getMonth()
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth

  const columns = [
    { Header: 'Month/Year', accessor: 'createdDate' },
    { Header: 'Beta', accessor: 'manualPricingBeta' },
    { Header: 'Phase3', accessor: 'manualPricingPhase3' },

  ]
  const [loading, isLoading] = useState<boolean>(false)
  const Table = (props: any) => {
    
    let formattedData = props.data  && props.data?.map((r: { date: string; manualPricingPhase3: number; manualPricingBeta: number }) => {
      return {
        ...r, createdDate: r.date ? formattingMonthYear(r.date) : null
      }
    });
    formattedData =formattedData?.length && formattedData?.sort(function (a: any, b: any) {
      const c = new Date(a.date);
      const d = new Date(b.date);
      return d.valueOf() - c.valueOf();
    });
    return (
      <RootContainerTable>
        <CreateTable data={formattedData || []} columns={props.columns} />
      </RootContainerTable>
    )
  }

  useEffect(() => {
    setTableData(beataPhase3Data?.staticDataViewModel)
  }, [beataPhase3Data])
  return (
    <>
      {loading && <PopUpStyleLoader>
        <LottieWrapper>
          <Lottie animationData={Loader} loop={true} />
        </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div>
      </PopUpStyleLoader>}
      <TableWidgetContainerByRefinitiveData key={'refini'}>
        {console.log("beataPhase3Data", tableData)
        }
        <TitleContainer>{props.widgetDescription}</TitleContainer>
        <Table data={tableData ? tableData : []} columns={columns} />
        {<span style={{ fontSize: "10px" }}>Data as of : { formattingMonthYearWithTime(new Date())}</span>} 

      </TableWidgetContainerByRefinitiveData>
    </>
  )
}


export default Beta
