import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/button/Button";
import DatePicker from "../../../components/datePicker/DatePicker";
import Label from "../../../components/label/Label";
import SelectDropdown from "../../../components/selectDropdown/SelectDropdown";
import Lottie from 'lottie-react';
import Loader from "../../../assets/lottiefiles/loader.json"
import { useLocation, useNavigate } from "react-router-dom";
import { formattingCurrencyWithDecimal, formattingDate, formattingNumber } from '../../../utils/globalFunctions';

import {
  getSuspenseAccount,
  suspenseAccountSelector,
  loadingSelector,
  accountConfigSelector,
  getAccountConfiguration
} from "../../../store/suspenseAccount/suspenseAccount";
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledSelectWrapper,
  StyledTableContainer,
  PopUpStyleLoader,
  StyledFilterPanel,
  StyledButtonWrapper,
  LottieWrapper
} from "../styles";
import Table from "../table/Table";
import { SvgEye } from "../../../components/svg/SvgEye";
import { Textbox } from "../../../components/textbox/Textbox";
import SelectDropdownWithCheckBox from "../../../components/selectDropdownWithCheckbox/SelectDropdown";


const SuspenseAccount = () => {
  const dispatch = useDispatch();
  const suspenseAccountData = useSelector(suspenseAccountSelector);
  const accountConfigData = useSelector(accountConfigSelector);

  const location = useLocation()
  const loading = useSelector(loadingSelector);
  const [input, setInput] = useState({
    settleDate: new Date().toISOString().split("T")[0],
    acctNbr: "",
    cusip: ""
  });

  const [assetType, setAssetType] = useState<any>({
    value: "",
    label: "  --Select--  ",
  });
  const [assetOption, setAssetOption] = useState<any>([{ value: "", label: "--Select--" },
  { value: "EQ", label: "EQ" }, { value: "FI", label: "FI" }])

  const [accountNumber, setAccountNumber] = useState<any>({
    value: "",
    label: "  --Select--  ",
  });
  const [accountNumbers, setAccountNumbers] = useState<any>([{ value: "", label: "--Select--" }])



  useEffect(() => {
    dispatch(getAccountConfiguration())
  }, []);

  const getSuspenseData = async () => {
    const paramValues: any = location.state
    const accountNumberArray: any = []
    if (accountNumber && accountNumber.length > 0) {
      accountNumber.forEach((element: any) => {
        accountNumberArray.push(element.value)
      })
    }
    await dispatch(
      getSuspenseAccount(
        "search",
        assetType.value?.length > 0 ? assetType.value : null,
        input.settleDate,
        input.cusip && input.cusip.length ? input.cusip : null,
        accountNumberArray && accountNumberArray.length > 0 ? accountNumberArray.toString() : null,
        null
      )
    );
   
  }

  useEffect(() => {
    const paramValues: any = location.state
    const tempAccount: any = []
    if (accountConfigData && accountConfigData.accountNumbers && accountConfigData.accountNumbers.length) {
      accountConfigData.accountNumbers.forEach((element: any) => {
        tempAccount.push({ "value": element.accountNumber, "label": element.accountNumber })
        setAccountNumbers(tempAccount)
        setAccountNumber([{
          value: paramValues && paramValues.passProps ? paramValues.passProps.accountNbr : null,
          label: paramValues && paramValues.passProps ? paramValues.passProps.accountNbr : null
        }])
      })
      setAssetType({
        value: paramValues && paramValues.passProps ? paramValues.passProps.assetType : null,
        label: paramValues && paramValues.passProps ? paramValues.passProps.assetType : null
      })
    }
    else {
      const tempAccount = [...accountNumbers, {
        value: paramValues && paramValues.passProps ? paramValues.passProps.accountNbr : null,
        label: paramValues && paramValues.passProps ? paramValues.passProps.accountNbr : null
      }]
      setAccountNumbers(tempAccount)
      setAccountNumber([{
        value: paramValues && paramValues.passProps ? paramValues.passProps.accountNbr : null,
        label: paramValues && paramValues.passProps ? paramValues.passProps.accountNbr : null
      }])
      setAssetType({
        value: paramValues && paramValues.passProps ? paramValues.passProps.assetType : null,
        label: paramValues && paramValues.passProps ? paramValues.passProps.assetType : null
      })
    }

    if (accountNumber?.length) {
      getSuspenseData()
    }
  }, [accountConfigData]);


  const handleSearch = async () => {
    const paramValues: any = location.state
    const accountNumberArray: any = []
    if (accountNumber && accountNumber.length > 0) {
      accountNumber.forEach((element: any) => {
        accountNumberArray.push(element.value)
      })
    }
    await dispatch(
      getSuspenseAccount(
        "search",
        assetType.value?.length > 0 ? assetType.value : null,
        input.settleDate,
        input.cusip && input.cusip.length ? input.cusip : null,
        accountNumberArray && accountNumberArray.length > 0 ? accountNumberArray.toString() : null,
        null
      )
    );
  };

  return (
    <RootContainer>
      {loading && <PopUpStyleLoader>
        <LottieWrapper>
          <Lottie animationData={Loader} loop={true} />
        </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div>
      </PopUpStyleLoader>}
      <StyledPanelHead>
        <StyledPanelHeadTitle>Suspense Account Market Value</StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledFilterPanel>
          <StyledSelectWrapper>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Asset Type
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="204px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={assetOption}
                value={assetType}
                onChange={(options) => {
                  setAssetType({ value: options, label: options });
                }}
                defaultValue={{
                  value: "",
                  label: "--Select--",
                }}
              />{" "}
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Settle Date
              </Label>
              <DatePicker
                onChange={(e: any) => {
                  setInput({ ...input, settleDate: e });
                }}
                value={input.settleDate}
                max={new Date().toISOString().split("T")[0]}
                bgColor=""
                borderColor=""
                placeHolder="From"
                padding="4px 8px"
                maxWidth="295px"
                defaultValue={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >CUSIP</Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                onChange={(e: any) => {
                  setInput({ ...input, cusip: e });
                }}
                placeholder={"CUSIP"}
                value={input.cusip}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >Account Number</Label>
              <SelectDropdownWithCheckBox
                height="32px"
                buttonWidth="300px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                isMulti={true}
                options={accountNumbers}
                optionValues={accountNumbers}
                value={accountNumber}
                onChange={(options) => {
                  setAccountNumber(options);
                }}
                defaultValue={{
                  value: "",
                  label: "--Select--",
                }}
                labelValue={"Accounts"}
                allowSelectAll={true}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                flexWrap={'nowrap'}
              />{" "}
            </div>

            <StyledButtonWrapper>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={"4px"}
                hover={true}
                padding={"4px 16px"}
                bgColor={"#2563EB"}
                color={"White"}
                type={"Button"}
                onClick={() => {
                  handleSearch();
                }}
                borderColor={"#2563EB"}
              >
                Search
              </Button>
            </StyledButtonWrapper>


          </StyledSelectWrapper>

        </StyledFilterPanel>
        <StyledTableContainer>
          {suspenseAccountData ? <Table
            hidePagination={false}
            heading={"Suspense Account"}
            data={suspenseAccountData && suspenseAccountData.suspenseAccountDetailsModelList ? suspenseAccountData.suspenseAccountDetailsModelList : []}
            columns={[
              {
                Header: " ",
                columns: [
                  {
                    Header: "Report Date",
                    accessor: (d: any) => { return formattingDate(d.reportDate) }
                  },

                  {
                    Header: "AccountNumber",
                    accessor: "accountNumber",
                  },
                  {
                    Header: "Cusip",
                    accessor: "cusip",
                  },
                  {
                    Header: "Symbol",
                    accessor: "symbol",
                  },
                  {
                    Header: "Description",
                    accessor: "description",
                  },
                  {
                    Header: "Desc2",
                    accessor: "desc2",
                  },
                  {
                    Header: "Active Date",
                    accessor: (d: any) => { return formattingDate(d.activeDate) }
                  },
                  {
                    Header: "SecType",
                    accessor: "secType",
                  },
                  {
                    Header: "Price",
                    accessor: "price"
                  },
                  {
                    Header: "Market Value",
                    accessor: "marketValue"
                  },
                  {
                    Header: "Trade Quantity",
                    accessor: (d: any) => {
                      if (d.tradeQuantity) {
                        return formattingNumber(d.tradeQuantity)
                      }
                    },
                  },
                  {
                    Header: "Settle Quantity",
                    accessor: (d: any) => {
                      if (d.settleQuantity) {
                        return formattingNumber(d.settleQuantity)
                      }
                    },
                  },


                ],
              },
            ]}
          /> : null}
        </StyledTableContainer>

      </StyledPanelSelect>
    </RootContainer>
  );
};
export default SuspenseAccount;
