/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import { useDispatch, useSelector } from 'react-redux'
import {
  BackwardButtonWrapper,
  ColoumnPaddingTop,
  ForwardButtonWrapper,
  GoToPageWrapper,
  NextPriviousWrapper,
  PaginationContainer,
  RootContainerTable,
} from './styles'
import { useEffect, useState } from 'react'
import { usePagination, useSortBy, useTable } from 'react-table'
import Label from '../../../../components/label/Label'
import SvgBackwardDoubleChevron from '../../../../components/svg/SvgBackwardDoubleChevron'
import { COLORS } from '../../../../theme/Colors'
import SvgForwardDoubleChevron from '../../../../components/svg/SvgForwardDoubleChevron'
import { LottieWrapper, PopUpStyleLoader, StyledSelectWrapper } from '../styles'
import Lottie from 'lottie-react'
import Loader from '../../../../assets/lottiefiles/loader.json'
import {
  getBetaLoanNetReconReport,
  isLoadingSelector,
} from '../../../../store/operations-Control/BETALoanetReconReport'

const CreateTable = (props: any) => {
  const loading = useSelector(isLoadingSelector)
  let [sortDirect, setSortDirect] = useState<any>('ASC')
  let [sortBy, setSortBy] = useState<any>('')
  const [sortAction, setSortAction] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [tempEvent, setTempEvent] = useState<string>('')
  const [pageNumberCall, setPageNumberCall] = useState<any>(1)
  const [pageStatus, setPageStatus] = useState<boolean>(false)
  const [timeoutId, setTimeoutId] = useState()
  let data = props.data
  const totalRecords = props.totalRecords

  useEffect(() => {
    data = props.data
  }, [sortBy])

  useEffect(() => {
    setPageNumberCall(1)
  }, [totalRecords])

  const columns = props.columns
  let controlledPageCount: number
  controlledPageCount = Math.ceil(totalRecords / 20)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: { pageIndex: 0, pageSize: 20 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  )

  const pageCallFunction = async (pageIndex: any, rowRange: any) => {
    await dispatch(
      getBetaLoanNetReconReport(
        'search',
        props.startDate,
        pageIndex - 1,
        rowRange
      )
    )
  }

  useEffect(() => {
    if (pageStatus) {
      pageCallFunction(pageNumberCall, 20)
    }
  }, [pageNumberCall])

  const onChangeFunction = (pageValue: number) => {
    if (pageValue > 0 && pageValue <= pageCount) {
      const pageNumber = pageValue ? pageValue : 1
      setPageStatus(true)
      clearTimeout(timeoutId)
      const timeout: any = setTimeout(() => {
        gotoPage(pageNumber)
        setPageNumberCall(pageNumber)
      }, 1000)
      setTimeoutId(timeout)
    }
  }

  return (
    <>
      <table {...getTableProps()}>
        {loading && (
          <PopUpStyleLoader>
            <StyledSelectWrapper style={{ flexDirection: 'column' }}>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LottieWrapper style={{ left: '53.5%' }}>
                {' '}
                Loading...
              </LottieWrapper>
            </StyledSelectWrapper>
          </PopUpStyleLoader>
        )}
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
              {headerGroup.headers.map((column: any, index) => (
                <th
                  key={index}
                  onClick={(e) => {
                    //trigger the react-table header onClick manually
                    // column
                    //   .getHeaderProps(column.getSortByToggleProps())
                    //   .onClick(e)
                    // sorting(column.id)
                  }}
                >
                  {column.render('Header')}

                  <ColoumnPaddingTop>
                    {/* {column.id == tempEvent ? (sortAction ? '🔼' : '🔽 ') : ''} */}
                  </ColoumnPaddingTop>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data.length == 0 && (
        <>
          <Label fontWeight={700} margin="5% 0 0 50%">
            No Records Found
          </Label>
          <Label fontWeight={700} margin="0.5% 0 0 49%">
            No Exceptions Identified
          </Label>
        </>
      )}

      <PaginationContainer>
        <Label>
          Page{' '}
          <strong>
            {pageNumberCall} of {pageOptions.length}
          </strong>{' '}
        </Label>
        <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
        <GoToPageWrapper>
          <Label>Go to page:</Label>
          <input
            type="number"
            min="1"
            max={pageCount}
            defaultValue={pageNumberCall}
            onChange={(e) => {
              onChangeFunction(Number(e.target.value))
            }}
            style={{ width: '50px' }}
          />
        </GoToPageWrapper>
        <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
        <NextPriviousWrapper>
          <BackwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(1)
              setPageNumberCall(1)
            }}
          >
            {pageNumberCall !== 1 ? (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </BackwardButtonWrapper>
          <button
            onClick={() => {
              setPageStatus(true)
              previousPage()
              setPageNumberCall(pageNumberCall - 1)
            }}
            disabled={pageNumberCall === 1}
            style={{
              border: 'none',
              background: '#FCFDFF',
              cursor: 'pointer',
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              setPageStatus(true)
              nextPage()
              setPageNumberCall(pageNumberCall + 1)
            }}
            disabled={pageNumberCall === pageCount}
            style={{
              border: 'none',
              background: '#FCFDFF',
              cursor: 'pointer',
            }}
          >
            Next
          </button>
          <ForwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(pageCount)
              setPageNumberCall(pageCount)
            }}
          >
            {pageNumberCall !== pageCount ? (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </ForwardButtonWrapper>
        </NextPriviousWrapper>
      </PaginationContainer>
    </>
  )
}

const ReportDetailTable = (props: any) => {
  return (
    <RootContainerTable>
      <CreateTable
        columns={props.columns}
        data={props.data}
        startDate={props.startDate}
        totalRecords={props.totalRecords}
      />
    </RootContainerTable>
  )
}

export default ReportDetailTable
