import { useEffect, useState } from 'react'
import DatePicker from '../../../components/datePicker/DatePicker'
import Label from '../../../components/label/Label'
import {
  ButtonIconWrapper,
  LottieWrapper,
  PopUpStyleLoader,
  RootContainer,
  StyledButtonWrapper,
  StyledFilterPanel,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledSelectWrapper,
  StyledTableContainer,
  ToastWrapper,
} from './styles'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import Button from '../../../components/button/Button'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { COLORS } from '../../../theme/Colors'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import Table from '../../../components/table/Table'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import { useDispatch, useSelector } from 'react-redux'
import {
  ErrorReportDataSelector,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  LoadingSelector,
  getCaisErrorsCSVReport,
  getErrorReportData,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../store/dash-board/error-report'
import BreadCrumb from '../../../components/BreadCrumb/breadCrumbs'
import { useLocation } from 'react-router-dom'
import { Toast } from '../../../components/toast/Toast'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'

const ErrorsReport = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const errorReportData = useSelector(ErrorReportDataSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const loading = useSelector(LoadingSelector)
  const [reportDate, setReportDate] = useState(
    new Date().toISOString().split('T')[0]
  )
  const [caisReportType, setCaisReportType] = useState<string>('')
  const [caisSource, setCaisSource] = useState<string>('')
  const [caisLob, setCaisLob] = useState<string>('')
  const [caisErrorType, setCaisErrorType] = useState<string>('')

  const reportType = [
    {
      value: '',
      label: '--Select--',
    },
    {
      value: 'Feedback',
      label: 'Feedback',
    },
    {
      value: 'Submission',
      label: 'Submission',
    },
  ]

  const sourceValues = [
    {
      value: '',
      label: '--Select--',
    },
    {
      value: 'Beta',
      label: 'BETA',
    },
    {
      value: 'P3',
      label: 'P3',
    },
  ]

  const lobValues = [
    {
      value: '',
      label: '--Select--',
    },
    {
      value: 'ACAPS',
      label: 'ACAPS',
    },
    {
      value: 'wealthManagement',
      label: 'Wealth Management',
    },
    {
      value: 'capitalMarkets',
      label: 'Capital Markets',
    },
  ]

  const errorType = [
    {
      value: '',
      label: '--Select--',
    },
    {
      value: 'Data Validation',
      label: 'Data Validation',
    },
    {
      value: 'Warning',
      label: 'Warning',
    },
  ]

  const ErrorReportColumns = [
    {
      Header: 'LOB',
      accessor: 'lob',
    },
    {
      Header: 'ErrorType',
      accessor: 'errorType',
    },
    {
      Header: 'Error Code',
      accessor: 'errorCode',
    },
    {
      Header: 'Error Description',
      accessor: 'errorCodeDescription',
    },
    {
      Header: 'Error Count',
      accessor: 'errorCount',
    },
  ]

  useEffect(() => {
    const paramValues: any = location.state
    setReportDate(paramValues.passProps.date)
    const getReportData = async () => {
      const response = await dispatch(
        getErrorReportData(
          'search',
          paramValues.passProps.date,
          paramValues.passProps.row.reportType,
          paramValues.passProps.source,
          caisLob,
          paramValues.passProps.row.errorType
        )
      )
    }
    getReportData()
  }, [])

  const handleSearch = async () => {
    const response = await dispatch(
      getErrorReportData(
        'search',
        reportDate,
        caisReportType,
        caisSource,
        caisLob,
        caisErrorType
      )
    )
  }

  const DownloadCSV = async () => {
    const response = await dispatch(
      getCaisErrorsCSVReport(
        `CAISDetailedErrorsReport__${reportDate}`,
        reportDate,
        caisReportType,
        caisSource,
        caisLob,
        caisErrorType
      )
    )
  }

  return (
    <RootContainer>
      <BreadCrumb />
      <StyledPanelHead>
        <StyledPanelHeadTitle>CAIS detailed errors report</StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledFilterPanel>
          <StyledSelectWrapper>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Report Date
                <span style={{ color: 'red' }}>*</span>
              </Label>
              <DatePicker
                padding={'4px 8px'}
                onChange={(e: string) => {
                  setReportDate(e)
                }}
                value={reportDate}
                max={new Date().toISOString().split('T')[0]}
                bgColor=""
                borderColor=""
                placeHolder="From"
                maxWidth="295px"
                defaultValue={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                ReportType
                <span style={{ color: 'red' }}>*</span>
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={reportType}
                onChange={(options) => {
                  setCaisReportType(options)
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Source
                <span style={{ color: 'red' }}>*</span>
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={sourceValues}
                onChange={(options) => {
                  setCaisSource(options)
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                LOB
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={lobValues}
                onChange={(options) => {
                  setCaisLob(options)
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Error Type
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={errorType}
                onChange={(options) => {
                  setCaisErrorType(options)
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <StyledButtonWrapper>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
              <ButtonIconWrapper
                onClick={() => {
                  DownloadCSV()
                }}
              >
                <SvgDownloadSmall
                  fillColor={`${COLORS.UI.Primary50}`}
                ></SvgDownloadSmall>

                <WDStyledButtonText>
                  <Label>CSV Download</Label>
                </WDStyledButtonText>
              </ButtonIconWrapper>
            </StyledButtonWrapper>
          </StyledSelectWrapper>
        </StyledFilterPanel>
      </StyledPanelSelect>
      <StyledTableContainer>
        <WDCard>
          <CardHeader
            label={'CAIS detailed errors report'}
            icon={false}
            amountValue={``}
          />
        </WDCard>
        <Table
          tableColumns={ErrorReportColumns}
          tableData={errorReportData.data ? errorReportData.data : []}
          onClick={() => {
            //
          }}
        />
      </StyledTableContainer>
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default ErrorsReport
