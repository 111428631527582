import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { StyledTableContainer, PopUpSideBar } from '../SettlementCommonStyles'
import Table from '../table/Table'
import { BetaSecLendingColumns } from './BetaSecLendingColumns'
import Comments from '../../../composites/comments/comments'
import {
  getBetaSecLendingComments,
  saveBetaSecLendingComment,
} from '../../../store/betaSecLending/beta-sec-lending'
import { getAccessFlag } from '../../../utils/globalFunctions'

interface IBetaSecLendingDetailsProps {
  data: any
  totalCount: number
}

const BetaSecLendingDetails = ({
  data,
  totalCount,
}: IBetaSecLendingDetailsProps) => {
  const dispatch = useDispatch()
  const [tableCommmentsData, setTableCommmentsData] = useState<any>([])
  const commentViewReceive = useRef<HTMLInputElement>()
  const [toggleComments, SetToggleComments] = useState<boolean>(false)
  const [accessFlag, setAccessFlag] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [selectedRow, setSelectedRow] = useState<any>([])
useEffect(()=>{
  setAccessFlag(getAccessFlag(['SS-RWX','OM-RWX']))
},[])
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        commentViewReceive?.current &&
        !commentViewReceive?.current?.contains(event.target)
      ) {
        SetToggleComments(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside, {
      capture: true,
    })
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, {
        capture: true,
      })
    }
  }, [commentViewReceive])

  const setCommentTable = async (select_row: any) => {
    const getCommentsData = await dispatch(
      getBetaSecLendingComments(
        select_row.snapShotDate,
        select_row.accountNumber,
        select_row.securityNumber
      )
    )
    if (getCommentsData && getCommentsData.betaSecLendingGetCommentsModel) {
      setTableCommmentsData([...getCommentsData.betaSecLendingGetCommentsModel])
    }
  }

  const toggleViewComments = async (select_row: any) => {
    setSelectedRow(select_row)
    setComment('')
    if (!toggleComments) {
      await setCommentTable(select_row)
    }
    SetToggleComments(!toggleComments)
  }
  const commentsOnChange = (e: any) => {
    const { value } = e.target
    setComment(value)
  }

  const commentTrade = async () => {
    const request = {
      snapShotDate: selectedRow.snapShotDate,
      accountNumber: selectedRow.accountNumber,
      securityNumber: selectedRow.securityNumber,
      comments: comment,
    }
    await dispatch(saveBetaSecLendingComment(request))
    setComment('')
    await setCommentTable(selectedRow)
  }

  return (
    <StyledTableContainer>
      <Table
        hidePagination={false}
        heading={'Beta Securities Report'}
        data={data && data?.length ? data : []}
        totalCount={totalCount ? totalCount : 0}
        columns={BetaSecLendingColumns(toggleViewComments,accessFlag)}
      />
      {toggleComments && (
        <PopUpSideBar ref={commentViewReceive}>
          <Comments
            tableCommmentsData={tableCommmentsData}
            commentsOnChange={commentsOnChange}
            comment={comment}
            commentTrade={commentTrade}
            ftrRowData={selectedRow}
          />
        </PopUpSideBar>
      )}
    </StyledTableContainer>
  )
}

export default BetaSecLendingDetails
