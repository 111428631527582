/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  outStanding: any
  isLoading: boolean
  action: string
  addComment: any
  comments: any
  httpErrorMessage: string
  successMessage: string
}

let initialState: State = {
  outStanding: {},
  isLoading: false,
  action: '',
  addComment: {},
  comments: {},
  httpErrorMessage: '',
  successMessage: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'out-standing',
  initialState,
  reducers: {
    SetOutStandingDetails: (state: State, action: PayloadAction<any>) => {
      return {
        ...state,
        outStanding: action.payload.data,
      }
    },

    SetIsLoading: (state: State, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      }
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  SetOutStandingDetails,
  SetIsLoading,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getOutStandingDetails: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoOutStandingBuyInDetail        `,
        {
          ...payload,
          pageNumber: 0,
          pageSize: 0,
          sortBy: 'CUSIP',
          sortDirection: 'ASC',
        }
      )

      if (status === 200) {
        dispatch(
          SetOutStandingDetails({
            data: data,
          })
        )
      }
      dispatch(SetIsLoading(false))
      return data
    } catch (e: any) {
      dispatch(
        SetOutStandingDetails({
          data: {},
        })
      )
      dispatch(SetIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
  }

export const getOutStandingsExcel: any =
  (fileName: string, payload: {}) => async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/GetRegShoOutStandingBuyInExcelExport',
        {
          ...payload,
          pageNumber: 0,
          pageSize: 0,
          sortBy: 'CUSIP',
          sortDirection: 'ASC',
        },
        { responseType: 'blob' }
      )
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(SetIsLoading(false))
        dispatch(setSuccessMessage('File Downloaded'))
        return status
      }
    } catch (e: any) {
      dispatch(SetIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const OutStandingSelector = (store: StoreState) =>
  store.Settlement.outStanding.outStanding

export const loadingSelector = (store: StoreState) =>
  store.Settlement.outStanding.isLoading

export const HttpErrorMessageSelector = (store: StoreState) =>
  store.Settlement.outStanding.httpErrorMessage

export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.Settlement.outStanding.successMessage

export default reducer
