import { combineReducers } from '@reduxjs/toolkit';
import countryCode from './country-code';
import P3Recon from './P3-Recon'
import SecuritiesToP3 from './securities-to-p3'
import P3MarketMakerSec from './P3-Market-Maker-Sec'
import countryCodeRecon from './country-code-recon'
import stalePriceBeta from './stale-pricing-beta'
import stalePriceP3 from './stale-pricing-p3'
import reconHistory from './recon-history'
export const valdiTrans = combineReducers({
    SecuritiesToP3,
    P3Recon,
    countryCode,
    P3MarketMakerSec,
    countryCodeRecon,
    stalePriceBeta,
    stalePriceP3,
    reconHistory
})