/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall, authApiUpload } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  betaStalePriceData: []
  betaStalePriceDataHistory: []
  isLoading: boolean
  action: string
  status: string
  response: boolean
  sorting: any
  sortDirection: any
  pageNumber: number
  TotalCount: number
}

const initialState: State = {
  betaStalePriceData: [],
  betaStalePriceDataHistory: [],
  isLoading: false,
  action: '',
  status: '',
  response: false,
  sorting: 'SecurityNbr, ChangedDate',
  sortDirection: 'ASC',
  pageNumber: 0,
  TotalCount: 0,
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'stale-pricing-beta',
  initialState,
  reducers: {
    SetBetaStalePriceData: (state: State, action: PayloadAction<any>) => {
      state.betaStalePriceData = action.payload.data
    },
    SetBetaStalePriceDataHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      state.betaStalePriceDataHistory = action.payload.data
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    updateTableDetails: (state: State, action: PayloadAction<any>) => {
      state.betaStalePriceData = action.payload.currentTable
    },
    setSorting: (state: State, action: PayloadAction<any>) => {
      state.sorting = action.payload
    },
    setSortDirection: (state: State, action: PayloadAction<any>) => {
      state.sortDirection = action.payload
    },
    setResponse: (state: State, action: PayloadAction<boolean>) => {
      state.response = action.payload
    },
    setStatusMessage: (state: State, action: PayloadAction<string>) => {
      state.status = action.payload
    },
    setPageNumberBeta: (state: State, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    setTotalCount: (state: State, action: PayloadAction<number>) => {
      state.TotalCount = action.payload
    },
  },
})

export const {
  SetBetaStalePriceData,
  SetBetaStalePriceDataHistory,
  setIsLoading,
  setAction,
  setSorting,
  setSortDirection,
  setStatusMessage,
  setResponse,
  setPageNumberBeta,
  setTotalCount,
  updateTableDetails,
} = actions

export const AddressData: any = () => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.get(
      'opsstaticdataapi/StaticData/GetStalePrice_Contact'
    )

    if (status === 200) {
      dispatch(setIsLoading(false))
      return data
    }
  } catch (e: any) {
    dispatch(setIsLoading(false))
    handleHttpError(dispatch, e, setStatusMessage)
    // if (e.response.status === 401) return e.response.status
  }
}
export const addContact: any =
  (
    id: number,
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    phone: string,
    notes: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/AddStalePrice_Contact',
        {
          id,
          firstName,
          lastName,
          company,
          email,
          phone,
          notes,
        }
      )
      if (status === 200) {
        dispatch(setStatusMessage('Added Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
    }
  }

export const updateContact: any =
  (
    id: number,
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    phone: string,
    notes: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.put(
        'opsstaticdataapi/StaticData/UpdateStalePrice_Contact',
        {
          id,
          firstName,
          lastName,
          company,
          email,
          phone,
          notes,
        }
      )
      if (status === 200) {
        dispatch(setStatusMessage('Updated Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
    }
  }
export const deleteContact: any =
  (
    id: number,
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    phone: string,
    notes: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.delete(
        'opsstaticdataapi/StaticData/DeleteStalePrice_Contact',
        {
          data: {
            id,
            firstName,
            lastName,
            company,
            email,
            phone,
            notes,
          },
        }
      )
      if (status === 200) {
        dispatch(setStatusMessage('Deleted Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
    }
  }

export const SendMail: any =
  (Subject: string, ToEmail: string, Body: string, Attachments: any) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiUpload.post(
        '/opsstaticdataapi/StaticData/StalePrice_SendMail',
        {
          Subject,
          ToEmail,
          Body,
          Attachments,
        }
      )
      if (status === 200) {
        dispatch(setStatusMessage('Mail Send Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        return true
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      return false
    }
  }
export const bbgUpload: any =
  (FormFile: any, Source: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiUpload.post(
        '/opsstaticdataapi/StaticData/StalePrice_BackGroundUpload',
        {
          FormFile,
          Source,
        }
      )
      if (status === 200) {
        dispatch(
          setStatusMessage(
            `File upload successfully completed, processing in progress. \n Email will be sent to 'SecurityMaster@wedbush.com' once processing is completed.`
          )
        )
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        return 200
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      dispatch(setResponse(false))
      return [e?.response?.status, e?.response?.data]
    }
  }
export const UpdateNote: any =
  (
    system: string,
    securityNumber: string,
    notes: string,
    cusip: string,
    BoxLocation: string,
    notes_LastUpdatedBy: string,
    notes_LastUpdatedOn: string,
    reportRunDate: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        '/opsstaticdataapi/StaticData/UpdateStalePrice_Notes',
        {
          system,
          securityNumber,
          cusip,
          notes,
          notes_LastUpdatedBy,
          notes_LastUpdatedOn,
        }
      )

      if (status === 200) {
        dispatch(setStatusMessage('Added Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        return status
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      return
    }
  }
export const getToMail: any =
  (ToMail: string) => async (dispatch: Dispatch) => {
    try {
      const { data, status } = await authApiCall.get(
        '/opsstaticdataapi/StaticData/GetToMailFromAddressBook',
        {
          params: {
            ToMail,
          },
        }
      )

      if (status === 200) {
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setStatusMessage)
    }
  }
export const getComments: any =
  (
    system: string,
    cusip: string,
    securityNumber: string,
    IsHistoric: boolean,
    ReportRunDate: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `/opsstaticdataapi/StaticData/GetStalePriceComments?system=${system}&cusip=${cusip}&securityNumber=${securityNumber}&IsHistoric=${IsHistoric}&ReportRunDate=${ReportRunDate}`
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setStatusMessage)
    }
  }
export const getBetaStalePriceData: any =
  (
    action: string,
    system: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    totalCount: number,
    securityNbr: string,
    top_Category: string,
    sub_Category: string,
    reviewer_LatestComment: string,
    lastPrice_In_Current_StaleReport: string,
    followup_Flag: string,
    securityDesc: string,
    cusip: string,
    isiN_Number: string,
    comments_Created_By: string,
    reviewer_Last_Commented_Date: string,
    icE_Last_Priced: string,
    icE_Last_Price: string,
    icE_Notes: string,
    bbG_Last_Priced: string,
    bbG_Last_Price: string,
    box_Location: string,
    box_Description: string,
    country: string,
    securityTypeCode: string,
    expirationDate: string,
    inDefault: string,
    corporateAction1: string,
    corporateAction2: string,
    surf_User_Pricing_Manual: string,
    surf_LastUpdated: string,
    surf_LastPrice: string,
    userIntial: string,
    changedDate: string,
    marketPrice: string,
    changeTerminal: string,
    dtC_Premed: string,
    lastUpdateDate: string,
    lastPrice: string,
    s_D: string,
    cash: string,
    margin: string,
    ira: string,
    upX_Z: string,
    currently_Still_in_StaleReport: string,
    last_Priced_In_Current_StaleReport: string,
    commentFilter: string,
    notes: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/GetBetaStalePriceReport',
        {
          system,
          fromDate,
          toDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          totalCount,
          securityNbr,
          top_Category,
          sub_Category,
          reviewer_LatestComment,
          lastPrice_In_Current_StaleReport,
          followup_Flag,
          securityDesc,
          cusip,
          isiN_Number,
          comments_Created_By,
          reviewer_Last_Commented_Date,
          icE_Last_Priced,
          icE_Last_Price,
          icE_Notes,
          bbG_Last_Priced,
          bbG_Last_Price,
          box_Location,
          box_Description,
          country,
          securityTypeCode,
          expirationDate,
          inDefault,
          corporateAction1,
          corporateAction2,
          surf_User_Pricing_Manual,
          surf_LastUpdated,
          surf_LastPrice,
          userIntial,
          changedDate,
          marketPrice,
          changeTerminal,
          dtC_Premed,
          lastUpdateDate,
          lastPrice,
          s_D,
          cash,
          margin,
          ira,
          upX_Z,
          currently_Still_in_StaleReport,
          last_Priced_In_Current_StaleReport,
          commentFilter,
          notes,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetBetaStalePriceData({
            data: data,
          })
        )
        dispatch(
          setTotalCount({
            data: data.totalCount,
          })
        )
        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      dispatch(
        SetBetaStalePriceData({
          data: '',
        })
      )
      return null
    }
  }
export const getBetaStalePriceDataHistory: any =
  (
    action: string,
    system: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    totalCount: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/GetBetaStalePriceReportArchive',
        {
          system,
          fromDate,
          toDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          totalCount,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetBetaStalePriceDataHistory({
            data: data,
          })
        )
        dispatch(
          setTotalCount({
            data: data.totalCount,
          })
        )
        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(
        SetBetaStalePriceDataHistory({
          data: '',
        })
      )
      return null
    }
  }

export const getBetaStalePriceDataCSV: any =
  (
    fileName: string,
    system: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    totalCount: number,
    securityNbr: string,
    securityDesc: string,
    cusip: string,
    top_Category: string,
    sub_Category: string,
    reviewer_LatestComment: string,
    lastUpdateDate: string,
    lastPrice: string,
    box_Location: string,
    box_Description: string,
    country: string,
    securityTypeCode: string,
    expirationDate: string,
    maturityDate: string,
    inDefault: string,
    corporateAction1: string,
    corporateAction2: string,
    surf_User_Pricing_Manual: string,
    surf_LastUpdated: string,
    surf_LastPrice: string,
    userIntial: string,
    changedDate: string,
    marketPrice: string,
    changeTerminal: string,
    icE_Last_Priced: string,
    icE_Last_Price: string,
    icE_Notes: string,
    bbG_Last_Priced: string,
    bbG_Last_Price: string,
    dtC_Premed: string,
    s_D: string,
    cash: string,
    margin: string,
    ira: string,
    upX_Z: string,
    currently_Still_In_StaleReport: string,
    last_Priced_In_Current_StaleReport: string,
    lastPrice_In_Current_StaleReport: string,
    commentFilter: string,
    notes: string,
    followup_Flag: string,
    isiN_NUMBER: string,
    reviewer_Last_Commented_Date: string,
    comments_Created_By: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/GetBetaStalePriceReport_CsvDownload',
        {
          system,
          fromDate,
          toDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          totalCount,
          securityNbr,
          securityDesc,
          cusip,
          top_Category,
          sub_Category,
          reviewer_LatestComment,
          lastUpdateDate,
          lastPrice,
          box_Location,
          box_Description,
          country,
          securityTypeCode,
          expirationDate,
          maturityDate,
          inDefault,
          corporateAction1,
          corporateAction2,
          surf_User_Pricing_Manual,
          surf_LastUpdated,
          surf_LastPrice,
          userIntial,
          changedDate,
          marketPrice,
          changeTerminal,
          icE_Last_Priced,
          icE_Last_Price,
          icE_Notes,
          bbG_Last_Priced,
          bbG_Last_Price,
          dtC_Premed,
          s_D,
          cash,
          margin,
          ira,
          upX_Z,
          currently_Still_In_StaleReport,
          last_Priced_In_Current_StaleReport,
          lastPrice_In_Current_StaleReport,
          commentFilter,
          notes,
          followup_Flag,
          isiN_NUMBER,
          reviewer_Last_Commented_Date,
          comments_Created_By,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        dispatch(setStatusMessage('File Downloaded'))
        return status
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      return 400
    }
  }
export const getBetaStalePriceDataCSVHistory: any =
  (
    fileName: string,
    system: string,
    fromDate: string,
    toDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    totalCount: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/GetBetaArchiveStalePriceReport_CsvDownload',
        {
          system,
          fromDate,
          toDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          totalCount,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setStatusMessage('File Downloaded'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      return 400
    }
  }

export const putUserBulkComment: any = (payload: any) => async () => {
  try {
    const { status } = await authApiCall.post(
      'opsstaticdataapi/StaticData/AddStalePriceComments',
      payload
    )

    if (status === 200) {
      return status
    }
  } catch {
    return 400
  }
}

export const updateFlagBulk: any = (payload: any) => async () => {
  try {
    const { status } = await authApiCall.post(
      'opsstaticdataapi/StaticData/UpdateStalePrice_FollowUpFlag',
      payload
    )

    if (status === 200) {
      return status
    }
  } catch {
    return 400
  }
}

export const updateFlag: any =
  (
    system: string,
    securityNumber: string,
    cusip: string,
    followUp_Flag: boolean,
    currentTable: any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/StaticData/UpdateStalePrice_FollowUpFlag`,
        [
          {
            system,
            cusip,
            securityNumber,
            followUp_Flag,
          },
        ]
      )

      if (status === 200) {
        dispatch(setStatusMessage('Updated Successfully'))
        dispatch(
          updateTableDetails({
            currentTable,
          })
        )
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
        return status
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      return
    }
  }

export const AddComment: any =
  (
    system: string,
    securityNumber: string,
    comment: string,
    cusip: string,
    BoxLocation: string,
    createdBy: string,
    createdOn: string,
    reportRunDate: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        '/opsstaticdataapi/StaticData/AddStalePriceComments',
        [
          {
            securityNumber,
            cusip,
            BoxLocation,
            system,
            comment,
            createdBy,
            createdOn,
            reportRunDate,
          },
        ]
      )

      if (status === 200) {
        dispatch(setStatusMessage('Added Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))

        return status
      }
    } catch (e: any) {
      dispatch(setResponse(false))
      handleHttpError(dispatch, e, setStatusMessage)
      dispatch(setIsLoading(false))
      return
    }
  }

export const dataSelector = (store: StoreState) =>
  store.valdi.stalePriceBeta.betaStalePriceData
export const dataSelectorHistory = (store: StoreState) =>
  store.valdi.stalePriceBeta.betaStalePriceDataHistory
export const currentDataSelector = (store: StoreState) =>
  store.valdi.stalePriceBeta.data
export const LoadingSelector = (store: StoreState) =>
  store.valdi.stalePriceBeta.isLoading
export const actionSelector = (store: StoreState) =>
  store.valdi.stalePriceBeta.action
export const sortingSelectorBeta = (store: StoreState) =>
  store.valdi.stalePriceBeta.sorting
export const sortDirectionSelectorBeta = (store: StoreState) =>
  store.valdi.stalePriceBeta.sortDirection
export const statusSelector = (store: StoreState) =>
  store.valdi.stalePriceBeta.status
export const ResponseSelector = (store: StoreState) =>
  store.valdi.stalePriceBeta.response
export const PageNumSelectorBeta = (store: StoreState) =>
  store.valdi.stalePriceBeta.pageNumber
export const TotalCountSelectorBeta = (store: StoreState) =>
  store.valdi.stalePriceBeta.TotalCount

export default reducer
