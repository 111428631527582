/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  CommentTableContainer,
  FilterCard,
  FilterOptionWrapper,
  PopUpStyleContent,
  RootContainer,
  SelectWrapper,
  StyledSelectWrapper,
  StyledTableContainer,
  SubHeading,
  ToastWrapper,
  PopUpStyleLoader,
  LodingTable,
  LottieWrapper,
  RadioCommentWrapper,
  MarginLeftAutoSpace,
  LoadingTextSpace,
  ButtonWrapper,
  CancelButtonWrapper,
} from './styles'
import {
  actionSelector,
  betaFailAnalysisDataSelector,
  commentSelector,
  getBetaCSV,
  getBetaEXCEL,
  getBetaFailAnalysis,
  getComments,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  isLoadingSelector,
  setAction,
  SetBetaFailAnalysis,
  setHttpErrorMessage,
  setSortDirection,
  setSorting,
  setSuccessMessage,
  sortDirectionSelector,
  sortingSelector,
} from '../../../../store/failAnalysis/beta-fail-analysis'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  WDStyledButtonText,
  WDStyledHeading,
} from '../../../../components/ui/WDTypography'
import { CardHeader } from '../../../../composites/cardHeader/CardHeader'
import { Textbox } from '../../../../components/textbox/Textbox'
import { Toast } from '../../../../components/toast/Toast'
import { Backgrounds } from '../../../../shared/styles'
import Label from '../../../../components/label/Label'
import WDCard from '../../../../components/ui/WDCard'
import Button from '../../../../components/button/Button'
import Loader from '../../../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import BetaTable from './betaTable/BetaTable'
import Divider from '../../../../components/divider/Divider'
import SelectDropdown from '../../../../components/selectDropdown/SelectDropdown'
import { COLORS } from '../../../../theme/Colors'
import { SvgDownloadSmall } from '../../../../components/svg/logo/SvgDownload'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import { addDays, formatISO } from 'date-fns'
import WeekDayCalender from '../../../../components/WeekDayCalander/WeekDayCalender'
import { FormateDateSelector } from '../../../../store/dash-board/about'
import Checkbox from '../../../../components/checkbox/Checkbox'

const BetaFailAnalysisHistory = () => {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const Usercomments = useSelector(commentSelector)
  const ExcludeDate = useSelector(FormateDateSelector)
  const sorting = useSelector(sortingSelector)
  const sortDirection = useSelector(sortDirectionSelector)
  const data = useSelector(betaFailAnalysisDataSelector)
  const buffering = useSelector(isLoadingSelector)
  const action = useSelector(actionSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [resetTable, setResetTable] = useState<boolean>(false)
  const [openComments, setOpenComments] = useState<boolean>(false)
  const [popUpLoader, setPopUpLoader] = useState<boolean>(false)
  const [hidePagination, setHidePagination] = useState<boolean>(false)
  const [includeFailComments, setIncludeFailComments] = useState(0)
  const [ageRange, setAgeRange] = useState('')
  const [buySell, setBuySell] = useState('')
  const [type] = useState<any>({ value: 'history', label: 'Historical' })
  const [checkAccCusip, setCheckAccCusip] = useState(false)
  const [loadingBuffer, setLoadingBuffer] = useState<boolean>(false)
  const DefaultDate: string =
    localStorage.getItem('DefaultDate') &&
    localStorage.getItem('DefaultDate') != 'undefined'
      ? JSON.parse(localStorage.getItem('DefaultDate') || '')
      : ''
  const [Startdate, setStartDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )
  const [Enddate, setEndDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )
  const [startCalendar, setStartCalender] = useState<boolean>(false)
  const [endCalendar, setEndCalender] = useState<boolean>(false)
  const [checkedValue, setCheckedValue] = useState(0)

  const [input, setInput] = useState({
    commentDate: new Date().toISOString(),
    cusip: '',
    subsidiaryNumber: '',
    branch: '',
    accountNumber: '',
    pageNumber: 0,
    commentRow: 0,
  })
  const ageOptions = [
    { value: '', label: 'Select' },
    { value: '1', label: '1' },
    { value: '2 to 5', label: '2 to 5' },
    { value: '6 to 30', label: '6 to 30' },
    { value: '30 and more', label: '30 more' },
  ]
  const typeOption = [
    { value: 'daily', label: 'Daily' },
    { value: 'history', label: 'Historical' },
  ]
  const BSoption = [
    { value: '', label: 'Select' },
    { value: 'S', label: 'Sell' },
    { value: 'B', label: 'Buy' },
  ]
  const d = new Date()
  d.setDate(d.getDate() - 1)

  useEffect(() => {
    dispatch(setAction(''))
    dispatch(
      SetBetaFailAnalysis({
        data: [],
      })
    )
  }, [])

  const savedComments = async (tb: any) => {
    setPopUpLoader(true)
    await dispatch(
      getComments(
        'Beta',
        data.betaReport[tb].failUniqueId,
        data.betaReport[tb].controlNumber
      )
    )
    setPopUpLoader(false)
    setOpenComments(true)
    setHidePagination(true)
  }

  const handleSearch = async () => {
    if (
      formatISO(Enddate).split('T')[0] == formatISO(Startdate).split('T')[0]
    ) {
      setCheckAccCusip(false)
      setResetTable(true)
      dispatch(setSortDirection('ASC'))
      dispatch(setSorting('SnapShotDate, Age'))
      setLoadingBuffer(true)
      const response = await dispatch(
        getBetaFailAnalysis(
          'search',
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.subsidiaryNumber,
          buySell,
          input.cusip,
          0,
          20,
          sorting,
          sortDirection,
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )
      setLoadingBuffer(false)
      setResetTable(false)
    } else if (
      (formatISO(Enddate).split('T')[0] > formatISO(Startdate).split('T')[0] &&
        input.accountNumber) ||
      input.cusip
    ) {
      setCheckAccCusip(false)
      setResetTable(true)
      dispatch(setSortDirection('ASC'))
      dispatch(setSorting('SnapShotDate, Age'))
      setLoadingBuffer(true)
      const response = await dispatch(
        getBetaFailAnalysis(
          'search',
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.subsidiaryNumber,
          buySell,
          input.cusip,
          0,
          20,
          sorting,
          sortDirection,
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )
      setLoadingBuffer(false)

      setResetTable(false)
    } else {
      setCheckAccCusip(true)
    }
  }

  const DownloadEXCEL = async () => {
    if (
      formatISO(Enddate).split('T')[0] == formatISO(Startdate).split('T')[0]
    ) {
      setCheckAccCusip(false)
      setPopUpLoader(true)
      const response = await dispatch(
        getBetaEXCEL(
          `BETAFail_${type.value}_${formatISO(Startdate).split('T')[0]}`,
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.subsidiaryNumber,
          buySell,
          input.cusip,
          0,
          0,
          'SnapShotDate, Age',
          'ASC',
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )

      setPopUpLoader(false)
    } else if (
      (formatISO(Enddate).split('T')[0] > formatISO(Startdate).split('T')[0] &&
        input.accountNumber) ||
      input.cusip
    ) {
      setCheckAccCusip(false)
      setPopUpLoader(true)
      const response = await dispatch(
        getBetaEXCEL(
          `BETAFail_${type.value}_${formatISO(Startdate).split('T')[0]}`,
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.subsidiaryNumber,
          buySell,
          input.cusip,
          0,
          0,
          'SnapShotDate, Age',
          'ASC',
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )

      setPopUpLoader(false)
    } else {
      setCheckAccCusip(true)
    }
  }
  const DownloadCSV = async () => {
    if (
      formatISO(Enddate).split('T')[0] == formatISO(Startdate).split('T')[0]
    ) {
      setCheckAccCusip(false)
      setPopUpLoader(true)
      const response = await dispatch(
        getBetaCSV(
          `BETAFail_${type.value}_${formatISO(Startdate).split('T')[0]}`,
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.subsidiaryNumber,
          buySell,
          input.cusip,
          0,
          0,
          'SnapShotDate, Age',
          'ASC',
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )

      setPopUpLoader(false)
    } else if (
      (formatISO(Enddate).split('T')[0] > formatISO(Startdate).split('T')[0] &&
        input.accountNumber) ||
      input.cusip
    ) {
      setCheckAccCusip(false)
      setPopUpLoader(true)
      const response = await dispatch(
        getBetaCSV(
          `BETAFail_${type.value}_${formatISO(Startdate).split('T')[0]}`,
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.subsidiaryNumber,
          buySell,
          input.cusip,
          0,
          0,
          'SnapShotDate, Age',
          'ASC',
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )

      setPopUpLoader(false)
    } else {
      setCheckAccCusip(true)
    }
  }

  function onChangeValue(event: any) {
    setIncludeFailComments(event.target.value)
  }

  function checkCheckbox(event: any) {
    setCheckedValue(event.target.checked ? 1 : 0)
  }

  return (
    <RootContainer>
      <WDStyledHeading>
        <Label>{'Beta Fail Analysis'}</Label>
      </WDStyledHeading>
      <StyledSelectWrapper
        style={{
          padding: '0px 5px',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonWrapper
          onClick={async () => {
            DownloadCSV()
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label>CSV</Label>
          </WDStyledButtonText>
        </ButtonWrapper>
        <ButtonWrapper
          onClick={async () => {
            DownloadEXCEL()
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label>EXCEL</Label>
          </WDStyledButtonText>
        </ButtonWrapper>
      </StyledSelectWrapper>
      <SelectWrapper>
        <FilterCard>
          <SubHeading>Filter Fails</SubHeading>
          <StyledSelectWrapper>
            <FilterOptionWrapper>
              <Label>Type</Label>
              <SelectDropdown
                defaultValue={{
                  value: 'history',
                  label: 'Historical',
                }}
                borderRadius={'4px'}
                height="32px"
                options={typeOption}
                onChange={(e) => {
                  {
                    e === 'daily' && navigation(-1)
                  }
                }}
              ></SelectDropdown>
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>Start Date</Label>
              <WeekDayCalender
                excludeDates={ExcludeDate}
                open={startCalendar}
                onSelect={() => setStartCalender(false)}
                onFocus={() => setStartCalender(true)}
                onClickOutside={() => setStartCalender(false)}
                selected={Startdate}
                startDate={Startdate}
                selectsStart={true}
                endDate={Enddate}
                maxDate={addDays(new Date(), -1)}
                onChange={(e: Date) => {
                  setStartDate(e)
                }}
              />
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>End Date</Label>
              <WeekDayCalender
                excludeDates={ExcludeDate}
                open={endCalendar}
                onSelect={() => setEndCalender(false)}
                onFocus={() => setEndCalender(true)}
                onClickOutside={() => setEndCalender(false)}
                selectsEnd={true}
                selected={Enddate}
                startDate={Startdate}
                endDate={Enddate}
                minDate={Startdate}
                maxDate={addDays(new Date(), -1)}
                onChange={(e: Date) => {
                  setEndDate(e)
                }}
              />
            </FilterOptionWrapper>
          </StyledSelectWrapper>
          <StyledSelectWrapper>
            <FilterOptionWrapper>
              <Label>Age Range</Label>
              <SelectDropdown
                defaultValue={{
                  value: '',
                  label: 'Select',
                }}
                borderRadius={'4px'}
                height="32px"
                options={ageOptions}
                onChange={(e) => {
                  setAgeRange(e)
                }}
              ></SelectDropdown>
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>Buy/Sell</Label>
              <SelectDropdown
                defaultValue={{
                  value: '',
                  label: 'Select',
                }}
                borderRadius={'4px'}
                height="32px"
                options={BSoption}
                onChange={(e) => {
                  setBuySell(e)
                }}
              ></SelectDropdown>
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>Account Number</Label>
              <Textbox
                placeholder="Enter Account Number"
                onChange={(e) => {
                  setInput({ ...input, accountNumber: e })
                }}
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
            </FilterOptionWrapper>
          </StyledSelectWrapper>
          <StyledSelectWrapper>
            <FilterOptionWrapper>
              <Label>Branch</Label>
              <Textbox
                onChange={(e) => {
                  setInput({ ...input, branch: e })
                }}
                placeholder="Enter Branch"
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>CUSIP/Symbol</Label>
              <Textbox
                placeholder="Enter CUSIP/Symbol"
                onChange={(e) => {
                  setInput({ ...input, cusip: e })
                }}
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
            </FilterOptionWrapper>
            <FilterOptionWrapper>
              <Label>Subsidiary Number</Label>
              <Textbox
                placeholder="Subsidiary Number"
                onChange={(e) => {
                  setInput({ ...input, subsidiaryNumber: e })
                }}
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
              />
            </FilterOptionWrapper>
          </StyledSelectWrapper>
          {checkAccCusip && (
            <StyledSelectWrapper>
              <Label color={COLORS.Background.Warning}>
                *Please enter Account Number or Cusip
              </Label>
            </StyledSelectWrapper>
          )}
          <StyledSelectWrapper></StyledSelectWrapper>
          <StyledSelectWrapper>
            <RadioCommentWrapper>
              <input
                type="radio"
                value={0}
                name="AllFailComments"
                checked={includeFailComments == 0}
                onChange={onChangeValue}
              />{' '}
              <span>All FailComments</span>
            </RadioCommentWrapper>
            <RadioCommentWrapper>
              <input
                type="radio"
                value={1}
                name="includeFailComments"
                checked={includeFailComments == 1}
                onChange={onChangeValue}
              />{' '}
              <span>Include Comments</span>
            </RadioCommentWrapper>
            <RadioCommentWrapper>
              <input
                type="radio"
                value={2}
                name="includeFailComments"
                checked={includeFailComments == 2}
                onChange={onChangeValue}
              />{' '}
              <span>Exclude Comments</span>
            </RadioCommentWrapper>
            <RadioCommentWrapper>
              <Checkbox
                title={'Money Only'}
                checked={checkedValue == 1}
                onChange={checkCheckbox}
              />
            </RadioCommentWrapper>
            <MarginLeftAutoSpace>
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  handleSearch()
                }}
              >
                Search
              </Button>
            </MarginLeftAutoSpace>
          </StyledSelectWrapper>
        </FilterCard>
      </SelectWrapper>
      {buffering && loadingBuffer && (
        <>
          <WDCard>
            <CardHeader label={'Beta Fail Analysis'} icon={false} />
          </WDCard>
          <StyledTableContainer>
            <LodingTable>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LoadingTextSpace>
                <Label> Loading...</Label>
              </LoadingTextSpace>
            </LodingTable>
          </StyledTableContainer>
        </>
      )}

      {popUpLoader && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <LoadingTextSpace>
            <Label> Loading...</Label>
          </LoadingTextSpace>{' '}
        </PopUpStyleLoader>
      )}
      {!resetTable && (action === 'search' || action === 'sort') && (
        <>
          <WDCard>
            <CardHeader label={'Beta Fail Analysis'} icon={false} />
          </WDCard>
          <StyledTableContainer>
            {openComments && (
              <PopUpStyleContent>
                <StyledSelectWrapper
                  style={{
                    padding: '15px 15px 0px 15px',
                    fontWeight: '600',
                    borderRadius: '8px 8px 0px 0px',
                  }}
                >
                  <>View Comment</>
                  <MarginLeftAutoSpace>
                    <CancelButtonWrapper
                      onClick={() => {
                        setOpenComments(false)
                      }}
                    >
                      <SvgCancel
                        fillColor={COLORS.Icons.NeutralIcon}
                      ></SvgCancel>
                    </CancelButtonWrapper>
                  </MarginLeftAutoSpace>
                  <Divider
                    thickness={'1px'}
                    horizontal={true}
                    bgColor={Backgrounds.Gray110}
                  />
                </StyledSelectWrapper>
                <CommentTableContainer>
                  <BetaTable
                    data={Usercomments ? Usercomments : []}
                    hidePagination={hidePagination}
                    columns={[
                      {
                        Header: 'CreatedBy',
                        accessor: 'createdBy',
                      },
                      {
                        Header: 'CreatedOn(EST)',
                        accessor: 'createdOn',
                      },
                      {
                        Header: 'Comment',
                        accessor: 'comment',
                      },
                    ]}
                  />
                  {Usercomments.length == 0 && <h3>No Comments</h3>}
                </CommentTableContainer>
              </PopUpStyleContent>
            )}
            <BetaTable
              columns={[
                {
                  Header: 'Age',
                  accessor: 'age',
                },
                {
                  Header: 'SnapShot Date',
                  accessor: 'snapShotDate',
                },

                {
                  Header: 'All Comments',
                  Cell: (table: any) => {
                    return (
                      <Link
                        style={{
                          color: COLORS.Background.Primarytext,
                          cursor: 'pointer',
                        }}
                        onClick={() => savedComments(table.cell.row.id)}
                        to={''}
                      >
                        View comments
                      </Link>
                    )
                  },
                },
                {
                  Header: 'Account',
                  accessor: 'accountNumber',
                },
                {
                  Header: 'B/S',
                  accessor: 'buy_Sell',
                },
                {
                  Header: 'CUSIP',
                  accessor: 'cusip',
                },
                {
                  Header: 'Sec Nbr',
                  accessor: 'securityNumber',
                },
                {
                  Header: 'Symbol',
                  accessor: 'symbol',
                },

                {
                  Header: 'Qnty',
                  accessor: 'quantity',
                },
                {
                  Header: 'Partial Qnty',
                  accessor: 'partialQuantity',
                },
                {
                  Header: 'Trade Date',
                  accessor: 'tradeDate',
                },
                {
                  Header: 'Settle Date',
                  accessor: 'settleDate',
                },
                {
                  Header: 'Entry Date',
                  accessor: 'entryDate',
                },
                {
                  Header: 'Price',
                  accessor: 'price',
                },
                {
                  Header: 'Amount',
                  accessor: 'amount',
                },
                {
                  Header: 'DTC Number',
                  accessor: 'dtcNum',
                },
                {
                  Header: 'Market Value',
                  accessor: 'marketValue',
                },
                {
                  Header: 'DTC Eligibility',
                  accessor: 'dtC_CNS_Eligibility',
                },
                {
                  Header: 'Failed BookLocation',
                  accessor: 'failedBookLocation',
                },
                {
                  Header: 'Counter PartyType',
                  accessor: 'counterPartyType',
                },
                {
                  Header: 'Account Name',
                  accessor: 'accountName',
                },
                {
                  Header: 'Control Number',
                  accessor: 'controlNumber',
                },
              ]}
              type={type.value}
              startDate={Startdate ? formatISO(Startdate).split('T')[0] : ''}
              endDate={Enddate ? formatISO(Enddate).split('T')[0] : ''}
              age={ageRange}
              accNum={input.accountNumber}
              branch={input.branch}
              subNum={input.subsidiaryNumber}
              BS={buySell}
              cusip={input.cusip}
              data={data.betaReport ? data.betaReport : []}
              totalRecords={data.vSum_TotalFails}
              includeFailComments={includeFailComments}
              checkedValue={checkedValue}
            />
          </StyledTableContainer>
        </>
      )}
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default BetaFailAnalysisHistory
