import { SvgEye } from '../../../components/svg/SvgEye'

export const BetaSecLendingColumns = (toggleViewComments: any,accessFlag:boolean) => {
  return [
    {
      Header: 'SnapShot Date',
      accessor: 'snapShotDate',
    },
    {
      Header: 'Account Number',
      accessor: 'accountNumber',
    },
    {
      Header: 'Account Type',
      accessor: 'accountType',
    },
    {
      Header: 'Account Name 1',
      accessor: 'accountName1',
    },
    {
      Header: 'Account Name 2',
      accessor: 'accountName2',
    },
    {
      Header: 'Account Name 3',
      accessor: 'accountName3',
    },
    {
      Header: 'Rep Code',
      accessor: 'repCode',
    },
    {
      Header: 'CUSIP',
      accessor: 'cusip',
    },
    {
      Header: 'Security Number',
      accessor: 'securityNumber',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'Settle Date Position',
      accessor: 'settlementDatePosition',
    },
    {
      Header: 'Seg Memo Position',
      accessor: 'segMemoPosition',
    },
    {
      Header: 'Diff Settle Date and Memo Seg',
      accessor: 'diffSettleDateAndMemoSeg',
    },
    {
      Header: 'Stock Loan Short Position 5151188',
      accessor: 'stockLoanShortPosition515118',
    },
    {
      Header: 'Stock Loan Long Position 1001278',
      accessor: 'stockLoanLongPosition100127',
    },

    {
      Header: 'Comments',
      Cell: (table: { cell: { row: { index: number; original: any } } }) => {
        return (
          <span
            role="presentation"
            title="View"
            style={accessFlag ?{ cursor: 'not-allowed', width: '25px' }:{ cursor: 'pointer', width: '25px' }}
            onClick={() => {
              !accessFlag && toggleViewComments(table.cell?.row?.original)
            }}
          >
            <SvgEye fillColor="#475569" />
          </span>
        )
      },
    },
  ]
}
