import {
  approveDataPost,
  notifyTrade,
  rejectDataPost,
} from '../../store/trade-break/trade-break'
import {
  getTradeBreak,
  gettradeBreakEXCEL880,
} from '../../store/trade-management/trade-management'

export const getEntityCodeArray = (entityType: any) => {
  const entityCodeArray: any = []
  if (entityType && entityType.length > 0) {
    entityType.forEach((element: any) => {
      entityCodeArray.push(element.value)
    })
  }
  return entityCodeArray
}

export const ExcelDownload880 = async (
  input: any,
  entityType: any,
  assetType: any,
  dispatch: any,
  setLoading: any,
  desk: string
) => {
  const entityCodeArray: any = getEntityCodeArray(entityType)
  setLoading(true)
  await dispatch(
    gettradeBreakEXCEL880(
      `TradeBreakByMPIDDetails_${input.startDate}`,
      input.startDate,
      input.endDate,
      input.settleDate?.length > 0 ? input.settleDate : null,
      entityCodeArray && entityCodeArray.length > 0
        ? entityCodeArray.toString()
        : null,
      input.cusip?.length > 0 ? input.cusip : null,
      input.symbol?.length > 0 ? input.symbol : null,
      assetType.value?.length > 0 ? assetType.value : null,
      input.mpid?.length > 0 ? input.mpid : null,
      desk.length > 0 ? desk : null
    )
  )
  setLoading(false)
}

export const HandleSearch = async (
  input: any,
  entityCodeArray: any,
  assetType: any,
  desk: string,
  dispatch: any
) => {
  const tradeDataBySymbol = await dispatch(
    getTradeBreak(
      'search',
      input.startDate,
      input.endDate,
      input.settleDate?.length > 0 ? input.settleDate : null,
      assetType.value?.length > 0 ? assetType.value : null,
      entityCodeArray && entityCodeArray.length > 0
        ? entityCodeArray.toString()
        : null,
      input.cusip
        ? input.cusip?.trim()?.length
          ? input.cusip?.trim()
          : null
        : null,
      input.symbol ? input.symbol?.trim() : null,
      input.mpid?.length > 0 ? input.mpid : null,
      null,
      desk.length > 0 ? desk : null
    )
  )
  return tradeDataBySymbol
}

export const ApproveReject = async (
  selectedTradeArray: any,
  name: string,
  setLoading: any,
  dispatch: any
) => {
  const getSelectedRows = selectedTradeArray.filter(
    (obj: any) => obj.selectedCheck
  )

  interface ReqObject {
    tradeBreakIds?: string | null
    category?: string | null
    userId?: string | null
    comments?: string | null
  }

  const categoryArr: any = []
  getSelectedRows.map((item: any) => {
    if (item.category) categoryArr.push(item.category)
  })
  const tradeBreakIds = getSelectedRows.map((item: any) => item.rpt880Id)
  const tempObj: ReqObject = {}
  tempObj.tradeBreakIds = tradeBreakIds.toString()
  tempObj.category = categoryArr?.length ? categoryArr.toString() : null
  tempObj.userId = localStorage.getItem('AccountName')
  tempObj.comments = null
  if (tradeBreakIds.length > 0) {
    if (name === 'reject') {
      setLoading(true)
      const rejectRequest = await dispatch(rejectDataPost(tempObj))
      return rejectRequest
    } else if (name === 'approve') {
      setLoading(true)
      const approveRequest = await dispatch(approveDataPost(tempObj))
      return approveRequest
    }
  }
}

export const NotifyTrade = async (
  selectedTradeArray: any,
  emailIdList: any,
  selectedIds: any,
  notes: any,
  dispatch: any
) => {
  const getSelectedRows = selectedTradeArray.filter(
    (obj: any) => obj.selectedCheck
  )
  const toList: any = []

  emailIdList?.mpidEmailModel?.length &&
    emailIdList.mpidEmailModel.map((ele: any, key: any) => {
      const tradeBreakId = getSelectedRows.filter(
        (item: any) => item.mpid === ele.mpid
      )
      const reqObj = {
        mpid: ele.mpid,
        tradeId: tradeBreakId.map((item: any) => item.rpt880Id),
        emailTo: selectedIds[key].map((email: any) => email.value),
        notes: notes[key],
      }
      toList.push(reqObj)
    })
  const request = {
    userId: localStorage.getItem('UserId'),
    toList,
  }
  const notifyResponse = await dispatch(notifyTrade(request))
  return notifyResponse
}

export const FilterOnChange = async (
  id: any,
  checkFilter: any,
  checked: any,
  setCheckFilter: any,
  setFilterKey: any,
  orginalTableData: any,
  setTableData: any
) => {
  switch (id) {
    case 'approve': {
      checkFilter.all = false
      checkFilter[id] = checked
      checkFilter.reject = false
      checkFilter.notAct = false
      checkFilter.cancelled = false
      checkFilter.notified = false
      setCheckFilter({ ...checkFilter })
      setFilterKey('APR')
      const getFilterTableApprove = checked
        ? orginalTableData.filter(
            (obj: any) =>
              obj.workFlowStatus === 'APR' && obj.breakStatus !== 'CXL'
          )
        : []
      const setFilteredTable = [...getFilterTableApprove]
      setTableData(setFilteredTable)
      break
    }
    case 'notified': {
      checkFilter.all = false
      checkFilter[id] = checked
      checkFilter.reject = false
      checkFilter.notAct = false
      checkFilter.cancelled = false
      checkFilter.approve = false
      setCheckFilter({ ...checkFilter })
      setFilterKey('NTF')
      const getFilterTableNotified = checked
        ? orginalTableData.filter(
            (obj: any) =>
              obj.workFlowStatus === 'NTF' && obj.breakStatus !== 'CXL'
          )
        : []
      const setFilteredTable = [...getFilterTableNotified]
      setTableData(setFilteredTable)
      break
    }
    case 'reject': {
      checkFilter.notAct = false
      checkFilter.approve = false
      checkFilter[id] = checked
      checkFilter.cancelled = false
      checkFilter.notified = false

      setCheckFilter({ ...checkFilter })
      setFilterKey('REJ')
      const filterTableReject = checked
        ? orginalTableData.filter(
            (obj: any) =>
              obj.workFlowStatus === 'REJ' && obj.breakStatus !== 'CXL'
          )
        : []
      const filteredTable = [...filterTableReject]
      setTableData(filteredTable)
      break
    }
    case 'notAct': {
      checkFilter.all = false
      checkFilter.reject = false
      checkFilter.approve = false
      checkFilter[id] = checked
      checkFilter.cancelled = false
      checkFilter.notified = false
      setCheckFilter({ ...checkFilter })
      const tableNotAct = checked
        ? orginalTableData.filter(
            (obj: any) =>
              obj.workFlowStatus === null ||
              obj.workFlowStatus?.trim() === 'NA' ||
              obj.workFlowStatus === '-'
          )
        : []
      setFilterKey('NA')
      const getFilteredTable = [...tableNotAct]
      setTableData(getFilteredTable)
      break
    }
    case 'cancelled': {
      checkFilter.all = false
      checkFilter.reject = false
      checkFilter.approve = false
      checkFilter[id] = checked
      checkFilter.notAct = false
      checkFilter.notified = false
      setCheckFilter({ ...checkFilter })
      const tableCancelled = checked
        ? orginalTableData.filter((obj: any) => obj.workFlowStatus === 'CXL')
        : []
      setFilterKey('CXL')
      setTableData(tableCancelled)
      break
    }
    case 'all': {
      checkFilter.approve = false
      checkFilter.reject = false
      checkFilter.notAct = false
      checkFilter[id] = checked
      checkFilter.cancelled = false
      checkFilter.notified = false
      setCheckFilter({ ...checkFilter })
      if (checked) {
        setTableData(orginalTableData)
      } else {
        setTableData([])
      }
      break
    }
    default:
      break
  }
}

export const setDataTable = (
  tradeDataBySymbol: any,
  params: any,
  setOriginalTableData: any,
  setCheckFilterCount: any,
  setTableData: any,
  filterKey: any
) => {
  if (tradeDataBySymbol && tradeDataBySymbol.tradeBreaksByMPIDViewModel) {
    let tempSave = []
    let tableArr: any = []
    tableArr = tradeDataBySymbol.tradeBreaksByMPIDViewModel.filter(
      (element: any) => element.workFlowStatus?.trim() === filterKey
    )
    setOriginalTableData(tradeDataBySymbol.tradeBreaksByMPIDViewModel)
    setCheckFilterCount({
      approve: tradeDataBySymbol.tradeBreaksByMPIDViewModel.filter(
        (element: any) => element.workFlowStatus === 'APR'
      ).length,
      reject: tradeDataBySymbol.tradeBreaksByMPIDViewModel.filter(
        (element: any) => element.workFlowStatus === 'REJ'
      ).length,
      notAct: tradeDataBySymbol.tradeBreaksByMPIDViewModel.filter(
        (element: any) =>
          element.workFlowStatus?.trim() === 'NA' ||
          element.workFlowStatus === null
      ).length,
      cancelled: tradeDataBySymbol.tradeBreaksByMPIDViewModel.filter(
        (element: any) => element.workFlowStatus === 'CXL'
      ).length,
      notified: tradeDataBySymbol.tradeBreaksByMPIDViewModel.filter(
        (element: any) => element.workFlowStatus === 'NTF'
      ).length,
    })
    tempSave = tableArr
    setTableData(tempSave)
  }
}

let selectedTradeArray: any = []

export const OnSelectChange = (selectedRowsData: any, tableData: any) => {
  selectedTradeArray = tableData.map((item: any, row: number) => {
    const isChecked = selectedRowsData?.find(
      (element: any) => element.rpt880Id === item.rpt880Id
    )
    if (isChecked) {
      return { ...item, selectedCheck: true }
    } else {
      return { ...item }
    }
  })
}

export const getSelectedData = () => {
  return selectedTradeArray
}
