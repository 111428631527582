import { z } from 'zod'

export const ValidateSchema = z
    .object({
        minValue: z.number({
            required_error: "MinValue is required",
            invalid_type_error: "MinValue should be a number",
          })
          .nullable().transform((value, ctx): number => {
            if (value == null )
              ctx.addIssue({
                code: 'custom',
                message: 'minValue Cannot be null',
              });
            return value ?? NaN;
          }),
        maxValue: z.number({
            required_error: "MaxValue is required",
            invalid_type_error:  "maxValue should be a number",
          })
          .nullable().transform((value, ctx): number => {
            if (value == null )
              ctx.addIssue({
                code: 'custom',
                message: 'maxValue Cannot be null',
              });
            return value ?? NaN;
          }),
        color: z.string({
            required_error: "Color is required",
            invalid_type_error: "Color is required",
          })
          .nullable().transform((value, ctx): string => {
            if (value == '' )
              ctx.addIssue({
                code: 'custom',
                message: 'Color is required',
              });
            return value ?? '';
          }),
          prevMin: z.number().optional(),
          prevMax: z.number().optional(),
          nextMin:z.number().optional(),
          nextMax:z.number().optional(),
    })
    .superRefine((data, ctx) => {
      if(data.minValue!=null && data.maxValue!=null && data.minValue > data.maxValue){
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["minValue"],
          message: 'Max should be greater than min Value',
        });
      }
       if(data.nextMin && data.maxValue && data.nextMin < data.maxValue){
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["maxValue"],
          message:'Max should be lesser than next min Value',
        });
      }
       if(data.prevMax && data.minValue && data.prevMax > data.minValue){
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["minValue"],
          message: 'Min should be greater than previous max Value',
        });
        
      }
    })

   
    


