/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { configJSONDashboard } from '../../utils/config'

interface State {
  isLoading: boolean
  buyInIntents: []  
  buyInIntents1: []  
  buyInIntents2: []  
  failsToDeliver: []
  failsToReceive: []
  TopContra: []
  TopCorrespondents:[]
  buyInLoading: boolean

}

const initialState: State = {
  isLoading: false,
  buyInIntents: [],
  buyInIntents1: []  ,
  buyInIntents2: []  ,
  failsToDeliver: [],
  failsToReceive: [],
  TopContra: [],
  TopCorrespondents:[],
  buyInLoading: false,

}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'settlement',
  initialState,
  reducers: {
    setBuyInIntents: (state: State, action: PayloadAction<any>) => {
      state.buyInIntents = action.payload
    },
    setBuyInIntents1: (state: State, action: PayloadAction<any>) => {
      state.buyInIntents1 = action.payload
    },
    setBuyInIntents2: (state: State, action: PayloadAction<any>) => {
      state.buyInIntents2 = action.payload
    },
    setFailsToDeliver: (state: State, action: PayloadAction<any>) => {
      state.failsToDeliver = action.payload
    },
    setFailsToReceive: (state: State, action: PayloadAction<any>) => {
      state.failsToReceive = action.payload
    },
    setTopContra: (state: State, action: PayloadAction<any>) => {
      state.TopContra = action.payload
    },
    setTopCorrespondents: (state: State, action: PayloadAction<any>) => {
      state.TopCorrespondents = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setBuyInLoading: (state: State, action: PayloadAction<boolean>) => {
      state.buyInLoading = action.payload
    }
  },
})

export const { setIsLoading, setBuyInIntents,setBuyInIntents1,setBuyInIntents2,setFailsToDeliver,setFailsToReceive,setTopContra,setTopCorrespondents,setBuyInLoading } = actions

export const getTopContra: any =
(system:string,productType:string) => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const endPoint = system === 'Beta' ? 'BetaCountDetails' : system === 'P3' ? 'P3CountDetails' : ''
    const { data, status } = await authApiCall.post(
      `opsfailprocessapi/Dashboard/${endPoint}`,{
        "type": "Contra",
        productType
      }
    )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setTopContra(data))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return null
    }
    dispatch(setIsLoading(false))
  }

  export const getTopCorrespondents: any =
  (system:string,productType:string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const endPoint = system === 'Beta' ? 'BetaCountDetails' : system === 'P3' ? 'P3CountDetails' : ''
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/Dashboard/${endPoint}`,{
          "type": "Correspondent",
          productType
        }
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setTopCorrespondents(data))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return null
    }
    dispatch(setIsLoading(false))
  }

export const getFailAnalysisKPIFailToReceiveSummary: any =
  (system:string) => async (dispatch: Dispatch) => {
    dispatch(setBuyInLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        `opsfailprocessapi/FailAnalysisReport/GetFailAnalysisKPIFailToReceiveSummary?system=${system}`
      )
      if (status === 200) {
        dispatch(setFailsToReceive(data))
        dispatch(setBuyInLoading(false))
        return {data ,status}
      }
    } catch (e: any) {
      dispatch(setBuyInLoading(false))
      return null
    }
    dispatch(setBuyInLoading(false))

  }

  export const getFailAnalysisKPIFailToDeliverSummary: any =
  (system:string) => async (dispatch: Dispatch) => {
    dispatch(setBuyInLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        `opsfailprocessapi/FailAnalysisReport/GetFailAnalysisKPIFailToDeliverSummary?system=${system}`
      )

      if (status === 200) {
        dispatch(setFailsToDeliver(data))
        dispatch(setBuyInLoading(false))
        return {data ,status}
      }
    } catch (e: any) {
      dispatch(setBuyInLoading(false))
      return null
    }
    dispatch(setBuyInLoading(false))
  }
  
  

export const isLoadingSelector = (store: StoreState) =>store.Dashboard.settlement.isLoading
export const TopCorrespondentsSelector = (store: StoreState) => store.Dashboard.settlement.TopCorrespondents
export const TopContraSelector = (store: StoreState) => store.Dashboard.settlement.TopContra
export const BuyInLoadingSelector = (store: StoreState) =>store.Dashboard.settlement.buyInLoading
export const FailDeliverAnalysisReport = (store: StoreState) => store.Dashboard.settlement.failsToDeliver
export const FailReceiveAnalysisReport = (store: StoreState) => store.Dashboard.settlement.failsToReceive


export default reducer
