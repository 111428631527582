/* eslint-disable @typescript-eslint/no-explicit-any */

import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'
import Label from '../../../components/label/Label'
import { useEffect, useState } from 'react'
import { Textarea } from '../../../components/textArea/Textarea'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  BulkCommentWrapper,
  LoadingTextSpace,
  LottieWrapper,
  PopupCardBulkComment,
  PopUpStyleLoader,
  StyledSelectWrapper,
  ToastWrapper,
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBetaStalePriceData,
  PageNumSelectorBeta,
  putUserBulkComment,
  sortDirectionSelectorBeta,
  sortingSelectorBeta,
} from '../../../store/staticData/stale-pricing-beta'
import { Toast } from '../../../components/toast/Toast'
import Lottie from 'lottie-react'
import {
  PageNumSelectorP3,
  sortingSelectorP3,
  sortDirectionSelectorP3,
  getP3StalePriceData,
} from '../../../store/staticData/stale-pricing-p3'
import { TableFilterList } from './betaStalePricing/tableFilter/TableFilterData'
import { P3TableFilterList } from './p3StalePricing/p3TableFilter/P3TableFilterData'
import { getAccessFlag } from '../../../utils/globalFunctions'

const BulkComment = (props: any) => {
  const dispatch = useDispatch()
  const [comment, setComments] = useState<boolean>(false)
  const [inputComment, setInputComment] = useState<any>()
  const [popUpLoader, setPopUpLoader] = useState<boolean>(false)
  const PageNumP3 = useSelector(PageNumSelectorP3)
  const sortingP3 = useSelector(sortingSelectorP3)
  const sortDirectionP3 = useSelector(sortDirectionSelectorP3)
  const sortingBeta = useSelector(sortingSelectorBeta)
  const sortDirectionBeta = useSelector(sortDirectionSelectorBeta)
  const PageNumBeta = useSelector(PageNumSelectorBeta)
  const [requestSubmit, setrequestSubmit] = useState<{
    status: boolean | null
    text: string
  }>({
    status: null,
    text: '',
  })
  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)

  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['SD-RWX', 'OM-RWX']))
  }, [])

  const securityNbr =
    TableFilterList[0]?.dropDownArray[0].value &&
    TableFilterList[0].selectedData[0]
      ? TableFilterList[0].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[0]?.dropDownArray[0].value &&
        !TableFilterList[0].selectedData[0]
      ? 'Blank'
      : TableFilterList[0].selectedData.join(';').toString()

  let top_Category = ''
  if (
    TableFilterList[1].dropDownArray.filter((option) => option.value).length ==
    TableFilterList[1].dropDownArray.length
  ) {
    top_Category = ''
  } else {
    top_Category = TableFilterList[1].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  let sub_Category = ''
  if (
    TableFilterList[2].dropDownArray.filter((option) => option.value).length ==
    TableFilterList[2].dropDownArray.length
  ) {
    sub_Category = ''
  } else {
    sub_Category = TableFilterList[2].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  const reviewer_LatestComment =
    TableFilterList[3]?.dropDownArray[0].value &&
    TableFilterList[3].selectedData[0]
      ? TableFilterList[3].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[3]?.dropDownArray[0].value &&
        !TableFilterList[3].selectedData[0]
      ? 'Blank'
      : TableFilterList[3].selectedData.join(';').toString()

  const lastPrice_In_Current_StaleReport =
    TableFilterList[4]?.dropDownArray[0].value &&
    TableFilterList[4].selectedData[0]
      ? TableFilterList[4].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[4]?.dropDownArray[0].value &&
        !TableFilterList[4].selectedData[0]
      ? 'Blank'
      : TableFilterList[4].selectedData.join(';').toString()

  let followup_Flag: string
  if (
    TableFilterList[5].dropDownArray.filter((option) => option.value).length ==
    TableFilterList[5].dropDownArray.length
  ) {
    followup_Flag = ''
  } else {
    followup_Flag = TableFilterList[5].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.labelValue)
      .join(';')
      .toString()
  }

  const securityDesc =
    TableFilterList[6]?.dropDownArray[0].value &&
    TableFilterList[6].selectedData[0]
      ? TableFilterList[6].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[6]?.dropDownArray[0].value &&
        !TableFilterList[6].selectedData[0]
      ? 'Blank'
      : TableFilterList[6].selectedData.join(';').toString()

  const cusip =
    TableFilterList[7]?.dropDownArray[0].value &&
    TableFilterList[7].selectedData[0]
      ? TableFilterList[7].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[7]?.dropDownArray[0].value &&
        !TableFilterList[7].selectedData[0]
      ? 'Blank'
      : TableFilterList[7].selectedData.join(';').toString()

  const isiN_Number =
    TableFilterList[8]?.dropDownArray[0].value &&
    TableFilterList[8].selectedData[0]
      ? TableFilterList[8].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[8]?.dropDownArray[0].value &&
        !TableFilterList[8].selectedData[0]
      ? 'Blank'
      : TableFilterList[8].selectedData.join(';').toString()

  const comments_Created_By =
    TableFilterList[9]?.dropDownArray[0].value &&
    TableFilterList[9].selectedData[0]
      ? TableFilterList[9].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[9]?.dropDownArray[0].value &&
        !TableFilterList[9].selectedData[0]
      ? 'Blank'
      : TableFilterList[9].selectedData.join(';').toString()

  const reviewer_Last_Commented_Date =
    TableFilterList[10]?.dropDownArray[0].value &&
    TableFilterList[10].selectedData[0]
      ? TableFilterList[10].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[10]?.dropDownArray[0].value &&
        !TableFilterList[10].selectedData[0]
      ? 'Blank'
      : TableFilterList[10].selectedData.join(';').toString()

  const icE_Last_Priced =
    TableFilterList[11]?.dropDownArray[0].value &&
    TableFilterList[11].selectedData[0]
      ? TableFilterList[11].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[11]?.dropDownArray[0].value &&
        !TableFilterList[11].selectedData[0]
      ? 'Blank'
      : TableFilterList[11].selectedData.join(';').toString()

  const icE_Last_Price =
    TableFilterList[12]?.dropDownArray[0].value &&
    TableFilterList[12].selectedData[0]
      ? TableFilterList[12].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[12]?.dropDownArray[0].value &&
        !TableFilterList[12].selectedData[0]
      ? 'Blank'
      : TableFilterList[12].selectedData.join(';').toString()

  const icE_Notes =
    TableFilterList[13]?.dropDownArray[0].value &&
    TableFilterList[13].selectedData[0]
      ? TableFilterList[13].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[13]?.dropDownArray[0].value &&
        !TableFilterList[13].selectedData[0]
      ? 'Blank'
      : TableFilterList[13].selectedData.join(';').toString()

  const bbG_Last_Priced =
    TableFilterList[14]?.dropDownArray[0].value &&
    TableFilterList[14].selectedData[0]
      ? TableFilterList[14].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[14]?.dropDownArray[0].value &&
        !TableFilterList[14].selectedData[0]
      ? 'Blank'
      : TableFilterList[14].selectedData.join(';').toString()

  const bbG_Last_Price =
    TableFilterList[15]?.dropDownArray[0].value &&
    TableFilterList[15].selectedData[0]
      ? TableFilterList[15].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[15]?.dropDownArray[0].value &&
        !TableFilterList[15].selectedData[0]
      ? 'Blank'
      : TableFilterList[15].selectedData.join(';').toString()

  const box_Location =
    TableFilterList[16]?.dropDownArray[0].value &&
    TableFilterList[16].selectedData[0]
      ? TableFilterList[16].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[16]?.dropDownArray[0].value &&
        !TableFilterList[16].selectedData[0]
      ? 'Blank'
      : TableFilterList[16].selectedData.join(';').toString()

  const box_Description =
    TableFilterList[17]?.dropDownArray[0].value &&
    TableFilterList[17].selectedData[0]
      ? TableFilterList[17].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[17]?.dropDownArray[0].value &&
        !TableFilterList[17].selectedData[0]
      ? 'Blank'
      : TableFilterList[17].selectedData.join(';').toString()

  const country =
    TableFilterList[18]?.dropDownArray[0].value &&
    TableFilterList[18].selectedData[0]
      ? TableFilterList[18].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[18]?.dropDownArray[0].value &&
        !TableFilterList[18].selectedData[0]
      ? 'Blank'
      : TableFilterList[18].selectedData.join(';').toString()

  const securityTypeCode =
    TableFilterList[19]?.dropDownArray[0].value &&
    TableFilterList[19].selectedData[0]
      ? TableFilterList[19].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[19]?.dropDownArray[0].value &&
        !TableFilterList[19].selectedData[0]
      ? 'Blank'
      : TableFilterList[19].selectedData.join(';').toString()

  const expirationDate =
    TableFilterList[20]?.dropDownArray[0].value &&
    TableFilterList[20].selectedData[0]
      ? TableFilterList[20].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[20]?.dropDownArray[0].value &&
        !TableFilterList[20].selectedData[0]
      ? 'Blank'
      : TableFilterList[20].selectedData.join(';').toString()

  const inDefault =
    TableFilterList[21]?.dropDownArray[0].value &&
    TableFilterList[21].selectedData[0]
      ? TableFilterList[21].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[21]?.dropDownArray[0].value &&
        !TableFilterList[21].selectedData[0]
      ? 'Blank'
      : TableFilterList[21].selectedData.join(';').toString()

  const corporateAction1 =
    TableFilterList[22]?.dropDownArray[0].value &&
    TableFilterList[22].selectedData[0]
      ? TableFilterList[22].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[22]?.dropDownArray[0].value &&
        !TableFilterList[22].selectedData[0]
      ? 'Blank'
      : TableFilterList[22].selectedData.join(';').toString()

  const corporateAction2 =
    TableFilterList[23]?.dropDownArray[0].value &&
    TableFilterList[23].selectedData[0]
      ? TableFilterList[23].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[23]?.dropDownArray[0].value &&
        !TableFilterList[23].selectedData[0]
      ? 'Blank'
      : TableFilterList[23].selectedData.join(';').toString()

  const surf_User_Pricing_Manual =
    TableFilterList[24]?.dropDownArray[0].value &&
    TableFilterList[24].selectedData[0]
      ? TableFilterList[24].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[24]?.dropDownArray[0].value &&
        !TableFilterList[24].selectedData[0]
      ? 'Blank'
      : TableFilterList[24].selectedData.join(';').toString()

  const surf_LastUpdated =
    TableFilterList[25]?.dropDownArray[0].value &&
    TableFilterList[25].selectedData[0]
      ? TableFilterList[25].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[25]?.dropDownArray[0].value &&
        !TableFilterList[25].selectedData[0]
      ? 'Blank'
      : TableFilterList[25].selectedData.join(';').toString()

  const surf_LastPrice =
    TableFilterList[26]?.dropDownArray[0].value &&
    TableFilterList[26].selectedData[0]
      ? TableFilterList[26].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[26]?.dropDownArray[0].value &&
        !TableFilterList[26].selectedData[0]
      ? 'Blank'
      : TableFilterList[26].selectedData.join(';').toString()

  const userIntial =
    TableFilterList[27]?.dropDownArray[0].value &&
    TableFilterList[27].selectedData[0]
      ? TableFilterList[27].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[27]?.dropDownArray[0].value &&
        !TableFilterList[27].selectedData[0]
      ? 'Blank'
      : TableFilterList[27].selectedData.join(';').toString()

  const changedDate =
    TableFilterList[28]?.dropDownArray[0].value &&
    TableFilterList[28].selectedData[0]
      ? TableFilterList[28].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[28]?.dropDownArray[0].value &&
        !TableFilterList[28].selectedData[0]
      ? 'Blank'
      : TableFilterList[28].selectedData.join(';').toString()

  const marketPrice =
    TableFilterList[29]?.dropDownArray[0].value &&
    TableFilterList[29].selectedData[0]
      ? TableFilterList[29].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[29]?.dropDownArray[0].value &&
        !TableFilterList[29].selectedData[0]
      ? 'Blank'
      : TableFilterList[29].selectedData.join(';').toString()

  const changeTerminal =
    TableFilterList[30]?.dropDownArray[0].value &&
    TableFilterList[30].selectedData[0]
      ? TableFilterList[30].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[30]?.dropDownArray[0].value &&
        !TableFilterList[30].selectedData[0]
      ? 'Blank'
      : TableFilterList[30].selectedData.join(';').toString()

  const dtC_Premed =
    TableFilterList[31]?.dropDownArray[0].value &&
    TableFilterList[31].selectedData[0]
      ? TableFilterList[31].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[31]?.dropDownArray[0].value &&
        !TableFilterList[31].selectedData[0]
      ? 'Blank'
      : TableFilterList[31].selectedData.join(';').toString()

  const lastUpdateDate =
    TableFilterList[32]?.dropDownArray[0].value &&
    TableFilterList[32].selectedData[0]
      ? TableFilterList[32].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[32]?.dropDownArray[0].value &&
        !TableFilterList[32].selectedData[0]
      ? 'Blank'
      : TableFilterList[32].selectedData.join(';').toString()

  const lastPrice =
    TableFilterList[33]?.dropDownArray[0].value &&
    TableFilterList[33].selectedData[0]
      ? TableFilterList[33].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[33]?.dropDownArray[0].value &&
        !TableFilterList[33].selectedData[0]
      ? 'Blank'
      : TableFilterList[33].selectedData.join(';').toString()

  const s_D =
    TableFilterList[34]?.dropDownArray[0].value &&
    TableFilterList[34].selectedData[0]
      ? TableFilterList[34].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[34]?.dropDownArray[0].value &&
        !TableFilterList[34].selectedData[0]
      ? 'Blank'
      : TableFilterList[34].selectedData.join(';').toString()

  const cash =
    TableFilterList[35]?.dropDownArray[0].value &&
    TableFilterList[35].selectedData[0]
      ? TableFilterList[35].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[35]?.dropDownArray[0].value &&
        !TableFilterList[35].selectedData[0]
      ? 'Blank'
      : TableFilterList[35].selectedData.join(';').toString()

  const margin =
    TableFilterList[36]?.dropDownArray[0].value &&
    TableFilterList[36].selectedData[0]
      ? TableFilterList[36].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[36]?.dropDownArray[0].value &&
        !TableFilterList[36].selectedData[0]
      ? 'Blank'
      : TableFilterList[36].selectedData.join(';').toString()

  const ira =
    TableFilterList[37]?.dropDownArray[0].value &&
    TableFilterList[37].selectedData[0]
      ? TableFilterList[37].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[37]?.dropDownArray[0].value &&
        !TableFilterList[37].selectedData[0]
      ? 'Blank'
      : TableFilterList[37].selectedData.join(';').toString()

  const upX_Z =
    TableFilterList[38]?.dropDownArray[0].value &&
    TableFilterList[38].selectedData[0]
      ? TableFilterList[38].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[38]?.dropDownArray[0].value &&
        !TableFilterList[38].selectedData[0]
      ? 'Blank'
      : TableFilterList[38].selectedData.join(';').toString()

  const currently_Still_in_StaleReport =
    TableFilterList[39]?.dropDownArray[0].value &&
    TableFilterList[39].selectedData[0]
      ? TableFilterList[39].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[39]?.dropDownArray[0].value &&
        !TableFilterList[39].selectedData[0]
      ? 'Blank'
      : TableFilterList[39].selectedData.join(';').toString()

  const last_Priced_In_Current_StaleReport =
    TableFilterList[40]?.dropDownArray[0].value &&
    TableFilterList[40].selectedData[0]
      ? TableFilterList[40].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[40]?.dropDownArray[0].value &&
        !TableFilterList[40].selectedData[0]
      ? 'Blank'
      : TableFilterList[40].selectedData.join(';').toString()

  const commentFilter =
    TableFilterList[41]?.dropDownArray[0].value &&
    TableFilterList[41].selectedData[0]
      ? TableFilterList[41].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[41]?.dropDownArray[0].value &&
        !TableFilterList[41].selectedData[0]
      ? 'Blank'
      : TableFilterList[41].selectedData.join(';').toString()

  const notes =
    TableFilterList[42]?.dropDownArray[0].value &&
    TableFilterList[42].selectedData[0]
      ? TableFilterList[42].selectedData.join(';').toString() + ';' + 'Blank'
      : TableFilterList[42]?.dropDownArray[0].value &&
        !TableFilterList[42].selectedData[0]
      ? 'Blank'
      : TableFilterList[42].selectedData.join(';').toString()

  const securityDescriptionP3 =
    P3TableFilterList[0]?.dropDownArray[0].value &&
    P3TableFilterList[0].selectedData[0]
      ? P3TableFilterList[0].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[0]?.dropDownArray[0].value &&
        !P3TableFilterList[0].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[0].selectedData.join(';').toString()

  let top_CategoryP3 = ''
  if (
    P3TableFilterList[1].dropDownArray.filter((option) => option.value)
      .length == P3TableFilterList[1].dropDownArray.length
  ) {
    top_CategoryP3 = ''
  } else {
    top_CategoryP3 = P3TableFilterList[1].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  let sub_CategoryP3 = ''
  if (
    P3TableFilterList[2].dropDownArray.filter((option) => option.value)
      .length == P3TableFilterList[2].dropDownArray.length
  ) {
    sub_CategoryP3 = ''
  } else {
    sub_CategoryP3 = P3TableFilterList[2].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.label)
      .join(';')
      .toString()
  }

  const reviewer_LatestCommentP3 =
    P3TableFilterList[3]?.dropDownArray[0].value &&
    P3TableFilterList[3].selectedData[0]
      ? P3TableFilterList[3].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[3]?.dropDownArray[0].value &&
        !P3TableFilterList[3].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[3].selectedData.join(';').toString()

  const security_Type_and_Sub_Type_CodeP3 =
    P3TableFilterList[4]?.dropDownArray[0].value &&
    P3TableFilterList[4].selectedData[0]
      ? P3TableFilterList[4].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[4]?.dropDownArray[0].value &&
        !P3TableFilterList[4].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[4].selectedData.join(';').toString()

  const lastPrice_In_Current_StaleReportP3 =
    P3TableFilterList[5]?.dropDownArray[0].value &&
    P3TableFilterList[5].selectedData[0]
      ? P3TableFilterList[5].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[5]?.dropDownArray[0].value &&
        !P3TableFilterList[5].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[5].selectedData.join(';').toString()

  let followup_FlagP3: string
  if (
    P3TableFilterList[6].dropDownArray.filter((option) => option.value)
      .length == P3TableFilterList[6].dropDownArray.length
  ) {
    followup_FlagP3 = ''
  } else {
    followup_FlagP3 = P3TableFilterList[6].dropDownArray
      .filter((obj) => obj.value)
      .map((obj) => obj.labelValue)
      .join(';')
      .toString()
  }

  const cusip_NumberP3 =
    P3TableFilterList[7]?.dropDownArray[0].value &&
    P3TableFilterList[7].selectedData[0]
      ? P3TableFilterList[7].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[7]?.dropDownArray[0].value &&
        !P3TableFilterList[7].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[7].selectedData.join(';').toString()

  const isiN_NUMBERP3 =
    P3TableFilterList[8]?.dropDownArray[0].value &&
    P3TableFilterList[8].selectedData[0]
      ? P3TableFilterList[8].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[8]?.dropDownArray[0].value &&
        !P3TableFilterList[8].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[8].selectedData.join(';').toString()

  const comments_Created_ByP3 =
    P3TableFilterList[9]?.dropDownArray[0].value &&
    P3TableFilterList[9].selectedData[0]
      ? P3TableFilterList[9].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[9]?.dropDownArray[0].value &&
        !P3TableFilterList[9].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[9].selectedData.join(';').toString()

  const reviewer_Last_Commented_DateP3 =
    P3TableFilterList[10]?.dropDownArray[0].value &&
    P3TableFilterList[10].selectedData[0]
      ? P3TableFilterList[10].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[10]?.dropDownArray[0].value &&
        !P3TableFilterList[10].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[10].selectedData.join(';').toString()

  const icE_Last_PricedP3 =
    P3TableFilterList[11]?.dropDownArray[0].value &&
    P3TableFilterList[11].selectedData[0]
      ? P3TableFilterList[11].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[11]?.dropDownArray[0].value &&
        !P3TableFilterList[11].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[11].selectedData.join(';').toString()

  const icE_Last_PriceP3 =
    P3TableFilterList[12]?.dropDownArray[0].value &&
    P3TableFilterList[12].selectedData[0]
      ? P3TableFilterList[12].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[12]?.dropDownArray[0].value &&
        !P3TableFilterList[12].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[12].selectedData.join(';').toString()

  const icE_NotesP3 =
    P3TableFilterList[13]?.dropDownArray[0].value &&
    P3TableFilterList[13].selectedData[0]
      ? P3TableFilterList[13].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[13]?.dropDownArray[0].value &&
        !P3TableFilterList[13].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[13].selectedData.join(';').toString()

  const bbG_Last_PricedP3 =
    P3TableFilterList[14]?.dropDownArray[0].value &&
    P3TableFilterList[14].selectedData[0]
      ? P3TableFilterList[14].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[14]?.dropDownArray[0].value &&
        !P3TableFilterList[14].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[14].selectedData.join(';').toString()

  const bbG_Last_PriceP3 =
    P3TableFilterList[15]?.dropDownArray[0].value &&
    P3TableFilterList[15].selectedData[0]
      ? P3TableFilterList[15].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[15]?.dropDownArray[0].value &&
        !P3TableFilterList[15].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[15].selectedData.join(';').toString()

  const boxLocationP3 =
    P3TableFilterList[16]?.dropDownArray[0].value &&
    P3TableFilterList[16].selectedData[0]
      ? P3TableFilterList[16].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[16]?.dropDownArray[0].value &&
        !P3TableFilterList[16].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[16].selectedData.join(';').toString()

  const locationMemoP3 =
    P3TableFilterList[17]?.dropDownArray[0].value &&
    P3TableFilterList[17].selectedData[0]
      ? P3TableFilterList[17].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[17]?.dropDownArray[0].value &&
        !P3TableFilterList[17].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[17].selectedData.join(';').toString()

  const boxDescP3 =
    P3TableFilterList[18]?.dropDownArray[0].value &&
    P3TableFilterList[18].selectedData[0]
      ? P3TableFilterList[18].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[18]?.dropDownArray[0].value &&
        !P3TableFilterList[18].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[18].selectedData.join(';').toString()

  const countryP3 =
    P3TableFilterList[19]?.dropDownArray[0].value &&
    P3TableFilterList[19].selectedData[0]
      ? P3TableFilterList[19].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[19]?.dropDownArray[0].value &&
        !P3TableFilterList[19].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[19].selectedData.join(';').toString()

  const securityTypeCodeP3 =
    P3TableFilterList[20]?.dropDownArray[0].value &&
    P3TableFilterList[20].selectedData[0]
      ? P3TableFilterList[20].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[20]?.dropDownArray[0].value &&
        !P3TableFilterList[20].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[20].selectedData.join(';').toString()

  const maturity_WarrantExpire_DateP3 =
    P3TableFilterList[21]?.dropDownArray[0].value &&
    P3TableFilterList[21].selectedData[0]
      ? P3TableFilterList[21].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[21]?.dropDownArray[0].value &&
        !P3TableFilterList[21].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[21].selectedData.join(';').toString()

  const inDefaultP3 =
    P3TableFilterList[22]?.dropDownArray[0].value &&
    P3TableFilterList[22].selectedData[0]
      ? P3TableFilterList[22].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[22]?.dropDownArray[0].value &&
        !P3TableFilterList[22].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[22].selectedData.join(';').toString()

  const description2P3 =
    P3TableFilterList[23]?.dropDownArray[0].value &&
    P3TableFilterList[23].selectedData[0]
      ? P3TableFilterList[23].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[23]?.dropDownArray[0].value &&
        !P3TableFilterList[23].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[23].selectedData.join(';').toString()

  const description3P3 =
    P3TableFilterList[24]?.dropDownArray[0].value &&
    P3TableFilterList[24].selectedData[0]
      ? P3TableFilterList[24].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[24]?.dropDownArray[0].value &&
        !P3TableFilterList[24].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[24].selectedData.join(';').toString()

  const eodcmvdaT_LastPricedP3 =
    P3TableFilterList[25]?.dropDownArray[0].value &&
    P3TableFilterList[25].selectedData[0]
      ? P3TableFilterList[25].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[25]?.dropDownArray[0].value &&
        !P3TableFilterList[25].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[25].selectedData.join(';').toString()

  const eodcmvdaT_LastPriceP3 =
    P3TableFilterList[26]?.dropDownArray[0].value &&
    P3TableFilterList[26].selectedData[0]
      ? P3TableFilterList[26].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[26]?.dropDownArray[0].value &&
        !P3TableFilterList[26].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[26].selectedData.join(';').toString()

  const issue_Status_CodeP3 =
    P3TableFilterList[27]?.dropDownArray[0].value &&
    P3TableFilterList[27].selectedData[0]
      ? P3TableFilterList[27].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[27]?.dropDownArray[0].value &&
        !P3TableFilterList[27].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[27].selectedData.join(';').toString()

  const dtC_Premed_EligP3 =
    P3TableFilterList[28]?.dropDownArray[0].value &&
    P3TableFilterList[28].selectedData[0]
      ? P3TableFilterList[28].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[28]?.dropDownArray[0].value &&
        !P3TableFilterList[28].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[28].selectedData.join(';').toString()

  const maturityDateP3 =
    P3TableFilterList[29]?.dropDownArray[0].value &&
    P3TableFilterList[29].selectedData[0]
      ? P3TableFilterList[29].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[29]?.dropDownArray[0].value &&
        !P3TableFilterList[29].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[29].selectedData.join(';').toString()

  const couponRateP3 =
    P3TableFilterList[30]?.dropDownArray[0].value &&
    P3TableFilterList[30].selectedData[0]
      ? P3TableFilterList[30].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[30]?.dropDownArray[0].value &&
        !P3TableFilterList[30].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[30].selectedData.join(';').toString()

  const symbolP3 =
    P3TableFilterList[31]?.dropDownArray[0].value &&
    P3TableFilterList[31].selectedData[0]
      ? P3TableFilterList[31].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[31]?.dropDownArray[0].value &&
        !P3TableFilterList[31].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[31].selectedData.join(';').toString()

  const currencyP3 =
    P3TableFilterList[32]?.dropDownArray[0].value &&
    P3TableFilterList[32].selectedData[0]
      ? P3TableFilterList[32].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[32]?.dropDownArray[0].value &&
        !P3TableFilterList[32].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[32].selectedData.join(';').toString()

  const lastPriceDateP3 =
    P3TableFilterList[33]?.dropDownArray[0].value &&
    P3TableFilterList[33].selectedData[0]
      ? P3TableFilterList[33].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[33]?.dropDownArray[0].value &&
        !P3TableFilterList[33].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[33].selectedData.join(';').toString()

  const lastPriceP3 =
    P3TableFilterList[34]?.dropDownArray[0].value &&
    P3TableFilterList[34].selectedData[0]
      ? P3TableFilterList[34].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[34]?.dropDownArray[0].value &&
        !P3TableFilterList[34].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[34].selectedData.join(';').toString()

  const cashQntyP3 =
    P3TableFilterList[35]?.dropDownArray[0].value &&
    P3TableFilterList[35].selectedData[0]
      ? P3TableFilterList[35].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[35]?.dropDownArray[0].value &&
        !P3TableFilterList[35].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[35].selectedData.join(';').toString()

  const marginQntyP3 =
    P3TableFilterList[36]?.dropDownArray[0].value &&
    P3TableFilterList[36].selectedData[0]
      ? P3TableFilterList[36].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[36]?.dropDownArray[0].value &&
        !P3TableFilterList[36].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[36].selectedData.join(';').toString()

  const inventoryP3 =
    P3TableFilterList[37]?.dropDownArray[0].value &&
    P3TableFilterList[37].selectedData[0]
      ? P3TableFilterList[37].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[37]?.dropDownArray[0].value &&
        !P3TableFilterList[37].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[37].selectedData.join(';').toString()

  const currently_Still_in_StaleReportP3 =
    P3TableFilterList[38]?.dropDownArray[0].value &&
    P3TableFilterList[38].selectedData[0]
      ? P3TableFilterList[38].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[38]?.dropDownArray[0].value &&
        !P3TableFilterList[38].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[38].selectedData.join(';').toString()

  const last_Priced_In_Current_StaleReportP3 =
    P3TableFilterList[39]?.dropDownArray[0].value &&
    P3TableFilterList[39].selectedData[0]
      ? P3TableFilterList[39].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[39]?.dropDownArray[0].value &&
        !P3TableFilterList[39].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[39].selectedData.join(';').toString()

  const commentFilterP3 =
    P3TableFilterList[40]?.dropDownArray[0].value &&
    P3TableFilterList[40].selectedData[0]
      ? P3TableFilterList[40].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[40]?.dropDownArray[0].value &&
        !P3TableFilterList[40].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[40].selectedData.join(';').toString()

  const notesP3 =
    P3TableFilterList[41]?.dropDownArray[0].value &&
    P3TableFilterList[41].selectedData[0]
      ? P3TableFilterList[41].selectedData.join(';').toString() + ';' + 'Blank'
      : P3TableFilterList[41]?.dropDownArray[0].value &&
        !P3TableFilterList[41].selectedData[0]
      ? 'Blank'
      : P3TableFilterList[41].selectedData.join(';').toString()

  const callBulkApi = async (payload: any) => {
    setPopUpLoader(true)
    const response = await dispatch(putUserBulkComment(payload))
    setPopUpLoader(false)

    if (response === 200) {
      props.system == 'Beta'
        ? dispatch(
            getBetaStalePriceData(
              'search',
              'Beta',
              props.fromDate,
              props.toDate,
              PageNumBeta,
              20,
              sortingBeta,
              sortDirectionBeta,
              0,
              securityNbr,
              top_Category,
              sub_Category,
              reviewer_LatestComment,
              lastPrice_In_Current_StaleReport,
              followup_Flag,
              securityDesc,
              cusip,
              isiN_Number,
              comments_Created_By,
              reviewer_Last_Commented_Date,
              icE_Last_Priced,
              icE_Last_Price,
              icE_Notes,
              bbG_Last_Priced,
              bbG_Last_Price,
              box_Location,
              box_Description,
              country,
              securityTypeCode,
              expirationDate,
              inDefault,
              corporateAction1,
              corporateAction2,
              surf_User_Pricing_Manual,
              surf_LastUpdated,
              surf_LastPrice,
              userIntial,
              changedDate,
              marketPrice,
              changeTerminal,
              dtC_Premed,
              lastUpdateDate,
              lastPrice,
              s_D,
              cash,
              margin,
              ira,
              upX_Z,
              currently_Still_in_StaleReport,
              last_Priced_In_Current_StaleReport,
              commentFilter,
              notes
            )
          )
        : dispatch(
            getP3StalePriceData(
              'search',
              'P3',
              props.fromDate,
              props.toDate,
              PageNumP3,
              20,
              sortingP3,
              sortDirectionP3,
              0,
              securityDescriptionP3,
              top_CategoryP3,
              sub_CategoryP3,
              reviewer_LatestCommentP3,
              security_Type_and_Sub_Type_CodeP3,
              lastPrice_In_Current_StaleReportP3,
              followup_FlagP3,
              cusip_NumberP3,
              isiN_NUMBERP3,
              comments_Created_ByP3,
              reviewer_Last_Commented_DateP3,
              icE_Last_PricedP3,
              icE_Last_PriceP3,
              icE_NotesP3,
              bbG_Last_PricedP3,
              bbG_Last_PriceP3,
              boxLocationP3,
              locationMemoP3,
              boxDescP3,
              countryP3,
              securityTypeCodeP3,
              maturity_WarrantExpire_DateP3,
              inDefaultP3,
              description2P3,
              description3P3,
              eodcmvdaT_LastPricedP3,
              eodcmvdaT_LastPriceP3,
              issue_Status_CodeP3,
              dtC_Premed_EligP3,
              maturityDateP3,
              couponRateP3,
              symbolP3,
              currencyP3,
              lastPriceDateP3,
              lastPriceP3,
              cashQntyP3,
              marginQntyP3,
              inventoryP3,
              currently_Still_in_StaleReportP3,
              last_Priced_In_Current_StaleReportP3,
              commentFilterP3,
              notesP3
            )
          )
      setrequestSubmit({
        status: true,
        text: `Comment added for ${props.selectedRows.length} securities`,
      })
    }
    if (response === 400) {
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }

  const buildBulkCommentPayloadData = () => {
    const payload: any = []
    if (props.system == 'Beta') {
      props.selectedRows.forEach((row: any) => {
        const temp = {
          securityNumber: row.securityNbr,
          cusip: row.cusip.replace(/'/g, ''),
          boxLocation:
            row.box_Location == null ? '' : row.box_Location.toString(),
          system: 'Beta',
          comment: inputComment,
          createdBy: localStorage.getItem('AccountName'),
          createdOn: new Date().toISOString(),
          reportRunDate: props.toDate,
        }
        payload.push(temp)
      })
    } else {
      props.selectedRows.forEach((row: any) => {
        const temp = {
          securityNumber: '',
          cusip: row.cusip_Number.replace(/'/g, ''),
          boxLocation:
            row.boxLocation == null ? '' : row.boxLocation.toString(),
          system: 'P3',
          comment: inputComment,
          createdBy: localStorage.getItem('AccountName'),
          createdOn: new Date().toISOString(),
          reportRunDate: props.toDate,
        }
        payload.push(temp)
      })
    }

    callBulkApi(payload)
  }

  return (
    <>
      {props.selectedRows && (
        <>
          <BulkCommentWrapper>
            <Button
              bgColor={'transparent'}
              color={
                props.selectedRows.length > 1
                  ? COLORS.Background.Primarytext
                  : COLORS.Background.Neutral40
              }
              padding={'4px 16px'}
              borderRadius={'4px'}
              style={{ whiteSpace: 'nowrap' }}
              type={'button'}
              disabled={!writeExecuteFlag}
              borderColor={
                props.selectedRows.length > 1
                  ? COLORS.Background.Primarytext
                  : COLORS.Background.Neutral40
              }
              onClick={() => {
                if (props.selectedRows.length < 1) {
                  return
                }
                setComments(!comment)
              }}
            >
              Bulk Comment
            </Button>

            {comment && (
              <PopupCardBulkComment>
                <Textarea
                  onChange={(e: any) => {
                    setInputComment(e.target.value)
                  }}
                  height="365px"
                  placeholder={'Enter Comment'}
                ></Textarea>

                <StyledSelectWrapper
                  style={{
                    padding: '2px 0px 0px 0px',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    style={{
                      marginRight: '16px',
                    }}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    type={'button'}
                    color={COLORS.Background.Primarytext}
                    bgColor={'transparent'}
                    onClick={() => {
                      setComments(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    hoverBgColor={COLORS.Background.Primary70}
                    bgColor={COLORS.Background.Primarytext}
                    color={COLORS.Background.Primary}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    hover={true}
                    type={''}
                    activeBgColor={COLORS.Background.Primaryactive}
                    borderColor={'transparent'}
                    onClick={async () => {
                      buildBulkCommentPayloadData()
                      setComments(false)
                    }}
                  >
                    Submit
                  </Button>
                </StyledSelectWrapper>
              </PopupCardBulkComment>
            )}
          </BulkCommentWrapper>
          <Button
            bgColor={'transparent'}
            color={
              props.selectedRows.length > 1
                ? COLORS.Background.Primarytext
                : COLORS.Background.Neutral40
            }
            padding={'4px 16px'}
            borderRadius={'4px'}
            style={{ whiteSpace: 'nowrap' }}
            type={'button'}
            borderColor={
              props.selectedRows.length > 1
                ? COLORS.Background.Primarytext
                : COLORS.Background.Neutral40
            }
            onClick={() => {
              if (props.selectedRows.length < 1) {
                return
              }
              props.toggleAllRowsSelected(false)
            }}
          >
            Deselect
          </Button>
        </>
      )}

      {popUpLoader && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <LoadingTextSpace>
            <Label> Loading...</Label>
          </LoadingTextSpace>{' '}
        </PopUpStyleLoader>
      )}

      {requestSubmit.status !== null && (
        <ToastWrapper>
          <Toast
            text={requestSubmit.text}
            type={requestSubmit.status ? 'success' : 'danger'}
            openStatusCallback={(status: boolean) =>
              setrequestSubmit({
                status: status ? status : null,
                text: '',
              })
            }
          />
        </ToastWrapper>
      )}
    </>
  )
}

export default BulkComment
