import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Backgrounds, Colors } from '../../shared/styles'

export interface InfoLabelProps {
  title: ReactNode
  onCloseLabel: any
}



export const StyledLabel = styled.a<InfoLabelProps>`
  display: inline-block;
  padding: 0 17px;
  font-size: 16px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #f1f1f1;
  }
`
export const CloseBtn = styled.a<InfoLabelProps>`
  padding-left: 10px;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
  &:hover { 
    color: #000;   
  }
  }
`
