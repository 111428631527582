import { TabData } from '../../../components/tab/TabListModels'
import TradeReportDetails from './TradeReportDetails'
import TradeReportSummary from './TradeReportSummary'

export const BetaShortSalesTabDetails: TabData[] = [
  {
    tabIndex: 0,
    label: 'Details',
    component: TradeReportDetails,
    apiEndpoint: '',
  },
  {
    tabIndex: 1,
    label: 'Summary',
    component: TradeReportSummary,
    apiEndpoint: '',
  },
 
]
