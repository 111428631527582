import Label from '../label/Label'
import SvgCalendar from '../svg/logo/SvgCalendar'
import DatePicker from 'react-datepicker'
import { Props, Wrapper } from './styles'

import { getDay } from 'date-fns'

const WeekDayCalender = ({
  open,
  onChange,
  onClickOutside,
  onFocus,
  maxDate,
  selected,
  minDate,
  startDate,
  endDate,
  selectsStart,
  selectsEnd,
  excludeDates,
  onSelect
}: Props) => {
  const isWeekday = (date: any) => {
    const day = getDay(date)
    return day !== 0 && day !== 6
  }

  return (
    <Wrapper>
      <DatePicker
        id="date"
        className="item"
        placeholderText="Select a Date"
        onSelect={onSelect}
        open={open}
        excludeDates={excludeDates}
        showYearDropdown
        showMonthDropdown
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        onFocus={onFocus}
        onClickOutside={onClickOutside}
        selected={selected}
        dateFormat="MM/dd/yyyy"
        filterDate={isWeekday}
        maxDate={maxDate}
        minDate={minDate}
        onChange={onChange}
      />
      <Label cursor='pointer' onClick={onFocus}>
        
        <SvgCalendar />
      </Label>
    </Wrapper>
  )
}

export default WeekDayCalender
