import {
  RootContainerTable,
  TableHeadingWrapper,
  StyledTableContainer,
  IconsWrapper,
  PopUpStyleLoader,
  StyledSelectWrapper,
  LottieWrapper,
} from '../BuyInAllocationAndExecution/broker/styles'
import { useEffect, useRef, useState } from 'react'
import { Backgrounds, Colors } from '../../../shared/styles'
import FileUpload from '../BuyInAllocationAndExecution/FileUploadModal'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import AllocationTable from '../BuyInAllocationAndExecution/table/Table'
import {
  commonTabProps,
  setCommonTabProps,
} from '../BuyInAllocationAndExecution/IBuyInAllocationInterface'
import { useDispatch, useSelector } from 'react-redux'
import {
  LoadingSelector,
  ToastMessageSelector,
  addBuyInTab,
  deleteL2RegSHOData,
  emailUploadL2RegSHOData,
  getAuditTrailBuyInNotify,
  getRegCommentsBuyIn,
  setToastMessage,
  setToastResponse,
  updateBuyInTab,
} from '../../../store/reg-SHO-Tool/reg-sho-buyInAllocation'
import { SvgPlus } from '../../../components/svg/SvgPlus'
import Label from '../../../components/label/Label'
import { PopupData } from '../../../models/PopUpData'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  CancelButtonWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  ToastWrapper,
} from '../styles'
import { Toast } from '../../../components/toast/Toast'
import { EmailList } from '../BuyInAllocationAndExecution/EmailList'
import { CommentTableContainer, TableHeight } from './styles'
import Table from '../../../components/table/Table'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import { COLORS } from '../../../theme/Colors'
import AuditTrailL2L3 from '../BuyInAllocationAndExecution/AuditTrailL2L3'
import { AuditTrailColumn } from '../BuyInAllocationAndExecution/AuditTrailColumn'
import { getAccessFlag } from '../../../utils/globalFunctions'
import { DisabledFunction } from '../utils'

export interface IL2L3BuyInDetails {
  commonTabProps: commonTabProps
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  setCommonTabProps: setCommonTabProps
  setReset: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  specificColumnL2: any
  data: any
  buyInData: any
}

const BuyInTabDetails = ({
  commonTabProps,
  setActiveTab,
  setCommonTabProps,
  title,
  setReset,
  specificColumnL2,
  data,
  buyInData,
}: IL2L3BuyInDetails) => {
  const dispatch = useDispatch()
  const loader = useSelector(LoadingSelector)
  const ToastMessage = useSelector(ToastMessageSelector)
  const [fileData, setFileData] = useState<File>()
  const [mailPOPUP, setMailPOPUP] = useState<boolean>(false)
  const [mailList, setMailList] = useState<boolean>(false)
  const [showAudit, setShowAudit] = useState<boolean>(false)
  const writeExecuteFlag = getAccessFlag(['SS-RWX', 'OM-RWX'])
  const [rowId, setRowId] = useState<number>(0)
  const [userComment, setUserComment] = useState<any>([])
  const [dataAuditTrail, setDataAuditTrail] = useState<any>([])
  const [L2L3Columns, setL2L3Columns] = useState<any>([])
  const [viewAllComments, setViewAllComments] = useState<boolean>(false)
  const [executionUserInputs, setExecutionUserInputs] =
    useState<any[]>(buyInData)
  const executionUserInputsRef = useRef(executionUserInputs)

  const currentDate = new Date()

  const addBuyIn = async () => {
    const response = await dispatch(
      addBuyInTab({
        regSHOBuyInId: null,
        allocationId: 0,
        regShoid: commonTabProps.regSHOId,
        allocationType: null,
        source: commonTabProps.source,
        cusip: commonTabProps.selectedSummaryTableCUSIP,
        pageName: 'buyins',
        settleDate: commonTabProps.failDate,
        reportDate: commonTabProps.selectedCUSIPReportDate,
        dateToBuyIn: '',
      })
    )
    response && (await setReset(true))
  }
  const UpdateBuyIn = async (id: number) => {
    const { regSHOBuyIn_ID, source } = data[id] ? data[id] : []
    const response = await dispatch(
      updateBuyInTab({
        regSHOBuyInId: regSHOBuyIn_ID,
        pageName: 'buyins',
        source: source,
        allocationType: executionUserInputsRef.current[id]?.buyInType,
        buyInType: executionUserInputsRef.current[id]?.buyInType,
        ...executionUserInputsRef.current[id],
      })
    )
    response && (await setReset(true))
  }
  const DeleteBuyInInformation = async (id: number) => {
    const { regSHOBuyIn_ID } = data[id] ? data[id] : []
    const response = await dispatch(deleteL2RegSHOData(regSHOBuyIn_ID))
    response && (await setReset(true))
  }

  const AuditBuyInNotify = async (id: number) => {
    const { regSHOBuyIn_ID } = data[id] ? data[id] : []
    const response = await dispatch(getAuditTrailBuyInNotify(regSHOBuyIn_ID))
    setDataAuditTrail(response)
    await setShowAudit(true)
  }

  const EmailUploadBuyIn = async (fileName: string) => {
    const { regShoId, allocationId } = data[rowId] ? data[rowId] : []
    const response = await dispatch(
      emailUploadL2RegSHOData({
        fileName,
        regSHO_Id: regShoId,
        allocation_ID: allocationId,
        fileData,
        dataLevel: 'buyins',
      })
    )
    setMailPOPUP(false)
  }
  const handleViewComment = async (id: number) => {
    const { regSHOBuyIn_ID } = data[id] ? data[id] : []
    const response = await dispatch(
      getRegCommentsBuyIn({
        regSHO_Id: commonTabProps.regSHOId,
        allocationType: '',
        currHist: 'C',
        pageNumber: 0,
        pageSize: 0,
        sortBy: 'regSHOBuyIn_ID',
        sortDirection: 'ASC',
        source: commonTabProps.source,
        regSHOBuyIn_ID: regSHOBuyIn_ID,
      })
    )
    setUserComment(response)
    await setViewAllComments(true)
  }
  const BuyInInformationL2Column = specificColumnL2(
    executionUserInputsRef,
    setExecutionUserInputs,
    UpdateBuyIn,
    DeleteBuyInInformation,
    AuditBuyInNotify,
    commonTabProps,
    setActiveTab,
    setCommonTabProps,
    handleViewComment
  )

  useEffect(() => {
    executionUserInputsRef.current = executionUserInputs
  }, [executionUserInputs])
  useEffect(() => {
    setL2L3Columns(BuyInInformationL2Column)
  }, [])

  return (
    <TableHeadingWrapper>
      {loader && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      <WDCard>
        <CardHeader label={title} />
        <IconsWrapper
          disabled={
            writeExecuteFlag &&
            !commonTabProps.approveFlag &&
            commonTabProps.selectedCUSIPReportDate >=
              currentDate?.toISOString()?.split('T')[0]
          }
          onClick={() =>
            writeExecuteFlag &&
            !commonTabProps.approveFlag &&
            commonTabProps.selectedCUSIPReportDate >=
              currentDate?.toISOString()?.split('T')[0] &&
            addBuyIn()
          }
        >
          <SvgPlus fillColor={Colors.White}></SvgPlus>
          <Label color={Colors.White}> Add</Label>
        </IconsWrapper>
      </WDCard>
      <StyledTableContainer>
        <RootContainerTable>
          <TableHeight>
            <AllocationTable
              setRowId={setRowId}
              setMailPOPUP={setMailPOPUP}
              setMailList={setMailList}
              tableColumns={L2L3Columns}
              tableData={data}
              disableComponent={DisabledFunction(
                '',
                '',
                commonTabProps.selectedCUSIPReportDate,
                commonTabProps.approveFlag
              )}
              onClick={() => {
                //
              }}
            />
          </TableHeight>
        </RootContainerTable>
        {viewAllComments && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{
                padding: '15px 15px 0px 15px',
                fontWeight: '600',
                borderRadius: '8px 8px 0px 0px',
              }}
            >
              <>Comments</>
              <MarginLeftAutoSpace>
                <CancelButtonWrapper
                  onClick={() => {
                    setViewAllComments(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </CancelButtonWrapper>
              </MarginLeftAutoSpace>
              <Divider
                thickness={'1px'}
                horizontal={true}
                bgColor={Backgrounds.Gray110}
              />
            </StyledSelectWrapper>
            <CommentTableContainer>
              <Table
                tableColumns={[
                  {
                    Header: 'Comment',
                    accessor: 'buyInComment',
                  },
                  {
                    Header: 'Created by',
                    accessor: 'createBy',
                  },
                  {
                    Header: 'Create on(EST)',
                    accessor: 'created_On',
                  },
                ]}
                tableData={userComment?.data[0]?.buyInComment?.length > 0 ? userComment.data : []}
                onClick={() => {
                  //
                }}
              />
            </CommentTableContainer>
          </PopUpStyleContent>
        )}
        {mailPOPUP && (
          <FileUpload
            ClosePopUp={() => {
              setMailPOPUP(false)
              setFileData(undefined)
            }}
            FileData={(e: File) => {
              setFileData(e)
            }}
            onSubmit={EmailUploadBuyIn}
          />
        )}
        {mailList && (
          <EmailList
            dataLevel={'buyins'}
            allocationId={data[rowId].allocationId}
            commonTabProps={commonTabProps}
            ClosePopUp={() => {
              setMailList(false)
            }}
          />
        )}
      </StyledTableContainer>

      {showAudit && (
        <AuditTrailL2L3
          data={dataAuditTrail}
          setShowAudit={setShowAudit}
          column={AuditTrailColumn}
        />
      )}
      {ToastMessage.length > 0 && (
        <ToastWrapper>
          <Toast
            text={ToastMessage}
            type={'success'}
            openStatusCallback={() => {
              dispatch(setToastMessage(''))
              dispatch(setToastResponse(null))
            }}
          />
        </ToastWrapper>
      )}
    </TableHeadingWrapper>
  )
}

export default BuyInTabDetails
