import styled from 'styled-components'

export const ErrorContainer = styled.div`
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;

`

export const ErrorTextContainer = styled.div`
    display: table-cell;
    vertical-align: middle;
`
export const ErrorHeading = styled.h1`
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    text-shadow: -1px 0 #2563EB, 0 1px #2563EB, 1px 0 #2563EB, 0 -1px #2563EB;
    font-family: sans; 
    color: #2563EB;
`