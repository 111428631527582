import { ImageProps, StyledImage } from './styles'

const Image = ({ image, alt, width, height, onClick }: ImageProps) => {
  return (
    <StyledImage
      src={image}
      alt={alt}
      width={width}
      height={height}
      onClick={onClick}
    />
  )
}

export default Image
