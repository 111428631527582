import {
  AmountContainer,
  InformationContainer,
  InformationTile,
  QuantityContainer,
  SummaryCardContainerTradeBreakCountT1,
  TitleSubContainer,
} from './styles'
import { useEffect, useState } from 'react'
import { authApiCall } from '../../../../utils/api'
import {
  formattingCurrencyWithDecimal,
  formattingDate,
  formattingDateWithTime,
  formattingNumber,
} from '../../../../utils/globalFunctions'
import { configJSONDashboard } from '../../../../utils/config'

const TradeBreakCountT1 = (props: {
  isIntraday?: boolean
  widgetDescription: any
}) => {
  const [datas, setDatas] = useState<any>([])
  const [currentTime, setCurrentTime] = useState<any>(null)

  useEffect(() => {
    const getTradeBreakCount = async () => {
      let api = ''
      if (props?.isIntraday) {
        api = configJSONDashboard.IntraDayTradeBreaks.tradeBreakCountT1.kpi
      } else {
        api = configJSONDashboard.TradeBreaks.tradeBreakCountT1.kpi
      }
      try {
        const { data, status } = await authApiCall.get(api)
        if (status === 200) {
          setDatas(data.tradeBreakCountViewModels)
          if (props?.isIntraday) {
            setCurrentTime(data?.currentTime)
          }
        }
      } catch (e) {
        const error = e
      }
    }
    getTradeBreakCount()
  }, [])

  const renderDateTime = (currentDate: Date | string) => {
    if (props?.isIntraday && currentTime) {
      return formattingDateWithTime(currentTime as Date)
    } else {
      if (currentDate) {
        return formattingDate(currentDate as string)
      }
    }
  }

  return (
    <SummaryCardContainerTradeBreakCountT1>
      <TitleSubContainer>T1</TitleSubContainer>
      {datas.map((m: any, key: any) => (
        <InformationContainer key={key}>
          <InformationTile>
            <QuantityContainer># of Breaks</QuantityContainer>
            <AmountContainer>
              {formattingNumber(m.countPrevious)}{' '}
            </AmountContainer>
          </InformationTile>
          <InformationTile>
            <QuantityContainer>Total Amount</QuantityContainer>
            <AmountContainer>
              {m.amountPrevious
                ? formattingCurrencyWithDecimal(m.amountPrevious)
                : 0}{' '}
            </AmountContainer>
          </InformationTile>
        </InformationContainer>
      ))}
    </SummaryCardContainerTradeBreakCountT1>
  )
}

export default TradeBreakCountT1
