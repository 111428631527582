import { useEffect, useState } from 'react'
import Divider from '../../../components/divider/Divider'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import { Backgrounds } from '../../../shared/styles'
import { COLORS } from '../../../theme/Colors'
import {
  CancelButtonWrapper,
  MailWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  StyledSelectWrapper,
} from './CommonBuyinStyles'
import { commonTabProps } from './IBuyInAllocationInterface'
import { useDispatch } from 'react-redux'
import {
  getEmailDelete,
  getEmailDownload,
  getL2AllEmailList,
} from '../../../store/reg-SHO-Tool/reg-sho-buyInAllocation'
import Table from '../../../components/table/Table'
import { StyledImage } from '../../../components/image/styles'
import { Icons } from '../../../shared/GlobalStyle'
import { setIsLoading } from '../../../store/reg-SHO-Tool/Reg-SHO-Summary'

interface EmailListProps {
  commonTabProps: commonTabProps
  ClosePopUp: () => void
  allocationId: any
  dataLevel: string
  disableDeleteClickFlag?: boolean
}
export const EmailList = ({
  ClosePopUp,
  commonTabProps,
  allocationId,
  dataLevel,
  disableDeleteClickFlag,
}: EmailListProps) => {
  const dispatch = useDispatch()
  const [allMail, setAllMail] = useState([])

  useEffect(() => {
    async function getAllMail() {
      dispatch(setIsLoading(true))
      const response = await dispatch(
        getL2AllEmailList(commonTabProps.regSHOId, allocationId, dataLevel)
      )
      setAllMail(response)
      dispatch(setIsLoading(false))
    }
    getAllMail()
  }, [])

  const downloadEmail = async (e: number) => {
    await dispatch(getEmailDownload(allMail[e]))
  }

  const deleteEmail = async (e: number) => {
    dispatch(setIsLoading(true))
    await dispatch(getEmailDelete(allMail[e]))
    const response = await dispatch(
      getL2AllEmailList(commonTabProps.regSHOId, allocationId, dataLevel)
    )
    setAllMail(response)
    dispatch(setIsLoading(false))
  }

  return (
    <PopUpStyleContent>
      <MailWrapper>
        <StyledSelectWrapper
          style={{
            padding: '15px 15px 0px 15px',
            fontWeight: '600',
            borderRadius: '8px 8px 0px 0px',
          }}
        >
          <MarginLeftAutoSpace>
            <CancelButtonWrapper
              onClick={() => {
                ClosePopUp()
              }}
            >
              <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
            </CancelButtonWrapper>
          </MarginLeftAutoSpace>
          <Divider
            thickness={'1px'}
            horizontal={true}
            bgColor={Backgrounds.Gray110}
          />
        </StyledSelectWrapper>

        <Table
          tableColumns={[
            {
              Header: 'Email',
              accessor: 'fileName',
            },
            {
              Header: 'Download',
              Cell: (e: { cell: { row: { id: number } } }) => {
                return (
                  <StyledImage
                    title="Download"
                    onClick={() => {
                      downloadEmail(e.cell.row.id)
                    }}
                    width={'25px'}
                    style={{ cursor: 'pointer' }}
                    src={Icons.Download}
                    alt="Download"
                  />
                )
              },
            },
            {
              Header: 'Delete',
              Cell: (e: { cell: { row: { id: number } } }) => {
                return (
                  <StyledImage
                    title="Delete"
                    onClick={() => {
                      if (disableDeleteClickFlag) return

                      deleteEmail(e.cell.row.id)
                    }}
                    width={'25px'}
                    style={
                      disableDeleteClickFlag
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    }
                    src={Icons.Delete}
                    alt="Delete"
                  />
                )
              },
            },
          ]}
          tableData={allMail}
          onClick={() => {
            //
          }}
        />

        {allMail.length === 0 && <>No Email</>}
      </MailWrapper>
    </PopUpStyleContent>
  )
}
