/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  RootContainer,
  DownloadWrapper,
  CardContainer,
  FilterCard,
  SubHeading,
  StyledSelectWrapper,
  StyledTableContainer,
  HeadingDownloadContainer,
  FilterOptionWrapper,
  ToastWrapper,
  PopUpStyleLoader,
  LodingTable,
  LottieWrapper,
  RadioButtonWrapper,
  PopUpStyleContent,
  CommentTableContainer,
  HeadingWrapper,
  RadioCommentWrapper,
  MarginLeftAutoSpace,
  LoadingTextSpace,
  DownloadButtonWrapper,
  CancelButtonWrapper,
} from './styles'
import {
  actionSelector,
  getP3FailAnalysis,
  isLoadingSelector,
  getP3EXCEL,
  p3FailAnalysisDataSelector,
  commentSelector,
  SetP3FailAnalysis,
  setAction,
  setSorting,
  setSortDirection,
  sortingSelector,
  sortDirectionSelector,
  getComments,
  getP3CSV,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../../store/failAnalysis/p3-fail-analysis'
import {
  WDStyledButtonText,
  WDStyledHeading,
} from '../../../../components/ui/WDTypography'
import Label from '../../../../components/label/Label'
import SelectDropdown from '../../../../components/selectDropdown/SelectDropdown'
import { Textbox } from '../../../../components/textbox/Textbox'
import Button from '../../../../components/button/Button'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import P3Table from './p3Table/P3Table'
import { useDispatch, useSelector } from 'react-redux'
import WDCard from '../../../../components/ui/WDCard'
import { CardHeader } from '../../../../composites/cardHeader/CardHeader'
import { Toast } from '../../../../components/toast/Toast'
import Loader from '../../../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import Divider from '../../../../components/divider/Divider'
import { Backgrounds } from '../../../../shared/styles'
import { COLORS } from '../../../../theme/Colors'
import { SvgDownloadSmall } from '../../../../components/svg/logo/SvgDownload'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import { addDays, formatISO } from 'date-fns'
import WeekDayCalender from '../../../../components/WeekDayCalander/WeekDayCalender'
import { FormateDateSelector } from '../../../../store/dash-board/about'
import Checkbox from '../../../../components/checkbox/Checkbox'

const P3FailHistory = () => {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const Usercomments = useSelector(commentSelector)
  const ExcludeDate = useSelector(FormateDateSelector)
  const data = useSelector(p3FailAnalysisDataSelector)
  const buffering = useSelector(isLoadingSelector)
  const action = useSelector(actionSelector)
  const sorting = useSelector(sortingSelector)
  const sortDirection = useSelector(sortDirectionSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [resetTable, setResetTable] = useState<boolean>(false)
  const [openComments, setOpenComments] = useState<boolean>(false)
  const [popUpLoader, setPopUpLoader] = useState<boolean>(false)
  const [hidePagination, setHidePagination] = useState<boolean>(false)
  const [includeFailComments, setIncludeFailComments] = useState(0)
  const [ageRange, setAgeRange] = useState<any>('')
  const [buySell, setBuySell] = useState<any>()
  const [type] = useState<any>({ value: 'history', label: 'Historical' })
  const [checkAccCusip, setCheckAccCusip] = useState(false)
  const [loadingBuffer, setLoadingBuffer] = useState<boolean>(false)
  const DefaultDate: string =
    localStorage.getItem('DefaultDate') &&
    localStorage.getItem('DefaultDate') != 'undefined'
      ? JSON.parse(localStorage.getItem('DefaultDate') || '')
      : ''
  const [Startdate, setStartDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )
  const [Enddate, setEndDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )
  const [startCalendar, setStartCalender] = useState<boolean>(false)
  const [endCalendar, setEndCalender] = useState<boolean>(false)
  const [checkedValue, setCheckedValue] = useState(0)
  const ageOptions = [
    { value: '', label: 'Select' },
    { value: '1', label: '1' },
    { value: '2 to 5', label: '2 to 5' },
    { value: '6 to 30', label: '6 to 30' },
    { value: '30 and more', label: '30 more' },
  ]

  const typeOption = [
    { value: 'daily', label: 'Daily' },
    { value: 'history', label: 'Historical' },
  ]

  const BSoption = [
    { value: '', label: 'Select' },
    { value: 'S', label: 'Sell' },
    { value: 'B', label: 'Buy' },
  ]

  useEffect(() => {
    dispatch(setAction(''))
    dispatch(
      SetP3FailAnalysis({
        data: [],
      })
    )
  }, [])

  const savedComments = async (tb: any) => {
    setPopUpLoader(true)
    await dispatch(getComments('P3', data.p3Report[tb].failUniqueId))
    setPopUpLoader(false)
    setOpenComments(true)
    setHidePagination(true)
  }

  const [input, setInput] = useState({
    commentDate: new Date().toISOString(),
    cusip: '',
    entity: '',
    branch: '',
    accountNumber: '',
    pageNumber: 0,
    commentRow: 0,
  })

  const handleSearch = async () => {
    if (
      formatISO(Enddate).split('T')[0] == formatISO(Startdate).split('T')[0]
    ) {
      setCheckAccCusip(false)
      setResetTable(true)
      setInput({ ...input, pageNumber: 0 })
      dispatch(setSortDirection('ASC'))
      dispatch(setSorting('SnapShotDate, Age'))
      setLoadingBuffer(true)
      const response = await dispatch(
        getP3FailAnalysis(
          'search',
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.entity,
          buySell,
          input.cusip,
          0,
          20,
          sorting,
          sortDirection,
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )
      setLoadingBuffer(false)
      setResetTable(false)
    } else if (
      (formatISO(Enddate).split('T')[0] > formatISO(Startdate).split('T')[0] &&
        input.accountNumber) ||
      input.cusip
    ) {
      setCheckAccCusip(false)
      setResetTable(true)
      setInput({ ...input, pageNumber: 0 })
      dispatch(setSortDirection('ASC'))
      dispatch(setSorting('SnapShotDate, Age'))
      setLoadingBuffer(true)
      const response = await dispatch(
        getP3FailAnalysis(
          'search',
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.entity,
          buySell,
          input.cusip,
          0,
          20,
          sorting,
          sortDirection,
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )
      setLoadingBuffer(false)
      setResetTable(false)
    } else {
      setCheckAccCusip(true)
    }
  }
  const DownloadEXCEL = async () => {
    if (
      formatISO(Enddate).split('T')[0] == formatISO(Startdate).split('T')[0]
    ) {
      setCheckAccCusip(false)
      setPopUpLoader(true)
      const response = await dispatch(
        getP3EXCEL(
          `P3Fail_${type.value}_${formatISO(Startdate).split('T')[0]}`,
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.entity,
          buySell,
          input.cusip,
          0,
          0,
          'SnapShotDate, Age',
          'ASC',
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )
      setPopUpLoader(false)
    } else if (
      (formatISO(Enddate).split('T')[0] > formatISO(Startdate).split('T')[0] &&
        input.accountNumber) ||
      input.cusip
    ) {
      setCheckAccCusip(false)
      setPopUpLoader(true)
      const response = await dispatch(
        getP3EXCEL(
          `P3Fail_${type.value}_${formatISO(Startdate).split('T')[0]}`,
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.entity,
          buySell,
          input.cusip,
          0,
          0,
          'SnapShotDate, Age',
          'ASC',
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )
      setPopUpLoader(false)
    } else {
      setCheckAccCusip(true)
    }
  }
  const DownloadCSV = async () => {
    if (
      formatISO(Enddate).split('T')[0] == formatISO(Startdate).split('T')[0]
    ) {
      setCheckAccCusip(false)
      setPopUpLoader(true)
      const response = await dispatch(
        getP3CSV(
          `P3Fail_${type.value}_${formatISO(Startdate).split('T')[0]}`,
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.entity,
          buySell,
          input.cusip,
          0,
          0,
          'SnapShotDate, Age',
          'ASC',
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )
      setPopUpLoader(false)
    } else if (
      (formatISO(Enddate).split('T')[0] > formatISO(Startdate).split('T')[0] &&
        input.accountNumber) ||
      input.cusip
    ) {
      setCheckAccCusip(false)
      setPopUpLoader(true)
      const response = await dispatch(
        getP3CSV(
          `P3Fail_${type.value}_${formatISO(Startdate).split('T')[0]}`,
          type.value,
          formatISO(Startdate).split('T')[0],
          formatISO(Enddate).split('T')[0],
          ageRange,
          input.accountNumber,
          input.branch,
          input.entity,
          buySell,
          input.cusip,
          0,
          0,
          'SnapShotDate, Age',
          'ASC',
          '',
          includeFailComments,
          '',
          checkedValue
        )
      )
      setPopUpLoader(false)
    } else {
      setCheckAccCusip(true)
    }
  }
  function onChangeValue(event: any) {
    setIncludeFailComments(event.target.value)
  }

  function checkCheckbox(event: any) {
    setCheckedValue(event.target.checked ? 1 : 0)
  }

  return (
    <>
      <RootContainer>
        <HeadingDownloadContainer>
          <HeadingWrapper>
            <WDStyledHeading>
              <Label>{'P3 Fail Analysis'}</Label>
            </WDStyledHeading>
          </HeadingWrapper>
          <DownloadWrapper>
            <DownloadButtonWrapper
              onClick={() => {
                DownloadCSV()
              }}
            >
              <SvgDownloadSmall
                fillColor={`${COLORS.UI.Primary50}`}
              ></SvgDownloadSmall>
              <WDStyledButtonText>
                <Label cursor="pointer">CSV</Label>
              </WDStyledButtonText>
            </DownloadButtonWrapper>
          </DownloadWrapper>
          <DownloadWrapper>
            <DownloadButtonWrapper
              onClick={() => {
                DownloadEXCEL()
              }}
            >
              <SvgDownloadSmall
                fillColor={`${COLORS.UI.Primary50}`}
              ></SvgDownloadSmall>
              <WDStyledButtonText>
                <Label cursor="pointer">EXCEL</Label>
              </WDStyledButtonText>
            </DownloadButtonWrapper>
          </DownloadWrapper>
        </HeadingDownloadContainer>
        <CardContainer>
          <FilterCard>
            <SubHeading>Filter Fails</SubHeading>
            <StyledSelectWrapper>
              <FilterOptionWrapper>
                <Label>Type</Label>
                <SelectDropdown
                  defaultValue={{ value: 'history', label: 'Historical' }}
                  borderRadius={'4px'}
                  height="32px"
                  options={typeOption}
                  onChange={(e: any) => {
                    {
                      e === 'daily' && navigation(-1)
                    }
                  }}
                ></SelectDropdown>
              </FilterOptionWrapper>
              <FilterOptionWrapper>
                <Label>Start Date</Label>
                <WeekDayCalender
                  excludeDates={ExcludeDate}
                  open={startCalendar}
                  onSelect={() => setStartCalender(false)}
                  onFocus={() => setStartCalender(true)}
                  onClickOutside={() => setStartCalender(false)}
                  selected={Startdate}
                  startDate={Startdate}
                  selectsStart={true}
                  endDate={Enddate}
                  maxDate={addDays(new Date(), -1)}
                  onChange={(e: Date) => {
                    setStartDate(e)
                  }}
                />
              </FilterOptionWrapper>
              <FilterOptionWrapper>
                <Label>End Date</Label>
                <WeekDayCalender
                  excludeDates={ExcludeDate}
                  open={endCalendar}
                  onSelect={() => setEndCalender(false)}
                  onFocus={() => setEndCalender(true)}
                  onClickOutside={() => setEndCalender(false)}
                  selectsEnd={true}
                  selected={Enddate}
                  startDate={Startdate}
                  endDate={Enddate}
                  minDate={Startdate}
                  maxDate={addDays(new Date(), -1)}
                  onChange={(e: Date) => {
                    setEndDate(e)
                  }}
                />
              </FilterOptionWrapper>
            </StyledSelectWrapper>
            <StyledSelectWrapper>
              <FilterOptionWrapper>
                <Label>Age Range</Label>
                <SelectDropdown
                  defaultValue={{
                    value: '',
                    label: 'Select',
                  }}
                  borderRadius={'4px'}
                  height="32px"
                  options={ageOptions}
                  onChange={(e: any) => {
                    setAgeRange(e)
                  }}
                ></SelectDropdown>
              </FilterOptionWrapper>
              <FilterOptionWrapper>
                <Label>Buy/Sell</Label>
                <SelectDropdown
                  defaultValue={{
                    value: '',
                    label: 'Select',
                  }}
                  borderRadius={'4px'}
                  height="32px"
                  options={BSoption}
                  onChange={(e: any) => {
                    setBuySell(e)
                  }}
                ></SelectDropdown>
              </FilterOptionWrapper>
              <FilterOptionWrapper>
                <Label>Account Number</Label>
                <Textbox
                  placeholder="Enter Account Number"
                  onChange={(e: any) => {
                    setInput({ ...input, accountNumber: e })
                  }}
                  style={{
                    borderRadius: '4px',
                    height: '32px',
                    padding: '4px 8px',
                  }}
                />
              </FilterOptionWrapper>
            </StyledSelectWrapper>
            <StyledSelectWrapper>
              <FilterOptionWrapper>
                <Label>Branch</Label>
                <Textbox
                  onChange={(e: any) => {
                    setInput({ ...input, branch: e })
                  }}
                  placeholder="Enter Branch"
                  style={{
                    borderRadius: '4px',
                    height: '32px',
                    padding: '4px 8px',
                  }}
                />
              </FilterOptionWrapper>
              <FilterOptionWrapper>
                <Label>CUSIP/Symbol</Label>
                <Textbox
                  placeholder="Enter CUSIP/Symbol"
                  onChange={(e: any) => {
                    setInput({ ...input, cusip: e })
                  }}
                  style={{
                    borderRadius: '4px',
                    height: '32px',
                    padding: '4px 8px',
                  }}
                />
              </FilterOptionWrapper>
              <FilterOptionWrapper>
                <Label>Entity</Label>
                <Textbox
                  placeholder="Entity"
                  onChange={(e: any) => {
                    setInput({ ...input, entity: e })
                  }}
                  style={{
                    borderRadius: '4px',
                    height: '32px',
                    padding: '4px 8px',
                  }}
                />
              </FilterOptionWrapper>
            </StyledSelectWrapper>

            {checkAccCusip && (
              <StyledSelectWrapper>
                <Label color={COLORS.Background.Warning}>
                  *Please enter Account Number or Cusip
                </Label>
              </StyledSelectWrapper>
            )}

            <StyledSelectWrapper>
              <RadioButtonWrapper>
                <RadioCommentWrapper>
                  <input
                    type="radio"
                    value={0}
                    name="AllFailComments"
                    checked={includeFailComments == 0}
                    onChange={onChangeValue}
                  />{' '}
                  <span>All FailComments</span>
                </RadioCommentWrapper>
                <RadioCommentWrapper>
                  <input
                    type="radio"
                    value={1}
                    name="includeFailComments"
                    checked={includeFailComments == 1}
                    onChange={onChangeValue}
                  />{' '}
                  <span>Include Comments</span>
                </RadioCommentWrapper>
                <RadioCommentWrapper>
                  <input
                    type="radio"
                    value={2}
                    name="includeFailComments"
                    checked={includeFailComments == 2}
                    onChange={onChangeValue}
                  />{' '}
                  <span>Exclude Comments</span>
                </RadioCommentWrapper>
                <RadioCommentWrapper>
                  <Checkbox
                    title={'Money Only'}
                    checked={checkedValue == 1}
                    onChange={checkCheckbox}
                  />
                </RadioCommentWrapper>
              </RadioButtonWrapper>
              <MarginLeftAutoSpace>
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={() => {
                    handleSearch()
                  }}
                >
                  Search
                </Button>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
          </FilterCard>
        </CardContainer>

        {buffering && loadingBuffer && (
          <>
            <WDCard>
              <CardHeader label={'P3 Fail Analysis'} icon={false} />
            </WDCard>
            <StyledTableContainer>
              <LodingTable>
                <LottieWrapper>
                  <Lottie animationData={Loader} loop={true} />
                </LottieWrapper>
                <LoadingTextSpace>
                  <Label> Loading...</Label>
                </LoadingTextSpace>
              </LodingTable>
            </StyledTableContainer>
          </>
        )}

        {popUpLoader && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LoadingTextSpace>
              <Label> Loading...</Label>
            </LoadingTextSpace>{' '}
          </PopUpStyleLoader>
        )}

        {!resetTable && (action === 'search' || action === 'sort') && (
          <>
            <WDCard>
              <CardHeader label={'P3 Fail Analysis'} icon={false} />
            </WDCard>
            <StyledTableContainer>
              {openComments && (
                <PopUpStyleContent>
                  <StyledSelectWrapper
                    style={{
                      padding: '15px 15px 0px 15px',
                      fontWeight: '600',
                      borderRadius: '8px 8px 0px 0px',
                    }}
                  >
                    <>View Comment</>
                    <MarginLeftAutoSpace>
                      <CancelButtonWrapper
                        onClick={() => {
                          setOpenComments(false)
                        }}
                      >
                        <SvgCancel
                          fillColor={COLORS.Icons.NeutralIcon}
                        ></SvgCancel>
                      </CancelButtonWrapper>
                    </MarginLeftAutoSpace>
                    <Divider
                      thickness={'1px'}
                      horizontal={true}
                      bgColor={Backgrounds.Gray110}
                    />
                  </StyledSelectWrapper>
                  <CommentTableContainer>
                    <P3Table
                      data={Usercomments ? Usercomments : []}
                      hidePagination={hidePagination}
                      columns={[
                        {
                          Header: 'CreatedBy',
                          accessor: 'createdBy',
                        },
                        {
                          Header: 'CreatedOn(EST)',
                          accessor: 'createdOn',
                        },
                        {
                          Header: 'Comment',
                          accessor: 'comment',
                        },
                      ]}
                    />
                    {Usercomments.length == 0 && <h3>No Comments</h3>}
                  </CommentTableContainer>
                </PopUpStyleContent>
              )}
              <P3Table
                columns={[
                  {
                    Header: 'Age',
                    accessor: 'age',
                  },
                  {
                    Header: 'SnapShot Date',
                    accessor: 'snapShotDate',
                  },
                  {
                    Header: 'All Comments',
                    Cell: (table: any) => {
                      return (
                        <Link
                          style={{
                            color: COLORS.Background.Primarytext,
                            cursor: 'pointer',
                          }}
                          onClick={() => savedComments(table.cell.row.id)}
                          to={''}
                        >
                          View comments
                        </Link>
                      )
                    },
                  },
                  {
                    Header: 'Account',
                    accessor: 'accT_NBR',
                  },
                  {
                    Header: 'B/S',
                    accessor: 'buY_SELL',
                  },
                  {
                    Header: 'CUSIP',
                    accessor: 'cusiP_NUMBER',
                  },
                  {
                    Header: 'Sec Nbr',
                    accessor: 'securityID',
                  },
                  {
                    Header: 'Symbol',
                    accessor: 'symbol',
                  },

                  {
                    Header: 'Qnty',
                    accessor: 'currenT_QUANTITY',
                  },
                  {
                    Header: 'Orig Qnty',
                    accessor: 'originaL_QUANTITY',
                  },
                  {
                    Header: 'Trade Date',
                    accessor: 'tradE_DATE',
                  },
                  {
                    Header: 'Settle Date',
                    accessor: 'settlE_DATE',
                  },
                  {
                    Header: 'Entry Date',
                    accessor: 'entrY_DATE',
                  },
                  {
                    Header: 'Price',
                    accessor: 'price',
                  },
                  {
                    Header: 'Net Amount',
                    accessor: 'neT_AMOUNT',
                  },
                  {
                    Header: 'DTC Number',
                    accessor: 'dtC_NBR',
                  },
                  {
                    Header: 'Market Value',
                    accessor: 'market_Value',
                  },
                  {
                    Header: 'DTC Eligibility',
                    accessor: 'dtC_ELIG',
                  },
                  {
                    Header: 'Counter PartyType',
                    accessor: 'counterpartyType',
                  },
                  {
                    Header: 'Account Name',
                    accessor: 'acctName',
                  },
                  {
                    Header: 'Market',
                    accessor: 'market',
                  },
                  {
                    Header: 'TranNbr',
                    accessor: 'tran_Nbr',
                  },
                ]}
                type={type.value}
                startDate={Startdate ? formatISO(Startdate).split('T')[0] : ''}
                endDate={Enddate ? formatISO(Enddate).split('T')[0] : ''}
                age={ageRange}
                accNum={input.accountNumber}
                branch={input.branch}
                entity={input.entity}
                BS={buySell}
                cusip={input.cusip}
                data={data.p3Report ? data.p3Report : []}
                totalRecords={data.vSum_TotalFails}
                includeFailComments={includeFailComments}
                checkedValue={checkedValue}
              />
            </StyledTableContainer>
          </>
        )}

        {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
          <ToastWrapper>
            <Toast
              text={SuccessMessage || ErrorMessage}
              type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
              openStatusCallback={() => {
                dispatch(setHttpErrorMessage(''))
                dispatch(setSuccessMessage(''))
              }}
            />
          </ToastWrapper>
        )}
      </RootContainer>
    </>
  )
}

export default P3FailHistory
