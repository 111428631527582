import { ISvg } from './ISvg'

export const SvgAccountsSmall = ({ fillColor = '#475569' }: ISvg) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6668 4.66667H12.0002V4C12.0002 3.46957 11.7894 2.96086 11.4144 2.58579C11.0393 2.21071 10.5306 2 10.0002 2H3.3335C2.80306 2 2.29436 2.21071 1.91928 2.58579C1.54421 2.96086 1.3335 3.46957 1.3335 4V12C1.3335 12.5304 1.54421 13.0391 1.91928 13.4142C2.29436 13.7893 2.80306 14 3.3335 14H12.6668C13.1973 14 13.706 13.7893 14.081 13.4142C14.4561 13.0391 14.6668 12.5304 14.6668 12V6.66667C14.6668 6.13623 14.4561 5.62753 14.081 5.25245C13.706 4.87738 13.1973 4.66667 12.6668 4.66667ZM3.3335 3.33333H10.0002C10.177 3.33333 10.3465 3.40357 10.4716 3.5286C10.5966 3.65362 10.6668 3.82319 10.6668 4V4.66667H3.3335C3.15669 4.66667 2.98712 4.59643 2.86209 4.4714C2.73707 4.34638 2.66683 4.17681 2.66683 4C2.66683 3.82319 2.73707 3.65362 2.86209 3.5286C2.98712 3.40357 3.15669 3.33333 3.3335 3.33333ZM13.3335 10H12.6668C12.49 10 12.3204 9.92976 12.1954 9.80474C12.0704 9.67971 12.0002 9.51014 12.0002 9.33333C12.0002 9.15652 12.0704 8.98695 12.1954 8.86193C12.3204 8.73691 12.49 8.66667 12.6668 8.66667H13.3335V10ZM13.3335 7.33333H12.6668C12.1364 7.33333 11.6277 7.54405 11.2526 7.91912C10.8775 8.29419 10.6668 8.8029 10.6668 9.33333C10.6668 9.86377 10.8775 10.3725 11.2526 10.7475C11.6277 11.1226 12.1364 11.3333 12.6668 11.3333H13.3335V12C13.3335 12.1768 13.2633 12.3464 13.1382 12.4714C13.0132 12.5964 12.8436 12.6667 12.6668 12.6667H3.3335C3.15669 12.6667 2.98712 12.5964 2.86209 12.4714C2.73707 12.3464 2.66683 12.1768 2.66683 12V5.88667C2.88101 5.96201 3.10645 6.00034 3.3335 6H12.6668C12.8436 6 13.0132 6.07024 13.1382 6.19526C13.2633 6.32029 13.3335 6.48986 13.3335 6.66667V7.33333Z"
        fill={fillColor}
      />
    </svg>
  )
}

export const SvgAccountsMedium = ({ fillColor = '#475569' }: ISvg) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7H18V6C18 5.20435 17.6839 4.44129 17.1213 3.87868C16.5587 3.31607 15.7956 3 15 3H5C4.20435 3 3.44129 3.31607 2.87868 3.87868C2.31607 4.44129 2 5.20435 2 6V18C2 18.7956 2.31607 19.5587 2.87868 20.1213C3.44129 20.6839 4.20435 21 5 21H19C19.7956 21 20.5587 20.6839 21.1213 20.1213C21.6839 19.5587 22 18.7956 22 18V10C22 9.20435 21.6839 8.44129 21.1213 7.87868C20.5587 7.31607 19.7956 7 19 7ZM5 5H15C15.2652 5 15.5196 5.10536 15.7071 5.29289C15.8946 5.48043 16 5.73478 16 6V7H5C4.73478 7 4.48043 6.89464 4.29289 6.70711C4.10536 6.51957 4 6.26522 4 6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5ZM20 15H19C18.7348 15 18.4804 14.8946 18.2929 14.7071C18.1054 14.5196 18 14.2652 18 14C18 13.7348 18.1054 13.4804 18.2929 13.2929C18.4804 13.1054 18.7348 13 19 13H20V15ZM20 11H19C18.2044 11 17.4413 11.3161 16.8787 11.8787C16.3161 12.4413 16 13.2044 16 14C16 14.7956 16.3161 15.5587 16.8787 16.1213C17.4413 16.6839 18.2044 17 19 17H20V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H5C4.73478 19 4.48043 18.8946 4.29289 18.7071C4.10536 18.5196 4 18.2652 4 18V8.83C4.32127 8.94302 4.65943 9.00051 5 9H19C19.2652 9 19.5196 9.10536 19.7071 9.29289C19.8946 9.48043 20 9.73478 20 10V11Z"
        fill={fillColor}
      />
    </svg>
  )
}

export const SvgAccountsBig = ({ fillColor = '#475569' }: ISvg) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7H18V6C18 5.20435 17.6839 4.44129 17.1213 3.87868C16.5587 3.31607 15.7956 3 15 3H5C4.20435 3 3.44129 3.31607 2.87868 3.87868C2.31607 4.44129 2 5.20435 2 6V18C2 18.7956 2.31607 19.5587 2.87868 20.1213C3.44129 20.6839 4.20435 21 5 21H19C19.7956 21 20.5587 20.6839 21.1213 20.1213C21.6839 19.5587 22 18.7956 22 18V10C22 9.20435 21.6839 8.44129 21.1213 7.87868C20.5587 7.31607 19.7956 7 19 7ZM5 5H15C15.2652 5 15.5196 5.10536 15.7071 5.29289C15.8946 5.48043 16 5.73478 16 6V7H5C4.73478 7 4.48043 6.89464 4.29289 6.70711C4.10536 6.51957 4 6.26522 4 6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5ZM20 15H19C18.7348 15 18.4804 14.8946 18.2929 14.7071C18.1054 14.5196 18 14.2652 18 14C18 13.7348 18.1054 13.4804 18.2929 13.2929C18.4804 13.1054 18.7348 13 19 13H20V15ZM20 11H19C18.2044 11 17.4413 11.3161 16.8787 11.8787C16.3161 12.4413 16 13.2044 16 14C16 14.7956 16.3161 15.5587 16.8787 16.1213C17.4413 16.6839 18.2044 17 19 17H20V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19H5C4.73478 19 4.48043 18.8946 4.29289 18.7071C4.10536 18.5196 4 18.2652 4 18V8.83C4.32127 8.94302 4.65943 9.00051 5 9H19C19.2652 9 19.5196 9.10536 19.7071 9.29289C19.8946 9.48043 20 9.73478 20 10V11Z"
        fill={fillColor}
      />
    </svg>
  )
}
