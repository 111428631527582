/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  GoToPageWrapper,
  NextPriviousWrapper,
  PaginationContainer,
  RootContainerTable,
  SpaceMaker,
  ColoumnPaddingTop,
  ForwardButtonWrapper,
  BackwardButtonWrapper,
  ThWrapper,
  ThContentWrapper,
  ViewColumnsPopupWrapper,
  ActionsButtonWrapper,
  CancelSaveWrapper,
  HistoryTable,
  ViewColumnsContainer,
} from './styles'
import {
  setSorting,
  setSortDirection,
  sortingSelectorBeta,
  sortDirectionSelectorBeta,
  setPageNumberBeta,
  getBetaStalePriceDataHistory,
  LoadingSelector,
} from '../../../../../store/staticData/stale-pricing-beta'
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LottieWrapper, PopUpStyleLoader, SvgButtonWrapper } from '../../styles'
import Lottie from 'lottie-react'
import Label from '../../../../../components/label/Label'
import Loader from '../../../../../assets/lottiefiles/loader.json'
import { COLORS } from '../../../../../theme/Colors'
import SvgBackwardDoubleChevron from '../../../../../components/svg/SvgBackwardDoubleChevron'
import SvgForwardDoubleChevron from '../../../../../components/svg/SvgForwardDoubleChevron'
import Button from '../../../../../components/button/Button'
import { WDTextStrong } from '../../../../../components/ui/WDTypography'
import Checkbox from '../../../../../components/checkbox/Checkbox'
import SvgCancel from '../../../../../components/svg/logo/SvgCancel'
import Divider from '../../../../../components/divider/Divider'
import { Backgrounds } from '../../../../../shared/styles'

const CreateTable = (props: any) => {
  const dispatch = useDispatch()
  const sortingCol = useSelector(sortingSelectorBeta)
  const sortingDir = useSelector(sortDirectionSelectorBeta)
  const loading = useSelector(LoadingSelector)
  const [sortDirect, setSortDirect] = useState<any>('ASC')
  let [sortBy, setSortBy] = useState<any>('')
  const [sortAction, setSortAction] = useState<boolean>(false)
  const [tempEvent, setTempEvent] = useState<string>('')
  const [pageNumberCall, setPageNumberCall] = useState<any>(1)
  const [pageStatus, setPageStatus] = useState<boolean>(false)
  const [timeoutId, setTimeoutId] = useState()
  const [showColumnPopup, setShowColumnPopup] = useState<boolean>(false)
  const TopColumn: any = []
  ;(TopColumn[0] = 'Beta Databases'),
    (TopColumn[15] = 'DTC Data'),
    (TopColumn[16] = 'WED14509A10 Report Data'),
    (TopColumn[23] = 'Current WED14509ABCD10 Report')
  const DummyHide = [
    'box_Location',
    'box_Description',
    'country',
    'securityTypeCode',
    'expirationDate',
    'inDefault',
    'corporateAction1',
    'corporateAction2',
    'surf_User_Pricing_Manual',
    'surf_LastUpdated',
    'surf_LastPrice',
    'userIntial',
    'changedDate',
    'marketPrice',
    'changeTerminal',
    'dtC_Premed',
    'lastUpdateDate',
    'lastPrice',
    's_D',
    'cash',
    'margin',
    'ira',
    'upX_Z',
    'currently_Still_In_StaleReport',
  ]
  const [hide, setHide] = useState<any>([
    'box_Location',
    'box_Description',
    'country',
    'securityTypeCode',
    'expirationDate',
    'inDefault',
    'corporateAction1',
    'corporateAction2',
    'surf_User_Pricing_Manual',
    'surf_LastUpdated',
    'surf_LastPrice',
    'userIntial',
    'changedDate',
    'marketPrice',
    'changeTerminal',
    'dtC_Premed',
    'lastUpdateDate',
    'lastPrice',
    's_D',
    'cash',
    'margin',
    'ira',
    'upX_Z',
    'currently_Still_In_StaleReport',
  ])

  const INITIAL_SELECTED_ROW_IDS = {}
  let data = props.data
  const columns = props.columns
  const totalRecords = props.totalRecords
  const fromDate = props.fromDate
  const toDate = props.toDate

  let controlledPageCount: number
  controlledPageCount = Math.ceil(totalRecords / 20)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        pageIndex: 1,
        pageSize: 20,
        selectedRowIds: INITIAL_SELECTED_ROW_IDS, // Do not just use {}
        hiddenColumns: hide,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect
  )

  const sorting = async (e: any) => {
    setTempEvent(e)

    sortBy = e.replace(/_Formatted/g, '')
    setSortBy([...sortBy])
    dispatch(setSorting(sortBy))

    if (sortDirect == 'ASC') {
      setSortDirect('DESC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(true)
    }

    if (sortDirect == 'DESC') {
      setSortDirect('ASC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(false)
    }

    await dispatch(
      getBetaStalePriceDataHistory(
        'sort',
        'Beta',
        fromDate,
        toDate,
        pageNumberCall - 1,
        20,
        sortBy,
        sortDirect,
        0
      )
    )
  }

  const pageCallFunction = async (pageIndex: any, rowRange: any) => {
    dispatch(setPageNumberBeta(pageIndex - 1))
    await dispatch(
      getBetaStalePriceDataHistory(
        'search',
        'Beta',
        fromDate,
        toDate,
        pageIndex - 1,
        rowRange,
        sortingCol,
        sortingDir,
        0
      )
    )
  }

  useEffect(() => {
    if (pageStatus) {
      pageCallFunction(pageNumberCall, 20)
    }
  }, [pageNumberCall])

  const onChangeFunction = (pageValue: number) => {
    if (pageValue > 0 && pageValue <= pageCount) {
      const pageNumber = pageValue ? pageValue : 1
      setPageStatus(true)
      clearTimeout(timeoutId)
      const timeout: any = setTimeout(() => {
        gotoPage(pageNumber)
        setPageNumberCall(pageNumber)
      }, 3000)
      setTimeoutId(timeout)
    }
  }
  const renderColors = (flag: number) => {
    switch (flag) {
      case 1:
        return '#ffe6cc'
      case 2:
        return '#e6ffe6'
      case 3:
        return '#f2f2f2'
      default:
        return ''
    }
  }

  const getTrProps = (rowInfo: any) => {
    return {
      style: {
        background: rowInfo?.original?.followup_Flag
          ? renderColors(Number(rowInfo?.original?.followup_Flag))
          : '',
        border: 'none',
      },
    }
    return {}
  }

  return (
    <>
      <HistoryTable>
        <PaginationContainer>
          <Label>
            Page{' '}
            <strong>
              {pageNumberCall} of {pageOptions.length}
            </strong>{' '}
          </Label>
          <span
            style={{
              marginLeft: '20px',
              color: `${COLORS.Icons.NeutralIconInverted}`,
            }}
          >
            |
          </span>
          <GoToPageWrapper>
            <Label>Go to page:</Label>
            <input
              type="number"
              min="1"
              max={pageCount}
              defaultValue={pageNumberCall}
              onChange={(e) => {
                onChangeFunction(Number(e.target.value))
              }}
              style={{ width: '50px' }}
            />
          </GoToPageWrapper>
          <span
            style={{
              marginLeft: '20px',
              color: `${COLORS.Icons.NeutralIconInverted}`,
            }}
          >
            |
          </span>
          <NextPriviousWrapper>
            <BackwardButtonWrapper
              onClick={() => {
                setPageStatus(true)
                gotoPage(1)
                setPageNumberCall(1)
              }}
            >
              {pageNumberCall !== 1 ? (
                <SvgBackwardDoubleChevron
                  fillColor={`${COLORS.Icons.NeutralIcon}`}
                />
              ) : (
                <SvgBackwardDoubleChevron
                  fillColor={`${COLORS.Background.Neutral40}`}
                />
              )}
            </BackwardButtonWrapper>
            <button
              onClick={() => {
                setPageStatus(true)
                previousPage()
                setPageNumberCall(pageNumberCall - 1)
              }}
              disabled={pageNumberCall === 1}
              style={{
                border: 'none',
                background: `${COLORS.Icons.NeutralIconInverted}`,
                cursor: 'pointer',
              }}
            >
              Previous
            </button>
            <button
              onClick={() => {
                setPageStatus(true)
                nextPage()
                setPageNumberCall(pageNumberCall + 1)
              }}
              disabled={pageNumberCall === pageCount}
              style={{
                border: 'none',
                background: `${COLORS.Icons.NeutralIconInverted}`,
                cursor: 'pointer',
              }}
            >
              Next
            </button>
            <ForwardButtonWrapper
              onClick={() => {
                setPageStatus(true)
                gotoPage(pageCount)
                setPageNumberCall(pageCount)
              }}
            >
              {pageNumberCall !== pageCount ? (
                <SvgForwardDoubleChevron
                  fillColor={`${COLORS.Icons.NeutralIcon}`}
                />
              ) : (
                <SvgForwardDoubleChevron
                  fillColor={`${COLORS.Background.Neutral40}`}
                />
              )}
            </ForwardButtonWrapper>
          </NextPriviousWrapper>
        </PaginationContainer>
        {/* <ViewColumnsContainer> */}
        <div style={{ marginLeft: 'auto' }}>
          <ViewColumnsContainer>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                setShowColumnPopup(!showColumnPopup)
                !showColumnPopup == false &&
                  pageCallFunction(pageNumberCall, 20)
              }}
            >
              View Columns
            </Button>
            {showColumnPopup && (
              <ViewColumnsPopupWrapper>
                <div style={{ height: '90%', overflow: 'auto' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <WDTextStrong>Choose columns</WDTextStrong>
                    <SvgButtonWrapper
                      style={{ marginLeft: 'auto' }}
                      onClick={() => {
                        setShowColumnPopup(false)
                        pageCallFunction(pageNumberCall, 20)
                      }}
                    >
                      <SvgCancel
                        fillColor={COLORS.Icons.NeutralIcon}
                      ></SvgCancel>
                    </SvgButtonWrapper>
                  </div>
                  {DummyHide?.map((item: any, index: number) => {
                    return (
                      <>
                        {
                          <Label padding="2px" fontWeight={600}>
                            {TopColumn[index]}
                          </Label>
                        }
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '4px',
                          }}
                          key={index}
                        >
                          <Checkbox
                            defaultChecked={!hide.includes(item)}
                            onChange={(e: any) => {
                              const index = hide.indexOf(item)
                              {
                                e.target.checked
                                  ? index > -1 && hide.splice(index, 1)
                                  : setHide([...hide, item])
                              }
                            }}
                          ></Checkbox>{' '}
                          {item.toUpperCase().replace(/_/g, ' ')}
                        </div>
                        <Divider
                          thickness={'1px'}
                          horizontal={true}
                          bgColor={Backgrounds.Gray110}
                        />
                      </>
                    )
                  })}
                </div>
                <ActionsButtonWrapper>
                  <Button
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    type={'button'}
                    color={COLORS.Background.Primarytext}
                    style={{ whiteSpace: 'nowrap' }}
                    bgColor={'transparent'}
                    onClick={() => {
                      setHide(DummyHide)
                      pageCallFunction(pageNumberCall, 20)
                      setShowColumnPopup(!showColumnPopup)
                    }}
                  >
                    Default
                  </Button>
                  <CancelSaveWrapper>
                    <Button
                      hoverBgColor={COLORS.Background.Primary70}
                      bgColor={COLORS.Background.Primarytext}
                      color={COLORS.Background.Primary}
                      padding={'4px 16px'}
                      borderRadius={'4px'}
                      hover={true}
                      type={''}
                      activeBgColor={COLORS.Background.Primaryactive}
                      borderColor={'transparent'}
                      onClick={() => {
                        pageCallFunction(pageNumberCall, 20)
                        setShowColumnPopup(!showColumnPopup)
                      }}
                    >
                      Save
                    </Button>
                  </CancelSaveWrapper>
                </ActionsButtonWrapper>
              </ViewColumnsPopupWrapper>
            )}
          </ViewColumnsContainer>
        </div>
      </HistoryTable>
      {/* </ViewColumnsContainer> */}

      <table {...getTableProps()}>
        {loading && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <SpaceMaker>
              <Label> Loading...</Label>
            </SpaceMaker>{' '}
          </PopUpStyleLoader>
        )}
        <thead>
          {headerGroups.map((headerGroup, rowIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={rowIndex}>
              {headerGroup.headers.map((column: any, index) => (
                <th
                  {...column.getHeaderProps({
                    style: { width: column.width },
                  })}
                  onClick={(e) => {
                    //trigger the react-table header onClick manually
                    column
                      .getHeaderProps(column.getSortByToggleProps())
                      .onClick(e)

                    sorting(column.id)
                  }}
                  key={index}
                >
                  <ThWrapper>
                    <ThContentWrapper>
                      {column.render('Header')}
                    </ThContentWrapper>
                    <ColoumnPaddingTop>
                      {column.id == tempEvent
                        ? sortAction
                          ? '🔼'
                          : '🔽 '
                        : ''}
                    </ColoumnPaddingTop>

                    {headerGroup.headers.length - 1 !== index && <div></div>}
                  </ThWrapper>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} {...getTrProps(row)} key={index}>
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data.length == 0 && (
        <>
          <Label fontWeight={700} margin="5% 0 0 50%">
            No Records Found
          </Label>
          <Label fontWeight={700} margin="0.5% 0 0 49%">
            No Exceptions Identified
          </Label>
        </>
      )}

      <PaginationContainer>
        <Label>
          Page{' '}
          <strong>
            {pageNumberCall} of {pageOptions.length}
          </strong>{' '}
        </Label>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <GoToPageWrapper>
          <Label>Go to page:</Label>
          <input
            type="number"
            min="1"
            max={pageCount}
            defaultValue={pageNumberCall}
            onChange={(e) => {
              onChangeFunction(Number(e.target.value))
            }}
            style={{ width: '50px' }}
          />
        </GoToPageWrapper>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <NextPriviousWrapper>
          <BackwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(1)
              setPageNumberCall(1)
            }}
          >
            {pageNumberCall !== 1 ? (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </BackwardButtonWrapper>
          <button
            onClick={() => {
              setPageStatus(true)
              previousPage()
              setPageNumberCall(pageNumberCall - 1)
            }}
            disabled={pageNumberCall === 1}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              setPageStatus(true)
              nextPage()
              setPageNumberCall(pageNumberCall + 1)
            }}
            disabled={pageNumberCall === pageCount}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Next
          </button>
          <ForwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(pageCount)
              setPageNumberCall(pageCount)
            }}
          >
            {pageNumberCall !== pageCount ? (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </ForwardButtonWrapper>
        </NextPriviousWrapper>
      </PaginationContainer>
    </>
  )
}

const BetaStalePricingTableHistory = (props: any) => {
  return (
    <RootContainerTable>
      <CreateTable
        fromDate={props.fromDate}
        toDate={props.toDate}
        data={props.data}
        columns={props.columns}
        totalRecords={props.totalRecords}
      />
    </RootContainerTable>
  )
}

export default BetaStalePricingTableHistory
