import { LabelProps, StyledLabel } from './styles'
import { FC } from 'react'

const Label: FC<LabelProps> = ({
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  color,
  children,
  onClick,
  cursor,
  fontFamily,
  margin,
  padding,
  minWidth,
  hover,
  title
}: LabelProps) => (
  <StyledLabel
  title={title}
    fontSize={fontSize}
    fontStyle={fontStyle}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    color={color}
    onClick={onClick}
    cursor={cursor}
    fontFamily={fontFamily}
    margin={margin}
    padding={padding}
    minWidth={minWidth}
    hover={hover}
  >
    {children}
  </StyledLabel>
)

export default Label
