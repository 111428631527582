import { ISvg } from './ISvg'

export const SvgVector = ({ fillColor }: ISvg) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2600.000000 2600.000000"
            width="30px" height="30px"
            preserveAspectRatio="xMidYMid meet">
           
            <g transform="translate(0.000000,2600.000000) scale(0.100000,-0.100000)"
                fill={fillColor} stroke="none">
                <path d="M7850 21224 c-151 -41 -266 -164 -299 -322 -8 -39 -11 -1965 -11
       -7128 0 -6392 2 -7082 16 -7144 28 -126 111 -226 234 -284 60 -29 73 -31 170
       -31 97 0 110 2 172 32 121 57 199 151 233 278 13 53 15 407 15 3167 l0 3108
       4664 0 c3091 0 4681 3 4716 10 213 40 368 244 346 453 -3 32 -17 86 -30 120
       -14 34 -271 855 -571 1825 l-546 1764 567 1835 c401 1299 568 1854 572 1898
       14 178 -116 359 -297 415 -62 20 -111 20 -4980 19 -4294 0 -4923 -2 -4971 -15z
       m9256 -841 c-3 -10 -228 -738 -499 -1618 -272 -880 -497 -1623 -501 -1651 -3
       -27 -2 -74 4 -104 5 -29 233 -775 505 -1656 272 -882 495 -1606 495 -1609 0
       -3 -1964 -5 -4365 -5 l-4365 0 0 3330 0 3330 4366 0 c4153 0 4365 -1 4360 -17z"/>
            </g>
        </svg>
    )
}
