import {
  LottieWrapper,
  RootContainerTable,
  TableChartWidgetContainerByTopSecurity,
  TitleContainer,
  ViewMoreText
} from './styles'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles';
import Loader from "../../../../assets/lottiefiles/loader.json";
import { useEffect, useState } from 'react'
import Lottie from 'lottie-react';
import Label from '../../../label/Label';
import CreateTable from '../createTable';
import { getTopSecurity, topSecuritySelector } from '../../../../store/dash-board/static-data';
import { useDispatch ,useSelector} from 'react-redux';
import { formattingDateMonthOnly, formattingMonthYear } from '../../../../utils/globalFunctions';
import { useNavigate } from 'react-router-dom';



const TopSecurity = (props: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const data = useSelector(topSecuritySelector)
  const [startDate] = useState(new Date())
  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)

  let mm: string | number
  let firstDayOfMonth: string | number

  mm = startDate.getMonth() 
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth
  
  const columns = [
    { Header: 'Field Name', accessor: 'requestType' },
    { Header: `${data?.dateViewModel?.[0]?.previousDate}`, accessor: 'previousMonthCount' },
    { Header: `${data?.dateViewModel?.[0]?.currentDate}`, accessor: 'currentMonthCount' },
  ]
    const [loading, isLoading] = useState<boolean>(true)
  
    const Table = (props: any) => {
    
      return (
        <RootContainerTable>
          <CreateTable data={props.data} columns={props.columns} />
        </RootContainerTable>
      )
    }
  
    useEffect(() => {
      const getTopSecurities = async () => {
       await  dispatch( getTopSecurity(fromDate) )}
        isLoading(false)
      getTopSecurities();
    }, [])

    const viewMoreDetails=()=>{
      navigate('/securityUpdates', {
        state: { fromDate }
    });
    }
  
    return (
      <>
      {loading && <PopUpStyleLoader>
        <LottieWrapper>
        <Lottie animationData={Loader} loop={true} />
      </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div> 
      </PopUpStyleLoader>}
        <TableChartWidgetContainerByTopSecurity>
          <TitleContainer>{props.widgetDescription}</TitleContainer>
          <Table data={data?.p3SecurityUpdateViewModel ? data.p3SecurityUpdateViewModel : []} columns={columns} />
          <ViewMoreText onClick={viewMoreDetails} onKeyDown={viewMoreDetails} role="presentation">View More</ViewMoreText>
        </TableChartWidgetContainerByTopSecurity>
      </>
    )
  }


export default TopSecurity
