import Dashboard from '../../dashboard/dashboard'
import {
  ErrorContainer,
  ErrorHeading,
  ErrorTextContainer,
} from '../../error/errorStyles'

const StaticDataDashboard = () => {
  return <Dashboard action="StaticData" />
}
export default StaticDataDashboard
