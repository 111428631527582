export const ReeOrgFeeColumn = [
  {
    Header: 'Account Number',
    accessor: 'accountNumber',
  },
  {
    Header: 'Account Type',
    accessor: 'accountType',
  },
  {
    Header: 'EntryFull Date',
    accessor: 'entryFullDate',
  },
  {
    Header: 'Trade Date',
    accessor: 'tradeDate',
  },
  {
    Header: 'Trade Number',
    accessor: 'tradeNumber',
  },
  {
    Header: 'Deliver CUSIP',
    accessor: 'deliver_CUSIP',
  },
  {
    Header: 'Receive CUSIP',
    accessor: 'receive_CUSIP',
  },
  {
    Header: 'Deliver Qty',
    accessor: 'deliver_Qty',
  },
  {
    Header: 'Receive Qty',
    accessor: 'receive_Qty',
  },
  {
    Header: 'Trailer',
    accessor: 'trailer',
  },
  {
    Header: 'Cert Number',
    accessor: 'certNumber',
  },
  {
    Header: 'Entry Type',
    accessor: 'entryType',
  },
  {
    Header: 'Source Program',
    accessor: 'sourceProgram',
  },
  {
    Header: 'Number Of Transactions',
    accessor: 'numberOfTransactions',
  },
  {
    Header: 'Reorg Fee',
    accessor: 'reorgFee',
  },
]
