import { configureStore, Store, combineReducers } from '@reduxjs/toolkit'
import { failAnalysisReducer } from './failAnalysis'
import { valdiTrans } from './staticData/index'
import { tradeManage } from './trade-management/index'
import { tradeBreakManage } from './trade-break/index'
import { EquityBreakManage } from './Equity-break/index'
import { BuyIns } from './buyIns'
import { SuspenseAccountManage } from './suspenseAccount'
import { TradeRemediation } from './tradeRemediation'
import { FailReport } from './failReport-204'
import { JAMSJobListReport } from './tech-ops'
import { Dashboard } from './dash-board'
import { SecurityUpdates } from './securityUpdate'
import { assetService } from './asset-Servicing'
import { ThresholdConfiguration } from './threshold-Config'
import { ContraCorrespondents } from './contraCorrespondent'
import { regSHO204Tool } from './reg-SHO-Tool'
import { SettlementShortSales } from './settlementShortSales'
import { BetaSecLendingReducer } from './betaSecLending'
import { operationsControl } from './operations-Control'
import { Settlement } from './settlement'

const reducer = combineReducers({
  failAnalysis: failAnalysisReducer,
  valdi: valdiTrans,
  trade: tradeManage,
  tradeBreakManage,
  EquityBreakManage,
  BuyIns,
  SuspenseAccountManage,
  TradeRemediation,
  FailReport,
  Dashboard,
  JAMSJobListReport,
  SecurityUpdates,
  assetService,
  ThresholdConfiguration,
  ContraCorrespondents,
  regSHO204Tool,
  SettlementShortSales,
  BetaSecLendingReducer,
  operationsControl,
  Settlement
})

const store: Store = configureStore({
  reducer,
})

export type StoreState = ReturnType<typeof reducer>
export default store
