import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authApiCall } from '../../../../utils/api';
import SvgArrowUp from '../../../svg/SvgArrowUp';
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles'
import {
  ArrowContainer, DangerArrowContainer, LottieWrapper,
  InformationTile, SummaryCardContainerSuspenseAccount, TitleSubContainer, EqualContainer, RootContainerTable
} from './styles'
import { configJSONDashboard } from "../../../../utils/config";
import Loader from "../../../../assets/lottiefiles/loader.json";
import Label from '../../../label/Label';
import Lottie from 'lottie-react';
import { formattingCurrencyWithDecimal, formattingNumber } from '../../../../utils/globalFunctions';
import Image from '../../../image/Image';
import { Icons } from '../../../../shared/GlobalStyle';
import SvgRecycle from '../../../svg/SvgRecycle';
import CreateTable from '../createTable';

const SuspenseAccount = () => {
  const navigate = useNavigate();
  const [loading, isLoading] = useState<boolean>(true)
  const [datas, setDatas] = useState<any>([])
  useEffect(() => {
    const getSuspenseAccount = async () => {
      const api = configJSONDashboard.TradeBreaks.suspenseAccount.kpi
      try {
        const { data, status } = await authApiCall.get(api);
        if (status === 200) {
          setDatas(data?.suspenseAccountCount || [])
          isLoading(false)
        }

      } catch (e) {
        isLoading(false)
      }
    }
    getSuspenseAccount();
  }, [])

  const renderSymbol = (value: any) => {
    let compare = "equal"

    if (value && value.currentMarketValue && value.previousMarketValue) {
      if (Number(value.currentMarketValue.replace(/[^0-9.-]+/g, "")) > Number(value.previousMarketValue.replace(/[^0-9.-]+/g, ""))) {
        compare = "greater"
      }
      else if (Number(value.currentMarketValue.replace(/[^0-9.-]+/g, "")) < Number(value.previousMarketValue.replace(/[^0-9.-]+/g, ""))) {
        compare = "lesser"
      }
    }
    switch (compare) {
      case 'greater':
        return <Image image={Icons.ArrowNegativeUpward} alt="greater" />
      case 'lesser':
        return <Image image={Icons.ArrowPositiveDownward} alt="lesser" />
      case 'equal':
        return <SvgRecycle fillColor='#ff6600' />
    }
  }
  const handleClick = (assetType: any, data: any) => {
    const breadCrumbArray = [{
      key: "Dashboard",
      link: "/tradeReconDashboard",
      active: false
    },
    {
      key: "SuspenseAccount",
      link: '/suspenseAccount',
      active: true
    }
    ]
    sessionStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbArray));
    navigate('/suspenseAccount', {
      state: { passProps: { assetType, accountNbr: data.accountNumber } }
    });

  }


  const columns = [
    { Header: 'AccountNumber', accessor: 'accountNumber' },
    { Header: 'Equity', accessor: (d: any) => { return <span role="presentation" onClick={() => handleClick('EQ', d)} onKeyDown={() => handleClick('EQ', d)} style={{ cursor: 'pointer', color: d.equity?.currMarketValueColor }}>{d.equity?.currMarketValue}{renderSymbol(d)}</span> } },
    { Header: 'FI', accessor: (d: any) => { return <span role="presentation" onClick={() => handleClick('FI', d)} onKeyDown={() => handleClick('EQ', d)} style={{ cursor: 'pointer', color: d.fixedIncome?.currMarketValueColor }}>{d.fixedIncome?.currMarketValue}{renderSymbol(d)}</span> } },
  ]



  const Table = (props: any) => {

    return (
      <RootContainerTable minHeight={'130px'} fontSize={"11px"}>
        <CreateTable data={datas} columns={props.columns} />
      </RootContainerTable>
    )
  }





  return (
    <>
      {loading && <PopUpStyleLoader>
        <LottieWrapper>
          <Lottie animationData={Loader} loop={true} />
        </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div>

      </PopUpStyleLoader>}
      <SummaryCardContainerSuspenseAccount>
        <TitleSubContainer>Suspense Account Market Value</TitleSubContainer>
        <Table data={datas ? datas : []} columns={columns} />

        {/* <div style={{ display: 'flex', flexFlow: 'row', gap: '10px' }}>
          <InformationTile onClick={() => handleClick("EQ")}>
            <p style={{ color: "#475569", fontSize: "14px", marginBottom: "5px" }}>Equity</p>
            <span style={{ fontWeight: 600, fontSize: "16px" }}>
              {datas.equity ? datas.equity.currentMarketValue || '$0' : '$0'}

            </span>
            {renderSymbol(datas.equity)}

          </InformationTile>
          <InformationTile onClick={() => handleClick("FI")}>
            <p style={{ color: "#475569", fontSize: "14px", marginBottom: "5px" }}>Fixed Income</p>
            <span style={{ fontWeight: 600, fontSize: "16px" }}>
              {datas.fixedIncome ? datas.fixedIncome.currentMarketValue || '$0' : '$0'}
            </span>
            {renderSymbol(datas.fixedIncome)}

          </InformationTile>
        </div> */}
      </SummaryCardContainerSuspenseAccount>
    </>

  )
}

export default SuspenseAccount
