import { useState } from 'react'
import DatePicker from '../../../components/datePicker/DatePicker'
import Dashboard from '../../dashboard/dashboard'
import { DateDashboardWrapper, DateWrapper } from './styles'
import Label from '../../../components/label/Label'
import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'

const CATCAISDashboard = () => {
  const [date, setDate] = useState(new Date().toISOString().split('T')[0])
  const [SelectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0])
  const OnclickSubmit = () => {
    setSelectedDate(date)
  }
  return (
    <DateDashboardWrapper>
      <DateWrapper>
        <Label margin="5px">SelectDate: </Label>
        <DatePicker
          padding={'4px 8px'}
          onChange={(e: string) => {
            setDate(e)
          }}
          value={date}
          max={new Date().toISOString().split('T')[0]}
          bgColor=""
          borderColor=""
          placeHolder="From"
          maxWidth="295px"
          defaultValue={new Date().toISOString().split('T')[0]}
        />
           <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin='0px 5px'
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={OnclickSubmit}
        >
          Submit
        </Button>
      </DateWrapper>
      <Dashboard action={'CATCAIS'} selectedDate={SelectedDate} />
    </DateDashboardWrapper>
  )
}
export default CATCAISDashboard
