import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  regShoHistoryData: {}
  totalCount: number
  isLoading: boolean
  action: string
  httpErrorMessage: string
  toastMessage: string
}

const  initialState: State = {
  regShoHistoryData: {},
  totalCount: 0,
  isLoading: false,
  action: '',
  httpErrorMessage: '',
  toastMessage: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'reg-sho-history',
  initialState,
  reducers: {
    SetRegShoHistoryData: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        regShoHistoryData: action.payload.data.data,
      }
    },

    SetIsLoading: (state: State, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      }
    },
    SetAction: (state: State, action: PayloadAction<string>) => {
      return {
        ...state,
        action: action.payload,
      }
    },
    setTotalCount: (state: State, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    sethttpErrorMessageSummary: (
      state: State,
      action: PayloadAction<string>
    ) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.toastMessage = action.payload
    },
  },
})

export const {
  SetAction,
  SetRegShoHistoryData,
  SetIsLoading,
  setTotalCount,
  sethttpErrorMessageSummary,
  setSuccessMessage
} = actions

export const getRegShoHistory: any =
  (system: string, payload: any) => async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoHistoricalViewReport`,
        { ...payload, pageNumber: 0, pageSize: 0,sortBy: "CUSIP_Number",
        sortDirection: "ASC" }
      )

      if (status === 200) {
        dispatch(
          SetRegShoHistoryData({
            data: data,
          })
        )
        dispatch(
          setTotalCount({
            data: data.totalCount,
          })
        )
        dispatch(SetIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(
        SetRegShoHistoryData({
          data: {},
        })
      )
      dispatch(SetIsLoading(false))
    }
  }
  export const getRegShoCommentsHistory: any =
  (source: string, Cusip: number,settleDate:string) => async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetRegShoListComments?cusip=${Cusip}&settleDate=${settleDate}&Source=${source}&CurrHist=H`
      )

      if (status === 200) {
        dispatch(SetIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(SetIsLoading(false))
      //
    }
  }

  export const getL2AllEmailList: any = 
  (regShoId: any,allocationId:any,screenName:string) => async (dispatch: Dispatch) => {
   dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/FailAnalysisReport/GetEmailAttachmentList`,
        {
            regShoId,
            allocationId,
            screenName ,
            CurrHist:"H"
        
        }
      )
      if (status === 200) {
        dispatch(SetIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return data
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(SetIsLoading(false))
      //
    }
  }
  export const getRegShoHistoryExcel: any =
  (
    payload: any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/FailAnalysisReport/RegShoHistoricalReportExcelExport',
        { ...payload, pageNumber: 0, pageSize: 0,sortBy: "CUSIP_Number",
        sortDirection: "ASC" },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `RegshoHistory${payload.pageName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(SetIsLoading(false))
        dispatch(sethttpErrorMessageSummary(''))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, sethttpErrorMessageSummary)
      dispatch(SetIsLoading(false))
      return null
    }
  }
export const RegShoHistoryDataSelector = (store: StoreState) =>
  store.Settlement.regShoHistory.regShoHistoryData
export const actionSelector = (store: StoreState) =>
  store.Settlement.regShoHistory.action
export const loadingSelector = (store: StoreState) =>
  store.Settlement.regShoHistory.isLoading
export const TotalCountSelector = (store: StoreState) =>
  store.Settlement.regShoHistory.totalCount
  export const HttpErrorMessageSelector = (store: StoreState) =>
    store.Settlement.regShoHistory.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
    store.Settlement.regShoHistory.toastMessage
export default reducer
