import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

export const StyledTabs = styled(Tabs)`
  width: 100%;
`

export const StyledTabList = styled(TabList)`
  list-style: none;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: 16px;
  color: #333;
  background-color: transparent;
  transition: border-bottom-color 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
`

export const StyledTab = styled(Tab)`
  padding: 10px 25px;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #a2a4b8;
  background-color: lightgrey;
  border-radius: 7px 7px 0px 0px;
  margin: 0px 1px;

  &.react-tabs__tab--selected {
    color: #ffffff;
    background-color: #1d4ed8;
    border-color: #ccc;
    border-bottom: none;
    font-weight: bold;
  }
`

export const StyledTabBefore = styled.div`
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 50%;
  transform: translateY(-50%);
  &::before {
    content: none;
  }
`

export const StyledTabAfter = styled.div`
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 50%;
  transform: translateY(-50%);
`
export const StyledTabPanel = styled(TabPanel)``
