import React from 'react'
import TradeReconcilation from '../../components/widgets/about/tradeReconciliation'
import Settlements from '../../components/widgets/about/settlement'
import StaticData from '../../components/widgets/about/staticData'
import AssetServicing from '../../components/widgets/about/assetServicing'
import OperationsControl from '../../components/widgets/about/operationsControl'
import RegulatoryOperations from '../../components/widgets/about/regulatoryOperations'

const WidgetCreator = (props: any) => {
  const widgetName: string = props.content?.replace(/ /g, '')

  const Components: any = {
    TradeReconciliation: TradeReconcilation,
    Settlement: Settlements,
    StaticData: StaticData,
    AssetServicing: AssetServicing,
    OperationsControl: OperationsControl,
    RegulatoryOperations: RegulatoryOperations,
  }
  return React.createElement(Components[widgetName], props)
}

export default WidgetCreator
