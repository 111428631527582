import styled from 'styled-components'
import { COLORS } from '../../../../theme/Colors'

export const RootContainer = styled.div`
  margin-top: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  // background: ${COLORS.Background.Secondary};
//  background: #e5e5e5;
`
export const FilterCard = styled.div`
  width: 57%;
  height: 364px;
  margin-right: 1.5%;
  border: 1px solid ${COLORS.Background.StrongBackgound};
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const FailSummaryCard = styled.div`
  width: 41%;
  height: 364px;
  background: ${COLORS.Background.Primary};
  border: 1px solid ${COLORS.Background.StrongBackgound};

  /* neutral/background */

 // background: #ffffff;
  /* neutral/border-weak */

  border: 1px solid #e2e8f0;
  /* Elevation/Elevation-low */

  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`

export const SubHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  padding: 8px 8px 8px 24px;
  color: ${COLORS.Text.Neutral};
`
export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${COLORS.Background.Primary};

  > * {
    margin-right: 16px;
  }
`
export const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`
export const CancelButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`
export const SelectWrapper = styled.div`
  width: 100%;
  padding: 12px 0px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  //place-content: space-evenly;
 // background: #e5e5e5;
`
export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 28px 0 28px 0;
`
export const PopUpStyleLoader = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
  z-index: 900;
`
export const FilterOptionWrapper = styled.div`
  width: 30%;
`


export const StyledTableContainer = styled.div`
  width: 100%;
  padding: 24px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
`
export const StyledTableFailSummary = styled.div`
  margin-bottom: 10px;
  width: 100%;
  padding: 24px;
`

export const PopupCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 16px;
  gap: 8px;
  position: absolute;
  width: 397px;
  height: 172px;
  background: ${COLORS.Background.Primary};
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
export const PageNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  > * {
    margin-right: 16px;
  }
`
export const LottieWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const LodingTable = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 28px 0 28px 0;
`
export const PopUpStyleContent = styled.div`
  background: rgba(100, 116, 139, 0.75);
  position: fixed;
  height: 40%;
  width: 60%;
  top: 30%;
  left: 20%;
  display: flex;
  flex-direction: column;
  padding: 10px 15px 10px 15px;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
`
export const CommentTableContainer = styled.div`
  padding: 24px;
  background: ${COLORS.Background.Primary};
  border-top: none;
  overflow-y: scroll;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px;
  background: #ffffff;
  overflow-y:scroll;
  /* background-color: pink; */
  table {
    width: 100% !important;
    //overflow-y:scroll;
    /* background-color: green; */

    th {
      font-size: 12px;
      // white-space: nowrap;
      font-style: normal;
      font-weight: 700;
      cursor: pointer;
      line-height: 16px;
      color: black;
      text-align: left;
      border-radius: 1px;
      padding: 4px 4px;
      border-right: 1px solid #d0d7e2;
      border-bottom: 1px solid #d0d7e2;
      &:active {
        box-shadow: inset 0 3px 0 0 rgb(0 0 0 / 60%);
      }

      /* &:nth-child(1) {
        text-align: left;
      } */

      // :last-child {
      //   text-align: right;
      // }
    }

    tr {
      td {
        padding: 4px;
        color: black;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        box-shadow: inset 0px -1px 0px #d0d7e2;

        // :last-child {
        //   /* background: #22c373; */
        //   text-align: left;
        //   border-radius: 4px;
        //   padding: 4px 10px;
        //   font-style: normal;
        //   font-weight: 700;
        //   font-size: 14px;
        //   line-height: 16px;
        //   text-align: right;

        //   /* color: #ffffff; */
        // }
      }
    }
  }
`
export const RadioCommentWrapper = styled.div``

export const MarginLeftAutoSpace = styled.div`
  margin-left: auto;
`
export const LoadingTextSpace = styled.div`
  margin-left: 10px;
`
