import { useState } from 'react'
2

export interface BuyInReceivesProducts {
  id?:number
  source: string
  secType: string
  description: string
  assetType: string
  dTCCSecType: string
  buyInActiveYN: boolean
  sdBusinessDays: number | undefined
}

export const getInputTypeList = (products: BuyInReceivesProducts) => {
  return [
    {
      label: 'Source',
      attribute: 'source',
      element: 'select',
      inputValue: products.source,
      options: ['Beta', 'P3'],
    },
    {
      label: 'Sec Type',
      type: 'text',
      inputValue: products.secType,
      attribute: 'secType',
      element: 'input',
    },
    {
      label: 'Description',
      type: 'text',
      inputValue: products.description,
      attribute: 'description',
      element: 'input',
    },
    {
      label: 'Asset Type',
      type: 'text',
      inputValue: products.assetType,
      attribute: 'assetType',
      element: 'input',
    },
    {
      label: 'DTCC Sec Type',
      type: 'email',
      inputValue: products.dTCCSecType,
      attribute: 'dTCCSecType',
      element: 'input',
    },
    {
      label: 'SD Business Days',
      type: 'text',
      inputValue: products.sdBusinessDays,
      attribute: 'sdBusinessDays',
      element: 'input',
    },
    {
      label: 'Is BuyIn Active',
      type: 'checkbox',
      inputValue: products.buyInActiveYN,
      attribute: 'buyInActiveYN',
      element: 'input',
      checked: products.buyInActiveYN,
    },
  ]
}