/* eslint-disable jsx-a11y/anchor-is-valid */

import { ColumnInstance, Row } from 'react-table'
import { SummaryTableCell } from './CommonComponentsCusipL1'
import {
  BuyInExecutionButton,
  BuyInExecutionInput,
} from '../BuyInAllocationAndExecution/buyInCommonComponents'
import { RegSHOTabDetails } from '../RegSHOModels'

interface CusipL1ColumnInstance<D extends object = {}>
  extends ColumnInstance<D> {
  allocationType?: string
}
interface ICell<D extends object = {}> {
  column: CusipL1ColumnInstance
  row: Row<D>
}
export const getCusipL1Columns = (
  setAllocationType: React.Dispatch<any> | undefined,
  setActiveTabIndex: React.Dispatch<any> | undefined
) => {
  return [
    {
      Header: ' BuyIn',
      allocationType: 'buyInAmount',
      accessor: 'buyInAmount',
      Cell: (row: {
        row: { original: { totalBuyInQty: string | number } }
      }) => {
        return (
          <a
            style={{ color: 'white' }}
            href="#"
            onClick={() => {
              setActiveTabIndex &&
                setActiveTabIndex(RegSHOTabDetails[3].tabIndex)
            }}
          >
            {row?.row?.original.totalBuyInQty ?? 0}
          </a>
        )
      },
    },
    {
      Header: 'Correspondent Allocation',
      allocationType: 'Correspondent Allocation',
      accessor: 'correspondentAllocation',
      Cell: ({ row, column }: ICell) => {
        return SummaryTableCell(
          setAllocationType,
          setActiveTabIndex,
          column?.allocationType,
          row.values[column.id]
        )
      },
    },
    {
      Header: 'Fail Allocation (non-customer)',
      allocationType: 'Broker Allocation',
      accessor: 'failAllocation',
      Cell: ({ row, column }: ICell) => {
        return SummaryTableCell(
          setAllocationType,
          setActiveTabIndex,
          column?.allocationType,
          row.values[column.id]
        )
      },
    },
    {
      Header: 'Customer Allocation (wealth management allocation)',
      allocationType: 'Customer Allocation',
      accessor: 'customerAllocation',
      Cell: ({ row, column }: ICell) => {
        return SummaryTableCell(
          setAllocationType,
          setActiveTabIndex,
          column?.allocationType,
          row.values[column.id]
        )
      },
    },
    {
      Header: 'Stock Loan Allocation',
      allocationType: 'Stock Loan Allocation',
      accessor: 'stockLoanAllocation',
      Cell: ({ row, column }: ICell) => {
        return SummaryTableCell(
          setAllocationType,
          setActiveTabIndex,
          column?.allocationType,
          row.values[column.id]
        )
      },
    },
    {
      Header: 'Others',
      allocationType: 'Others Allocation',
      accessor: 'otherAllocation',
      Cell: ({ row, column }: ICell) => {
        return SummaryTableCell(
          setAllocationType,
          setActiveTabIndex,
          column?.allocationType,
          row.values[column.id]
        )
      },
    },
    {
      Header: 'Total Amount',
      accessor: 'totalAllocations',
      Cell: ({ row }: ICell) => {
        const {
          correspondentAllocation,
          failAllocation,
          customerAllocation,
          stockLoanAllocation,
          otherAllocation,
        } = row.values
        const totalAllocation =
          parseInt(correspondentAllocation.replace(/,/g, ''), 10) +
          parseInt(failAllocation.replace(/,/g, ''), 10) +
          parseInt(customerAllocation.replace(/,/g, ''), 10) +
          parseInt(stockLoanAllocation.replace(/,/g, ''), 10) +
          parseInt(otherAllocation.replace(/,/g, ''), 10)

        return (
          <div>
            {totalAllocation.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        )
      },
    },
  ]
}

export const EditColumn = (
  executionUserInputsRef: React.MutableRefObject<any>,
  setExecutionUserInputs: React.Dispatch<any>,
  handleBuyInSubmit: (id: number) => void,
  handleDelete: (id: number) => void,
  handleViewAuditTrail: (id: number) => void,
  DisabledFunction: (
    source: string,
    createdBy: string,
    createDate: string
  ) => boolean,
  pastActionDateDisableFlag: boolean
) => {
  return [
    {
      Header: 'Allocation Type',
      accessor: 'buyInType',
    },
    {
      Header: 'Buy-In Quantity',
      objectKey: 'buyInAmount',
      Cell: ({ row, column }: any) => {
        return (
          <BuyInExecutionInput
            disableFunction={
              pastActionDateDisableFlag ||
              DisabledFunction(
                executionUserInputsRef?.current[row.index]?.source,
                '',
                executionUserInputsRef?.current[row.index]?.nextBuyInDate
              )
            }
            type={'text'}
            parentValue={executionUserInputsRef}
            objectKey={column.objectKey}
            rowIndex={row.index}
            setParentValue={setExecutionUserInputs}
          />
        )
      },
    },
    {
      Header: 'Buy-In Execution Date',
      objectKey: 'nextBuyInDate',
      Cell: ({ row, column }: any) => {
        return (
          <BuyInExecutionInput
            disableFunction={
              pastActionDateDisableFlag ||
              DisabledFunction(
                executionUserInputsRef?.current[row.index]?.source,
                '',
                executionUserInputsRef?.current[row.index]?.nextBuyInDate
              )
            }
            type={'date'}
            parentValue={executionUserInputsRef}
            objectKey={column.objectKey}
            rowIndex={row.index}
            setParentValue={setExecutionUserInputs}
          />
        )
      },
    },
    {
      Header: 'Update',
      objectKey: 'update',
      Cell: ({ row }: ICell) => {
        return (
          <BuyInExecutionButton
            disableFunction={
              pastActionDateDisableFlag ||
              DisabledFunction(
                executionUserInputsRef?.current[row.index]?.source,
                '',
                executionUserInputsRef?.current[row.index]?.nextBuyInDate
              )
            }
            parentValue={executionUserInputsRef}
            label={'Update'}
            tableId={row.index}
            UpdateBuyInExeCution={handleBuyInSubmit}
          />
        )
      },
    },
    {
      Header: 'Delete',
      objectKey: 'delete',
      Cell: ({ row }: ICell) => {
        return (
          <BuyInExecutionButton
            disableFunction={
              pastActionDateDisableFlag ||
              DisabledFunction(
                executionUserInputsRef?.current[row.index]?.source,
                '',
                executionUserInputsRef?.current[row.index]?.nextBuyInDate
              )
            }
            parentValue={executionUserInputsRef}
            label={'Delete'}
            tableId={row.index}
            UpdateBuyInExeCution={handleDelete}
          />
        )
      },
    },
    {
      Header: 'Audit Trail',
      objectKey: 'auditTrail',
      Cell: ({ row }: ICell) => {
        return (
          <BuyInExecutionButton
            disableFunction={false}
            parentValue={executionUserInputsRef}
            label={'Audit Trail'}
            tableId={row.index}
            UpdateBuyInExeCution={handleViewAuditTrail}
          />
        )
      },
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
    },
    {
      Header: 'Created On(EST)',
      accessor: 'createdOn',
    },
  ]
}
