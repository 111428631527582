/* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */

import { useTable, useSortBy, usePagination } from 'react-table'
import {
  RootContainerTable,
  StyledTableHeader,
  PaginationContainer,
  GoToPageWrapper,
  NextPriviousWrapper,
  StyledNoData,
  TableHeader,
  TableContainer,
  StyledCheckBox,
  IconsWrapper,
  AddFieldWrapper,
  CancelModal,
  CancelSendButtonWrapper,
  AddFieldHeaderContainer,
  AddFieldTable,
  MarginLeft,
  AlignCenter,
  DropDownWrapper,
  TextWrapper,
} from './tableStyles'
import Label from '../../../../components/label/Label'
import Checkbox from '../../../../components/checkbox/Checkbox'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { Icon } from '../../../../components/icon/Icon'
import { Icons } from '../../../../shared/GlobalStyle'
import { useMemo, useState } from 'react'
import { SvgPlus } from '../../../../components/svg/SvgPlus'
import { Colors } from '../../../../shared/styles'
import { Textbox } from '../../../../components/textbox/Textbox'
import Button from '../../../../components/button/Button'
import Table from '../../../../components/table/Table'
import {
  WDStyledButtonText,
  WDStyledMainHeading,
} from '../../../../components/ui/WDTypography'
import { COLORS } from '../../../../theme/Colors'
import SelectDropdown from '../../../../components/selectDropdown/SelectDropdown'
import { StyledImage } from '../../../../components/image/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  AddP3RequestTypeMaster,
  getSecurityUpdate,
  loadingSelector,
  requestTypeSelector,
} from '../../../../store/securityUpdate/securityUpdate'
import SvgApprove from '../../../../components/svg/logo/SvgApprove'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import SvgRemove from '../../../../components/svg/logo/SvgRemove'
import { LottieWrapper, PopUpStyleLoader } from '../styles'
import Lottie from 'lottie-react'
import { ValidateNewField } from './AddFiledValidation'
import { z } from 'zod'
import { renderError } from '../../../../utils/globalFunctions'

const CreateTable = (props: any) => {
  const data = useMemo(() => props.data, [props.data])
  const columns = useMemo(() => props.columns, [props.columns])

  const isTradeDetails = props.showCancel
  const tableInstance = useTable(
    { columns, data, autoResetSortBy: false, autoResetPage: false },
    useSortBy,
    usePagination
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
  } = tableInstance

  const actionType = [
    'Action',
    'Comments/Activity',
    'Select',
    'Category',
    'View Details',
  ]

  const { pageIndex } = state
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page && page.length ? (
            page.map((row, key) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={key}>
                  {row &&
                    row.cells.map((cell) => {
                      const headerValue =
                        cell.column.Header?.toString() ||
                        cell.column.id?.toString() ||
                        ''
                      const isActionType = actionType.includes(headerValue)
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.value ||
                          isTradeDetails ||
                          isActionType ||
                          cell.value === 0
                            ? cell.render('Cell')
                            : '-'}
                        </td>
                      )
                    })}
                </tr>
              )
            })
          ) : (
            <tr>
              <td
                colSpan={
                  columns[0] && columns[0].columns
                    ? columns[0].columns.length
                    : columns?.length
                }
              >
                <StyledNoData>No Data Found</StyledNoData>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {page && page.length ? (
        <PaginationContainer>
          <Label>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </Label>
          <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
          <GoToPageWrapper>
            <Label>Go to page:</Label>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              max={pageOptions.length}
              min={1}
              onChange={(e) => {
                e.stopPropagation()
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(pageNumber)
              }}
              style={{ width: '50px' }}
            />
          </GoToPageWrapper>
          <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
          <NextPriviousWrapper>
            <Icon icon={Icons.BackwardFirst} onClick={() => gotoPage(0)}></Icon>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              style={{ border: 'none', background: '#FCFDFF' }}
            >
              Previous
            </button>

            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={{ border: 'none', background: '#FCFDFF' }}
            >
              Next
            </button>
            <Icon
              icon={Icons.ForwardLast}
              onClick={() => gotoPage(pageCount - 1)}
            ></Icon>
          </NextPriviousWrapper>
        </PaginationContainer>
      ) : null}
    </>
  )
}

const Tables = (props: any) => {
  const dispatch = useDispatch()
  const loading = useSelector(loadingSelector)
  const [AddField, SetAddField] = useState<boolean>(false)
  const [disableSave, setDisableSave] = useState<boolean>(true)
  const RequestType = useSelector(requestTypeSelector)
  const [validationError, setValidationError] = useState<z.ZodError[]>([])
  const [response, setResponse] = useState([
    {
      message: '',
      status: '',
    },
  ])

  const defaultField = {
    requestType: '',
    fieldName: '',
    activeYN: 'N',
  }
  const [Field, setField] = useState<any>([
    {
      requestType: '',
      fieldName: '',
      activeYN: 'N',
    },
  ])

  const addNew = () => {
    Field.push(defaultField)
    setValidationError([])
    setField([...Field])
  }

  const addNewFields = async () => {
    const ValidateNewFieldSchema = z.array(ValidateNewField)
    const FieldValidation = ValidateNewFieldSchema.safeParse(Field)
    if (FieldValidation?.success) {
      let i = 0
      Field.map((id: { [x: string]: number }) => {
        id['id'] = i
        i++
      })
      setField([...Field])

      const res = await dispatch(AddP3RequestTypeMaster(Field))
      await dispatch(getSecurityUpdate(props.fromDate))
      await setResponse(res.p3Request)
      setDisableSave(false)
      setValidationError([])
    } else {
      const errors: any = FieldValidation.error.errors
      setValidationError(errors)
    }
  }
  return (
    <RootContainerTable>
      <TableHeader>
        {props.heading}{' '}
        {
          <IconsWrapper onClick={() => SetAddField(true)}>
            <SvgPlus fillColor={Colors.White}></SvgPlus>
            <Label fontSize="17px" fontWeight={400} color={Colors.White}>
              Add Field
            </Label>
          </IconsWrapper>
        }
      </TableHeader>

      {props.isFilter ? (
        <StyledTableHeader>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="notAct"
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              title={`NA (Needs Attention) (${props.checkFilterCount.notAct} breaks)`}
              checked={props.checkFilter.notAct}
            />
          </StyledCheckBox>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="approve"
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              title={`Approved (${props.checkFilterCount.approve} breaks)`}
              checked={props.checkFilter.approve}
            />
          </StyledCheckBox>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="reject"
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              title={`Rejected (${props.checkFilterCount.reject} breaks)`}
              checked={props.checkFilter.reject}
            />
          </StyledCheckBox>
        </StyledTableHeader>
      ) : null}

      {props.showCancel ? (
        <StyledTableHeader>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="all"
              title={`Valid (${props.checkFilterCount.all} breaks)`}
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              checked={props.checkFilter.all}
            />
          </StyledCheckBox>
          <StyledCheckBox>
            <Checkbox
              checkBoxId="cancelled"
              buttonCheckbox={true}
              onChange={props.filterOnChange}
              title={`Cancelled (${props.checkFilterCount.cancelled} breaks)`}
              checked={props.checkFilter.cancelled}
            />
          </StyledCheckBox>
        </StyledTableHeader>
      ) : null}
      <TableContainer>
        <CreateTable
          data={props.data}
          columns={props.columns}
          hidePagination={props.hidePagination}
          showCancel={props.showCancel}
        />
      </TableContainer>

      {AddField && (
        <AddFieldWrapper>
          {loading && (
            <PopUpStyleLoader>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <div style={{ marginLeft: '10px' }}>
                <Label> Loading...</Label>
              </div>
            </PopUpStyleLoader>
          )}
          <AddFieldHeaderContainer>
            <WDStyledMainHeading>Add New Field</WDStyledMainHeading>
            <CancelSendButtonWrapper>
              <CancelModal
                onClick={() => {
                  setValidationError([])
                  SetAddField(false)
                  setDisableSave(true)
                  setResponse([])
                  setField([
                    {
                      requestType: '',
                      fieldName: '',
                      activeYN: 'N',
                    },
                  ])
                }}
              >
                <WDStyledButtonText>
                  <Label>Close</Label>
                </WDStyledButtonText>
              </CancelModal>

              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                gap={'8px'}
                disabled={!disableSave}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  addNewFields()
                }}
              >
                Save
              </Button>
            </CancelSendButtonWrapper>
          </AddFieldHeaderContainer>
          <AddFieldTable>
            <Table
              tableColumns={[
                {
                  Header: 'Request Type',
                  Cell: (table: { cell: { row: { index: number } } }) => {
                    return (
                      <DropDownWrapper>
                        <SelectDropdown
                          padding="4px 8px"
                          height="32px"
                          options={RequestType.map((value: string) => {
                            return { label: value, value: value }
                          })}
                          defaultValue={{
                            label: Field[table.cell.row.index].requestType,
                            value: Field[table.cell.row.index].requestType,
                          }}
                          onChange={(e) => {
                            Field[table.cell.row.index].requestType = e
                            setField(Field)
                          }}
                        />

                        <span style={{ color: 'red', display: 'inline-block' }}>
                          {renderError(
                            'requestType',
                            validationError,
                            table.cell.row.index
                          )}
                        </span>
                      </DropDownWrapper>
                    )
                  },
                },
                {
                  Header: 'Field Name',
                  Cell: (table: { cell: { row: { index: number } } }) => {
                    return (
                      <TextWrapper>
                        <Textbox
                          style={{
                            borderRadius: '4px',
                            height: '32px',
                            margin: '6px',
                            padding: '4px 8px',
                          }}
                          defaultValue={Field[table.cell.row.index].fieldName}
                          onChange={(e) => {
                            Field[table.cell.row.index].fieldName = e
                            setField(Field)
                          }}
                        />

                        <span style={{ color: 'red', display: 'inline-block' }}>
                          {renderError(
                            'fieldName',
                            validationError,
                            table.cell.row.index
                          )}
                        </span>
                      </TextWrapper>
                    )
                  },
                },
                {
                  Header: 'Active',
                  Cell: (table: { cell: { row: { index: number } } }) => {
                    return (
                      <AlignCenter>
                        <Checkbox
                          defaultChecked={
                            Field[table.cell.row.index].activeYN === 'Y'
                          }
                          onChange={(e: any) => {
                            Field[table.cell.row.index].activeYN = e.target
                              .checked
                              ? 'Y'
                              : 'N'
                            setField(Field)
                          }}
                        />
                      </AlignCenter>
                    )
                  },
                },
                {
                  Header: 'Action',
                  Cell: (table: { cell: { row: { index: number } } }) => {
                    return (
                      <AlignCenter>
                        <StyledImage
                          style={{ cursor: 'pointer' }}
                          title="delete"
                          src={Icons.Delete}
                          onClick={() => {
                            disableSave && Field.splice(table.cell.row.index, 1)
                            setField([...Field])
                          }}
                        ></StyledImage>
                      </AlignCenter>
                    )
                  },
                },
                {
                  Header: 'Status',
                  Cell: (table: { cell: { row: { index: number } } }) => {
                    return (
                      <AlignCenter>
                        {response[table.cell.row.index]?.status == 'S' ? (
                          <>
                            {' '}
                            <SvgApprove fillColor={'green'} />{' '}
                            {response[table.cell.row.index].message}
                          </>
                        ) : response[table.cell.row.index]?.status == 'F' ? (
                          <>
                            <SvgCancel fillColor={'red'} />{' '}
                            {response[table.cell.row.index].message}
                          </>
                        ) : (
                          <SvgRemove fillColor={'Black'} />
                        )}
                      </AlignCenter>
                    )
                  },
                },
              ]}
              tableData={Field}
              onClick={() => {
                //
              }}
            />
            <MarginLeft>
              <CancelModal
                onClick={() => {
                  disableSave && addNew()
                }}
              >
                <WDStyledButtonText>
                  <Label>+Add NewField</Label>
                </WDStyledButtonText>
              </CancelModal>
            </MarginLeft>
          </AddFieldTable>
        </AddFieldWrapper>
      )}
    </RootContainerTable>
  )
}

export default Tables
