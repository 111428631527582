import { ISvg } from './ISvg'

const SvgForwardDoubleChevron = ({ fillColor }: ISvg) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.63203 0.205555C1.50735 0.0764827 1.33685 0.00256604 1.15804 6.55549e-05C0.979238 -0.00243493 0.806769 0.0666859 0.678578 0.192222C0.550387 0.317757 0.476974 0.489425 0.474491 0.66946C0.472008 0.849495 0.540656 1.02315 0.665335 1.15222L2.21469 2.67889L0.665335 4.20555C0.603276 4.26753 0.554018 4.34126 0.520404 4.4225C0.486789 4.50374 0.469482 4.59088 0.469482 4.67889C0.469482 4.7669 0.486789 4.85403 0.520404 4.93527C0.554018 5.01651 0.603276 5.09025 0.665335 5.15222C0.726888 5.21471 0.800118 5.2643 0.880803 5.29815C0.961488 5.33199 1.04803 5.34942 1.13544 5.34942C1.22285 5.34942 1.30939 5.33199 1.39007 5.29815C1.47076 5.2643 1.54399 5.21471 1.60554 5.15222L3.59189 3.15222C3.65395 3.09025 3.70321 3.01651 3.73682 2.93527C3.77044 2.85403 3.78774 2.7669 3.78774 2.67889C3.78774 2.59088 3.77044 2.50374 3.73682 2.4225C3.70321 2.34126 3.65395 2.26753 3.59189 2.20555L1.63203 0.205555ZM7.26001 2.20555L5.27366 0.205555C5.14899 0.0800189 4.97989 0.00949358 4.80356 0.00949358C4.62724 0.00949358 4.45814 0.0800189 4.33346 0.205555C4.20878 0.33109 4.13874 0.501354 4.13874 0.678888C4.13874 0.856422 4.20878 1.02669 4.33346 1.15222L5.85633 2.67889L4.33346 4.20555C4.2714 4.26753 4.22214 4.34126 4.18853 4.4225C4.15491 4.50374 4.13761 4.59088 4.13761 4.67889C4.13761 4.7669 4.15491 4.85403 4.18853 4.93527C4.22214 5.01651 4.2714 5.09025 4.33346 5.15222C4.39501 5.21471 4.46824 5.2643 4.54893 5.29815C4.62961 5.33199 4.71616 5.34942 4.80356 5.34942C4.89097 5.34942 4.97751 5.33199 5.0582 5.29815C5.13888 5.2643 5.21211 5.21471 5.27366 5.15222L7.26001 3.15222C7.32384 3.09202 7.37519 3.01969 7.41109 2.93941C7.447 2.85912 7.46676 2.77246 7.46922 2.68445C7.47168 2.59643 7.4568 2.50879 7.42544 2.42659C7.39408 2.3444 7.34686 2.26927 7.2865 2.20555H7.26001Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default SvgForwardDoubleChevron
