import {
  ButtonWrapper,
  DownloadWrapper,
  ExcelWrapper,
  StyledContainer,
  StyledRightContainer,
  StyledSelectWrapper,
} from './RegShoHistoryAuditStyles'
import {
  ProppedDatePicker,
  ProppedLabel,
  ProppedTextbox,
} from '../commonComponents'
import Button from '../../../components/button/Button'
import Select from 'react-select'
import {
  getRegShoHistoryAudit,
  getRegShoHistoryExcel,
} from '../../../store/settlement/regSho-AuditTrail'
import { useDispatch, useSelector } from 'react-redux'
import { IRegShoHistoryAuditSearchFilters } from './RegShoHistoryAuditPage'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import Label from '../../../components/label/Label'
import { COLORS } from '../../../theme/Colors'

interface RegShoHistorySearchPanelProps {
  searchParams: IRegShoHistoryAuditSearchFilters
  setsearchParams: React.Dispatch<
    React.SetStateAction<IRegShoHistoryAuditSearchFilters>
  >
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>
}

const RegShoHistoryAuditSearchPanel = ({
  searchParams,
  setsearchParams,
  setShowTable,
}: RegShoHistorySearchPanelProps) => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  const isoStringDate = date.toISOString()
  const splitDate = isoStringDate.split('T')[0]
  const pageNameOption = [
    { value: 'All', label: 'All', columnValue: 'regShoL1buyInsId' },
    {
      value: 'CusipLevelSummary',
      label: 'CusipLevelSummary',
      columnValue: 'regShoL1buyInsId',
    },
    { value: 'Allocations', label: 'Allocations', columnValue: 'allocationId' },
    {
      value: 'BuyInNotify',
      label: 'BuyInNotify',
      columnValue: 'regSHOBuyInID',
    },
    {
      value: 'BuyInExecDetails',
      label: 'BuyInExecDetails',
      columnValue: 'regShobuyInExecId',
    },
  ]

  const allocationOption = [
    { value: 'All', label: 'All' },
    { value: 'Stock Loan', label: 'Stock Loan' },
    { value: 'Correspondent', label: 'Correspondent' },
    { value: 'Customer', label: 'Customer' },
    { value: 'Broker', label: 'Broker' },
    { value: 'Others', label: 'Others' },
  ]

  const dispatch = useDispatch()
  const handleSearch = () => {
    const { columnId: _, ...newObj } = searchParams
    setShowTable(true)
    const fetchData = async () => {
      await dispatch(
        getRegShoHistoryAudit(newObj.source, {
          ...newObj,
          cusip: newObj.cusip.length === 0 ? null : newObj.cusip,
          symbol: newObj.symbol.length === 0 ? null : newObj.symbol,
          source: newObj.source.length === 0 ? null : newObj.source,
          pageName: newObj.pageName === '' ? null : newObj.pageName,
          allocationType: newObj.allocationType,
        })
      )
    }

    fetchData()
  }
  const ExcelDownload = async () => {
    await dispatch(
      getRegShoHistoryExcel({
        ...searchParams,
        cusip: null,
        symbol: null,
        source: searchParams.source.length === 0 ? null : searchParams.source,
        pageName: searchParams.pageName === '' ? null : searchParams.pageName,
        allocationType: searchParams.allocationType,
      })
    )
  }
  return (
    <StyledContainer>
      <StyledSelectWrapper>
        <div>
          <ProppedLabel>Source</ProppedLabel>
          <SelectDropdown
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                source: v,
              }))
            }}
            defaultValue={{ value: 'Beta', label: 'Beta' }}
            options={[
              { value: 'Beta', label: 'Beta' },
              { value: 'P3', label: 'P3' },
            ]}
          />
        </div>
        <div style={{ width: '15%' }}>
          <ProppedLabel>PageName</ProppedLabel>
          <Select
            onChange={(v: any) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                pageName: v.value,
                columnId: v.columnValue,
              }))
              setShowTable(false)
            }}
            defaultValue={{
              value: 'All',
              label: 'All',
            }}
            options={pageNameOption}
          />
        </div>
        <div style={{ width: '12%' }}>
          <ProppedLabel>AllocationType</ProppedLabel>
          <SelectDropdown
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                allocationType: v,
              }))
              setShowTable(false)
            }}
            defaultValue={{ value: 'All', label: 'All' }}
            options={allocationOption}
          />
        </div>
        <div>
          <ProppedLabel>From Date</ProppedLabel>
          <ProppedDatePicker
            max={splitDate}
            value={searchParams.fromDate}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                fromDate: v,
              }))
            }}
          />
        </div>
        <div>
          <ProppedLabel>To Date</ProppedLabel>
          <ProppedDatePicker
            max={splitDate}
            value={searchParams.toDate}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                toDate: v,
              }))
            }}
          />
        </div>
        <div>
          <ProppedLabel>CUSIP</ProppedLabel>
          <ProppedTextbox
            value={searchParams.cusip}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                cusip: v,
              }))
            }}
            placeHolderText={'Cusip'}
          />
        </div>
        <div>
          <ProppedLabel>Symbol</ProppedLabel>
          <ProppedTextbox
            value={searchParams.symbol}
            onChange={(v: string) => {
              setsearchParams((previousValue) => ({
                ...previousValue,
                symbol: v,
              }))
            }}
            placeHolderText={'Symbol'}
          />
        </div>
        <ButtonWrapper>
          <div>
            <StyledRightContainer>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                margin={'0px 6px'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
            </StyledRightContainer>
          </div>
        </ButtonWrapper>
        {/* <ExcelWrapper 
        onClick={()=>{
          ExcelDownload()
        }}  
        >
        <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label>Excel</Label>
          </WDStyledButtonText>
          </ExcelWrapper> */}
      </StyledSelectWrapper>
    </StyledContainer>
  )
}

export default RegShoHistoryAuditSearchPanel
