import styled from 'styled-components'

export const PanelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`

export const PanelWrapper = styled.div`
  display: flex;
  width: 400px;
  width:${(props:any)=>props.panelWidth};
  flex-direction: column;
  margin: 12px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`

export const IconWrapper = styled.div`
  margin: 6px;
`

export const BodyWrapper = styled.div`
  flex: 10;
  overflow: auto;
  margin: 12px 6px;
`

export const MessageWrapper = styled.div`
  margin: 12px 6px;
`
export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 12px 6px;
  height: 40px;
`
