import styled from 'styled-components'

export const RootContainerParent = styled.div`
  flex: 1;
  width: 0px;
  display: flex;
  height:100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  color: #181e27;
`

export const RootContainer = styled.div`
  margin: -8px 0px 8px 0px;
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  // background: #E5E5E5;
  /* background-color: orange; */
`
export const StyledPanelSelect = styled.div`
  width: 100%;
  
  padding: 20px 30px 20px 30px;
  border-radius: 0px 0px 8px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // background: #fcfdff;
`

export const StyledPanelHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  /* background: #fcfdff; */
  /* color: #192638; */
  // color: #ffffff;
  // background: #1f5eb7;

  /* padding: 32px 32px 0px 32px; */
  padding: 16px 24px 16px;
  border-radius: 8px 8px 0px 0px;
`

export const StyledPanelHeadTitle = styled.div`
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 48px;
text-align: left;
color: #0F172A;
`

export const StyledPanelHeadTotal = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-align: right;
`

export const StyledPanelMonthSelect = styled.div`
  width: 100%;
  padding: 24px 32px 24px 32px;
  border-radius: 0px 0px 8px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #fcfdff;
`

export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 24px 24px 26px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  > * {
    margin-right: 16px;
  }
`

export const StyledButtonWrapper = styled.div`
display: flex;
flex-direction: row;
margin-top: 28px;
justify-content: space-between;
margin-left: auto;
`

export const StyledTableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  padding: 24px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`
export const CardContainer = styled.div`
  flex: 1 1 auto;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 8px 16px;
  margin: 8px;
  background: #fafbfc;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  background-color: #fafbfc;
  margin-left: 0px;
  margin-bottom: 16px;

  p {
    color: 374253;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`
export const PopUpStyleContent = styled.div`
  background: #fcfdff;
  position: fixed;
  top: 50%;
  left: 55%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const PopUpStyleLoader = styled.div`
  background: #f0f2f5;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`

export const PopUpSideBar = styled.div`
background: #FFFF;
position: fixed;
height: 100%;
width: 30%;
top: 50%;
left: 85%;
-webkit-transform: translate(-50%,-50%);
padding: 10px 15px 10px 15px;
box-shadow: 0px 0px 150rem 150rem rgb(0 36 93 / 30%);
border: 1px solid #E2E8F0;
flex-flow: column;
display: flex;
`
export const TradeSplitLabel = styled.div`
  width:17%;
  margin-right:0px;
  .p{
    font-weight: 400;
    font-size: 14px;
  }
`

export const StyledBreakTableContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding-top: 24px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`

export const StyledFilterPanel = styled.div`

background: #FFFFFF;
/* Elevation/Elevation-low */
width: 100%;
box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
border-radius: 8px;
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`

export const ModalApproveWrapper = styled.div`
  background: #f0f2f5;
  position: fixed;
  height: 120px;
  width: 400px;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: right;
  flex-direction: column;
  box-shadow: 10px 8px 16px 8px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const ModalRootComments = styled.div`
  background: white;
  position: fixed;
  height: 400px;
  width: 800px;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  z-index: 99999;
  border: 2px solid #f0f2f5;
  `
export const StyledInfoPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #FFFFFF;
  /* Elevation/Elevation-low */
  width: 100%;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  padding: 24px 0px 28px 0px;
`
export const PopupCard = styled.div`
 
  padding: 10px;
  gap: 8px;

  position: absolute;
  width: 50%;
  height: 50%;
  max-width: 600px;
  max-height: 200px;

  /* neutral/background */

  background: #ffffff;
  /* Elevation/Elevation-low */

  box-shadow: 0px 0px 150rem 150rem rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  z-index: 99999;
`
export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  margin-left: 25px;
  place-content: end;
`
export const LottieWrapper = styled.div`
  // position: fixed;
  // top: 70%;
  // left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`
export const InfoTile = styled.div`
  margin-top: 23px;
  background: #F8FAFC;
`
export const TableTopBarRoot = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 4px;
  justify-content: flex-end;
  align-content: center;
  width: 100%;
`
