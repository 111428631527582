import React from 'react'
import { CellButton, EmailButtonWrapper } from './styles'
import { SvgDownloadSmall } from '../../../../components/svg/logo/SvgDownload'
import Button from '../../../../components/button/Button'
import { getEmailDownload } from '../../../../store/reg-SHO-Tool/reg-sho-buyInAllocation'
import { Colors } from '../../../../shared/styles'

const TableCell: React.FC<{
  row: any
  setMailPOPUP: any
  setRowId: any
  setMailList: any
  disableComponent?: boolean
}> = ({ row, setMailPOPUP, setRowId, setMailList, disableComponent }) => {
  return row?.cells?.map((cell: any, i: number) => {
    switch (cell.column.Cell?.toString()) {
      case 'emailAttachment':
        return (
          <td key={i}>
            <EmailButtonWrapper>
              <CellButton
                disabled={disableComponent ? disableComponent : false}
                onClick={() => {
                  setMailPOPUP(true)
                  setRowId(row.id)
                }}
              >
                Upload Email
              </CellButton>
              <Button
                padding="2px"
                margin="0px 6px"
                bgColor={'transparent'}
                onClick={() => {
                  setRowId(row.id)
                  setMailList(true)
                }}
                type={''}
                color={''}
              >
                <SvgDownloadSmall
                  fillColor={Colors.Primary80}
                ></SvgDownloadSmall>
              </Button>
            </EmailButtonWrapper>
          </td>
        )

      default:
        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
    }
  })
}

export default TableCell
