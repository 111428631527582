import { useEffect, useState } from 'react'
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  PopUpStyleLoader,
  StyledSelectWrapper,
  LottieWrapper,
  ToastWrapper,
} from './OutStandingStyle'
import OutStandingSearchPanel from './OutStandingSearchPanel'
import OutStandingDetails from './OutStandingDetails'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { Toast } from '../../../components/toast/Toast'
import {
  OutStandingSelector,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  loadingSelector,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../store/settlement/out-standing'

export interface IOutStandingSearchFilters {
  fromDate: string
  toDate: string
  cusip: string | null
  symbol: string | null
  source: string | null
}

const date = new Date()
date.setDate(date.getDate() - 1)
const isoStringDate = date.toISOString()
const splitDate = isoStringDate.split('T')[0]

const OutStandingPage = () => {
  const InitialFilterParams: IOutStandingSearchFilters = {
    fromDate: splitDate,
    toDate: splitDate,
    cusip: '',
    symbol: '',
    source: null,
  }

  const [searchParams, setSearchParams] = useState({ ...InitialFilterParams })
  const [searchFlag, setSearchFlag] = useState({ ...InitialFilterParams })
  const OutStandingData = useSelector(OutStandingSelector)
  const Loading = useSelector(loadingSelector)

  const dispatch = useDispatch()
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)

  return (
    <RootContainer>
      {Loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>Buyin Execution Report</StyledPanelHeadTitle>
      </StyledPanelHead>
      <OutStandingSearchPanel
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        setSearchFlag={setSearchFlag}
      />
      <OutStandingDetails
        totalCount={
          OutStandingData?.totalCount ? OutStandingData?.totalCount : 0
        }
        data={OutStandingData?.data?.length ? OutStandingData.data : []}
      />
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default OutStandingPage
