import { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import Button from "../../components/button/Button";
import Label from "../../components/label/Label";
import SelectDropdown from "../../components/selectDropdown/SelectDropdown";
import { StyledButtonWrapper, StyledFilterPanel, StyledPanelSelect, StyledSelectWrapper } from "./styles";
import { FilterDetailSelector, WidgetDetailSelector } from "../../store/threshold-Config/thresholdCongfiguration";
import { ConfigDetails } from './IconfigDetails';

interface InputFunc {
    (value: string): void
}

interface FilterInterfaceProps {
    onWidgetChange: InputFunc,
    input: ConfigDetails,
    getDetails: (widgetIds: any) => void,
    filterDetails: any
}

interface WidgetInterfaceProps {
    widgetDescription: string,
    widgetId: number

}
const ConfigFilters = (props: FilterInterfaceProps) => {
    const [filterIds, setFilterIds] = useState<any>({})
    const [buttonDisable, setButtonDisable] = useState<boolean>(true)
    const widgetData = useSelector(WidgetDetailSelector)

    const { filterDetails } = props

    const onChangeFilter = (value: string, param: any) => {
        const label = param?.Value?.find((element: any) => value === element.Id)
        const wigetSelected = { ...filterIds, [param.Label]: { 'value': value, 'label': label.AttributeValue } }
        setFilterIds(wigetSelected)
       
        if (filterDetails.length && (Object.keys(wigetSelected).length === filterDetails.length)) {
            setButtonDisable(false)
        }
    }

    const handleWidget = async (options: string) => {
        setButtonDisable(true)
        const obj = filterIds
        for (const key in obj) {
            delete obj[key];
        }
        await setFilterIds(obj)
        await props.onWidgetChange(options)
    }

    return (
        <StyledPanelSelect>
            <StyledFilterPanel>
                <StyledSelectWrapper>
                    {widgetData?.widgetThreshold?.length ? <div>
                        <Label fontSize="16px" fontWeight={400} color={'#0F172A'} lineHeight={'24px'}>
                            Widget
                        </Label>
                        <SelectDropdown
                            height="32px"
                            buttonWidth="170px"
                            padding="4px 16px"
                            borderRadius="4px"
                            fw={100}
                            lh="24px"
                            fs="16px"
                            options={widgetData?.widgetThreshold?.map((element: WidgetInterfaceProps) => { return { value: element.widgetId, label: element.widgetDescription } })}
                            onChange={(options) => {
                                handleWidget(options)
                            }}
                            defaultValue={{
                                value: "",
                                label: "--Select--",
                            }}
                        />{" "}
                    </div> : null}
                    {props.input.widgetId &&
                        filterDetails && filterDetails.length ? filterDetails.map((ele: any, key: number) => {
                            return <div key={key}>
                                <Label fontSize="16px" fontWeight={400} color={'#0F172A'} lineHeight={'24px'}>
                                    {ele.Label}
                                </Label>
                                <SelectDropdown
                                    height="32px"
                                    buttonWidth="150px"
                                    padding="4px 16px"
                                    borderRadius="4px"
                                    fw={100}
                                    lh="24px"
                                    fs="16px"
                                    value={filterIds[ele.Label] ? filterIds[ele.Label] : { value: '', label: "--Select--" }}
                                    options={ele?.Value?.map((element: any) => { return { value: element?.Id, label: element?.AttributeValue } })}
                                    onChange={(options) => {
                                        onChangeFilter(options, ele)
                                    }}
                                    defaultValue={{
                                        value: "",
                                        label: "--Select--",
                                    }}
                                /></div>
                        }) : null
                    }
                    <StyledButtonWrapper>
                        <Button
                            hoverBgColor={'#FFFFFF'}
                            hoverTextColor={'#2563EB'}
                            borderRadius={"4px"}
                            disabled={buttonDisable}
                            hover={true}
                            padding={"4px 16px"}
                            bgColor={"#2563EB"}
                            color={"White"}
                            type={"Button"}
                            onClick={() => {
                                props.getDetails(filterIds);
                            }}
                            borderColor={"#2563EB"}
                        >
                            Configure
                        </Button>
                    </StyledButtonWrapper>
                </StyledSelectWrapper>
            </StyledFilterPanel>
        </StyledPanelSelect >
    )
}
export default ConfigFilters;