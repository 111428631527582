import {
  SummaryCardContainerTradeBreaksTrend,
  BetaPhase3,
  TitleContainer,
  LottieWrapper
} from './styles'
import { useEffect, useState } from 'react'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles';
import Loader from "../../../../assets/lottiefiles/loader.json";
import Lottie from 'lottie-react';
import { formattingDate, formattingMonthYear, formattingMonthYearWithTime } from '../../../../utils/globalFunctions';
import BarChart from '../../../bar/barChart';
import { betaphase3manualpricingSelector, getBetaPhase3Manualpricing } from '../../../../store/dash-board/static-data';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../label/Label';


const BetaPhase3ManualPricing = (props: any) => {
  const dispatch = useDispatch()
  const data = useSelector(betaphase3manualpricingSelector)
  const [datas, setDatas] = useState<any>([]);
  const [legendLabels, setlegendLabels] = useState<any>([])
  const [loading, isLoading] = useState<boolean>(true)

  const [startDate] = useState(new Date())
  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1)
  let mm: string | number
  let firstDayOfMonth: string | number

  mm = startDate.getMonth() 
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth


  useEffect(() => {
    const getTopSecurities = async () => {
     await  dispatch( getBetaPhase3Manualpricing() )}
      isLoading(false)
    getTopSecurities();
  }, []) 

  useEffect(() => {
      const formatDataChart: {
        label: string,
        data: any,
        barThickness: number,
        backgroundColor: any,
        borderColor: any,
        borderWidth: any,
        stack: string
      }[] = []
      const responseData = data.staticDataViewModel
      const legendLabelsTemp: string[] = []
      responseData?.length && responseData.map((m: { date: string; }) => legendLabelsTemp.push(formattingDate(m.date)))
      const bgColors = ['#BFDBFE', '#60A5FA', '#2563EB', '#1E40AF', '#1E3A8A']
      const borderColors = ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
      if (responseData && responseData.length) {
        formatDataChart.push(
          {
            label: 'Beta',
            stack: "Stack 0",
            data: responseData.map((value: any) => value?.manualPricingBeta),
            barThickness: 50,
            backgroundColor: bgColors[2],
            borderColor: borderColors[2],
            borderWidth: 0,
           
          },
          {
            label: 'Phase3',
            data: responseData.map((value: any) => value?.manualPricingPhase3),
            barThickness: 50,
            backgroundColor: bgColors[1],
            borderColor: borderColors[1],
            borderWidth: 0,
            stack: "Stack 0",
          },
        )
      }
      setDatas(formatDataChart)
      setlegendLabels(legendLabelsTemp)
    
  }, [data]);

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        fullSize: true,
        align: 'start',
        labels: {
          color: '#0F172A',
          usePointStyle: true,
          pointStyle: "rectRounded",
          boxWidth: 24,
          boxHeight: 24
        }
      },
      datalabels: {
        display: true,
        color: "white",
        formatter: function (value:any, index:any, values:any) {
          console.log("value",value)
          if (value > 0) {
            return value;
          } else {
            value = "";
            return value;
          }
        }
      },
    },
    responsive: true,
  }


  return (
    <>
    {loading && <PopUpStyleLoader>
        <LottieWrapper>
        <Lottie animationData={Loader} loop={true} />
      </LottieWrapper>
        <div style={{ marginLeft: '10px' }}>
          <Label> Loading...</Label>
        </div>
      </PopUpStyleLoader>}
      <BetaPhase3>
        <TitleContainer>{props.widgetDescription}</TitleContainer>
        <SummaryCardContainerTradeBreaksTrend>
          <BarChart
            dataValues={datas}
            options={options}
            legendLabels={legendLabels}
          />
        </SummaryCardContainerTradeBreaksTrend>
        {<span style={{ fontSize: "10px" }}>Data as of : { formattingMonthYearWithTime(new Date())}</span>} 

      </BetaPhase3>
    </>
  )
}

export default BetaPhase3ManualPricing
