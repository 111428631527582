/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */

import { useTable, useSortBy, usePagination } from 'react-table'
import {
  RootContainerTable,
  StyledTableHeader,
  PaginationContainer,
  GoToPageWrapper,
  NextPriviousWrapper,
  StyledNoData,
  TableHeader,
  TableContainer,
  StyledCheckBox,
} from './tableStyles'
import Label from '../../../../components/label/Label'
import Checkbox from '../../../../components/checkbox/Checkbox'
import { Icon } from '../../../../components/icon/Icon'
import { Icons } from '../../../../shared/GlobalStyle'
import { useMemo, useEffect } from 'react'
import { CardHeader } from '../../../../composites/cardHeader/CardHeader'
import { formattingNumber } from '../../../../utils/globalFunctions'
import TableToggle from './tableToggle'

const CreateTable = (props: any) => {
  const data = useMemo(() => props.data, [props.data])

  const controlledPageCount: number = Math.ceil(props.totalCount / 10)
  const columns = useMemo(() => props.columns, [props.columns])
  const isTradeDetails = props.showCancel

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetPage: false,
      pageCount: controlledPageCount,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
  } = tableInstance

  const actionType = [
    'Action',
    'Comments/Activity',
    'Select',
    'Category',
    'View Details',
    'Save',
    'Fix',
    'CUSIP',
    'Account Number',
    'Settle Date',
    'Sec Type',
    'Delivery Broker',
    'Audit Trail',
  ]

  useEffect(() => {
    gotoPage(0)
  }, [props.gotoFirstPage])

  const pageCallFunction = async (pageIndex: any, rowRange: any) => {
    props.setUpdateRef(pageIndex)
    props.setPageCount(pageIndex + 1, 10)
  }

  const { pageIndex } = state

  useEffect(() => {
    pageCallFunction(pageIndex, 10)
  }, [pageIndex])

  const getTdStyle = (rowInfo: any) => {
    if (data.length) {
      if (
        rowInfo?.original?.resubmitCount &&
        rowInfo?.original?.resubmitCount > 0
      ) {
        return {
          style: {
            color: '#29A41E',
            border: 'none',
            fontWeight: '500',
            background: '#D3D3D3',
          },
        }
      }
      return {}
    }
  }

  const getTrProps = (rowInfo: any) => {
    if (data.length) {
      if (
        rowInfo?.original?.dtccFileLogId &&
        rowInfo?.original?.saveFixFlag !== 1
      ) {
        return {
          style: {
            background: '#D3D3D3',
            border: 'none',
          },
        }
      }
      if (rowInfo?.original?.saveFixFlag == 1) {
        return {
          style: {
            background: '#ADD8E6',
            border: 'none',
          },
        }
      }
    }
    return {}
  }

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page && page.length ? (
            page.map((row, key) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={key} {...getTrProps(row)}>
                  {row &&
                    row.cells.map((cell) => {
                      const headerValue =
                        cell.column.Header?.toString() ||
                        cell.column.id?.toString() ||
                        ''
                      const isActionType = actionType.includes(headerValue)
                      return (
                        <td {...cell.getCellProps()} {...getTdStyle(row)}>
                          {cell.value || isTradeDetails || isActionType
                            ? cell.render('Cell')
                            : '-'}
                        </td>
                      )
                    })}
                </tr>
              )
            })
          ) : (
            <tr>
              <td
                colSpan={
                  columns[0] && columns[0].columns
                    ? columns[0].columns.length && columns[1]?.columns.length
                      ? columns[0].columns.length + columns[1].columns.length
                      : columns[0].columns.length
                    : columns?.length
                }
              >
                <StyledNoData>No Data Found</StyledNoData>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {page && page.length ? (
        <PaginationContainer>
          <Label>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </Label>
          <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
          <GoToPageWrapper>
            <Label>Go to page:</Label>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              max={pageCount}
              onChange={(e) => {
                e.stopPropagation()
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(pageNumber)
              }}
              style={{ width: '50px' }}
            />
          </GoToPageWrapper>
          <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
          <NextPriviousWrapper>
            <Icon icon={Icons.BackwardFirst} onClick={() => gotoPage(0)}></Icon>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              style={{ border: 'none', background: '#FCFDFF' }}
            >
              Previous
            </button>

            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={{ border: 'none', background: '#FCFDFF' }}
            >
              Next
            </button>
            <Icon
              icon={Icons.ForwardLast}
              onClick={() => gotoPage(pageCount - 1)}
            ></Icon>
          </NextPriviousWrapper>
        </PaginationContainer>
      ) : null}
    </>
  )
}

const Table = (props: any) => {
  return (
    <RootContainerTable>
      <TableHeader>
        <CardHeader
          label={props.heading}
          icon={false}
          amountValue={`Total Records Count: ${
            props?.totalCount ? formattingNumber(props?.totalCount) : 0
          }`}
        />
      </TableHeader>

      {props.isFilter ? (
        <TableToggle
          checkFilter={props.checkFilter}
          checkFilterKeys={props.checkFilterKeys}
          filterOnChange={props.filterOnChange}
          toggleLabels={props.toggleLabels}
        />
      ) : null}

      <TableContainer>
        <CreateTable
          data={props.data}
          columns={props.columns}
          hidePagination={props.hidePagination}
          showCancel={props.showCancel}
          setPageCount={props.setPageCount}
          totalCount={props.totalCount}
          setUpdateRef={props.setUpdateRef}
          gotoFirstPage={props.gotoFirstPage}
        />
      </TableContainer>
    </RootContainerTable>
  )
}

export default Table
