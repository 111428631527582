/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { configJSONDashboard } from '../../utils/config'

interface State {
  isLoading: boolean
  fisPhase: []  
  refinitiveBeta: []
  topSecurity: []
  betaphase3manualpricing: []
}

const initialState: State = {
  isLoading: false,
  fisPhase: [],
  refinitiveBeta: [],
  topSecurity: [],
  betaphase3manualpricing: []
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'static-data',
  initialState,
  reducers: {
    setFisPhase: (state: State, action: PayloadAction<any>) => {
      state.fisPhase = action.payload
    },
    setRefinitiveBeta: (state: State, action: PayloadAction<any>) => {
      state.refinitiveBeta = action.payload
    },
    setTopSecurity: (state: State, action: PayloadAction<any>) => {
      state.topSecurity = action.payload
    },
    setBetaphase3manualpricing: (state: State, action: PayloadAction<any>) => {
      state.betaphase3manualpricing = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    }
  },
})

export const { setFisPhase, setRefinitiveBeta, setIsLoading, setTopSecurity, setBetaphase3manualpricing } = actions

export const getFisPhase: any =
  () => async (dispatch: Dispatch) => {
    const api = configJSONDashboard.Static.fisPhase.kpi
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        api
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setFisPhase(data.p3ViewModel))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return null
    }
    dispatch(setIsLoading(false))
  }


export const getRefinitiveBeta: any =
  () => async (dispatch: Dispatch) => {
    const api = configJSONDashboard.Static.refinitiveBeta.kpi
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        api
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setRefinitiveBeta(data))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return null
    }
    dispatch(setIsLoading(false))
  }
export const getTopSecurity: any =
  (reportDate: string) => async (dispatch: Dispatch) => {
    const api = configJSONDashboard.Static.topSecurity.kpi
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `${api}?reportDate=${reportDate}`,
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setTopSecurity(data))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return null
    }
    dispatch(setIsLoading(false))
  }

export const getBetaPhase3Manualpricing: any =
  () => async (dispatch: Dispatch) => {
    const api = configJSONDashboard.Static.betaPhase3ManualPricing.kpi
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        api
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setBetaphase3manualpricing(data))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return null
    }
    dispatch(setIsLoading(false))
  }
export const isLoadingSelector = (store: StoreState) =>
  store.Dashboard.staticDashboard.isLoading
export const FisPhaseSelector = (store: StoreState) => store.Dashboard.staticDashboard.fisPhase
export const RefinitiveBetaSelector = (store: StoreState) => store.Dashboard.staticDashboard.refinitiveBeta
export const topSecuritySelector = (store: StoreState) => store.Dashboard.staticDashboard.topSecurity
export const betaphase3manualpricingSelector = (store: StoreState) => store.Dashboard.staticDashboard.betaphase3manualpricing

export default reducer
