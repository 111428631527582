import Button from '../../../../components/button/Button'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Label from '../../../../components/label/Label'
import {
  WDStyledButtonText,
  WDStyledHeading,
} from '../../../../components/ui/WDTypography'
import { COLORS } from '../../../../theme/Colors'
import { useNavigate } from 'react-router-dom'
import {
  ButtonIconWrapper,
  DateWrapper,
  MarginLeftAutoSpace,
  MarginTopSpace,
  MonthPickerLabelWrapper,
  RootContainer,
  StyledTableContainer,
  ToastWrapper,
  LottieWrapper,
  LodingTable,
  LoadingTextSpace,
  FirstContainer,
  FilterContainerHistory,
  HistoryDownload,
  BackButton,
} from '../styles'

import {
  actionSelector,
  dataSelectorHistory,
  getBetaStalePriceDataCSVHistory,
  getBetaStalePriceDataHistory,
  LoadingSelector,
  setSortDirection,
  setSorting,
  sortDirectionSelectorBeta,
  sortingSelectorBeta,
  TotalCountSelectorBeta,
} from '../../../../store/staticData/stale-pricing-beta'

import { useDispatch, useSelector } from 'react-redux'
import { SvgDownloadSmall } from '../../../../components/svg/logo/SvgDownload'
import WDCard from '../../../../components/ui/WDCard'
import { CardHeader } from '../../../../composites/cardHeader/CardHeader'
import { useState } from 'react'
import { Toast } from '../../../../components/toast/Toast'
import Lottie from 'lottie-react'
import Loader from '../../../../assets/lottiefiles/loader.json'
import BetaStaleColumnHistory from './BetaStalePriceHistoryColumn'
import { SvgLeftArrowMedium } from '../../../../components/svg/SvgLeftArrow'

const BetaStalePricingHistory = () => {
  const navigation = useNavigate()
  const sorting = useSelector(sortingSelectorBeta)
  const sortDirection = useSelector(sortDirectionSelectorBeta)
  const buffering = useSelector(LoadingSelector)
  const action = useSelector(actionSelector)
  const data = useSelector(dataSelectorHistory)
  const TotalCount = useSelector(TotalCountSelectorBeta)
  const [isTableVisible, setIsTableVisible] = useState(false)
  const dispatch = useDispatch()
  const defaultDate = new Date()
  defaultDate.setMonth(defaultDate.getMonth(), 0)
  const [startDate, setStartDate] = useState(defaultDate)
  const [loadingBuffer, setLoadingBuffer] = useState<boolean>(false)

  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  const lastDay = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)

  let mm: string | number
  let firstDayOfMonth: string | number
  let lastDayOfMonth: string | number

  mm = startDate.getMonth() + 1
  const yyyy = firstDay.getFullYear()
  firstDayOfMonth = firstDay.getDate()
  firstDayOfMonth = '0' + firstDayOfMonth
  lastDayOfMonth = lastDay.getDate()
  lastDayOfMonth = '' + lastDayOfMonth
  if (mm < 10) {
    mm = '0' + mm
  }
  const fromDate = yyyy + '-' + mm + '-' + firstDayOfMonth
  const toDate = yyyy + '-' + mm + '-' + lastDayOfMonth

  const current = new Date()

  const monthNames = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]

  const monthYear = monthNames[startDate.getMonth()] + startDate.getFullYear()

  const [requestSubmit, setrequestSubmit] = useState<{
    status: boolean | null
    text: string
  }>({
    status: null,
    text: '',
  })

  const handleSubmit = async () => {
    setIsTableVisible(false)
    dispatch(setSortDirection('ASC'))
    dispatch(setSorting('SecurityNbr, ChangedDate'))
    setLoadingBuffer(true)
    const response = await dispatch(
      getBetaStalePriceDataHistory(
        'search',
        'Beta',
        fromDate,
        toDate,
        0,
        20,
        sorting,
        sortDirection,
        0
      )
    )
    setLoadingBuffer(false)
    if (response == 200) {
      setIsTableVisible(true)
    } else {
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }

  const DownloadCSV = async () => {
    const response = await dispatch(
      getBetaStalePriceDataCSVHistory(
        `Beta_stalePriceReport_History_${monthYear}`,
        'Beta',
        fromDate,
        toDate,
        0,
        0,
        sorting,
        sortDirection,
        0
      )
    )
    if (response === 200) {
      setrequestSubmit({
        status: true,
        text: 'File Downloaded',
      })
    }
    if (response === 400) {
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }
  return (
    <RootContainer>
      <WDStyledHeading>{'Stale Pricing Beta Historical'}</WDStyledHeading>
      <FilterContainerHistory>
        <FirstContainer>
          <BackButton
            onClick={() => {
              navigation('/beta-Stale-Pricing-Report')
            }}
          >
            <SvgLeftArrowMedium fillColor={`${COLORS.UI.Primary50}`} />
            <WDStyledButtonText>
              <Label margin="0px 20px 0px 0px">Back</Label>
            </WDStyledButtonText>
          </BackButton>
          <MonthPickerLabelWrapper>
            <Label>Select Month</Label>
            <DateWrapper>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                startDate={startDate}
                dateFormat="MM/yyyy"
                maxDate={current}
                showMonthYearPicker
              />
            </DateWrapper>
          </MonthPickerLabelWrapper>

          <MarginLeftAutoSpace>
            <HistoryDownload>
              <ButtonIconWrapper
                onClick={() => {
                  DownloadCSV()
                }}
              >
                <SvgDownloadSmall
                  fillColor={`${COLORS.UI.Primary50}`}
                ></SvgDownloadSmall>

                <WDStyledButtonText>
                  <Label>CSV Download</Label>
                </WDStyledButtonText>
              </ButtonIconWrapper>
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                margin={'10px'}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  handleSubmit()
                }}
              >
                Submit
              </Button>
            </HistoryDownload>
          </MarginLeftAutoSpace>
        </FirstContainer>
      </FilterContainerHistory>
      {buffering && loadingBuffer && (
        <>
          <MarginTopSpace>
            <WDCard>
              <CardHeader
                label={'Beta Historical'}
                icon={false}
                amountValue={`Total: 0.00`}
              />
            </WDCard>
          </MarginTopSpace>
          <StyledTableContainer>
            <LodingTable>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LoadingTextSpace>
                <Label> Loading...</Label>
              </LoadingTextSpace>
            </LodingTable>
          </StyledTableContainer>
        </>
      )}
      {isTableVisible && (action === 'search' || action === 'sort') && (
        <>
          <MarginTopSpace>
            <WDCard>
              <CardHeader
                label={'Beta Historical'}
                icon={false}
                amountValue={`Total: ${TotalCount.data}`}
              />
            </WDCard>
          </MarginTopSpace>
          <StyledTableContainer>
            <BetaStaleColumnHistory
              handleSubmit={handleSubmit}
              fromDate={fromDate}
              toDate={toDate}
              data={data.data}
              totalRecords={TotalCount.data}
            />
          </StyledTableContainer>
        </>
      )}

      {requestSubmit.status !== null && (
        <ToastWrapper>
          <Toast
            text={requestSubmit.text}
            type={requestSubmit.status ? 'success' : 'danger'}
            openStatusCallback={(status: boolean) =>
              setrequestSubmit({
                status: status ? status : null,
                text: '',
              })
            }
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default BetaStalePricingHistory
