import {
    TradeSplitLabel,
    StyledInfoPanel
  } from "../styles";
  import Label from "../../../components/label/Label";


const CommonDetails = (props:any) => {
    const {topTableData}=props
    return(
        <StyledInfoPanel>
          <TradeSplitLabel>
            <Label fontSize="16px" fontWeight={600} color='#0F172A'>Symbol</Label>
            <p>{topTableData.symbol}</p>
          </TradeSplitLabel>
          <TradeSplitLabel>
            <Label fontSize="16px" fontWeight={600} color='#0F172A'>Entity Code</Label>
            <p>{topTableData.entityCode}</p>
          </TradeSplitLabel>
          <TradeSplitLabel>
            <Label fontSize="16px" fontWeight={600} color='#0F172A'>Description</Label>
            <p>{topTableData.description}</p>
          </TradeSplitLabel>
          <TradeSplitLabel>
            <Label fontSize="16px" fontWeight={600} color='#0F172A'>CUSIP</Label>
            <p>{topTableData.cusip}</p>
          </TradeSplitLabel>
        
        </StyledInfoPanel>
    )
}
export default CommonDetails;