import { useDispatch } from 'react-redux'
import DatePicker from '../../../datePicker/DatePicker'
import { TitleContainer } from './styles'
interface WidgetProps {
  widgetDescription: string
  getDetails: (e: string) => any
  setDate: (e: string) => void
}
const WidgetHeader = (props: WidgetProps) => {
  const dispatch = useDispatch()

  return (
    <>
      <div style={{ display: 'flex' }}>
        <TitleContainer>{props.widgetDescription}</TitleContainer>
        <div style={{ margin: '10px' }}>
          <DatePicker
            padding={'4px 8px'}
            onChange={(e: any) => {
              console.log('e', e)
              props.setDate(e)
              dispatch(props.getDetails(e))
            }}
            bgColor=""
            borderColor=""
            placeHolder="Date"
            maxWidth="295px"
            defaultValue={new Date().toISOString().split('T')[0]}
          />
        </div>
      </div>
    </>
  )
}
export default WidgetHeader
