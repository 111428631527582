/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'

interface State {
  jamsJobListData: []
  moduleNameListData: []
  jamsJobModuleBasedListData: []
  jamsJobReportData: []
  isLoading: boolean
  action: string
  status: string
  response: boolean
}

const initialState: State = {
  jamsJobListData: [],
  moduleNameListData: [],
  jamsJobModuleBasedListData: [],
  jamsJobReportData: [],
  isLoading: false,
  action: '',
  status: '',
  response: false,
}

const { actions, reducer }: Slice = createSlice({
  name: 'JAMS-Job-report',
  initialState,
  reducers: {
    SetJamsJobListData: (state: State, action: PayloadAction<any>) => {
      state.jamsJobListData = action.payload.data
    },
    SetModuleNameListData: (state: State, action: PayloadAction<any>) => {
      state.moduleNameListData = action.payload.data
    },
    SetJamsJobModuleBasedListData: (
      state: State,
      action: PayloadAction<any>
    ) => {
      state.jamsJobModuleBasedListData = action.payload.data
    },
    SetJamsJobReportData: (state: State, action: PayloadAction<any>) => {
      state.jamsJobReportData = action.payload.data
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setResponse: (state: State, action: PayloadAction<boolean>) => {
      state.response = action.payload
    },
    setStatus: (state: State, action: PayloadAction<string>) => {
      state.status = action.payload
    },
  },
})

export const {
  SetJamsJobListData,
  SetModuleNameListData,
  SetJamsJobModuleBasedListData,
  SetJamsJobReportData,
  setIsLoading,
  setAction,
  setStatus,
  setResponse,
} = actions

export const getJAMSJobNameList: any = () => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.get(
      'tradebreakapi/Utility/GetJobNameList'
    )
    if (status === 200) {
      dispatch(
        SetJamsJobListData({
          data: data,
        })
      )
      dispatch(setIsLoading(false))
      return data
    }
  } catch (e: any) {
    dispatch(setIsLoading(false))
    dispatch(
      SetJamsJobListData({
        data: '',
      })
    )
    return null
  }
}
export const getJAMSJobModuleNameList: any =
  () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        'tradebreakapi/Utility/GetModuleNameList'
      )
      if (status === 200) {
        dispatch(
          SetModuleNameListData({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      dispatch(
        SetModuleNameListData({
          data: '',
        })
      )
      return null
    }
  }

export const getJAMSJobListBasedOnModule: any =
  (action: string, moduleName: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/Utility/GetJobListBasedOnModule',
        { moduleName }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetJamsJobModuleBasedListData({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      dispatch(
        SetJamsJobModuleBasedListData({
          data: '',
        })
      )
      return null
    }
  }

export const getJAMSJobReportData: any =
  (
    action: string,
    jobName: string,
    moduleName: string,
    jobStatus: string,
    reportStartDate: string,
    reportEndDate: string,
    pageNumber: number,
    pageSize: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/Utility/GetJobStatusTracking',
        {
          jobName,
          moduleName,
          jobStatus,
          reportStartDate,
          reportEndDate,
          pageNumber,
          pageSize,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          SetJamsJobReportData({
            data: data,
          })
        )
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      dispatch(
        SetJamsJobReportData({
          data: '',
        })
      )
      return null
    }
  }

export const dataSelector = (store: StoreState) =>
  store.JAMSJobListReport.JAMSJob.jamsJobListData
export const jamsJobDataSelector = (store: StoreState) =>
  store.JAMSJobListReport.JAMSJob.jamsJobReportData
export const currentDataSelector = (store: StoreState) =>
  store.JAMSJobListReport.JAMSJob.data
export const LoadingSelector = (store: StoreState) =>
  store.JAMSJobListReport.JAMSJob.isLoading
export const actionSelector = (store: StoreState) =>
  store.JAMSJobListReport.JAMSJob.action
export const statusSelector = (store: StoreState) =>
  store.JAMSJobListReport.JAMSJob.status
export const ResponseSelector = (store: StoreState) =>
  store.JAMSJobListReport.JAMSJob.response

export default reducer
