/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  data: any
  isLoading: boolean
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  data: [],
  isLoading: false,
  httpErrorMessage: '',
  successMessage: '',
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'security-to-p3',
  initialState,
  reducers: {
    setCurrentData: (state: State, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  setCurrentData,
  setIsLoading,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getDataList: any = () => async (dispatch: Dispatch) => {
  try {
    const { data, status } = await authApiCall.get(
      'opsstaticdataapi/StaticData/GetSecuritiesToP3Mappings'
    )
    dispatch(setIsLoading(true))
    if (status === 200) {
      dispatch(setCurrentData(data))
      dispatch(setIsLoading(false))
      return 200
    }
  } catch (e: any) {
    handleHttpError(dispatch, e, setHttpErrorMessage)
    dispatch(setIsLoading(false))
  }
  dispatch(setIsLoading(false))
}

export const addList: any =
  (flexTradeTicker: string, p3Ticker: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/AddSecuritiesToP3MappingRequest',
        {
          flexTradeTicker,
          p3Ticker,
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Added Successfully'))
        dispatch(setIsLoading(false))
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
    }
  }
export const updateList: any =
  (id: number, flexTradeTicker: string, p3Ticker: string, isActive: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/UpdateSecuritiesToP3MappingRequest',
        {
          id,
          flexTradeTicker,
          p3Ticker,
          isActive,
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Updated Successfully'))
        dispatch(setIsLoading(false))
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
    }
  }

export const deactivateMapping: any =
  (id: number, flexTradeTicker: string, p3Ticker: string, isActive: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'opsstaticdataapi/StaticData/UpdateSecuritiesToP3MappingRequest',
        {
          id,
          flexTradeTicker,
          p3Ticker,
          isActive,
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Deactivated Successfully'))
        dispatch(setIsLoading(false))
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
    }
  }

export const currentDataSelector = (store: StoreState) =>
  store.valdi.SecuritiesToP3.data
export const LoadingSelector = (store: StoreState) =>
  store.valdi.SecuritiesToP3.isLoading
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.valdi.SecuritiesToP3.httpErrorMessage

export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.valdi.SecuritiesToP3.successMessage

export default reducer
