import React, { useState, useEffect } from 'react'
import WidgetCreator from './widgetCreator'
import { DashboardContainer, LottieWrapper } from './styles'
import { authApiCall } from '../../utils/api'
import { PopUpStyleLoader } from '../tradeRecon/styles'
import Lottie from 'lottie-react'
import Loader from '../../assets/lottiefiles/loader.json'
import Label from '../../components/label/Label'

const Dashboard = (props: { action: string ,isIntraday?:boolean,selectedDate?:string}) => {

  const [dashboardData, setDashboardData] = useState<any>([])
  const [loading, isLoading] = useState<boolean>(true)

  const getDashboardData = async () => {
    const userName = localStorage.getItem('UserId')
    const role =localStorage.getItem('roleName') ?JSON.parse(localStorage.getItem('roleName')??''): ''
    try {
      const { data, status } = await authApiCall.post(
        'opsdashboardapi/OpsDashboard/WidgetDetails',
        {
          userName: userName,
           roleNames: role.map((item:string) => 
              item.split('_')[0].split('-')[0]),
          widgetCategory: [
            props.action
          ]
        }
      )
      if (status === 200) {
        const result: any = data?.widgets || []
        await setDashboardData(result)
        isLoading(false)
      }
    } catch (e) {
   
      isLoading(false)
    }
  }

  useEffect(() => {
    getDashboardData()
  }, [])

  const token = localStorage.getItem('AccessToken')
  return (
    <>
      {loading && (
        <PopUpStyleLoader>
          {' '}
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <DashboardContainer>
        {token &&
          dashboardData?.length ? dashboardData?.map((widget: any) => WidgetCreator(widget ,props.isIntraday,props.selectedDate)) : null}
      </DashboardContainer>
    </>
  )
}

export default Dashboard