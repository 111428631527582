import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export const RootContainer = styled.div`
  margin: -8px 0px 8px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* background-color: orange; */
`
export const StyledPanelSelect = styled.div`
  width: 100%;

  padding: 20px 30px 20px 30px;
  border-radius: 0px 0px 8px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const StyledPanelHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  /* background: #fcfdff; */
  /* color: #192638; */
  // color: #ffffff;
  // background: #1f5eb7;

  /* padding: 32px 32px 0px 32px; */
  padding: 16px 24px 16px;
  border-radius: 8px 8px 0px 0px;
`

export const StyledPanelHeadTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  text-align: left;
  color: #0f172a;
`

export const StyledPanelHeadTotal = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-align: right;
`

export const StyledPanelMonthSelect = styled.div`
  width: 100%;
  padding: 24px 32px 24px 32px;
  border-radius: 0px 0px 8px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #fcfdff;
`

export const StyledSelectWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-right: 16px;
  }
`

export const StyledTableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  padding: 24px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`
export const CardContainer = styled.div`
  flex: 1 1 auto;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 8px 16px;
  margin: 8px;
  background: #fafbfc;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  background-color: #fafbfc;
  margin-left: 0px;
  margin-bottom: 16px;

  p {
    color: 374253;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`
export const PopUpStyleContent = styled.div`
  background: #fcfdff;
  position: fixed;
  top: 50%;
  left: 55%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const PopUpStyleLoader = styled.div`
  background: #f0f2f5;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const TradeSplitLabel = styled.div`
  width: 20%;
  margin-right: 0px;
`

export const StyledBreakTableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  padding-top: 24px;
  padding-left: 12px;
  padding-bottom: 12px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`

export const ModalApproveWrapper = styled.div`
  background: #f0f2f5;
  position: fixed;
  height: 120px;
  width: 400px;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: right;
  flex-direction: column;
  box-shadow: 10px 8px 16px 8px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`

export const ModalRootComments = styled.div`
  background: white;
  position: fixed;
  height: 400px;
  width: 800px;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  z-index: 99999;
  border: 2px solid #f0f2f5;
`
export const StyledFilterPanel = styled.div`
  background: #ffffff;
  /* Elevation/Elevation-low */
  width: 100%;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const EmailPopUpWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fcfdff;
  display: flex;
  flex-direction: column;
  min-height: 35%;
  min-width: 870px;
  padding: 10px 15px 10px 15px;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`
export const NoEmailPopUpWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fcfdff;
  display: flex;
  flex-direction: column;
  min-height: 15%;
  min-width: 20%;
  padding: 10px 15px 10px 15px;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`
export const EmailModalBody = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* text-align: right; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  > * {
    margin-right: 16px;
  }
`
export const PopUpActionButtonWrapper = styled.div`
  width: 100%;
  padding: 8px 24px 8px 24px;
  margin-top: 5%;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-right: 16px;
  }
`

export const TextEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 28%;
  .quill {
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
  }

  .quill > .ql-toolbar {
    border: none;
  }

  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 16px;
    font-style: normal;
  }
  .quill > .ql-container.ql-snow {
    height: 10vh;
    // border: 1px solid;
    border: none;
  }
`
export const MessageAlert = styled.div`
  display: flex;
  width: 427px;
  position: absolute;
  top: -408px;
  right: -125px;
  z-index: 1;
  height: 80px;
  animation: smooth 5s ease-in;

  @keyframes smooth {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.75;
    }
    50% {
      opacity: 0.5;
    }
    75% {
      opacity: 0.25;
    }
    100% {
      opacity: 0;
    }
  }
`
export const CancelEmailModal = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  background: none;
  cursor: pointer;
`

export const CancelSendButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 20px;
`
export const DeleteContactPopup = styled.div`
  background: ${COLORS.Background.BlurBackground};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`
export const EmailAddressTable = styled.div`
  overflow-y: scroll;
  height: 90%;
`

export const EmailHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`
export const EmailModalWrapper = styled.div`
  position: fixed;
  top: 55%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fcfdff;
  display: flex;
  flex-direction: column;
  height: 87%;
  width: 90%;
  padding: 10px 15px 10px 15px;
  box-shadow: 0px 0px 150rem 150rem rgba(100, 116, 139, 0.75);
  border-radius: 8px;
`
export const ToastWrapper = styled.div`
  position: fixed;
  color: black;
  top: 5rem;
  right: 2rem;
  z-index: 999;
`
export const ErrorMessage = styled.div`
  color: red;
`
