import { FC, useState } from 'react'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'

export const BuyInExecutionButton: FC<any> = ({
  disableFunction,
  parentValue,
  label,
  tableId,
  UpdateBuyInExeCution,
}) => {
  const onClick = () => {
    UpdateBuyInExeCution(tableId)
  }
  return (
    <button
      disabled={disableFunction}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export const BuyInExecutionInput: FC<any> = ({
  disableFunction,
  parentValue,
  rowIndex,
  objectKey,
  setParentValue,
  type,
  maxLength,
}) => {
  const [value, setValue] = useState(
    parentValue?.current[rowIndex]?.[objectKey]?.toString()
  )
  return (
    <input
      disabled={disableFunction}
      maxLength={maxLength}
      type={type}
      title={type == 'date' ? value?.split('T')[0] : value?.toString()}
      defaultValue={type == 'date' ? value?.split('T')[0] : value?.toString()}
      min={new Date().toISOString().split('T')[0]}
      onChange={(e) => {
        setValue(e.target.value)
      }}
      onBlur={() => {
        setParentValue((prevValue: any) => [
          ...prevValue.slice(0, rowIndex),
          { ...prevValue[rowIndex], [objectKey]: value },
          ...prevValue.slice(rowIndex + 1),
        ])
      }}
    />
  )
}

export const BuyInExecutionDropDown: FC<any> = ({
  parentValue,
  rowIndex,
  objectKey,
  setParentValue,
  defaultValue,
  options,
}) => {
  const [value, setValue] = useState(
    parentValue?.current[rowIndex]?.[objectKey]?.toString()
  )
  return (
    <SelectDropdown
      width="150px"
      defaultValue={defaultValue}
      borderRadius={'4px'}
      height="32px"
      options={options}
      onChange={(e) => {
        setValue(e)
      }}
      onBlur={() => {
        setParentValue((prevValue: any) => [
          ...prevValue.slice(0, rowIndex),
          { ...prevValue[rowIndex], [objectKey]: value },
          ...prevValue.slice(rowIndex + 1),
        ])
      }}
    ></SelectDropdown>
  )
}
