/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/button/Button'
import DatePicker from '../../../components/datePicker/DatePicker'
import Label from '../../../components/label/Label'
import SvgVerticalDivider from '../../../components/svg/SvgVerticalDivider'
import { WDStyledTitleHeading } from '../../../components/ui/WDTypography'
import {
  ApproveRegShoData,
  dataSelector,
  getApprovalAudit,
} from '../../../store/reg-SHO-Tool/Reg-SHO-Summary'
import { COLORS } from '../../../theme/Colors'
import {
  ApplyResetButtonWrapper,
  ApproveButtonWrapper,
  ApprovedBy,
  CancelButtonWrapper,
  FilterContainer,
  FilterLabelWrapper,
  FirstContainer,
  LeftSpace,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  StyledSelectWrapper,
} from '../styles'
import ConfirmDeletePopUp from '../../../components/popUp/confirmDeletePopUp/ConfirmPopUp'
import { useState } from 'react'
import Table from '../../../components/table/Table'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import { Backgrounds } from '../../../shared/styles'
import { CommentTableContainer } from '../cusipLevelSummary/styles'
import { getAccessFlag } from '../../../utils/globalFunctions'
interface searchParam {
  source: string
  fromDate: string | null
  toDate: string | null
  dropdownCusip: string
  dropdownSymbols: string
  applySearchClick: boolean
}
interface RegSHOSearchPanelProps {
  searchParam: searchParam
  setSearchParam: React.Dispatch<React.SetStateAction<any>>
  setSummaryFilters: React.Dispatch<React.SetStateAction<any>>
}

const RegSHOSearchPanel = ({
  searchParam,
  setSearchParam,
  setSummaryFilters,
}: RegSHOSearchPanelProps) => {
  const dispatch = useDispatch()
  const [openApprovalPopup, setOpenApprovalPopup] = useState<boolean>(false)
  const [openApprovalAudit, setOpenApprovalAudit] = useState<boolean>(false)
  const data = useSelector(dataSelector)
  const [approvedBy, setApprovedBy] = useState<string>('')
  const [auditTrailData, setAuditTrailData] = useState<[]>([])
  const approveButtonFlag = getAccessFlag(['SA-RWX','OM-RWX'])

  const HandleApprove = async () => {
    setOpenApprovalPopup(false)
    const response = await dispatch(
      ApproveRegShoData(
        searchParam.fromDate,
        searchParam.toDate,
        searchParam.source
      )
    )
    setApprovedBy(response.latestReviewedName)
    await  setSearchParam({
      ...searchParam,
      applySearchClick: true,
    })
   await setSummaryFilters(() => [])
  }
  const AuditTrail = async () => {
    const response = await dispatch(
      getApprovalAudit({
        reportDateFrom: searchParam.fromDate,
        reportDateTo: searchParam.toDate,
        source: searchParam.source,
        pageNumber: 0,
        pageSize: 0,
        sortBy: 'ActionDate',
        sortDirection: 'DESC',
      })
    )
    setAuditTrailData(response.data)
    setOpenApprovalAudit(true)
  }

  return (
    <FilterContainer>
      <FirstContainer>
        <WDStyledTitleHeading>
          {'Reg SHO 204 Tool - Summary '}
        </WDStyledTitleHeading>
        <LeftSpace>
          <Button
            bgColor={
              searchParam.source == 'Beta'
                ? COLORS.Background.Primarytext
                : 'transparent'
            }
            color={
              searchParam.source == 'Beta'
                ? COLORS.Background.Primary
                : COLORS.Background.Primarytext
            }
            padding={'4px 16px'}
            borderRadius={'20px'}
            hover={true}
            type={'button'}
            activeBgColor={
              searchParam.source == 'Beta'
                ? COLORS.Background.Primaryactive
                : ''
            }
            borderColor={
              searchParam.source == 'Beta'
                ? 'transparent'
                : COLORS.Background.Primarytext
            }
            onClick={() => {
              setSearchParam({
                ...searchParam,
                source: 'Beta',
              })
            }}
          >
            Beta
          </Button>
        </LeftSpace>
        <LeftSpace>
          <Button
            bgColor={
              searchParam.source == 'P3'
                ? COLORS.Background.Primarytext
                : 'transparent'
            }
            color={
              searchParam.source == 'P3'
                ? COLORS.Background.Primary
                : COLORS.Background.Primarytext
            }
            padding={'4px 16px'}
            borderRadius={'20px'}
            hover={true}
            type={'button'}
            activeBgColor={
              searchParam.source == 'P3' ? COLORS.Background.Primaryactive : ''
            }
            borderColor={
              searchParam.source == 'P3'
                ? 'transparent'
                : COLORS.Background.Primarytext
            }
            onClick={() => {
              setSearchParam({
                ...searchParam,
                source: 'P3',
              })
            }}
          >
            P3
          </Button>
        </LeftSpace>
        <LeftSpace></LeftSpace>
        <SvgVerticalDivider
          fillColor={COLORS.Background.Neutral40}
        ></SvgVerticalDivider>
        <FilterLabelWrapper>
          <Label>From Date</Label>
          <DatePicker
            max={new Date().toISOString().split('T')[0]}
            defaultValue={new Date().toISOString().split('T')[0]}
            borderColor={''}
            bgColor={''}
            placeHolder={''}
            padding={'4px 8px'}
            onChange={(e) => {
              setSearchParam({
                ...searchParam,
                fromDate: e,
                applySearchClick: false,
              })
            }}
            maxWidth={'100%'}
          />
        </FilterLabelWrapper>
        <FilterLabelWrapper>
          <Label>To Date</Label>
          <DatePicker
            max={new Date().toISOString().split('T')[0]}
            defaultValue={new Date().toISOString().split('T')[0]}
            borderColor={''}
            bgColor={''}
            placeHolder={''}
            padding={'4px 8px'}
            onChange={(e) => {
              setSearchParam({
                ...searchParam,
                toDate: e,
                applySearchClick: false,
              })
            }}
            maxWidth={'100%'}
          />
        </FilterLabelWrapper>
        <ApplyResetButtonWrapper>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              setApprovedBy('')
              setSearchParam({
                ...searchParam,
                applySearchClick: true,
              })
              setSummaryFilters(() => [])
            }}
          >
            Apply
          </Button>
        </ApplyResetButtonWrapper>
        <LeftSpace></LeftSpace>
        <SvgVerticalDivider
          fillColor={COLORS.Background.Neutral40}
        ></SvgVerticalDivider>
        <ApprovedBy>
          {approvedBy ?? data.latestReviewedName}
        </ApprovedBy>
        {searchParam?.fromDate === searchParam?.toDate && approveButtonFlag && (
          <ApproveButtonWrapper>
            <Button
              hoverBgColor={COLORS.Background.Primary70}
              bgColor={COLORS.Background.Primarytext}
              color={COLORS.Background.Primary}
              padding={'4px 16px'}
              borderRadius={'4px'}
              hover={true}
              type={''}
              activeBgColor={COLORS.Background.Primaryactive}
              borderColor={'transparent'}
              onClick={() => {
                setOpenApprovalPopup(true)
              }}
            >
              Approve
            </Button>
          </ApproveButtonWrapper>
        )}
        <ApproveButtonWrapper>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
             padding={'2px 2px'}
            borderRadius={'4px'}
            hover={true}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
               AuditTrail()
            }}
          >
          View Approval History
          </Button>
        </ApproveButtonWrapper>
      </FirstContainer>
      {openApprovalPopup && (
        <PopUpStyleContent>
          <ConfirmDeletePopUp
            title={''}
            ButtonLabel={'Approve'}
            message={'Are you sure you want to approve?'}
            onCancel={() => {
              setOpenApprovalPopup(false)
            }}
            onDelete={HandleApprove}
          />
        </PopUpStyleContent>
      )}
      {openApprovalAudit && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>View Approval History</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenApprovalAudit(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableData={auditTrailData ?? []}
              tableColumns={[
                {
                  Header: 'Source',
                  accessor: 'source',
                },
                {
                  Header: 'ActionDate',
                  accessor: 'actionDate',
                },
                {
                  Header: 'ApproveStatus',
                  accessor: 'approveStatus',
                },
                {
                  Header: 'ApprovedBy',
                  accessor: 'approvedBy',
                },
                {
                  Header: 'ApprovedDateTime',
                  accessor: 'approvedDateTime',
                },
              ]}
              onClick={() => {
                //
              }}
            />
            {auditTrailData.length == 0 && <h3>NO DATA</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
    </FilterContainer>
  )
}

export default RegSHOSearchPanel
