/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components'
import { ReactNode } from 'react'
import { Colors } from './../../shared/styles'

export interface ButtonProps {
  type: string
  bgColor?: string
  color: string
  style?: any
  children?: ReactNode
  borderColor?: string
  padding?: string
  hoverBgColor?: string
  borderRadius?: string
  prefixedIcon?: JSX.Element | string
  subPrefixedIcon?: JSX.Element | string
  suffixedIcon?: JSX.Element | string
  subSuffixIcon?: JSX.Element | string
  onClick?: any
  onBlur?: any
  disabled?: boolean
  hoverTextColor?: string
  hover?: boolean
  hasBorder?: boolean
  iconWidth?: string
  iconHeight?: string
  borderLessBgColor?: string
  gap?: string
  activeBgColor?: string
  focusBrColor?: string
  margin?: string
  fontWeight?: string
  title?:string
}

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: ${(ButtonProps: ButtonProps) =>
    ButtonProps.hasBorder
      ? ButtonProps.bgColor
      : ButtonProps.borderLessBgColor};
  color: ${(ButtonProps: ButtonProps) =>
    ButtonProps.hasBorder ? ButtonProps.color : Colors.Primary50};
  border-width: ${(ButtonProps: ButtonProps) =>
    ButtonProps.hasBorder ? `1px` : `0`};
  border-style: ${(ButtonProps: ButtonProps) =>
    ButtonProps.hasBorder ? `solid` : `0`};
  border-color: ${(ButtonProps: ButtonProps) =>
    ButtonProps.borderColor ? ButtonProps.borderColor : Colors.Primary50};
  border-radius: ${(ButtonProps: ButtonProps) =>
    ButtonProps.borderRadius ? ButtonProps.borderRadius : `8px`};
  cursor: pointer;
  outline: none;
  margin: ${(props: ButtonProps) => props.margin};
  padding: ${(ButtonProps: ButtonProps) => ButtonProps.padding};
  &:disabled {
    cursor: no-drop;
  }
  font-weight: ${(props: ButtonProps) =>
    props.fontWeight ? props.fontWeight : '600'};
  ${(ButtonProps: ButtonProps) =>
    ButtonProps.hover &&
    css`
      &:hover {
        background-color: ${(ButtonProps: ButtonProps) =>
          ButtonProps.hoverBgColor};
        color: ${(ButtonProps: ButtonProps) => ButtonProps.hoverTextColor};
      }
    `};
  ${(ButtonProps: ButtonProps) =>
    ButtonProps.activeBgColor &&
    css`
      &:active {
        background-color: ${(ButtonProps: ButtonProps) =>
          ButtonProps.activeBgColor};
      }
    `};

  ${(ButtonProps: ButtonProps) =>
    ButtonProps.focusBrColor &&
    css`
      &:focus {
        border: ${(ButtonProps: ButtonProps) => ButtonProps.focusBrColor};
      }
    `};
`
