import { useEffect, useState } from 'react'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleLoader,
  RootContainer,
  StyledSelectWrapper,
} from './styles'
import { useDispatch } from 'react-redux'
import Lottie from 'lottie-react'
import { useGetStockLoanSpecificL2Column } from './buyInTabColumns'
import {
  commonTabProps,
  setCommonTabProps,
} from '../BuyInAllocationAndExecution/IBuyInAllocationInterface'
import { ButtonWrapper } from '../styles'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import Label from '../../../components/label/Label'
import {
  SecurityDescriptionWrapper,
  DataTable,
  TableRowContainer,
  BoldTableData,
  TableData,
} from '../cusipLevelSummary/styles'
import {
  getRegSHOExcelBuyIn,
  getRegSHOSummaryData,
  getRegShoBuyIns,
} from '../../../store/reg-SHO-Tool/Reg-SHO-Summary'
import BuyInTabDetails from './buyInTabDetails'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { COLORS } from '../../../theme/Colors'

interface IStockLoanL2Content {
  commonTabProps: commonTabProps
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  setCommonTabProps: setCommonTabProps
}

const BuyInContent = ({
  commonTabProps,
  setActiveTab,
  setCommonTabProps,
}: IStockLoanL2Content) => {
  const dispatch = useDispatch()
  const [descriptionData, setDescriptionData] = useState<any>([])
  const [reset, setReset] = useState<boolean>(false)
  const [buyInData, setBuyInData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const { regSHOId, source, fromDate, toDate, selectedSummaryTableCUSIP } =
    commonTabProps

  const selectedCusipSummary = async () => {
    const descriptionDataResponse = await dispatch(
      getRegSHOSummaryData(
        'search',
        source,
        fromDate,
        toDate,
        0,
        20,
        'CUSIP_Number',
        'ASC',
        selectedSummaryTableCUSIP,
        '',
        regSHOId
      )
    )
    if (descriptionDataResponse) {
      setDescriptionData(descriptionDataResponse)
    }
  }

  useEffect(() => {
    const BuyIn = async () => {
      setLoading(true)
      const DataResponse = await dispatch(
        getRegShoBuyIns(
          commonTabProps.regSHOId,
          commonTabProps.allocationType.split('Buyin')[0],
          0,
          0,
          'RegShoBuyIn_ID',
          'DESC'
        )
      )
      const BuyInExecutions = DataResponse.data?.map(
        ({
          regSHOBuyIn,
          allocationId,
          regShoId,
          source,
          cusip,
          offset,
          buyInComment,
          buyInTicketNumber,
          buyInQty,
          dateToBuyIn,
          buyInNoticeDate,
          buyInType,
          createDate,
          createBy,
          reportDate,
        }: any) => ({
          regSHOBuyIn,
          allocationId,
          regShoId,
          source,
          cusip,
          offset,
          buyInComment,
          buyInTicketNumber,
          buyInQty,
          dateToBuyIn,
          buyInNoticeDate,
          buyInType,
          createDate,
          createBy,
          reportDate,
        })
      )
      descriptionData.length == 0 && (await selectedCusipSummary())
      if (DataResponse) {
        setBuyInData(BuyInExecutions)
        setData(DataResponse)
      }
      setLoading(false)
      setReset(false)
    }
    BuyIn()
  }, [reset])
  const DownloadExcelBuyIn = async () => {
    await dispatch(
      getRegSHOExcelBuyIn(
        `${commonTabProps.selectedSummaryTableCUSIP}_${commonTabProps.source}BuyIn`,
        {
          regSHO_Id: commonTabProps.regSHOId,
          allocationType: '',
          currHist: 'C',
          pageNumber: 0,
          pageSize: 0,
          sortBy: 'RegShoBuyIn_ID',
          sortDirection: 'ASC',
          source: commonTabProps.source,
        }
      )
    )
  }
  return (
    <RootContainer>
      {!loading && (
        <SecurityDescriptionWrapper>
          <DataTable>
            <tbody>
              <TableRowContainer>
                <BoldTableData>Security Description:</BoldTableData>
                <TableData>{descriptionData?.data[0]?.symbol}</TableData>
                <TableData>{descriptionData?.data[0]?.cusiP_Number}</TableData>
                <TableData>
                  {descriptionData?.data[0]?.securityDescription}
                </TableData>
                <TableData>{descriptionData?.data[0]?.borrowDesc}</TableData>
                <TableData>
                  {descriptionData?.data[0]?.thresholdIndex}
                </TableData>
              </TableRowContainer>
            </tbody>
          </DataTable>
        </SecurityDescriptionWrapper>
      )}
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {!loading && (
        <>
          {' '}
          <MarginLeftAutoSpace>
            <ButtonWrapper
              style={{ padding: '0px', lineHeight: '0px', cursor: 'pointer' }}
              onClick={async () => {
                DownloadExcelBuyIn()
              }}
            >
              <SvgDownloadSmall
                fillColor={`${COLORS.UI.Primary50}`}
              ></SvgDownloadSmall>
              <WDStyledButtonText>
                <Label>Excel</Label>
              </WDStyledButtonText>
            </ButtonWrapper>
          </MarginLeftAutoSpace>
          <BuyInTabDetails
            commonTabProps={commonTabProps}
            setActiveTab={setActiveTab}
            setCommonTabProps={setCommonTabProps}
            title={'BuyIn'}
            setReset={setReset}
            specificColumnL2={useGetStockLoanSpecificL2Column}
            buyInData={buyInData}
            data={data.data}
          />
        </>
      )}
    </RootContainer>
  )
}

export default BuyInContent
