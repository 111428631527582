/* eslint-disable react/jsx-no-target-blank */
import {
  RootContainer,
  StyledTableContainer,
  OptionsWrapper,
  LottieWrapper,
  ToastWrapper,
  LottieLoadingWrapper,
  MarginTopSpace,
  FilterOptionWrapper,
  DateWrapper,
} from './styles'

import {
  isLoadingSelector,
  dataSelector,
  actionSelector,
  getValdiReconHistoryData,
  setPageNumber,
  TotalCountSelector,
  getValdiReconHistoryCSV,
} from '../../../store/staticData/recon-history'

import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import Loader from '../../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import { Toast } from '../../../components/toast/Toast'
import { COLORS } from '../../../theme/Colors'
import ValdiReconHistoryTable from './ReconHistoryTable/ValdiReconHistoryTable'
import DatePicker from '../../../components/datePicker/DatePicker'
import { Link } from 'react-router-dom'

const ReconHistory = () => {
  const [isTableVisible, setIsTableVisible] = useState(false)
  const dispatch = useDispatch()
  const buffering = useSelector(isLoadingSelector)
  const action = useSelector(actionSelector)
  const data = useSelector(dataSelector)
  const TotalCount = useSelector(TotalCountSelector)
  const [input, setInput] = useState({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
  })

  const [requestSubmit, setrequestSubmit] = useState<{
    status: boolean | null
    text: string
  }>({
    status: null,
    text: '',
  })

  const handleRunReport = async () => {
    dispatch(setPageNumber(0))
    const response = await dispatch(
      getValdiReconHistoryData('search', input.startDate, input.endDate, 0, 20)
    )
    setIsTableVisible(true)
    if (response !== 200) {
      setIsTableVisible(false)
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }
  const handleDownload = async (e: string) => {
    dispatch(setPageNumber(0))
    const response = await dispatch(getValdiReconHistoryCSV(e))
    setIsTableVisible(true)
    if (response !== 200) {
      setrequestSubmit({
        status: false,
        text: 'Sorry, Something went wrong',
      })
    }
  }
  const TableColumnsDaily = [
    {
      Header: 'File Name',
      accessor: (d: any) => {
        if (d.fileName)
          return (
            <Link
              onClick={() => {
                handleDownload(d.fileName)
              }}
              to={''}
            >
              {d.fileName}
            </Link>
          )
      },
    },
    {
      Header: 'Report Date',
      accessor: 'reportDate',
    },
    {
      Header: 'Report Location',
      accessor: 'reportLocation',
    },
  ]

  return (
    <RootContainer>
      <WDCard>
        <CardHeader
          label={'Reconciliation History Report'}
          icon={false}
          amountValue={`Total: ${TotalCount.data ? TotalCount.data : 0}`}
        />
      </WDCard>
      <StyledTableContainer>
        <DateWrapper>
          <FilterOptionWrapper>
            <Label>Start Date</Label>
            <DatePicker
              max={new Date().toISOString().split('T')[0]}
              defaultValue={new Date().toISOString().split('T')[0]}
              borderColor={''}
              bgColor={''}
              placeHolder={''}
              padding={'4px 8px'}
              onChange={(e) => {
                setInput({
                  ...input,
                  startDate: e,
                })
              }}
              maxWidth={'100%'}
            />
          </FilterOptionWrapper>
          <FilterOptionWrapper>
            <Label>End Date</Label>
            <DatePicker
              min={input.startDate}
              max={new Date().toISOString().split('T')[0]}
              defaultValue={new Date().toISOString().split('T')[0]}
              borderColor={''}
              bgColor={''}
              placeHolder={''}
              padding={'4px 8px'}
              onChange={(e) => {
                setInput({
                  ...input,
                  endDate: e,
                })
              }}
              maxWidth={'100%'}
            />
          </FilterOptionWrapper>
          <OptionsWrapper>
            {/* <DownloadWrapper
            onClick={() => {
              DownloadCSV()
            }}
          >
            <SvgDownloadSmall
              fillColor={`${COLORS.UI.Primary50}`}
            ></SvgDownloadSmall>
            <WDStyledButtonText>
              <Label>CSV Download</Label>
            </WDStyledButtonText>
          </DownloadWrapper> */}
            <Button
              hoverBgColor={COLORS.Background.Primary70}
              bgColor={COLORS.Background.Primarytext}
              color={COLORS.Background.Primary}
              padding={'4px 16px'}
              borderRadius={'4px'}
              hover={true}
              gap={'8px'}
              type={''}
              activeBgColor={COLORS.Background.Primaryactive}
              borderColor={'transparent'}
              onClick={() => {
                handleRunReport()
              }}
            >
              Submit
            </Button>
          </OptionsWrapper>
        </DateWrapper>

        {buffering && (
          <LottieLoadingWrapper>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '54%' }}> Loading...</LottieWrapper>
          </LottieLoadingWrapper>
        )}
        {isTableVisible && action === 'search' && (
          <MarginTopSpace>
            <ValdiReconHistoryTable
              input={input}
              columns={TableColumnsDaily}
              data={data}
              totalRecords={TotalCount.data}
            />
          </MarginTopSpace>
        )}
      </StyledTableContainer>

      {requestSubmit.status !== null && (
        <ToastWrapper>
          <Toast
            text={requestSubmit.text}
            type={requestSubmit.status ? 'success' : 'danger'}
            openStatusCallback={(status: boolean) =>
              setrequestSubmit({
                status: status ? status : null,
                text: '',
              })
            }
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default ReconHistory
