
function HoliDay(props:any) {
  const ListOfDate: any = []
  props?.map((value: any) => {
    ListOfDate.push(new Date(value.year, value.month-1, value.day).getTime())
  })
  return ListOfDate
}

export default HoliDay
