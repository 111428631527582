/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useTable, useSortBy, usePagination } from 'react-table'
import { useEffect, useState } from 'react'
import {
  BackwardButtonWrapper,
  ColoumnPaddingTop,
  ForwardButtonWrapper,
  GoToPageWrapper,
  NextPriviousWrapper,
  PaginationContainer,
  RootContainerTable,
} from '../../styles'

import {
  getValdiReconHistoryData, PageNumSelector, setPageNumber
} from '../../../../store/staticData/recon-history'

import { useDispatch, useSelector } from 'react-redux'
import { LottieWrapper, PopUpStyleLoader } from '../styles'
import Lottie from 'lottie-react'
import Label from '../../../../components/label/Label'
import Loader from '../../../../assets/lottiefiles/loader.json'
import SvgBackwardDoubleChevron from '../../../../components/svg/SvgBackwardDoubleChevron'
import { COLORS } from '../../../../theme/Colors'
import SvgForwardDoubleChevron from '../../../../components/svg/SvgForwardDoubleChevron'

const CreateTable = (props: any) => {
 
  const [popUpLoaderSort, setPopUpLoaderSort] = useState<boolean>(false)
 
  const dispatch = useDispatch()
 
  const pageBeta = useSelector(PageNumSelector)
  const [pageNumberCall, setPageNumberCall] = useState<any>(1)
  const [pageStatus, setPageStatus] = useState<boolean>(false)
  const [timeoutId, setTimeoutId] = useState()
  let data = props.data
  const totalRecords = props.totalRecords
  const input=props.input

  const columns = props.columns
  let controlledPageCount: number
  controlledPageCount = Math.ceil(totalRecords / 20)

  
  useEffect(() => {
    if(pageBeta == 0 ) {
      setPageNumberCall(1)
    }
  
  }, [pageBeta])
  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: { pageIndex: 0, pageSize: 20 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  )

  const pageCallFunction = async (pageIndex: any, rowRange: any) => {
    dispatch(setPageNumber(pageIndex - 1))
    setPopUpLoaderSort(true)
    await dispatch(
      getValdiReconHistoryData(
        'search',
        input.startDate,
        input.endDate,
        pageIndex - 1,
        rowRange,
      )
    )
    setPopUpLoaderSort(false)
  }

  useEffect(() => {
    if (pageStatus) {
      pageCallFunction(pageNumberCall, 20)
    }
  }, [pageNumberCall])

  const onChangeFunction = (pageValue: number) => {
    if (pageValue > 0 && pageValue <= pageCount) {
      const pageNumber = pageValue ? pageValue : 1
      setPageStatus(true)
      clearTimeout(timeoutId)
      const timeout: any = setTimeout(() => {
        gotoPage(pageNumber)
        setPageNumberCall(pageNumber)
      }, 1000)
      setTimeoutId(timeout)
    }
  }

  return (
    <>
      <table {...getTableProps()}>
        {popUpLoaderSort && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <div style={{ marginLeft: '10px' }}>
              <Label> Loading...</Label>
            </div>{' '}
          </PopUpStyleLoader>
        )}
       <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
              {headerGroup.headers.map((column: any, index) => (
                <th
                  key={index}
                 
                >
                  {column.render('Header')}

                 
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      {data.length == 0 && (
             <><Label fontWeight={700} margin="5% 0 0 50%">
             No Records Found 
           </Label><Label fontWeight={700} margin="0.5% 0 0 49%">
             No Exceptions Identified
             </Label></>
            )}

      <PaginationContainer>
        <Label>
          Page{' '}
          <strong>
            {pageNumberCall} of {pageOptions.length}
          </strong>{' '}
        </Label>
        <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
        <GoToPageWrapper>
          <Label>Go to page:</Label>
          <input
            type="number"
            defaultValue={pageNumberCall}
            onChange={(e) => {
              onChangeFunction(Number(e.target.value))
            }}
            style={{ width: '50px' }}
          />
        </GoToPageWrapper>
        <span style={{ marginLeft: '20px', color: '#E2E8F0' }}>|</span>
        <NextPriviousWrapper>
          <BackwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(1)
              setPageNumberCall(1)
            }}
          >
            {pageNumberCall !== 1 ? (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </BackwardButtonWrapper>
          <button
            onClick={() => {
              setPageStatus(true)
              previousPage()
              setPageNumberCall(pageNumberCall - 1)
            }}
            disabled={pageNumberCall === 1}
            style={{
              border: 'none',
              background: '#FCFDFF',
              cursor: 'pointer',
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              setPageStatus(true)
              nextPage()
              setPageNumberCall(pageNumberCall + 1)
            }}
            disabled={pageNumberCall === pageCount}
            style={{
              border: 'none',
              background: '#FCFDFF',
              cursor: 'pointer',
            }}
          >
            Next
          </button>
          <ForwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(pageCount)
              setPageNumberCall(pageCount)
            }}
          >
            {pageNumberCall !== pageCount ? (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </ForwardButtonWrapper>
        </NextPriviousWrapper>
      </PaginationContainer>
    </>
  )
}

const ValdiReconHistoryTable = (props: any) => {
  return (
    <RootContainerTable>
      <CreateTable
        input={props.input}
        data={props.data}
        columns={props.columns}
        totalRecords={props.totalRecords}
        // hidePagination={props.hidePagination}
      />
    </RootContainerTable>
  )
}

export default ValdiReconHistoryTable
