import {
  SummaryCardContainerTradeBreaksTrend,
  TableChartWidgetContainerByTrend,
  TitleContainer
} from './styles'
import LineChart from '../../../lineChart/lineChart'
import { useEffect, useState } from 'react'
import { authApiCall } from "../../../../utils/api";
import { LineGraphColors } from '../../../../shared/styles';
import { configJSONDashboard } from '../../../../utils/config'
import { formattingWithTime } from '../../../../utils/globalFunctions';


const TradeBreaksTrend = (props: { isIntraday?: boolean, widgetDescription: any }) => {

  const [datas, setDatas] = useState<any>([]);

  useEffect(() => {
    const getTradeBreakByTrend = async () => {
      let api = ''
      if (props?.isIntraday) {
        api = configJSONDashboard.IntraDayTradeBreaks.tradeBreaksTrend.kpi
      }
      else {
        api = configJSONDashboard.TradeBreaks.tradeBreaksTrend.kpi
      }
      try {
        const { data, status } = await authApiCall.post(
          api,
          {
            // tradeDate: new Date().toISOString().split("T")[0],
            mpidCount: 5
          }
        );
        if (status === 200) {
          let formatedData = []
          if (props?.isIntraday) {
            formatedData = data.tradeBreakTrendDetails.map(
              (m: { mpid: string; number: number; tradeDate: string }) => { return { ...m, tradeDate:formattingWithTime(m.tradeDate) } })
              setDatas(formatedData);
          }
          else {
            formatedData = data.tradeBreakTrendDetails.map(
              (m: { mpid: string; number: number; tradeDate: string }) => { return { ...m, tradeDate: m.tradeDate.toString().split("T")[0] } })
            setDatas(formatedData);
          }

        }
      } catch (e) {
        const error = e
      }
    };
    getTradeBreakByTrend();
  }, []);


  const groupBy = (xs: [], key: string) => {
    return xs.reduce((rv: any, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const formatedDataObject = groupBy(datas, "mpid")
  const dateRange = Object.keys(groupBy(datas, "tradeDate")).map((key: any) => { return key })


  const graphArray: any = [];
  Object.keys(formatedDataObject).forEach(function (key, index) {
    graphArray.push({
      label: key,
      data: formatedDataObject[key].map((ele: any) => { return ele.number }),
      borderColor: LineGraphColors[index],
      backgroundColor: LineGraphColors[index],
      yAxisID: 'y',
      tension: 0.1,
      borderWidth: '2',
      pointBorderColor: "#ffff",
      pointRadius: 5
    })
  })

  return (
    <>
      <TableChartWidgetContainerByTrend>
        <TitleContainer>{props.widgetDescription}</TitleContainer>

        <SummaryCardContainerTradeBreaksTrend>
          <LineChart
            legendLabels={dateRange}
            title={"Trade Breaks By Trends"}
            dataValues={graphArray}
            bgColors={["#BFDBFE", "#60A5FA", "#2563EB", "#1E40AF", "#1E3A8A"]}
            borderColors={["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]}
            borderWidth={0}
          />
          {/* <span style={{ display: 'block', textAlign: 'center', fontSize: '16px', padding: '10px', whiteSpace: 'nowrap' }}>Work in progress</span> */}


        </SummaryCardContainerTradeBreaksTrend>
      </TableChartWidgetContainerByTrend>
    </>
  )
}


export default TradeBreaksTrend
