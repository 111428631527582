import { FeatureDescription, IconConatainer, SummaryCardContainerTradeReconcilation, TitleContainer, TradeReconInfoTile } from './styles'
import { useNavigate } from "react-router-dom";
import SvgReport from '../../svg/SvgReport';

const StaticData = (props: any) => {
  const navigate = useNavigate();

  const addBreadCrumb = async (date: any) => {
    const breadCrumbArray = [
      {
        key: "Dashboard",
        link: "/staticDashboard",
        active: false
      }
    ]
    sessionStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbArray));
    navigate("/staticDashboard", {
      state: {
        passProps: {}
      },
    });
  }


  return (
    <SummaryCardContainerTradeReconcilation onClick={addBreadCrumb}>
      <TitleContainer>Static Data
      </TitleContainer>
      <IconConatainer>
        <SvgReport fillColor={"#FFFFFF"} />
      </IconConatainer>
      <FeatureDescription>
        Manage descriptive and pricing reference data quality and control processes
      </FeatureDescription>
    </SummaryCardContainerTradeReconcilation>
  )
}

export default StaticData
