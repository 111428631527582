/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { configJSONDashboard } from '../../utils/config'

interface State {
  isLoading: boolean
  staticJob: []
  settlementJob: []
  tradeReconJob: []
  buyInLoading: boolean
}

const initialState: State = {
  isLoading: false,
  staticJob: [],
  settlementJob: [],
  tradeReconJob: [],
  buyInLoading: false,
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'settlement',
  initialState,
  reducers: {
    setTradeReconJob: (state: State, action: PayloadAction<any>) => {
      state.tradeReconJob = action.payload
    },
    setSettlementJob: (state: State, action: PayloadAction<any>) => {
      state.settlementJob = action.payload
    },
    setStaticJob: (state: State, action: PayloadAction<any>) => {
      state.staticJob = action.payload
    },

    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setBuyInLoading: (state: State, action: PayloadAction<boolean>) => {
      state.buyInLoading = action.payload
    },
  },
})

export const {
  setIsLoading,
  setTradeReconJob,
  setSettlementJob,
  setStaticJob,
  setBuyInLoading,
} = actions

export const getTradeReconJob: any =
  (date: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `tradebreakapi/Utility/GetTradeReconJobStatus`,
        {
          moduleName: 'TradeReconciliation',
          date: date,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setTradeReconJob(data))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return null
    }
    dispatch(setIsLoading(false))
  }

export const getStaticJob: any = (date:string) => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.post(
      `tradebreakapi/Utility/GetStaticDataJobStatus`,
      {
        moduleName: 'StaticData',
        date: date,
      }
    )

    if (status === 200) {
      dispatch(setIsLoading(false))
      dispatch(setStaticJob(data))
      return 200
    }
  } catch (e: any) {
    dispatch(setIsLoading(false))
    return null
  }
  dispatch(setIsLoading(false))
}

export const getSettlementJob: any =
  (date: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `tradebreakapi/Utility/GetSettlementsJobStatus`,
        {
          moduleName: 'Settlement',
          date: date,
        }
      )
      if (status === 200) {
        dispatch(setSettlementJob(data))
        dispatch(setIsLoading(false))
        return { data, status }
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return null
    }
    dispatch(setIsLoading(false))
  }

export const isLoadingSelector = (store: StoreState) =>
  store.Dashboard.settlement.isLoading
export const TradeReconJobSelector = (store: StoreState) =>
  store.Dashboard.jamsJob.tradeReconJob
export const SettlementJobSelector = (store: StoreState) =>
  store.Dashboard.jamsJob.settlementJob
export const StaticJobSelector = (store: StoreState) =>
  store.Dashboard.jamsJob.staticJob

export default reducer
