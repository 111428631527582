import { useEffect, useState } from 'react'
import Divider from '../../../components/divider/Divider'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import { Backgrounds } from '../../../shared/styles'
import { COLORS } from '../../../theme/Colors'
import { useDispatch } from 'react-redux'
import {
  getEmailDownload,
  getL2AllEmailList,
} from '../../../store/reg-SHO-Tool/reg-sho-buyInAllocation'
import Table from '../../../components/table/Table'
import { StyledImage } from '../../../components/image/styles'
import { Icons } from '../../../shared/GlobalStyle'
import { setIsLoading } from '../../../store/reg-SHO-Tool/Reg-SHO-Summary'
import { CancelButtonWrapper, MailWrapper, MarginLeftAutoSpace, PopUpStyleContent, StyledSelectWrapper } from './RegShoHistoryStyles'

interface EmailListProps {
  ClosePopUp: () => void
  AllEmailList:any
}
export const EmailList = ({
  ClosePopUp,
  AllEmailList
}: EmailListProps) => {
  const dispatch = useDispatch()



  const downloadEmail = async (e: number) => {
    await dispatch(getEmailDownload(AllEmailList[e]))
  }

  return (
    <PopUpStyleContent>
      <MailWrapper>
        <StyledSelectWrapper
          style={{
            padding: '15px 15px 0px 15px',
            fontWeight: '600',
            borderRadius: '8px 8px 0px 0px',
          }}
        >
          <MarginLeftAutoSpace>
            <CancelButtonWrapper
              onClick={() => {
                ClosePopUp()
              }}
            >
              <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
            </CancelButtonWrapper>
          </MarginLeftAutoSpace>
          <Divider
            thickness={'1px'}
            horizontal={true}
            bgColor={Backgrounds.Gray110}
          />
        </StyledSelectWrapper>

        <Table
          tableColumns={[
            {
              Header: 'Email',
              accessor: 'fileName',
            },
            {
              Header: 'Download',
              Cell: (e: { cell: { row: { id: number } } }) => {
                return (
                  <StyledImage
                    title="Download"
                    onClick={() => {
                      downloadEmail(e.cell.row.id)
                    }}
                    width={'25px'}
                    style={{ cursor: 'pointer' }}
                    src={Icons.Download}
                    alt="Download"
                  />
                )
              },
            },
          ]}
          tableData={AllEmailList}
          onClick={() => {
            //
          }}
        />

        {AllEmailList?.length === 0 && <>No Email</>}
      </MailWrapper>
    </PopUpStyleContent>
  )
}
