import {
  RootContainer,
  StyledTableContainer,
  OptionsWrapper,
  DownloadWrapper,
  LottieWrapper,
  ToastWrapper,
  LottieLoadingWrapper,
  MarginTopSpace,
} from './styles'
import {
  getP3ReconData,
  isLoadingSelector,
  getP3ReconCSV,
  dataSelector,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  setSuccessMessage,
  setHttpErrorMessage,
} from '../../../store/staticData/P3-Recon'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import Label from '../../../components/label/Label'
import Button from '../../../components/button/Button'
import Loader from '../../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import { Toast } from '../../../components/toast/Toast'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import { COLORS } from '../../../theme/Colors'
import Table from '../../../components/table/Table'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import DatePicker from '../../../components/datePicker/DatePicker'

const ValdiOperation = () => {
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [ReportDate, setReportDate] = useState(
    new Date().toISOString().split('T')[0]
  )
  const dispatch = useDispatch()
  const buffering = useSelector(isLoadingSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const data = useSelector(dataSelector)

  const TableColumnsDaily = [
    {
      Header: 'FlexTrade Symbol',
      accessor: 'flexTradeSymbol',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'P3 Symbol',
      accessor: 'p3Symbol',
    },
    {
      Header: 'CUSIP',
      accessor: 'cusip',
    },
    {
      Header: 'Market Maker',
      accessor: 'marketMaker',
    },
    {
      Header: 'Report Date',
      accessor: 'reportDate',
    },
  ]

  const handleRunReport = async () => {
    setIsTableVisible(false)
    const response = await dispatch(getP3ReconData(ReportDate))
   
   
      if (response == 200) {
        setIsTableVisible(true)
      } 
    else{
      setIsTableVisible(false)
    }
  }

  const DownloadCSV = async () => {
    const response = await dispatch(
      getP3ReconCSV(`ReconReport_${ReportDate}`, ReportDate)
    )
  }
  return (
    <RootContainer>
      <WDCard>
        <CardHeader
          label={'P3 Reconciliation'}
          icon={false}
          amountValue={`Total: ${data.length  > 0 ? data.length : 0}`}
        />
      </WDCard>
      <StyledTableContainer>
        <Label padding="5px" fontWeight={700}>
          Report Date
        </Label>
        <DatePicker
          max={new Date().toISOString().split('T')[0]}
          defaultValue={new Date().toISOString().split('T')[0]}
          borderColor={''}
          bgColor={''}
          placeHolder={''}
          padding={'4px 8px'}
          onChange={(e) => {
            setReportDate(e)
          }}
          maxWidth={'15%'}
        />
        <OptionsWrapper>
          <DownloadWrapper
            onClick={() => {
              DownloadCSV()
            }}
          >
            <SvgDownloadSmall
              fillColor={`${COLORS.UI.Primary50}`}
            ></SvgDownloadSmall>
            <WDStyledButtonText>
              <Label>CSV Download</Label>
            </WDStyledButtonText>
          </DownloadWrapper>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            gap={'8px'}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              handleRunReport()
            }}
          >
            Run Report
          </Button>
        </OptionsWrapper>
        {buffering && (
          <LottieLoadingWrapper>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '54%' }}> Loading...</LottieWrapper>
          </LottieLoadingWrapper>
        )}
        {isTableVisible && (
          <MarginTopSpace>
            <Table
              tableColumns={TableColumnsDaily}
              tableData={data}
              onClick={() => {
                //
              }}
            />
            {data.length == 0 && (
            <><Label fontWeight={700} margin="5% 0 0 50%">
            No Records Found 
          </Label><Label fontWeight={700} margin="0.5% 0 0 49%">
            No Exceptions Identified
            </Label></>
            )}
          </MarginTopSpace>
        )}
      </StyledTableContainer>

      {(SuccessMessage?.length > 0 ||
        ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success':'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default ValdiOperation
