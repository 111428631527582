import React from 'react'
import Alerts from '../../components/widgets/DashBoard/tradeBreaks/alerts'
import TradeBreakCountT0 from '../../components/widgets/DashBoard/tradeBreaks/tradeBreakCountT0'
import SuspenseAccount from '../../components/widgets/DashBoard/tradeBreaks/suspenseAccount'
import TradeBreakByAgeFI from '../../components/widgets/DashBoard/tradeBreaks/tradeBreakByAgeFI'
import TradeBreakByAgeEquity from '../../components/widgets/DashBoard/tradeBreaks/tradeBreakByAgeEquity'

import TradeBreaksTrend from '../../components/widgets/DashBoard/tradeBreaks/tradeBreaksTrend'
import TradeBreakCountT1 from '../../components/widgets/DashBoard/tradeBreaks/tradeBreakCountT1'
import BetaPhase3ManualPricing from '../../components/widgets/DashBoard/StaticData/betaPhase3ManualPricingGraph'
import FisPhase3 from '../../components/widgets/DashBoard/StaticData/phase3SecurityAdd&UpdateTable'
import TopSecurity from '../../components/widgets/DashBoard/StaticData/topSecurity'
import Phase3 from '../../components/widgets/DashBoard/StaticData/phase3SecurityAdd&UpdateGraph'
import BuyInIntents from '../../components/widgets/DashBoard/Settlement/buyInIntents'
import BuyInIntents1 from '../../components/widgets/DashBoard/Settlement/buyInIntents1'
import BuyInIntents2 from '../../components/widgets/DashBoard/Settlement/buyInIntents2'
import FailsToDeliver from '../../components/widgets/DashBoard/Settlement/failsToDeliver'
import FailsToReceive from '../../components/widgets/DashBoard/Settlement/failsToReceive'
import TopContra from '../../components/widgets/DashBoard/Settlement/TopContra'
import TopCorrespondents from '../../components/widgets/DashBoard/Settlement/TopCorrespondents'
import Beta from '../../components/widgets/DashBoard/StaticData/betaPhase3ManualPricingTable'
import TradeReconJob from '../../components/widgets/DashBoard/JamsJob/TradeReconJob'
import SettlementJobs from '../../components/widgets/DashBoard/JamsJob/SettlementJob'
import StaticJobs from '../../components/widgets/DashBoard/JamsJob/StaticJob'
import SubmissionErrorByType from '../../components/widgets/DashBoard/cais/submissionErrorByType'
import FeedBackErrorByType from '../../components/widgets/DashBoard/cais/feedBackErrorByType'
import ErrorTrend from '../../components/widgets/DashBoard/cais/errorTrend'
import BetaSubmissionLOB from '../../components/widgets/DashBoard/cais/betaSubmissionLOB'
import P3SubmissionLOB from '../../components/widgets/DashBoard/cais/p3SubmissionLOB'
import ErrorBreakDown from '../../components/widgets/DashBoard/cais/errorBreakDown'
import ErrorBreakDownByErrorCode from '../../components/widgets/DashBoard/cais/errorBreakDownByErrorCode'

const WidgetCreator = (
  props: any,
  isIntraday?: boolean,
  selectedDate?: string
) => {
  const widgetName: string = props.widgetName

  const Components: any = {
    alerts: Alerts,
    tradeBreaksTrend: TradeBreaksTrend,
    tradeBreaksByAgeFI: TradeBreakByAgeFI,
    tradeBreaksByAgeEquity: TradeBreakByAgeEquity,
    suspenseAccount: SuspenseAccount,
    tradeBreakCountT0: TradeBreakCountT0,
    tradeBreakCountT1: TradeBreakCountT1,
    BetaP3ManualPricingCount: Beta,
    P3SecurityAddUpdateCount: FisPhase3,
    P3Top5SecurityUpdates: TopSecurity,
    BetaP3ManualPricingGraph: BetaPhase3ManualPricing,
    P3SecurityAddUpdateGraph: Phase3,
    BuyInIntents1: BuyInIntents,
    BuyInIntents2: BuyInIntents1,
    BuyInIntents3: BuyInIntents2,
    FailToDeliver: FailsToDeliver,
    FailToReceive: FailsToReceive,
    Top10Contra: TopContra,
    Top10Correspondents: TopCorrespondents,
    TradeReconJob: TradeReconJob,
    SettlementJob: SettlementJobs,
    StaticJob: StaticJobs,
    submissionErrorsByType: SubmissionErrorByType,
    feedBackErrorsByType: FeedBackErrorByType,
    catCAISErrorTrend: ErrorTrend,
    top3BetaSubmissionLOBs: BetaSubmissionLOB,
    top3P3SubmissionLOBs: P3SubmissionLOB,
    errorsBreakdownByLOBs: ErrorBreakDown,
    errorsBreakdownByErrorCode: ErrorBreakDownByErrorCode,
  }
  return React.createElement(Components[widgetName], {
    ...props,
    isIntraday,
    selectedDate,
  })
}

export default WidgetCreator
